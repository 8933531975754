import React from 'react'
import { SvgIcon } from '@mui/material';

export default function UrgentIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M50 33.876V54.001"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.6663 25.8338C17.6663 21.4172 21.2913 17.7922 25.7079 17.7922H32.9163C34.5829 17.7922 36.9163 16.9172 38.1663 15.8755L44.7496 10.2505C47.6663 7.79215 52.3746 7.79215 55.2079 10.2505L61.7913 15.8755C63.0413 16.9172 65.4163 17.7922 67.0829 17.7922H74.1663C78.5829 17.7922 82.2079 21.4172 82.2079 25.8338V32.9172C82.2079 34.5838 83.0829 36.9172 84.1246 38.1672L89.7496 44.7505C92.2079 47.6672 92.2079 52.3755 89.7496 55.2088L84.1246 61.7922C83.0829 63.0422 82.2079 65.3755 82.2079 67.0422V74.1255C82.2079 78.5422 78.5829 82.1672 74.1663 82.1672H67.0829C65.4163 82.1672 63.0829 83.0422 61.8329 84.0838L55.2496 89.7088C52.3329 92.1672 47.6246 92.1672 44.7913 89.7088L38.2079 84.0838C36.9579 83.0422 34.5829 82.1672 32.9579 82.1672H25.7079C21.2913 82.1672 17.6663 78.5422 17.6663 74.1255V67.0005C17.6663 65.3755 16.8329 63.0005 15.7913 61.7922L10.1663 55.1672C7.74959 52.2922 7.74959 47.6255 10.1663 44.7505L12.4996 42.0005"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M49.9771 66.6665H50.0145"
          stroke="currentColor"
          strokeWidth="6.26"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
