import React from 'react'
import { SvgIcon } from '@mui/material';

export default function DurationIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M40.0408 40.9585L71.9158 69.9168C80.3741 77.5835 79.1658 91.6668 63.4991 91.6668H36.4991C20.8325 91.6668 19.6241 77.5835 28.0825 69.9168L71.9158 30.0835C80.3741 22.4168 79.1658 8.3335 63.4991 8.3335H36.4991C20.8325 8.3335 19.6241 22.4168 28.0825 30.0835"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
