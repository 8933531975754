import React from 'react'
import { SvgIcon } from '@mui/material';

export default function SingularityIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M8.33325 30.2502C8.33325 15.4169 11.4583 10.0003 22.9999 8.70861C25.1666 8.41694 27.5416 8.3335 30.2916 8.3335H69.7499C72.4583 8.3335 74.8749 8.41694 77.0416 8.70861C88.5833 10.0003 91.7082 15.4169 91.7082 30.2502V56.5836C91.7082 71.4169 88.5833 76.8335 77.0416 78.1252C74.8749 78.4168 72.4999 78.5003 69.7499 78.5003H30.2916C27.5833 78.5003 25.1666 78.4168 22.9999 78.1252C11.4583 76.8335 8.33325 71.4169 8.33325 56.5836V46.2501"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M56.5837 34.666H71.917"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.0833 58.791H28.1665H71.9582"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.1667 91.6665H70.8334"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.9779 34.5824H30.0154"
          stroke="currentColor"
          strokeWidth="8.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M43.7272 34.5824H43.7646"
          stroke="currentColor"
          strokeWidth="8.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
