import React from "react";
import {
  Box,
  Tooltip,
  Typography,
  Button,
  Paper,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Popper,
  Fade,
  Toolbar,
  IconButton,
  Divider,
  alpha,
  ClickAwayListener,
  styled,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
} from "@mui/material";
import { FlowBox } from "blocks/atoms/uistyles";
import { getFileIcon, UserAvatar, LoadingSpinner } from "blocks/atoms";
import { useElementSize } from "hooks";
import { useAtom } from "jotai";
import {
  selectedFilesListAtom,
  selectedUploadActionAtom,
  selectedFileActionAtom,
  aiPromptDialogOpenAtom,
  isItemSelectedAFolderAtom,
  numOfFilesSelectedAtom,
} from "providers/FilesProvider/files-atoms";
import { checkIfNull, useAppContext } from "@app21/core";
import { DataGrid } from "@mui/x-data-grid";
import {
  Visibility,
  Close,
  Insights,
  AutoFixHigh,
  History,
  HistoryEdu,
  AddCircle,
} from "@mui/icons-material";
import { useFilesContext } from "providers/FilesProvider";
import { format } from "date-fns";
import { formatBytes } from "utils/fileHelperUtils";
import {
  fileActionListOptions,
  uploadActionListOptions,
  FileAIPromptDialog,
} from "blocks/modules/TrrystFiles/foundations";
import ShowEmptyFolderPanel from "./ShowEmptyFolderPanel";
import { reservedFolderNames } from "./reservedFolderNames";
import {
  usePopupState,
  bindToggle,
  bindPopper,
  bindTrigger,
} from "material-ui-popup-state/hooks";
import FileHistoryAnalytics from "./FileHistoryAnalytics";
import { sort } from "fast-sort";
import { checkIfActionsAllowedPath } from "../Menubar/checkIfActionsAllowedPath";
import { getDateTimeFormat } from "utils/getDateFormats";

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: "absolute",
  bottom: 10,
  right: 2,
  "& .MuiSpeedDial-directionUp, & .MuiSpeedDial-directionLeft": {
    bottom: theme.spacing(1),
    right: theme.spacing(1),
  },

  "& .MuiSpeedDial-directionDown, & .MuiSpeedDial-directionRight": {
    top: theme.spacing(1),
    left: theme.spacing(1),
  },
}));

export default function FilesListPanel({ viewContext = "SUITE" }) {
  const [hoveredRow, setHoveredRow] = React.useState(null);

  const { suiteRole, checkAccessRules } = useAppContext();
  const { accessFlag: canAccessMembers } = checkAccessRules({
    entity: "USERS-VISIBILITY",
    action: "VIEW-ALL-SUITE-USERS",
    featureName: "SUITE-USER-DETAILS",
    isInvitee: true,
  });
  const isSuiteManager = suiteRole === "MANAGER";
  const {
    s3Files = [],
    allS3KeysStatus,
    filesPermissions = {},
    folderChain = [],
    isMobile,
    handleAiDialogClose,
  } = useFilesContext();

  const [selectedFilesList, setSelectedFilesList] = useAtom(
    selectedFilesListAtom
  );
  const [selectedFileAction, setSelectedFileAction] = useAtom(
    selectedFileActionAtom
  );
  const [selectedUploadAction, setSelectedUploadAction] = useAtom(
    selectedUploadActionAtom
  );
  const [aiPromptDialogOpen, setAiPromptDialogOpen] = useAtom(
    aiPromptDialogOpenAtom
  );
  const [isItemSelectedAFolder, setIsItemSelectedAFolder] = useAtom(
    isItemSelectedAFolderAtom
  );
  const [numOfFilesSelected, setNumOfFilesSelected] = useAtom(
    numOfFilesSelectedAtom
  );

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 50,
    page: 0,
  });

  const [contextMenu, setContextMenu] = React.useState(null);
  const [selectedFileHistoryKey, setSelectedFileHistoryKey] =
    React.useState(null);
  const [dialOpen, setDialOpen] = React.useState(false);
  const handleDialOpen = () => setDialOpen(true);
  const handleDialClose = () => setDialOpen(false);

  const fileHistoryPopupState = usePopupState({
    variant: "popper",
    popupId: "fileHistoryTimelinePopper",
  });

  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "fileType",
      headerName: "Type",
      flex: 1,
      minWidth: 30,
      maxWidth: 45,
      editable: false,
      hide: false,
      renderCell: (params) =>
        getFileIcon({
          fileExt: params.value || "file",
          size: "lg",
        }),
    },
    // {
    //   field: "isPasswordProtected",
    //   headerName: "🔒",
    //   flex: 1,
    //   hide: false,
    //   minWidth: 30,
    //   maxWidth: 30,
    //   editable: false,
    //   renderCell: (params) => (params.value ? "🔒" : ""),
    // },

    {
      field: "name",
      headerName: "Name",
      flex: 1,
      hide: false,
      minWidth: 120,
      editable: false,
      renderCell: (params) => {
        if (params.value) {
          return (
            <Box sx={{ display: "flex", gap: 3 }}>
              {params.row.isPasswordProtected ? "🔒" : ""}
              {params.value}
            </Box>
          );
        } else return "";
      },
    },
    {
      field: "size",
      headerName: "Size",
      type: "number",
      flex: 1,
      hide: false,
      minWidth: 50,
      maxWidth: 80,
      editable: false,
      renderCell: (params) => {
        if (params.value) return formatBytes(params.value);
        else return "";
      },
    },
    {
      field: "modDate",
      headerName: "Created On",
      sortable: true,
      flex: 1,
      hide: false,
      minWidth: 100,
      maxWidth: 140,
      renderCell: (params) => {
        if (params.value) {
          return format(
            new Date(params.value ?? null),
            getDateTimeFormat({
              startDate: params.value,
            })
          );
        } else return <></>;
      },
    },
    {
      field: "hasAiGenerated",
      headerName: "AI",
      flex: 1,
      hide: false,
      minWidth: 30,
      maxWidth: 50,
      editable: false,
      renderCell: (params) => {
        if (params.row.isDir || !params.value) return <></>;
        else
          return (
            <Tooltip
              title="AI generated Summaries available on this document"
              placement="top-start"
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSelectedFileAction("generate_ai_summary");
                  setSelectedFilesList([params.row.id]);
                }}

                // {...bindToggle(aiSummariesPopupState)}
                // onClick={(e) => {
                //   if (selectedAiSummaryFile?.id === params.id)
                //     setSelectedAiSummaryFile(null);
                //   else setSelectedAiSummaryFile(params.row);
                //   bindToggle(aiSummariesPopupState).onClick(e);
                // }}
              >
                <AutoFixHigh fontSize="small" />
              </Box>
            </Tooltip>
          );
      },
    },
    {
      field: "createdBy",
      headerName: "By",
      flex: 1,
      hide: !canAccessMembers,
      minWidth: 30,
      maxWidth: 50,
      sortable: true,

      renderCell: (params) => {
        if (params.value) {
          return <UserAvatar userId={params.value} size={20} />;
        } else return <></>;
      },
    },
    {
      field: "views",
      flex: 1,
      hide: !isSuiteManager,
      minWidth: 30,
      maxWidth: 60,
      headerName: "Logs",
      sortable: true,

      renderCell: (params) => {
        if (params.row.isDir) return <></>;

        return (
          <Box
            sx={{
              display:
                params.row.id === hoveredRow || isMobile ? "flex" : "none",
              alignItems: "center",
              cursor: "pointer",
            }}
            {...bindToggle(fileHistoryPopupState)}
            onClick={(e) => {
              if (selectedFileHistoryKey === params.id)
                setSelectedFileHistoryKey(null);
              else setSelectedFileHistoryKey(params.id);
              bindToggle(fileHistoryPopupState).onClick(e);
            }}
          >
            <History fontSize="small" />
            {/* <Typography sx={{ ml: 1 }}>{params.value}</Typography> */}
          </Box>
        );
      },
    },
  ];
  const [rowsSelectedList, setRowsSelectedList] = React.useState([]);
  const addUnique = (arr, item) => {
    if (!arr.includes(item)) {
      return [...arr, item];
    } else return arr;
  };

  const handleRowsSelection = (newSelection) => {
    setRowsSelectedList(newSelection);
    setSelectedFilesList(newSelection);
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
    handleRowsSelection(
      addUnique(rowsSelectedList, event.currentTarget.getAttribute("data-id"))
    );
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };
  const handleClose = () => {
    setContextMenu(null);
  };

  const handleFileContextMenuItemClick = (actionValue) => {
    setSelectedFileAction(actionValue);
    //  handleRowContextSelection();
    handleClose();
  };
  const handleUploadContextMenuItemClick = (actionValue) => {
    setSelectedUploadAction(actionValue);
    //  handleRowContextSelection();
    handleClose();
  };

  const handleRowDoubleClick = (params, event, details) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedFilesList([params.id]);
    setSelectedFileAction("open_selection");
  };
  const handleCellClick = (params, event) => {
    if (params.field === "views" || params.field === "hasAiGenerated") {
      event.defaultMuiPrevented = true;
    }
  };
  const handlePaginationModelChange = (params) => {
    setPaginationModel(params);
  };

  const onlyDataroom =
    s3Files.filter(
      (f) =>
        !(
          (f.isDir && f.name === "Breakouts") ||
          (f.isDir && reservedFolderNames.includes(f.name))
        )
    ) ?? [];
  const sortedDataroom = sort(onlyDataroom).asc((f) => f.isDir);

  const selectedFiles = React.useMemo(() => {
    return s3Files.filter((f) => selectedFilesList.includes(f.id));
  }, [selectedFilesList, s3Files]);

  React.useEffect(() => {
    setNumOfFilesSelected(selectedFiles?.length);
    setIsItemSelectedAFolder(
      selectedFiles?.length === 1
        ? selectedFiles[0].isDir
          ? true
          : false
        : false
    );
  }, [selectedFiles]);

  const fileActionsList = React.useMemo(
    () =>
      fileActionListOptions({
        isItemSelected: false,
        isMobile: isMobile,
        filesPermissions,
        numOfFilesSelected: numOfFilesSelected,
        isItemSelectedAFolder: isItemSelectedAFolder,
      }),
    [filesPermissions, isMobile, numOfFilesSelected, isItemSelectedAFolder]
  );

  const uploadActionsList = React.useMemo(
    () =>
      uploadActionListOptions({
        isItemSelected: false,
        isMobile: isMobile,
        filesPermissions,
      }),
    []
  );

  const selectedFile = selectedFiles[0] ?? {};

  return (
    <FlowBox
      sx={{
        position: "relative",
        "& .trryst-files-theme--dir": {
          bgcolor: (theme) => alpha(theme.palette.grey[300], 0.4),
          "&:hover": {
            bgcolor: (theme) => alpha(theme.palette.grey[400], 0.4),
          },
        },
      }}
    >
      {allS3KeysStatus === "loading" ? (
        <LoadingSpinner />
      ) : onlyDataroom.length === 0 ? (
        <ShowEmptyFolderPanel />
      ) : (
        <DataGrid
          density="compact"
          keepNonExistentRowsSelected
          rows={sortedDataroom}
          columnBuffer={1}
          columnVisibilityModel={{
            id: false,
            createdBy: canAccessMembers,
            views: isSuiteManager,
          }}
          disableRowSelectionOnClick={true}
          checkboxSelection={checkIfActionsAllowedPath(folderChain)}
          rowSelectionModel={rowsSelectedList}
          onRowSelectionModelChange={handleRowsSelection}
          columns={columns}
          paginationModel={paginationModel}
          hideFooterPagination={onlyDataroom.length < paginationModel.pageSize}
          onPaginationModelChange={handlePaginationModelChange}
          pageSizeOptions={[25, 50, 100]}
          localeText={{
            noRowsLabel: "No files",
            footerRowSelected: (count) =>
              count !== 1
                ? `${count.toLocaleString()} files selected`
                : `${count.toLocaleString()} file selected`,
          }}
          experimentalFeatures={{ newEditingApi: true }}
          onRowDoubleClick={handleRowDoubleClick}
          onCellClick={handleCellClick}
          componentsProps={{
            row: {
              onContextMenu: handleContextMenu,
              onMouseEnter: (event) => {
                setHoveredRow(event.currentTarget.getAttribute("data-id"));
              },
              style: { cursor: "context-menu" },
            },
            footer: {
              onContextMenu: handleContextMenu,
              style: { cursor: "context-menu" },
            },
          }}
          getRowClassName={(params) => {
            return `trryst-files-theme--${params.row.isDir ? "dir" : "file"}`;
          }}
          sx={{
            boxShadow: 2,
            "& .MuiDataGrid-virtualScroller": {
              "&::-webkit-scrollbar": {
                width: 10,
              },

              "&::-webkit-scrollbar-track": {
                backgroundColor: "#f2f2f2",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: (theme) => theme.palette.gray.lighter,
                borderRadius: 2,
              },
            },
            "& .MuiDataGrid-scrollArea": {},
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
            ".MuiDataGrid-row": {
              fontSize: (theme) => theme.typography.body2.fontSize,
            },
            ".MuiDataGrid-columnHeader": {
              fontSize: (theme) => theme.typography.body1.fontSize,
            },
            "& .MuiDataGrid-footerContainer": {
              pr: 12,
            },
          }}
        />
      )}
      {checkIfActionsAllowedPath(folderChain) && (
        <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
          componentsProps={{
            root: {
              onContextMenu: (e) => {
                e.preventDefault();
                handleClose();
              },
            },
          }}
        >
          {fileActionsList.map((action) => (
            <MenuItem
              key={action.value}
              value={action.value}
              dense
              disabled={checkIfNull(selectedFilesList)}
              onClick={() => handleFileContextMenuItemClick(action.value)}
              sx={{ display: "flex" }}
            >
              <ListItemIcon
                sx={{
                  "&.Mui-selected": {
                    color: "red",
                  },
                }}
              >
                {action.icon && <action.icon fontSize="small" />}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body1">{action.label} </Typography>
                }
              />
            </MenuItem>
          ))}
          {uploadActionsList.map((action) => (
            <MenuItem
              key={action.value}
              value={action.value}
              dense
              onClick={() => handleUploadContextMenuItemClick(action.value)}
              sx={{ display: "flex" }}
            >
              <ListItemIcon
                sx={{
                  "&.Mui-selected": {
                    color: "red",
                  },
                }}
              >
                {action.icon && <action.icon fontSize="small" />}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body1">{action.label} </Typography>
                }
              />
            </MenuItem>
          ))}
        </Menu>
      )}
      {checkIfActionsAllowedPath(folderChain) && (
        <StyledSpeedDial
          ariaLabel="Trryst quick actions"
          hidden={!Boolean(uploadActionsList?.length)}
          FabProps={{ size: "small" }}
          icon={<SpeedDialIcon size="small" />}
          direction={"up"}
          color="secondary"
        >
          {uploadActionsList.map((action, index) => {
            return (
              <SpeedDialAction
                key={index}
                icon={action.icon ? <action.icon /> : <AddCircle />}
                tooltipTitle={action.label}
                onClick={() => handleUploadContextMenuItemClick(action.value)}
              />
            );
          })}
        </StyledSpeedDial>
      )}

      <Popper
        {...bindPopper(fileHistoryPopupState)}
        transition
        placement="bottom-end"
        sx={{ p: 2 }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={fileHistoryPopupState.close}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper
                sx={{
                  p: 1,
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    px: 2,
                  }}
                >
                  <Typography variant="subtitle2">File Analytics</Typography>
                  <IconButton
                    size="small"
                    onClick={fileHistoryPopupState.close}
                  >
                    <Close fontSize="inherit" />
                  </IconButton>
                </Box>
                <Divider />
                <FileHistoryAnalytics
                  fileKey={selectedFileHistoryKey}
                  handleClickAway={() => fileHistoryPopupState.close()}
                />
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
      {Boolean(selectedFilesList?.length && aiPromptDialogOpen) && (
        <FileAIPromptDialog
          selectedFile={selectedFile}
          onClose={handleAiDialogClose}
        />
      )}
    </FlowBox>
  );
}
