import React from 'react'
import { SvgIcon } from '@mui/material';

export default function ShareScreenIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M91.7352 51.876V66.6676C91.7352 79.1676 83.4018 87.5009 70.9018 87.5009H66.7352"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.40182 37.4995V33.3328C8.40182 20.8328 16.7352 12.4995 29.2352 12.4995H70.9018C83.4018 12.4995 91.7352 20.8328 91.7352 33.3328"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.447 48.793C34.697 51.2513 48.822 65.418 51.322 84.668"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.9873 62.7949C25.1123 64.5866 35.4874 75.0033 37.3207 89.1283"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.31807 78.5859C15.3597 79.5026 20.5681 84.6693 21.4848 91.7526"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
