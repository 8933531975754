import React from 'react'
import { SvgIcon } from "@mui/material";

export default function CallingIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M39.1252 25.0413C39.8752 26.083 40.4168 27.0413 40.7918 27.958C41.1668 28.833 41.3752 29.708 41.3752 30.4997C41.3752 31.4997 41.0835 32.4997 40.5002 33.458C39.9585 34.4163 39.1668 35.4163 38.1668 36.4163L35.0002 39.708C34.5418 40.1663 34.3335 40.708 34.3335 41.3747C34.3335 41.708 34.3752 41.9997 34.4585 42.333C34.5835 42.6663 34.7085 42.9163 34.7918 43.1663C35.5418 44.5413 36.8335 46.333 38.6668 48.4997C40.5418 50.6663 42.5418 52.8747 44.7085 55.083C46.9585 57.2913 49.1252 59.333 51.3335 61.208C53.5002 63.0413 55.2918 64.2913 56.7085 65.0413C56.9168 65.1247 57.1668 65.2497 57.4585 65.3747C57.7918 65.4997 58.1252 65.5413 58.5002 65.5413C59.2085 65.5413 59.7502 65.2913 60.2085 64.833L63.3752 61.708C64.4168 60.6663 65.4168 59.8747 66.3752 59.3747C67.3335 58.7913 68.2918 58.4997 69.3335 58.4997C70.1252 58.4997 70.9585 58.6663 71.8752 59.0413C72.7918 59.4163 73.7502 59.958 74.7918 60.6663L88.5835 70.458C89.6668 71.208 90.4168 72.083 90.8752 73.1247C91.2918 74.1663 91.5418 75.208 91.5418 76.3747C91.5418 77.8747 91.2085 79.4163 90.5002 80.9163C89.7918 82.4163 88.8752 83.833 87.6668 85.1663C85.6252 87.4163 83.3752 89.0413 80.8335 90.083C78.3335 91.1247 75.6252 91.6663 72.7085 91.6663C68.4585 91.6663 63.9168 90.6663 59.1252 88.6247C54.3335 86.583 49.5418 83.833 44.7918 80.3747C40.0002 76.8747 35.4585 72.9997 31.1252 68.708C26.8335 64.3747 22.9585 59.833 19.5002 55.083C16.0835 50.333 13.3335 45.583 11.3335 40.8747C9.3335 36.1247 8.3335 31.583 8.3335 27.2497C8.3335 24.4163 8.8335 21.708 9.8335 19.208C10.8335 16.6663 12.4168 14.333 14.6252 12.2497C17.2918 9.62467 20.2085 8.33301 23.2918 8.33301C24.4585 8.33301 25.6252 8.58301 26.6668 9.08301C27.7502 9.58301 28.7085 10.333 29.4585 11.4163"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M77.0833 37.5003C77.0833 35.0003 75.125 31.167 72.2083 28.042C69.5417 25.167 66 22.917 62.5 22.917"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M70.875 9.54134C68.2083 8.74967 65.4167 8.33301 62.5 8.33301"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M91.6669 37.5005C91.6669 27.6255 86.7502 18.8755 79.2502 13.6255"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
