import React from "react";
import { Box, Typography, IconButton, ClickAwayListener } from "@mui/material";
import { FlowBox } from "blocks/atoms/uistyles";
import { LoadingSpinner } from "blocks/atoms";
import { useAppContext } from "@app21/core";
import { useSurveysContext } from "providers/SurveysProvider";
import ShowEmptySurveysComponent from "./ShowEmptySurveysComponent";
import {
  SimpleSurveyBuilder,
  SimpleSurveyResponse,
} from "blocks/modules/Surveys/features";
import { useAtom } from "jotai";
import { centerpanelContextAtom } from "blocks/modules/Surveys/surveys-atoms";
import {
  SurveyResponseAnalyticsTabPanel,
  ShowDocketResolutionsList,
} from "blocks/modules/Surveys/foundations";

export default function SurveyDetailsPanel({}) {
  const [centerpanelContext, setCenterpanelContext] = useAtom(
    centerpanelContextAtom
  );
  const {
    isMobile,
    isDocket,
    viewContext,
    actionContext,
    selectedDecisionId,
    selectedDecision,
    decisionsData,
    resolutionsInSuiteStatus,
  } = useSurveysContext();

  React.useEffect(() => {
    if (selectedDecisionId) {
      if (
        selectedDecision?.userResponseStatus === "completed" ||
        !selectedDecision?.userIsInvited
      ) {
        setCenterpanelContext("results");
      }
    }
  }, [selectedDecision]);

  if (resolutionsInSuiteStatus === "loading") {
    return <LoadingSpinner />;
  }

  const ShowSelectASurveyComponent = () => {
    if (isDocket) return <ShowDocketResolutionsList />;
    return (
      <FlowBox sx={{ p: 2, justifyContent: "center", alignItems: "center" }}>
        <Typography variant="h6">Select a survey to view details</Typography>
      </FlowBox>
    );
  };

  if (decisionsData?.length === 0 && actionContext !== "add") {
    return (
      <FlowBox sx={{ p: 2 }}>
        <ShowEmptySurveysComponent />
      </FlowBox>
    );
  }

  switch (actionContext) {
    case "resolutions":
      return !selectedDecisionId ? (
        <ShowSelectASurveyComponent />
      ) : (
        <SurveyResponseAnalyticsTabPanel />
      );
    case "list":
      return !selectedDecisionId ? (
        <ShowSelectASurveyComponent />
      ) : (
        <SurveyResponseAnalyticsTabPanel />
      );
    case "view":
      return !selectedDecisionId ? (
        <ShowSelectASurveyComponent />
      ) : (
        <SurveyResponseAnalyticsTabPanel />
      );
    case "view-resolution":
      return !selectedDecisionId ? (
        <ShowSelectASurveyComponent />
      ) : (
        <SurveyResponseAnalyticsTabPanel />
      );
    case "edit":
      return !selectedDecisionId ? (
        <ShowSelectASurveyComponent />
      ) : (
        <SimpleSurveyBuilder />
      );
    case "edit-resolution":
      return !selectedDecisionId ? (
        <ShowSelectASurveyComponent />
      ) : (
        <SimpleSurveyBuilder />
      );
    case "add":
      return <SimpleSurveyBuilder />;
    case "add-resolution":
      return <SimpleSurveyBuilder />;
    case "respond":
      return !selectedDecisionId ? (
        <ShowSelectASurveyComponent />
      ) : (
        <SimpleSurveyResponse />
      );
    case "respond-resolution":
      return !selectedDecisionId ? (
        <ShowSelectASurveyComponent />
      ) : (
        <SimpleSurveyResponse />
      );
    default:
      return <SurveyResponseAnalyticsTabPanel />;
  }
}
