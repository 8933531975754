import React from "react";
import { Box } from "@mui/material";
// write a function for showing material ui tabs here
import {
  StyledRoundedTabs,
  StyledRoundedTab,
  StyledRoundedTabList,
} from "blocks/atoms/uistyles";
import SelectMoreChatContextsDropdown from "./SelectMoreChatContextsDropdown";
import { useChatContext } from "providers/TrrystChatProvider";
import { baseMenuOptions } from "./ContextMenuOptions";

export default function SelectChatSidebarContext() {
  const { viewChatContext, handleChangeViewContext } = useChatContext();

  return (
    <Box sx={{ display: "flex", flexGrow: 1, alignItems: "stretch" }}>
      <StyledRoundedTabs
        value={viewChatContext}
        onChange={(event, value) => {
          handleChangeViewContext(value);
        }}
        selectionFollowsFocus
      >
        <StyledRoundedTabList>
          {baseMenuOptions.map((option, index) => (
            <StyledRoundedTab
              key={index}
              value={option.value}
              textColor="secondary"
            >
              {option.label}
            </StyledRoundedTab>
          ))}
        </StyledRoundedTabList>
      </StyledRoundedTabs>
      <Box sx={{ flexGrow: 1 }} />
      <SelectMoreChatContextsDropdown />
    </Box>
  );
}
