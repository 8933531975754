import React, { useEffect, useRef } from "react";

import {
  useContentShareState,
  useAudioVideo,
  VideoTile,
  Content,
} from "amazon-chime-sdk-component-library-react";
import { Box, Avatar, Typography } from "@mui/material";
import { checkIfNull } from "@app21/core";

export default function CustomScreenShareTile({ tileInfo, pipRef }) {
  const { isLocalUserSharing } = useContentShareState();
  const audioVideo = useAudioVideo();
  const { tileId } = useContentShareState();
  const contentVideoEl = useRef(null);

  useEffect(() => {
    if (!audioVideo || !tileId) {
      return;
    }

    audioVideo.bindVideoElement(
      tileId,
      checkIfNull(pipRef?.current) ? contentVideoEl.current : pipRef.current
    );

    return () => {
      const tile = audioVideo.getVideoTile(tileId);
      if (tile) {
        audioVideo.unbindVideoElement(tileId);
      }
    };
  }, [audioVideo, tileId]);

  if (isLocalUserSharing) {
    return (
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "block",

            height: "50%",
            width: "50%",
            opacity: 0.5,
          }}
        >
          <Typography
            variant="h6"
            sx={{ textAlign: "center", fontWeight: "bold" }}
          >
            You are sharing your screen.
          </Typography>
          {tileId ? (
            <VideoTile
              objectFit="contain"
              ref={checkIfNull(pipRef) ? contentVideoEl : pipRef}
            />
          ) : null}
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          position: "absolute",
          top: 5,
          left: 5,
          zIndex: 100,
        }}
      >
        <Avatar
          src={tileInfo?.image?.url}
          alt={tileInfo?.name}
          sx={{ height: 20, width: 20, mr: 1 }}
        />
        <Typography variant="caption" color="secondary">
          {tileInfo?.name} is sharing screen
        </Typography>
      </Box>
      {tileId ? (
        <VideoTile
          objectFit="contain"
          ref={checkIfNull(pipRef) ? contentVideoEl : pipRef}
        />
      ) : null}
    </Box>
  );
}
