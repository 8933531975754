// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  Box,
  Paper,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableContainer,
  TableBody,
  Avatar,
  Card,
  CardHeader,
  CardContent,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { FlowBox } from "blocks/atoms/uistyles";
import ShowSurveyBarChart from "blocks/modules/Surveys/foundations/SurveyCharts/ShowSurveyBarChart";
import processSurveyData from "blocks/modules/Surveys/foundations/processData/processSurveyData";
import {
  getS3ImageWrapper,
  checkIfNull,
  useUsersInSuite,
  useResolution,
  useAppContext,
} from "@app21/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import LoadingSpinner from "blocks/atoms/LoadingSpinner";
import { useIsMobileBreakpoint } from "hooks";
import { PieChart, ViewList } from "@mui/icons-material";
import {
  ShowSurveyResultsListMode,
  ShowSurveyResultsChartMode,
} from "blocks/modules/Surveys/foundations";

function getStatusText(voteStatus, individualStatus) {
  switch (voteStatus) {
    case "Not Opened":
      return "Not open for vote";
    case "Open":
      if (individualStatus) {
        return "Completed";
      } else {
        return "Pending";
      }
    case "Closed":
      if (individualStatus) {
        return "Completed";
      } else {
        return "No Response";
      }
    default: {
      return "Unknown";
    }
  }
}

const SurveyResponseAnalytics = ({ resolution, resolutionId }) => {
  const { selectedSuiteId } = useAppContext();
  const [viewAnalyticsMode, setViewAnalyticsMode] = React.useState("graph");

  const { data: usersInSuite } = useUsersInSuite(selectedSuiteId);
  const currentDate = new Date().getTime();
  const selectedDecision = resolution ?? {};
  const isMobile = useIsMobileBreakpoint();

  const handleViewAnalyticsMode = (event, newViewAnalyticsMode) => {
    setViewAnalyticsMode(newViewAnalyticsMode);
  };

  const ShowSurveyHeader = () => {
    return (
      <Box>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            color: "text.secondary",
          }}
        >
          {selectedDecision?.survey?.decisionName}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",

            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              color: (theme) => theme.palette.text.primary,
            }}
          >
            Responses -
            {Object.values(selectedDecision?.responses ?? {})?.length} /
            {selectedDecision?.survey?.decisionInvitees.length}
          </Typography>
          <ToggleButtonGroup
            size="small"
            value={viewAnalyticsMode}
            exclusive
            onChange={handleViewAnalyticsMode}
            aria-label="view results mode"
          >
            <ToggleButton
              value="graph"
              aria-label="Graph mode"
              sx={{
                "&.Mui-selected": {},
                "& .MuiButtonBase-root": {
                  "&: hover": {
                    backgroundColor: "#fafafa",
                  },
                  bgcolor:
                    viewAnalyticsMode === "graph"
                      ? "background.paper"
                      : "gray.lighter",
                },
              }}
            >
              <PieChart />
            </ToggleButton>
            <ToggleButton
              value="list"
              aria-label="List Mode"
              sx={{
                "&.Mui-selected": {},
                "& .MuiButtonBase-root": {
                  "&: hover": {
                    backgroundColor: "#fafafa",
                  },
                  bgcolor:
                    viewAnalyticsMode === "list"
                      ? "background.paper"
                      : "gray.lighter",
                },
              }}
            >
              <ViewList />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>
    );
  };

  return (
    <FlowBox
      sx={{
        mt: 1,
      }}
    >
      <FlowBox>
        {checkIfNull(selectedDecision?.survey?.decisionInvitees) ? (
          <Typography
            variant="body1"
            style={{ textAlign: "center", padding: 10 }}
          >
            No Invitees for the Resolution
          </Typography>
        ) : (
          <FlowBox
            style={{
              width: "100%",
              display: "flex",

              padding: (theme) => theme.spacing(0),
              justifyContent: "flex-start",
            }}
          >
            <ShowSurveyHeader selectedDecision={selectedDecision} />
            {viewAnalyticsMode === "list" ? (
              <ShowSurveyResultsListMode selectedDecision={selectedDecision} />
            ) : (
              <ShowSurveyResultsChartMode selectedDecision={selectedDecision} />
            )}
          </FlowBox>
        )}
      </FlowBox>
    </FlowBox>
  );
};

export default SurveyResponseAnalytics;
