// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  useAppContext,
  useAllOrganizations,
  useOrgsAndSuitesForUser,
  filterOrgsBasedOnAdmin,
  getS3ImageWrapper,
  useHandleOrganizationActions,
  useHandleManageImage,
} from "@app21/core";
// import { LoadingSpinner } from 'blocks/atoms';
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Grid,
  Autocomplete,
  IconButton,
  CircularProgress,
} from "@mui/material";

import { useForm, Controller } from "react-hook-form";
import { useTheme } from "@mui/material/styles";
import EditableImage from "blocks/atoms/EditableImage";
import { useRouter } from "next/router";
import ObjectID from "bson-objectid";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";

import RestartAltIcon from "@mui/icons-material/RestartAlt";

export function createOrgSelectOptions(props) {
  let optionsArray = [];

  if (props && Array.isArray(props)) {
    props.forEach((d) =>
      optionsArray.push({
        _id: d._id,
        value: d._id,
        label: d?.fullName,
        avatar: getS3ImageWrapper(d?.image, "initials", d?.fullName),
      })
    );
    optionsArray.push({
      _id: "ADD_NEW_ORG",
      value: "ADD_NEW_ORG",
      label: "Create new organization",
      avatar: "",
    });
  }
  return optionsArray;
}

const UpgradeUser = () => {
  const theme = useTheme();
  const router = useRouter();
  const { userInfo } = useAppContext();
  const { data: organizations } = useAllOrganizations(userInfo._id);
  const { data: orgsAndSuites } = useOrgsAndSuitesForUser(userInfo._id);

  const [loadingIndicatorStatus, setLoadingIndicatorStatus] =
    React.useState(null);

  const {
    handleOrganizationActions,
    addOrganizationStatus,
    addSuiteToOrganizationStatus,
    addSuiteToOrganizationData,
  } = useHandleOrganizationActions();
  const { handleManageImage } = useHandleManageImage();

  const filteredOrgSet =
    (userInfo?.role?.toUpperCase() === "GLOBALADMIN" ||
    userInfo?.role?.toUpperCase() === "TRRYSTADMIN"
      ? organizations?.filter((org) => org.orgType !== "PERSONAL")
      : filterOrgsBasedOnAdmin(orgsAndSuites, userInfo._id)?.filter(
          (org) => org.orgType !== "PERSONAL"
        )) ?? [];

  const [selectedOrganizationId, setSelectedOrganizationId] =
    React.useState("ADD_NEW_ORG");
  const orgOptions = createOrgSelectOptions(filteredOrgSet);

  const subsChoice = [
    {
      label: "Boardroom Suite",
      value: "BOARD-SUITE-BASIC",
      description: "Best Suited for Boardroom or Committee interactions",
      price: "$30",
    },
    {
      label: "Executive Suite",
      value: "BOARD-SUITE-PRO",
      description: "General purpose suite for your Executive conversations",
      price: "$40",
    },
    {
      label: "EXEC-SUITE-BASIC",
      value: "EXEC-SUITE-BASIC",
      description:
        "Virtual Datarooms for your RFP, Due Diligence, M&A and other usecases",
      price: "$50",
    },
  ];

  const { handleSubmit, control, setValue, reset } = useForm({
    mode: "onChange",
    defaultValues: {
      organizationName: "",
      organizationImage: null,
      organizationId: null,
      suiteName: "",
      suiteImage: null,
      subscriptionChoice: "",
      subscriptionPrice: 0,
      promocode: "",
    },
  });

  React.useEffect(() => {
    if (
      addSuiteToOrganizationStatus &&
      addSuiteToOrganizationStatus.isSuccess
    ) {
      if (addSuiteToOrganizationData) {
        if (addSuiteToOrganizationData.data.paymentURL) {
          // Redirect user to payment screen
          window.location.href = addSuiteToOrganizationData.data.paymentURL;
        }
      } else {
        router.push(`/user`);
      }
    }
  }, [addSuiteToOrganizationData, addSuiteToOrganizationStatus, router]);

  const onSubmit = async (newData) => {
    setLoadingIndicatorStatus("Creating Organization");
    const orgId = newData.organizationId
      ? newData.organizationId
      : ObjectID().toString();
    if (!newData.organizationId) {
      // upload organizationImage
      const orgImageResult = await handleManageImage(
        {
          files: [newData.organizationImage],
          imageType: "ORG-IMAGE",
          actionType: "ADDNEW",
          orgId: orgId,
        },
        null,
        null
      );
      // Create an organization
      await handleOrganizationActions(
        {
          action: "ADD-ORGANIZATION",
          inputData: {
            data: {
              _id: orgId,
              image: orgImageResult?.data?.key || null,
              fullName: newData.organizationName,
              createdBy: userInfo._id,
            },
          },
        },
        null,
        null
      );
    }
    setLoadingIndicatorStatus("Creating Suite");
    const suiteImageResult = await handleManageImage(
      {
        files: [newData.suiteImage],
        imageType: "SUITE-IMAGE",
        actionType: "ADDNEW",
        orgId: orgId,
      },
      null,
      null
    );
    // Create a suite
    const suiteId = ObjectID().toString();
    await handleOrganizationActions(
      {
        action: "ADD-SUITE",
        suiteData: {
          organizationId: orgId,
          data: {
            _id: suiteId,
            image: suiteImageResult?.data?.key || null,
            fullName: newData.suiteName,
            createdBy: userInfo._id,
            suiteType: newData.suiteType,
            notifyUsers: false,
            subscriptionData: {
              userId: userInfo._id,
              couponCodes: [],
              app21CouponCode: newData.promocode,
              priceId: "",
            },
          },
        },
        adminUsers: [{ userId: userInfo._id, role: "MANAGER" }],
      },
      null,
      null
    );
    setLoadingIndicatorStatus("Waiting for response");
  };

  const handleOrgSelect = (selOrganization) => {
    setSelectedOrganizationId(selOrganization?._id);
    if (selOrganization?._id == "ADD_NEW_ORG") {
      reset();
    } else {
      setValue("organizationName", selOrganization?.label);
      setValue("organizationImage", selOrganization?.avatar);
      setValue("organizationId", selOrganization._id);
    }
  };

  const clearOrgField = () => {
    setSelectedOrganizationId(null);
    reset();
  };

  return (
    <div>
      <Dialog open={loadingIndicatorStatus !== null}>
        <DialogTitle>Please Wait...</DialogTitle>
        <Box
          sx={{
            background: "#fff",
            m: 5,
            p: 2,
            borderRadius: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box>
            <CircularProgress />
            <Typography sx={{ marginTop: "5px" }}>
              {loadingIndicatorStatus}
            </Typography>
          </Box>
        </Box>
      </Dialog>
      {filteredOrgSet.length > 0 ? (
        <Box sx={{ mb: 2 }}>
          <Typography gutterBottom variant="h5" component="div" sx={{ my: 2 }}>
            Select organization you wish to create a suite in
          </Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Autocomplete
              sx={{ width: 600, mr: 5 }}
              value={selectedOrganizationId}
              options={orgOptions}
              autoHighlight
              disableClearable
              onChange={(event, newValue) => {
                handleOrgSelect(newValue);
              }}
              getOptionLabel={(option) => {
                return (
                  option?.label ??
                  orgOptions?.find((org) => org._id === option)?.label ??
                  ""
                );
              }}
              isOptionEqualToValue={(option, value) => {
                return option._id === value;
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img loading="lazy" width="25" src={option.avatar} alt="" />
                  {option.label}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Organization"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />
            <Button
              variant="outlined"
              endIcon={<RestartAltIcon />}
              onClick={clearOrgField}
            >
              Reset
            </Button>
          </div>
          <Typography gutterBottom variant="h6" component="div" sx={{ my: 2 }}>
            You can create a new organization by filling below form
          </Typography>
        </Box>
      ) : (
        <Typography gutterBottom variant="h5" component="div" sx={{ my: 2 }}>
          You do not have admin privileges in any organization you are a part
          of, Create a new organization
        </Typography>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper variant="outlined">
          <Box
            sx={{
              padding: (theme) => theme.spacing(3),
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: "space-around",
              [theme.breakpoints.down("lg")]: {
                flexDirection: "column-reverse",
                alignItems: "flex-start",
              },
            }}
          >
            <Grid container spacing={6} wrap="wrap">
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Controller
                  name="organizationName"
                  control={control}
                  render={({ field }) => {
                    return (
                      <TextField
                        fullWidth
                        required
                        id="outlined-required"
                        label="Organization Name"
                        onChange={(event, newValue) => {
                          field.onChange(event.target.value);
                        }}
                        value={field.value}
                        disabled={selectedOrganizationId ? true : false}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid container item lg={6} md={6} sm={12} xs={12}>
                <Controller
                  name="suiteName"
                  control={control}
                  render={({ field }) => {
                    return (
                      <TextField
                        fullWidth
                        required
                        id="outlined-required"
                        label="Name of the suite"
                        onChange={(event, newValue) => {
                          field.onChange(event.target.value);
                        }}
                        value={field.value}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="h6" sx={{ ml: 20, mb: 2 }}>
                  Organization Image
                </Typography>
                <Controller
                  control={control}
                  name="organizationImage"
                  render={({ field }) => {
                    return (
                      <EditableImage
                        labelText="Upload Picture for org"
                        currentImage={field.value}
                        handleFileUpload={field.onChange}
                        disableChange={selectedOrganizationId ? true : false}
                        imageProps={{ sx: { height: 100, width: 100 } }}
                        sx={{ height: 130, width: 130 }}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="h6" sx={{ ml: 20, mb: 2 }}>
                  Suite Image
                </Typography>
                <Controller
                  control={control}
                  name="suiteImage"
                  render={({ field }) => {
                    return (
                      <EditableImage
                        labelText="Upload Picture for Suite"
                        currentImage={field.value}
                        handleFileUpload={field.onChange}
                        imageProps={{ sx: { height: 100, width: 100 } }}
                        sx={{ height: 130, width: 130 }}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid container item lg={12} md={12} sm={12} xs={12}>
                <Controller
                  name="subscriptionChoice"
                  control={control}
                  render={({ field }) => {
                    return (
                      <TextField
                        fullWidth
                        id="Subscription_Choice"
                        select
                        SelectProps={{ native: true }}
                        label="Select Subscription Choise"
                        margin="normal"
                        name="subscriptionChoice"
                        size="large"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(event, newValue) => {
                          field.onChange(event.target.value);
                          setValue(
                            "subscriptionPrice",
                            subsChoice[
                              event.nativeEvent.target.selectedIndex - 1
                            ].price
                          );
                          setValue(
                            "suiteType",
                            subsChoice[
                              event.nativeEvent.target.selectedIndex - 1
                            ].value
                          );
                        }}
                        value={field.value}
                      >
                        <option>Select Subscription Choice</option>
                        {subsChoice.map((option, index) => (
                          <option value={option.value} key={index}>
                            {option.label} {" ["} {option.description} {" ]"}
                          </option>
                        ))}
                      </TextField>
                    );
                  }}
                />
              </Grid>
              <Grid container item lg={3} md={3} sm={12} xs={12}>
                <Controller
                  name="subscriptionPrice"
                  control={control}
                  render={({ field }) => {
                    return (
                      <TextField
                        fullWidth
                        id="outlined-required"
                        label="Subscription Price"
                        defaultValue={"$100"}
                        value={field.value}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid container item lg={6} md={6} sm={12} xs={12}>
                <Controller
                  name="promocode"
                  control={control}
                  render={({ field }) => {
                    return (
                      <TextField
                        fullWidth
                        id="outlined-required"
                        label="Enter Promocode"
                        onChange={(event, newValue) => {
                          field.onChange(event.target.value);
                        }}
                        value={field.value}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid container item lg={3} md={3} sm={12} xs={12}>
                <Button variant="contained">Apply</Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <Button
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          sx={{ m: 1 }}
        >
          Create
        </Button>
      </form>
    </div>
  );
};

export default UpgradeUser;
