import React from "react";
import { Box, Paper, IconButton, Typography } from "@mui/material";
import { ReadMore, Close } from "@mui/icons-material";
import { FlowBox } from "blocks/atoms/uistyles";
import { showSidebarFlagAtom } from "providers/LayoutProvider/layout-atoms";
import { useAtom } from "jotai";

export default function ModulesTitleComponent({
  sidebarActive = false,
  TitleComponent = <></>,
  width = "20%",
  HeaderComponent = <></>,
  centralTitleBarHeight = 40,
  sx = {},
}) {
  const [showSidebarFlag, setShowSidebarFlag] = useAtom(showSidebarFlagAtom);
  const handleSidebarToggle = (ev, flag) => {
    ev.preventDefault();
    ev.stopPropagation();
    if (typeof flag === "boolean") {
      setShowSidebarFlag(flag);
    } else {
      setShowSidebarFlag((current) => !current);
    }
  };
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        height: centralTitleBarHeight,
        zIndex: 2,
        ...sx,
      }}
    >
      {sidebarActive && (
        <IconButton sx={{ zIndex: 4 }} onClick={handleSidebarToggle}>
          {showSidebarFlag ? <Close /> : <ReadMore />}
        </IconButton>
      )}

      {!sidebarActive && <Box sx={{ width: width }}>{TitleComponent}</Box>}
      <Box sx={{ flexGrow: 1 }}>{HeaderComponent}</Box>
    </Box>
  );
}
