import React from "react";
import {
  styled,
  Typography,
  ListItem,
  List,
  ListItemAvatar,
  ListItemText,
  Box,
} from "@mui/material";
import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from "material-ui-popup-state/hooks";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import MessageMenuPopover from "./MessageMenuPopover";
import FileMessageCard from "./FileMessageCard";

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 320,
  minWidth: 30,
  width: "fit-content",
  padding: theme.spacing(1.5),
  marginTop: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  color: "#727287",
  backgroundColor: "#FFF3E4",
}));

export default function RecivedMessageCard({ message }) {
  let chatMessage = message?.message ?? {};
  let fileKey = message?.message?.customElements?.s3Key;
  const [showOptions, setShowOptions] = React.useState(false);
  const messageCardPopupState = usePopupState({
    variant: "popover",
    popupId: "message-card-popover",
  });
 
  return (
    <List
      onMouseEnter={() => setShowOptions(true)}
      onMouseLeave={() => setShowOptions(false)}
    >
      <ListItem>
       
        <ListItemText
          primary={
            <Box sx={{ display: "flex" }}>
              {fileKey ? (
                <FileMessageCard
                  fileKey={fileKey}
                  fileName={chatMessage.text}
                  bgColor="#FFF3E4"
                />
              ) : (
                <ContentStyle>
                  <Typography variant="body2">{chatMessage.text}</Typography>
                </ContentStyle>
              )}
              <div>
                {showOptions && (
                  <MoreHorizOutlinedIcon
                    {...bindTrigger(messageCardPopupState)}
                    sx={{ width: "1rem", height: "1rem" }}
                  />
                )}
                <MessageMenuPopover
                  bindPopover={bindPopover}
                  messageCardPopupState={messageCardPopupState}
                />
              </div>
            </Box>
          }
         
        />
      </ListItem>
    </List>
  );
}
