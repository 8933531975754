import { atom } from "jotai";

export const showSurveysSidebarFlagAtom = atom(false);
export const centerpanelContextAtom = atom("questions");
export const selectedSurveyActionAtom = atom("");
export const selectedUploadActionAtom = atom("");
export const selectedSurveysListAtom = atom([]);
export const viewSurveysFilterContextAtom = atom([]);
export const selectedDocketsInSurveysFilterAtom = atom([]);
export const selectedStatusInSurveysFilterAtom = atom([]);
export const selectedSurveysDateRangeAtom = atom({});
