import { SvgIcon } from "@mui/material";
import React from "react";
export default function MoreChoiceWebInactiveIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 56 56"
      stroke="#727287"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <rect
          width="56"
          height="56"
          rx="4.8"
          stroke="none"
          fill="currentColor"
        />
        <path
          d="M13.998 29.342V32.198C13.998 39.198 16.798 41.998 23.798 41.998H32.198C39.198 41.998 41.998 39.198 41.998 32.198V23.798C41.998 16.798 39.198 13.998 32.198 13.998H23.798C16.798 13.998 13.998 16.798 13.998 23.798"
          strokeWidth="2.04167"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.5937 28.0008H33.6063"
          strokeWidth="2.72222"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.9882 28.0008H28.0008"
          strokeWidth="2.72222"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.392 28.0008H22.4046"
          strokeWidth="2.72222"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
