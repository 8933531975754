// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Card } from "@mui/material";
import {
  useWorkerParser,
  usePlayerState,
  usePlayback,
  Canvas,
} from "@react-gifs/tools";

const CustomGifRenderer = ({ mainState: { currentDocument } }) => {
  // default state

  const [state, update] = usePlayerState();
  //  load and parse gif
  useWorkerParser(currentDocument.uri, update);

  // updates current index
  usePlayback(state, () => update(({ index }) => ({ index: index + 1 })));

  // render frames
  if (!currentDocument) return null;

  return (
    <Card
      sx={{
        display: "flex",
        p: 1,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        flexGrow: 1,
        height: "100%",
        width: "100%",
      }}
    >
      <Canvas {...state} style={{ width: "100%" }} />
    </Card>
  );
};

CustomGifRenderer.fileTypes = ["gif", "image/gif"];
CustomGifRenderer.weight = 1;

export default CustomGifRenderer;
