import {styled} from "@mui/material";

const StyledDot = styled("span", {
  shouldForwardProp: (prop) => prop !== "size" && prop !== "color",
})(({ theme, size = 5, color }) =>  ({
height: size,
  width: size,
  backgroundColor: color||theme.palette.primary.main,
  borderRadius: '50%'
  
}))

export default StyledDot