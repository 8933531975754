import PerfectScrollbar from "react-perfect-scrollbar";
import React, { useState, Fragment } from "react";
import { Box, Fab } from "@mui/material";
import { KeyboardArrowUp } from "@mui/icons-material";
import Page from "blocks/views/Layouts/Page";
import { styled } from "@mui/material/styles";
import { LAYOUTWIDTHS } from "blocks/atoms/constants";
import { LandingMainTopbar } from "blocks/views/Layouts/LandingPageLayout";
import { GenericTopbar } from "blocks/views/Layouts";
import { useSettingsContext } from "providers/SettingsProvider";
//import { animateScroll } from 'react-scroll';

const ContainerDiv = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  overflowX: "hidden",
  height: "100vh",
  maxHeight: "100vh",
  width: "100%",
  maxWidth: "100vw",
  position: "relative",
}));

const TopbarStyleBox = styled(Box)(() => ({
  height: LAYOUTWIDTHS.TopbarHeight,
  maxHeight: LAYOUTWIDTHS.TopbarHeight,
  overflow: "hidden",
  flexGrow: 0,
  flexShrink: 0,
}));

export default function CombinedBaseLayout(props) {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const pageRef = React.useRef();
  const { scrollY, setScrollY } = useSettingsContext();

  const {
    children,
    quickActions = false,
    showPathCrumbs = false,
    quickActionsList = [],
    viewContext = "home",
    pageTitle = "Trryst",
    ...rest
  } = props;

  const getLayout = (viewContext) => {
    switch (viewContext) {
      case "home":
        return [LandingMainTopbar];
      case "generic":
        return [GenericTopbar];
      default:
        return [LandingMainTopbar];
    }
  };

  const LayoutTopbar = getLayout(viewContext)[0] || Fragment;

  const handleScrollToTop = () => {
    const curr = pageRef.current;
    if (curr) {
      curr.scrollTop = 0;
    }
  };

  return (
    <Page title={pageTitle} {...rest}>
      <ContainerDiv id="landing-page">
        <PerfectScrollbar
          containerRef={(el) => (pageRef.current = el)}
          onScrollY={(container) => setScrollY(container.scrollTop)}
          options={{ suppressScrollX: true }}
          style={{
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            flexGrow: 1,
          }}
        >
          <TopbarStyleBox sx={{ displayPrint: "none !important" }}>
            <LayoutTopbar
              onMobileNavOpen={() => setMobileNavOpen(true)}
              showPathCrumbs={showPathCrumbs}
              viewContext={viewContext}
              scrollY={scrollY}
            />
          </TopbarStyleBox>
          {children}
        </PerfectScrollbar>
        {scrollY > 500 && (
          <Fab
            size="small"
            aria-label="scroll back to top"
            sx={{ position: "fixed", bottom: "2rem", right: "2rem" }}
            onClick={handleScrollToTop}
          >
            <KeyboardArrowUp />
          </Fab>
        )}
      </ContainerDiv>
    </Page>
  );
}
