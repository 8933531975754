import React from "react";
import {
  Box,
  Typography,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CardActionArea,
  alpha,
  IconButon,
} from "@mui/material";
import { useSurveysContext } from "providers/SurveysProvider";
import { useAppContext } from "@app21/core";
import {
  CollapseArrowIcon,
  DurationIcon,
  UserIcon,
  SelectActiveIcon,
  SurveyFinishedIcon,
  SurveyIcon,
  ArrowRightIcon,
} from "blocks/atoms/trrysticons";
import {
  Done,
  AccessTime,
  AssignmentTurnedIn,
  Edit,
  DeleteForever,
  Assignment,
} from "@mui/icons-material";
import { UserAvatar, PrintUserInfo } from "blocks/atoms";
import clip from "text-clipper";
import { MoreActionsDropdownMenu } from "blocks/atoms/uicomponents";
import { surveysModuleTestIds } from "blocks/atoms/constants";

export default function DisplaySidebarSurveyCard({ surveyObj, ...props }) {
  const {
    isDocket,
    isMobile,
    loadRoute,
    decisionsData,
    selectedDecisionId,
    surveysPermissions,
    handleDeleteSurvey,
    handleSurveyStatusChange,
  } = useSurveysContext();

  const { userInfo } = useAppContext();
  const isCardActive = surveyObj._id === selectedDecisionId;
  const isSurveyClosed = surveyObj?.survey?.decisionStatus === "CLOSED";
  const handleCardClick = (event) => {
    event.preventDefault();
    if (isDocket) {
      loadRoute("VIEW-RESOLUTION", { decisionId: surveyObj._id });
    } else {
      loadRoute("VIEW-SURVEY", { decisionId: surveyObj._id });
    }
  };

  const handleSurveyEdit = () => {
    loadRoute("EDIT-SURVEY", { decisionId: surveyObj._id });
  };

  const menuOptions = [
    ...(surveysPermissions?.checkCanEdit(surveyObj) && !isSurveyClosed
      ? [{ label: "Edit", icon: <Edit />, onClick: handleSurveyEdit }]
      : []),
    ...(surveysPermissions?.checkCanDelete(surveyObj)
      ? [
          {
            label: "Delete",
            icon: <DeleteForever />,
            onClick: () => handleDeleteSurvey(surveyObj),
          },
        ]
      : []),
    ...(surveysPermissions?.checkCanEdit(surveyObj)
      ? surveyObj.survey.decisionStatus !== "CLOSED"
        ? [
            {
              label: "Close Survey",
              icon: <AssignmentTurnedIn />,
              onClick: () => handleSurveyStatusChange("CLOSED", surveyObj),
            },
          ]
        : [
            {
              label: "Re-open Survey",
              icon: <Assignment />,
              onClick: () => handleSurveyStatusChange("OPEN", surveyObj),
            },
          ]
      : []),
  ];

  return (
    <Card
      data-testid={surveysModuleTestIds.individualSurveyDetails}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        p: 0,
        gap: 1,
        my: 1,
        backgroundColor: (theme) =>
          isCardActive
            ? alpha(theme.palette.primary.lighter, 0.1)
            : theme.palette.background.paper,
      }}
      {...props}
    >
      <CardHeader
        sx={{ m: 0 }}
        avatar={<DurationIcon />}
        title={
          <Typography variant="caption" color="text.secondary">
            {surveyObj?.survey?.decisionStatus}
          </Typography>
        }
        disableTypography
        action={
          menuOptions.length > 0 ? (
            <MoreActionsDropdownMenu
              menuOptions={menuOptions}
              tooltipLabel="More Survey Options"
            />
          ) : null
        }
      />
      <CardActionArea onClick={handleCardClick}>
        <CardContent
          data-testid={surveysModuleTestIds.surveyDetails}
          sx={{
            pt: 0.5,
            m: 0,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant={isCardActive ? "h6" : "subtitle1"}
            color={isCardActive ? "text.primary" : "text.secondary"}
          >
            {clip(surveyObj?.survey?.decisionName, 100)}
          </Typography>
          <ArrowRightIcon sx={{ color: "text.secondary" }} />
        </CardContent>

        <CardActions
          sx={{
            pt: 0,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",

            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <SelectActiveIcon />
            <Typography
              variant="caption"
              color={isCardActive ? "text.primary" : "text.secondary"}
            >
              {Object.values(surveyObj?.responses ?? {}).length}/
              {surveyObj?.survey?.decisionInvitees?.length}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 1,
            }}
          >
            <UserAvatar userId={surveyObj.createdBy} />
            <Typography variant="caption" color="text.secondary">
              <PrintUserInfo userId={surveyObj.createdBy} />
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {surveyObj.userResponseStatus === "noresponse" ? (
              <AccessTime fontSize="inherit" color="warning" />
            ) : (
              <Done fontSize="inherit" color="primary" />
            )}
          </Box>
        </CardActions>
      </CardActionArea>
    </Card>
  );
}
