// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import { SvgIcon } from "@mui/material";

export default function FullScreenIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 125"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g>
        <path d="M44.6,7H8.9C7.9,7,7,7.9,7,8.9V45c0,1.1,0.9,1.9,1.9,1.9h35.6c1.1,0,1.9-0.9,1.9-1.9V8.9C46.5,7.9,45.6,7,44.6,7z    M42.6,43.1H10.8V10.8h31.8V43.1z" />
        <path d="M91.1,7H55.4c-1.1,0-1.9,0.9-1.9,1.9V45c0,1.1,0.9,1.9,1.9,1.9h35.6c1.1,0,1.9-0.9,1.9-1.9V8.9C93,7.9,92.1,7,91.1,7z    M89.2,43.1H57.4V10.8h31.8V43.1z" />
        <path d="M44.6,53.1H8.9C7.9,53.1,7,54,7,55v36.1C7,92.1,7.9,93,8.9,93h35.6c1.1,0,1.9-0.9,1.9-1.9V55C46.5,54,45.6,53.1,44.6,53.1z    M42.6,89.2H10.8V56.9h31.8V89.2z" />
        <path d="M91.1,53.1H55.4c-1.1,0-1.9,0.9-1.9,1.9v36.1c0,1.1,0.9,1.9,1.9,1.9h35.6c1.1,0,1.9-0.9,1.9-1.9V55   C93,54,92.1,53.1,91.1,53.1z M89.2,89.2H57.4V56.9h31.8V89.2z" />
      </g>
    </SvgIcon>
  );
}
