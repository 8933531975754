import React from "react";
import { Box } from "@mui/material";
import { DateRangeSelector } from "blocks/atoms/pickers";
import isAfterDay from "react-dates/lib/utils/isAfterDay";
import moment from "moment";

export default function TasksDateRangeSelection({
  selectedDateRange = {},
  handleTasksDateRangeSelect = () => null,
}) {
  return (
    <Box>
      <DateRangeSelector
        // focusedInput="START_DATE"
        startDate={selectedDateRange.startDate}
        endDate={selectedDateRange.endDate}
        onDatesChange={({ startDate, endDate }) => {
          handleTasksDateRangeSelect({ startDate, endDate });
        }}
        onClose={() => console.log("closed")}
        isOutsideRange={(day) => isAfterDay(day, moment())}
        minDate={moment().subtract(12, "months").startOf("month")}
        maxDate={moment()}
        numberOfMonths={2}
        withPortal
      />
    </Box>
  );
}
