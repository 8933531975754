// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
// next
import React from "react";
import NextLink from "next/link";
import { useRouter } from "next/router";
// material
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  AppBar,
  Toolbar,
  Container,
  useMediaQuery,
} from "@mui/material";
// hooks
import { useOffSetTop } from "@app21/core";
// components
import Logo from "blocks/atoms/Logo";
import Label from "blocks/atoms/uicomponents/Label";
import { MHidden } from "blocks/atoms/@material-extend";
//

import MenuDesktop from "./MenuDesktop";
import MenuMobile from "./MenuMobile";
import MenuConfig from "./MenuConfig";
import LanguagePopover from "blocks/atoms/features/LanguagePopover";
import { LAYOUTWIDTHS } from "blocks/atoms/constants";

import { useAuth0 } from "@auth0/auth0-react";
import { useIntl } from "react-intl";

import LoggedInUser from "blocks/views/LandingPage/features/LoggedInUser";
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = LAYOUTWIDTHS.TopbarHeight;
const APP_BAR_DESKTOP = LAYOUTWIDTHS.TopbarHeight;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,

  [theme.breakpoints.up("md")]: {
    height: APP_BAR_DESKTOP,
  },
}));

const ToolbarShadowStyle = styled("div")(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: "auto",
  borderRadius: "50%",
  position: "absolute",
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8,
}));

// ----------------------------------------------------------------------

export default function LandingMainTopbar({ scrollY }) {
  // const isOffset = useOffSetTop(100);
  const isOffset = scrollY > 50;
  const router = useRouter();
  const { pathname } = router;

  const isHome = pathname === "/";

  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });
  const navConfig = MenuConfig();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <AppBar
      color={isOffset ? "default" : "transparent"}
      sx={{ boxShadow: 0, minWidth: "100vw" }}
    >
      <ToolbarStyle
        disableGutters
        sx={{
          width: "100%",
          ...(isOffset && {
            bgcolor: "background.default",
            height: { xs: APP_BAR_DESKTOP - 16 },
          }),
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              p: 0,
              m: 0,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Logo size={isMobile ? "normal" : "full"} />
          </Box>
          {/*hiddenUp && (
            <Label color="secondary" sx={{ ml: 1 }}>
              Trryst
            </Label>
          )*/}
          <Box
            sx={{ flexGrow: 4, display: "flex", justifyContent: "flex-end" }}
          >
            <MHidden width="mdDown">
              <MenuDesktop
                isOffset={isOffset}
                isHome={isHome}
                navConfig={navConfig}
              />
            </MHidden>
          </Box>
          <>
            {!isAuthenticated ? (
              <Button
                variant="contained"
                color="primary"
                id="SignUp-Button-Title-Bar"
                sx={{ mr: 1, ml: 2 }}
                onClick={() =>
                  loginWithRedirect({
                    appState: {
                      targetUrl: `${process.env.ROOT_URL}/auth0_callback`,
                    },
                    screen_hint: "login",
                    isPasswordlessLogin: false,
                  })
                }
              >
                {`${t("TopNavbar.signIn")}`}
              </Button>
            ) : (
              <Box sx={{ display: "flex", alignItems: "center", ml: 2 }}>
                <Button
                  variant="contained"
                  onClick={() =>
                    router.push("/user", "/user", { shallow: true })
                  }
                >
                  {`${t("TopNavbar.dashboard")}`}
                </Button>
                <LoggedInUser sx={{ ml: isMobile ? 1 : 2 }} />
              </Box>
            )}
            {/* {
              !isMobile && (
                <LanguagePopover />
              ) /*FIXME : need to fix this to roll onto menu in mobile */}
          </>

          <MHidden width="mdUp">
            <MenuMobile
              isOffset={isOffset}
              isHome={isHome}
              navConfig={navConfig}
            />
          </MHidden>
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
