export default function chimeGridSizesCalculator({
  chimeViewMode,
  elementDimensionsWidth,
  elementDimensionsHeight,
  contentSharedFlag,
  featured,
  isCollaborationActive,
}) {
  let mainWindowWidth = Math.min(
    Math.max(elementDimensionsWidth - 20, 100),
    4000
  );

  let mainWindowHeight = Math.min(
    Math.max(elementDimensionsHeight - 20, 100),
    3000
  );

  const calculatedLayout = {
    tilesSectionWidth: mainWindowWidth,
    tilesSectionMinWidth: mainWindowWidth,
    tilesSectionMaxWidth: mainWindowWidth,
    tilesSectionHeight: mainWindowHeight,
    tilesSectionMinHeight: mainWindowHeight,
    tilesSectionMaxHeight: mainWindowHeight,
    featuredSectionWidth: null,
    featuredSectionMinWidth: null,
    featuredSectionMaxWidth: null,
    featuredSectionHeight: null,
    featuredSectionMinHeight: null,
    featuredSectionMaxHeight: null,
    displayOrientation: null,
    containerHeight: mainWindowHeight - (chimeViewMode === "featured" ? 0 : 70),
    containerMinHeight:
      mainWindowHeight - (chimeViewMode === "featured" ? 0 : 70),
    containerMaxHeight:
      mainWindowHeight - (chimeViewMode === "featured" ? 0 : 70),
  };

  switch (chimeViewMode) {
    case "fullscreen":
      calculatedLayout.tilesSectionWidth =
        contentSharedFlag || isCollaborationActive
          ? 0.3 * mainWindowWidth
          : mainWindowWidth;
      calculatedLayout.tilesSectionMinWidth =
        contentSharedFlag || isCollaborationActive
          ? 0.3 * mainWindowWidth
          : mainWindowWidth;
      calculatedLayout.tilesSectionMaxWidth =
        contentSharedFlag || isCollaborationActive
          ? 0.3 * mainWindowWidth
          : mainWindowWidth;
      calculatedLayout.tilesSectionHeight = calculatedLayout.containerHeight;
      calculatedLayout.tilesSectionMinHeight = calculatedLayout.containerHeight;
      calculatedLayout.tilesSectionMaxHeight = calculatedLayout.containerHeight;
      calculatedLayout.featuredSectionWidth = 0.7 * mainWindowWidth;
      calculatedLayout.featuredSectionMinWidth = 0.7 * mainWindowWidth;
      calculatedLayout.featuredSectionMaxWidth = 0.7 * mainWindowWidth;
      calculatedLayout.featuredSectionHeight = calculatedLayout.containerHeight;
      calculatedLayout.featuredSectionMinHeight =
        calculatedLayout.containerHeight;
      calculatedLayout.featuredSectionMaxHeight =
        calculatedLayout.containerHeight;
      calculatedLayout.displayOrientation = "row";

      return calculatedLayout;
    case "featured":
      calculatedLayout.tilesSectionWidth =
        contentSharedFlag || isCollaborationActive
          ? 0.12 * mainWindowWidth
          : featured
          ? 0.2 * mainWindowWidth
          : mainWindowWidth;
      calculatedLayout.tilesSectionMinWidth =
        contentSharedFlag || isCollaborationActive
          ? 0.12 * mainWindowWidth
          : featured
          ? 0.2 * mainWindowWidth
          : mainWindowWidth;
      calculatedLayout.tilesSectionMaxWidth =
        contentSharedFlag || isCollaborationActive
          ? 0.12 * mainWindowWidth
          : featured
          ? 0.2 * mainWindowWidth
          : mainWindowWidth;
      calculatedLayout.tilesSectionHeight = calculatedLayout.containerHeight;
      calculatedLayout.tilesSectionMinHeight = calculatedLayout.containerHeight;
      calculatedLayout.tilesSectionMaxHeight = calculatedLayout.containerHeight;
      calculatedLayout.featuredSectionWidth =
        contentSharedFlag || isCollaborationActive
          ? 0.88 * mainWindowWidth
          : 0.8 * mainWindowWidth;
      calculatedLayout.featuredSectionMinWidth =
        contentSharedFlag || isCollaborationActive
          ? 0.88 * mainWindowWidth
          : 0.8 * mainWindowWidth;
      calculatedLayout.featuredSectionMaxWidth =
        contentSharedFlag || isCollaborationActive
          ? 0.88 * mainWindowWidth
          : 0.8 * mainWindowWidth;
      calculatedLayout.featuredSectionHeight = calculatedLayout.containerHeight;
      calculatedLayout.featuredSectionMinHeight =
        calculatedLayout.containerHeight;
      calculatedLayout.featuredSectionMaxHeight =
        calculatedLayout.containerHeight;
      calculatedLayout.displayOrientation = "row";

      return calculatedLayout;
    case "normal":
      calculatedLayout.tilesSectionWidth = mainWindowWidth;
      calculatedLayout.tilesSectionMinWidth = mainWindowWidth;
      calculatedLayout.tilesSectionMaxWidth = mainWindowWidth;
      calculatedLayout.tilesSectionHeight =
        (contentSharedFlag ? 0.5 : 1) * calculatedLayout.containerHeight;
      calculatedLayout.tilesSectionMinHeight =
        (contentSharedFlag ? 0.5 : 1) * calculatedLayout.containerHeight;
      calculatedLayout.tilesSectionMaxHeight =
        (contentSharedFlag ? 0.5 : 1) * calculatedLayout.containerHeight;
      calculatedLayout.featuredSectionWidth = mainWindowWidth;
      calculatedLayout.featuredSectionMinWidth = mainWindowWidth;
      calculatedLayout.featuredSectionMaxWidth = mainWindowWidth;
      calculatedLayout.featuredSectionHeight =
        0.5 * calculatedLayout.containerHeight;
      calculatedLayout.featuredSectionMinHeight =
        0.5 * calculatedLayout.containerHeight;
      calculatedLayout.featuredSectionMaxHeight =
        0.5 * calculatedLayout.containerHeight;
      calculatedLayout.displayOrientation = "column";

      return calculatedLayout;

    case "compact":
      calculatedLayout.tilesSectionWidth = mainWindowWidth;
      calculatedLayout.tilesSectionMinWidth = mainWindowWidth;
      calculatedLayout.tilesSectionMaxWidth = mainWindowWidth;
      calculatedLayout.tilesSectionHeight =
        (contentSharedFlag ? 0.7 : 1) * calculatedLayout.containerHeight;
      calculatedLayout.tilesSectionMinHeight =
        (contentSharedFlag ? 0.7 : 1) * calculatedLayout.containerHeight;
      calculatedLayout.tilesSectionMaxHeight =
        (contentSharedFlag ? 0.7 : 1) * calculatedLayout.containerHeight;
      calculatedLayout.featuredSectionWidth = mainWindowWidth;
      calculatedLayout.featuredSectionMinWidth = mainWindowWidth;
      calculatedLayout.featuredSectionMaxWidth = mainWindowWidth;
      calculatedLayout.featuredSectionHeight =
        0.3 * calculatedLayout.containerHeight;
      calculatedLayout.featuredSectionMinHeight =
        0.3 * calculatedLayout.containerHeight;
      calculatedLayout.featuredSectionMaxHeight =
        0.3 * calculatedLayout.containerHeight;
      calculatedLayout.displayOrientation = "column";

      return calculatedLayout;

    default:
      calculatedLayout.tilesSectionWidth = mainWindowWidth;
      calculatedLayout.tilesSectionMinWidth = mainWindowWidth;
      calculatedLayout.tilesSectionMaxWidth = mainWindowWidth;
      calculatedLayout.tilesSectionHeight =
        (contentSharedFlag || isCollaborationActive ? 0.5 : 1) *
        calculatedLayout.containerHeight;
      calculatedLayout.tilesSectionMinHeight =
        (contentSharedFlag || isCollaborationActive ? 0.5 : 1) *
        calculatedLayout.containerHeight;
      calculatedLayout.tilesSectionMaxHeight =
        (contentSharedFlag || isCollaborationActive ? 0.5 : 1) *
        calculatedLayout.containerHeight;
      calculatedLayout.featuredSectionWidth = mainWindowWidth;
      calculatedLayout.featuredSectionMinWidth = mainWindowWidth;
      calculatedLayout.featuredSectionMaxWidth = mainWindowWidth;
      calculatedLayout.featuredSectionHeight =
        0.5 * calculatedLayout.containerHeight;
      calculatedLayout.featuredSectionMinHeight =
        0.5 * calculatedLayout.containerHeight;
      calculatedLayout.featuredSectionMaxHeight =
        0.5 * calculatedLayout.containerHeight;
      calculatedLayout.displayOrientation = "column";

      return calculatedLayout;
  }
}
