// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Paper } from "@mui/material";
import {
  AddCircleOutline,
  RemoveCircleOutline,
  Home,
  CheckBox,
} from "@mui/icons-material";
import TreeView from "@mui/lab/TreeView";
import { getExtensionFromFilename } from "utils";
import { getFileIcon } from "blocks/atoms";
import { useHandleRouterPush, useAppContext, checkIfNull } from "@app21/core";
import {} from "@app21/core";

import { DocketLabel } from "blocks/atoms";
import { useClickPreventionOnDoubleClick } from "hooks";

import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import { alpha, styled } from "@mui/material/styles";
import { Box, Collapse, Typography, useTheme } from "@mui/material";

function TransitionComponent(props) {
  return <Collapse {...props} />;
}

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2),
    },
  },
  [`& .${treeItemClasses.content}`]: {},
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
  "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
    backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
    color: "var(--tree-view-color)",
  },
  [`& .${treeItemClasses.label}`]: {
    fontWeight: theme.typography.subtitle1.fontWeight,
    fontSize: theme.typography.subtitle1.fontSize,
    color: "inherit",
  },
}));

export function TreeItemEntry(props) {
  const {
    bgcolor,
    color,
    labelIcon,
    labelInfo,
    labelText,
    isActiveFolder,
    nodeId,
    ...other
  } = props;

  return (
    <StyledTreeItemRoot
      {...other}
      nodeId={nodeId}
      TransitionComponent={TransitionComponent}
      label={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            my: 0.3,
            mx: -0.5,
            pr: 0,
            ...(isActiveFolder
              ? {
                  backgroundColor: (theme) =>
                    alpha(theme.palette.primary.main, 0.2),
                }
              : {}),
            borderRadius: 5,
          }}
        >
          {labelIcon}
          {/*<Box component={labelIcon} color="inherit" sx={{ mr: 1 }} />
          {labelIcon}*/}
          <Typography
            variant="body1"
            component="h6"
            sx={{ flexGrow: 1, ml: 1, my: 0.2 }}
          >
            {labelText}
          </Typography>
        </Box>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgcolor,
      }}
    />
  );
}

const SelectableTreeFileExplorer = ({
  filesList = { objects: [], folders: [] },
  allKeys,
  docketsInSuite,
  decodedS3Prefix,
  selectedItems,
  setSelectedItems,
}) => {
  const theme = useTheme();
  const { loadRoute } = useHandleRouterPush();
  const { viewFile } = useAppContext();

  const [expandedItems, setExpandedItems] = React.useState([]);

  const handleNodeToggle = (event, nodeIds) => {
    //event.preventDefault();
    //event.stopPropagation();

    setExpandedItems(nodeIds);
  };

  const getCheckIcon = (node) => {
    if (selectedItems?.includes(node?.Key)) {
      return <CheckBox color="success" />;
    } else {
      return getFileIcon({
        fileExt: getExtensionFromFilename(node.Key ?? ""),
        size: "lg",
      });
    }
  };
  const handleNodeSelect = (event, nodeIds) => {
    setSelectedItems(nodeIds);
  };

  // const handleTreeItemSingleClick = (event, key, node, context) => {
  //   let labelClicked = event.target.closest('.MuiTreeItem-label');
  //   let docketSearchId = docketsInSuite?.find(d => d.title === key)?._id;

  //   let updatedKey = docketSearchId ?? key;

  //   if (Boolean(labelClicked)) {
  //     const s3Entry =
  //       context === 'folder'
  //         ? allKeys?.find(k => k.Key.endsWith(`${updatedKey}/`) || k?.origKey?.endsWith(`${updatedKey}/`))
  //         : context === 'home'
  //         ? `${node.parent}/${node.name}`
  //         : allKeys?.find(k => k.Key === updatedKey || k?.origKey === updatedKey);
  //     const fileName = updatedKey?.replace(/^.*(\\|\/|\:)/, '');
  //     const newKey = s3Entry?.Key?.replace(fileName, '');

  //     s3Entry &&
  //       loadRoute('VIEW-DATAROOM', {
  //         s3Key: context === 'folder' ? s3Entry?.Key : newKey,
  //       });
  //   }
  // };

  // const handleTreeItemDoubleClick = (event, key, node, context) => {
  //   if (key === 'Breakouts') {
  //     const firstKeySplit = allKeys[0].Key.split('/');
  //     const constructedKey = `${firstKeySplit[0]}/${firstKeySplit[1]}/dockets/`;
  //     loadRoute('VIEW-DATAROOM', { s3Key: constructedKey });
  //   }

  //   let docketId = docketsInSuite.find(d => d.title === key)?._id ?? null;

  //   if (docketId) key = docketId;

  //   const s3Entry = allKeys.find(k => k.Key.endsWith(`${key}/`) || k?.origKey?.endsWith(`${key}/`));

  //   // if there is no key that ends with docketId, there is no other way but to manually send it out.
  //   if (docketId && !s3Entry) {
  //     const firstKeySplit = allKeys[0].Key.split('/');
  //     const constructedKey = `${firstKeySplit[0]}/${firstKeySplit[1]}/dockets/${docketId}/`;
  //     loadRoute('VIEW-DATAROOM', { s3Key: constructedKey });
  //   } else {
  //     s3Entry && loadRoute('VIEW-DATAROOM', { s3Key: s3Entry?.Key });
  //   }
  // };

  // const [handleSingleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(
  //   handleTreeItemSingleClick,
  //   handleTreeItemDoubleClick,
  // );
  let idElements = decodedS3Prefix?.split("/");
  let folderId =
    idElements?.length > 1 ? idElements[idElements.length - 2] : "";

  function findNode(id, array) {
    if (array && Array.isArray(array)) {
      for (const node of array) {
        if (node?.name === id) return node;
        if (node?.folders) {
          const child = findNode(id, node.folders);
          if (child) return child;
        }
      }
    } else return {};
  }
  let currentFolderName =
    (filesList?.folders && findNode(folderId, filesList.folders))?.name ?? "";

  const showFilesFlag = Boolean(viewFile);

  const renderTree = (nodes, mainIndex) => {
    return <>
      {!checkIfNull(nodes.folders) &&
        Array.isArray(nodes.folders) &&
        nodes.folders.map((node, subIndex) => {
          const isActiveFolder = currentFolderName === node.name;
          return (
            <TreeItemEntry
              key={mainIndex * 100 + subIndex}
              nodeId={node.name ?? `Folder ${subIndex}`}
              labelText={
                node.parent === "Breakouts" ? (
                  <DocketLabel id={node.origKey} title={node.name} />
                ) : (
                  node.name
                )
              }
              labelInfo={""}
              color={theme.palette.secondary.contrastText}
              bgcolor={theme.palette.secondary.main}
              //onClick={event => handleSingleClick(event, node.name, node, 'folder')}
              //onDoubleClick={event => handleDoubleClick(event, node.name, node, 'folder')}
              isActiveFolder={isActiveFolder}
            >
              {renderTree(node, mainIndex * 20)}
            </TreeItemEntry>
          );
        })}

      {!checkIfNull(nodes.objects) &&
        Array.isArray(nodes.objects) &&
        nodes.objects.map((node, subIndex) => {
          return (
            <TreeItemEntry
              key={node.ETag + subIndex}
              nodeId={node.Key ?? `File ${subIndex}`}
              //  dndState={dndState}
              labelText={(node.Key ?? "File").replace(/^.*(\\|\/|\:)/, "")}
              labelIcon={getCheckIcon(node)}
              labelInfo={""}
              color={theme.palette.secondary.contrastText}
              bgcolor={theme.palette.secondary.main}
              //onClick={event => handleSingleClick(event, node.Key, node, 'file')}
              //onDoubleClick={event => handleDoubleClick(event, node.Key, node, 'file')}
            />
          );
        })}
    </>;
  };

  return (
    <Paper sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
      <TreeView
        aria-label="rich object"
        defaultExpanded={["root"]}
        defaultCollapseIcon={<RemoveCircleOutline />}
        defaultExpandIcon={<AddCircleOutline />}
        defaultEndIcon={<div style={{ marginLeft: -13 }} />}
        expanded={expandedItems}
        selected={selectedItems}
        onNodeToggle={handleNodeToggle}
        onNodeSelect={handleNodeSelect}
        multiSelect
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          overflowY: "auto",

          width: "100%",
          mt: 2,
        }}
      >
        {/* <TreeItemEntry
          nodeId="HomeRoot"
          labelText="Home"
          labelIcon={<Home fontSize="small" sx={{ mr: -1 }} />}
          onClick={event => handleSingleClick(event, filesList.name, filesList, 'home')}
        > */}
        {renderTree(filesList, 1)}
        {/* </TreeItemEntry> */}
      </TreeView>
    </Paper>
  );
};

export default SelectableTreeFileExplorer;
