// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  Tooltip,
  Badge,
  Avatar,
  Menu,
  MenuItem,
  ListSubheader,
  ListItemIcon,
  ListItemText,
  Divider,
  AvatarGroup,
} from "@mui/material";

import { Forum } from "@mui/icons-material";
import { usePubNub } from "pubnub-react";
import {
  useAppContext,
  useUsersInSuite,
  getS3ImageWrapper,
  useHandleRouterPush,
} from "@app21/core";

import useDimensions from "react-cool-dimensions";

import toast from "react-hot-toast";

const UserAvatarList = () => {
  const { selectedSuiteId } = useAppContext();

  const {
    data: usersInSuite,
    status: usersInSuiteStatus,
    apiError: usersInSuiteApiError,
  } = useUsersInSuite(selectedSuiteId);

  React.useEffect(() => {
    if (usersInSuiteApiError && usersInSuiteStatus != "loading") {
      toast.error(usersInSuiteApiError.message);
    }
  }, [usersInSuiteApiError]);

  const { activeUsers, userInfo } = useAppContext();
  const { loadRoute } = useHandleRouterPush();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [clickedUser, setClickedUser] = React.useState(null);
  const [topbarDimensions, setTopbarDimensions] = React.useState({
    width: 200,
    height: 200,
  });
  const { ref: topbarElementRef } = useDimensions({
    onResize: ({ width, height }) => {
      // Triggered whenever the size of the target is changed...
      setTopbarDimensions({ width: width, height: height });
    },
  });
  let maxAvatars = Math.floor((topbarDimensions.width - 25) / 35);
  if (maxAvatars < 4) {
    maxAvatars = 0;
  }
  const listOfUsers = usersInSuite || [];

  const handleAvatarClick = (event, index, user) => {
    if (user._id !== userInfo._id) {
      setAnchorEl(event?.currentTarget);
      setClickedUser(user);
    }
  };
  const handleAvatarClickClose = () => {
    setAnchorEl(null);
    setClickedUser(null);
  };
  const pubnub = usePubNub();

  const initiateChat = async (chatWithUserId) => {
    loadRoute("GROUP-CHAT", {
      chatId:
        userInfo?._id < chatWithUserId
          ? `${userInfo?._id}-${chatWithUserId}-CHAT`
          : `${chatWithUserId}-${userInfo?._id}-CHAT`,
      chatType: "suite",
    });
  };

  let listOfOnlineUsers = activeUsers?.channels["CSUITECOMMON"]?.occupants;
  let foundIDArray = [];
  Array.isArray(listOfOnlineUsers) &&
    listOfOnlineUsers.map((arrayObj) => {
      let id = arrayObj?.state?.userInfo?._id;
      if (id) {
        foundIDArray.push(id);
      }
      return foundIDArray;
    });

  return (
    <div ref={topbarElementRef} style={{ width: "100%", flexGrow: 1 }}>
      {Boolean(maxAvatars) && (
        <AvatarGroup
          max={maxAvatars}
          sx={{
            "& .MuiAvatarGroup-root": { height: maxAvatars < 7 ? 30 : 35 },
            "& .MuiAvatarGroup-avatar": {
              borderRadius: maxAvatars < 7 ? 25 : 30,
              height: maxAvatars < 7 ? 30 : 35,
            },
          }}
          style={{
            background: "transparent",
            borderRadius: maxAvatars < 7 ? 25 : 30,
          }}
        >
          {listOfUsers?.map((user, index) => {
            let isUserOnline = foundIDArray?.find((id) => id === user._id);
            //let isUserOnline = false;
            return (
              <Tooltip key={index} title={user.fullName} arrow>
                <Badge
                  sx={{
                    "& .MuiBadge-dot": {
                      backgroundColor: "LimeGreen",
                      height: 8,
                      width: 8,
                    },
                  }}
                  overlap="circular"
                  variant="dot"
                  invisible={!isUserOnline}
                >
                  <Avatar
                    sx={{
                      height: maxAvatars < 7 ? 25 : 30,
                      width: maxAvatars < 7 ? 25 : 30,
                      cursor: "pointer",
                      backgroundColor: (theme) =>
                        theme.palette.background.default,
                    }}
                    aria-label={user.fullName}
                    alt={user.fullName}
                    src={getS3ImageWrapper(user?.image, "person")}
                    onClick={(event) => handleAvatarClick(event, index, user)}
                  />
                </Badge>
              </Tooltip>
            );
          })}
        </AvatarGroup>
      )}
      <Menu
        id="avatar-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleAvatarClickClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <ListSubheader>{clickedUser?.fullName} </ListSubheader>
        <Divider />
        <MenuItem onClick={() => initiateChat(clickedUser._id)}>
          <ListItemIcon>
            <Forum />
          </ListItemIcon>
          <ListItemText primary={"Chat"} />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserAvatarList;
// onClick={() => initiateChat(user._id)
