// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from "react";
import { useLocalVideo } from "amazon-chime-sdk-component-library-react";
import { useTheme, Button } from "@mui/material";
import { Videocam, VideocamOff } from "@mui/icons-material";

import {
  VideoCallInActiveIcon,
  VideoCallActiveIcon,
} from "blocks/atoms/trrystCustomIcons";
import { pxToRem } from "utils";
import { StyledSquareMenuButton } from "blocks/atoms/uistyles";

const VideoInputControl = ({ recommendedButtonSize = 40 }) => {
  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  const theme = useTheme();
  return (
    <StyledSquareMenuButton
      onClick={toggleVideo}
      sx={{
        borderRadius: 0,
        padding: 0,
        width: recommendedButtonSize,
        height: recommendedButtonSize,
        maxWidth: recommendedButtonSize,
        maxHeight: recommendedButtonSize,
        minWidth: recommendedButtonSize,
        minHeight: recommendedButtonSize,
      }}
    >
      {isVideoEnabled ? (
        <VideoCallActiveIcon
          color="primary"
          stroke={theme.palette.primary.contrastText}
          fontSize="large"
          sx={{
            width: recommendedButtonSize,
            height: recommendedButtonSize,
            maxWidth: recommendedButtonSize,
            maxHeight: recommendedButtonSize,
            minWidth: recommendedButtonSize,
            minHeight: recommendedButtonSize,
            "&:hover": {
              color: "primary.dark",
            },
          }}
        />
      ) : (
        <VideoCallInActiveIcon
          stroke={theme.palette.grey[600]}
          fontSize="large"
          sx={{
            width: recommendedButtonSize,
            height: recommendedButtonSize,

            maxWidth: pxToRem(40),
            maxHeight: pxToRem(40),
            color: (theme) => theme.palette.gray.lighter,
            "&:hover": {
              color: "gray.light",
            },
          }}
        />
      )}
    </StyledSquareMenuButton>
  );
};

export default VideoInputControl;
