// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import { Button, IconButton, Tooltip } from "@mui/material";
import { useIsMobileBreakpoint } from "hooks";

const ResponsiveButton = ({ children, label, ...rest }) => {
  const isMobile = useIsMobileBreakpoint();

  if (isMobile && Boolean(label))
    return (
      <Tooltip title={label} arrow>
        <IconButton {...rest}>{children}</IconButton>
      </Tooltip>
    );
  else if (isMobile) return <IconButton {...rest}>{children}</IconButton>;
  else
    return (
      <Button startIcon={children} {...rest}>
        {label}
      </Button>
    );
};
export default ResponsiveButton;
