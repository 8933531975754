// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  ListItemIcon,
} from "@mui/material";
import { FiberManualRecord } from "@mui/icons-material";
import { checkIfNull, useTasks, useAppContext } from "@app21/core";
import { ShowActions } from "blocks/modules/Dockets/foundations";
import { processAllTasksByStatus } from "blocks/modules/Tasks/foundations/processAllTasksByStatus";

import LoadingSpinner from "blocks/atoms/LoadingSpinner";
import { useIntl } from "react-intl";
import toast from "react-hot-toast";

const DocketActionsView = (props) => {
  const { viewMode } = props;
  const { selectedDocketId } = useAppContext();
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });

  const {
    data: docketActions,
    status: docketActionStatus,
    apiError: docketActionsApiError,
  } = useTasks({
    objectId: selectedDocketId,
    idType: "docket",
  });

  React.useEffect(() => {
    if (docketActionsApiError && docketActionStatus != "loading") {
      toast.error(docketActionsApiError.message);
    }
  }, [docketActionsApiError]);

  const listActions = (actions) => {
    let processedActions = processAllTasksByStatus(actions?.allTasks);

    return (
      <>
        <List dense sx={{ my: 0 }}>
          {!checkIfNull(processedActions.pendingTasksList) && (
            <>
              <ListSubheader sx={{ mb: 0, pb: 0 }}>{`${t(
                "DocketDashboard.ShowActions.actionsListMessage"
              )}`}</ListSubheader>
              {processedActions.pendingTasksList?.map((action, allIndex) => (
                <ListItem key={allIndex} disableGutters>
                  <ListItemIcon>
                    <FiberManualRecord fontSize={"small"} />
                  </ListItemIcon>
                  <ListItemText primary={action?.title} />
                </ListItem>
              ))}
            </>
          )}
          {!checkIfNull(processedActions.wipTasksList) && (
            <>
              <ListSubheader sx={{ mb: 0, pb: 0 }}>
                Work In Progress
              </ListSubheader>
              {processedActions.wipTasksList?.map((action, allIndex) => (
                <ListItem key={allIndex} disableGutters>
                  <ListItemIcon>
                    <FiberManualRecord fontSize={"small"} />
                  </ListItemIcon>
                  <ListItemText primary={action?.title} />
                </ListItem>
              ))}
            </>
          )}
          {!checkIfNull(processedActions.completedTasksList) && (
            <>
              <ListSubheader sx={{ mb: 0, pb: 0 }}>Completed</ListSubheader>
              {processedActions.completedTasksList?.map((action, allIndex) => (
                <ListItem key={allIndex} disableGutters>
                  <ListItemIcon>
                    <FiberManualRecord fontSize={"small"} />
                  </ListItemIcon>
                  <ListItemText primary={action?.title} />
                </ListItem>
              ))}
            </>
          )}
        </List>
      </>
    );
  };

  if (viewMode === "summary") {
    if (checkIfNull(docketActions?.allTasks)) {
      if (docketActionStatus === "loading") {
        return <LoadingSpinner size={20} />;
      } else
        return (
          <Typography variant="body1">{`${t(
            "DocketDashboard.ShowActions.noActionsMessage"
          )}`}</Typography>
        );
    } else
      return (
        <div>
          {/*<Typography variant="h6">Actions Overview</Typography>*/}
          {listActions(docketActions)}
        </div>
      );
  }
  return (
    <div style={{ weight: "100%", height: "100%" }}>
      <ShowActions viewMode={"docketactions"} />
    </div>
  );
};

export default DocketActionsView;
