// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Avatar, Tooltip } from "@mui/material";
import { useUserInfo, getS3ImageWrapper } from "@app21/core";

const DisplayAttendeeAvatar = ({ id = null }) => {
  const { data: userInfo } = useUserInfo(id);

  return (
    <Tooltip title={userInfo?.fullName} arrow>
      <Avatar
        src={getS3ImageWrapper(userInfo?.image, "person")}
        alt={userInfo?.fullName}
      />
    </Tooltip>
  );
};
export default DisplayAttendeeAvatar;
