import React from "react";
import { Box } from "@mui/material";
import {
  StyledRoundedTabs,
  StyledRoundedTab,
  StyledRoundedTabList,
} from "blocks/atoms/uistyles";
import { useAtom } from "jotai";
import { useHandleRouterPush, useAppContext } from "@app21/core";
import {
  viewTasksFilterContextAtom,
  selectedBreakoutsInTaskFiltersAtom,
  selectedStatusInTaskFiltersAtom,
  selectedTasksDateRangeAtom,
} from "blocks/modules/Tasks/tasks-atoms";
import {
  SmartActionsSearchBar,
  baseSearchActions,
} from "blocks/atoms/uicomponents/smartsearch";
import SelectedActionsDisplay from "./SelectedActionsDisplay";

export default function SelectTasksSidebarContext() {
  const [viewTasksFilterContext, setViewTasksFilterContext] = useAtom(
    viewTasksFilterContextAtom
  );
  const [selectedBreakoutsInTaskFilters, setSelectedBreakoutsInTaskFilters] =
    useAtom(selectedBreakoutsInTaskFiltersAtom);
  const [selectedStatusInTaskFilters, setSelectedStatusInTaskFilters] = useAtom(
    selectedStatusInTaskFiltersAtom
  );
  const [selectedTasksDateRange, setSelectedTasksDateRange] = useAtom(
    selectedTasksDateRangeAtom
  );

  const handleFilterContextSelect = (value) => {
    setViewTasksFilterContext(value?.map((obj) => obj.value) ?? []);
  };
  const filteredValues =
    baseSearchActions.filter((obj) =>
      viewTasksFilterContext?.includes(obj.value)
    ) ?? [];

  const handleResetFilterValues = () => {
    setSelectedBreakoutsInTaskFilters([]);
    setSelectedStatusInTaskFilters([]);
    setViewTasksFilterContext([]);
    setSelectedTasksDateRange({});
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        alignItems: "stretch",
        pr: 0,
      }}
    >
      <SmartActionsSearchBar
        baseActions={baseSearchActions}
        value={filteredValues}
        onFilterSelect={(value) => handleFilterContextSelect(value)}
        showClearButton
        onClear={handleResetFilterValues}
      />
      <SelectedActionsDisplay />
      {/* <StyledRoundedTabs
        value={viewTaskContext}
        onChange={(event, value) => {
          handleChangeViewContext(value);
        }}
        selectionFollowsFocus
        sx={{ flexGrow: 1, my: 2 }}
      >
        <StyledRoundedTabList sx={{ p: 1 }}>
          {baseMenuOptions.map((option, index) => (
            <StyledRoundedTab
              key={index}
              value={option.value}
              textColor="secondary"
              sx={{ fontSize: "1.1 rem" }}
            >
              {option.label}
            </StyledRoundedTab>
          ))}
        </StyledRoundedTabList>
      </StyledRoundedTabs> */}
    </Box>
  );
}
