import React from "react";
import { Label } from "blocks/atoms";
import clip from "text-clipper";
import { Typography } from "@mui/material";

const DocketLabel = ({ docket, title, sx }) => {
  return (
    <Label
      sx={{
        height: "100%",
        fontWeight: "bold",
        bgcolor: "transparent",
        ...sx,
      }}
    >
      <Typography variant="subtitle1" component="span">
        {clip(title, 25, { breakWords: true })}
      </Typography>
    </Label>
  );
};

export default React.memo(DocketLabel);
