import { atom } from "jotai";

export const showRecordingsSidebarFlagAtom = atom(false);
export const viewRecordingsContextAtom = atom("myrecordings");
export const selectedRecordingsActionAtom = atom("");
export const selectedUploadActionAtom = atom("");
export const selectedRecordingsListAtom = atom([]);
export const viewRecordingsFilterContextAtom = atom([]);
export const selectedBreakoutsInRecordingsFiltersAtom = atom([]);
export const selectedStatusInRecordingsFiltersAtom = atom([]);
export const selectedRecordingsDateRangeAtom = atom({});
