import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  IconButton,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Collapse,
  Chip,
  Paper,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useIntl } from "react-intl";
import { Delete, ExpandMore, Settings } from "@mui/icons-material";
import { FormProvider, useForm, Controller } from "react-hook-form";
import { useConfirm } from "material-ui-confirm";
import {
  useUsersInSuite,
  useSuite,
  useAppContext,
  checkIfNull,
  createSelectOptions,
  sortMembersVisitors,
  getRolePrivileges,
  useHandleDocketActions,
} from "@app21/core";
import { useDocketsContext } from "providers/DocketsProvider";

import MuiSelectFormField from "blocks/atoms/MuiSelectFormField";

import ObjectID from "bson-objectid";

import isAlphanumeric from "validator/lib/isAlphanumeric";
import toast from "react-hot-toast";

const DocketEditForm = ({ newDocket, open, docket = {}, ...rest }) => {
  const confirm = useConfirm();
  const { selectedSuiteId, checkAccessRules, userInfo, selectedDocketId } =
    useAppContext();
  const { loadRoute, docketPermissions } = useDocketsContext();

  const {
    data: selectedSuite,
    status: selectedSuiteStatus,
    apiError: selectedSuiteApiError,
  } = useSuite(selectedSuiteId);
  const {
    data: usersInSuite,
    status: usersInSuiteStatus,
    apiError: usersInSuiteApiError,
  } = useUsersInSuite(selectedSuiteId);

  const dataFetchError = selectedSuiteApiError || usersInSuiteApiError;
  const dataFetchLoading =
    selectedSuiteStatus === "loading" || usersInSuiteStatus === "loading";

  React.useEffect(() => {
    if (dataFetchError && !dataFetchLoading) {
      toast.error(dataFetchError.message);
    }
  }, [dataFetchError]);
  const [openSettings, setOpenSettings] = React.useState(false);
  const { formatMessage } = useIntl();

  const t = (id) => formatMessage({ id });

  const handleToggleSettingsOpen = () => setOpenSettings(!openSettings);

  let selectedDocket = newDocket ? {} : docket;

  let populatedUserArray = [];
  if (
    !checkIfNull(selectedDocket?.members) &&
    typeof selectedDocket?.members[0] === "string"
  ) {
    selectedDocket?.members.forEach((userId) =>
      populatedUserArray.push(
        usersInSuite?.find((user) => user?._id === userId)
      )
    );
  } else {
    populatedUserArray = [
      ...populatedUserArray,
      ...(selectedDocket?.members ?? [userInfo]),
    ];
  }

  if (
    !checkIfNull(selectedDocket?.visitors) &&
    Array.isArray(selectedDocket?.visitors)
  ) {
    populatedUserArray = [
      ...populatedUserArray,
      ...(selectedDocket?.visitors || []),
    ];
  }
  let defaultUserOptions = createSelectOptions(populatedUserArray) || [];

  const baseDefaultValues = {
    title: selectedDocket?.title || "",
    members: selectedDocket?.members ?? [],
    visitors: selectedDocket?.visitors || [],
    combined: defaultUserOptions ?? [],
    videocall: selectedDocket?.videocall ?? true,
    protectDocketFiles: selectedDocket?.settings?.protectDocketFiles ?? false,
    protectDocketText:
      selectedDocket?.settings?.protectDocketText ?? "Confidential",
    disableDownloads: false,
  };

  const methods = useForm({
    mode: "onChange",
    defaultValues: baseDefaultValues,
  });
  const {
    getValues,
    handleSubmit,
    control,
    formState,
    watch,
    setValue,
    reset,
    setError,
  } = methods;
  const { errors, isDirty, isSubmitting, isValid, dirtyFields } = formState;

  const { handleDocketActions } = useHandleDocketActions();

  React.useEffect(() => {
    reset(baseDefaultValues);
  }, [reset, docket?._id]);

  const handleEditClose = (id) => {
    reset();
    if (id) {
      loadRoute("VIEW-DOCKET", { docketId: id });
    } else {
      loadRoute("LIST-DOCKETS", {});
    }
  };

  const handleDialogClose = () => {
    if (isDirty) {
      confirm({
        description:
          "Exiting would mean you lose all your unsaved changes. Please confirm to proceed",
      })
        .then(async () => {
          handleEditClose();
        })
        .catch(() => {
          console.log("Deletion cancelled.");
        });
    } else handleEditClose();
  };

  const handleDocketDelete = async () => {
    confirm({
      description:
        " Deleting the Breakout is permanent and all your data within this Breakout room will be deleted and cannot be recovered. Proceed only if you are sure!",
    })
      .then(async () => {
        await handleDocketActions(
          { action: "DELETE-DOCKET", docketId: selectedDocket._id },
          null,
          null
        );
        handleEditClose();
      })
      .catch(() => {
        console.log("Deletion cancelled.");
      });
  };

  const handleDocketUpdate = async (data) => {
    await handleDocketActions(
      {
        action: "UPDATE-DOCKET",
        docketData: data,
        selectedDocket: selectedDocket,
      },
      null,
      null
    );

    const inviteesList = [];

    data.members.map((i) =>
      inviteesList.push({
        _id: i?._id,
        emailId: i.emailId,
        fullName: i.label,
        image: i.image || i.avatar,
        rsvpStatus: i.rsvpStatus ?? "Invited",
        calendarAdded: true,
      })
    );
    return docket._id;
  };
  const handleDocketCreate = async (data) => {
    const newDocketId = ObjectID().toHexString();

    await handleDocketActions({
      action: "ADD-DOCKET",
      docketData: data,
      docketId: newDocketId,
    });
    //TODO: need to fix conditional around when to show toast
    toast.success("Breakout room created");

    return newDocketId;
  };

  const onSubmit = async (data) => {
    let sortedData = sortMembersVisitors(data.combined);
    let modifiedData = { ...data, ...sortedData };

    let docketWriteResult;
    try {
      if (newDocket) {
        modifiedData.type = selectedSuite?.suiteType?.includes("BOARD")
          ? "BOARD-MEETING"
          : "BREAKOUT";
        docketWriteResult = await handleDocketCreate(modifiedData);
      } else {
        docketWriteResult = await handleDocketUpdate(modifiedData);
      }
      reset();
    } catch (err) {
      console.error(err);
      toast.error(`Something went wrong ${err}`);
    }
    handleEditClose(docketWriteResult);
  };

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
      }}
    >
      <DialogTitle>
        {newDocket ? "Create a Breakout" : "Edit Breakout"}
      </DialogTitle>
      <FormProvider {...methods}>
        {(newDocket && docketPermissions.canAddNew) ||
        docketPermissions.checkCanEditDocket(docket) ? (
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <DialogContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="title"
                    rules={{
                      required: {
                        value: true,
                        message: "A name for the Breakout is required",
                      },
                      minLength: {
                        value: 3,
                        message: "Sorry. The chosen name is too short.",
                      },
                      maxLength: {
                        value: 100,
                        message: "Sorry. Too long. Limit to 100 characters",
                      },
                      validate: {
                        validText: (value) =>
                          isAlphanumeric(value, "en-US", {
                            ignore: '/^(?=.*[0-9])[- +()_@./#&!"#%{}£~`0-9]+$/',
                          }),
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors?.title}
                        helperText={
                          errors?.title?.message ||
                          (errors?.title?.type === "validText" &&
                            "Special characters are not allowed")
                        }
                        placeholder="Enter a brief title for your Breakout (For e.g. March 2021 Exco)"
                        label="Name for your Breakout"
                        InputProps={{}}
                        InputLabelProps={{}}
                        fullWidth
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name={"combined"}
                    defaultValue={defaultUserOptions}
                    rules={{
                      required: {
                        value: true,
                        message: "Atleast one person needs to be chosen.",
                      },
                      validate: {
                        validLength: (value) => value?.length !== 0,
                      },
                    }}
                    disabled={
                      !newDocket &&
                      !docketPermissions.checkCanManageInvitees(docket)
                    }
                    render={({ field: { ref, ...rest } }) => (
                      <MuiSelectFormField
                        listOfUsers={usersInSuite}
                        refs={ref}
                        placeholder="Select Members within this Breakout"
                        limitTags={10}
                        allowGuests={false}
                        inputValue={""}
                        helperText={
                          errors?.combined?.message ||
                          (errors?.combined?.type === "validLength" &&
                            "Atleast one member needs to be present")
                        }
                        {...rest}
                        onChange={(data) => {
                          setValue("combined", data, { shouldDirty: true });
                        }}
                        errors={errors?.combined}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <Chip
                    variant="outlined"
                    label="More settings"
                    deleteIcon={
                      <ExpandMore
                        sx={{
                          my: 2,
                          transform: !openSettings
                            ? "rotate(0deg)"
                            : "rotate(180deg)",
                          marginLeft: "auto",
                          transition: "transform 500ms ease-in-out",
                        }}
                      />
                    }
                    onDelete={handleToggleSettingsOpen}
                    onClick={handleToggleSettingsOpen}
                    icon={<Settings />}
                  />
                  <Collapse in={openSettings} sx={{ my: 1, width: "100%" }}>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Controller
                        name="protectDocketFiles"
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(e) =>
                                  field.onChange(e.target.checked)
                                }
                                checked={field.value}
                              />
                            }
                            label={
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography variant="body2" color="textPrimary">
                                  {`${t(
                                    "SuiteDashboard.BoardMeetingEditForm.watermarkPrompt"
                                  )}`}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  {`${t(
                                    "SuiteDashboard.BoardMeetingEditForm.protectDocumentPrompt"
                                  )}`}
                                </Typography>
                              </div>
                            }
                          />
                        )}
                      />

                      {watch("protectDocketFiles") && (
                        <Controller
                          name="protectDocketText"
                          control={control}
                          rules={{
                            maxLength: {
                              value: 25,
                              message:
                                "Sorry. Too long. Limit to 25 characters",
                            },
                            minLength: {
                              value: 3,
                              message:
                                "Sorry. Too short. Atleast 3 characters required",
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              sx={{ ml: 3, mt: 1 }}
                              error={!!errors?.protectDocketText}
                              helperText={errors?.protectDocketText?.message}
                              variant="standard"
                              placeholder={
                                "What text do you want to use for the watermark?"
                              }
                              label="Watermark text"
                              size="small"
                              style={{ minWidth: 150, maxWidth: 300 }}
                            />
                          )}
                        />
                      )}
                      <Controller
                        name="disableDownloads"
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(e) =>
                                  field.onChange(e.target.checked)
                                }
                                checked={field.value}
                              />
                            }
                            label={
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography variant="body2" color="textPrimary">
                                  Disable downloads of breakout files
                                </Typography>
                              </div>
                            }
                          />
                        )}
                      />
                    </Box>
                  </Collapse>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  width: "100%",
                  justifyContent: newDocket ? "flex-end" : "space-between",
                  alignItems: "center",
                }}
              >
                {!newDocket && docketPermissions.checkCanDeleteDocket(docket) && (
                  <IconButton onClick={handleDocketDelete}>
                    <Delete />
                  </IconButton>
                )}
                <div style={{ display: "flex" }}>
                  <LoadingButton
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    variant="contained"
                    loading={isSubmitting}
                    disabled={
                      !isDirty ||
                      Object.values(errors).length !== 0 ||
                      watch("combined")?.length === 0
                    }
                  >
                    {`${newDocket ? "Setup" : "Update"} Breakout`}
                  </LoadingButton>
                </div>
              </Box>
            </DialogActions>
          </form>
        ) : (
          "You do not have privileges to carry out this action"
        )}
      </FormProvider>
    </Paper>
  );
};

DocketEditForm.propTypes = {
  className: PropTypes.string,
  newDocket: PropTypes.bool,
};

DocketEditForm.defaultProps = {
  newDocket: false,
};

export default DocketEditForm;
