import React from "react";
import { DocketDetailsPanel } from "../foundations";
import { useAppContext } from "@app21/core";
import { FlowBox } from "blocks/atoms/uistyles";
import { useDocketsContext } from "providers/DocketsProvider";

export default function DocketsCenterPanel({ isMobile = false }) {
  return (
    <FlowBox sx={{ gap: 3, overflow: "hidden" }}>
      <DocketDetailsPanel isMobile={isMobile} />
    </FlowBox>
  );
}
