// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
  Box,
  Typography,
  Button,
  Stack,
} from "@mui/material";
import { Send } from "@mui/icons-material";
import isURL from "validator/lib/isURL";
import { checkIfNull } from "@app21/core";
import { getAllUrlParams } from "utils/getAllUrlParams";
import { useRouter } from "next/router";
import { LoadingSpinner } from "blocks/atoms";

const GetMeetingId = ({ onSubmit, currentPath, message }) => {
  const [pathString, setPathString] = React.useState(currentPath ?? "");
  const [onSubmitComplete, setOnSubmitComplete] = React.useState(false);

  const router = useRouter();

  const handleChangePath = (event) => {
    if (event.keyCode !== 13) {
      setPathString(event.target.value);
    }
  };

  const handlePathSubmit = (event) => {
    event?.preventDefault();
    const linkParams = getAllUrlParams(pathString);

    router.push({
      pathname: "/meeting",
      query: { meetingId: linkParams.meetingId },
    });
  };

  const handleKeypress = (event) => {
    if (event.keyCode === 13 && pathString) {
      handlePathSubmit();
    }
  };

  return (
    <Box sx={{ m: 3, flexGrow: 1, display: "flex", flexDirection: "column" }}>
      <Typography variant="body1" sx={{ my: 3 }}>
        {message ? message + ". " : ""} If you have a meeting link, please paste
        it below:
      </Typography>

      <TextField
        value={pathString}
        error={!checkIfNull(pathString) && !isURL(pathString)}
        helperText={
          !checkIfNull(pathString) && !isURL(pathString)
            ? 'Please enter a valid URL. e.g. "https://www.trryst.com/meetingId=12345"'
            : null
        }
        onChange={handleChangePath}
        onKeyUp={handleKeypress}
        autoFocus={true}
        placeholder={"Your Meeting Link"}
        style={{ minWidth: 150 }}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title="Submit" arrow>
                <IconButton
                  size="small"
                  type="submit"
                  color="primary"
                  style={{ marginLeft: "0.8em" }}
                  disabled={onSubmitComplete || !isURL(pathString)}
                  onClick={handlePathSubmit}
                >
                  <Send fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
      <Stack spacing={3} direction="row" sx={{ my: 3 }}>
        <Button
          variant="contained"
          disabled={onSubmitComplete || !isURL(pathString)}
          onClick={handlePathSubmit}
        >
          {onSubmitComplete ? <LoadingSpinner /> : "Submit"}
        </Button>
        <Button onClick={() => router.push("/user")}>Dashboard</Button>
      </Stack>
    </Box>
  );
};

export default GetMeetingId;
