import { SvgIcon } from "@mui/material";
import React from "react";
export default function ChatInactiveWebIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 56 56"
      stroke="#727287"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <rect
          width="56"
          height="56"
          rx="4.8"
          stroke="none"
          fill="currentColor"
        />
        <path
          d="M14.0005 22.402C14.0005 16.802 16.8005 14.002 22.4005 14.002H33.6005C39.2005 14.002 42.0005 16.802 42.0005 22.402V29.402C42.0005 35.002 39.2005 37.802 33.6005 37.802H32.9005C32.4665 37.802 32.0465 38.012 31.7805 38.362L29.6805 41.162C28.7565 42.394 27.2445 42.394 26.3205 41.162L24.2205 38.362C23.9965 38.054 23.4785 37.802 23.1005 37.802H22.4005C16.8005 37.802 14.0005 36.402 14.0005 29.402V28.002"
          strokeWidth="2.1"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.5869 22.4023H35.0009"
          strokeWidth="2.1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21 22.4023H28.714"
          strokeWidth="2.1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21 29.4004H29.4"
          strokeWidth="2.1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
