import React from "react";
import { Box, Paper, IconButton, Typography } from "@mui/material";
import { ReadMore, Close } from "@mui/icons-material";
import { FlowBox } from "blocks/atoms/uistyles";

export default function ComboSidePanelDrawer({
  sidebarModeActive = false,
  sidebarOpen = false,
  sidebarOpenToggle = () => null,
  width = "20%",
  children,
  DrawerSxProps = {},
  sx = {},
}) {
  if (sidebarModeActive) {
    if (!sidebarOpen) {
      return (
        <IconButton
          sx={{
            position: "absolute",
            top: 8,
            left: 0,
          }}
          onClick={sidebarOpenToggle}
        >
          <ReadMore />
        </IconButton>
      );
    } else {
      return (
        <FlowBox
          sx={{
            position: "relative",
            maxWidth: 2,
          }}
        >
          <Paper
            elevation={3}
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              flexDirection: "column",
              height: "100%",
              flexGrow: 1,
              maxWidth: 250,
              transition: "display 0.3s ease-in-out",
              zIndex: 10,
              px: 1,
              py: 1,
              ...DrawerSxProps,
            }}
          >
            <Box
              sx={{
                maxHeight: 1,
                position: "relative",
              }}
            >
              <IconButton
                onClick={sidebarOpenToggle}
                size="small"
                sx={{ position: "absolute", right: 0, top: 0 }}
              >
                <Close fontSize="inherit" />
              </IconButton>
            </Box>

            {children}
          </Paper>
        </FlowBox>
      );
    }
  } else {
    return (
      <FlowBox
        sx={{
          width: width,

          transition: "all 1s ease-in-out",
          ...sx,
        }}
      >
        {children}
      </FlowBox>
    );
  }
}
