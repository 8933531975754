import React from 'react'
import { SvgIcon } from '@mui/material';

export default function MessageQuestionIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M8.33325 48.1662V55.9581C8.33325 68.4581 16.6666 76.7914 29.1666 76.7914H45.8333L64.375 89.1246C67.125 90.9579 70.8333 88.9998 70.8333 85.6665V76.7914C83.3333 76.7914 91.6666 68.4581 91.6666 55.9581V30.9578C91.6666 18.4578 83.3333 10.1245 70.8333 10.1245H29.1666C16.6666 10.1245 8.33325 18.4578 8.33325 30.9578"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M50.0003 47.3335V46.4587C50.0003 43.6253 51.7504 42.1253 53.5004 40.9169C55.2087 39.7503 56.9169 38.2503 56.9169 35.5003C56.9169 31.667 53.8336 28.5835 50.0003 28.5835C46.167 28.5835 43.0837 31.667 43.0837 35.5003"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M49.9813 57.2913H50.0188"
          stroke="currentColor"
          strokeWidth="5.335"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
