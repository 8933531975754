import React from 'react'
import { SvgIcon } from '@mui/material';

export default function ChangeLayOutIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M8.33337 78.5415V67.2915C8.33337 57.9165 12.0834 54.1665 21.4584 54.1665H32.7084C42.0834 54.1665 45.8334 57.9165 45.8334 67.2915V78.5415C45.8334 87.9165 42.0834 91.6665 32.7084 91.6665H21.4584"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M91.6667 62.5C91.6667 78.625 78.625 91.6667 62.5 91.6667L66.875 84.375"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.33337 37.5002C8.33337 21.3752 21.375 8.3335 37.5 8.3335L33.125 15.6252"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M72.9166 45.8335C83.272 45.8335 91.6666 37.4388 91.6666 27.0835C91.6666 16.7282 83.272 8.3335 72.9166 8.3335C62.5613 8.3335 54.1666 16.7282 54.1666 27.0835C54.1666 37.4388 62.5613 45.8335 72.9166 45.8335Z"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
