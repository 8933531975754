// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Chip, Typography, Avatar } from "@mui/material";
import {
  CalendarIcon,
  HomeIcon,
  BreakoutIcon,
  DashboardIcon,
  FilesIcon,
  MicOnIcon,
  PlayRecordingsIcon,
  DataroomIcon,
  TaskIcon,
  SurveyIcon,
  PollIcon,
  ManageSettingsIcon,
  AddIcon,
  SettingsIcon,
  ProfileIcon,
  AdminIcon,
} from "blocks/atoms/trrysticons";

import {
  Trello as TrelloIcon,
  Tool as ToolIcon,
  MessageCircle as MessageCircleIcon,
  Bell as NotificationsIcon,
} from "react-feather";
import {
  BallotOutlined,
  TrendingUp,
  LockOpen,
  ManageAccounts,
  Analytics,
  MonetizationOn,
  AddCircle,
  AutoGraph,
} from "@mui/icons-material";
import { SuiteSwitchButton } from "blocks/views/SuiteDashboard/foundations";
import clip from "text-clipper";
import { getS3ImageWrapper } from "@app21/core";
import { UserAvatar, PrintUserInfo } from "blocks/atoms";

const getNavbarItems = ({ orgId, suiteId, selectedSuite, userInfo, t }) => {
  const isPersonalSuite = selectedSuite?.suiteType?.includes("PERSONAL");
  const isOwnPersonalSuite =
    isPersonalSuite && selectedSuite?.createdBy === userInfo?._id;
  const navbarItemsList = [
    {
      items: [
        {
          title: `${t("SideMenu.home")}`,
          icon: HomeIcon,
          href: `/user`,
          route: "GOTO-MAINDASHBOARD",
          as: `/user`,
          menuLabel: "home",
          context: "all",
          permissionKey: "any",
          featureName: "GENERAL",
        },
        {
          title: isPersonalSuite ? (
            isOwnPersonalSuite ? (
              "My Personal Suite"
            ) : (
              <Typography>
                Personal Suite (
                <PrintUserInfo userId={selectedSuite.createdBy} />)
              </Typography>
            )
          ) : (
            clip(selectedSuite?.fullName ?? `${t("SideMenu.suite")}`, 17, {
              breakWords: true,
            })
          ),
          icon: DashboardIcon,
          avatar: isPersonalSuite ? (
            <UserAvatar userId={selectedSuite?.createdBy} />
          ) : (
            <Avatar
              sx={{ height: 25, width: 25 }}
              src={getS3ImageWrapper(
                selectedSuite?.image,
                "suite",
                selectedSuite?._id
              )}
            />
          ),
          href: `/user/organization/suite?orgId=${orgId}&suiteId=${suiteId}`,
          route: "VIEW-SUITE",
          menuLabel: "suite",
          context: "suite",
          permissionKey: "any",
          featureName: "GENERAL",
          secondAction: () => <SuiteSwitchButton viewOption="chipOnly" />,
        },
      ],
    },
    {
      subheader: `${t("SideMenu.modules")}`,
      context: "none",
      items: [
        {
          title: `${
            selectedSuite?.suiteType?.includes("BOARD")
              ? t("SideMenu.boardMeetings")
              : t("SideMenu.dockets")
          }`,
          icon: BreakoutIcon,
          href: `/user/organization/suite/docket/list?orgId=${orgId}&suiteId=${suiteId}`,
          route: "LIST-DOCKETS",
          menuLabel: "docket",
          context: "suite",
          permissionKey: "SHOW-DOCKET-MENU", //'List-Dockets',
          featureName: "DOCKET",
        },
        {
          title: `${t("SideMenu.files")}`,
          href: `/user/organization/suite/dataroom?s3Key=${encodeURIComponent(
            `${suiteId}/`
          )}&orgId=${orgId}&suiteId=${suiteId}`,
          route: "VIEW-DATAROOM",
          icon: DataroomIcon,
          menuLabel: "Files",
          context: "suite",
          permissionKey: "SHOW-DATAROOM-MENU",
          featureName: "DATAROOM",
        },
        {
          title: `${t("SideMenu.schedule")}`,
          href: `/user/organization/suite/calendar?orgId=${orgId}&suiteId=${suiteId}`,
          route: "VIEW-CALENDAR",
          icon: CalendarIcon,
          menuLabel: "Schedule",
          context: "suite",
          permissionKey: "SHOW-CALENDAR-MENU", //'VIEW-CALENDAR',
          featureName: "CALENDAR",
        },
        {
          title: `${t("SideMenu.notifications")}`,
          href: "/",
          icon: NotificationsIcon,
          // eslint-disable-next-line react/display-name
          info: (props) =>
            props?.notificationsCount && (
              <Chip
                color="secondary"
                size="small"
                label={props.notificationsCount}
              />
            ),
          menuLabel: "Notifications",
          context: "none",
          permissionKey: "any",
          featureName: "NOTIFICATIONS",
        },
        {
          title: `${t("SideMenu.tasks")}`,
          href: `/user/organization/suite/tasks?orgId=${orgId}&suiteId=${suiteId}`,
          route: "LIST-TASKS",
          icon: TaskIcon,
          menuLabel: "Tasks",
          context: "suite",
          permissionKey: "SHOW-ACTIONS-MENU", //'LIST-ACTIONS',
          featureName: "ACTIONS",
        },

        {
          title: `${t("SideMenu.surveys")}`,
          href: `/user/organization/suite/surveys?orgId=${orgId}&suiteId=${suiteId}`,
          route: "LIST-SURVEYS",
          icon: PollIcon,
          menuLabel: "Surveys",
          context: "suite",
          permissionKey: "SHOW-SURVEYS-MENU", //'LIST-SURVEYS',
          featureName: "SURVEYS",
        },
        {
          title: `${t("SideMenu.chat")}`,
          href: `/user/organization/suite/chatroom?orgId=${orgId}&suiteId=${suiteId}&chatId=${suiteId}&chatType=group`,
          route: "GROUP-CHAT",
          icon: MessageCircleIcon,
          // eslint-disable-next-line react/display-name
          info: (props) =>
            props?.messagesCount ? (
              <Chip
                color="secondary"
                size="small"
                label={props.messagesCount}
              />
            ) : null,
          menuLabel: "Comet-Chat",
          context: "none",
          permissionKey: "SHOW-DISCUSS-MENU", //'VIEW-DISCUSS',
          featureName: "CHAT",
        },
        {
          title: `${t("SideMenu.transcriptsFolder")}`,
          href: `/user/organization/suite/transcripts?orgId=${orgId}&suiteId=${suiteId}`,
          route: "VIDEOCALL-TRANSCRIPTS",
          icon: PlayRecordingsIcon,
          // eslint-disable-next-line react/display-name
          context: "suite",
          menuLabel: "VIDEOCALL-TRANSCRIPTS",
          permissionKey: "SHOW-CALENDAR-MENU", //'Substitute for VIEW-Records. To be changed later',
          featureName: "VIDEOCALL",
        },
        {
          title: `${t("SideMenu.addNew")}`,
          icon: AddIcon,
          href: `/user/manage?choice=addsuite`,
          route: "ADD-SUITE",
          params: { choice: "addsuite" },
          menuLabel: "addnew",
          context: "main",
          permissionKey: "any",
          featureName: "GENERAL",
        },
        {
          title: `${t("SideMenu.admin")}`,
          icon: AdminIcon,
          href: `/user/manage?choice=adminconsole`,
          route: "ADMIN-CONSOLE",
          params: { choice: "adminconsole" },
          menuLabel: "Admin-Organization",
          context: "main",
          permissionKey: "orgAdminCheck",
          featureName: "MANAGEMENT-CONSOLE",
        },
        // {
        //   title: `${t("SideMenu.manageOrg")}`,
        //   icon: ManageAccounts,
        //   href: `/user/manage?choice=orgadmin`,
        //   route: "MANAGE-ORGANIZATION",
        //   params: { choice: "orgadmin" },
        //   menuLabel: "Admin-Organization",
        //   context: "main",
        //   permissionKey: "orgAdminCheck",
        //   featureName: "MANAGEMENT-CONSOLE",
        // },
        // {
        //   title: `${t("SideMenu.manageAnalytics")}`,
        //   icon: Analytics,
        //   href: `/user/manage?choice=analytics`,
        //   route: "ADMIN-ANALYTICS",
        //   params: { choice: "analytics" },
        //   menuLabel: "Admin-Organization",
        //   context: "main",
        //   permissionKey: "orgAdminCheck",
        //   featureName: "MANAGEMENT-CONSOLE",
        // },
        // {
        //   title: `${t("SideMenu.manageBilling")}`,
        //   icon: MonetizationOn,
        //   href: `/user/manage?choice=billing`,
        //   route: "ADMIN-BILLING",
        //   params: { choice: "billing" },
        //   menuLabel: "Admin-Organization",
        //   context: "main",
        //   permissionKey: "orgAdminCheck",
        //   featureName: "MANAGEMENT-CONSOLE",
        // },
        // {
        //   title: `${t("SideMenu.permissionsSettings")}`,
        //   icon: LockOpen,
        //   href: `/user/manage?choice=permissions`,
        //   params: { choice: "permissions" },
        //   route: "ADMIN-PERMISSIONS",
        //   menuLabel: "ADMIN-PERMISSIONS",
        //   context: process.env.STAGE === "prod" ? "none" : "main",
        //   permissionKey: "trrystAdminCheck",
        //   featureName: "MANAGEMENT-CONSOLE",
        // },
        // {
        //   title: `${t("SideMenu.trrystAnalyticsSettings")}`,
        //   icon: AutoGraph,
        //   href: `/user/manage?choice=trrystanalytics`,
        //   params: { choice: "trrystanalytics" },
        //   route: "TRRYST-ANALYTICS",
        //   menuLabel: "TRRYST-ANALYTICS",
        //   context: "main",
        //   permissionKey: "trrystAdminCheck",
        //   featureName: "MANAGEMENT-CONSOLE",
        // },
        {
          title: `${t("SideMenu.notificationSettings")}`,
          icon: NotificationsIcon,
          href: `/user/manage?choice=notifications`,
          menuLabel: "Admin-Notifications",
          params: { choice: "notifications" },
          context: "none",
          permissionKey: "orgAdminCheck",
          featureName: "MANAGEMENT-CONSOLE",
        },
        {
          title: `${t("SideMenu.audit")}`,
          icon: TrendingUp,
          href: `/user/manage?choice=usage`,
          menuLabel: "Admin-Usage",
          params: { choice: "usage" },
          context: "none",
          permissionKey: "orgAdminCheck",
          featureName: "MANAGEMENT-CONSOLE",
        },
        {
          title: `${t("SideMenu.manageSuite")}`,
          icon: ManageSettingsIcon,
          href: `/user/organization/suite/manage?orgId=${orgId}&suiteId=${suiteId}`,
          route: "MANAGE-SUITE",
          menuLabel: "Manage-Suite",
          context: "suite",
          permissionKey: "SHOW-SETTINGS-MENU",
          featureName: "MANAGEMENT-CONSOLE",
        },
      ],
    },

    {
      subheader: `${t("SideMenu.settings")}`,
      context: "none",
      items: [
        {
          title: `${t("SideMenu.admin")}`,
          icon: SettingsIcon,
          href: `/user/manage`,
          menuLabel: "Admin",
          context: "none",
          permissionKey: "orgAdminCheck",
          featureName: "MANAGEMENT-CONSOLE",
          items: [],
        },
        {
          title: `${t("SideMenu.editProfile")}`,
          icon: ProfileIcon,
          href: "/user/manage?choice=userprofile",
          menuLabel: "Edit-Profile",
          context: "none",
          permissionKey: "any",
          featureName: "GENERAL",
        },
      ],
    },
  ];

  return navbarItemsList;
};

export default getNavbarItems;
