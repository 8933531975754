import React from "react";
import { Dialog, styled, alpha, DialogActions } from "@mui/material";

export const StyledStandardDialog = styled((props) => (
  <Dialog fullWidth maxWidth="sm" {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
}));

export const StyledStandardDialogActions = styled((props) => (
  <DialogActions {...props} />
))(({ theme }) => ({
  padding: theme.spacing(2),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));
