import React, { useState, useEffect, useCallback, useRef } from "react";
import { usePubNub } from "pubnub-react";
import { useAtom } from "jotai";
import {
  CurrentChannelTypingIndicatorAtom,
  ThemeAtom,
  TypingIndicatorTimeoutAtom,
  UsersMetaAtom,
} from "blocks/modules/TrrystChat/state-atoms";
import isEqual from "lodash.isequal";
import {UserAvatar} from 'blocks/atoms'
import {Typography, AvatarGroup, Box} from '@mui/material'
import { useAppContext } from "@app21/core";
/** Subscribes to events generated by MessageInput to display information about users that are
 * currently typing messages.
 *
 * It can be displayed as a text denoting the user's name, or in a form similar to
 * a message that can be renderer inside MessageList.
 */
const TypingIndicator = (props) => {
  const pubnubChatClient = usePubNub();
  const [typingIndicators] = useAtom(CurrentChannelTypingIndicatorAtom);
  const [typingIndicatorTimeout] = useAtom(TypingIndicatorTimeoutAtom);
  const [activeUUIDs, setActiveUUIDs] = useState([]);
  const typingIndicatorsRef = useRef(typingIndicators);

  if (!isEqual(typingIndicatorsRef.current, typingIndicators)) {
    typingIndicatorsRef.current = typingIndicators;
  }

  const calculateActiveUUIDs = useCallback(() => {
    const currentActiveUUIDs = Object.keys(typingIndicators).filter(
      (id) =>
        Date.now() - parseInt(typingIndicators[id]) / 10000 <
        typingIndicatorTimeout * 1000
    );
    const currentActiveUUIDsWoCurrent = currentActiveUUIDs.filter(
      (id) => id !== pubnubChatClient.getUUID()
    );
    setActiveUUIDs(currentActiveUUIDsWoCurrent);
  }, [typingIndicatorsRef.current]);

  const getIndicationString = () => {
    if (activeUUIDs.length === 0) {
      return "";
    }
    if (activeUUIDs.length > 1) return <Box sx={{display:'flex', alignItems: 'center' }}> <AvatarGroup max={6}>{
      activeUUIDs.map((uuid) => <UserAvatar key={uuid} userId={uuid} size={20} />)
      
    }</AvatarGroup><Typography variant='caption' sx={{fontStyle: 'italic'}} > are typing...</Typography> </Box>
   
    else return <Typography variant='caption' sx={{fontStyle: 'italic'}}><UserAvatar userId={activeUUIDs[0]} viewContext='name' /> is typing...</Typography>  ;
  }
  

  useEffect(() => {
    calculateActiveUUIDs();
    const interval = setInterval(calculateActiveUUIDs, 1000);
    return () => clearInterval(interval);
  }, [calculateActiveUUIDs]);

  // const renderUserBubble = (uuid) => {
  //  // const user = usersInSuite.find((u) => u._id === uuid);

  //   return (
  //     <div className="pn-msg" key={uuid}>
  //       <div className="pn-msg__avatar">
  //         {user?.profileUrl && <img src={user.profileUrl} alt="User avatar" />}
  //         {!user?.profileUrl && <div className="pn-msg__avatar-placeholder" />}
  //       </div>

  //       <div className="pn-msg__main">
  //         <div className="pn-msg__title">
  //           <span className="pn-msg__author">
  //             {user?.fullName || "Unknown User"}
  //           </span>
  //         </div>
  //         <div className="pn-msg__bubble">
  //           <span className="pn-typing-indicator-dot">●</span>
  //           <span className="pn-typing-indicator-dot">●</span>
  //           <span className="pn-typing-indicator-dot">●</span>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  return (
    <>
      {!props.showAsMessage && !!activeUUIDs.length && (
        <span>
          {getIndicationString()}
        </span>
      )}
      {/* {props.showAsMessage && activeUUIDs.map((uuid) => renderUserBubble(uuid))} */}
    </>
  );
};

TypingIndicator.defaultProps = {
  showAsMessage: false,
};

export default TypingIndicator;
