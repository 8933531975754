import React from 'react'
import { SvgIcon } from '@mui/material';

export default function ShareFileIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M8.33337 37.5002C8.33337 16.6668 16.6667 8.3335 37.5 8.3335H58.3334"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M91.6667 41.6665V62.4998C91.6667 83.3332 83.3334 91.6665 62.5 91.6665H37.5C16.6667 91.6665 8.33337 83.3332 8.33337 62.4998V54.0832"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M75 41.6668C62.5 41.6668 58.3334 37.5002 58.3334 25.0002V8.3335L91.6667 41.6668"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
