// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import { isVideoTransformDevice } from "amazon-chime-sdk-js";
import React, { useState, useEffect } from "react";
import {
  useBackgroundBlur,
  useBackgroundReplacement,
  useVideoInputs,
  useMeetingManager,
  useLocalVideo,
  useLogger,
} from "amazon-chime-sdk-component-library-react";
import _ from "lodash";
import {
  VideoTransformOptions,
  useChimeState,
} from "providers/TrrystVideocallProvider";
import {
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Button,
  Box,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
} from "@mui/material";
import Image from "next/image";
import isEqual from "lodash.isequal";
import { useMemoCompare } from "hooks";
import { BlurOn, AutoFixHigh, AutoFixOff } from "@mui/icons-material";
import { pxToRem } from "utils";

const VideoTransformChoice = ({
  label = "Video Transform Choices",
  controlType = "button",
  RenderComponent,
  Icon = BlurOn,
  handleClick = () => {
    /* */
  },
  ...rest
}) => {
  const meetingManager = useMeetingManager();
  const logger = useLogger();
  const { devices, selectedDevice } = useVideoInputs();
  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  const { isBackgroundBlurSupported, createBackgroundBlurDevice } =
    useBackgroundBlur();
  const { loadReplacementImageBlob, setChangedUserPreferencesFlag } =
    useChimeState();
  const {
    isBackgroundReplacementSupported,
    createBackgroundReplacementDevice,
    backgroundReplacementProcessor,
  } = useBackgroundReplacement();
  const { activeVideoTransformOption, setActiveVideoTransformOption } =
    useChimeState();
  const handleDialogClose = () => {
    setSelectDialogForBackgroundImageActive(false);
  };

  // Reset the video input to intrinsic if current video input is a transform device because this component
  // does not know if blur or replacement was selected. This depends on how the demo is set up.
  // TODO: use a hook in the appState to track whether blur or replacement was selected before this component mounts,
  // or maintain the state of `activeVideoTransformOption` in `MeetingManager`.
  const resetDeviceToIntrinsic = async () => {
    try {
      if (isVideoTransformDevice(selectedDevice)) {
        const intrinsicDevice = await selectedDevice.intrinsicDevice();
        await meetingManager.selectVideoInputDevice(intrinsicDevice);
      }
    } catch (error) {
      logger.error("Failed to reset Device to intrinsic device");
    }
  };

  // Creates a device based on the selections (None, Blur, Replacement) and uses it as input.

  // useEffect(() => {
  //   resetDeviceToIntrinsic();
  // }, []);

  // Both hooks are needed because this component uses both blur and replacement filters.
  const [
    selectDialogForBackgroundImageActive,
    setSelectDialogForBackgroundImageActive,
  ] = React.useState(false);

  const handleImageChange = () => {
    setSelectDialogForBackgroundImageActive(true);
    handleClick();
  };

  const getTransformationDisplayComponent = (option) => {
    const isActiveOption = option[0] === activeVideoTransformOption;
    switch (true) {
      case option[0].includes("BLURLOW"):
        return (
          <Button
            size="medium"
            disabled={isActiveOption}
            sx={{
              height: "100px",
              width: "150px",
              border: (theme) =>
                isActiveOption
                  ? `2px solid ${theme.palette.primary.main}`
                  : null,
            }}
            variant="contained"
            endIcon={<BlurOn fontSize="small" sx={{ ml: 1 }} />}
          >
            Low Blur
          </Button>
        );
      case option[0].includes("BLURHIGH"):
        return (
          <Button
            size="medium"
            disabled={isActiveOption}
            sx={{
              height: "100px",
              width: "150px",
              border: (theme) =>
                isActiveOption
                  ? `2px solid ${theme.palette.primary.main}`
                  : null,
            }}
            variant="contained"
            endIcon={<BlurOn fontSize="large" sx={{ ml: 1 }} />}
          >
            High Blur
          </Button>
        );
      case option[0].includes("REPLACEMENT"):
        return (
          <Box
            sx={{
              height: "100px",
              width: "150px",
              border: (theme) =>
                isActiveOption
                  ? `2px solid ${theme.palette.primary.main}`
                  : null,

              pointerEvents: isActiveOption ? "none" : null,
            }}
          >
            <Image
              height={100}
              width={150}
              src={option[1].value}
              alt={option[1].label}
              style={{
                maxWidth: "100%",
                height: "auto",
                objectFit: "cover",
                borderRadius: 10,
              }}
            />
          </Box>
        );
      default:
        return (
          <Button
            disabled={isActiveOption}
            sx={{
              height: "100px",
              width: "150px",
              border: (theme) =>
                isActiveOption
                  ? `2px solid ${theme.palette.primary.main}`
                  : null,
            }}
            variant="outlined"
          >
            None
          </Button>
        );
    }
  };
  const renderAction = () => {
    let DisplayComponent = RenderComponent || MenuItem;

    switch (controlType) {
      case "button":
        return (
          <Button
            startIcon={<AutoFixHigh />}
            variant="contained"
            onClick={handleImageChange}
            sx={{
              borderRadius: 1,
              height: "100%",
              width: pxToRem(80),
              height: pxToRem(40),
            }}
            {...rest}
          >
            {label}
          </Button>
        );
      case "menu":
        return (
          <DisplayComponent onClick={handleImageChange} {...rest}>
            <ListItemIcon>
              <BlurOn />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  {label}
                </Typography>
              }
            />
          </DisplayComponent>
        );
      case "icon":
        return (
          <Button
            variant="contained"
            onClick={handleImageChange}
            sx={{ borderRadius: 0.5 }}
            {...rest}
          >
            <AutoFixHigh />
          </Button>
        );
      default:
        return null;
    }
  };

  return (
    <Box sx={{ zIndex: 3 }}>
      {renderAction()}
      <Dialog
        open={selectDialogForBackgroundImageActive}
        onClose={handleDialogClose}
      >
        <DialogTitle onClose={handleDialogClose}>
          Select Background Transformation Option
        </DialogTitle>
        <DialogContent>
          <Box sx={{ background: "#fff", p: 4 }}>
            <Grid
              container
              columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }}
              spacing={1}
            >
              {Object.entries(VideoTransformOptions).map((option, index) => {
                return (
                  <Grid key={index} item>
                    <Box
                      sx={{ cursor: "pointer" }}
                      onClick={async () => {
                        if (option[0].includes("REPLACE")) {
                          await loadReplacementImageBlob(option[1].value);
                        }
                        setChangedUserPreferencesFlag(true);
                        setActiveVideoTransformOption(option[0]);
                        handleDialogClose();
                      }}
                    >
                      {getTransformationDisplayComponent(option)}
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default VideoTransformChoice;
