import React from "react";
import { Box } from "@mui/material";
import { SelectChatSidebarContext, ShowChatGroupCards } from "../foundations";

export default function ChatSidebar({ isMobile = false }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",

        height: "100%",
        width: "100%",
        maxWidth: isMobile ? "100%" : "300px",
        overflow: "hidden",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <SelectChatSidebarContext />
      </Box>
      <ShowChatGroupCards />
    </Box>
  );
}
