import React from 'react'
import { SvgIcon } from "@mui/material";

export default function FilesDownloadedIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M50 8.33398V33.334L58.3333 25.0007"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M49.9992 33.3333L41.6659 25"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M50 91.6667H66.6667C83.3333 91.6667 87.5 82.3333 87.5 70.8333V66.6667C87.5 57.4583 87.5 50 70.8333 50C66.6667 50 65.5 50.875 63.3333 52.5L59.0833 57C54.1667 62.25 45.8333 62.25 40.875 57L36.6667 52.5C34.5 50.875 33.3333 50 29.1667 50C12.5 50 12.5 57.4583 12.5 66.6667V70.8333C12.5 82.3333 12.5 91.6667 33.3333 91.6667"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.8341 50.0006V33.334C20.8341 24.959 20.8341 18.0423 33.3341 16.834"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M79.1659 50.0006V33.334C79.1659 24.959 79.1659 18.0423 66.6659 16.834"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
