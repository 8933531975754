import React, { useRef } from "react";
import { Box, Container, Typography, Grid, styled } from "@mui/material";
import { SurveysMenubar, SurveyDetailsPanel } from "../foundations";
import FileViewer from "blocks/modules/FileViewer";
import { checkIfNull, useAppContext } from "@app21/core";
import { FlowBox } from "blocks/atoms/uistyles";
import { useSurveysContext } from "providers/SurveysProvider";

export default function SurveysCenterPanel({ isMobile = false }) {
  const { viewFile } = useAppContext();
  const { viewContext, actionContext, selectedDecisionId } =
    useSurveysContext();
  const fileViewerFlag = !checkIfNull(viewFile);

  return (
    <FlowBox>
      {fileViewerFlag ? (
        <FileViewer viewContext={viewContext} />
      ) : (
        <>
          {actionContext !== "add" && selectedDecisionId && (
            <SurveysMenubar isMobile={isMobile} />
          )}
          <FlowBox
            sx={{
              overflow: "hidden",
            }}
          >
            <FlowBox sx={{}}>
              <FlowBox
                sx={{
                  overflow: "hidden",
                }}
              >
                <SurveyDetailsPanel isMobile={isMobile} />
              </FlowBox>
            </FlowBox>
          </FlowBox>
        </>
      )}
    </FlowBox>
  );
}
