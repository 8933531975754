// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import {
  Button,
  IconButton,
  SvgIcon,
  Tooltip,
  MenuItem,
  Typography,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { MenuPopover, handleWalkinCall, IconButtonAnimate } from "blocks/atoms";
import { styled } from "@mui/material/styles";
import { OfflineBolt, Schedule, AddIcCall } from "@mui/icons-material";
import { VideocallIcon } from "blocks/atoms/trrysticons";
import * as React from "react";
import {
  useAppContext,
  useHandleRouterPush,
  useHandleAddRemoveIdFromRoute,
  executeApi,
  useUserInfo,
} from "@app21/core";
import { useIntl } from "react-intl";
import { Video as VideoIcon } from "react-feather";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";

import { useRouter } from "next/router";
import { useCheckActiveLink, useIsMobileBreakpoint } from "hooks";

import toast from "react-hot-toast";
import { useVideocallContext } from "providers/TrrystVideocallProvider";
import { menuActionTestIds } from "./constants";

const StyledButton = styled(Button)(({ theme, ownerState }) => {
  const { isActive } = ownerState;
  return {
    margin: 3,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
    color: isActive
      ? theme.palette.secondary.main
      : theme.palette.text.secondary,
  };
});

const ScheduleConference = ({
  disabled,
  iconSize = "medium",
  type,
  buttonLabel = "Join",
  meetingId: propMeetingId,
  docketId,
  ...rest
}) => {
  const router = useRouter();
  const isMobile = useIsMobileBreakpoint();
  const isConferenceLinkActive = useCheckActiveLink("JOIN-MEETING");
  const {
    data: userInfo,
    apiError: userInfoApiError,
    status: userInfoStatus,
  } = useUserInfo();
  const { loadRoute } = useHandleRouterPush();
  const { addIdToRoute } = useHandleAddRemoveIdFromRoute();
  const { setChimeRoute } = useVideocallContext();
  React.useEffect(() => {
    if (userInfoApiError && userInfoStatus != "loading") {
      toast.error(userInfoApiError.message);
    }
  }, [userInfoApiError]);

  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });

  const { meetingId } = useAppContext();

  const { selectedSuiteId, checkAccessRules } = useAppContext();

  const statusSelectPopupState = usePopupState({
    variant: "popover",
    popupId: "statusSelectMenu",
  });

  const CustomButton = type === "icon" ? IconButtonAnimate : Button;
  const buttonProps = (type) => {
    let props = {};
    props.disabled = disabled;

    return props;
  };
  const initiateConference = () => {
    setChimeRoute("base");
    handleWalkinCall({
      docketId,
      userInfo,
      selectedSuiteId,
      addIdToRoute,
      router,
    });
  };
  const { accessFlag: canAccessVideocall } = checkAccessRules({
    entity: "MENU",
    action: "SHOW-VIDEOCALL-MENU",
    featureName: "VIDEOCALL",
    isInvitee: true,
  });
  const { accessFlag: canstartWalkinCall } = checkAccessRules({
    entity: "SUITE",
    action: "START-WALKINCALL",
    featureName: "VIDEOCALL",
    isInvitee: true,
  });
  if (canAccessVideocall) {
    if (type === "joinButton") {
      return (
        <CustomButton
          size={iconSize}
          {...buttonProps(type)}
          onClick={() => {
            docketId
              ? initiateConference()
              : loadRoute("START-MEETING", {
                  meetingId: propMeetingId ?? meetingId,
                });
          }}
          {...rest}
          startIcon={<VideocallIcon fontSize="inherit" />}
          style={{ marginRight: isMobile && "-8px" }}
        >
          {!isMobile && buttonLabel}
        </CustomButton>
      );
    }
    return (
      <Tooltip title="Start a VideoCall" arrow>
        <>
          <CustomButton
            data-testid={menuActionTestIds.conferenceButtonTestId}
            size={iconSize}
            {...buttonProps(type)}
            {...bindTrigger(statusSelectPopupState)}
            {...rest}
          >
            {type === "prompt" ? (
              <span style={{ marginRight: 10 }}>
                {!isMobile && buttonLabel}{" "}
              </span>
            ) : null}
            <SvgIcon
              fontSize="inherit"
              sx={{
                color: (theme) =>
                  isConferenceLinkActive
                    ? theme.palette.secondary.main
                    : theme.palette.text.secondary,
              }}
            >
              <VideocallIcon fontSize="inherit" />
            </SvgIcon>
          </CustomButton>
          <MenuPopover
            {...bindMenu(statusSelectPopupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            arrow="top-center"
          >
            {canstartWalkinCall && (
              <MenuItem
                data-testid={menuActionTestIds.walkinCallTestId}
                disabled={Boolean(meetingId)}
                onClick={initiateConference}
              >
                <ListItemIcon>
                  <Tooltip
                    title={`${t("SuiteDashboard.Meeting.StartInstantMeet")}`}
                    arrow
                  >
                    <OfflineBolt />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      color="textSecondary"
                      variant="subtitle2"
                      sx={{ ml: -2 }}
                    >
                      {`${t("SuiteDashboard.Meeting.WalkInMeet")}`}
                    </Typography>
                  }
                />
              </MenuItem>
            )}
            <MenuItem
              data-testid={menuActionTestIds.scheduleVideoCallTestId}
              onClick={() => loadRoute("SCHEDULE-VIDEOCALL", {})}
            >
              <ListItemIcon>
                <Tooltip
                  title={`${t(
                    "SuiteDashboard.ToolTips.ScheduleCloseGroupMeet"
                  )}`}
                  arrow
                >
                  <Schedule fontSize="medium" />
                </Tooltip>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    color="textSecondary"
                    variant="subtitle2"
                    sx={{ ml: -2 }}
                  >
                    {`${t("SuiteDashboard.Meeting.scheduleVideoCall")}`}
                  </Typography>
                }
              />
            </MenuItem>
            <MenuItem
              data-testid={menuActionTestIds.joinUpcomingMeetingTestId}
              onClick={() => loadRoute("JOIN-MEETING", {})}
            >
              <ListItemIcon>
                <Tooltip
                  title="Click here to join an ongoing or upcoming meeting"
                  arrow
                >
                  <AddIcCall fontSize="medium" />
                </Tooltip>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    color="textSecondary"
                    variant="subtitle2"
                    sx={{ ml: -2 }}
                  >
                    Join Upcoming meeting
                  </Typography>
                }
              />
            </MenuItem>
          </MenuPopover>
        </>
      </Tooltip>
    );
  } else return <></>;
};
export default ScheduleConference;
