// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Paper } from "@mui/material";

import PropTypes from "prop-types";

import {
  Roster,
  RosterHeader,
  RosterGroup,
  useRosterState,
  RosterAttendee,
  useMeetingManager,
} from "amazon-chime-sdk-component-library-react";
import Scrollbar from "blocks/atoms/uicomponents/Scrollbar";
import CopyMeetingLinkControl from "../CopyMeetingLinkControl";
import { checkIfNull, useAppContext } from "@app21/core";

const AttendeesRoster = () => {
  const { roster } = useRosterState();
  let attendees = Object.values(roster);

  const [filter, setFilter] = React.useState("");
  const meetingManager = useMeetingManager();
  const { meetingId } = useAppContext();

  const rosterList = () => {
    let filteredAttendees = attendees;
    if (filter) {
      filteredAttendees = attendees.filter((attendee) => {
        if (
          attendee.chimeAttendeeId ===
          meetingManager?.configuration?.credentials.attendeeId
        ) {
          return "Me";
        } else {
          return attendee?.name
            .toLowerCase()
            .includes(filter.trim().toLowerCase());
        }
      });
    }
    const handleSearch = (event) => {
      setFilter(event.target.value);
    };

    const attendeeItems = filteredAttendees.map((attendee) => {
      const { chimeAttendeeId } = attendee || {};
      return (
        <div key={chimeAttendeeId}>
          <RosterAttendee attendeeId={chimeAttendeeId} />
        </div>
      );
    });

    return (
      <Paper
        sx={{
          height: 100 + attendees.length * 60,
          maxHeight: 550,
        }}
      >
        <Roster>
          <Scrollbar
            sx={{
              height: "100%",
              padding: 0,
              "& .simplebar-content": {
                height: "100%",
                display: "flex",
                flexDirection: "column",
              },
            }}
          >
            <RosterHeader
              searchValue={filter}
              onSearch={handleSearch}
              onClose={null}
              title="Present"
              badge={attendees.length}
            />
            <RosterGroup>{attendeeItems}</RosterGroup>
          </Scrollbar>
        </Roster>
      </Paper>
    );
  };

  return (
    <>
      <CopyMeetingLinkControl meetingId={meetingId} componentType="panel" />
      {rosterList()}
    </>
  );
};
AttendeesRoster.propTypes = {
  attendeeInfoModal: PropTypes.bool.isRequired,
};

AttendeesRoster.defaultProps = {
  attendeeInfoModal: false,
};
export default AttendeesRoster;
