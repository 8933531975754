// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import { SvgIcon } from "@mui/material";

export default function PersonalSuiteIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 700 550"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      sx={{ width: "100%", height: "100%" }}
      {...props}
    >
      <g>
        <path
          fill="#fdc500"
          d="m310.02 126.75c-12.848 0-23.309-10.457-23.309-23.309s10.457-23.309 23.309-23.309 23.309 10.457 23.309 23.309c-0.007813 12.852-10.457 23.309-23.309 23.309z"
        />
        <path
          fill="#fdc500"
          d="m288.4 210.12h43.004l12.59-49.57c3.2461-12.27 2.7617-16.238 2.418-17.336-4.3242-14.488-17.668-14.035-18.273-14.012h-36.242c-11.66 0-17.047 10.863-17.328 11.441-2.8945 6.5781 0.3125 17.23 0.84375 18.898l12.168 48.105zm22.207-34.652-7.168-27.164 6.0664-10.617h-2.5352v-5.8281h7.6758v5.8281h-2.7109l6.2617 10.098z"
        />
        <path d="m356.88 52.785v110.23h96.512v-110.23zm78.977 83.375h-65.801l27.781-40.945 15.355 14.605 22.664-31.605z" />
        <path d="m522.2 484.81h-344.4l67.199-267.4h210z" />
        <path
          fill="#fdc500"
          d="m172.2 445.61c0 21.652-17.551 39.199-39.199 39.199s-39.199-17.547-39.199-39.199c0-21.648 17.551-39.199 39.199-39.199s39.199 17.551 39.199 39.199"
        />
        <path
          fill="#fdc500"
          d="m606.2 445.61c0 21.652-17.551 39.199-39.199 39.199s-39.199-17.547-39.199-39.199c0-21.648 17.551-39.199 39.199-39.199s39.199 17.551 39.199 39.199"
        />
        <path
          fill="#fdc500"
          d="m564.2 348.31c0 18.172-14.73 32.902-32.898 32.902-18.172 0-32.902-14.73-32.902-32.902 0-18.168 14.73-32.898 32.902-32.898 18.168 0 32.898 14.73 32.898 32.898"
        />
        <path
          fill="#fdc500"
          d="m198.8 351.11c0 18.172-14.73 32.902-32.902 32.902-18.168 0-32.898-14.73-32.898-32.902 0-18.168 14.73-32.898 32.898-32.898 18.172 0 32.902 14.73 32.902 32.898"
        />
        <path
          fill="#fdc500"
          d="m221.72 266.5c0 15.414-12.496 27.91-27.914 27.91-15.414 0-27.91-12.496-27.91-27.91 0-15.418 12.496-27.91 27.91-27.91 15.418 0 27.914 12.492 27.914 27.91"
        />
        <path
          fill="#fdc500"
          d="m535.32 266.5c0 15.414-12.496 27.91-27.91 27.91-15.414 0-27.91-12.496-27.91-27.91 0-15.418 12.496-27.91 27.91-27.91 15.414 0 27.91 12.492 27.91 27.91"
        />
      </g>
    </SvgIcon>
  );
}
