import React from 'react'
import { SvgIcon } from "@mui/material";

export default function ClipBoardIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 17 16"
      stroke="currentColor"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M6.70654 9.79997L7.70654 10.8L10.3732 8.1333"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.80683 1.3335H7.16683C6.50016 1.3335 5.8335 1.3335 5.8335 2.66683C5.8335 4.00016 6.50016 4.00016 7.16683 4.00016H9.8335C11.1668 4.00016 11.1668 3.3335 11.1668 2.66683C11.1668 1.3335 10.5002 1.3335 9.8335 1.3335"
          stroke="currentColor"
          strokeMiterlimit="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.5 6.66684C2.5 3.62684 3.61333 2.80018 5.83333 2.68018"
          stroke="currentColor"
          strokeMiterlimit="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.1667 2.68018C13.3867 2.80018 14.5 3.62018 14.5 6.66684V10.6668C14.5 13.3335 13.8333 14.6668 10.5 14.6668H6.5C3.16667 14.6668 2.5 13.3335 2.5 10.6668V9.27351"
          stroke="currentColor"
          strokeMiterlimit="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
