import * as React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
  Typography,
  Button,
  Card,
  CardHeader,
  Box,
  Avatar,
  Grid,
  Chip,
} from "@mui/material";
import { ExpandMore, Edit } from "@mui/icons-material";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  UserAvatar,
  Label,
  ProfilesChipsList,
  DocketLabel,
} from "blocks/atoms";
import { checkIfNull, useDocketsInSuite, useAppContext } from "@app21/core";
import { format } from "date-fns";
import { useIsMobileBreakpoint } from "hooks";
import Comment from "./TaskEditModal/Comment";
import toast from "react-hot-toast";
import { getDateTimeFormat } from "utils/getDateFormats";

export default function TasksListAccordion({
  tasksList = [],
  expandedTaskId,
  handleExpandedTaskId,
  handleEditTaskDetails,
  handleTaskEditClick,
}) {
  const hasEnoughWidthToShowComments = !useIsMobileBreakpoint(850);
  const { selectedSuiteId } = useAppContext();

  const {
    data: docketsInSuite,
    status: docketsInSuiteStatus,
    apiError: docketsInSuiteApiError,
  } = useDocketsInSuite(selectedSuiteId);

  React.useEffect(() => {
    if (docketsInSuiteApiError) {
      toast.error(docketsInSuiteApiError.message);
    }
  }, [docketsInSuiteApiError]);

  if (!(tasksList && tasksList.length)) {
    return (
      <Card
        sx={{
          p: 2,
          m: 2,
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <Typography variant="subtitle1">No Actions to display!</Typography>
      </Card>
    );
  }

  const getStatusChip = (status) => {
    return (
      <Chip
        color={
          status === "wip"
            ? "warning"
            : status === "completed"
            ? "success"
            : "primary"
        }
        label={
          status === "wip"
            ? "In-Progress"
            : status === "completed"
            ? "Completed"
            : "Pending"
        }
      />
    );
  };

  return (
    <PerfectScrollbar
      sx={{ display: "flex", flexDirection: "column", flexGrow: 1, mt: 2 }}
    >
      {tasksList.map((task, index) => {
        const taskDocket = docketsInSuite?.find(
          (docket) => docket._id === task.docketId
        );
        const hasCommentFlag = !checkIfNull(task.comments);
        return (
          <Accordion
            key={index}
            expanded={expandedTaskId === task._id}
            onChange={handleExpandedTaskId(task._id)}
            sx={{ boxShadow: (theme) => theme.customShadows.z20 }}
            TransitionProps={{ unmountOnExit: true }}
          >
            <AccordionSummary
              sx={{ display: "flex" }}
              expandIcon={<ExpandMore />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Box sx={{ flexGrow: 1, display: "flex" }}>
                <UserAvatar userId={task?.createdBy} size={35} />
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography variant="subtitle1" sx={{ ml: 2 }}>
                      {task?.title}
                    </Typography>
                    {task.docketId && (
                      <DocketLabel
                        id={task.docketId}
                        title={taskDocket?.title}
                        sx={{ ml: 2 }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {!checkIfNull(task.dueBy) && (
                      <Label variant="ghost" color="primary" sx={{ mr: 1 }}>
                        <Typography variant="body1" sx={{ m: 1 }}>
                          {`Due: ${format(
                            new Date(task.dueBy),
                            getDateTimeFormat({
                              startDate: task.dueBy,
                              noTimeFlag: true,
                            })
                          )}`}
                        </Typography>
                      </Label>
                    )}
                    {!checkIfNull(task.assignees) && (
                      <Label
                        variant="ghost"
                        color="secondary"
                        sx={{ mr: 1, px: 1, py: 1 }}
                      >
                        <Typography variant="body1" sx={{ mr: 1 }}>
                          Assignee(s):
                        </Typography>
                        {task.assignees?.map((assignee, index) => (
                          <UserAvatar key={index} userId={assignee._id} />
                        ))}
                      </Label>
                    )}
                    {task?.status !== "pending" && getStatusChip(task?.status)}
                  </Grid>
                </Grid>
              </Box>
            </AccordionSummary>
            {(hasCommentFlag || !checkIfNull(task.description)) && (
              <AccordionDetails>
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={12}
                    md={
                      !hasCommentFlag
                        ? 12
                        : hasEnoughWidthToShowComments
                        ? 7
                        : 12
                    }
                  >
                    <Typography variant="body2">{task.description}</Typography>
                  </Grid>
                  {hasCommentFlag && (
                    <Grid
                      item
                      xs={12}
                      md={hasEnoughWidthToShowComments ? 5 : 12}
                    >
                      <Box>
                        {task.comments?.map((comment, index) => (
                          <Comment
                            key={index}
                            comment={comment}
                            viewMode="compact"
                          />
                        ))}
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </AccordionDetails>
            )}
            <AccordionActions>
              <Button
                startIcon={<Edit />}
                onClick={() => handleTaskEditClick(task._id, task, null)}
              >
                Edit
              </Button>
            </AccordionActions>
          </Accordion>
        );
      })}
    </PerfectScrollbar>
  );
}
