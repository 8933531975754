// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { useFieldArray, Controller } from "react-hook-form";
import {
  TextField,
  Typography,
  FormControl,
  FormGroup,
  FormLabel,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  Button,
  Card,
} from "@mui/material";

import { CheckBox, Clear, AddCircle } from "@mui/icons-material";

const NestedQuestionOptions = ({ nestIndex, control }) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `questionBlock[${nestIndex}].answerChoices`,
  });

  return (
    <div data-testid="NestedQuestionOptions">
      {fields.map((answerChoice, k) => {
        return (
          <FormControl key={answerChoice.id} fullWidth>
            <Controller
              control={control}
              name={`questionBlock[${nestIndex}].answerChoices[${k}].choice`}
              defaultValue={answerChoice.choice || ""}
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CheckBox />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            type="button"
                            size="small"
                            onClick={() => remove(k)}
                          >
                            <Clear fontSize="inherit" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Option Info. For e.g. Yes (/For) etc"
                    style={{ width: 300 }}
                    variant="standard"
                  />
                );
              }}
            />
          </FormControl>
        );
      })}

      <Button
        type="button"
        size="small"
        variant="outlined"
        startIcon={<AddCircle fontSize="inherit" />}
        style={{ marginTop: 10 }}
        onClick={() => append({ choice: `Choice ${fields.length + 1}` })}
      >
        Add Choice
      </Button>
    </div>
  );
};

export default NestedQuestionOptions;
