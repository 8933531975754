import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  Box,
  IconButton,
  Grid,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Divider,
  TextField,
  Radio,
  RadioGroup,
} from "@mui/material";

import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  CheckBoxTwoTone,
} from "@mui/icons-material";

export default function SubscriptionTierComparisonDialog({
  dialogOpen = false,
  handleDialogClose = () => null,
}) {
  const FeatureRow = ({ featureData }) => {
    const [open, setOpen] = React.useState(false);
    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            {featureData.Feature}
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          <TableCell>
            <CheckBoxTwoTone color="secondary" />
          </TableCell>
          <TableCell>
            <CheckBoxTwoTone color="secondary" />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} unmountOnExit>
              {featureData?.featuresList.map((item, index) => {
                return (
                  <Grid container key={index} spacing={2} sx={{ mb: 2 }}>
                    <Grid item sm={6} md={7}>
                      <Typography variant="h6" color="#00264d">
                        {item.featuresData}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={2}
                      sm={3}
                      style={{ alignSelf: "center", paddingLeft: "50px" }}
                    >
                      <CheckBoxTwoTone color="secondary" />
                    </Grid>
                    <Grid item md={1}></Grid>
                    <Grid
                      item
                      md={2}
                      sm={3}
                      style={{ alignSelf: "center", paddingLeft: "20px" }}
                    >
                      <CheckBoxTwoTone color="secondary" />
                    </Grid>
                  </Grid>
                );
              })}
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleDialogClose}
      fullWidth={true}
      maxWidth={"md"}
    >
      <DialogTitle>Feature Comparison</DialogTitle>
      <DialogContent sx={{ my: 1 }}>
        <Box
          sx={{
            borderRadius: "10px",
            background: "#fff",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          }}
        >
          <TableContainer component={Paper}>
            <Table size={"medium"} aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell>Feature List</TableCell>
                  <TableCell>Basic</TableCell>
                  <TableCell>Pro</TableCell>
                </TableRow>
              </TableHead>
              <TableBody></TableBody>
            </Table>
          </TableContainer>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
