// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { Icon } from "@mui/material";
import { styled } from "@mui/material/styles";

import { SpeedDial, SpeedDialIcon, SpeedDialAction } from "@mui/material";
import "@mui/lab";
import {
  Briefcase as BriefcaseIcon,
  Calendar as CalendarIcon,
  ShoppingCart as ShoppingCartIcon,
  Folder as FolderIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  UserPlus as UserPlusIcon,
  AlertCircle as AlertCircleIcon,
  Trello as TrelloIcon,
  User as UserIcon,
  Layout as LayoutIcon,
  Edit as EditIcon,
  DollarSign as DollarSignIcon,
  Mail as MailIcon,
  MessageCircle as MessageCircleIcon,
  PieChart as PieChartIcon,
  Share2 as ShareIcon,
  Users as UsersIcon,
  Video as VideoIcon,
  Database as DataRoomIcon,
  Bell as NotificationsIcon,
  Home as HomeIcon,
  Settings as SettingsIcon,
} from "react-feather";
import {
  BallotOutlined,
  PagesOutlined,
  TrendingUp,
  LockOpen,
  Event,
} from "@mui/icons-material";
import { checkIfNull } from "@app21/core";
import { useHandleRouterPush } from "@app21/core";

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: "absolute",

  "& .MuiSpeedDial-directionUp, & .MuiSpeedDial-directionLeft": {
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },

  "& .MuiSpeedDial-directionDown, & .MuiSpeedDial-directionRight": {
    top: theme.spacing(4),
    left: theme.spacing(4),
  },
}));

const QuickActionsButton = ({
  quickActions = false,
  quickActionsList,
  ...rest
}) => {
  const { loadRoute } = useHandleRouterPush();
  const [dialOpen, setDialOpen] = React.useState(false);
  const handleDialOpen = () => setDialOpen(true);
  const handleDialClose = () => setDialOpen(false);

  const actions = [
    {
      icon: <BriefcaseIcon fontSize="small" color="primary" />,
      name: "View Breakouts",
      id: "listBriefs",
      handleAction: () => {
        loadRoute("LIST-DOCKETS", {});
        handleDialClose();
      },
    },
    {
      icon: <Event fontSize="small" color="primary" />,
      id: "addMeeting",
      name: "Setup Meeting",
      handleAction: () => {
        loadRoute("SCHEDULE-VIDEOCALL", {});
        handleDialClose();
      },
    },
    {
      icon: <SettingsIcon fontSize="small" color="primary" />,
      id: "manageSuite",
      name: "Manage Suite",
      handleAction: () => {
        loadRoute("MANAGE-SUITE", {});
        handleDialClose();
      },
    },
    {
      icon: <BriefcaseIcon fontSize="small" color="primary" />,
      id: "addBrief",
      name: "Add a new Trryst",
      handleAction: () => {
        loadRoute("CREATE-DOCKET", {});
        handleDialClose();
      },
    },
  ];

  let filteredActions = checkIfNull(quickActionsList)
    ? actions
    : actions.filter((action) => quickActionsList.includes(action.id));
  return (
    <StyledSpeedDial
      ariaLabel="Trryst quick actions"
      hidden={!quickActions}
      FabProps={{ size: "small" }}
      icon={<SpeedDialIcon size="small" />}
      onClose={handleDialClose}
      onOpen={handleDialOpen}
      open={dialOpen}
      direction={"up"}
      color="secondary"
    >
      {filteredActions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          onClick={action.handleAction}
        />
      ))}
    </StyledSpeedDial>
  );
};

QuickActionsButton.propTypes = {
  quickActions: PropTypes.bool,
};

export default QuickActionsButton;
