// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Box, Pagination, Typography } from "@mui/material";
import { MeetingInfoCard } from "blocks/modules/MeetingRecords";
import { useIsMobileBreakpoint } from "hooks";
import { FlowBox } from "blocks/atoms/uistyles";
import PerfectScrollbar from "react-perfect-scrollbar";

const RecordingsList = ({
  handleSelectMeeting = () => null,
  selectedMeetingId,
  filteredMeetings = [],
  transcriptions = [],
}) => {
  const isMobile = useIsMobileBreakpoint();

  let numOfCardsPerPage = isMobile ? 5 : 7;

  const [recordingsPage, setRecordingsPage] = React.useState(1);

  const applyPagination = React.useCallback((allmeetings, page, limit) => {
    return allmeetings.slice((page - 1) * limit, page * limit);
  }, []);

  const paginatedMeetings = applyPagination(
    filteredMeetings,
    recordingsPage,
    numOfCardsPerPage
  );
  const handleRecordingPagesChange = (event, value) => {
    setRecordingsPage(value);
  };

  return (
    <FlowBox
      sx={{
        overflow: "hidden",
      }}
    >
      <PerfectScrollbar
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          height: "100%",
          paddingRight: 10,
          overflow: "auto",
        }}
      >
        {filteredMeetings.length === 0 ? (
          <Typography
            variant="body1"
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              height: "100%",
              justifyContent: "center",

              textAlign: "center",
            }}
          >
            There are no recorded meetings in this suite
          </Typography>
        ) : (
          <>
            <FlowBox>
              {paginatedMeetings.map((meeting, index) => (
                <MeetingInfoCard
                  key={index}
                  handleSelectMeeting={handleSelectMeeting}
                  meeting={meeting}
                  isActive={meeting?._id === selectedMeetingId}
                  transcriptions={transcriptions}
                />
              ))}
            </FlowBox>
            {paginatedMeetings.length != 0 && (
              <Box
                sx={{ display: "flex", justifyContent: "center", pr: 2, mb: 1 }}
              >
                <Pagination
                  count={Math.ceil(filteredMeetings.length / numOfCardsPerPage)}
                  variant="outlined"
                  shape="rounded"
                  color="primary"
                  onChange={handleRecordingPagesChange}
                />
              </Box>
            )}
          </>
        )}
      </PerfectScrollbar>
    </FlowBox>
  );
};

export default RecordingsList;
