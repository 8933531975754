import React from "react";
import { MenuItem, Button, Box } from "@mui/material";
import { MenuPopover } from "blocks/atoms/uicomponents";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from "material-ui-popup-state/hooks";
import DateFilterPopover from "../features/DateFilterPopover";

export default function FilterRecordingsPopover() {
  const filterRecordingsPopupState = usePopupState({
    variant: "popover",
    popupId: "filter-recordings-popover",
  });
  return (
    <Box>
      <Button
        size="small"
        sx={{
          borderRadius: 0,
          height: "100%",
          minWidth: 30,
          width: 30,
          maxWidth: 30,
        }}
        {...bindTrigger(filterRecordingsPopupState)}
      >
        <FilterAltOutlinedIcon fontSize="small" />
      </Button>
      <MenuPopover
        {...bindPopover(filterRecordingsPopupState)}
        id="filter-recordings-popover"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        arrow="top-center"
        sx={{ bgcolor: "background.default" }}
      >
        <MenuItem>Last 7 Days (4)</MenuItem>
        <MenuItem>Show Only Breakout</MenuItem>
        <DateFilterPopover />
      </MenuPopover>
    </Box>
  );
}
