import React from "react";
import {
  Button,
  Avatar,
  Typography,
  Card,
  CardHeader,
  CardActions,
  Divider,
  Box,
} from "@mui/material";
import {
  useMeeting,
  useUserInfo,
  getS3ImageWrapper,
  useAppContext,
} from "@app21/core";
import RSVPHandlerChip from "blocks/modules/Scheduler/foundations/RSVPHandlerChip";
import { format } from "date-fns";
import { useRouter } from "next/router";
import { LoadingSpinner } from "blocks/atoms";
import { getDateTimeFormat } from "utils";

const RsvpCard = () => {
  const { userInfo } = useAppContext();
  const router = useRouter();
  const { id, uid } = JSON.parse(localStorage.getItem("lastIds")) || {};
  const { data: meeting } = useMeeting(id);
  const { data: originatorInfo } = useUserInfo(
    meeting?.data?.meetingJSON?.createdBy
  );

  const [meetingTitle, setMeetingTitle] = React.useState("");
  const [meetingSchedule, setMeetingSchedule] = React.useState("");
  const [isUserExist, setUserExist] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const currentDate = new Date();
  const pastMeetingFlag =
    meeting?.data?.meetingJSON?.start < currentDate.valueOf();
  let userRsvpStatus = meeting?.members?.find(
    (attendee) => attendee._id === userInfo._id
  )?.rsvpStatus;

  React.useEffect(() => {
    checkUserInclude();
  }, [meeting]);

  const checkUserInclude = () => {
    setLoading(true);
    const members = meeting?.data?.meetingJSON?.members;
    const visitors = meeting?.data?.meetingJSON?.visitors;
    members &&
      members.map((user, index) => {
        if (user._id == uid) {
          setUserExist(true);
        }
      });
    setLoading(false);
  };

  React.useEffect(() => {
    if (meeting?.data != undefined) {
      const meetingJSON = meeting.data.meetingJSON;
      setMeetingTitle(meetingJSON.summary);
      setMeetingSchedule(
        format(
          new Date(meetingJSON?.start),
          getDateTimeFormat({
            startDate: meetingJSON?.start,
            endDate: meetingJSON?.end,
            datePosition: 1,
            dateContext: "meeting",
          })
        ) +
          " - " +
          format(
            new Date(meetingJSON?.end),
            getDateTimeFormat({
              startDate: meetingJSON?.start,
              endDate: meetingJSON?.end,
              datePosition: 2,
              dateContext: "meeting",
            })
          )
      );
    }
  }, []);

  const handleRSVPChange = async (status) => {
    // await handleMeetingActions(
    //   {
    //     meetingJSON: meeting?.data?.meetingJSON,
    //     action: 'RSVP-UPDATE',
    //     changedrsvpStatus: status,
    //     meetingId: meeting?.data?.meetingJSON,
    //   },
    //   null,
    //   null,
    //   );
    const newChangeData = {
      meetingJSON: meeting?.data?.meetingJSON,
      action: "RSVP-UPDATE",
      changedrsvpStatus: status,
      meetingId: meeting?.data?._id,
    };
    console.log("NEW MEETING HANDLE CHANGE", newChangeData);
  };

  const redirectUser = () => {
    router.push({
      pathname: "/user",
    });
  };

  if (loading) {
    return <LoadingSpinner />;
  } else if (isUserExist) {
    return (
      <Card sx={{ maxWidth: "fit-content" }}>
        <CardHeader
          avatar={
            <Avatar
              src={getS3ImageWrapper(originatorInfo?.image, "person")}
              sx={{ width: 60, height: 60 }}
            />
          }
          title={<Typography variant="h5">{meetingTitle}</Typography>}
          subheader={<Typography variant="h6">{meetingSchedule}</Typography>}
        />
        <Divider sx={{ mt: 1 }} />
        <CardActions
          style={{
            justifyContent: "flex-end",
          }}
        >
          <RSVPHandlerChip
            rsvpStatus={userRsvpStatus}
            handleRSVPChange={handleRSVPChange}
            pastMeetingFlag={pastMeetingFlag}
          />
        </CardActions>
      </Card>
    );
  } else {
    return (
      <Box sx={{ m: 6 }}>
        <Typography variant="h5">{` ${userInfo?.emailId} is not a part of this meeting`}</Typography>
        <Button variant="contained" onClick={redirectUser}>
          Dashboard
        </Button>
      </Box>
    );
  }
};

export default RsvpCard;
