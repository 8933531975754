import React from 'react'
import { SvgIcon } from '@mui/material';

export default function ManageSuiteIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M65.5417 8.3335C58.9167 8.3335 56.25 11.0002 56.25 17.6252V34.4585C56.25 41.0835 58.9167 43.7502 65.5417 43.7502H82.375C89 43.7502 91.6667 41.0835 91.6667 34.4585V17.6252C91.6667 11.0002 89 8.3335 82.375 8.3335"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M43.75 35.5002V16.5835C43.75 10.7085 41.0833 8.3335 34.4583 8.3335H17.625C11 8.3335 8.33331 10.7085 8.33331 16.5835V35.4585C8.33331 41.3752 11 43.7085 17.625 43.7085H34.4583C41.0833 43.7502 43.75 41.3752 43.75 35.5002Z"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.4583 91.6667C41.0833 91.6667 43.75 89 43.75 82.375V65.5417C43.75 58.9167 41.0833 56.25 34.4583 56.25H17.625C11 56.25 8.33331 58.9167 8.33331 65.5417V82.375C8.33331 89 11 91.6667 17.625 91.6667"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M60.4167 72.9165H85.4167" stroke="currentColor" strokeWidth="6.25" strokeLinecap="round" />
        <path d="M72.9167 85.4165V60.4165" stroke="currentColor" strokeWidth="6.25" strokeLinecap="round" />
      </g>
    </SvgIcon>
  );
}
