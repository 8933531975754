export const baseMenuOptions = [
  { value: "suite", label: "Suite" },
  { value: "dockets", label: "Breakouts" },
];
export const moreMenuOptions = [
  { value: "meetings", label: "Meetings" },
  { value: "tasks", label: "Tasks" },
  { value: "files", label: "Files" },
  { value: "surveys", label: "Surveys" },
];
