// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
// import dynamic from "next/dynamic";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  LabelList,
} from "recharts";
import { Typography, Chip, Paper } from "@mui/material";
import {
  getCustomLabelValue,
  processChartData,
} from "blocks/modules/Surveys/foundations";
import { useAppContext } from "@app21/core";
import ProfilesChipsList from "blocks/atoms/ProfilesChipsList";
import { useTheme } from "@mui/material/styles";

const ShowSurveyBarChart = ({ compositeData = {}, viewContext = "full" }) => {
  const theme = useTheme();
  const { checkAccessRules } = useAppContext();
  const { accessFlag: canAccessMembers } = checkAccessRules({
    entity: "USERS-VISIBILITY",
    action: "VIEW-ALL-SUITE-USERS",
    featureName: "SUITE-USER-DETAILS",
    isInvitee: true,
  });
  // const {
  //   ResponsiveContainer,
  //   BarChart,
  //   Bar,
  //   XAxis,
  //   YAxis,
  //   Tooltip,
  //   LabelList
  // } = dynamic(() => import("recharts"), {
  //   ssr: false
  // });
  const chartData = processChartData(compositeData);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: (theme) => theme.spacing(2),
          }}
        >
          <Typography variant="subtitle1">{label}</Typography>
          <span>
            {payload &&
              payload[0] &&
              (canAccessMembers ? (
                <ProfilesChipsList
                  usersList={payload[0]?.payload?.choiceRespondents ?? []}
                />
              ) : (
                <></>
              ))}
          </span>
        </Paper>
      );
    }
    return null;
  };

  const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value } = props;

    const radius = 8;
    const mappedLabelValue = getCustomLabelValue(
      value,
      compositeData?.answerChoices
    );
    const cx = viewContext === "full" ? x - 20 : x + 12;
    const cy = viewContext === "full" ? y + 15 : 10;
    return (
      <g>
        <circle cx={cx} cy={cy} r={radius} fill={theme.palette.primary.main} />
        <text
          x={cx}
          y={cy}
          fill={theme.palette.primary.contrastText}
          textAnchor="middle"
          fontSize={10}
          dominantBaseline="middle"
        >
          {mappedLabelValue}
        </text>
      </g>
    );
  };

  return (
    <ResponsiveContainer
      width="100%"
      height={viewContext === "full" ? "100%" : 50}
      minHeight={viewContext === "full" ? 200 : 50}
      minWidth={viewContext === "full" ? 150 : 100}
      debounce={2}
    >
      {viewContext === "full" ? (
        <BarChart
          data={chartData}
          layout={"vertical"}
          margin={{ top: 5, right: 15, bottom: 5, left: -15 }}
        >
          <XAxis type="number" allowDecimals={false} />
          <YAxis
            type="category"
            dataKey="choice"
            tick={false}
            minTickGap={15}
          ></YAxis>
          <Tooltip content={<CustomTooltip />} />
          <Bar
            background
            legendType="none"
            label={{
              fill: theme.palette.secondary.main,
              fontSize: 14,
              fontWeight: "bold",
            }}
            dataKey="choiceCount"
            fill={theme.palette.primary.main}
          >
            <LabelList
              dataKey="choice"
              position="bottom"
              content={renderCustomizedLabel}
            />
          </Bar>
        </BarChart>
      ) : (
        <BarChart
          data={chartData}
          layout={"horizontal"}
          margin={{ top: 1, right: 1, bottom: -25, left: 1 }}
        >
          <YAxis type="number" allowDecimals={false} tick={false} hide />
          <XAxis
            type="category"
            dataKey="choice"
            tick={false}
            minTickGap={5}
          ></XAxis>
          {/* <Tooltip content={<CustomTooltip />} /> */}
          <Bar
            background
            legendType="none"
            label={{
              fill: theme.palette.secondary.main,
              fontSize: 11,

              fontWeight: "bold",
            }}
            dataKey="choiceCount"
            fill={theme.palette.primary.main}
          >
            <LabelList
              dataKey="choice"
              position="bottom"
              content={renderCustomizedLabel}
            />
          </Bar>
        </BarChart>
      )}
    </ResponsiveContainer>
  );
};

export default React.memo(ShowSurveyBarChart);
