// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  Box,
  Typography,
  Card,
  CardHeader,
  CardActionArea,
  AvatarGroup,
} from "@mui/material";
import { Label, UserAvatar } from "blocks/atoms";
import clip from "text-clipper";
import { format } from "date-fns";
import { getDateTimeFormat } from "utils/getDateFormats";

const MeetingInfoCard = ({
  meeting = {},
  handleSelectMeeting = () => null,
  isActive = false,
  transcriptions,
}) => {
  return (
    <Card
      sx={{
        my: 1,
        bgcolor: (theme) =>
          isActive ? "secondary.lighter" : theme.palette.background.paper,
        borderRadius: 2,
      }}
    >
      <CardActionArea
        sx={{ p: 1 }}
        onClick={(e) => handleSelectMeeting(meeting)}
      >
        <CardHeader
          title={
            <Typography variant="subtitle2">
              {clip(
                meeting.meetingType === "GROUP-MEETING"
                  ? "Walk-In Group Call"
                  : meeting?.meetingJSON?.summary ?? "Trryst Meeting",
                50
              )}
            </Typography>
          }
          action={
            <Label sx={{ position: "absolute", right: 5, bottom: 5 }}>
              <Typography variant="caption" sx={{ mr: 1 }} color="text.primary">
                {format(
                  new Date(meeting.meetingJSON.start),
                  getDateTimeFormat({
                    startDate: meeting.meetingJSON.start,
                    endDate: meeting.meetingJSON.end,
                    datePosition: 1,
                    dateContext: "meeting",
                  })
                )}
              </Typography>
            </Label>
          }
          subheader={
            <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
              <AvatarGroup
                max={4}
                sx={{
                  "& .MuiAvatar-root": { width: 20, height: 20 },
                  justifyContent: "flex-end",
                }}
              >
                {meeting.meetingJSON.members?.map((user, index) => {
                  if (user.emailId !== "app21chimerecorder@trryst.com") {
                    return (
                      <UserAvatar key={index} size={20} userId={user._id} />
                    );
                  }
                })}
              </AvatarGroup>
              {/* <ShowMarkerLabelsList meeting={meeting} /> */}
            </Box>
          }
          sx={{ p: 1 }}
        />
      </CardActionArea>
    </Card>
  );
};

export default MeetingInfoCard;
