// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { ContextualChatComponent } from "blocks/modules/TrrystChat";
import { FlowBox } from "blocks/atoms/uistyles";
import { useChatContext } from "providers/TrrystChatProvider";
import { useAppContext, app21APIInstance } from "@app21/core";

const DocketChatView = (props) => {
  const { selectedDocketId, userInfo } = useAppContext();
  const { allChannelsData } = useChatContext();
  const [docketChatId, setDocketChatId] = React.useState(null);

  React.useEffect(() => {
    if (Array.isArray(allChannelsData)) {
      const taskChannel = allChannelsData?.find(
        (channel) => channel.metadata?.belongsToId === selectedDocketId
      );
      if (taskChannel) {
        setDocketChatId(taskChannel.id);
      } else setDocketChatId(null);
    } else setDocketChatId(null);
    // you can use metadata.belongsToId === selectedTaskId to check if the
    // chat channel for this task exists or not.
  }, [allChannelsData, selectedDocketId]);

  const handleDocketChatClick = async () => {
    const response = await app21APIInstance.post(`/channels/fetch`, {
      objectType: "docket",
      objectId: selectedDocketId,
      calledBy: userInfo._id,
    });
    setDocketChatId(response?.data?.data?.data?.[0]?.id);
  };

  return (
    <FlowBox>
      <FlowBox
        sx={{
          mt: 2,
        }}
      >
        <Typography variant="overline" sx={{ m: 2 }} color="textSecondary">
          Board Meeting Discussions
        </Typography>
        {docketChatId ? (
          <FlowBox>
            <ContextualChatComponent channelId={docketChatId} />
          </FlowBox>
        ) : (
          <FlowBox
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            No comments yet.
            <Button variant="contained" onClick={handleDocketChatClick}>
              Chat
            </Button>
          </FlowBox>
        )}
      </FlowBox>
    </FlowBox>
  );
};

export default DocketChatView;
