import React from "react";
import {
  TextField,
  Box,
  IconButton,
  Autocomplete,
  ListItem,
  ListItemIcon,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Button,
} from "@mui/material";
import { useAppContext, useHandleRouterPush } from "@app21/core";

export default function DocketSelectionDropdown({
  sx = {},
  id = `field1-randompiece`,
  label = "Select a Board Meeting",
  showLabel = false,
  onDocketSelect = null,
  dockets = [],
}) {
  const { selectedDocketId, userInfo } = useAppContext();
  const { loadRoute } = useHandleRouterPush();

  const docketsList = dockets.map((docket) => ({
    value: docket._id,
    label: docket.title,
    createdBy: docket.createdBy,
    status: docket.status,
    members: docket.members,
  }));

  const handleDocketSelect = (docket) => {
    if (onDocketSelect && typeof onDocketSelect === "function") {
      onDocketSelect(docket);
    } else {
      const selectedDocket =
        dockets?.find((docket) => docket._id === docket.value) ?? {};
      let userIsDocketVisitor =
        selectedDocket?.visitors?.includes((v) => v._id === userInfo._id) ??
        false;

      loadRoute("VIEW-DOCKET", {
        docketId: docket.value,
        orgId: selectedDocket?.orgId,
        suiteId: selectedDocket?.suiteId,
        tabValue: "summary",
        userIsDocketVisitor,
      });
    }
  };

  return (
    <Box>
      <Autocomplete
        id={id} // to avoid autofill from showing up
        sx={{
          width: 300,
          "& .MuiAutocomplete-inputRoot": {
            fontSize: (theme) => theme.typography.caption.fontSize,
          },
          ...sx,
        }}
        value={selectedDocketId}
        size="small"
        options={docketsList}
        autoHighlight
        disableClearable
        onChange={(event, newValue) => {
          handleDocketSelect(newValue);
        }}
        getOptionLabel={(option) => {
          return (
            docketsList?.find((docket) => docket.value === option)?.label ?? ""
          );
        }}
        isOptionEqualToValue={(option, value) => {
          return option.value == value;
        }}
        renderOption={(props, option) => {
          return (
            <ListItem {...props} key={option.value}>
              {typeof option.avatar === "string" ? (
                <ListItemAvatar>
                  <Avatar
                    alt={option.label}
                    src={option.avatar}
                    sx={{ widht: 25, height: 25 }}
                    variant="rounded"
                  />
                </ListItemAvatar>
              ) : (
                <ListItemIcon>{option.avatar}</ListItemIcon>
              )}
              <ListItemText primary={option.label} />
            </ListItem>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={showLabel ? { label } : null}
            placeholder={label}
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password", // disable autocomplete and autofill
            }}
            sx={{ fontSize: (theme) => theme.typography.caption.fontSize }}
          />
        )}
      />
    </Box>
  );
}
