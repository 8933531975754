import React from "react";

export default function NoCookiesComponent() {
  const [mounted, setMounted] = React.useState(false);
  React.useEffect(() => {
    setMounted(true);
  }, []);

  if (mounted) {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          minHeight: "100vh",
          minWidth: "100vw",

          maxHeight: "100vh",
          maxWidth: "100vw",
          overflow: "hidden",
          display: "flex",

          backgroundColor: "#2a3132",
          padding: 30,
        }}
      >
        <span
          style={{
            color: "#fdc500",
            textAlign: "center",

            margin: "auto",
            maxWidth: 800,
            fontSize: 16,
          }}
        >
          Thanks for using Trryst. However, we need to use cookies to enable
          full range of functionalities on our site.
          <br />
          <br />
          We would like to assure you that we handle all our customer
          information with great care and do not use it for unauthorized
          purposes.
          <br /> <br />
          Please enable cookies and reload browser.
          <br />
          <br />
          If you think you are seeing this in error, please contact us at
          support @ trryst.com and we will do our best to resolve the issue.
        </span>
      </div>
    );
  } else return null;
}
