import React from 'react'
import { SvgIcon } from "@mui/material";

export default function PrinterIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M30.2085 29.1663H69.7918V20.833C69.7918 12.4997 66.6668 8.33301 57.2918 8.33301H42.7085C33.3335 8.33301 30.2085 12.4997 30.2085 20.833V29.1663Z"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M66.6668 62.5V79.1667C66.6668 87.5 62.5002 91.6667 54.1668 91.6667H45.8335C37.5002 91.6667 33.3335 87.5 33.3335 79.1667V62.5H66.6668Z"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M87.5 41.667V62.5003C87.5 70.8337 83.3333 75.0003 75 75.0003H66.6667V62.5003H33.3333V75.0003H25C16.6667 75.0003 12.5 70.8337 12.5 62.5003V41.667C12.5 33.3337 16.6667 29.167 25 29.167H75C83.3333 29.167 87.5 33.3337 87.5 41.667Z"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M70.8332 62.5H65.7915H29.1665"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.1665 45.833H41.6665"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
