import React from "react";
import { styled, Typography } from "@mui/material";
import { ShowMoreTextPanel } from "blocks/atoms";
import { convertStringToUrl, processTextWithEmoji } from "utils";
import { checkIfNull } from "@app21/core";
import Autolinker from "autolinker";
import dompurify from "dompurify";
import clip from "text-clipper";

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 320,
  minWidth: 30,
  width: "fit-content",
  marginTop: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  color: "#727287",
  wordWrap: "break-word",
}));

export default function BasicMessageCard({ message, isMe }) {
  const hasLinkFlag =
    Autolinker.parse(message?.message?.text ?? "", {
      urls: true,
      email: true,
      stripPrefix: false,
    }).length > 0;
  let maxLength = hasLinkFlag ? 1000 : 250;
  let showMoreIndicator = "More";
  let showLessIndicator = "Less";
  const [isExpanded, setExpanded] = React.useState(false);

  const autolinkedMessage = Autolinker.link(
    dompurify.sanitize(message?.message?.text ?? "", {
      ADD_ATTR: ["target"],
    }),
    {
      truncate: { length: 100, location: "middle" },
      newWindow: true,
      stripPrefix: false,
      sanitizeHtml: true,
    }
  );
  const messageText = isExpanded
    ? autolinkedMessage
    : clip(autolinkedMessage, maxLength);

  const emojiRegex = /\p{Emoji}/u;

  if (
    emojiRegex.test(message?.message?.text ?? "") &&
    message?.message?.text?.length === 2
  ) {
    return (
      <Typography variant="body2" sx={{ fontSize: 24 }}>
        {message?.message?.text}
      </Typography>
    );
  }
  return (
    <ContentStyle>
      {autolinkedMessage?.length > maxLength ? (
        <>
          <Typography
            variant="body2"
            dangerouslySetInnerHTML={{
              __html: messageText ?? " ",
            }}
          />
          <a
            onClick={() => setExpanded(!isExpanded)}
            style={{
              marginLeft: 3,
              marginRight: 3,
              fontSize: 12,
              fontWeight: 600,
              color: "darkslategrey",
              cursor: "pointer",
            }}
          >
            {isExpanded ? showLessIndicator : showMoreIndicator}
          </a>
        </>
      ) : (
        <Typography
          variant="body2"
          dangerouslySetInnerHTML={{
            __html: autolinkedMessage ?? " ",
          }}
        />
      )}
    </ContentStyle>
  );
}
