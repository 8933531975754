import React from "react";
import {
  Tabs,
  Tab,
  Typography,
  Box,
  Paper,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Chip,
  ListItemSecondaryAction,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FlowBox } from "blocks/atoms/uistyles";
import { UserAvatar } from "blocks/atoms";
import { AddIcon } from "blocks/atoms/trrysticons";
import { RemoveCircle, ContentCopy } from "@mui/icons-material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import { constructBasicMinutesTemplate } from "blocks/modules/Dockets/foundations";
import {
  useHandleTaskActions,
  useHandleRouterPush,
  useHandleMeetingActions,
  checkIfNull,
} from "@app21/core";
import ObjectID from "bson-objectid";
import ShowMinutesDialog from "./ShowMinutesDialog";
import { useRecordingsContext } from "providers/RecordingsProvider";

export default function ShowAISummariesAndActionsTabs({ openAiSummary = {} }) {
  const { loadRoute } = useHandleRouterPush();
  const { selectedMeetingId, filteredMeetings, transcriptions } =
    useRecordingsContext();
  const [tabValue, setTabValue] = React.useState("summary");

  const parsedAISummary = React.useMemo(() => {
    let combinedSummaryText = "Summary <br>";
    if (openAiSummary?.shortSummaryContent) {
      combinedSummaryText += openAiSummary.shortSummaryContent + "<br><br>";
    }
    if (!checkIfNull(openAiSummary?.summaryContent)) {
      combinedSummaryText += "Minutes <br>";
      openAiSummary.summaryContent.forEach((e) => {
        combinedSummaryText += e + "<br><br>";
      });
    }

    return combinedSummaryText;
  }, [openAiSummary]);

  const [copyState, setCopyState] = React.useState({
    value: parsedAISummary.replace(/<br>/g, "\n"),
    copied: false,
  });
  const meeting =
    filteredMeetings?.find((e) => e._id === selectedMeetingId) ?? {};

  const [minutesDialogFlag, setMinutesDialogFlag] = React.useState(false);
  const [minutesConstructStatusFlag, setMinutesConstructStatusFlag] =
    React.useState(false);
  const { handleMeetingActions } = useHandleMeetingActions();

  const onCopy = () => {
    setCopyState({ ...copyState, copied: true });
    if (copyState.value) {
      toast.success("Copied");
    }
  };
  const { handleTaskActions } = useHandleTaskActions();

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleTaskCreate = async (localTaskId, actionItem) => {
    const basicTaskData = {
      title: actionItem.action,
      dueBy: "",
      assignees: [actionItem.userid],
    };
    await handleTaskActions(
      {
        action: "ADD-TASK",
        taskId: localTaskId,
        taskData: basicTaskData,
      },
      null,
      null
    );
  };

  const handleMinutesConstruct = async () => {
    setMinutesConstructStatusFlag(true);

    await handleMeetingActions(
      {
        action: "UPDATE-MEETING",
        meetingData: {
          minutes: constructBasicMinutesTemplate({
            meeting,
            parsedAISummary,
            transcriptions,
          }),
        },
        meetingId: meeting?._id,
      },
      null,
      null
    );

    setMinutesConstructStatusFlag(false);
    setMinutesDialogFlag(true);
  };

  const handleCreateTask = async (task, index) => {
    const localTaskId = ObjectID().toHexString();
    await handleTaskCreate(localTaskId, task);
    //await handleAISummaryUpdate("added", index);
    loadRoute("VIEW-TASK", { taskId: localTaskId });
  };

  const handleRemoveTask = async (task, index) => {
    //   await handleAISummaryUpdate("removed", index);
  };

  const ShowActionListItem = ({ actionItem, index }) => {
    return (
      <ListItem>
        <ListItemAvatar>{index}</ListItemAvatar>
        <ListItemText
          sx={{ maxWidth: "calc(100% - 100px)" }}
          primary={
            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
              <Typography variant="body1">
                {actionItem.modifiedContent ?? actionItem.originalContent}
              </Typography>
              {actionItem?.modifiedByUserid && (
                <Chip
                  size="small"
                  label="Owner"
                  deleteIcon={
                    <UserAvatar userId={actionItem.modifiedByUserid} />
                  }
                  onDelete={() => null}
                />
              )}
            </Box>
          }
        />
        <ListItemSecondaryAction sx={{ display: "flex", gap: 2 }}>
          <Button
            size="small"
            startIcon={<AddIcon />}
            onClick={() => handleCreateTask(actionItem, index)}
          >
            Add Task
          </Button>
          <IconButton
            size="small"
            onClick={() => handleRemoveTask(actionItem, index)}
          >
            <RemoveCircle
              fontSize="inherit"
              sx={{
                "&: hover": {
                  color: "error.main",
                },
              }}
            />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  };
  return (
    <FlowBox
      sx={{
        bgcolor: "background.paper",
        pl: 3,
      }}
    >
      <Box sx={{ display: "flex" }}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={tabValue}
          onChange={handleChange}
          aria-label="Vertical tabs"
          sx={{ minWidth: 100, borderRight: 1, borderColor: "divider" }}
        >
          <Tab label="Minutes" value="summary" />
          <Tab label="Suggested Actions" value="actions" />
        </Tabs>
        <FlowBox>
          <TabPanel value={tabValue} index={"summary"}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box sx={{ py: 2, ml: 4, alignSelf: "flex-end" }}>
                <CopyToClipboard text={copyState.value} onCopy={onCopy}>
                  <Tooltip title="Copy" arrow>
                    <Button
                      size="small"
                      startIcon={<ContentCopy fontSize="small" />}
                      sx={{ mx: 2 }}
                    >
                      COPY
                    </Button>
                  </Tooltip>
                </CopyToClipboard>
                <LoadingButton
                  size="small"
                  loadingPosition="start"
                  startIcon={<AddIcon />}
                  sx={{ m: 2 }}
                  disabled={minutesConstructStatusFlag}
                  loading={minutesConstructStatusFlag}
                  onClick={handleMinutesConstruct}
                >
                  Create Meeting Minutes
                </LoadingButton>
              </Box>
              <FlowBox>
                <Typography variant="subtitle1" sx={{ p: 5 }}>
                  Summary of Meeting Discussions
                </Typography>
                <Typography variant="body1">
                  <List>
                    {openAiSummary.summaryContent?.map((section, index) => (
                      <ListItem key={index}>
                        <ListItemText primary={section} />
                      </ListItem>
                    ))}
                  </List>
                </Typography>
              </FlowBox>
            </Box>
          </TabPanel>
          <TabPanel value={tabValue} index={"actions"}>
            <Typography variant="subtitle1">
              Auto-generated Suggested Actions
            </Typography>

            <List>
              {/* {actions.map((e, index) => (
                <ShowActionListItem
                  key={index}
                  actionItem={e}
                  index={index + 1}
                />
              ))} */}
              {typeof openAiSummary?.actionItems === "string" ? (
                <Typography variant="body1">
                  {openAiSummary.actionItems}
                </Typography>
              ) : Array.isArray(openAiSummary?.actionItems) ? (
                openAiSummary.actionItems?.map((e, index) => (
                  <ShowActionListItem
                    key={index}
                    actionItem={e}
                    index={index + 1}
                  />
                ))
              ) : (
                <Typography variant="body1">
                  Suggested action items are not available for this meeting
                </Typography>
              )}
            </List>
          </TabPanel>
        </FlowBox>
      </Box>
      <ShowMinutesDialog
        meeting={meeting}
        minutesDialogFlag={minutesDialogFlag}
        onClose={() => setMinutesDialogFlag(false)}
      />
    </FlowBox>
  );
}
