// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)

import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import {
  useRemoteVideoTileState,
  useContentShareState,
  useFeaturedTileState,
  useLocalVideo,
  useRosterState,
  //useUserActivityState,
  RemoteVideo,
} from "amazon-chime-sdk-component-library-react";
import { FlowBox } from "blocks/atoms/uistyles";
import usePictureInPicture from "react-use-pip";
import {
  GridHeaderBar,
  CustomRemoteTile,
  CustomLocalTile,
  CustomCollaborationTile,
  CustomScreenShareTile,
  AttendeeInfoWrapper,
} from "blocks/modules/TrrystVideocall/foundations/components";
import chimeGridSizesCalculator from "./chimeGridSizesCalculator.js";

import useDeepCompareEffect from "use-deep-compare-effect";
import { baseLayoutPlanner } from "./baseLayoutPlanner";
import { useVideocallContext } from "providers/TrrystVideocallProvider";
import { useAppContext, checkIfNull } from "@app21/core";
import useDimensions from "react-cool-dimensions";
import _ from "lodash";
import { LAYOUTWIDTHS } from "blocks/atoms/constants";
import toast from "react-hot-toast";
import { useSound } from "use-sound";
import { VideocallChatModal } from "blocks/modules/TrrystVideocall";
//import suiteEnterSound from 'blocks/atoms/sounds/roomEnterSound.mp3';

const GridVideoWall = () => {
  //const { isUserActive } = useUserActivityState();
  const {
    setIsRecordingBotReady,
    isCollaborationActive,
    isBotJoining,
    isChatModalOpen,
  } = useVideocallContext();
  const { userInfo } = useAppContext();
  const [playSoundOnEnter] = useSound("/static/sounds/roomEnterSound.mp3", {
    volume: 0.25,
  });
  const [playSoundOnExit] = useSound("/static/sounds/roomExitSound.mp3", {
    volume: 0.25,
  });

  const { chimeViewMode } = useAppContext();

  const { tiles, tileIdToAttendeeId } = useRemoteVideoTileState();

  //const featuredElementRef = React.useRef();
  const elementRef = React.useRef();

  const aspectRatio = 16 / 9; // lets check both 16:9 and 4:3 ratios to see which fits better
  const { roster } = useRosterState();
  const [rosterMembers, setRosterMembers] = React.useState([]);

  const { tileId: featuredTileId } = useFeaturedTileState();
  const [adjustedFeaturedTileId, setAdjustedFeaturedTileId] =
    React.useState(null);
  const [featured, setFeatured] = React.useState(
    (chimeViewMode === "featured" && !!adjustedFeaturedTileId) ||
      isCollaborationActive
  );

  // let featured = (chimeViewMode === 'featured' && !!adjustedFeaturedTileId) || isCollaborationActive;

  // adjusting featured tile id to account for local speaker and also no featured speaker scenarios so that consistency is maintained and no jerky movements

  // FIXME: TODO note.. figure out why featured has issues in setstate mode and instead needs to be as let..

  React.useEffect(() => {
    if (!!featuredTileId) {
      setAdjustedFeaturedTileId(featuredTileId);
      setFeatured(
        (chimeViewMode === "featured" && !!featuredTileId) ||
          isCollaborationActive
      );
      // featured = (chimeViewMode === 'featured' && !!featuredTileId) || isCollaborationActive;
    } else {
      setFeatured(false);
      //featured = false;
    }
  }, [featuredTileId]);

  //filtering out chimerecorderbot attendees...
  useDeepCompareEffect(() => {
    let videocallAttendees = [];
    let recordingBotPresentFlag = false;
    Object.values(roster ?? {}).forEach((attendeeItem) => {
      if (attendeeItem?.emailId?.includes("app21chimerecorder@trryst.com")) {
        setIsRecordingBotReady(true);
        recordingBotPresentFlag = true;
      } else if (!attendeeItem.name) {
        // do nothing.. an intermediate state..
        videocallAttendees.push(attendeeItem); //temporarily put this to adderss that bug around attendeeItem.name not being there
      } else videocallAttendees.push(attendeeItem);

      setRosterMembers(videocallAttendees);
      if (!recordingBotPresentFlag) {
        setIsRecordingBotReady(false);
      }
    });
  }, [roster || {}]);

  const { tileId: contentTileId, sharingAttendeeId } = useContentShareState();
  const contentSharedFlag = Boolean(!!contentTileId);
  const {
    isVideoEnabled,
    tileId: localTileId,
    localAttendeeId,
  } = useLocalVideo();
  //FIXME just a functiom below to create dummy tiles for layout testing purposes.. to be removed later
  const [dummyTiles, setDummyTiles] = React.useState(0);
  const [prevRosterState, setPrevRosterState] = React.useState(rosterMembers);

  React.useEffect(() => {
    if (rosterMembers.length > prevRosterState.length) {
      if (
        rosterMembers?.slice(-1)?.emailId !== "app21chimerecorder@trryst.com"
      ) {
        playSoundOnEnter();
      }
      setPrevRosterState(rosterMembers);
    } else if (rosterMembers.length < prevRosterState.length) {
      playSoundOnExit();
      setPrevRosterState(rosterMembers);
    }
  }, [
    rosterMembers,
    prevRosterState.length,
    playSoundOnEnter,
    playSoundOnExit,
  ]);

  const remoteAttendeeIdList = tiles.map((tile) => ({
    tileId: tile,
    attendeeId: tileIdToAttendeeId[tile],
  }));

  const mobileMinWidth = useMediaQuery("(min-width:850px)");
  let defaultFullScreenFlag = chimeViewMode === "fullscreen" || !mobileMinWidth;
  let chimeGridSize = defaultFullScreenFlag
    ? 100
    : chimeViewMode === "normal"
    ? LAYOUTWIDTHS.standardVCWidth
    : chimeViewMode === "featured"
    ? LAYOUTWIDTHS.featuredVCWidth
    : LAYOUTWIDTHS.compactVCWidth;
  const {
    elementDimensionsWidth,
    elementDimensionsHeight,
    setElementDimensionsWidth,
    setElementDimensionsHeight,
  } = useVideocallContext();

  const {
    observe: elementObserve,
    width: OldElementDimensionsWidth,
    height: oldElementDimensionsHeight,
  } = useDimensions({
    onResize: React.useMemo(
      () =>
        _.throttle(({ width, height }) => {
          // Triggered whenever the size of the target is changed...
          setElementDimensionsWidth(width);
          setElementDimensionsHeight(height);
          // if (meetingId) setElementDimensions({ width: width, height: height });
        }, 500),
      []
    ),
  });

  const calculatedSizes = React.useMemo(
    () =>
      chimeGridSizesCalculator({
        chimeViewMode,
        elementDimensionsWidth,
        elementDimensionsHeight,
        contentSharedFlag,
        featured,
        isCollaborationActive,
      }),
    [
      chimeViewMode,
      elementDimensionsHeight,
      elementDimensionsWidth,
      contentSharedFlag,
      featured,
      isCollaborationActive,
    ]
  );

  const getContentTileInfo = (contentTileId) => {
    let contentTile = [];
    if (contentTileId) {
      let sharingChimeAttendeeId = sharingAttendeeId?.split("#")[0];
      let contentUser = rosterMembers?.find(
        (user) => user.chimeAttendeeId === sharingChimeAttendeeId
      );
      contentTile.push({
        tileId: contentTileId,
        type: "content",
        chimeAttendeeId: sharingChimeAttendeeId,
        tileInfo: contentUser,
      });
    }
    return contentTile;
  };

  const getAllTilesInfo = React.useCallback(
    ({ featured }) => {
      let localAllTiles = [];

      rosterMembers.map((attendeeItem, index) => {
        if (
          (attendeeItem.chimeAttendeeId === localAttendeeId ||
            attendeeItem?.externalUserId === userInfo._id) &&
          !isBotJoining
        ) {
          localAllTiles.push({
            tileId: localTileId || "local",
            type: "local",
            tileInfo: attendeeItem,
            videoEnabled: isVideoEnabled,
            featured: checkIfNull(adjustedFeaturedTileId)
              ? false
              : localTileId === adjustedFeaturedTileId,
            chimeAttendeeId: attendeeItem.chimeAttendeeId,
          });
        } else {
          let matchObj = remoteAttendeeIdList?.find(
            (item) => item.attendeeId === attendeeItem.chimeAttendeeId
          );

          if (matchObj) {
            localAllTiles.push({
              tileId: matchObj.tileId,
              type: "remote",
              tileInfo: attendeeItem,
              videoEnabled: true,
              featured: matchObj?.tileId === adjustedFeaturedTileId,
              chimeAttendeeId: attendeeItem.chimeAttendeeId,
            });
          } else {
            localAllTiles.push({
              tileId: `blank${index}`,
              type: "remote",
              tileInfo: attendeeItem,
              videoEnabled: false,
              featured: false,
              chimeAttendeeId: attendeeItem.chimeAttendeeId,
            });
          }
        }
      });
      let nonFeaturedAllTiles = localAllTiles.filter(
        (t) =>
          !(
            featured &&
            !contentSharedFlag &&
            t.tileId === adjustedFeaturedTileId
          )
      );
      return nonFeaturedAllTiles;
    },
    [
      rosterMembers,
      localAttendeeId,
      userInfo._id,
      isBotJoining,
      localTileId,
      isVideoEnabled,
      adjustedFeaturedTileId,
      remoteAttendeeIdList,
      contentSharedFlag,
    ]
  );

  let contentTile = getContentTileInfo(contentTileId);

  let allTiles = getAllTilesInfo({ featured });

  let i;
  for (i = 0; i < dummyTiles; i++) {
    allTiles.push({ tileId: `dummy${i}`, type: "dummy" });
  }

  let baseGridPlan = React.useMemo(
    () =>
      baseLayoutPlanner({
        numOfScreens: allTiles.length,
        inputScreenX: Math.round(calculatedSizes.tilesSectionWidth) - 5,
        inputScreenY: Math.round(calculatedSizes.tilesSectionHeight) - 5,
        minVideoX: 100,
        aspectRatio: aspectRatio,
        featured: false,
      }),
    [calculatedSizes, allTiles.length, aspectRatio]
  );

  const handleEnterPictureInPicture = (event) => {
    // console.log('entered picture in picture', event);
  };
  const handleExitPictureInPicture = (event) => {
    //console.log('exit picture in picture', event);
  };
  const pipVideoRef = React.useRef(null);
  //const pipScreenShareRef = React.useRef(null);

  const { isPictureInPictureActive, togglePictureInPicture } =
    usePictureInPicture(
      pipVideoRef,
      //pipScreenShareRef,
      {
        onEnterPictureInPicture: handleEnterPictureInPicture,
        onLeavePictureInPicture: handleExitPictureInPicture,
        onRequestPictureInPictureError: (error) =>
          console.error(`Couldnt enter pip mode - ${error}`),
        onExitPictureInPictureError: (err) =>
          console.log("exit pip error", err),
      }
    );

  const pipRefTileId = React.useMemo(() => {
    let pipTile = null;
    if (contentTile && contentTile[0] && contentTile[0].type === "content") {
      return "content";
    } else if (adjustedFeaturedTileId) {
      // most active speaker Tile gets first preference
      pipTile = allTiles?.find(
        (tile) => tile.tileId === adjustedFeaturedTileId
      );
    } else if (tiles.length > 0) {
      //else for first remote video that has video switched on
      pipTile = allTiles?.find((tile) => tile.tileId === tiles[0].tileId);
    } else if (isVideoEnabled) {
      // else self local video if local video is enabled
      pipTile = allTiles?.find((tile) => tile.tileId === localTileId);
    } else {
      pipTile = {};
    }
    return pipTile?.tileId;
  }, [
    allTiles,
    adjustedFeaturedTileId,
    isVideoEnabled,
    localTileId,
    tiles,
    contentTile,
  ]);

  React.useEffect(() => {
    function handlerFunction(evt) {
      var v = "visible",
        h = "hidden",
        evtMap = {
          focus: v,
          focusin: v,
          pageshow: v,
          blur: h,
          focusout: h,
          pagehide: h,
        };

      evt = evt || window.event;
      if (evt.type in evtMap) document.body.className = evtMap[evt.type];
      else document.body.className = this[hidden] ? "hidden" : "visible";
      if (document.body.className === "hidden") {
        if (!isPictureInPictureActive) {
          togglePictureInPicture(true);
        }
        //pipVideoRef.current.requestPictureInPicture();
      } else {
        togglePictureInPicture(false);
        //pipVideoRef.current?.exitPictureInPicture();
      }
    }

    var hidden = "hidden";

    // Standards:
    window.addEventListener("blur", handlerFunction);
    window.addEventListener("focus", handlerFunction);
    if (hidden in document)
      document.addEventListener("visibilitychange", handlerFunction);
    else if ((hidden = "mozHidden") in document)
      document.addEventListener("mozvisibilitychange", handlerFunction);
    else if ((hidden = "webkitHidden") in document)
      document.addEventListener("webkitvisibilitychange", handlerFunction);
    else if ((hidden = "msHidden") in document)
      document.addEventListener("msvisibilitychange", handlerFunction);
    // IE 9 and lower:
    else if ("onfocusin" in document)
      document.onfocusin = document.onfocusout = handlerFunction;
    // All others:
    else
      window.onpageshow =
        window.onpagehide =
        window.onfocus =
        window.onblur =
          handlerFunction;

    return () => {
      window.removeEventListener("blur", handlerFunction);
      window.removeEventListener("focus", handlerFunction);
      window.removeEventListener("visibilitychange", handlerFunction);
      window.removeEventListener("mozvisibilityChange", handlerFunction);
      window.removeEventListener("webkitvisibilityChange", handlerFunction);
      window.removeEventListener("msvisibilityChange", handlerFunction);
    };
  }, []);

  return (
    <div
      id="canvasPaperForPip"
      style={{
        width: "100%",
        backgroundColor: "transparent",
        position: "relative",
        paddingTop: LAYOUTWIDTHS.TopbarHeight,
        paddingBottom: LAYOUTWIDTHS.chimeMenubarHeight,
        maxHeight: "100vh",
        minHeight: `calc(100vh - ${0.85 * LAYOUTWIDTHS.chimeMenubarHeight}px )`,
        height: "100%",
        maxWidth: "100vw",

        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,

          zIndex: (theme) => theme.zIndex.drawer - 1,
          height: LAYOUTWIDTHS.TopbarHeight,
        }}
      >
        <GridHeaderBar />
        {/*chimeViewMode !== 'featured' ? (
          <GridHeaderBar />
        ) : (
          <>{isShown || isMobile ? <GridHeaderBar /> : <div></div>}</>
        )*/}
      </Box>

      <Box
        ref={(el) => {
          elementObserve(el);
          elementRef.current = el;
        }}
        sx={{
          display: "flex",
          alignItems: "safe center",
          justifyContent: "safe center",
          width: "100%",
          height: "100%",
          flexGrow: 1,
          flexDirection: calculatedSizes.displayOrientation,
          maxHeight: `calc(100vh - ${
            LAYOUTWIDTHS.TopbarHeight + LAYOUTWIDTHS.chimeMenubarHeight
          } px)`,
          maxWidth: `${chimeGridSize}vw`,
          // transition:
          //   "width 5s, height 5s, min-width 5s, max-width 5s, min-height 5s, max-height 5s, flex-direction: 5s",
        }}
      >
        {isCollaborationActive ? (
          <Box
            sx={{
              width: calculatedSizes.featuredSectionWidth,
              minWidth: calculatedSizes.featuredSectionMinWidth,
              minHeight: calculatedSizes.featuredSectionMinHeight,
              maxHeight: calculatedSizes.featuredSectionMaxHeight,
              display: "flex",
              flexGrow: 1,
              flexDirection: "column",
              // transition:
              //   "width 1s, height 1s, min-width 1s, max-width 1s, min-height 1s, max-height 1s",
            }}
          >
            <CustomCollaborationTile />
          </Box>
        ) : contentSharedFlag ? (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              // transition:
              //   "width 1s, height 1s, min-width 1s, max-width 1s, min-height 1s, max-height 1s",
            }}
          >
            {contentTile[0].type === "content" ? (
              <CustomScreenShareTile
                tileInfo={contentTile[0]?.tileInfo}
                pipRef={pipRefTileId === "content" ? pipVideoRef : null}
              />
            ) : (
              // <Typography variant="h5">REMOTE VIDEO WALL </Typography>
              <RemoteVideo
                tileId={contentTile[0]?.tileId}
                name={contentTile[0]?.tileInfo?.name}
              />
            )}
          </Box>
        ) : featured ? (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              // transition:
              //   "width 0.2s, height 0.2s, min-width 0.2s, max-width 0.2s, min-height 0.2s, max-height 0.2s",
            }}
          >
            <RemoteVideo
              tileId={adjustedFeaturedTileId}
              name={
                allTiles?.find((tile) => tile.tileId === adjustedFeaturedTileId)
                  ?.name ?? "Speaker"
              }
            />
          </Box>
        ) : (
          <></>
        )}
        <Box
          style={{
            display: "flex",
            border: "none",
            flexDirection: "row",
            justifyContent: "safe center",
            alignItems: "safe center",
            flexWrap: "wrap",
            flexGrow: 1,
            width: calculatedSizes.tilesSectionWidth,
            minWidth: calculatedSizes.tilesSectionMinWidth,
            minHeight: calculatedSizes.tilesSectionMinHeight,
            maxHeight: calculatedSizes.tilesSectionMaxHeight,
            maxWidth: "calc(100vw - 40px)",
            alignContent: baseGridPlan.scroll ? "flex-start" : "center",
            overflow: baseGridPlan.scroll ? "auto" : "hidden",
            // transition:
            //   "width 1s, height 1s, min-width 1s, max-width 1s, min-height 1s, max-height 1s",
          }}
        >
          {allTiles.map((tileObj, index) => {
            return (
              <div
                key={index}
                style={{
                  display: "flex",
                  border: "none",
                  position: "relative",
                  background: "transparent",
                  height: (baseGridPlan.videoWidth - 2) / aspectRatio,
                  width: baseGridPlan.videoWidth - 2,
                  minWidth: 100,
                  overflow: "hidden",
                  padding: 5,
                  // transition:
                  //   "width 1s, height 1s, min-width 1s, max-width 1s, min-height 1s, max-height 1s",
                }}
              >
                {tileObj.type === "local" ? (
                  <AttendeeInfoWrapper
                    nameplate={"Me"}
                    context="local"
                    attendeeId={tileObj?.tileInfo?.chimeAttendeeId}
                  >
                    <CustomLocalTile
                      pipRef={
                        pipRefTileId === tileObj.tileId ? pipVideoRef : null
                      }
                      tile={tileObj}
                      isPictureInPictureActive={isPictureInPictureActive}
                    />
                  </AttendeeInfoWrapper>
                ) : tileObj.type === "featuredSpeaker" ||
                  tileObj.type === "remote" ? (
                  <AttendeeInfoWrapper
                    context="remote"
                    nameplate={tileObj?.tileInfo?.name}
                    attendeeId={tileObj?.tileInfo?.chimeAttendeeId}
                  >
                    <CustomRemoteTile
                      pipRef={
                        pipRefTileId === tileObj.tileId ? pipVideoRef : null
                      }
                      tile={tileObj}
                    />
                  </AttendeeInfoWrapper>
                ) : typeof tileObj.type === "string" &&
                  tileObj.type.includes("dummy") ? (
                  <RemoteVideo tileId={tileObj.tileId} name={"dummy"} />
                ) : (
                  <>{console.log("tile layout error")} </>
                )}
              </div>
            );
          })}
        </Box>
      </Box>
      {isChatModalOpen && <VideocallChatModal />}
    </div>
  );
};

export default GridVideoWall;
