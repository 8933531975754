// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
/* eslint-disable @typescript-eslint/ban-ts-comment */
// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
// @ts-nocheck

import React, { useEffect } from "react";
import {
  UserActivityProvider,
  useToggleLocalMute,
  useRosterState,
  useMeetingManager,
} from "amazon-chime-sdk-component-library-react";
import {
  VideocallActivityProvider,
  useVideocallContext,
  useMeetingEndRedirect,
  setChimeRoute,
  TrrystBotObserver,
} from "providers/TrrystVideocallProvider";
import {
  GridVideoWall,
  ConferenceControlBar,
} from "blocks/modules/TrrystVideocall/foundations";

import { FlowBox } from "blocks/atoms/uistyles";
import toast from "react-hot-toast";

import { Button } from "@mui/material";
import {
  useAppContext,
  checkIfNull,
  useHandleMeetingActions,
} from "@app21/core";
import { useSnackbar } from "notistack";
import { MeetingAccessRequestMessageShape } from "blocks/modules/TrrystVideocall/foundations/components/Messaging";
import { useSound } from "use-sound";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useHandleLeaveVideocall } from "providers/TrrystVideocallProvider/hooks";

const MeetingView = () => {
  useMeetingEndRedirect();
  const roster = useRosterState();
  const meetingManager = useMeetingManager();
  const { enqueueSnackbar } = useSnackbar() ?? {};
  const { muted, toggleMute } = useToggleLocalMute();
  const { userInfo, meetingId } = useAppContext();
  const {
    isBotJoining,
    guestAccessStatus,
    setGuestAccessStatus,
    meeting,
    usersInMeetingSuite,
    chimeNotistackRef,
    endMeetingForAllReceived,
  } = useVideocallContext();
  const { handleMeetingActions } = useHandleMeetingActions();
  const [snackbarKeys, setSnackbarKeys] = React.useState([]);

  const [playSoundOnRequestAccess] = useSound(
    "/static/sounds/roomEnterSound.mp3",
    { volume: 0.1 }
  );

  useEffect(() => {
    if (!muted && isBotJoining) {
      toggleMute();
    }
  }, []);

  // leave chime meeting for this client if end meeting for all is received.
  const { leaveVideocall } = useHandleLeaveVideocall();
  React.useEffect(() => {
    endMeetingForAllReceived && leaveVideocall({ meetingManager });
  }, [endMeetingForAllReceived]);

  const handleAccess = async (action, userId, key) => {
    const updatedMeetingJSON = meeting?.data?.meetingJSON;
    updatedMeetingJSON?.visitors?.forEach((v, index) => {
      if (v._id === userId) {
        // invited user used the same emailId on which he was invited earlier.
        // enhance that with the _id of his signed up account now.
        updatedMeetingJSON.visitors[index] = {
          ...v,
          status: action,
        };
      }
    });
    await handleMeetingActions({
      action: "UPDATE-MEETING",
      meetingData: { meetingJSON: updatedMeetingJSON },
      meetingId: meetingId,
      visitorAccessGrantedOrDenied: true,
    });
    chimeNotistackRef?.current?.closeSnackbar(key);
  };

  useDeepCompareEffect(() => {
    // request access updates are published only on the meeting.suiteId channels
    // this would mean only people who can grant/deny access will get that message

    // need to insert a check to ensure that code only runs for userInfo._id being part of the list of attendees
    let isMember = false;
    if (!(checkIfNull(meeting?.data) || checkIfNull(usersInMeetingSuite))) {
      if (meeting.data?.meetingType === "GROUP-MEETING") {
        // check attendees in meeting overlapping with usersInMeetingSuite
        if (!checkIfNull(roster)) {
          isMember = Object.values(roster).some((activeAttendee) =>
            Boolean(
              usersInMeetingSuite.find((user) => {
                const chimeAttendeeId =
                  meetingManager?.meetingSessionConfiguration?.credentials
                    ?.attendeeId;
                if (chimeAttendeeId)
                  return (
                    activeAttendee[chimeAttendeeId]?.externalUserId === user._id
                  );
                else return false;
              })
            )
          );
        }
      } else {
        //setting as member of meeting if a) either he is a member of the suite or he has been invited as a visitor to the meeting
        isMember = meeting?.data?.meetingJSON?.members?.some(
          (member) => member._id === userInfo._id
        );
      }

      const isVisitor = meeting?.data?.meetingJSON?.visitors?.some(
        (v) => v._id === userInfo._id
      );

      if (isMember) {
        meeting?.data?.meetingJSON?.visitors?.forEach((v) => {
          if (
            (v.status === "GRANTED" || v.status === "DENIED") &&
            snackbarKeys.length
          ) {
            let foundSnack = snackbarKeys.find(
              (snack) => snack.userId === v._id
            );

            if (foundSnack) {
              chimeNotistackRef?.current?.closeSnackbar(foundSnack.key);
            }
          }
          if (v.status === "REQUESTED") {
            // show the enqueue snack bar here with the command button to GRANT / DENY
            enqueueSnackbar(
              <MeetingAccessRequestMessageShape userId={v._id} />,
              {
                variant: "warning",
                action: (key) => {
                  setSnackbarKeys((oldSnacks) => [
                    ...oldSnacks,
                    { key: key, userId: v._id },
                  ]);
                  return (
                    <>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={async () =>
                          await handleAccess("DENIED", v._id, key)
                        }
                      >
                        Deny
                      </Button>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={async () =>
                          await handleAccess("GRANTED", v._id, key)
                        }
                      >
                        Allow
                      </Button>
                    </>
                  );
                },
              }
            );
            playSoundOnRequestAccess();
          }
        });
      }

      // this piece of code should only be executed when userInfo._id is part of the list of
      // visitors
      if (isVisitor) {
        const foundVisitorInMeetingObj =
          meeting?.data?.meetingJSON?.visitors.find(
            (v) => v._id === userInfo._id
          );
        if (foundVisitorInMeetingObj && guestAccessStatus === "NEEDED") {
          if (foundVisitorInMeetingObj.status === "GRANTED") {
            setGuestAccessStatus("GRANTED");
            meetingManager.start();
            setChimeRoute("meeting");
          }
          if (foundVisitorInMeetingObj.status === "DENIED") {
            setGuestAccessStatus("DENIED");
            toast.error("Your Access request has been denied.", {
              appearance: "error",
              autoDismiss: true,
            });
          }
        }
      }
    }
  }, [meeting ?? {}, usersInMeetingSuite ?? []]);

  if (isBotJoining) {
    return (
      <FlowBox sx={{ overflow: "hidden", height: "100vh" }}>
        <VideocallActivityProvider>
          <TrrystBotObserver />
          <GridVideoWall />
        </VideocallActivityProvider>
      </FlowBox>
    );
  }

  return (
    <FlowBox sx={{ overflow: "hidden", height: "100vh" }}>
      <UserActivityProvider>
        <VideocallActivityProvider>
          <GridVideoWall />
          <ConferenceControlBar />
        </VideocallActivityProvider>
      </UserActivityProvider>
    </FlowBox>
  );
};

export default MeetingView;
