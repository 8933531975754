import React from "react";

import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Badge,
} from "@mui/material";
import { UserAvatar, PrintUserInfo } from "blocks/atoms";
import MessageCountBox from "../MessageCountBox";
import { checkIfNull, useUserInfo } from "@app21/core";
import { useChatContext } from "providers/TrrystChatProvider";

export default function GroupMemberChatCard({
  member,
  isActive = false,
  handleClick = () => null,
  unreadMessages = [],
  sx = {},
  searchFilter = "",
  disabled = false,
  ...rest
}) {
  // need to fetch it for direct chat corresponding to current user and this member
  // it needs to be fetched from pubnub directly here for the channel
  const { data: loggedInUser } = useUserInfo();
  const { data: userInfo } = useUserInfo(member.id);

  const { unreadMessageCounts } = useChatContext();

  const [unreadMessageCount, setUnreadMessageCount] = React.useState(0);

  React.useEffect(() => {
    const directChatId =
      loggedInUser?._id < member?.id
        ? `${loggedInUser?._id}-${member?.id}-CHAT`
        : `${member?.id}-${loggedInUser?._id}-CHAT`;

    setUnreadMessageCount(unreadMessageCounts[directChatId] ?? 0);
  }, [member.id, unreadMessageCounts]);

  if (
    !checkIfNull(searchFilter) &&
    !userInfo.fullName.toLowerCase().includes(searchFilter.toLowerCase())
  )
    return;

  return (
    <ListItem
      onClick={handleClick}
      disabled={disabled}
      disableGutters
      button
      sx={{
        bgcolor: isActive ? "background.neutral" : "background.paper",
        position: "relative",
        ...sx,
      }}
      {...rest}
    >
      <ListItemAvatar>
        <Badge
          overlap="circular"
          color="success"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          variant="dot"
          invisible={!member.memberActive}
        >
          <UserAvatar size={35} userId={member.id} />
        </Badge>
      </ListItemAvatar>

      <ListItemText
        primary={<PrintUserInfo userId={member.id} />}
        {...(!checkIfNull(unreadMessages)
          ? {
              secondary: (
                <Typography variant="caption" display="block">
                  {unreadMessages.slice(-1).pop()}
                </Typography>
              ),
            }
          : {})}
      />
      {Boolean(unreadMessageCount) && (
        <MessageCountBox count={unreadMessageCount} />
      )}
    </ListItem>
  );
}
