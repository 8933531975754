// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { isString } from "lodash";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import { useDropzone } from "react-dropzone";
import fileFill from "@iconify/icons-eva/file-fill";
import closeFill from "@iconify/icons-eva/close-fill";
import { motion, AnimatePresence } from "framer-motion";
import { Close } from "@mui/icons-material";
// material
import { alpha, styled } from "@mui/material/styles";
import DraggablePaper from "blocks/atoms/uicomponents/DraggablePaper";

import {
  Box,
  List,
  Link,
  Stack,
  Paper,
  Button,
  ListItem,
  Typography,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Card,
  CardHeader,
  IconButton,
} from "@mui/material";
// utils
import { fData, checkIfNull } from "@app21/core";
//
import { IconButtonAnimate } from "blocks/atoms";
import { varFade } from "blocks/atoms/uieffects/animate";
import { UploadIllustration } from "blocks/atoms/graphics";
import { useForm, useFieldArray, FormProvider } from "react-hook-form";
import UploadMultiFileComponent from "./UploadMultiFileComponent";
import mime from "mime-types";
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

UploadMultiFileDialog.propTypes = {
  error: PropTypes.bool,
  showPreview: PropTypes.bool,
  showNotify: PropTypes.bool,
  open: PropTypes.bool,
  files: PropTypes.array,
  onSubmit: PropTypes.func,
  handleNotify: PropTypes.func,
  title: PropTypes.any,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  onClose: PropTypes.func,
  sx: PropTypes.object,
};

export default function UploadMultiFileDialog({
  files = [],
  sx = {},
  open,
  dialogContainerProps = {},
  maxSize,
  onSubmit,
  title = "Add Files",
  onClose,
  showNotify = false,
  handleNotify,
  ...other
}) {
  const [uploadFiles, setUploadFiles] = React.useState(files);
  const [notifyPreference, setNotifyPreference] = React.useState(false);
  const [uploadingActive, setUploadActive] = React.useState(false);

  React.useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      Array.isArray(uploadFiles) &&
        uploadFiles?.forEach((file) => URL.revokeObjectURL(file?.preview));
    },
    [uploadFiles]
  );

  const handleMultipleFilesDrop = (data) => {
    let dataWithPreview = data?.map((file) => {
      return Object.assign(file, {
        preview: URL.createObjectURL(file),
      });
    });

    let dataWithConfirmedContentType = dataWithPreview?.map((file) => {
      if (checkIfNull(file?.type)) {
        return Object.defineProperty(file, "type", {
          value: mime.lookup(file.name?.split(".")?.pop()),
          writable: true,
          enumerable: true,
          configurable: true,
        });
      } else {
        return file;
      }
    });

    setUploadFiles((oldFiles) => [
      ...oldFiles,
      ...dataWithConfirmedContentType,
    ]);
  };

  const handleFileRemove = (index) => {
    setUploadFiles((oldFiles) => oldFiles.filter((value, i) => i !== index));
  };
  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setUploadFiles([]);
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableEnforceFocus={true}
      hideBackdrop
      fullWidth
      PaperComponent={DraggablePaper}
      PaperProps={{ square: true }}
      onBackdropClick={() => console.log("click")}
      sx={{
        "& .MuiDialog-container": {
          height: "100%",
          width: "100%",
          p: 0,
          display: "flex",
          flexDirection: "column",
          ...dialogContainerProps,
        },
        "& .MuiDialog-paper": {
          height: "100%",
          maxHeight: "50vh !important",
          width: "100%",
          flexGrow: 1,
          justifyContent: "stretch",
          padding: 0,
          margin: 0,
          position: "absolute",
          bottom: 10,
          right: 10,
        },
        flexGrow: 1,
        height: "100%",
        width: "100%",

        p: 0,

        display: "flex",
        flexDirection: "column",
        ...sx,
      }}
    >
      <DialogTitle id="dragModal" sx={{ p: 0 }}>
        <Card square sx={{ p: 0, m: 0, borderRadius: 0 }}>
          <CardHeader
            sx={{ m: 0, p: 1 }}
            title={title}
            action={
              <IconButton size="small" onClick={handleClose}>
                <Close />
              </IconButton>
            }
          />
        </Card>
      </DialogTitle>
      <DialogContent sx={{ overflow: "auto", mt: 1 }}>
        <UploadMultiFileComponent
          onDrop={handleMultipleFilesDrop}
          onRemove={handleFileRemove}
          loading={uploadingActive}
          showPreview
          maxSize={maxSize}
          files={uploadFiles}
          {...other}
        />
      </DialogContent>

      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        {Boolean(showNotify) && (
          <FormControlLabel
            label="Notify other suite users regarding file(s) upload"
            control={
              <Checkbox
                checked={notifyPreference}
                onChange={() => setNotifyPreference(!notifyPreference)}
              />
            }
          />
        )}
        <Stack
          direction="row"
          justifyContent="flex-end"
          sx={{ alignSelf: "flex-end" }}
        >
          <Button onClick={handleClose} sx={{ mr: 1.5 }}>
            Close
          </Button>
          <Button
            variant="contained"
            disabled={uploadingActive}
            onClick={async () => {
              if (showNotify) {
                handleNotify(notifyPreference);
              }
              await onSubmit(uploadFiles);
              handleClose();
            }}
          >
            {uploadingActive ? (
              <CircularProgress color="secondary" size={20} />
            ) : (
              "Upload"
            )}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
