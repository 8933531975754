import React from "react";
import {
  Select,
  FormControl,
  MenuItem,
  ListItemIcon,
  ListItemText,
  InputLabel,
  styled,
  InputBase,
  Typography,
  alpha,
} from "@mui/material";
import { useAtom } from "jotai";
import {
  selectedFileActionAtom,
  selectedFilesListAtom,
  isItemSelectedAFolderAtom,
  numOfFilesSelectedAtom,
} from "providers/FilesProvider/files-atoms";
import { fileActionListOptions } from "./fileActionOptions";
import { checkIfNull } from "@app21/core";
import { useFilesContext } from "providers/FilesProvider";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(1),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",

    padding: "4px 5px 4px 5px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.

    "&:focus": {
      borderRadius: 4,

      borderColor: theme.palette.primary.lighter,
      boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.light, 0.15)}`,
    },
  },
}));

export default function FileActionsDropdown() {
  const [selectedFileAction, setSelectedFileAction] = useAtom(
    selectedFileActionAtom
  );
  const [selectedFilesList] = useAtom(selectedFilesListAtom);
  const [isItemSelectedAFolder] = useAtom(isItemSelectedAFolderAtom);
  const [numOfFilesSelected] = useAtom(numOfFilesSelectedAtom);

  const { filesPermissions, selectedSuite } = useFilesContext();
  const handleFileActionSelect = (event) => {
    setSelectedFileAction(event.target.value ?? "");
  };
  const fileActionsList = React.useMemo(
    () =>
      fileActionListOptions({
        isItemSelected: false,
        isMobile: false,
        filesPermissions,
        numOfFilesSelected: numOfFilesSelected,
        isItemSelectedAFolder: isItemSelectedAFolder,
      }),
    [filesPermissions, numOfFilesSelected, isItemSelectedAFolder]
  );

  return (
    <FormControl size="small" sx={{ minWidth: 80 }}>
      <Select
        value={selectedFileAction}
        onChange={handleFileActionSelect}
        displayEmpty
        renderValue={(val) => {
          let action = fileActionsList.find((action) => action.value === val);
          return action ? action.label : "Action";
        }}
        SelectDisplayProps={{
          sx: {},
        }}
        inputProps={{
          "aria-label": "File Actions",
          sx: {
            fontSize: (theme) => theme.typography.body2.fontSize,
            fontWeight: (theme) => theme.typography.subtitle1.fontWeight,
          },
        }}
        input={<BootstrapInput />}
      >
        <MenuItem
          value=""
          disabled
          dense
          sx={{
            "&.Mui-disabled": {
              bgcolor: checkIfNull(selectedFilesList)
                ? "primary.main"
                : "gray.main",
              color: checkIfNull(selectedFilesList)
                ? "primary.contrastText"
                : "gray.contrastText",
            },
          }}
        >
          <Typography variant="body2">
            {checkIfNull(selectedFilesList)
              ? "You need to select a file first"
              : "Select Action?"}
          </Typography>
        </MenuItem>
        {fileActionsList.map((action) => (
          <MenuItem
            key={action.value}
            value={action.value}
            dense
            disabled={action.needsSelection && selectedFilesList.length === 0}
            sx={{ display: "flex" }}
          >
            <ListItemIcon sx={{}}>
              {action.icon && <action.icon fontSize="small" />}
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="subtitle2">{action.label} </Typography>
              }
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
