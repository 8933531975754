import React, { useState, useRef, useEffect } from "react";

import {
  Box,
  IconButton,
  Input,
  InputAdornment,
  Stack,
  styled,
  Divider,
} from "@mui/material";
import { TypingIndicator } from "blocks/modules/TrrystChat/foundations";
import { useAtom } from "jotai";
import axios from "axios";

import {
  CurrentChannelAtom,
  TypingIndicatorTimeoutAtom,
  ErrorFunctionAtom,
  OriginalMessageOnReplyAtom,
} from "blocks/modules/TrrystChat/state-atoms";
import { Send, AttachFile } from "@mui/icons-material";
import { LoadingSpinner, EmojiPicker, UploadOmniModal } from "blocks/atoms";
import { usePubNub } from "pubnub-react";
import { v4 as uuidv4 } from "uuid";
import { useOuterClick } from "utils";
import {
  useAppContext,
  useSelectedBucketId,
  useUploadFilesS3,
  useUpdateFileInChannel,
  checkIfNull,
  app21APIInstance,
  useUserInfo,
} from "@app21/core";
import { chatModuleTestIds, SIZELIMITS } from "blocks/atoms/constants";
import { ReplyMessageCard, FilePreviewCard } from "./MessageCards";
import Autolinker from "autolinker";
import { fileUploadProcessor } from "utils/fileReaderHelpers";
import { processTextWithEmoji } from "utils/emojiHelpers";
import { useChatContext } from "providers/TrrystChatProvider";

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  position: "relative",
  alignItems: "center",
  height: 50,
}));
export default function ChatMessageInput({
  /* with normal view context or contextual viewcontext */
  viewMode = "full",
  /** Option to set a draft message to display in the text window. */
  draftMessage = "",
  /** Option to attach sender data directly to each message. Enable it for high-throughput environments.
   * This is an alternative to providing a full list of users directly into the Chat provider. */
  senderInfo = false,
  /** Option to enable/disable firing the typing events when a user is typing a message. */
  typingIndicator = true,
  /** Option to enable/disable the internal file upload capability. enum - 'image' or 'all' */
  fileUpload = "all",
  /** Option to disable the input from composing and sending messages. */
  disabled = false,
  /** Option to hide the Send button. */
  hideSendButton = false,
  /** Custom UI component to override default display for the Send button. */
  sendButton = <Send />,
  /** Option to pass in an emoji picker if you want it to be rendered in the input. For more details, refer to the Emoji Pickers section in the docs. */
  emojiPicker = null,
  /** Callback to handle an event when the text value changes. */
  onChange = (value) => null,
  /** Callback to modify message content before sending it. This only works for text messages, not files. */
  onBeforeSend = (value) => null,
  /** Callback for extra actions after sending a message. */
  onSend = (value) => null,
  /** Option to provide an extra actions renderer to add custom action buttons to the input. */
  extraActionsRenderer = null,
}) {
  const pubnubChatClient = usePubNub();

  const { selectedOrganizationId, selectedSuiteId } = useAppContext();
  const selectedBucketId = useSelectedBucketId(null, true);

  const uploadFilesS3 = useUploadFilesS3();
  const { data: userInfo } = useUserInfo();
  const updateFileInChatChannel = useUpdateFileInChannel();
  const [, setUploadedFiles] = useState([]);
  const [text, setText] = useState(draftMessage);
  const [copyPastedFiles, setCopyPastedFiles] = useState([]);
  const [emojiPickerShown, setEmojiPickerShown] = useState(false);
  const [typingIndicatorSent, setTypingIndicatorSent] = useState(false);
  const [picker, setPicker] = useState();
  const [loader, setLoader] = useState(false);
  const [showFileUploaderDialog, setShowFileUploaderDialog] =
    React.useState(false);
  const [currentChannel] = useAtom(CurrentChannelAtom);
  const [onErrorObj] = useAtom(ErrorFunctionAtom);
  const onError = onErrorObj.function;
  const [typingIndicatorTimeout] = useAtom(TypingIndicatorTimeoutAtom);
  const { chatPermissions } = useChatContext();
  const canReply = chatPermissions?.canRespondToChat;
  const [originalMessageOnReply, setOriginalMessageOnReply] = useAtom(
    OriginalMessageOnReplyAtom
  );

  const inputRef = useRef(null);
  const messageboxRef = useRef(null);
  const fileRef = useRef(null);

  const pickerRef = useOuterClick((event) => {
    if (event.target.closest(".pn-msg-input__emoji-toggle")) return;
    setEmojiPickerShown(false);
  });
  const placeholder = canReply
    ? "Send Message"
    : "You do not have permission to respond to chat messages in this channel. You can only view the messages. You can chat with your team members in other channels such as Breakouts or meeting chats or file chats";
  /*
  /* Helper functions
  */

  React.useEffect(() => {
    if (originalMessageOnReply) {
      inputRef.current.focus();
    }
  }, [originalMessageOnReply]);

  const autoSize = () => {
    const input = messageboxRef.current;
    if (!input) return;

    setTimeout(() => {
      input.style.cssText = `height: auto;`;
      input.style.cssText = `height: ${input.scrollHeight}px;`;
    }, 0);
  };

  const isValidInputText = () => {
    return !!text.trim().length;
  };

  /*
  /* Commands
  */
  function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  const checkFileType = (fileType) => {
    if (!fileType) return null;

    if (fileType.includes("video/")) return "VIDEO-FILE";
    else if (fileType.includes("image/")) return "IMAGE-FILE";
    else if (fileType.includes("audio/")) return "AUDIO-FILE";
    else if (fileType.includes("/pdf")) return "PDF-FILE";
    else return "OTHER-FILE";
  };

  const getThumbnail = async (fileObj) => {
    try {
      // reassign a preview URL, the previous one will have been lost.
      // https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL
      // The URL lifetime is tied to the document in the window on which it was created. The new object URL represents the specified File object or Blob object.
      Object.assign(fileObj, {
        preview: URL.createObjectURL(fileObj),
      });

      const response = await fetch(fileObj?.preview, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const blob = await response.blob();
      const thumbnail = await blobToBase64(blob);
      return thumbnail;
    } catch (err) {
      console.log(err);
    }
  };

  const uploadFileAndUpdateChannel = async (
    fileObj,
    message,
    publishMessageResult,
    isCopyPasteFile
  ) => {
    if (isCopyPasteFile) {
      const file = {
        bucket: selectedBucketId,
        key: `${selectedOrganizationId}/${selectedSuiteId}/chats/${currentChannel}/${decodeURIComponent(
          fileObj.name
        )}`,
        contentType: fileObj.type,
        calledBy: userInfo?._id,
        contentDisposition: "attachment",
        urlTypeRequested: "post",
      };

      const { data } = await app21APIInstance.post(
        `/s3/fetchurl`,
        { list: [file] },
        {
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "json",
        }
      );

      if (data && data.result) {
        const url = data.result[0].key.signedURL;

        try {
          await axios.put(url, fileObj, {
            headers: {
              "Content-Type": fileObj.type,
            },
          });
        } catch (error) {
          console.log(error);
        }
      }
    }

    // update the chat channel with this file upload information.
    await uploadFilesS3.mutate({
      fileList: [fileObj.name], // just name is enough here, actual file upload will happen via SignedUrl
      bucket: selectedBucketId,
      key: `${selectedOrganizationId}/${selectedSuiteId}/chats/${currentChannel}/${decodeURIComponent(
        fileObj.name
      )}`,
      notify: null,
      s3Prefix: `${selectedOrganizationId}/${selectedSuiteId}/chats/${currentChannel}/`,
      channels: [], // TODO: get this from user
      membersToNotify: [],
    });

    await updateFileInChatChannel.mutate({
      message: {
        ...message,
        ...publishMessageResult,
        thumbnail:
          checkFileType(fileObj?.type) === "IMAGE-FILE"
            ? await getThumbnail(fileObj)
            : null,
      },
      channelId: currentChannel,
      action: "ADD",
      additionalData: { suiteId: selectedSuiteId },
    });
  };

  const hasCopyPastedFiles =
    (copyPastedFiles && copyPastedFiles.length > 0) ?? false;

  const hasText = text && text.trim().length > 0;

  const sendMessage = async (uploadFiles = []) => {
    try {
      if (!(uploadFiles || hasCopyPastedFiles || hasText)) return;

      if (hasCopyPastedFiles) {
        // uploadFiles that comes back from the UploadMiniModal already has the time stamp prefix to file addded.
        await Promise.all(
          copyPastedFiles?.map(async (fileObj) => {
            let app21MessageType = checkFileType(fileObj?.type);
            let message = {
              id: uuidv4(),
              text: text || `File : ${fileObj.name}`,
              type: "default",
              ...(senderInfo && {
                sender: pubnubChatClient.getUUID(),
              }),
              createdAt: new Date().toISOString(),
              customElements: {
                s3Key: `${selectedOrganizationId}/${selectedSuiteId}/chats/${currentChannel}/${decodeURIComponent(
                  fileObj.name
                )}`,
                fileSize: fileObj.size,
                app21MessageType,
                originalMessageOnReply: originalMessageOnReply,
              },
            };

            if (onBeforeSend) message = onBeforeSend(message) || message;

            const publishMessageResult = await pubnubChatClient.publish({
              channel: currentChannel,
              message,
            });

            await uploadFileAndUpdateChannel(
              fileObj,
              message,
              publishMessageResult,
              true
            );

            setOriginalMessageOnReply({});
            onSend && onSend(message);
          })
        );
      } else if (!checkIfNull(uploadFiles)) {
        await Promise.all(
          uploadFiles?.map(async (fileObj) => {
            let app21MessageType = checkFileType(fileObj?.type);
            let message = {
              id: uuidv4(),
              text: text || `File : ${fileObj.name}`,
              type: "default",
              ...(senderInfo && {
                sender: pubnubChatClient.getUUID(),
              }),
              createdAt: new Date().toISOString(),
              customElements: {
                s3Key: `${selectedOrganizationId}/${selectedSuiteId}/chats/${currentChannel}/${decodeURIComponent(
                  fileObj.name
                )}`,
                fileSize: fileObj.size,
                app21MessageType,
                originalMessageOnReply: originalMessageOnReply,
              },
            };

            if (onBeforeSend) message = onBeforeSend(message) || message;
            const publishMessageResult = await pubnubChatClient.publish({
              channel: currentChannel,
              message,
            });

            await uploadFileAndUpdateChannel(
              fileObj,
              message,
              publishMessageResult,
              false
            );

            setOriginalMessageOnReply({});
            onSend && onSend(message);
          })
        );
      } else if (text) {
        const linksInText = Autolinker.parse(text, {
          urls: true,
          email: true,
        });

        let app21MessageType = null;
        if (linksInText.length > 0) app21MessageType = "TEXT-WITH-LINKS";
        else app21MessageType = "PLAIN-TEXT";

        // currently only parsing the first URL in the text, same as what WhatsApp client does
        let urlMetadataResponse = {};
        if (linksInText.length > 0) {
          urlMetadataResponse = await app21APIInstance.post(`/url/metadata`, {
            url: `${linksInText[0].matchedText}`,
          });
        }
        let message = {
          id: uuidv4(),
          text: text,
          type: "default",
          ...(senderInfo && {
            sender: pubnubChatClient.getUUID(),
          }),
          createdAt: new Date().toISOString(),
          pn_gcm: {
            notification: {
              type: "PUBNUB-CHAT-MESSAGE",
              payload: {
                text,
                app21MessageType,
                metadata: {
                  orgId: selectedOrganizationId,
                  suiteId: selectedSuiteId,
                  channelId: currentChannel,
                },
              },
            },
          },
          customElements: {
            originalMessageOnReply: originalMessageOnReply,
            app21MessageType,
            urlMetadata: urlMetadataResponse?.data?.data
              ? urlMetadataResponse?.data?.data
              : {},
          },
        };

        if (onBeforeSend) message = onBeforeSend(message) || message;
        await pubnubChatClient.publish({
          channel: currentChannel,
          message,
        });
        setOriginalMessageOnReply({});
        onSend && onSend(message);
      }

      if (typingIndicator) stopTypingIndicator();
      clearInput();
    } catch (e) {
      onError(e);
    } finally {
      setLoader(false);
    }
  };

  const startTypingIndicator = async () => {
    if (typingIndicatorSent) return;
    try {
      setTypingIndicatorSent(true);
      const message = {
        message: { type: "typing_on" },
        channel: currentChannel,
      };
      pubnubChatClient.signal(message);
    } catch (e) {
      onError(e);
    }
  };

  const stopTypingIndicator = async () => {
    if (!typingIndicatorSent) return;
    try {
      setTypingIndicatorSent(false);
      const message = {
        message: { type: "typing_off" },
        channel: currentChannel,
      };
      pubnubChatClient.signal(message);
    } catch (e) {
      onError(e);
    }
  };

  const clearInput = () => {
    setUploadedFiles([]);
    setText("");
    setCopyPastedFiles([]);
    autoSize();
    fileRef.current.value = null;
  };

  /*
  /* Event handlers
  */

  const handleEmojiInsertion = (emoji) => {
    try {
      if (!("unified" in emoji)) return;
      setText((text) => text + String.fromCodePoint("0x" + emoji.unified));
      setEmojiPickerShown(false);
    } catch (e) {
      onError(e);
    }
  };

  const handleKeyPress = (event) => {
    try {
      if (event.key === "Enter" && !event.shiftKey) {
        event.preventDefault();
        sendMessage();
      }
    } catch (e) {
      onError(e);
    }
  };

  const handleInputChange = (event) => {
    try {
      const textArea = event.target;
      const newText = textArea.value;

      if (typingIndicator && newText.length) startTypingIndicator();
      if (typingIndicator && !newText.length) stopTypingIndicator();

      onChange && onChange(newText);
      autoSize();
      setText(newText);
    } catch (e) {
      onError(e);
    }
  };

  const handleFileDialogClose = () => {
    setShowFileUploaderDialog(false);
  };

  const handleCopyPaste = (event) => {
    if (event?.clipboardData?.files?.length) {
      const { files = {} } = event.clipboardData;

      setCopyPastedFiles((oldFiles) => [
        ...oldFiles,
        ...fileUploadProcessor(Object.values(files), true),
      ]);
    }
  };

  const handleRemoveCopyPastedFile = (index) => {
    if (index > -1) {
      setCopyPastedFiles((oldFiles) => oldFiles.filter((_, i) => i !== index));
    }
  };

  /*
  /* Lifecycle
  */
  useEffect(() => {
    if (React.isValidElement(emojiPicker)) {
      setPicker(
        React.cloneElement(emojiPicker, { onSelect: handleEmojiInsertion })
      );
    }
  }, [emojiPicker]);

  useEffect(() => {
    let timer = null;

    if (typingIndicatorSent) {
      timer = setTimeout(() => {
        setTypingIndicatorSent(false);
      }, (typingIndicatorTimeout - 1) * 1000);
    }

    return () => clearTimeout(timer);
  }, [typingIndicatorSent]);

  /*
  /* Renderers
  */

  const renderCopyPastedFiles = (copyPastedFiles = []) => {
    if (copyPastedFiles.length <= 0) return null;
    return (
      <Stack direction="row" spacing={1}>
        {copyPastedFiles.map((file, index) => {
          return (
            <FilePreviewCard
              key={index}
              index={index}
              file={file}
              onRemove={handleRemoveCopyPastedFile}
            />
          );
        })}
      </Stack>
    );
  };
  return (
    <>
      <Box sx={{ height: 12, p: 0, maxHeight: 12, mb: 0.3, lineHeight: 0.25 }}>
        <TypingIndicator />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          boxShadow: (theme) => theme.customShadows.z10,
          border: (theme) => `1px solid ${theme.palette.divider}`,
          bgcolor: "background.paper",
        }}
      >
        {!checkIfNull(originalMessageOnReply) && (
          <ReplyMessageCard originalMessageOnReply={originalMessageOnReply} />
        )}
        {!checkIfNull(copyPastedFiles) &&
          renderCopyPastedFiles(copyPastedFiles)}
        <RootStyle onPaste={handleCopyPaste}>
          {extraActionsRenderer && extraActionsRenderer()}
          <Input
            data-testid={chatModuleTestIds.sendMessageInput}
            disabled={!canReply || disabled}
            {...(viewMode === "compact" ? { margin: "dense" } : {})}
            onChange={(e) => handleInputChange(e)}
            onKeyPress={(e) => handleKeyPress(e)}
            placeholder={placeholder}
            ref={messageboxRef}
            inputRef={inputRef}
            multiline
            minRows={1}
            maxRows={4}
            fullWidth
            value={text}
            disableUnderline
            // onKeyUp={handleKeyPress}
            startAdornment={
              !disabled ? (
                <InputAdornment position="start">
                  <EmojiPicker
                    disabled={disabled || !canReply}
                    value={text}
                    setValue={handleEmojiInsertion}
                  />
                </InputAdornment>
              ) : (
                <></>
              )
            }
            endAdornment={
              <Stack
                direction="row"
                spacing={1}
                sx={{ flexShrink: 0, mr: 1.5 }}
              >
                {!disabled && canReply && fileUpload && (
                  <IconButton onClick={() => setShowFileUploaderDialog(true)}>
                    <AttachFile
                      data-testid={chatModuleTestIds.fileUploadButton}
                    />
                  </IconButton>
                )}
                {/*!disabled && fileUpload && renderFileUpload()*/}
              </Stack>
            }
          />
          <Divider orientation="vertical" flexItem />
          {!hideSendButton && !disabled && (
            <IconButton
              data-testid={chatModuleTestIds.sendMessageButtonId}
              color="primary"
              disabled={
                loader ||
                disabled ||
                !canReply ||
                !(hasCopyPastedFiles || hasText)
              }
              onClick={() => sendMessage()}
              sx={{ mx: 1 }}
              title="Send"
            >
              {loader ? <LoadingSpinner /> : sendButton}
            </IconButton>
          )}
          {emojiPickerShown && <div ref={pickerRef}>{picker}</div>}
          {showFileUploaderDialog && (
            <UploadOmniModal
              open={showFileUploaderDialog}
              title="Add Files to share"
              showUploadFolderOption={false}
              showPreview
              showNotify={false}
              bucket={selectedBucketId}
              s3Prefix={`${selectedOrganizationId}/${selectedSuiteId}/chats/${currentChannel}/`}
              maxSize={SIZELIMITS.uploadMaxFileSize}
              onComplete={(uploadFiles) => {
                setText(uploadFiles[0].name);
                sendMessage(uploadFiles);
              }}
              onClose={handleFileDialogClose}
              prefixTimestampToFilename={true}
            />
          )}
        </RootStyle>
      </Box>
    </>
  );
}
