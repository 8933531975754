// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { Fragment } from "react";
import PropTypes from "prop-types";
// material
import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  useTheme,
  Container,
} from "@mui/material";
import { Icon } from "@iconify/react";
import menu2Fill from "@iconify/icons-eva/menu-2-fill";
import { LAYOUTWIDTHS } from "blocks/atoms/constants";
import { useAppContext, useSuite } from "@app21/core";
import { useLayoutContext } from "providers/LayoutProvider";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.up("lg")]: {
    // width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: LAYOUTWIDTHS.TopbarHeight,

  [theme.breakpoints.up("lg")]: {
    minHeight: LAYOUTWIDTHS.TopbarHeight,
    padding: theme.spacing(0, 5),
  },
}));

const TopBarLayout = ({
  viewContext,
  LeftComponent,
  MainComponent,
  RightComponent,
  chimeViewMode,
  activeConference,
  onMobileNavOpen,
  scrollY = 0,
  ...rest
}) => {
  const { menuViewMode } = useLayoutContext() ?? {};
  const theme = useTheme();

  // const leftGap =
  //   menuViewMode === "menuOnly"
  //     ? 0
  //     : menuViewMode === "full"
  //     ? LAYOUTWIDTHS.NavbarWidth
  //     : LAYOUTWIDTHS.NavbarCompactWidth;

  // const rightGap =
  //   chimeViewMode === "normal"
  //     ? LAYOUTWIDTHS.standardVCWidth
  //     : chimeViewMode === "compact"
  //     ? LAYOUTWIDTHS.compactVCWidth
  //     : 0;

  const DefaultLeftPanel = () =>
    menuViewMode === "menuOnly" ? (
      <IconButton
        onClick={onMobileNavOpen}
        sx={{ mr: 1, color: "text.primary" }}
      >
        <Icon icon={menu2Fill} />
      </IconButton>
    ) : null;

  const LeftPanel = LeftComponent || DefaultLeftPanel;
  const MiddlePanel = MainComponent || Fragment;
  const RightPanel = RightComponent || Fragment;

  return (
    <StyledAppBar
      position="sticky"
      sx={{
        bgcolor: "background.paper",
      }}
    >
      <StyledToolbar>
        <LeftPanel />

        <Box sx={{ flexGrow: 1 }}>
          <MiddlePanel />
        </Box>

        <RightPanel />
      </StyledToolbar>
    </StyledAppBar>
  );
};

TopBarLayout.propTypes = {
  className: PropTypes.string,
};

export default TopBarLayout;
