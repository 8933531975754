import React from "react";
import { styled } from "@mui/material";


const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 320,
  minWidth: 30,
  width: "fit-content",
  marginTop: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  color: "#727287",
  wordWrap: "break-word",
  fontStyle: 'italic'
}));

export default function DeletedMessageCard() {

  return (
    <ContentStyle>
      This message has been deleted.
    </ContentStyle>
  );
}
