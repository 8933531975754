// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
// import dynamic from "next/dynamic";

import { useTheme } from "@mui/material";

import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar } from "recharts";
// export interface IBarChartData {
//   key: string | number;
//   value: string | number;
// }

// export interface IBarChartWrapperProps {
//   data: IBarChartData[];
//   width?: number;
//   height?: number;
//   YAxisName?: string;
//   name?: string;
//   tickFormatter?: (e: string | number) => string;
//   xAxisFormatter?: (e: string | number) => string;
//   customToolTipProps?: TooltipProps<string | number, string | number>;
//   // children: React.ReactNode;
//   layout?: any;
// }

export const BarChartWrapper = props => {
  const theme = useTheme();
  const {
    data,
    layout,
    width,
    height,
    YAxisName,
    name,
    customToolTipProps,
    tickFormatter,
    xAxisFormatter,
    children
  } = props;
  // const { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar } = dynamic(
  //   async () => await import("recharts"),
  //   {
  //     ssr: false
  //   }
  // );
  return (
    <BarChart layout={layout} width={width} height={height} data={data}>
      <CartesianGrid strokeDasharray="1" />
      <XAxis dataKey="key" tickFormatter={xAxisFormatter} />
      <YAxis name={YAxisName} tickFormatter={tickFormatter} />
      <Tooltip formatter={tickFormatter} {...customToolTipProps} />
      <Bar dataKey="value" name={name} fill={theme.palette.primary.main} />
      {children}
    </BarChart>
  );
};
