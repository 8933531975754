// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Tooltip,
  IconButton,
} from "@mui/material";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";

import { getS3ImageWrapper, useHandleRouterPush } from "@app21/core";

import { useRouter } from "next/router";
import { useCheckActiveLink } from "hooks";
import { alpha, styled, useTheme } from "@mui/material/styles";
import { Icon as IconComponent } from "@iconify/react";
import { useLayoutContext } from "providers/LayoutProvider";

const ListItemStyle = styled((props) => <ListItem button {...props} />)(
  ({ theme }) => ({
    ...theme.typography.body2,

    position: "relative",
    textTransform: "capitalize",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(0.5),
    color: theme.palette.grey[600],
    marginTop: theme.spacing(2),
    "&:hover": { borderRadius: theme.shape.borderRadiusMenu },
    "&:before": {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      display: "none",
      position: "absolute",
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
    },
  })
);

const ListItemIconStyle = styled(ListItemIcon)({
  //width: 30,
  height: 30,
  fontWeight: "bold",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const NavItem = ({
  children,
  className,
  depth,
  href,
  route,
  params = {},
  icon: Icon,
  info: Info,
  secondAction: SecondAction,
  avatar: avatar,
  open: openProp,
  title,
  ...rest
}) => {
  const [open, setOpen] = useState(openProp);
  const { menuViewMode } = useLayoutContext();
  const router = useRouter();
  const theme = useTheme();
  const isActiveLink = useCheckActiveLink(route);
  const { loadRoute } = useHandleRouterPush();

  const activeRootStyle = {
    color: "common.white",
    fontWeight: "fontWeightMedium",
    bgcolor: alpha(theme.palette.primary.main, 0.9),
    borderRadius: theme.shape.borderRadiusMenu,
    "&:before": { display: "block" },
    "&:hover": {
      bgcolor: alpha(theme.palette.primary.lighter, 0.7),
      borderRadius: theme.shape.borderRadiusMenu,
    },
  };

  const activeSubStyle = {
    color: "text.primary",
    fontWeight: "fontWeightMedium",
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleMenuChoice = (route, params = {}) => {
    loadRoute(route, { ...params });
    // router.push(href);
  };
  let paddingLeft = 8;

  if (depth > 0 && menuViewMode !== "compact") {
    paddingLeft = 16 + 4 * depth;
  }

  const style = { paddingLeft };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleToggle}
          sx={{
            ...(isActiveLink && activeRootStyle),
            px: 2,
          }}
        >
          <ListItemIconStyle>
            {Icon && (
              <Icon
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: (theme) => theme.spacing(1),
                  height: 23,
                  width: 23,
                }}
              />
            )}
          </ListItemIconStyle>
          {menuViewMode !== "compact" ? (
            <ListItemText disableTypography primary={title} />
          ) : null}

          <Box
            component={IconComponent}
            icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
            sx={{ width: 16, height: 16, ml: -1.5 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      </>
    );
  } else {
    return (
      <ListItemStyle
        onClick={(event) => {
          event.stopPropagation();
          handleMenuChoice(route, params);
        }}
        sx={{
          pl: 3,
          borderRadius: theme.shape.borderRadiusMenu,
          "&:hover": {
            borderRadius: theme.shape.borderRadiusMenu,
            outline: `1px solid ${alpha(theme.palette.gray.lighter, 0.7)}`,
          },
          ...(isActiveLink && activeRootStyle),
        }}
      >
        <ListItemIconStyle>
          {avatar
            ? avatar
            : Icon && (
                <Icon
                  sx={{
                    color: (theme) =>
                      isActiveLink
                        ? theme.palette.grey[0]
                        : theme.palette.grey[600],

                    display: "flex",
                    alignItems: "center",
                    mr: 1,
                    height: 23,
                    width: 23,
                  }}
                />
              )}
        </ListItemIconStyle>
        {menuViewMode !== "compact" ? (
          <ListItemText disableTypography primary={title} />
        ) : null}
      </ListItemStyle>
    );
  }
};

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  route: PropTypes.string,
  params: PropTypes.object,
  icon: PropTypes.elementType,
  info: PropTypes.elementType,
  open: PropTypes.bool,
  title: PropTypes.any.isRequired,
  menuLabel: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
  open: false,
};

export default NavItem;
