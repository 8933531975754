import React from 'react'
import { SvgIcon } from '@mui/material';

export default function CopyFolder2Icon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 110 106 "
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M54.9618 11.4658H82.4427C88.5496 11.4658 91.603 14.5193 91.603 20.6261V24.8399"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.4274 38.8242H51.9083C58.0152 38.8242 61.0686 41.8777 61.0686 47.9845V52.1983"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.10681 72.4731V54.0914C6.10681 41.8777 9.16025 38.8242 21.374 38.8242H25.9541C30.5343 38.8242 31.5419 40.1677 33.2824 42.4883L37.8625 48.5952C39.0228 50.1219 39.6946 51.038 42.748 51.038H51.9083C64.1221 51.038 67.1755 54.0914 67.1755 66.3051"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M67.1755 78.4893V84.6267C67.1755 96.8404 64.1221 99.8938 51.9083 99.8938H21.374C9.16025 99.8938 6.10681 96.8404 6.10681 84.6267"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M41.314 16.1386C39.8711 17.5815 39.0606 20.1746 38.9455 25.3895H38.913L38.913 32.7178C38.913 33.9724 37.8959 34.9895 36.6412 34.9895C35.3866 34.9895 34.3695 33.9724 34.3695 32.7178L34.3695 25.3895H34.3636C34.4697 19.9454 35.2827 15.6926 38.0753 12.9C41.0937 9.88163 45.8179 9.17578 51.9084 9.17578H56.4885C58.9769 9.17578 60.8498 9.53524 62.3968 10.4646C63.8287 11.3248 64.7553 12.5625 65.5483 13.6217L70.2244 19.8567C70.8623 20.6958 71.0629 20.9189 71.3143 21.0708C71.5063 21.1868 71.9624 21.3895 73.2824 21.3895H82.4427C88.5332 21.3895 93.2575 22.0954 96.2758 25.1137C99.2941 28.132 100 32.8563 100 38.9468C100 40.2115 98.9747 41.2368 97.7099 41.2368C96.4451 41.2368 95.4198 40.2115 95.4198 38.9468C95.4198 32.8235 94.599 29.9142 93.0371 28.3524C91.4753 26.7905 88.566 25.9697 82.4427 25.9697H73.2824C71.549 25.9697 70.1426 25.7143 68.9452 24.9907C67.8762 24.3446 67.1802 23.4243 66.6694 22.7489L66.6683 22.7475C66.636 22.7048 66.6045 22.6631 66.5737 22.6225L66.5649 22.6109L61.9847 16.504C61.0598 15.2708 60.6294 14.7459 60.0382 14.3908C59.5013 14.0682 58.5804 13.7559 56.4885 13.7559H51.9084C45.7851 13.7559 42.8758 14.5768 41.314 16.1386Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M97.8321 48.8398C97.8321 48.8398 97.8321 54.7024 97.8321 62.0307C97.8321 69.3589 93.4351 72.2902 88.3053 72.2902C83.1756 72.2902 76.2137 72.2902 76.2137 72.2902"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
        />
      </g>
    </SvgIcon>
  );
}
