import React from "react";
import { IconButton } from "@mui/material";
import { Search } from "@mui/icons-material";

const ChartSuiteInfoPicker = () => {
  return (
    <IconButton size="small">
      <Search fontSize="inherit" />
    </IconButton>
  );
};
export default ChartSuiteInfoPicker;
