// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import Head from "next/head";
import React, { useState, Fragment } from "react";
import clsx from "clsx";
import ObjectID from "bson-objectid";
import LoadingSpinner from "blocks/atoms/LoadingSpinner";
import PropTypes from "prop-types";
import dompurify from "dompurify";
import {
  Paper,
  Box,
  Button,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  NoSsr,
  ScopedCssBaseline,
  Divider,
} from "@mui/material";
import DocketAgendaMenu from "blocks/atoms/uicomponents/DocketAgendaMenu";
import { formalAgenda } from "./formalBoardTemplate";
import { FlowBox } from "blocks/atoms/uistyles";
import {
  checkIfNull,
  useUploadFilesS3,
  useDeleteFilesS3,
  useDockets,
  useMeetingsInDockets,
  useAppContext,
  useSelectedBucketId,
  useHandleRouterPush,
  getRolePrivileges,
  useHandleMeetingActions,
  executeApi,
} from "@app21/core";

import useDeepCompareEffect from "use-deep-compare-effect";

import { UploadSingleFileDialog } from "blocks/atoms/uicomponents/upload";
import { Base64 } from "js-base64";
import AddEditAgendaCard from "./AddEditAgendaCard";
import { useIntl } from "react-intl";
import {
  ShowAgendaHeaderMenu,
  AgendaFilesListPopover,
} from "blocks/modules/Dockets/foundations";
import { useIsMobileBreakpoint } from "hooks";
import toast from "react-hot-toast";
import Image from "next/image";
import AddIcon from "@mui/icons-material/Add";

import { useDocketsContext } from "providers/DocketsProvider";

const ShowAgenda = ({ viewMode, selectedMeeting = {} }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [uploadingActive, setUploadingActive] = useState(false);
  const relatedMeeting = selectedMeeting ?? {};

  const {
    selectedOrganizationId,
    selectedSuiteId,
    selectedDocketId,
    checkAccessRules,
    userInfo,
  } = useAppContext();
  const { selectedDocket = {} } = useDocketsContext();
  const selectedBucketId = useSelectedBucketId(null, true);

  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });
  const isMobile = useIsMobileBreakpoint();
  const [addAgenda, setAddAgenda] = React.useState(false);
  const [uploadedAgenda, setUploadedAgenda] = useState(
    checkIfNull(relatedMeeting?.uploadedAgenda)
      ? null
      : relatedMeeting?.uploadedAgenda
  );

  const { accessFlag: canEditAgenda } = checkAccessRules({
    entity: "DOCKET",
    action: "MODIFY-AGENDA",
    featureName: "DOCKET-AGENDA",
    ...getRolePrivileges({
      userId: userInfo._id,
      baseObject: selectedDocket,
      members: selectedDocket?.members ?? [],
    }),
  });
  const isEditable = canEditAgenda && isEditing;
  let tableMode = "VIEW-FULL-TABLE";
  if (viewMode === "summary") {
    tableMode = "VIEW-SHORT-TABLE";
  } else {
    if (isEditable) {
      tableMode = "EDIT-TABLE";
    }
  }

  const { accessFlag: canDeleteAgenda } = checkAccessRules({
    entity: "DOCKET",
    action: "DELETE-AGENDA",
    featureName: "DOCKET-AGENDA",
    ...getRolePrivileges({
      userId: userInfo._id,
      baseObject: selectedDocket,
      members: selectedDocket?.members ?? [],
    }),
  });

  const { accessFlag: canAddAgenda } = checkAccessRules({
    entity: "DOCKET",
    action: "ADD-AGENDA",
    featureName: "DOCKET-AGENDA",
    ...getRolePrivileges({
      userId: userInfo._id,
      baseObject: selectedDocket,
      members: selectedDocket?.members ?? [],
    }),
  });

  const { accessFlag: canViewAgenda } = checkAccessRules({
    entity: "DOCKET",
    action: "ADD-AGENDA",
    featureName: "DOCKET-AGENDA",
    ...getRolePrivileges({
      userId: userInfo._id,
      baseObject: selectedDocket,
      members: selectedDocket?.members ?? [],
    }),
  });

  const { accessFlag: canAttachAgendaFiles } = checkAccessRules({
    entity: "DOCKET",
    action: "ATTACH-AGENDA-FILES",
    featureName: "DOCKET-AGENDA",
    ...getRolePrivileges({
      userId: userInfo._id,
      baseObject: selectedDocket,
      members: selectedDocket?.members ?? [],
    }),
  });

  const deleteFilesS3 = useDeleteFilesS3();
  const uploadFilesS3 = useUploadFilesS3();
  const { handleMeetingActions } = useHandleMeetingActions();

  const [agendaItemsData, setAgendaItemsData] = useState([]);

  const [tempDeletedFiles, setTempDeletedFiles] = useState([]);

  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

  const blank_data = {
    _id: ObjectID().toHexString(),
    when: "",
    seq: "1",
    what: "Call to Order and Introductions",
    who: "",
    files: [],
  };

  useDeepCompareEffect(() => {
    if (
      relatedMeeting &&
      Object.prototype.hasOwnProperty.call(relatedMeeting, "agendaItems") &&
      !checkIfNull(relatedMeeting?.agendaItems)
    )
      setAgendaItemsData(relatedMeeting?.agendaItems);
  }, [agendaItemsData || [], relatedMeeting?.agendaItems || []]);

  const handleNewAgendaCreate = (option, file) => {
    switch (option) {
      case "formal":
        setAgendaItemsData(formalAgenda);
        setIsEditing(true);
        break;
      case "upload":
        setUploadDialogOpen(false);
        setUploadedAgenda(file);
        break;
      default: {
        let blankAgenda = [
          {
            _id: ObjectID().toHexString(),
            when: "",
            seq: "1",
            what: "Call to Order and Introductions",
            who: "",
            files: [],
          },
        ];
        setAgendaItemsData([]);
        setIsEditing(true);
        setAddAgenda(true);
        break;
      }
    }
  };

  const ShowEmptyAgendaComponent = () => {
    const handleAgendaFileUpload = async (uploadFiles) => {
      setUploadingActive(true);
      const reader = new FileReader();
      reader.readAsDataURL(uploadFiles[0]);

      let conversionId = -1;
      reader.onloadend = async (event) => {
        const filename = uploadFiles[0].name;

        const resData = await fetch("https://api.convertio.co/convert", {
          method: "POST",
          body: JSON.stringify({
            apikey: "7d6b7b43b3213d406421c3853a5d65d6",
            input: "base64",
            file: reader.result.split(";")[1].split(",")[1],
            filename: filename,
            outputformat: "html",
          }),
        });

        conversionId = await resData.json();
      };

      const fetchFileAfterConversion = async () => {
        const conversionStep2 = await fetch(
          `https://api.convertio.co/convert/${conversionId.data.id}/dl`,
          {
            method: "GET",
          }
        );
        const downloadedFile = await conversionStep2.json();

        if (downloadedFile.status === "ok") {
          clearInterval(id);

          const bytes = downloadedFile.data.content;

          if (Base64.isValid(bytes)) {
            let decodedFile = Base64.atob(bytes);

            handleNewAgendaCreate("upload", decodedFile);
            setUploadingActive(false);

            setIsEditing(true);
          } else {
            setUploadingActive(false);
            setUploadDialogOpen(false);
          }
        }
      };

      const id = setInterval(fetchFileAfterConversion, 5000);

      // 7d6b7b43b3213d406421c3853a5d65d6
      //setUploadDialogOpen(false);
    };

    return (
      <Box sx={{ textAlign: "center" }}>
        <Image
          alt={"No Agenda"}
          src="/static/images/NoAgenda.jpeg"
          height={150}
          width={150}
          style={{
            maxWidth: "100%",
            height: "auto",
            objectFit: "contain",
          }}
        />
        <Typography variant="h5" display="block">
          There is no Agenda
        </Typography>
        <Typography variant="h5" sx={{ mb: 4 }}>
          associated with the Meeting.
        </Typography>
        <DocketAgendaMenu
          handleNewAgendaCreate={handleNewAgendaCreate}
          setUploadDialogOpen={setUploadDialogOpen}
        />
        {uploadDialogOpen && (
          <>
            <UploadSingleFileDialog
              open={uploadDialogOpen}
              accept={
                ".doc, .docx, .txt, .csv, .html, .htm, .odt, .rtf, application/pdf, .xls, .xlsx"
              }
              loading={uploadingActive}
              title="Add files to Upload"
              showPreview
              maxSize={5000000}
              onSubmit={handleAgendaFileUpload}
              onClose={() => setUploadDialogOpen(false)}
            />
          </>
        )}
      </Box>
    );
  };

  const uploadFilesUsingSignedUrlToS3 = async (files, uploadPrefix) => {
    await Promise.all(
      files.map(async (elem) => {
        const response = await executeApi("FETCH-SIGNED-URL", {
          bucket: selectedBucketId,
          key: `${uploadPrefix}${elem.file.name}`,
          urlTypeRequested: "post",
          contentType: elem.file.type,
          calledBy: userInfo._id,
        });
        await fetch(response?.signedUrl, {
          method: "PUT",
          headers: { "Content-Type": elem.file.type },
          body: elem.file,
        });
      })
    );
  };

  const handleAddNewAgenda = () => {
    setAddAgenda(true);
  };

  const handleSaveNewAgenda = (data) => {
    const latestArray =
      agendaItemsData.length > 0 ? [...agendaItemsData, data] : [data];
    onSubmit({
      agenda: latestArray,
    });
    setAddAgenda(false);
  };

  const closeAddNewAgenda = () => {
    setAddAgenda(false);
  };

  const DeleteAgenda = async (agenda, indexValue) => {
    const agendaList = [...agendaItemsData];
    const deleteFiles = agendaList.splice(indexValue, 1);
    setTempDeletedFiles(deleteFiles?.files);
    onSubmit({
      agenda: agendaList,
    });
  };

  const onSubmit = async (data) => {
    let keysToDelete = [];
    tempDeletedFiles.map((f) => keysToDelete.push(f.s3Key));

    if (keysToDelete.length > 0)
      await deleteFilesS3.mutate({
        bucket: selectedBucketId,
        filesList: keysToDelete,
        s3Prefix: `${selectedOrganizationId}/${selectedSuiteId}/dockets/${selectedDocketId}/`,
      });

    // now process the changes in newly added and changes to existing agenda items
    let deletedS3Keys = [];
    let addedFiles = [];
    //  const updatedAgendaItems = data.agenda;
    data.agenda.map((item, index) => {
      let deletedS3KeysInAgendaItem = [];
      let addedFilesInAgendaItem = [];
      item.files.map((i) => {
        if (i.deleted)
          i.deleted.map((s3Key) => {
            deletedS3KeysInAgendaItem.push(s3Key);
          }); // summation of all deleyed keys across agenda items
        if (i.added) {
          i.added.map((file) => {
            addedFilesInAgendaItem.push({ file: file.file });
          });
        }
      });
      // update the agenda item files now that we have fully processed added and deleted files.
      const updatedListOfFiles = [];

      item.files.map((i) => {
        if (
          Object.prototype.hasOwnProperty.call(i, "s3Key") &&
          !deletedS3KeysInAgendaItem.includes(i.s3Key)
        )
          updatedListOfFiles.push(i);
      });

      addedFilesInAgendaItem.map((a) => {
        updatedListOfFiles.push({
          s3Key: `${selectedOrganizationId}/${selectedSuiteId}/dockets/${selectedDocketId}/meetings/${
            relatedMeeting._id ? relatedMeeting._id : "files"
          }/${a.file.name}`,
          image: null,
        });
      });
      data.agenda[index].files = updatedListOfFiles;
      deletedS3KeysInAgendaItem?.length > 0 &&
        deletedS3Keys.push(...deletedS3KeysInAgendaItem);
      addedFilesInAgendaItem?.length > 0 &&
        addedFiles.push(...addedFilesInAgendaItem);
    });

    // update the deletedS3Keys to match format requested by useDeleteFilesS3 hook.
    const updatedDeletedS3Keys = [];
    deletedS3Keys.map((k) => updatedDeletedS3Keys.push({ id: k }));

    if (deletedS3Keys?.length > 0)
      await deleteFilesS3.mutate({
        bucket: selectedBucketId,
        filesList: updatedDeletedS3Keys,
        s3Prefix: `${selectedOrganizationId}/${selectedSuiteId}/dockets/${selectedDocketId}/`,
      });

    const uploadPrefix = `${selectedOrganizationId}/${selectedSuiteId}/dockets/${selectedDocketId}/meetings/${
      relatedMeeting._id ? relatedMeeting._id : "files"
    }/`;

    await uploadFilesUsingSignedUrlToS3(addedFiles, uploadPrefix);

    await uploadFilesS3.mutate({
      fileList: addedFiles.map((f) => f.name), // just name is enough as actual file will be uploaded via SignedUrl
      bucket: selectedBucketId,
      s3Prefix: uploadPrefix,
      key: uploadPrefix,
    });

    await handleMeetingActions(
      {
        action: "UPDATE-MEETING",
        meetingData: {
          agendaItems: data.agenda,
        },
        meetingId: relatedMeeting._id,
      },
      null,
      null
    );
    setIsEditing(false);
  };

  const AgendaTableRow = ({ agendaItem, indexValue }) => {
    const [allowEdit, setAllowEdit] = React.useState(false);
    const handleEditAgenda = () => {
      setAllowEdit(true);
    };
    const handleSaveChanges = (data) => {
      const agendaIndexValue = agendaItemsData.findIndex(
        (agenda) => agenda._id == data._id
      );
      let latestArray = [...agendaItemsData];
      latestArray[agendaIndexValue] = data;
      onSubmit({
        agenda: latestArray,
      });
      setAllowEdit(false);
    };
    return (
      <>
        {console.log("agendaItem", agendaItem.who, "=>", allowEdit)}
        {allowEdit ? (
          <TableRow>
            <TableCell colSpan={5}>
              {" "}
              <AddEditAgendaCard
                agendaInfo={agendaItem}
                saveAgenda={handleSaveChanges}
                deleteAgenda={DeleteAgenda}
                canDeleteAgenda={canDeleteAgenda}
                indexValue={indexValue}
              />
            </TableCell>
          </TableRow>
        ) : (
          <TableRow
            sx={{
              borderBottom: "none",
              borderRadius: 0,
              boxShadow:
                "0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)",
            }}
            onDoubleClick={handleEditAgenda}
            key={agendaItem.id}
            disabled
          >
            <TableCell sx={{ height: "100%", width: "15%" }}>
              <Typography variant="body2" color="textSecondary">
                {agendaItem.when}
              </Typography>
            </TableCell>
            <TableCell sx={{ height: "100%", width: "15%" }}>
              {agendaItem.seq}
            </TableCell>
            <TableCell sx={{ height: "100%", width: "15%" }}>
              {agendaItem.what}
            </TableCell>
            <TableCell sx={{ height: "100%", width: "15%" }}>
              {agendaItem.who}
            </TableCell>
            <TableCell sx={{ height: "100%", width: "15%" }}>
              {agendaItem?.files?.length > 0 ? (
                <AgendaFilesListPopover filesList={agendaItem?.files} />
              ) : (
                <Button variant="contained" disabled>
                  {" No Files"}
                </Button>
              )}
            </TableCell>
          </TableRow>
        )}
      </>
    );
  };
  return (
    <FlowBox sx={{ p: 2 }}>
      <ShowAgendaHeaderMenu
        canEditAgenda={canEditAgenda}
        meeting={selectedMeeting}
        isEditing={isEditing}
      />

      <Box
        sx={{
          my: tableMode === "VIEW-SHORT-TABLE" ? 0 : 1,
          maxWidth: "100%",
          weight: "100%",
          height: "100%",
        }}
      >
        <>
          {agendaItemsData.length !== 0 ? (
            <>
              <TableContainer
                component={Paper}
                square
                style={{
                  width: "100%",
                  padding: 0,
                  margin: 0,
                  height: "100%",
                  borderRadius: 0,
                  border: "none",
                }}
              >
                <Table
                  sx={{
                    borderSpacing:
                      viewMode === "summary" ? 0 : `0 1px !important`,
                    borderCollapse: `separate !important`,
                    width: "100%",
                    mt: 0,
                    pt: 0,
                  }}
                  size={tableMode === "VIEW-SHORT-TABLE" ? "small" : "medium"}
                  stickyHeader
                >
                  {tableMode !== "VIEW-SHORT-TABLE" && (
                    <TableHead sx={{ mt: 0 }}>
                      <TableRow>
                        {tableMode === "EDIT-TABLE" && (
                          <TableCell
                            padding="normal"
                            style={{ marginLeft: -1, width: 5 }}
                          ></TableCell>
                        )}
                        <TableCell sx={{ width: "15%" }}>
                          Time / Duration
                        </TableCell>
                        <TableCell sx={{ width: 35 }}>Index</TableCell>
                        {!isMobile && (
                          <TableCell sx={{ flexGrow: 1 }}>Topic</TableCell>
                        )}
                        <TableCell sx={{ width: "20%" }}>
                          Presenter(s)
                        </TableCell>
                        <TableCell sx={{ width: "10%", maxWidth: 190 }}>
                          Files
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  )}
                  <TableBody>
                    {agendaItemsData.map((agendaItem, index) => {
                      return (
                        <AgendaTableRow
                          agendaItem={agendaItem}
                          key={index}
                          indexValue={index}
                        />
                      );
                    })}
                  </TableBody>
                </Table>
                {addAgenda && (
                  <AddEditAgendaCard
                    agendaInfo={blank_data}
                    saveAgenda={handleSaveNewAgenda}
                    deleteAgenda={closeAddNewAgenda}
                    canDeleteAgenda={true}
                  />
                )}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    mt: 4,
                  }}
                >
                  <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={handleAddNewAgenda}
                    disabled={addAgenda}
                  >
                    {" "}
                    ADD A NEW AGENDA
                  </Button>
                </Box>
              </TableContainer>
            </>
          ) : !canAddAgenda ? (
            <Typography variant="h5">
              You do not have the required privileges in this Meeting docket
            </Typography>
          ) : agendaItemsData.length == 0 && !addAgenda && canAddAgenda ? (
            <ShowEmptyAgendaComponent />
          ) : addAgenda ? (
            <AddEditAgendaCard
              agendaInfo={blank_data}
              saveAgenda={handleSaveNewAgenda}
              deleteAgenda={closeAddNewAgenda}
              canDeleteAgenda={true}
            />
          ) : (
            <></>
          )}
        </>
      </Box>
    </FlowBox>
  );
};

ShowAgenda.propTypes = {
  className: PropTypes.string,
};

ShowAgenda.defaultProps = {};

export default ShowAgenda;
