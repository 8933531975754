import React from 'react'
import { SvgIcon } from '@mui/material';

export default function CalendarIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M33.3336 8.3335V20.8335"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M66.6669 8.3335V20.8335"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.5836 37.8745H85.4169"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.5002 54.2085V35.4168C12.5002 22.9168 18.7502 14.5835 33.3336 14.5835H66.6669C81.2502 14.5835 87.5002 22.9168 87.5002 35.4168V70.8335C87.5002 83.3335 81.2502 91.6668 66.6669 91.6668H33.3336C18.7502 91.6668 12.5002 83.3335 12.5002 70.8335"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M65.3949 57.0843H65.4323"
          stroke="currentColor"
          strokeWidth="8.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M65.3949 69.5843H65.4323"
          stroke="currentColor"
          strokeWidth="8.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M49.9814 57.0843H50.0188"
          stroke="currentColor"
          strokeWidth="8.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M49.9814 69.5843H50.0188"
          stroke="currentColor"
          strokeWidth="8.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.5599 57.0843H34.5973"
          stroke="currentColor"
          strokeWidth="8.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.5599 69.5843H34.5973"
          stroke="currentColor"
          strokeWidth="8.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
