import React, { Fragment } from "react";
import {
  Box,
  Typography,
  Paper,
  Button,
  IconButton,
  Stack,
  Avatar,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Grid,
} from "@mui/material";
import clip from "text-clipper";
import {
  UserIcon,
  RightArrowIcon,
  LeftArrowIcon,
  ListViewIcon,
  DetailsViewIcon,
  ChartIcon,
} from "blocks/atoms/trrysticons";
import { getCustomLabelValue } from "blocks/modules/Surveys/foundations";

export default function ShowSurveyResultsTable({ question }) {
  return (
    <Table size="small" stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>
          <TableCell align="left">Answers</TableCell>
          <TableCell align="right">
            <UserIcon
              sx={{
                width: 20,
                height: 20,
              }}
            />
          </TableCell>
          <TableCell align="right">
            <ChartIcon
              sx={{
                width: 24,
                height: 24,
              }}
            />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {question?.answerChoices?.map((row, rowIndex) => {
          return (
            <TableRow key={rowIndex}>
              <TableCell
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Avatar
                  color="primary"
                  sx={{
                    width: 20,
                    height: 20,
                    fontSize: 13,
                    backgroundColor: "primary.main",
                    color: "primary.contrastText",
                  }}
                >
                  {getCustomLabelValue(row.choice, question.answerChoices)}
                </Avatar>
                <Typography sx={{ ml: 2 }}>{clip(row.choice, 30)}</Typography>
              </TableCell>
              <TableCell align="right">{row.count}</TableCell>
              <TableCell align="right">
                {question.totalResponsesCount
                  ? Math.round((row.count / question.totalResponsesCount) * 100)
                  : 0}{" "}
                %
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
