// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { Fragment, useRef, useState } from "react";

import {
  Badge,
  ListItemSecondaryAction,
  List,
  ListItem,
  ListItemAvatar,
  Typography,
  ListItemText,
  Avatar,
  ListItemButton,
} from "@mui/material";
import { MessageCircle, Users } from "react-feather";
import { ChatIcon, UsersGroupIcon } from "blocks/atoms/trrysticons";
// material
import { alpha } from "@mui/material/styles";

// components
import PerfectScrollbar from "react-perfect-scrollbar";
import { MenuPopover, IconButtonAnimate } from "blocks/atoms";

import {
  useAppContext,
  useSuite,
  useUsersInSuite,
  getS3ImageWrapper,
  useHandleRouterPush,
} from "@app21/core";
import { useChatContext } from "providers/TrrystChatProvider";

import toast from "react-hot-toast";
// ----------------------------------------------------------------------

const ITEM_HEIGHT = 64;
const PADDING_ITEM = 1.5;

export default function MembersPopover({ iconSize = "medium" }) {
  const anchorRef = useRef(null);

  const [open, setOpen] = useState(false);
  const {
    checkAccessRules,
    selectedSuiteId,
    userInfo = {},
    isBasicDataReady,
  } = useAppContext();
  const {
    data: selectedSuite,
    status: selectedSuiteStatus,
    apiError: selectedSuiteApiError,
  } = useSuite(selectedSuiteId);
  const { loadRoute } = useHandleRouterPush();
  const {
    data: usersInSuite,
    status: usersInSuiteStatus,
    apiError: usersInSuiteApiError,
  } = useUsersInSuite(selectedSuiteId);
  const dataFetchError = usersInSuiteApiError || selectedSuiteApiError;
  const dataFetchLoading =
    selectedSuiteStatus === "loading" || usersInSuiteStatus === "loading";

  React.useEffect(() => {
    if (dataFetchError && !dataFetchLoading) {
      toast.error(dataFetchError.message);
    }
  }, [dataFetchError]);
  const { accessFlag: canAccessMembers } = checkAccessRules({
    entity: "USERS-VISIBILITY",
    action: "VIEW-ALL-SUITE-USERS",
    featureName: "SUITE-USER-DETAILS",
    isInvitee: true,
  });

  const currentUser = usersInSuite?.find((user) => user._id === userInfo?._id);
  const restrictedMembersView =
    selectedSuite?.suiteType?.includes("DATA") &&
    currentUser?.roleInfo?.role !== "MANAGER";

  const { presentUUIDs: activeUserIds } = useChatContext();

  let featureHeadline = restrictedMembersView
    ? "Suite Managers"
    : "Suite Members";

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const initiateChat = async (chatWithUserId) => {
    loadRoute("GROUP-CHAT", {
      chatId:
        userInfo?._id < chatWithUserId
          ? `${userInfo?._id}-${chatWithUserId}-CHAT`
          : `${chatWithUserId}-${userInfo?._id}-CHAT`,
      chatType: "suite",
    });
  };
  if (!isBasicDataReady || !canAccessMembers) return null;
  return (
    <>
      <IconButtonAnimate
        data-testid="members-list"
        ref={anchorRef}
        onClick={handleOpen}
        color={open ? "primary" : "default"}
        size={iconSize}
        sx={{
          ...(open && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.focusOpacity
              ),
          }),
        }}
      >
        <UsersGroupIcon fontSize="inherit" />
      </IconButtonAnimate>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{
          width: 320,
          maxHeight: ITEM_HEIGHT * 8,

          height: "100%",
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <Typography variant="h6" sx={{ p: PADDING_ITEM }}>
          {featureHeadline}{" "}
          {!restrictedMembersView && (
            <Typography component="span">({usersInSuite?.length})</Typography>
          )}
        </Typography>

        <PerfectScrollbar
          options={{ suppressScrollX: true }}
          style={{ maxHeight: ITEM_HEIGHT * 8, paddingRight: 8 }}
        >
          <List>
            {usersInSuite
              ?.filter(
                (user) =>
                  !restrictedMembersView || user?.roleInfo?.role === "MANAGER"
              )
              .map((user, index) => {
                const isUserActive = activeUserIds?.includes(user._id);
                const BadgeWrapperComponent = isUserActive ? Badge : Fragment;
                const badgeWrapperProps = isUserActive
                  ? { overlap: "circular", variant: "dot", color: "success" }
                  : {};

                return (
                  <ListItem
                    data-testid={user.fullName}
                    disableGutters
                    key={index}
                    sx={{
                      px: PADDING_ITEM,
                      height: ITEM_HEIGHT,
                    }}
                  >
                    <ListItemButton
                      data-testid={user.fullName}
                      disabled={user._id === userInfo?._id}
                      onClick={() =>
                        user._id !== userInfo?._id && initiateChat(user._id)
                      }
                    >
                      <ListItemAvatar sx={{ position: "relative" }}>
                        <BadgeWrapperComponent {...badgeWrapperProps}>
                          <Avatar
                            aria-label={user.fullName}
                            alt={user.fullName}
                            src={getS3ImageWrapper(user?.image, "person")}
                          />
                        </BadgeWrapperComponent>
                      </ListItemAvatar>
                      <ListItemText
                        primaryTypographyProps={{
                          typography: "subtitle2",
                          mb: 0.25,
                        }}
                        secondaryTypographyProps={{ typography: "caption" }}
                        primary={user.fullName}
                      />
                      {user._id !== userInfo?._id && (
                        <ListItemSecondaryAction>
                          <ChatIcon fontSize="inherit" />
                        </ListItemSecondaryAction>
                      )}
                    </ListItemButton>
                  </ListItem>
                );
              })}
          </List>
        </PerfectScrollbar>
      </MenuPopover>
    </>
  );
}
