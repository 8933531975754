// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import * as React from "react";
import PropTypes from "prop-types";
import { Container, Box, Skeleton, Typography, Grid } from "@mui/material";
import { useIsMobileBreakpoint } from "hooks";

export default function MainDashboardLoading() {
  const isMobile = useIsMobileBreakpoint();
  return (
    <Grid container spacing={3} sx={{ p: 1 }}>
      {Array.from(new Array(isMobile ? 2 : 5)).map((item, index) => (
        <Grid key={index} item xs={12} sm={6} md={4}>
          <Box
            sx={{
              width: "100%",
              marginRight: 0.5,
              my: 5,
              borderRadius: 5,
              border: "1px solid #cfcfcf",
              position: "relative",
            }}
          >
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={160}
              sx={{ borderRadius: `10px 10px 0 0` }}
            />
            <Skeleton
              variant="text"
              width={80}
              height={25}
              sx={{
                position: "absolute",
                right: 5,
                top: 2,
                border: "1px solid #cfcfcf",
              }}
            />
            <Box sx={{ p: 1, display: "flex" }}>
              <Skeleton
                variant="rectangular"
                width={50}
                height={50}
                sx={{ borderRadius: 1 }}
              />
              <Box sx={{ ml: 2, pt: 0.5, flexGrow: 1 }}>
                <Skeleton />
                <Skeleton width="60%" />
              </Box>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}
