import React from "react";
import { UnreadChatNotificationCard } from "blocks/views/ChatNotifiers";
import { MenuList, Typography } from "@mui/material";

import { useChatContext } from "providers/TrrystChatProvider";

import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
export default function UnreadChatMessagesWall() {
  const { allChannelsData, unreadMessageCounts } = useChatContext();

  const [allUnreadChatsArray, setAllUnreadChatsArray] = React.useState([]);

  React.useEffect(() => {
    let unreadChats = [];
    for (const chatId in unreadMessageCounts) {
      if (unreadMessageCounts[chatId] > 0) {
        let constructedChatObj = {};
        constructedChatObj.chatId = chatId;
        constructedChatObj.unreadMessageCount = unreadMessageCounts[chatId];
        constructedChatObj.chatDetails = allChannelsData?.find(
          (chat) => chat.id === chatId
        );
        unreadChats.push(constructedChatObj);
      }
    }

    setAllUnreadChatsArray(unreadChats);
  }, [allChannelsData, unreadMessageCounts]);

  if (allUnreadChatsArray.length > 0) {
    return (
      <PerfectScrollbar
        options={{ suppressScrollX: true }}
        style={{ paddingLeft: 1, paddingRight: 5, maxHeight: 500 }}
      >
        <MenuList>
          {allUnreadChatsArray.map((unreadChatInfo, index) => (
            <UnreadChatNotificationCard
              key={index}
              unreadChatInfo={unreadChatInfo}
            />
          ))}
        </MenuList>
      </PerfectScrollbar>
    );
  } else
    return (
      <Typography variant="body2" sx={{ p: 2 }}>
        Nothing to see here. You are up to date!
      </Typography>
    );
}

UnreadChatMessagesWall.propTypes = {
  suiteId: PropTypes.string,
};
UnreadChatMessagesWall.defaultProps = {
  suiteId: "",
};
