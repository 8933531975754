import React from 'react'
import { SvgIcon } from '@mui/material';

export default function FilesIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M50.0002 54.1665H54.1669"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.1669 54.1665H39.3753"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.1669 70.8335H45.8336"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.33356 37.5002C8.33356 16.6668 16.6669 8.3335 37.5002 8.3335H58.3336"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M91.6669 41.6665V62.4998C91.6669 83.3332 83.3336 91.6665 62.5002 91.6665H37.5002C16.6669 91.6665 8.33356 83.3332 8.33356 62.4998V54.0832"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M75.0002 41.6668C62.5002 41.6668 58.3336 37.5002 58.3336 25.0002V8.3335L91.6669 41.6668"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
