import React from 'react'
import { SvgIcon } from '@mui/material';

export default function SpeakingIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M24.9847 41.0847V58.9599"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.4868 35.1338V64.8838"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M49.9888 29.1729V70.8395"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M62.4908 35.1338V64.8838"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M74.9807 41.0847V58.9599"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.502 91.6707H62.502C83.3353 91.6707 91.6687 83.3374 91.6687 62.5041V37.5041C91.6687 16.6707 83.3353 8.3374 62.502 8.3374H37.502C16.6687 8.3374 8.33533 16.6707 8.33533 37.5041V62.5041C8.33533 83.3374 16.6687 91.6707 37.502 91.6707Z"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
