import React from "react";
import { Box, Typography, Button, IconButton } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useSurveysContext } from "providers/SurveysProvider";
import { chimeTestIds, surveysModuleTestIds } from "blocks/atoms/constants";

export default function SurveysHeaderMenubar({ isMobile }) {
  const { loadRoute, surveysPermissions } = useSurveysContext();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        justifyContent: "flex-end",
        alignItems: "center",
        pb: 1,
        ml: 2,
        mt: 2,
      }}
    >
      {surveysPermissions?.canAddNew &&
        (!isMobile ? (
          <Button
            data-testid={surveysModuleTestIds.surveyAddButton}
            variant="outlined"
            size="small"
            startIcon={<Add />}
            onClick={() => loadRoute("CREATE-SURVEY", {})}
          >
            Add New
          </Button>
        ) : (
          <IconButton onClick={() => loadRoute("CREATE-SURVEY", {})}>
            <Add />
          </IconButton>
        ))}
    </Box>
  );
}
