import React from "react";
import {
  Box,
  TextField,
  Stack,
  Typography,
  IconButton,
  Button,
  Container,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import isEmail from "validator/lib/isEmail";
import { Send, Clear } from "@mui/icons-material";
import OtpInput from "react-otp-input";

export default function VerifyEmailPasscodeComponent() {
  const [emailId, setEmailId] = React.useState("");
  const [emailSent, setEmailSent] = React.useState(false);
  const [invalidOtp, setInvalidOtp] = React.useState(false);
  const [resendOtpClicked, setResendOtpClicked] = React.useState(false);
  const [otp, setOtp] = React.useState("");
  const [otpSubmitting, setOtpSubmitting] = React.useState(false);
  const otpLength = 6;

  const handleOtpClick = (otp) => {
    setOtp(otp);
  };

  const handleOtpClear = () => {
    setOtp("");
  };
  const handleOtpSubmit = () => {
    setOtpSubmitting(true);
    setTimeout(() => {
      setOtpSubmitting(false);
    }, 2000);
  };
  const handleResendOtp = () => {
    setResendOtpClicked(true);
    setTimeout(() => {
      setResendOtpClicked(false);
    }, 10000);
  };
  return (
    <Container
      maxWidth={"md"}
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "75vh",
        maxHeight: "100vh",
        maxWidth: "100vw",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <Typography variant="h6" sx={{ my: 2 }}>
        Enter your email Address to enter Meeting
      </Typography>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <TextField
          label="Email Id"
          variant="outlined"
          placeholder="Enter your email Address to enter Meeting"
          onChange={(event) => setEmailId(event.target.value)}
          fullWidth
          sx={{ marginBottom: "1rem" }}
          error={!isEmail(emailId) && emailId.length > 5}
          helperText={
            !isEmail(emailId) && emailId.length > 5 ? "Invalid email" : ""
          }
        />
        <IconButton
          disabled={!isEmail(emailId)}
          onClick={() => {
            setEmailSent(true);
          }}
          variant="contained"
          color="primary"
          sx={{ mb: 2, ml: 2 }}
        >
          <Send color={!isEmail(emailId) ? "disabled" : "success"} />
        </IconButton>
      </Box>
      {Boolean(emailSent) && (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            variant="subtitle1"
            sx={{ mb: 2, mt: 2, textAlign: "center" }}
          >
            Enter the OTP sent to your email
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <OtpInput
                value={otp}
                onChange={handleOtpClick}
                containerStyle={{}}
                numInputs={otpLength}
                inputStyle={{
                  width: 50,
                  height: 50,
                  margin: "0 1rem",
                  fontSize: "2rem",
                  borderRadius: 4,
                  border: "1px solid rgba(127, 127, 127, 0.3)",
                }}
                hasErrored={invalidOtp}
                errorStyle={{ border: "1px solid red !important" }}
                isInputNum={true}
                isInputSecure={false}
                shouldAutoFocus
                placeholder={"*".repeat(otpLength)}
                separator={<span> - </span>}
              />
              <IconButton sx={{ ml: 2 }} onClick={handleOtpClear}>
                <Clear />
              </IconButton>
            </div>
            <Stack direction="row" spacing={2} sx={{ my: 3 }}>
              <LoadingButton
                endIcon={<Send />}
                variant="contained"
                onClick={handleOtpSubmit}
                loading={otpSubmitting}
                disabled={otp.length < otpLength}
              >
                Submit
              </LoadingButton>
              <Button
                sx={{ ml: 2 }}
                disabled={resendOtpClicked}
                onClick={handleResendOtp}
              >
                Re-send OTP
              </Button>
            </Stack>
          </Box>
        </Box>
      )}
    </Container>
  );
}
