import { styled } from "@mui/material";

export const ImageColorOnHover = styled("img", {
  shouldForwardProp: (prop) => prop !== "mode",
})(({ theme, mode = "normal" }) => {
  return {
    transition: "filter .5s ease-in-out",
    filter: `grayscale(100%) invert(${mode === "normal" ? 1 : 0})`,
    "&:hover": {
      filter: "grayscale(0%)",
    },
  };
});
