// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useState, useEffect, useRef } from "react";
import {
  useAudioVideo,
  useLocalVideo,
  useApplyVideoObjectFit,
} from "amazon-chime-sdk-component-library-react";
import { CustomVideoTile } from "blocks/modules/TrrystVideocall/foundations/components";
import { checkIfNull } from "@app21/core";
import { useChimeState } from "providers/TrrystVideocallProvider";

const CustomLocalTile = ({ pipRef, tile }) => {
  const { tileId, isVideoEnabled, setIsVideoEnabled, toggleVideo } =
    useLocalVideo();
  const { onStartVideoEnabled } = useChimeState();
  const audioVideo = useAudioVideo();
  const localVideoEl = useRef();

  useApplyVideoObjectFit(checkIfNull(pipRef?.current) ? localVideoEl : pipRef);

  useEffect(() => {
    if (onStartVideoEnabled) {
      if (!isVideoEnabled) toggleVideo();
    }
  }, []);

  useEffect(() => {
    if (!audioVideo || !tileId || !isVideoEnabled) {
      return;
    }
    audioVideo.bindVideoElement(
      tileId,
      checkIfNull(pipRef?.current) ? localVideoEl.current : pipRef.current
    );

    return () => {
      const localTile = audioVideo.getVideoTile(tileId);
      if (localTile) {
        audioVideo.unbindVideoElement(tileId);
      }
    };
  }, [audioVideo, tileId, isVideoEnabled, pipRef]);

  const videoProps = {
    ref: checkIfNull(pipRef) ? localVideoEl : pipRef,
    pipRef,
    active: tile?.videoEnabled,
    tile,
    context: "local",
  };

  return <CustomVideoTile {...videoProps} />;
};

export default CustomLocalTile;
