// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  Button,
  IconButton,
  Paper,
  Collapse,
  Icon,
  Typography,
  Popover,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Menu,
  Link,
  Tooltip,
  SvgIcon,
} from "@mui/material";
import { Calendar } from "react-feather";
import AddToCalendarHOC from "react-add-to-calendar-hoc";
import { checkIfNull } from "@app21/core";
import { format, differenceInHours } from "date-fns";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarPlus } from "@fortawesome/free-regular-svg-icons";
import { faGoogle, faMicrosoft } from "@fortawesome/free-brands-svg-icons";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";

export const constructATCLinkArray = ({ meetingData }) => {
  const {
    start,
    end,
    duration,
    title,
    location,
    description,
    id,
    createdBy,
    videocall,
    members,
    visitors,
    attendees,
  } = meetingData;
  const meetingLinkUrl = `${process.env.ROOT_URL}/meeting?meetingId=${id}`;

  const constructMeetingInviteeArray = () => {
    let inviteeArray = [];
    members?.forEach((member) =>
      inviteeArray.push({
        displayName: member.fullName,
        email: member.emailId,
        responseStatus:
          member.rsvpStatus === "Invited"
            ? "needsAction"
            : member?.rsvpStatus?.toLowerCase(),
      })
    );
    visitors?.forEach((member) =>
      inviteeArray.push({
        displayName: member.fullName,
        email: member.emailId,
        responseStatus:
          member.rsvpStatus === "Invited"
            ? "needsAction"
            : member?.rsvpStatus?.toLowerCase(),
      })
    );

    return inviteeArray;
  };

  let googleLink = `https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${start}/${end}&location=${
    checkIfNull(location) ? meetingLinkUrl : location
  }&text=${title}&details=${description ?? ""}&attendees=${encodeURIComponent(
    JSON.stringify(constructMeetingInviteeArray())
  )}`;

  let icalAttendeeList = "";
  members?.forEach(
    (attendee) =>
      (icalAttendeeList += `\nATTENDEE;CUTYPE=INDIVIDUAL;ROLE=REQ-PARTICIPANT;PARTSTAT=NEEDS-ACTION;RSVP=TRUE:mailto:${attendee.emailId}`)
  );
  visitors?.forEach(
    (attendee) =>
      (icalAttendeeList += `\nATTENDEE;CUTYPE=INDIVIDUAL;ROLE=REQ-PARTICIPANT;PARTSTAT=NEEDS-ACTION;RSVP=TRUE:mailto:${attendee.emailId}`)
  );

  let iCalLink = `BEGIN:VCALENDAR\nVERSION:2.0\nPRODID:-//Trryst Plc//NONSGML Executive Suites//EN\nBEGIN:VEVENT\nUID:${id}\nORGANIZER;CN=${
    createdBy?.fullName ?? "Trryst Organizer"
  }:mailto:${
    createdBy?.emailId ?? "no-reply@trryst.com"
  }${icalAttendeeList}\nRSVP:TRUE\nURL:${
    videocall ? `${meetingLinkUrl}` : `${meetingLinkUrl}`
  }\nDTSTART:${start}\nDTEND:${end}\nSUMMARY:${title}\nDESCRIPTION:${
    description ?? ""
  }\nLOCATION:${
    checkIfNull(location) ? meetingLinkUrl : location
  }\nEND:VEVENT\nEND:VCALENDAR`;

  let attendeeList = "";
  attendees?.forEach(
    (attendee, index) =>
      (attendeeList += (index !== 0 ? "," : "") + attendee.emailId)
  );
  let microsoftLink = `https://outlook.live.com/owa/?path=/calendar/action/compose&rru=addevent&startdt=${start}&enddt=${end}&subject=${title}&body=${
    description ?? ""
  }&location=${
    checkIfNull(location) ? meetingLinkUrl : location
  }&to=${attendeeList}`;

  return {
    google: encodeURI(googleLink),
    iCal: iCalLink,
    microsoft: encodeURI(microsoftLink),
  };
};

export default function AddToCalendarButton({
  appointmentData,
  iconOnly = false,
}) {
  const atcButtonRef = React.useRef();
  const selectPopupState = usePopupState({
    variant: "popover",
    popupId: "selectMenu",
  });

  const handleCalendarButtonClick = (e) => {
    let filename = "meeting-event";
    e.preventDefault();
    const url = e.currentTarget.getAttribute("href");

    const blob = new Blob([url], { type: "text/calendar;charset=utf-8" });

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", `${filename}.ics`);
    document.body.appendChild(link);
    link.click();
    selectPopupState?.close();
  };

  const modAppointmentData = appointmentData;

  modAppointmentData.start = moment(appointmentData?.start).format(
    "YYYYMMDDTHHmmss"
  );
  modAppointmentData.end = moment(appointmentData?.end).format(
    "YYYYMMDDTHHmmss"
  );

  modAppointmentData.duration = differenceInHours(
    new Date(appointmentData?.end),
    new Date(appointmentData?.start)
  );

  const ModalWrapper = ({ children, isOpen, onRequestClose, ...rest }) => {
    return (
      <Popover
        open={isOpen}
        onClose={onRequestClose}
        anchorEl={atcButtonRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Paper
          elevation={2}
          sx={{
            padding: (theme) => theme.spacing(2),
            minWidth: 100,
            border: "2px solid red",
          }}
        >
          {children}
        </Paper>
      </Popover>
    );
  };
  const CustomDropdown = ({ children, isOpen, onRequestClose, ...rest }) => {
    return (
      <Collapse in={isOpen} unmountOnExit>
        <Paper elevation={2} {...rest}>
          {children}
        </Paper>
      </Collapse>
    );
  };
  const AddToCalendarDropdown = AddToCalendarHOC(Button, ModalWrapper);

  let meetingData = modAppointmentData;
  const getATCMenuOptions = () => {
    let calendarOptions = constructATCLinkArray({ meetingData });
    let atcMenuOptions = [];

    atcMenuOptions.push(
      <MenuItem
        key={"google"}
        component={"button"}
        href={calendarOptions.google}
        target="_blank"
        rel="noopener noreferrer"
      >
        <ListItemIcon>
          <Icon fontSize="inherit" color="primary">
            <FontAwesomeIcon icon={faGoogle} />
          </Icon>
        </ListItemIcon>
        <ListItemText primary="Add to Google Calendar" />
      </MenuItem>
    );
    atcMenuOptions.push(
      <MenuItem
        key={"microsoft"}
        component={"button"}
        href={calendarOptions.microsoft}
        target="_blank"
        rel="noopener noreferrer"
      >
        <ListItemIcon>
          <Icon fontSize="inherit" color="primary">
            <FontAwesomeIcon icon={faMicrosoft} />
          </Icon>
        </ListItemIcon>
        <ListItemText primary="Add to Microsoft Calendar" />
      </MenuItem>
    );
    atcMenuOptions.push(
      <MenuItem
        key={"ical"}
        component={"button"}
        href={calendarOptions.iCal}
        onClick={handleCalendarButtonClick}
      >
        <ListItemIcon>
          <Icon fontSize="inherit" color="primary">
            <FontAwesomeIcon icon={faCalendarPlus} />
          </Icon>
        </ListItemIcon>
        <ListItemText primary="Add as Calendar Event" />
      </MenuItem>
    );
    return atcMenuOptions;
  };

  return (
    <div ref={atcButtonRef}>
      <Tooltip title="Add to your Calendar" arrow>
        {iconOnly ? (
          <IconButton
            onClick={(e) => {
              bindTrigger(selectPopupState).onClick(e);
              e.nativeEvent.stopImmediatePropagation();
              e.stopPropagation();
              e.preventDefault();
            }}
            sx={{ zIndex: 10 }}
          >
            <FontAwesomeIcon icon={faCalendarPlus} size="xs" />
          </IconButton>
        ) : (
          <Button
            size="small"
            onClick={(e) => {
              e.nativeEvent.stopImmediatePropagation();
              e.stopPropagation();
              e.preventDefault();

              // perform additional action...and then:
              bindTrigger(selectPopupState).onClick(e);
            }}
            endIcon={
              <Icon fontSize="inherit" color="primary">
                <FontAwesomeIcon icon={faCalendarPlus} />
              </Icon>
            }
          >
            <Typography variant="caption">Add to</Typography>
          </Button>
        )}
      </Tooltip>
      <Menu
        {...bindMenu(selectPopupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {getATCMenuOptions()}
      </Menu>
      {/*<AddToCalendarDropdown
        className={classes.addCalRoot}
        buttonProps={{ size: 'small', variant: 'outlined', color: 'primary', className: classes.addCalButton }}
        linkProps={{
          className: classes.addCalLinks,
        }}
        dropdownProps={{ className: classes.addCalDropdown }}
        buttonText={
          <Typography variant="caption">
            <Icon fontSize="inherit" color="primary" className={classes.iconProps}>
              <FontAwesomeIcon icon={faCalendarPlus} />
            </Icon>
            Add to Calendar
          </Typography>
        }
        event={appointmentData}
      />*/}
    </div>
  );
}
