import React from "react";
import {
  Tooltip,
  IconButton,
  Typography,
  Card,
  Grid,
  Box,
} from "@mui/material";
import { UserAvatar, Label } from "blocks/atoms";
import AggregatedTranscriptCard from "../AggregatedTranscriptCard";
import PerfectScrollbar from "react-perfect-scrollbar";

// import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
//

export default function ShowAggregatedTranscript({
  filteredProcessedTranscript = [],
  playedSeconds,
  videoOffsetSeconds,
  videoPlayerRef,
  setPlayedSeconds,
  handleAddMarker,
  handleSelectMarkerTranscript,
  handleAddMeetingMarker,
  addMarkerPopupState,
  transcription,
  searchQuery,
}) {
  const keyWords = ["Lorem", "ipsum", "dolor", "Lorem", "ipsum", "dolor"];
  const cardProps = {
    playedSeconds,
    videoOffsetSeconds,
    videoPlayerRef,
    setPlayedSeconds,
    handleAddMeetingMarker,
    handleSelectMarkerTranscript,
    handleAddMarker,
    addMarkerPopupState,
    keyWords,
    searchQuery,
    transcription,
  };

  return (
    <PerfectScrollbar
      style={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
        paddingRight: 10,
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          height: "100%",
          gap: 1,
        }}
      >
        {filteredProcessedTranscript.map((transcript, index) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
            key={index}
          >
            <AggregatedTranscriptCard transcript={transcript} {...cardProps} />
          </Box>
        ))}
      </Box>
    </PerfectScrollbar>
  );
}
