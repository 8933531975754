// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";

import Page from "blocks/views/Layouts/Page";

import MeetingNavbar from "blocks/views/Layouts/MeetingPageLayout/MeetingNavbar";
import LandingNavbar from "blocks/views/Layouts/LandingPageLayout/LandingNavbar";
import SuiteNavbar from "blocks/views/Layouts/SuitePageLayout/SuiteNavbar";
import DashboardNavbar from "blocks/views/Layouts/DashboardPageLayout/DashboardNavbar";
import GenericNavbar from "blocks/views/Layouts/GenericLayout/GenericNavbar";

import MeetingTopbar from "blocks/views/Layouts/MeetingPageLayout/MeetingTopbar";
import LandingMainTopbar from "blocks/views/Layouts/LandingPageLayout/LandingMainTopbar";
import SuiteTopbar from "blocks/views/Layouts/SuitePageLayout/SuiteTopbar";
import DashboardTopbar from "blocks/views/Layouts/DashboardPageLayout/DashboardTopbar";
import GenericTopbar from "blocks/views/Layouts/GenericLayout/GenericTopbar";

import GenericFooter from "blocks/views/Layouts/GenericLayout/GenericFooter";
import RedirectComponent from "blocks/views/GenericFunctions/RedirectComponent";
import PerfectScrollbar from "react-perfect-scrollbar";
import { LAYOUTWIDTHS } from "blocks/atoms/constants";
import { FlowBox } from "blocks/atoms/uistyles";
import { Box, Grid } from "@mui/material";
import {
  useAppContext,
  useDockets,
  useUsersInSuite,
  useHandleRouterPush,
} from "@app21/core";
import LoadingSpinner from "blocks/atoms/LoadingSpinner";
import { styled } from "@mui/material/styles";
import { useIsMobileBreakpoint } from "hooks";
import { useLayoutContext } from "providers/LayoutProvider";
import toast from "react-hot-toast";
import { UserInfoModal } from "blocks/atoms";

const ContentContainerDiv = styled("div")(() => ({
  display: "flex",
  overflowX: "hidden",
  height: "100vh",
  maxHeight: "100vh",
  width: "100%",
  maxWidth: "100vw",
}));

const ContentWrapperBox = styled(Box)(() => ({
  display: "flex",
  overflowX: "hidden",
  height: "100vh",
  //maxHeight: "100vh",
  flexGrow: 1,
  width: "100%",
  flexDirection: "column",
}));

const TopbarStyleBox = styled(Box)(() => ({
  height: LAYOUTWIDTHS.TopbarHeight,
  maxHeight: LAYOUTWIDTHS.TopbarHeight,
  overflow: "hidden",
  flexGrow: 0,
  flexShrink: 0,
}));

const ContentStyleDiv = styled("div", {
  shouldForwardProp: (prop) => prop !== "chimeViewMode",
})(({ chimeViewMode }) => ({
  width: "100%",
  flexGrow: 1,
  display: chimeViewMode === "fullscreen" ? "none" : "flex",
  maxWidth: "100%",
  flexDirection: "column",
  overflow: "auto",
  position: "relative",
}));

const NavbarWrapperBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "menuViewMode",
})(({ theme, menuViewMode }) => {
  return {
    display: menuViewMode === "menuOnly" ? "none" : "flex",
    width:
      menuViewMode === "compact"
        ? LAYOUTWIDTHS.NavbarCompactWidth
        : menuViewMode === "full"
        ? LAYOUTWIDTHS.NavbarWidth
        : 0,
    overflow: "hidden",
    flexShrink: 0,
    [theme.breakpoints.down("xs")]: {
      display: "none",
      width: "0px !important",
      maxWidth: "0px !important",
      minWidth: "0px !important",
    },
  };
});

const CombinedAuthLayout = ({
  children,
  quickActions = false,
  showPathCrumbs = true,
  quickActionsList = [],
  viewContext = "home",
  pageTitle = "Trryst",
  ...rest
}) => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const {
    chimeViewMode,
    selectedSuiteId,
    selectedDocketId,
    checkAccessRules,
    userInfo,
    meetingId,
    isBasicDataReady,
  } = useAppContext();
  const { menuViewMode, displayUserInfo, setDisplayUserInfo } =
    useLayoutContext();

  const {
    data: usersInSuite,
    status: usersInSuiteStatus,
    apiError: usersInSuiteApiError,
  } = useUsersInSuite(selectedSuiteId);
  const {
    data: selectedDocket,
    status: selectedDocketStatus,
    apiError: selectedDocketApiError,
  } = useDockets(selectedDocketId);

  const dataFetchError = usersInSuiteApiError || selectedDocketApiError;
  const dataFetchLoading =
    selectedDocketStatus === "loading" || usersInSuiteStatus === "loading";

  React.useEffect(() => {
    if (dataFetchError && !dataFetchLoading) {
      toast.error(dataFetchError.message);
    }
  }, [dataFetchError]);

  const { loadRoute } = useHandleRouterPush();
  const isMobile = useIsMobileBreakpoint();

  let canViewSuite = false;
  if (selectedDocketId) {
    const canViewSuiteAsSuiteMember =
      selectedSuiteId &&
      checkAccessRules({
        entity: "SUITE",
        action: "VIEW-SUITE",
        featureName: "GENERAL",
        isInvitee:
          usersInSuite?.find((user) => user._id === userInfo?._id) ?? false,
      })?.accessFlag;
    let isDocketInvitee = false;
    selectedDocket?.visitors.map((v) => {
      if (v.emailId === userInfo.emailId) isDocketInvitee = true;
    });
    canViewSuite = canViewSuiteAsSuiteMember || isDocketInvitee;
  } else {
    canViewSuite =
      selectedSuiteId &&
      checkAccessRules({
        entity: "SUITE",
        action: "VIEW-SUITE",
        featureName: "GENERAL",
        isInvitee:
          usersInSuite?.find((user) => user._id === userInfo?._id) ?? false,
      })?.accessFlag;
  }

  const getLayout = (viewContext) => {
    switch (viewContext) {
      case "home":
        return [false, LandingNavbar, LandingMainTopbar];
      case "main":
        return [true, DashboardNavbar, DashboardTopbar];
      case "suite":
        return [true, SuiteNavbar, SuiteTopbar];
      case "meeting":
        return [false, MeetingNavbar, MeetingTopbar, GenericFooter];
      case "generic":
        return [false, GenericNavbar, GenericTopbar, GenericFooter];

      default:
        return [false, LandingNavbar, LandingMainTopbar];
    }
  };

  const LayoutNavbar = getLayout(viewContext)[1] || Fragment;
  const LayoutTopbar = getLayout(viewContext)[2] || Fragment;
  const LayoutFooter = getLayout(viewContext)[3] || Fragment;

  const getContentFrame = (children) => {
    return (
      <FlowBox
        sx={{
          pt: 1,
          // pl: 1,
          p: isMobile ? 1 : 2,
          minHeight: `calc(100vh - ${LAYOUTWIDTHS.TopbarHeight})`,
        }}
      >
        {viewContext === "suite" ? (
          usersInSuiteStatus !== "success" ? (
            <LoadingSpinner type="exp" flowScreenFlag />
          ) : canViewSuite ? (
            children
          ) : (
            <RedirectComponent
              messageTitle="You do not have access to this Suite"
              buttonMessage="Goto Main Dashboard"
              handleClick={() => loadRoute("GOTO-MAINDASHBOARD", {})}
            />
          )
        ) : (
          children
        )}
      </FlowBox>
    );
  };

  return (
    <Page title={pageTitle} {...rest}>
      <ContentContainerDiv>
        <NavbarWrapperBox
          menuViewMode={menuViewMode}
          sx={{ displayPrint: "none !important" }}
        >
          {isBasicDataReady ? (
            <LayoutNavbar
              onMobileClose={() => setMobileNavOpen(false)}
              openMobile={isMobileNavOpen}
              chimeViewMode={chimeViewMode}
              activeConference={Boolean(meetingId)}
            />
          ) : (
            <></>
          )}
        </NavbarWrapperBox>
        <ContentWrapperBox>
          <TopbarStyleBox sx={{ displayPrint: "none !important" }}>
            {isBasicDataReady ? (
              <LayoutTopbar
                onMobileNavOpen={() => setMobileNavOpen(true)}
                chimeViewMode={chimeViewMode}
                activeConference={Boolean(meetingId)}
                showPathCrumbs={showPathCrumbs}
                viewContext={viewContext}
              />
            ) : (
              <></>
            )}
          </TopbarStyleBox>

          <ContentStyleDiv chimeViewMode={chimeViewMode}>
            <Grid
              container
              spacing={0}
              alignItems="stretch"
              sx={{ height: "100%", pt: 2, pb: 1, px: 1 }}
              columns={16}
            >
              <Grid item xs={0} md={1}></Grid>
              <Grid
                item
                xs={16}
                md={15}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  flexGrow: 1,
                }}
              >
                <PerfectScrollbar
                  options={{ suppressScrollX: true }}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    overflowY: "auto",
                    flexGrow: 1,
                  }}
                >
                  {getContentFrame(children)}
                  <LayoutFooter />
                </PerfectScrollbar>
              </Grid>
            </Grid>
            <UserInfoModal
              displayUserInfo={displayUserInfo}
              handleClose={() => setDisplayUserInfo(null)}
            />
            {/* <QuickActionsButton
              quickActions={quickActions}
              quickActionsList={quickActionsList}
            /> */}
          </ContentStyleDiv>
        </ContentWrapperBox>
      </ContentContainerDiv>
    </Page>
  );
};

CombinedAuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
  viewContext: PropTypes.string,
  pageTitle: PropTypes.string,
  quickActions: PropTypes.bool,
  showPathCrumbs: PropTypes.bool,
  quickActionsList: PropTypes.array,
};

export default CombinedAuthLayout;
