// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { SvgIcon, IconButton, Tooltip, Badge } from "@mui/material";
import { IconButtonAnimate } from "blocks/atoms";
import { ChatIcon } from "blocks/atoms/trrysticons";
import {
  useHandleRouterPush,
  useAppContext,
  app21APIInstance,
  useUserInfo,
} from "@app21/core";
import { useCheckActiveLink } from "hooks";

const DiscussButton = ({
  iconSize = "medium",
  orgId,
  suiteId,
  viewContext = "main",
}) => {
  const { loadRoute } = useHandleRouterPush();
  const { checkAccessRules, selectedSuiteId } = useAppContext();
  const { data: userInfo } = useUserInfo();
  const isChatLinkActive = useCheckActiveLink("GROUP-CHAT");

  const { accessFlag: canAccessChat } = checkAccessRules({
    entity: "MENU",
    action: "SHOW-DISCUSS-MENU",
    featureName: "CHAT",
    isInvitee: true,
  });

  const handleOpen = async () => {
    const response = await app21APIInstance.post(`/channels/fetch`, {
      objectType: "suite",
      objectId: selectedSuiteId,
      calledBy: userInfo?._id,
      additionalData: {
        suiteId: selectedSuiteId,
      },
    });
    response &&
      loadRoute("GROUP-CHAT", {
        chatId: response?.data?.data?.data[0].id,
        chatType: "suite",
      });
  };
  if (canAccessChat) {
    return (
      <Tooltip title="Discuss with other members in your Suite" arrow>
        <IconButtonAnimate
          data-testid="discuss-button"
          size={iconSize}
          onClick={handleOpen}
          color={isChatLinkActive ? "secondary" : "primary"}
        >
          <Badge badgeContent={0} invisible={true} color="secondary">
            <ChatIcon
              fontSize="inherit"
              sx={{
                color: isChatLinkActive ? "secondary" : "text.secondary",
              }}
            />
          </Badge>
        </IconButtonAnimate>
      </Tooltip>
    );
  } else return null;
};

export default DiscussButton;
