import React from 'react'
import { SvgIcon } from '@mui/material';

export default function CamOffIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M8.33331 50.6245V67.5412C8.33331 72.7912 9.91665 78.0828 14.0416 81.4578L16.6666 83.3328"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M69.2916 31.5837C69.2916 31.5837 69.4166 27.6253 69.2916 26.3337C68.5833 17.8337 63.0417 14.917 52.1667 14.917H25.875C12.7083 14.917 8.33331 19.292 8.33331 32.4587"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M69.7501 45.626V67.5426C69.7501 80.7093 65.3751 85.0843 52.2084 85.0843H30.2501"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M91.6666 28.0845V65.8761C91.6666 72.8345 86.9999 75.2511 81.3333 71.2511L69.7499 63.1261"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M91.7501 9.125L8.41675 92.4583"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
