import React from 'react'
export default function WhiteboardIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M12.5 41.6647C12.5 22.6647 19.4583 17.498 33.3333 16.748"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M58.3333 91.6668H37.5C16.6667 91.6668 12.5 83.3335 12.5 66.6668V58.3335"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M66.6666 16.748C80.5416 17.498 87.5 22.623 87.5 41.6647V62.498"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M45.8294 8.3335H41.8294C37.6628 8.3335 33.4961 8.3335 33.4961 16.6668C33.4961 25.0002 37.6628 25.0002 41.8294 25.0002H58.4961C66.8294 25.0002 66.8294 20.8335 66.8294 16.6668C66.8294 8.3335 62.6628 8.3335 58.4961 8.3335"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M62.5 79.1665V66.6665H75"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M87.4961 91.6629L62.6627 66.8296"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
