import React, { Fragment } from "react";
import { Box, Badge, Typography, Tooltip } from "@mui/material";
import { UserAvatar } from "blocks/atoms";
import { AvatarGroupWithMore } from "blocks/atoms/uicomponents";

export default function AvatarGroupWithStatus({
  userStatusList = [],
  AvatarGroupProps = {},
}) {
  const ConditionalTooltip = ({ title, key, children }) => {
    return title ? (
      <Tooltip key={key} title={title}>
        {children}
      </Tooltip>
    ) : (
      <Fragment key={key}>{children}</Fragment>
    );
  };

  const getBadgeInfo = (status) => {
    switch (status) {
      case "yes":
        return { color: "success", text: "Yes" };
      case "no":
        return { color: "error", text: "No" };
      case "partial":
        return { color: "warning", text: "Tentative" };
      case "maybe":
        return { color: "info", text: "Maybe" };
      default:
        return { color: "info", text: "No Response" };
    }
  };

  return (
    <AvatarGroupWithMore max={9} size={32} {...AvatarGroupProps}>
      {userStatusList.map((user, index) => {
        const { tooltipText = null, responseStatus = "", _id = "" } = user;

        return (
          <ConditionalTooltip title={tooltipText} key={index}>
            <Badge
              overlap="circular"
              variant="dot"
              color={getBadgeInfo(responseStatus).color}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <UserAvatar userId={_id} size={25} tooltipFlag={false} />
            </Badge>
          </ConditionalTooltip>
        );
      })}
    </AvatarGroupWithMore>
  );
}
