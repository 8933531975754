// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  Card,
  TextField,
  Typography,
  CardContent,
  FormControlLabel,
  Switch,
  IconButton,
  Tooltip,
  Grid,
  CardHeader,
  CardActions,
} from "@mui/material";

import { Delete } from "@mui/icons-material";
import { Controller, useFormContext } from "react-hook-form";
import NestedQuestionOptions from "blocks/modules/Surveys/features/SimpleSurveyBuilder/NestedQuestionOptions";
import FileUploaderFormComponent from "blocks/atoms/FileUploaderFormComponent";

const QuestionCard = ({
  question,
  index,
  handleDelete,
  totalQuestionsCount,
  isDocket = false,
  id,
}) => {
  const { watch, control, setValue, getValues, formState } = useFormContext();
  const { errors } = formState;
  return (
    <Card elevation={2}>
      <CardContent
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          paddingLeft: "0px",
          paddingRight: "0px",
        }}
      >
        <div>
          <Typography variant="h6" style={{ marginBottom: 10 }}>
            What is the question you want to put to the respondents?
          </Typography>
          <Controller
            control={control}
            rules={{ required: { value: true, message: "Required" } }}
            name={`questionBlock[${index}].questionItem`}
            defaultValue={question?.questionItem ?? ""}
            render={({ field }) => {
              getValues("questionBlock");
              return (
                <TextField
                  {...field}
                  fullWidth
                  placeholder={`Enter the ${
                    isDocket ? "resolution" : "survey"
                  } question you want to ask`}
                  error={!!errors?.questionBlock?.[index]?.questionItem}
                  helperText={
                    errors?.questionBlock?.[index]?.questionItem?.message
                  }
                />
              );
            }}
          />
        </div>
        <div style={{ marginTop: 10 }}>
          <Grid container style={{ marginTop: 10, display: "flex" }}>
            <Grid item xs={12} md={7}>
              <Typography variant="h6" style={{ marginBottom: 10 }}>
                What are the response choices for the above resolution?
              </Typography>
              <NestedQuestionOptions nestIndex={index} control={control} />
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Card>
                <CardHeader title="Include any supporting reading material" />
                <CardActions>
                  <Controller
                    control={control}
                    name={`questionBlock[${index}].files`}
                    defaultValue={question?.files || []}
                    render={({ field }) => (
                      <FileUploaderFormComponent
                        value={field.value}
                        onChange={(value) => {
                          setValue(`questionBlock[${index}].files`, value);
                          field.onChange(value);
                        }}
                        handleFileDelete={() =>
                          setValue(`agenda[${index}].files`, [])
                        }
                      />
                    )}
                  />
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            marginTop: 10,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Controller
            name={`questionBlock[${index}].commentsBoxQuestion`}
            control={control}
            defaultValue={question?.commentsBoxQuestion ?? false}
            render={({ field }) => {
              return (
                <FormControlLabel
                  control={
                    <Switch
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                      color="primary"
                    />
                  }
                  label={"Include a comments box for other Inputs?"}
                  variant="standard"
                />
              );
            }}
          />
          {totalQuestionsCount > 1 ? (
            <Tooltip title="Delete this Question?" arrow>
              <IconButton size="small" onClick={() => handleDelete(index)}>
                <Delete fontSize="inherit" />
              </IconButton>
            </Tooltip>
          ) : null}
        </div>
      </CardContent>
    </Card>
  );
};

export default QuestionCard;
