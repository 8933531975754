// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Box, Paper } from "@mui/material";
import { FlowBox } from "blocks/atoms/uistyles";
import { useIsMobileBreakpoint } from "hooks";
import { useAtom } from "jotai";
import { showDocketsSidebarFlagAtom } from "./dockets-atoms";
import { DocketsSidebar, DocketsCenterPanel } from "./features";
import { MobileSidePanelDrawer } from "blocks/atoms/uicomponents";
import DocketsProvider from "providers/DocketsProvider";
import { TabbedDocketDashboardView } from "blocks/modules/Dockets";

export default function BoardMeetingDocketView({
  viewContext = "list",
  actionContext = "summary",
}) {
  const isMobile = useIsMobileBreakpoint(480, true);

  return (
    <DocketsProvider
      isMobile={isMobile}
      viewContext={viewContext}
      actionContext={actionContext}
    >
      <FlowBox
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          height: "100%",
        }}
      >
        <TabbedDocketDashboardView
          viewContext={viewContext}
          actionContext={actionContext}
        />
      </FlowBox>
    </DocketsProvider>
  );
}
