import React from "react";

import {
  DeviceLabelTriggerStatus,
  useDeviceLabelTriggerStatus,
  useLogger,
} from "amazon-chime-sdk-component-library-react";

import {
  Card,
  CardHeader,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

// Show permission prompt when the user is granting the browser permissions
// Show nothing if permission is already granted or component loads on initial render
const DevicePermissionPrompt = () => {
  const logger = useLogger();
  const status = useDeviceLabelTriggerStatus();

  return status === DeviceLabelTriggerStatus.IN_PROGRESS ? (
    <Dialog open={true} onClose={() => logger.info("Permission prompt closed")}>
      <DialogTitle>Device Label Permissions check</DialogTitle>
      <DialogContent>
        <Card>
          <CardHeader
            title="Unable to get device labels"
            subheader={
              <>
                <p>
                  In order to select media devices, we need to do a quick
                  permissions check of your mic and camera.
                </p>
                <p>
                  When the pop-up appears, choose <strong>Allow</strong>.
                </p>
              </>
            }
          />
        </Card>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  ) : (
    <></>
  );
};

export default DevicePermissionPrompt;
