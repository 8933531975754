import React, { useEffect, useCallback, ReactNode } from "react";
import { useAtom } from "jotai";
import { usePubNub } from "pubnub-react";
import cloneDeep from "lodash.clonedeep";
import setDeep from "lodash.set";
import { useChatContext } from "providers/TrrystChatProvider";
import {
  CurrentChannelAtom,
  ErrorFunctionAtom,
  MessagesAtom,
  RetryFunctionAtom,
  SubscribeChannelGroupsAtom,
  SubscribeChannelsAtom,
  ThemeAtom,
  TypingIndicatorAtom,
  TypingIndicatorTimeoutAtom,
  UsersMetaAtom,
  RefetchOnNetworkReconnectAtom,
} from "blocks/modules/TrrystChat/state-atoms";
import { useAppContext } from "@app21/core";
import _ from "lodash";
export default function MainChat({
  children: childrenProp,
  theme: themeProp = "light",
  currentChannel: currentChannelProp,
  channels: channelsProp = [],
  channelGroups: channelGroupsProp = [],
  enablePresence: enablePresenceProp = true,
  users: usersProp = [],
  typingIndicatorTimeout: typingIndicatorTimeoutProp = 10,
  retryOptions: retryOptionsProp = {
    maxRetries: 1,
    timeout: 0,
    exponentialFactor: 1,
  },
  onMessage: onMessageProp = () => null,
  onSignal: onSignalProp = () => null,
  onMessageAction: onMessageActionProp = () => null,
  onPresence: onPresenceProp = () => null,
  onUser: onUserProp = () => null,
  onChannel: onChannelProp = () => null,
  onMembership: onMembershipProp = () => null,
  onFile: onFileProp = () => null,
  onStatus: onStatusProp = () => null,
  onError: onErrorProp = () => null,
}) {
  const pubnubChatClient = usePubNub();
  const [, setMessages] = useAtom(MessagesAtom);
  const [, setTheme] = useAtom(ThemeAtom);
  const [, setErrorFunction] = useAtom(ErrorFunctionAtom);
  const [, setRetryFunction] = useAtom(RetryFunctionAtom);
  const [, setTypingIndicator] = useAtom(TypingIndicatorAtom);
  const [, setTypingIndicatorTimeout] = useAtom(TypingIndicatorTimeoutAtom);
  const [, setUsersMeta] = useAtom(UsersMetaAtom);
  const [currentChannel, setCurrentChannel] = useAtom(CurrentChannelAtom);
  const [channels, setChannels] = useAtom(SubscribeChannelsAtom);
  const [channelGroups] = useAtom(SubscribeChannelGroupsAtom);

  const {
    updateChatNotificationsInMeeting,
    inputsForUnreadCounts,
    unreadMessageCounts,
    setUnreadMessageCounts,
    setTotalUnreadMessageCount,
    sumValue,
  } = useChatContext();

  const { meetingId } = useAppContext();

  const [, setRefetchOnNetworkReconnect] = useAtom(
    RefetchOnNetworkReconnectAtom
  );
  /**
   * Helpers
   */

  const retryOnError = useCallback(
    async (fn = () => null) => {
      const { maxRetries, timeout, exponentialFactor } = retryOptionsProp;
      for (let i = 0; i < maxRetries; i++) {
        try {
          return await fn();
        } catch (error) {
          if (maxRetries === i + 1) throw error;
          await new Promise((resolve) =>
            setTimeout(resolve, timeout * exponentialFactor ** i)
          );
        }
      }
    },
    [retryOptionsProp]
  );

  /**
   * Event handlers
   */

  const handleMessage = useCallback(
    (message) => {
      try {
        // push to message notifications if there is a new message which
        // belongs to the current meeting which is in progress.
        // only add it if the user is not already in the chat window
        if (message.channel === `${meetingId}-CHAT` && !currentChannel)
          updateChatNotificationsInMeeting(message);

        if (message.channel !== currentChannel) {
          const updatedUnreadMessageCounts = _.cloneDeep(unreadMessageCounts);
          updatedUnreadMessageCounts[message.channel] =
            updatedUnreadMessageCounts[message.channel] + 1;
          const updatedTotalUnreadMessageCount = sumValue(
            updatedUnreadMessageCounts
          );
          setUnreadMessageCounts(updatedUnreadMessageCounts);
          setTotalUnreadMessageCount(updatedTotalUnreadMessageCount);
        }

        setMessages((messages) => {
          const messagesClone = cloneDeep(messages) || {};
          messagesClone[message.channel] = messagesClone[message.channel] || [];
          messagesClone[message.channel].push(message);
          return messagesClone;
        });

        if (onMessageProp) onMessageProp(message);
      } catch (e) {
        onErrorProp(e);
      }
    },
    [onMessageProp, onErrorProp, setMessages]
  );

  const handleSignalEvent = useCallback(
    (signal) => {
      try {
        if (["typing_on", "typing_off"].includes(signal.message.type)) {
          setTypingIndicator((indicators) => {
            const indicatorsClone = cloneDeep(indicators);
            const value =
              signal.message.type === "typing_on" ? signal.timetoken : null;
            setDeep(indicatorsClone, [signal.channel, signal.publisher], value);
            return indicatorsClone;
          });
        }

        if (onSignalProp) onSignalProp(signal);
      } catch (e) {
        onErrorProp(e);
      }
    },
    [onSignalProp, onErrorProp, setTypingIndicator]
  );

  const handlePresenceEvent = useCallback(
    (event) => {
      try {
        if (onPresenceProp) onPresenceProp(event);
      } catch (e) {
        onErrorProp(e);
      }
    },
    [onPresenceProp, onErrorProp]
  );

  const handleObjectsEvent = useCallback(
    (event) => {
      try {
        if (event.message.type === "membership" && onMembershipProp)
          onMembershipProp(event);
        if (event.message.type === "channel" && onChannelProp)
          onChannelProp(event);
        if (event.message.type === "uuid" && onUserProp) onUserProp(event);
      } catch (e) {
        onErrorProp(e);
      }
    },
    [onMembershipProp, onChannelProp, onUserProp, onErrorProp]
  );

  const handleAction = useCallback(
    (action) => {
      try {
        setMessages((messages) => {
          if (!messages || !messages[action.channel]) return;

          const { channel, event } = action;
          const { type, value, actionTimetoken, messageTimetoken, uuid } =
            action.data;
          const messagesClone = cloneDeep(messages);
          const message = messagesClone[channel].find(
            (m) => m.timetoken === messageTimetoken
          );
          const actions = message?.actions?.[type]?.[value] || [];

          if (message && event === "added") {
            const newActions = [...actions, { uuid, actionTimetoken }];
            setDeep(message, ["actions", type, value], newActions);
          }

          if (message && event === "removed") {
            const newActions = actions.filter(
              (a) => a.actionTimetoken !== actionTimetoken
            );
            newActions.length
              ? setDeep(message, ["actions", type, value], newActions)
              : delete message.actions[type][value];
          }

          return messagesClone;
        });

        if (onMessageActionProp) onMessageActionProp(action);
      } catch (e) {
        onErrorProp(e);
      }
    },
    [onMessageActionProp, onErrorProp, setMessages]
  );

  const handleFileEvent = useCallback(
    (event) => {
      try {
        setMessages((messages) => {
          const { file, message, ...payload } = event;
          const newMessage = {
            ...payload,
            message: { file, message },
            messageType: 4,
          };
          const messagesClone = cloneDeep(messages) || {};
          messagesClone[newMessage.channel] =
            messagesClone[newMessage.channel] || [];
          messagesClone[newMessage.channel].push(newMessage);
          return messagesClone;
        });

        if (onFileProp) onFileProp(event);
      } catch (e) {
        onErrorProp(e);
      }
    },
    [onFileProp, onErrorProp, setMessages]
  );

  const handleStatusEvent = useCallback(
    (event) => {
      try {
        // console.log("status event: ", event);
        if (
          event.category === "PNReconnectedCategory" ||
          event.category === "PNNetworkUpCategory"
        ) {
          setRefetchOnNetworkReconnect(true);
        }
        if (onStatusProp) onStatusProp(event);
      } catch (e) {
        onErrorProp(e);
      }
    },
    [onStatusProp, onErrorProp]
  );

  /**
   * Lifecycle: load updateable props
   */

  useEffect(() => {
    setUsersMeta(usersProp);
  }, [usersProp]);

  useEffect(() => {
    setTheme(themeProp);
  }, [themeProp]);

  useEffect(() => {
    setCurrentChannel(currentChannelProp);
  }, [currentChannelProp]);

  useEffect(() => {
    setChannels(channelsProp);
  }, [channelsProp]);

  // useEffect(() => {
  //   setChannelGroups(channelGroupsProp);
  // }, [channelGroupsProp]);

  useEffect(() => {
    setTypingIndicatorTimeout(typingIndicatorTimeoutProp);
  }, [typingIndicatorTimeoutProp]);

  useEffect(() => {
    setErrorFunction({ function: (error) => onErrorProp(error) });
  }, [onErrorProp]);

  useEffect(() => {
    setRetryFunction({ function: (fn) => retryOnError(fn) });
  }, []);

  /**
   * Lifecycle: use currentChannel for subscriptions when neither channels or channelGroups is passed
   */

  useEffect(() => {
    if (!currentChannel) return;
    if (
      !channels.includes(currentChannel) &&
      !channelsProp.length &&
      !channelGroupsProp.length
    ) {
      setChannels([...channels, currentChannel]);
    }
  }, [
    currentChannel,
    channels,
    channelsProp.length,
    channelGroupsProp.length,
    setChannels,
  ]);

  /**
   * Lifecycle: setup correct subscriptions based on channels and channelGroups
   */

  useEffect(() => {
    if (!channels.length && !channelGroups.length) return;

    const currentSubscriptions = pubnubChatClient.getSubscribedChannels();
    const currentGroups = pubnubChatClient.getSubscribedChannelGroups();

    try {
      const newChannels = channels.filter(
        (c) => !currentSubscriptions.includes(c)
      );
      const oldChannels = currentSubscriptions.filter(
        (c) => !channels.includes(c)
      );

      const newGroups = channelGroups.filter((c) => !currentGroups.includes(c));
      const oldGroups = currentGroups.filter((c) => !channelGroups.includes(c));

      if (newChannels.length || newGroups.length) {
        pubnubChatClient.subscribe({
          channels: newChannels,
          channelGroups: newGroups,
          withPresence: enablePresenceProp,
        });
      }

      if (oldChannels.length || oldGroups.length) {
        pubnubChatClient.unsubscribe({
          channels: oldChannels,
          channelGroups: oldGroups,
        });
      }
    } catch (e) {
      onErrorProp(e);
    }
  }, [channels, channelGroups, enablePresenceProp, onErrorProp]);

  /**
   * Lifecycle: setup event listeners
   */

  useEffect(() => {
    if (!pubnubChatClient) return;

    const listener = {
      message: (m) => handleMessage(m),
      messageAction: (m) => handleAction(m),
      presence: (e) => handlePresenceEvent(e),
      objects: (e) => handleObjectsEvent(e),
      signal: (e) => handleSignalEvent(e),
      file: (e) => handleFileEvent(e),
      status: (e) => handleStatusEvent(e),
    };

    try {
      pubnubChatClient.addListener(listener);
    } catch (e) {
      onErrorProp(e);
    }

    return () => {
      if (pubnubChatClient && pubnubChatClient.removeListener) {
        pubnubChatClient.removeListener(listener);
      }
    };
  }, [
    handleMessage,
    handleAction,
    handlePresenceEvent,
    handleObjectsEvent,
    handleSignalEvent,
    handleFileEvent,
    handleStatusEvent,
    onErrorProp,
  ]);

  return <>{childrenProp}</>;
}
