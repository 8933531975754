// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Button, TextField, Box, Grid, Typography } from "@mui/material";
import { FormProvider, useForm, Controller } from "react-hook-form";
import LoadingSpinner from "blocks/atoms/LoadingSpinner";
import BackTitleWrapperPaper from "blocks/atoms/BackTitleWrapperPaper";
import { executeApi, useAppContext } from "@app21/core";
import { Check, Send } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

export default function OrgCreationRequestForm() {
  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      organizationName: "",
      contactNumber: "",
      companyEmail: "",
      companyInfo: "",
      comments: "",
    },
  });
  const [formSubmitted, setFormSubmitted] = React.useState(false);
  const [submitError, setSubmitError] = React.useState(true);
  const { handleSubmit, control, formState } = methods;
  const { userInfo } = useAppContext();
  const { isSubmitting, isValid, errors } = formState;

  const handleSubmitButtonClick = async (data) => {
    await executeApi("TRIGGER-EMAIL", {
      emailType: "ADD-ORGANIZATION-REQUEST",
      emailData: {
        organizationName: data.organizationName,
        contactNumber: data.contactNumber,
        companyEmail: data.companyEmail,
        companyInfo: data.companyInfo,
        comments: data.comments,
      },
      calledBy: userInfo._id,
    });
    setFormSubmitted(true);
  };

  return (
    <BackTitleWrapperPaper title={"Request Organization setup"}>
      <Typography variant="body1" sx={{ m: 2 }}>
        Please give us the details below for creating an organization. We will
        validate details with you and get the organization setup
      </Typography>
      <Box>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(handleSubmitButtonClick)}>
            <Grid container spacing={2} direction="column">
              <Grid item>
                <Controller
                  control={control}
                  name={"organizationName"}
                  rules={{
                    required: {
                      value: true,
                      message:
                        "A name for the Organization you want to create is required",
                    },
                    maxLength: {
                      value: 200,
                      message: "Sorry. Too long. Limit to 200 characters",
                    },
                    minLength: { value: 3, message: "Sorry. Too short." },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      disabled={formSubmitted}
                      placeholder="Enter the name of the Organization you wish to create"
                      label="Name of Organization"
                      error={!!errors?.organizationName}
                      helperText={errors && errors?.organizationName?.message}
                      variant={"outlined"}
                      fullWidth={true}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Controller
                  control={control}
                  name={"companyEmail"}
                  rules={{
                    required: {
                      value: true,
                      message:
                        "Please let us know your company email Id. We will be contacting you over this email to verify that you are indeed a part of the organization.",
                    },
                    maxLength: { value: 100, message: "Sorry. Too long." },
                    minLength: { value: 3, message: "Sorry. Too short." },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      disabled={formSubmitted}
                      placeholder="Your organization email Id. We need it to verify that you are a part of the organization."
                      label="Your official organization email ID"
                      error={!!errors?.companyEmail}
                      helperText={errors && errors?.contactNumber?.companyEmail}
                      variant={"outlined"}
                      fullWidth={true}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Controller
                  control={control}
                  name={"contactNumber"}
                  rules={{
                    required: {
                      value: true,
                      message:
                        "Please let us know how to contact you for any clarifications. We will not use it for marketing purposes.",
                    },
                    maxLength: { value: 30, message: "Sorry. Too long." },
                    minLength: { value: 3, message: "Sorry. Too short." },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      disabled={formSubmitted}
                      placeholder="Please leave a contact number behind, in case we need to contact you for clarifications"
                      label="Your contact number"
                      error={!!errors?.contactNumber}
                      helperText={errors && errors?.contactNumber?.message}
                      variant={"outlined"}
                      fullWidth={true}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Controller
                  control={control}
                  name={"companyInfo"}
                  rules={{
                    required: {
                      value: true,
                      message:
                        "Please suggest where we could get more details about your organization so that we can validate for fitment purposes.",
                    },
                    maxLength: { value: 100, message: "Sorry. Too long." },
                    minLength: { value: 3, message: "Sorry. Too short." },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      disabled={formSubmitted}
                      placeholder="Your organization website URL"
                      label="URL / Link to Organization website"
                      error={!!errors?.companyInfo}
                      helperText={errors && errors?.companyInfo?.message}
                      variant={"outlined"}
                      fullWidth={true}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Controller
                  control={control}
                  name={"comments"}
                  rules={{
                    maxLength: { value: 500, message: "Sorry. Too long." },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      disabled={formSubmitted}
                      multiline
                      maxRows={10}
                      minRows={5}
                      placeholder="Comments, if any. It would help us if you could briefly tell us the purpose of opening a suite with Trryst. We can then accurately setup your organization features accordingly"
                      label="Comments"
                      error={!!errors?.comments}
                      helperText={errors && errors?.companyInfo?.comments}
                      variant={"outlined"}
                      fullWidth={true}
                    />
                  )}
                />
              </Grid>

              <Grid item sx={{ display: "flex", justifyContent: "center" }}>
                {!formSubmitted ? (
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    type="submit"
                    startIcon={<Send />}
                    loading={isSubmitting}
                    disabled={!isValid}
                  >
                    Submit
                  </LoadingButton>
                ) : (
                  <Button
                    startIcon={<Check color="success" />}
                    disabled
                    variant="outlined"
                    color="primary"
                  >
                    Thanks. We have received your Request. We will validate and
                    get in touch with you soon
                  </Button>
                )}
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Box>
    </BackTitleWrapperPaper>
  );
}
