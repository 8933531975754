import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
  Button,
  Typography,
  IconButton,
  Avatar,
  ListItem,
  ListItemText,
  Stack,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import {
  CloseIcon,
  BreakoutIcon,
  CallingIcon,
  CameraOnIcon,
} from "blocks/atoms/trrysticons";
import {
  app21APIInstance,
  useAppContext,
  useHandleRouterPush,
  getS3ImageWrapper,
  useUsersInSuite,
} from "@app21/core";
import { getNameInitials } from "utils/getNameInitials";
import { format } from "date-fns";
import { Label } from "blocks/atoms";
import { useOrgsAndSuitesInfoContext } from "providers/OrgsAndSuitesInfoProvider";
import { getDateTimeFormat } from "utils";

export default function UserInfoModal({
  displayUserInfo = null,
  handleClose = () => null,
  suiteId,
  ...rest
}) {
  const { selectedSuiteId } = useAppContext();
  const { loadRoute } = useHandleRouterPush();
  const { combinedMainOrgsAndSuitesArray } = useOrgsAndSuitesInfoContext();
  const chosenSuiteId = suiteId ?? selectedSuiteId;

  const usersInSuite =
    combinedMainOrgsAndSuitesArray?.find(
      (obj) => obj?.suite?.suiteId === chosenSuiteId
    )?.usersInSuite ?? [];
  const userInfo = usersInSuite?.find(
    (user) => user._id === displayUserInfo?._id
  );

  const onClickChatButton = async () => {
    const response = await app21APIInstance.post(`/channels/fetch`, {
      objectType: "docket",
      calledBy: displayUserInfo._id,
      additionalData: {
        suiteId: selectedSuiteId,
      },
    });

    if (response) {
      loadRoute("GROUP-CHAT", {
        chatId: response?.data?.data.data[0].id,
        chatType: "dockets",
      });
    }
  };
  return (
    <Dialog
      open={Boolean(displayUserInfo)}
      onClose={handleClose}
      sx={{}}
      {...rest}
    >
      <DialogTitle id="alert-dialog-title">
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ width: 350 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Avatar
            src={getS3ImageWrapper(displayUserInfo?.image, "person")}
            alt={getNameInitials(displayUserInfo?.fullName ?? "unknown")}
            sx={{ height: 100, width: 100 }}
          />
        </Box>

        <Stack direction="column" sx={{ m: 2 }} spacing={1}>
          <ListItem>
            <ListItemText
              primary={<Typography variant="caption">Name</Typography>}
              secondary={
                <Typography variant="h6" component="span">
                  {displayUserInfo?.fullName}
                </Typography>
              }
              sx={{ display: "flex", flexDirection: "column" }}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <Typography variant="caption">Role in this Suite</Typography>
              }
              secondary={
                <Typography variant="h6" component="span">
                  <Label variant="ghost" color="success" sx={{ mt: 1, p: 3 }}>
                    {userInfo?.roleInfo?.role ?? displayUserInfo?.role}
                  </Label>
                </Typography>
              }
              sx={{ display: "flex", flexDirection: "column" }}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={<Typography variant="caption">Email ID</Typography>}
              secondary={
                <Typography variant="h6" component="span">
                  {displayUserInfo?.emailId}
                </Typography>
              }
              sx={{ display: "flex", flexDirection: "column" }}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <Typography variant="caption">Suite Member Since</Typography>
              }
              secondary={
                <Typography variant="h6" component="span">
                  {format(
                    new Date(displayUserInfo?.createdAt ?? 0),
                    getDateTimeFormat({ startDate: displayUserInfo?.createdAt })
                  )}
                </Typography>
              }
              sx={{ display: "flex", flexDirection: "column" }}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={<Typography variant="caption">Status</Typography>}
              secondary={
                <FormGroup row={true} component="span" variant="standard">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={displayUserInfo?.isActiveUser ?? true}
                        onChange={() => null}
                      />
                    }
                    label="Active User"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={displayUserInfo?.isEmailVerified ?? true}
                        onChange={() => null}
                      />
                    }
                    label="E-mail Verified"
                  />
                </FormGroup>
              }
              sx={{ display: "flex", flexDirection: "column" }}
            />
          </ListItem>
        </Stack>
      </DialogContent>
      {/* <DialogActions>
        <Stack direction={"row"} spacing={2} sx={{ m: 2 }}>
          <Button
            variant="contained"
            startIcon={<CameraOnIcon />}
            sx={{ borderRadius: 3 }}
          >
            Video Call
          </Button>
          <Button
            variant="outlined"
            startIcon={<BreakoutIcon />}
            onClick={onClickChatButton}
          >
            CHAT
          </Button>
        </Stack>
      </DialogActions> */}
    </Dialog>
  );
}
