import React from "react";
import {
  Dialog,
  IconButton,
  Box,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  ListItemIcon,
  Typography,
} from "@mui/material";
import { ArrowBack, ChevronRight, Done } from "@mui/icons-material";
import { useFilesContext } from "providers/FilesProvider";
import { reservedFolderNames } from "blocks/modules/TrrystFiles/foundations";
import { checkIfNull } from "@app21/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import { selectedFilesListAtom } from "providers/FilesProvider/files-atoms";
import { useAtom } from "jotai";

export default function MoveFilesDialog({ open, onClose, onMove }) {
  const { updatedTreeKeys } = useFilesContext();
  const keysData = React.useMemo(
    () => updatedTreeKeys?.folders?.[0]?.folders?.[0] ?? {},
    [updatedTreeKeys]
  );
  const baseFolderPrefix = keysData.parentPrefix + keysData.name + "/";
  const filteredKeysData = React.useMemo(() => {
    let keysDataCopy = { ...keysData };
    let filteredFolders =
      keysData?.folders
        ?.filter(
          (key) =>
            !(
              (key.name == "Breakouts" && key.origKey === "dockets") ||
              reservedFolderNames.includes(key.name)
            )
        )
        .reverse() ?? [];

    keysDataCopy.folders = filteredFolders;
    return keysDataCopy;
  }, [keysData]);

  const [selectedFolderKey, setSelectedFolderKey] =
    React.useState(baseFolderPrefix);

  const [selectedFilesList] = useAtom(selectedFilesListAtom);
  const [currentFolderRoot, setCurrentFolderRoot] =
    React.useState(baseFolderPrefix);
  const isHomeFolder = baseFolderPrefix === currentFolderRoot;

  const handleListItemClick = (event, folder) => {
    setSelectedFolderKey(folder.parentPrefix + folder.name + "/");
  };

  const getFolderObject = (folders, folderPrefix) => {
    if (folderPrefix === baseFolderPrefix) return filteredKeysData;
    if (!Array.isArray(folders)) return null;
    for (const obj of folders) {
      if (obj.parentPrefix + obj.name + "/" == folderPrefix) {
        return obj;
      }

      if (Array.isArray(obj.folders)) {
        const found = getFolderObject(obj.folders, folderPrefix);
        if (found) {
          return found;
        }
      }
    }

    return null;
  };
  const handleMove = () => {
    if (selectedFolderKey) onMove(selectedFolderKey);
    onClose();
  };

  const handleGotoFolderClick = (folderPrefix) => {
    setSelectedFolderKey(folderPrefix);
    setCurrentFolderRoot(folderPrefix);
  };

  const ShowSubfolders = ({}) => {
    let currentFolder = getFolderObject(
      filteredKeysData?.folders ?? [],
      currentFolderRoot
    );

    let subfoldersList = currentFolder?.folders ?? [];

    return (
      <PerfectScrollbar>
        <List>
          {subfoldersList?.map((folder, index) => {
            return (
              <ListItem key={index} disablePadding disableGutters>
                <ListItemButton
                  selected={
                    selectedFolderKey == folder.parentPrefix + folder.name + "/"
                  }
                  onClick={(event) => handleListItemClick(event, folder)}
                >
                  <ListItemIcon>
                    {selectedFolderKey ==
                    folder.parentPrefix + folder.name + "/" ? (
                      <Done color="success" />
                    ) : (
                      <Box sx={{ width: 20 }}></Box>
                    )}
                  </ListItemIcon>
                  <ListItemText primary={folder.name} />
                </ListItemButton>
                {!checkIfNull(folder?.folders) && (
                  <ListItemSecondaryAction>
                    <IconButton
                      onClick={() => {
                        setSelectedFolderKey(null);
                        handleGotoFolderClick(
                          folder.parentPrefix + folder.name + "/"
                        );
                      }}
                    >
                      <ChevronRight />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            );
          })}
        </List>
      </PerfectScrollbar>
    );
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Select a Folder to move the {selectedFilesList?.length} selected files
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            gap: 3,
            mt: 3,
            mb: 1,
            p: 1,
            alignItems: "center",
            bgcolor: "gray.lighter",
          }}
        >
          <IconButton
            disabled={isHomeFolder}
            onClick={() =>
              handleGotoFolderClick(
                getFolderObject(
                  filteredKeysData?.folders ?? [],
                  currentFolderRoot
                )?.parentPrefix ?? baseFolderPrefix
              )
            }
          >
            <ArrowBack />
          </IconButton>
          <Typography variant="subtitle1">
            {isHomeFolder
              ? "Suite Home"
              : getFolderObject(
                  filteredKeysData?.folders ?? [],
                  currentFolderRoot
                )?.name}
          </Typography>
        </Box>
        <Box
          sx={{
            minHeight: 200,
            minWidth: 200,
            overflow: "auto",
          }}
        >
          <ShowSubfolders />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          disabled={checkIfNull(selectedFolderKey) && !isHomeFolder}
          onClick={handleMove}
        >
          Move
        </Button>
      </DialogActions>
    </Dialog>
  );
}
