import React from "react";
import { useDataSuiteAnalytics, useAppContext, checkIfNull } from "@app21/core";

import {
  Typography,
  Toolbar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@mui/material";
import { ExpandMore, AccessTime } from "@mui/icons-material";
import { getDateTimeFormat } from "utils/getDateFormats";
import { UserAvatar, LoadingSpinner, PrintUserInfo } from "blocks/atoms";
import { processFileHistoryData } from "./processFileHistoryData";
import { format } from "date-fns";
import { msToTime } from "utils";

export default function FileHistoryAnalytics({ fileKey, handleClickAway }) {
  const { selectedSuiteId } = useAppContext();
  const { data, isLoading } = useDataSuiteAnalytics({
    type: "FileKey",
    key: fileKey,
    suiteId: selectedSuiteId,
  });
  const processedFileHistory = React.useMemo(() => {
    if (data) {
      return processFileHistoryData(data.fileTimelineData);
    } else return {};
  }, [fileKey, data]);

  const getTimelineSummary = React.useCallback((e) => {
    const { eventType } = e;
    switch (eventType) {
      case "app21:S3:Read": {
        return `File was opened`;
      }

      case "app21:S3:Rename": {
        const oldFileSplit = e.data.oldKey.split("/");
        const newFileSplit = e.data.key.split("/");
        return `Renamed ${oldFileSplit[oldFileSplit.length - 1]} to ${
          newFileSplit[newFileSplit.length - 1]
        }`;
      }
      case "app21:S3:Create": {
        const fileName = e.data.key.split("/");
        return `Created file ${fileName[fileName.length - 1]}`;
      }
    }
  }, []);

  if (isLoading) return <LoadingSpinner />;
  if (checkIfNull(data?.fileTimelineData)) {
    return (
      <Typography variant="caption" sx={{ my: 2 }}>
        No History found
      </Typography>
    );
  }

  return Object.entries(processedFileHistory).map((objInfo, index) => {
    const [userId, obj] = objInfo;

    return (
      <Accordion key={index} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <UserAvatar size={20} userId={userId} />
          <Typography variant="body2" sx={{ ml: 1 }}>
            <PrintUserInfo userId={userId} />
          </Typography>
          {obj.totalViewDuration ? (
            <Chip
              size="small"
              icon={<AccessTime />}
              label={`${msToTime(obj.totalViewDuration)}`}
              sx={{ m: 0, ml: 2 }}
            />
          ) : null}
        </AccordionSummary>
        <AccordionDetails sx={{ m: 0, py: 0 }}>
          <List dense>
            {obj.events.map((e, index) => {
              return (
                <ListItem key={index} disableGutters sx={{ p: 0 }}>
                  <Typography variant="caption">
                    {e.summary} -{" "}
                    {format(
                      new Date(e.createdAt),
                      getDateTimeFormat({
                        startDate: e.createdAt,
                        noTimeFlag: true,
                      })
                    )}
                  </Typography>
                  {e.viewDuration ? (
                    <Chip
                      size="small"
                      label={`${msToTime(e.viewDuration)}`}
                      sx={{ m: 0, ml: 1 }}
                    />
                  ) : null}
                </ListItem>
              );
            })}
          </List>
        </AccordionDetails>
      </Accordion>
    );
  });
}
