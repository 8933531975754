// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import { SvgIcon } from "@mui/material";

export default function VideocallSettingsIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g>
        <circle cx="5" cy="12" r="1" />
        <circle cx="9" cy="12" r="1" />
        <circle cx="13" cy="12" r="1" />
        <path d="M14,21H4a4,4,0,0,1-4-4V7A4,4,0,0,1,4,3H14a4,4,0,0,1,4,4V17A4,4,0,0,1,14,21ZM4,5A2,2,0,0,0,2,7V17a2,2,0,0,0,2,2H14a2,2,0,0,0,2-2V7a2,2,0,0,0-2-2Z" />
        <path d="M21.65,21a2.35,2.35,0,0,1-1.84-.88l-3.59-4.5A1,1,0,0,1,16,15V9a1,1,0,0,1,.22-.62l3.59-4.5A2.35,2.35,0,0,1,24,5.35v13.3A2.35,2.35,0,0,1,21.65,21ZM18,14.65l3.38,4.22a.36.36,0,0,0,.62-.22V5.35a.36.36,0,0,0-.62-.22L18,9.35Z" />
      </g>
    </SvgIcon>
  );
}
