import { SvgIcon } from "@mui/material";
import React from "react";

export default function AddFolderIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M50.2488 68.7489V47.9155"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M60.4161 58.3345H39.5828"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.33276 54.2511V29.1678C8.33276 12.5011 12.4994 8.33447 29.1661 8.33447H35.4161C41.6661 8.33447 43.0411 10.1678 45.4161 13.3345L51.6661 21.6678C53.2494 23.7511 54.1661 25.0011 58.3328 25.0011H70.8328C87.4994 25.0011 91.6661 29.1678 91.6661 45.8345"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M91.6661 62.457V70.832C91.6661 87.4987 87.4994 91.6654 70.8328 91.6654H29.1661C12.4994 91.6654 8.33276 87.4987 8.33276 70.832"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
