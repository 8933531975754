// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { useRef } from "react";
import {
  Paper,
  Typography,
  Box,
  Divider,
  IconButton,
  Grid,
  Button,
  Stack,
} from "@mui/material";

import { Print, Edit } from "@mui/icons-material";
import DocketInviteesView from "./DocketInviteesView";
import DocketScheduleView from "./DocketScheduleView";
import DocketAgendaView from "./DocketAgendaView";
import DocketFilesView from "./DocketFilesView";
import DocketResolutionsView from "./DocketResolutionsView";
import DocketMinutesView from "./DocketMinutesView";
import DocketActionsView from "./DocketActionsView";
import DocketRecordsView from "./DocketRecordsView";
import { format } from "date-fns";
import { useReactToPrint } from "react-to-print";
import {
  useDockets,
  useMeetingsInDockets,
  useAppContext,
  useUserInfo,
  getRolePrivileges,
  useHandleRouterPush,
  checkIfNull,
} from "@app21/core";
import { useDocketsContext } from "providers/DocketsProvider";
import { getDateTimeFormat } from "utils/getDateFormats";
import { useIntl } from "react-intl";

import { processFeaturesAccessCheck } from "utils/processFeaturesAccessCheck";
import { useIsMobileBreakpoint } from "hooks";
import toast from "react-hot-toast";

const DocketSummaryView = (props) => {
  const printAreaRef = useRef();

  const { selectedDocketId, checkAccessRules, userInfo } = useAppContext();
  const {
    data: selectedDocket,
    status: selectedDocketStatus,
    apiError: selectedDocketApiError,
  } = useDockets(selectedDocketId);
  const {
    data: docketMeetings,
    status: docketMeetingsStatus,
    apiError: docketMeetingsApiError,
  } = useMeetingsInDockets(selectedDocketId);
  const isMobile = useIsMobileBreakpoint();

  const relatedMeeting = docketMeetings && docketMeetings.slice(-1)[0];

  const {
    data: creator,
    status: creatorStatus,
    apiError: creatorApiError,
  } = useUserInfo(selectedDocket?.createdBy);
  const { loadRoute, docketPermissions } = useDocketsContext();
  const dataFetchError =
    selectedDocketApiError || docketMeetingsApiError || creatorApiError;
  const dataFetchLoading =
    selectedDocketStatus === "loading" ||
    docketMeetingsStatus === "loading" ||
    creatorStatus === "loading";

  React.useEffect(() => {
    if (dataFetchError && !dataFetchLoading) {
      toast.errorrorror(dataFetchError.message);
    }
  }, [dataFetchError]);

  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });
  const featuresAccessCheck = React.useCallback(
    processFeaturesAccessCheck(checkAccessRules)
  );

  const handlePrint = useReactToPrint({
    content: () => printAreaRef.current,
  });

  return (
    <Box>
      <Box
        style={{
          display: "flex",
          minHeight: 50,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5" sx={{ mx: 5 }}>
          {selectedDocket?.title}
        </Typography>
        {/* {docketMeetingsStatus !== "loading" && (
            <CopyMeetingLinkControl
              meetingId={relatedMeeting?._id}
              componentType={"icon"}
            />
          )} */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mx: 5,
            width: "15%",
          }}
        >
          <Button size="small" variant="outlined">
            Cancel
          </Button>
          {docketPermissions.checkCanEditDocket(selectedDocket) && (
            <Button
              size="small"
              variant="contained"
              onClick={() =>
                loadRoute("EDIT-DOCKET", { docketId: selectedDocketId })
              }
            >
              Edit
            </Button>
          )}
        </Box>
      </Box>
      <Divider />
      <Paper
        ref={printAreaRef}
        elevation={3}
        square
        sx={{
          height: "100%",
          width: "100%",
          padding: (theme) => theme.spacing(2),
          bgcolor: "gray.lighter",
        }}
      >
        <Paper
          sx={{
            p: 4,
            mt: 3,
            bgcolor: "background.paper",
          }}
        >
          <Button
            variant="outlined"
            startIcon={<Print fontSize="small" />}
            onClick={handlePrint}
            size="small"
            sx={{ my: 2 }}
          >
            PRINT
          </Button>
          <Box sx={{ my: 2 }}>
            <Typography variant="body2">{`${t(
              "DocketDashboard.ShowCreator.creatorSummaryLabel"
            )}`}</Typography>
            {!checkIfNull(selectedDocket?.createdAt) && (
              <Typography variant="h6">
                {creator?.fullName} (
                {selectedDocket?.createdAt &&
                  format(
                    new Date(selectedDocket?.createdAt),
                    getDateTimeFormat({
                      startDate: selectedDocket?.createdAt,
                      noTimeFlag: true,
                    })
                  )}
                {" at "}
                {selectedDocket?.createdAt &&
                  format(new Date(selectedDocket?.createdAt), "hh:mm a")}
                )
              </Typography>
            )}
          </Box>
          <Box sx={{ my: 2 }}>
            <Typography variant="body2" sx={{ mb: 1 }}>{`${t(
              "DocketDashboard.ShowInvitees.inviteesSummaryLabel"
            )}`}</Typography>
            <DocketInviteesView viewMode="summary" />
          </Box>
          <Box sx={{ my: 4 }}>
            <Typography variant="h6" color="primary.dark">{`${t(
              "DocketDashboard.ShowSchedule.scheduleSummaryLabel"
            )}`}</Typography>
            <DocketScheduleView viewMode="summary" />
          </Box>
          {featuresAccessCheck.canViewDocketAgenda && (
            <Box sx={{ my: 2 }}>
              <Typography variant="body2">{`${t(
                "DocketDashboard.ShowAgenda.agendaSummaryLabel"
              )}`}</Typography>
              <DocketAgendaView viewMode="summary" />
            </Box>
          )}
          {featuresAccessCheck.canViewDocketInfoPack && (
            <Box sx={{ my: 4 }}>
              <Typography variant="body2">{`${t(
                "DocketDashboard.ShowFiles.filesSummaryLabel"
              )}`}</Typography>
              <DocketFilesView viewMode="summary" />
            </Box>
          )}
          {featuresAccessCheck.canViewDocketResolutions && (
            <Box sx={{ my: 2 }}>
              <Typography variant="body2">{`${t(
                "DocketDashboard.ShowResolutions.resolutionsSummaryLabel"
              )}`}</Typography>
              <DocketResolutionsView viewMode="summary" />
            </Box>
          )}
          {featuresAccessCheck.canViewDocketMinutes && (
            <Box sx={{ my: 2 }}>
              <Typography variant="h6">{`${t(
                "DocketDashboard.ShowMinutes.minutesSummaryLabel"
              )}`}</Typography>
              <DocketMinutesView viewMode="summary" />
            </Box>
          )}
          {featuresAccessCheck.canViewDocketActions && (
            <Box sx={{ my: 4 }}>
              <Typography variant="body2">{`${t(
                "DocketDashboard.ShowActions.actionsSummaryLabel"
              )}`}</Typography>
              <DocketActionsView viewMode="summary" />
            </Box>
          )}
          {featuresAccessCheck.canViewDocketRecords && (
            <Box sx={{ my: 4 }}>
              <Typography variant="body2">{`${t(
                "DocketDashboard.ShowRecords.recordsSummaryLabel"
              )}`}</Typography>
              <DocketRecordsView viewMode="summary" />
            </Box>
          )}
        </Paper>
      </Paper>
    </Box>
  );
};

export default DocketSummaryView;
