// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Paper } from "@mui/material";
import { ConsolidatedTasksView } from "blocks/modules/Tasks";
import { useTasks, useAppContext } from "@app21/core";

import { useIntl } from "react-intl";
import LoadingSpinner from "blocks/atoms/LoadingSpinner";
import toast from "react-hot-toast";

const ShowActions = (props) => {
  const { viewMode } = props;

  const { selectedDocketId } = useAppContext();
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });

  const {
    data: actions,
    status: actionsStatus,
    apiError: actionsApiError,
  } = useTasks({
    objectId: selectedDocketId,
    idType: "docket",
  });
  React.useEffect(() => {
    if (actionsApiError && actionsStatus != "loading") {
      toast.error(actionsApiError.message);
    }
  }, [actionsApiError]);
  if (actionsStatus === "loading") {
    return <LoadingSpinner size={25} />;
  } else {
    return (
      <Paper
        sx={{
          weight: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
        }}
      >
        <ConsolidatedTasksView context={"docket"} viewMode={viewMode} />
      </Paper>
    );
  }
};

export default ShowActions;
