import React from 'react'
import { SvgIcon } from "@mui/material";

export default function CollapseArrowIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 14 15"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M7.90479 10.1894L10.0748 12.3594L12.2448 10.1894"
          stroke="currentColor"
          strokeWidth="0.875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.0737 5.94855V12.3594"
          stroke="currentColor"
          strokeWidth="0.875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.0737 1.85938V3.60354"
          stroke="currentColor"
          strokeWidth="0.875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.09586 4.02936L3.92584 1.85938L1.75586 4.02936"
          stroke="currentColor"
          strokeWidth="0.875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.92627 1.85938V8.32856"
          stroke="currentColor"
          strokeWidth="0.875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.92627 12.3594V10.6269"
          stroke="currentColor"
          strokeWidth="0.875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
