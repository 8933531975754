// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Badge, useTheme } from "@mui/material";
import {
  useAppContext,
  app21APIInstance,
  useHandleRouterPush,
  useMeeting,
} from "@app21/core";
import { useCheckActiveLink } from "hooks";
import { useChatContext } from "providers/TrrystChatProvider";
import {
  ChatActiveWebIcon,
  ChatInactiveWebIcon,
} from "blocks/atoms/trrystCustomIcons";
import { pxToRem } from "utils/styleHelpers";
import { StyledSquareMenuButton } from "blocks/atoms/uistyles";
import { chimeTestIds } from "blocks/atoms/constants";
import { useVideocallContext } from "providers/TrrystVideocallProvider";
import { useAtom } from "jotai";
import { CurrentChannelAtom } from "blocks/modules/TrrystChat/state-atoms";

const ChatMessageControl = ({ recommendedButtonSize }) => {
  const {
    userInfo,
    selectedSuiteId,
    meetingId,
    selectedOrganizationId,
    chimeViewMode,
  } = useAppContext();
  const [, setCurrentChannel] = useAtom(CurrentChannelAtom);
  const { setIsChatModalOpen } = useVideocallContext();
  const { loadRoute } = useHandleRouterPush();

  const { data: meeting } = useMeeting(meetingId);
  const theme = useTheme();
  const isChatWindowActive = useCheckActiveLink("GROUP-CHAT");

  const { unreadMessageCounts } = useChatContext();
  const isModalView =
    chimeViewMode === "fullscreen" || chimeViewMode === "featured";

  const handleSelectMeeetingChannel = async () => {
    const response = await app21APIInstance.post(`/channels/fetch`, {
      objectType: "meeting",
      objectId: meetingId,
      calledBy: userInfo._id,
      additionalData: {
        suiteId: meeting?.suiteId ?? selectedSuiteId,
      },
    });

    if (response && response?.data?.data?.data[0]?.id) {
      // channel switching should happen via the loadRoute and activeChatId

      let meetingChannel = response?.data?.data?.data[0];
      if (isModalView) {
        setCurrentChannel(meetingChannel.id);
        setIsChatModalOpen(true);
      } else
        loadRoute("GROUP-CHAT", {
          chatId: meetingChannel.id,
          chatType: "meetings",
          orgId:
            meetingChannel?.metadata?.organizationId ?? selectedOrganizationId,
          suiteId: meetingChannel?.metadata?.suiteId?.[0] ?? selectedSuiteId,
        });
    } else {
      // FIXME: put in a toast to handle the error
    }
  };

  const unreadMessagesInMeeting =
    typeof unreadMessageCounts === "object"
      ? unreadMessageCounts[`${meetingId}-CHAT`] ?? 0
      : 0;

  return (
    <StyledSquareMenuButton
      data-testid={chimeTestIds.chatMessageControl}
      onClick={async () => await handleSelectMeeetingChannel()}
      sx={{
        borderRadius: 0,
        padding: 0,
        width: recommendedButtonSize,
        height: recommendedButtonSize,
        maxWidth: recommendedButtonSize,
        maxHeight: recommendedButtonSize,
        minWidth: recommendedButtonSize,
        minHeight: recommendedButtonSize,
      }}
    >
      <Badge
        badgeContent={unreadMessagesInMeeting}
        overlap="circular"
        color="warning"
      >
        {isChatWindowActive ? (
          <ChatActiveWebIcon
            color="primary"
            stroke={theme.palette.primary.contrastText}
            fontSize="large"
            sx={{
              width: recommendedButtonSize,
              height: recommendedButtonSize,

              maxWidth: pxToRem(40),
              maxHeight: pxToRem(40),
              "&:hover": {
                color: "primary.dark",
              },
            }}
          />
        ) : (
          <ChatInactiveWebIcon
            stroke={theme.palette.grey[600]}
            fontSize="large"
            sx={{
              width: recommendedButtonSize,
              height: recommendedButtonSize,

              maxWidth: pxToRem(40),
              maxHeight: pxToRem(40),
              color: (theme) => theme.palette.gray.lighter,
              "&:hover": {
                color: "gray.light",
              },
            }}
          />
        )}
      </Badge>
    </StyledSquareMenuButton>
  );
};

export default ChatMessageControl;
