// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { useState, useCallback } from "react";
import { Box, IconButton, Typography, Button, Paper } from "@mui/material";
import { CloudUpload } from "@mui/icons-material";
import { checkIfNull, getS3ImageWrapper } from "@app21/core";
import Imgix from "react-imgix";

import { styled } from "@mui/material/styles";
import { UploadSingleFileDialog } from "blocks/atoms/uicomponents/upload";
import { isString } from "lodash";
import Image from "next/image";
import { SIZELIMITS } from "blocks/atoms/constants/appConstants";
import { useIsMobileBreakpoint } from "hooks";

const StyledBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  flexGrow: 1,
}));

const StyledImage = styled(Image)(({ theme }) => ({
  borderRadius: 10,
  objectFit: "cover",
}));
const StyledMessagePaper = styled(Paper)(({ theme }) => ({
  background: "transparent",
  padding: 5,
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: 5,
}));

const EditableImage = ({
  labelText = "Upload Image",
  maxSize = SIZELIMITS.objectImageMaxFileSize,
  handleFileUpload,
  title,
  handleCurrentImageChange,
  currentImage,
  disableChange = false,
  imageProps = {},
  StyledBoxProps = {},
  ...other
}) => {
  const isMobile = useIsMobileBreakpoint();
  const [changeImageFlag, setChangeImageFlag] = React.useState(false);
  const [imageFiles, setImageFiles] = React.useState(null);
  const [preview, setPreview] = React.useState(null);
  const [uploadingActive, setUploadingActive] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDropSingleFile = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setImageFiles(file);
        setPreview({ preview: URL.createObjectURL(file) });
        handleFileUpload(file);
      }
    },
    [handleFileUpload]
  );

  const handleDialogClose = () => {
    setChangeImageFlag(!changeImageFlag);
  };
  return (
    <StyledBox {...StyledBoxProps}>
      {!checkIfNull(currentImage) ? (
        <Paper
          square
          component="img"
          elevation={5}
          style={{
            objectFit: "cover",
          }}
          src={
            isString(currentImage)
              ? getS3ImageWrapper(currentImage, "person")
              : currentImage?.preview
          }
          {...imageProps}
        />
      ) : (
        <StyledMessagePaper
          square
          elevation={5}
          onClick={() => {
            setChangeImageFlag(!changeImageFlag);
            handleCurrentImageChange ? handleCurrentImageChange() : null;
          }}
          disabled={disableChange}
          sx={{}}
          {...imageProps}
        >
          <Typography variant="caption" sx={{ textAlign: "center" }}>
            {labelText}
          </Typography>
        </StyledMessagePaper>
      )}
      <Button
        onClick={() => {
          setChangeImageFlag(!changeImageFlag);
          handleCurrentImageChange ? handleCurrentImageChange() : null;
        }}
        disabled={disableChange}
      >
        Change
      </Button>
      <UploadSingleFileDialog
        file={preview || currentImage}
        open={changeImageFlag}
        accept={"image/*"}
        loading={uploadingActive}
        title={title ?? "Upload"}
        maxSize={maxSize}
        onSubmit={async (files) => {
          setUploadingActive(true);

          handleDropSingleFile(files);
          setUploadingActive(false);
          setChangeImageFlag(!changeImageFlag);
        }}
        onClose={handleDialogClose}
        {...other}
      />
    </StyledBox>
  );
};

export default EditableImage;
