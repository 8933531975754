// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import PropTypes from "prop-types";
// material
import { Card, CardContent } from "@mui/material";
// utils

UploadFilesModal.propTypes = {
  error: PropTypes.bool,
  showPreview: PropTypes.bool,
  showNotify: PropTypes.bool,
  open: PropTypes.bool,
  files: PropTypes.array,
  onSubmit: PropTypes.func,
  handleNotify: PropTypes.func,
  title: PropTypes.any,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  onClose: PropTypes.func,
  sx: PropTypes.object,
};

export default function UploadFilesModal({}) {
  return (
    <Card
      sx={{
        flxeGrow: 1,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        my: 0,
      }}
    >
      <CardContent></CardContent>
    </Card>
  );
}
