// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Icon, Link, IconButton } from "@mui/material";
import { useRouter } from "next/router";
import NextMuiLink from "blocks/atoms/NextMuiLink";
import { useHandleRouterPush } from "@app21/core";

export default function Logo({ size, variant = "color", disabled }) {
  const router = useRouter();
  const { loadRoute } = useHandleRouterPush();
  const fullLogoColorPath = "/static/images/TrrystLogoFull.svg";
  const fullLogoMonochromePath = "/static/images/TrrystLogoFullMonochrome.svg";
  const logoImageColorPath = "/static/images/TrrystLogoImage.svg";
  const logoImageMonochromePath =
    "/static/images/TrrystLogoImageMonochrome.svg";
  const logoImageMonochromeWhite =
    "/static/images/TrrystLogoMonochromeWhite.svg";

  let outsideRouteFlag =
    router.pathname.includes("/home") || router.pathname === "/";
  let targetRoute = outsideRouteFlag ? "GOTO-LANDING" : "GOTO-MAINDASHBOARD";
  {
    /* if (router.pathname?.includes('/user/organization/suite')) {
    targetRoute = 'GOTO-MAINDASHBOARD';
  }*/
  }

  if (size === "full") {
    return (
      <img
        style={{
          height: "80%",
          maxHeight: 20,

          width: "100%",
          cursor: "pointer",
        }}
        src={
          outsideRouteFlag
            ? fullLogoColorPath
            : variant !== "white"
            ? fullLogoMonochromePath
            : logoImageMonochromeWhite
        }
        onClick={() => loadRoute(targetRoute, {})}
      />
    );
  } else {
    if (disabled) {
      return (
        <Icon sx={{ width: "100%", height: "100%", maxHeight: 30 }}>
          <img
            style={{ maxHeight: 30 }}
            src={
              outsideRouteFlag
                ? logoImageColorPath
                : variant !== "white"
                ? logoImageMonochromePath
                : logoImageMonochromeWhite
            }
          />
        </Icon>
      );
    } else
      return (
        <IconButton
          onClick={() => loadRoute(targetRoute, {})}
          disableFocusRipple
          disableRipple
          sx={{ "&:hover": { backgroundColor: "transparent" } }}
        >
          <Icon
            style={{
              width: "100%",
              height: "100%",
              maxHeight: 30,
              cursor: "pointer",
            }}
          >
            <img
              style={{ maxHeight: 30 }}
              src={
                outsideRouteFlag && variant !== "white"
                  ? logoImageColorPath
                  : variant !== "white"
                  ? logoImageMonochromePath
                  : logoImageMonochromeWhite
              }
            />
          </Icon>
        </IconButton>
      );
  }
}
