import React from 'react'
import { SvgIcon } from '@mui/material';

export default function MeetingMarkerIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M42.3644 52.047L68.8219 78.5045L56.094 91.2324C52.8531 94.4733 47.5498 94.4733 44.3089 91.2324L8.95352 55.8771C5.71262 52.6362 5.71262 47.3329 8.95352 44.092L21.6815 31.364L30.7266 40.4091"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M79.3066 68.0161C82.4886 64.8342 83.7259 60.179 82.4885 55.8185L78.6878 42.1774C77.8629 39.29 78.6879 36.1668 80.8092 34.0455L86.9964 27.8583C90.2373 24.6174 90.2373 19.3141 86.9964 16.0732L84.0501 13.127C80.8092 9.88605 75.5059 9.88605 72.265 13.127L66.0779 19.3141C63.9565 21.4354 60.8333 22.2604 57.9459 21.4355L44.3048 17.6348C39.9738 16.4269 35.2892 17.6347 32.1072 20.8167L21.6184 31.3054"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
