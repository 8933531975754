import React from 'react'
import { SvgIcon } from '@mui/material';

export default function ArrowUpIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M20.2917 20.7913C12.875 28.2913 8.33337 38.6247 8.33337 49.9997C8.33337 72.9997 27 91.6663 50 91.6663C73 91.6663 91.6667 72.9997 91.6667 49.9997C91.6667 26.9997 73 8.33301 50 8.33301C47.125 8.33301 44.3334 8.62467 41.5834 9.16634"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M35.2915 55.2507L49.9998 40.584L64.7082 55.2507"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
