import React from "react";
import { Box } from "@mui/material";
import { LoadingSpinner, UserAvatar } from "blocks/atoms";
import { useOrgsAndSuitesInfoContext } from "providers/OrgsAndSuitesInfoProvider";

export default function PrintUserInfo({ userId, defaultText = "", sx = {} }) {
  const { fetchUserInfo } = useOrgsAndSuitesInfoContext();

  const userObj = fetchUserInfo(userId);

  if (userObj)
    return (
      <Box component="span" sx={sx}>
        {userObj?.fullName ?? defaultText}
      </Box>
    );
  return (
    <UserAvatar
      viewContext="name"
      userId={userId}
      defaultText={defaultText}
      sx={sx}
    />
  );
}
