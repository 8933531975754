import React from 'react'
import { SvgIcon } from "@mui/material";

export default function FileHome2Icon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 16 16"
      stroke="currentColor"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M1.3335 8.67998V4.66665C1.3335 1.99998 2.00016 1.33331 4.66683 1.33331H5.66683C6.66683 1.33331 6.88683 1.62665 7.26683 2.13331L8.26683 3.46665C8.52016 3.79998 8.66683 3.99998 9.3335 3.99998H11.3335C14.0002 3.99998 14.6668 4.66665 14.6668 7.33331"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.6668 9.99335V11.3333C14.6668 14 14.0002 14.6667 11.3335 14.6667H4.66683C2.00016 14.6667 1.3335 14 1.3335 11.3333"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.9562 8.25183C10.9562 7.89473 10.7171 7.43729 10.425 7.23365L8.6011 5.95576C8.18793 5.66654 7.5239 5.6813 7.12548 5.99118L5.53476 7.2307C5.26915 7.43729 5.05371 7.87702 5.05371 8.21051V10.3974C5.05371 11.0821 5.6115 11.6428 6.29618 11.6428H9.71372C10.3984 11.6428 10.9562 11.0821 10.9562 10.4003V9.48545"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.00488 10.4623V9.57697"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
