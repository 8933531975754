import React from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Card,
  Stack,
  Avatar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { FlowBox } from "blocks/atoms/uistyles";
import { useUsersInSuite, useAppContext } from "@app21/core";
import {
  processSurveyData,
  ShowSurveyBarChart,
  ShowSurveyResultsTable,
} from "blocks/modules/Surveys/foundations";

export default function ShowSurveyResultsChartMode({ selectedDecision = {} }) {
  const { selectedSuiteId } = useAppContext();
  const { data: usersInSuite, status: usersInSuiteStatus } =
    useUsersInSuite(selectedSuiteId);
  const questionsInfo = selectedDecision?.survey?.questionBlock ?? [];
  const answersInfo = [];
  for (const id in selectedDecision?.responses) {
    let newObj = {};
    newObj.respondentId = id;
    newObj.answers = selectedDecision?.responses[id];

    answersInfo.push(newObj);
  }

  let processedSurveyData = processSurveyData(
    questionsInfo,
    answersInfo,
    usersInSuite
  );

  return (
    <FlowBox sx={{ py: 2 }}>
      {processedSurveyData.map((question, index) => {
        return (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id={"survey-response-" + index}
            >
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,

                  alignItems: "center",
                }}
              >
                <Typography variant="body1">{question.questionItem}</Typography>
              </Box>
              <Box sx={{ flexShrink: 0, pr: 3 }}>
                <ShowSurveyBarChart
                  compositeData={question}
                  viewContext="compact"
                />
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <ShowSurveyResultsTable question={question} />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </FlowBox>
  );
}
