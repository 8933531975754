import clip from "text-clipper";
import React from "react";
import Highlighter from "react-highlight-words";

export default function ShowMoreTextPanel({
  text = "",
  maxLength,
  showMoreIndicator = "More",
  showLessIndicator = "Less",
  toggleFlag = true,
  searchQuery = "",
}) {
  const [isExpanded, setExpanded] = React.useState(false);

  if (typeof text !== "string") return text;
  else if (typeof text === "string" && text.length <= maxLength) {
    return (
      <Highlighter
        highlightClassName="YourHighlightClass"
        searchWords={[searchQuery]}
        autoEscape={true}
        textToHighlight={text ?? ""}
      />
    );
  } else
    return (
      <>
        {isExpanded ? (
          <Highlighter
            highlightClassName="YourHighlightClass"
            searchWords={[searchQuery]}
            autoEscape={true}
            textToHighlight={text ?? ""}
          />
        ) : (
          <Highlighter
            highlightClassName="YourHighlightClass"
            searchWords={[searchQuery]}
            autoEscape={true}
            textToHighlight={clip(text, maxLength) ?? ""}
          />
        )}
        <a
          onClick={() => setExpanded(!isExpanded)}
          style={{
            marginLeft: 3,
            marginRight: 3,
            fontSize: 12,
            fontWeight: 600,
            color: "darkslategrey",
            cursor: "pointer",
          }}
        >
          {isExpanded ? showLessIndicator : showMoreIndicator}
        </a>
      </>
    );
}
