import React from "react";
import { FlowBox } from "blocks/atoms/uistyles";
import { Button, Typography } from "@mui/material";
import { useAtom } from "jotai";
import { useAppContext, useHandleRouterPush } from "@app21/core";
import { useSurveysContext } from "providers/SurveysProvider";

export default function ShowEmptySurveysComponent() {
  const { isDocket, loadRoute, canAddNew } = useSurveysContext();

  return (
    <FlowBox sx={{ justifyContent: "center", alignItems: "center" }}>
      <Typography variant="h6" sx={{ color: "text.secondary" }}>
        {isDocket
          ? "No Resolutions"
          : "There are no Surveys. Get Started by creating a new Survey"}
      </Typography>
      {canAddNew ? (
        isDocket ? (
          <Button
            variant="contained"
            onClick={() => loadRoute("CREATE-RESOLUTION", {})}
            sx={{ mt: 3 }}
          >
            Create Resolution
          </Button>
        ) : (
          <Button
            onClick={() => loadRoute("CREATE-SURVEY", {})}
            variant="outlined"
            color="primary"
            sx={{ mt: 2 }}
          >
            Create Survey
          </Button>
        )
      ) : null}
    </FlowBox>
  );
}
