// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
// Function to output the Number of videos in one row
/*
	Input - 
  		Size of Screen - screenX x screenY
		Aspect ratio of video - 4:3
		Total number of videos - N
		Featured video present - featured (True/False)
		Minimum width of video - minVideoX
		
	Output -
		width of each video
        Number of cols
        Number of rows
        scroll required - true or false
        
*/

export const baseLayoutPlanner = ({
  inputScreenX = 100,
  inputScreenY = 100,
  numOfScreens = 1,
  featured = false,
  minVideoX = 50,
  aspectRatio = 16 / 9,
}) => {
  let screenX = Math.max(Math.min(inputScreenX, 4000), 50);
  let screenY = Math.max(Math.min(inputScreenY, 3000), 50);
  let N = numOfScreens < 1 ? 1 : numOfScreens;

  // Max number of videos in 1 row
  const maxRow = screenX / minVideoX;
  var row_layout = true;
  // Check if featured video is possible or not
  if (featured && maxRow < 0) {
    return {
      feasible: false,
      rows: null,
      scroll: null,
    };
  }

  if (featured) {
    N = N + 8;
  }

  // unit number of videos in one row
  const unitX = screenX / aspectRatio;
  // unit number of videos in one column
  const unitY = screenY;
  // ratio to calculate deviation from square
  const ratio = unitX / unitY;
  const ratio2 = unitY / unitX;

  // number of videos in one row
  var c1 = Math.max(Math.ceil(Math.sqrt(N / ratio)), 1);
  var r1 = Math.max(Math.ceil(N / c1), 1);

  var r2 = Math.ceil(Math.sqrt(N / ratio2));
  var c2 = Math.ceil(N / r2);

  if (featured) {
    if (c1 < 3 && r1 > 3) {
      c1 = 3;
      r1 = Math.ceil(N / c1);
    }
    if (c1 > 3 && r1 < 3) {
      r1 = 3;
      c1 = Math.ceil(N / r1);
    }
    if (c2 < 3 && r2 > 3) {
      c2 = 3;
      r2 = Math.ceil(N / c2);
    }
    if (c2 > 3 && r2 < 3) {
      r2 = 3;
      c2 = Math.ceil(N / r2);
    }
  }

  /*const c2 = 5;
  const r2 = 6;
  const c1 = 5;
  const r1 = 6;
*/

  //If grid fills the width completely then does height also fit.
  var widthOfGridX = screenX;
  var heightOfGridX = (widthOfGridX / c1 / aspectRatio) * r1;
  var videoWidthX = widthOfGridX / c1;

  if (heightOfGridX > screenY) {
    row_layout = false;
    videoWidthX = (screenY / r1) * aspectRatio;
  }

  //If grid fills the height completely then does width also fit.
  var heightOfGridY = screenY;
  var widthOfGridY = (heightOfGridY / r2) * aspectRatio * c2;

  var videoWidthY = (heightOfGridY / r2) * aspectRatio;

  if (widthOfGridY > screenX) {
    row_layout = true;
    videoWidthY = screenX / c2;
  }

  var videoWidth = videoWidthY;
  var c = c2;
  var r = r2;

  if (videoWidthX > videoWidthY) {
    row_layout = false;
    c = c1;
    r = r1;
    videoWidth = videoWidthX;
  }

  var t = c1;
  c1 = r1;
  r1 = t;

  t = c2;
  c2 = r2;
  r2 = t;

  widthOfGridX = screenX;
  heightOfGridX = (widthOfGridX / c1 / aspectRatio) * r1;
  videoWidthX = widthOfGridX / c1;

  if (heightOfGridX > screenY) {
    row_layout = true;
    videoWidthX = (screenY / r1) * aspectRatio;
  }

  if (videoWidthX > videoWidth) {
    row_layout = false;
    c = c1;
    r = r1;
    videoWidth = videoWidthX;
  }

  //If grid fills the height completely then does width also fit.
  heightOfGridY = screenY;
  widthOfGridY = (heightOfGridY / r2) * aspectRatio * c2;

  videoWidthY = (heightOfGridY / r2) * aspectRatio;

  if (widthOfGridY > screenX) {
    row_layout = true;
    videoWidthY = screenX / c2;
  }

  if (videoWidthY > videoWidth) {
    row_layout = false;
    c = c2;
    r = r2;
    videoWidth = videoWidthY;
  }

  var scroll = false;

  if (videoWidth < minVideoX) {
    c = Math.floor(screenX / minVideoX);
    videoWidth = Math.floor(screenX / c);
    r = Math.ceil(N / c);
    scroll = true;
  }

  return {
    feasible: true,
    cols: Math.min(Math.max(c, 1), 15),
    rows: Math.min(Math.max(r, 1), 15),
    scroll: scroll,
    videoWidth: Math.min(Math.max(videoWidth, 50), 800),
    row_layout: row_layout,
  };
};
