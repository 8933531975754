// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Box, Typography } from "@mui/material";
import { FlowBox } from "blocks/atoms/uistyles";
import { useIsMobileBreakpoint } from "hooks";
import { RecordingsSidebar, RecordingsCenterPanel } from "./features";
import { RecordingsMenubar } from "./foundations";
import RecordingsProvider from "providers/RecordingsProvider";

import {
  ModulesTitleComponent,
  SidePanelDrawer,
} from "blocks/atoms/uicomponents";

export default function MainRecordingsComponent({
  viewContext = "suite",
  viewMode = "full",
  actionContext = "list",
}) {
  const isMobile = useIsMobileBreakpoint(480, true) || viewMode === "compact";

  const sidebarModeActive = isMobile;

  const showSidebar = viewContext === "suite";

  const TitleComponent = (
    <Typography variant="h4" color="text.secondary">
      Meeting Records
    </Typography>
  );
  const sidebarWidthRatio = 25;
  const centralTitleBarHeight = 48;

  return (
    <RecordingsProvider
      viewContext={viewContext}
      actionContext={actionContext}
      isMobile={isMobile}
    >
      <FlowBox sx={{ position: "relative" }}>
        <ModulesTitleComponent
          sidebarActive={isMobile}
          width={`${sidebarWidthRatio}%`}
          TitleComponent={TitleComponent}
          centralTitleBarHeight={centralTitleBarHeight}
          HeaderComponent={
            <RecordingsMenubar viewMode={viewMode} isMobile={isMobile} />
          }
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "stretch",
            flexGrow: 1,
            height: "100%",
            position: "relative",
          }}
        >
          {showSidebar ? (
            <SidePanelDrawer
              sidebarModeActive={sidebarModeActive}
              centralTitleBarHeight={centralTitleBarHeight}
              TitleComponent={TitleComponent}
              width={`${sidebarWidthRatio}%`}
              DrawerSxProps={{ minWidth: 250 }}
              sx={{ width: `min(max(${sidebarWidthRatio}%, 200px), 400px)` }}
            >
              <RecordingsSidebar
                viewContext={viewContext}
                isMobile={isMobile}
              />
            </SidePanelDrawer>
          ) : (
            <></>
          )}
          <FlowBox
            sx={{
              transition: "all 1s ease-in-out",
              paddingTop: `${centralTitleBarHeight}px`,

              width: showSidebar
                ? `calc(100% - min(max(${sidebarWidthRatio}%, 200px), 400px))}`
                : "100%",
            }}
          >
            <RecordingsCenterPanel
              viewContext={viewContext}
              isMobile={isMobile}
            />
          </FlowBox>
        </Box>
      </FlowBox>
    </RecordingsProvider>
  );
}
