import React from "react";
import { CombinedAuthLayout, CombinedBaseLayout } from "./features";

export default function Layouts({ viewContext = "main", children, ...rest }) {
  if (viewContext === "home" || viewContext === "generic") {
    return (
      <CombinedBaseLayout viewContext={viewContext} {...rest}>
        {children}
      </CombinedBaseLayout>
    );
  } else {
    return (
      <CombinedAuthLayout viewContext={viewContext} {...rest}>
        {children}
      </CombinedAuthLayout>
    );
  }
}
