// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Card } from "@mui/material";
import { Player } from "video-react";

const CustomVideoRenderer = ({ mainState: { currentDocument } }) => {
  if (!currentDocument) return null;

  return (
    <Card
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        flexGrow: 1,
        height: "100%",
        width: "100%",
        backgroundColor: "rgba(18,18,18,0.8)",
      }}
    >
      <Player playsInline src={currentDocument.fileData} />

      {/*<Video src={currentDocument.fileData} autoPlay controls>
        {(video, state, actions) => (
          <div
            style={{
              border: '2px solid red',
              outline: '2px solid yellow',

              minWidth: '100%',
              minHeight: '100%',
              width: 'auto',
              height: 'auto',
              backgroundSize: 'cover',
              overflow: 'hidden',
            }}
          >
            {video}
            <div>
              {state.currentTime} / {state.duration} / {state.buffered}
            </div>
            <progress value={state.currentTime} max={state.duration} onChange={actions.navigate} />
            <progress value={state.volume} max={1} onChange={actions.setVolume} />
            <button onClick={actions.play}>Play</button>
            <button onClick={actions.pause}>Pause</button>
            <button onClick={actions.requestFullScreen}>Fullscreen</button>
          </div>
        )}
      </Video>*/}
    </Card>
  );
};

CustomVideoRenderer.fileTypes = ["mp4", "video/mp4"];
CustomVideoRenderer.weight = 1;

export default CustomVideoRenderer;
