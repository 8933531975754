import React from 'react'
import { SvgIcon } from '@mui/material';

export default function EndCallIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M42.9867 79.0412C43.57 79.4995 44.1534 79.9162 44.7784 80.3745C49.5284 83.8328 54.32 86.5828 59.1117 88.6245C63.9034 90.6662 68.445 91.6662 72.695 91.6662C75.6117 91.6662 78.32 91.1245 80.82 90.0828C83.3617 89.0412 85.6117 87.4162 87.6534 85.1662C88.8617 83.8328 89.7784 82.4162 90.4867 80.9162C91.195 79.4162 91.5284 77.8745 91.5284 76.3745C91.5284 75.2078 91.2784 74.1662 90.8617 73.1245C90.4034 72.0828 89.6534 71.2078 88.57 70.4578L74.7784 60.6662C73.7367 59.9578 72.7784 59.4162 71.8617 59.0412C70.945 58.6662 70.1117 58.4995 69.32 58.4995C68.2784 58.4995 67.32 58.7912 66.3617 59.3745C65.4034 59.8745 64.4034 60.6662 63.3617 61.7078L60.195 64.8328C59.7367 65.2912 59.195 65.5412 58.4867 65.5412C58.1117 65.5412 57.7784 65.4995 57.445 65.3745C57.1534 65.2495 56.9034 65.1245 56.695 65.0412C55.9034 64.6245 54.9867 64.0412 53.9867 63.2912"
          stroke="currentColor"
          strokeWidth="4.84615"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.448 11.4173C28.698 10.334 27.7397 9.58398 26.6563 9.08398C25.6147 8.58398 24.448 8.33398 23.2813 8.33398C20.198 8.33398 17.2813 9.62565 14.6147 12.2507C12.4063 14.334 10.823 16.6673 9.823 19.209C8.823 21.709 8.323 24.4173 8.323 27.2506C8.323 31.584 9.323 36.1256 11.323 40.8756C13.323 45.584 16.073 50.334 19.4897 55.084C22.948 59.834 26.823 64.3756 31.1147 68.709"
          stroke="currentColor"
          strokeWidth="4.84615"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M44.6979 55.0832C42.5312 52.8748 40.5312 50.6665 38.6562 48.4998C36.8229 46.3332 35.5312 44.5415 34.7812 43.1665C34.6979 42.9165 34.5729 42.6665 34.4479 42.3332C34.3645 41.9998 34.3229 41.7082 34.3229 41.3748C34.3229 40.7082 34.5312 40.1665 34.9895 39.7082L38.1562 36.4165C39.1562 35.4165 39.9479 34.4165 40.4895 33.4582C41.0729 32.4998 41.3645 31.4998 41.3645 30.4998C41.3645 29.7082 41.1562 28.8332 40.7812 27.9582C40.4062 27.0415 39.8645 26.0832 39.1145 25.0415"
          stroke="currentColor"
          strokeWidth="4.84615"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M91.6563 8.33398L8.323 91.6673"
          stroke="currentColor"
          strokeWidth="4.84615"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
