import React from "react";
import {
  Box,
  Typography,
  RadioGroup,
  Radio,
  TextField,
  InputAdornment,
  FormControlLabel,
  styled,
  Card,
  CardHeader,
  Grid,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { Send, CheckCircle, Error } from "@mui/icons-material";
import { useApp21ProductsAndCoupons } from "@app21/core";
import {format, isAfter} from 'date-fns'
import { useIsMobileBreakpoint } from "hooks";
import { getSuitePrices } from "utils";
import { LoadingSpinner } from "blocks/atoms";

const mainChoice = [
  {
    label: "Basic",
    value: "BASIC",
    active: true,
    description:
      "Normal subscription with a normal allocation of Credits. You can always top-up to get more credits.",
    width: 100,
  },
  {
    label: "Pro",
    value: "PRO",
    active: true,
    description:
      "Choose Pro if you plan to use the suite extensively plus some additional features",

    width: 100,
  },
];
const addonChoice = [
  {
    label: "None",
    value: "EXEC-SUITE",
    active: true,
    description: "Best Suited for Boardroom or Committee interactions",
    width: 80,
  },
  {
    label: "Boardroom",
    value: "BOARD-SUITE",
    active: true,
    description: "General purpose suite for your Executive conversations",
    width: 100,
  },
  {
    label: "Virtual Dataroom",
    value: "DATA-SUITE",
    active: true,
    description:
      "Virtual Datarooms for your RFP, Due Diligence, M&A and other usecases",
    width: 170,
  },
  {
    label: "Sales Suite",
    value: "SALES-SUITE",
    active: false,
    description:
      "Coming Soon - For outreach and Sales CRM situations, such as Sales Suites, Analyst Interactions etc",
    width: 200,
  },
];
const StyledRadioFormButton = styled(FormControlLabel)(
  ({ theme, ownerState }) => {
    return {
      background: theme.palette.background.paper,
      padding: 10,
      minWidth: 100,
      maxWidth: 150,
    };
  }
);

export default function SelectSubscriptionChoicesForm({
  formSubscriptionChoice,
  formSubscriptionTemplate,
  formCouponCode,
}) {
  const [couponSuccess, setCouponSuccess] = React.useState(false);
  const [couponHelperMessage, setCouponHelperMessage] = React.useState("");
  const [chosenPromoCode, setChosenPromoCode] = React.useState(null);
  const { watch, control, getValues } = useFormContext();
  const isMobile = useIsMobileBreakpoint();
  const {
    data: app21ProductsAndCouponsData,
    status: app21ProductsAndCouponsStatus,
  } = useApp21ProductsAndCoupons();

  const priceInfo = getSuitePrices(app21ProductsAndCouponsData.products);

  const promoCouponCodes = React.useMemo(
    () =>
      app21ProductsAndCouponsData ? app21ProductsAndCouponsData.coupons : null,
    [app21ProductsAndCouponsData]
  );

  const handleCheckCouponCode = () => {
    let couponCode = getValues(formCouponCode);
    let promoCodes;
    if (promoCouponCodes) {
      promoCodes = promoCouponCodes;
    } else {
      promoCodes = promoCouponCodes;
    }
    if (couponCode && couponCode?.length > 0) {
      let foundPromoCode = promoCodes
        ? promoCodes[(couponCode ?? "").toUpperCase()]
        : {};

      let today = new Date();
      let codeStillValid = isAfter(new Date(foundPromoCode?.validity), today);

      if (foundPromoCode && codeStillValid) {
        setCouponSuccess(true);
        setCouponHelperMessage(
          `Promo Code of ${100 * foundPromoCode.discount} % discount Applied`
        );
        setChosenPromoCode(foundPromoCode);
      } else {
        setCouponSuccess(false);
        setCouponHelperMessage(
          !foundPromoCode
            ? "Promo Code not found"
            : codeStillValid
            ? "Promo Code not Valid"
            : "Promo Code expired"
        );
      }
    }
  };
  const calculateSubscriptionPrice = () => {
    let originalPrice = 0;
    let discountedPrice = 0;
    const mainChoiceValue = watch(formSubscriptionChoice);
    const addonChoiceValue = watch(formSubscriptionTemplate);

    originalPrice = priceInfo[mainChoiceValue] + priceInfo[addonChoiceValue];

    if (chosenPromoCode) {
      discountedPrice = Math.round(
        originalPrice * (1 - chosenPromoCode.discount)
      );
    }
    return { originalPrice, discountedPrice };
  };

  const FinalPriceDisplay = () => {
    const price = calculateSubscriptionPrice();
    return (
      <Box sx={{ display: "flex" }}>
        <Typography
          variant="h6"
          sx={{
            textDecoration: couponSuccess ? "line-through" : "none",
            ml: 1,
          }}
        >
          $ {price.originalPrice.toFixed(0)}
        </Typography>
        {couponSuccess && (
          <Typography variant="h6" sx={{ ml: 1 }}>
            $ {price.discountedPrice.toFixed(0)}
          </Typography>
        )}
      </Box>
    );
  };
  const InfoCard = ({ title = "", subheader = "", sx = {} }) => {
    return (
      <Card
        elevation={0}
        sx={{
          borderRadius: 0,
          background: "transparent",
          boxShadow: "none",

          ...sx,
        }}
      >
        <CardHeader
          title={title}
          subheader={subheader}
          sx={{ m: 0, p: 0, background: "transparent" }}
        />
      </Card>
    );
  };
  if (app21ProductsAndCouponsStatus === "loading") {
    return <LoadingSpinner />;
  }
  return (
    <Grid
      container
      rowSpacing={{ xs: 3, sm: 4, md: 5 }}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      sx={{ my: 3 }}
    >
      <Grid
        item
        container
        direction={{ xs: "column", md: "row" }}
        xs={12}
        alignItems="center"
      >
        <Grid item xs={12} md={5} lg={4}>
          <Typography variant="h6">Choose your Subscription Level</Typography>
        </Grid>
        <Grid item xs={12} md={7} lg={8}>
          <Controller
            control={control}
            name={formSubscriptionChoice}
            render={({ field }) => {
              return (
                <RadioGroup row {...field}>
                  {mainChoice.map(
                    ({ label, value, active, description, price, width }) => {
                      return (
                        <Grid item xs="auto" key={value}>
                          <Tooltip title={description}>
                            <StyledRadioFormButton
                              value={value}
                              control={<Radio />}
                              disabled={!active}
                              sx={{ minWidth: width }}
                              label={
                                <InfoCard
                                  title={label}
                                  subheader={`$ ${priceInfo[value]?.toFixed(
                                    0
                                  )} ${active ? "" : "(Coming soon)"}`}
                                  sx={{ width: width - 20 }}
                                />
                              }
                            />
                          </Tooltip>
                        </Grid>
                      );
                    }
                  )}
                </RadioGroup>
              );
            }}
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction={{ xs: "column", md: "row" }}
        xs={12}
        alignItems="center"
      >
        <Grid item xs={12}>
          <Typography variant="h6">
            Would you like a particular suite Add-on Template for your specific
            Business conversation needs?
          </Typography>
        </Grid>
        <Grid item container xs={12} alignItems="center">
          <Controller
            control={control}
            name={formSubscriptionTemplate}
            render={({ field }) => {
              return (
                <RadioGroup row {...field}>
                  {addonChoice.map(
                    ({ label, value, active, description, price, width }) => {
                      return (
                        <Grid item xs="auto" key={value}>
                          <Tooltip title={description}>
                            <StyledRadioFormButton
                              value={value}
                              control={<Radio />}
                              disabled={!active}
                              sx={{ minWidth: width }}
                              label={
                                <InfoCard
                                  title={label}
                                  subheader={`+ $ ${priceInfo[value]?.toFixed(
                                    0
                                  )} ${active ? "" : "(Coming soon)"}`}
                                  sx={{ width: width - 20 }}
                                />
                              }
                            />
                          </Tooltip>
                        </Grid>
                      );
                    }
                  )}
                </RadioGroup>
              );
            }}
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction={{ xs: "column", md: "row" }}
        xs={12}
        alignItems="center"
      >
        <Grid item xs={12} md={5} lg={4}>
          <Typography variant="h6">
            (Optional) If you have a coupon Code, please enter it here:{" "}
          </Typography>
        </Grid>
        <Grid item xs={12} md={7} lg={8}>
          <Controller
            control={control}
            name={formCouponCode}
            render={({ field }) => {
              return (
                <TextField
                  size="small"
                  {...field}
                  sx={{ ml: 2 }}
                  helperText={couponHelperMessage}
                  FormHelperTextProps={{ error: !couponSuccess }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {isMobile ? (
                          <IconButton
                            aria-label="Apply Coupon Code"
                            onClick={handleCheckCouponCode}
                            onMouseDown={handleCheckCouponCode}
                            edge="end"
                          >
                            <Send />
                          </IconButton>
                        ) : (
                          <Button
                            aria-label="Apply Coupon Code"
                            onClick={handleCheckCouponCode}
                            onMouseDown={handleCheckCouponCode}
                            edge="end"
                          >
                            Apply
                          </Button>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              );
            }}
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction={{ xs: "column", md: "row" }}
        xs={12}
        alignItems="center"
      >
        <Grid item xs={12} md={5} lg={4}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Your Subscription price per month is:
          </Typography>
        </Grid>
        <Grid item xs={12} md={7} lg={8}>
          <Typography variant="h6">
            <FinalPriceDisplay />
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
