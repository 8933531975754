import React from 'react'
import { SvgIcon } from '@mui/material';

export default function FullIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M8.33334 54.2913V62.4997C8.33334 83.333 16.6667 91.6663 37.5 91.6663H62.5C83.3333 91.6663 91.6667 83.333 91.6667 62.4997V37.4997C91.6667 16.6663 83.3333 8.33301 62.5 8.33301H37.5C16.6667 8.33301 8.33334 16.6663 8.33334 37.4997"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M50 49.9995L25 74.9995"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M75 25.0005L62.5417 37.4588"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M75 41.6672V25.0005H58.3333"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25 58.3325V74.9992H41.6667"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
