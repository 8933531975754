import React from 'react'
import { SvgIcon } from '@mui/material';

export default function VolumeIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M62.5 30.874C62.5 18.4573 53.875 13.7073 43.375 20.2906L31.2083 27.9156C29.875 28.7073 28.3333 29.1656 26.7917 29.1656H20.8333C12.5 29.1656 8.33334 33.3323 8.33334 41.6656V58.3323C8.33334 66.6656 12.5 70.8323 20.8333 70.8323H26.7917C28.3333 70.8323 29.875 71.2906 31.2083 72.0823L43.375 79.7073C53.875 86.2906 62.5 81.499 62.5 69.124V47.7906"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M75 33.3335C82.4167 43.2085 82.4167 56.7918 75 66.6668"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M82.625 77.0833C88.6667 69.0417 91.6667 59.5417 91.6667 50"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M82.625 22.9165C85.0833 26.1665 87 29.6665 88.4583 33.3332"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
