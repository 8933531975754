// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { Fragment } from "react";
import { useRouter } from "next/router";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Divider,
  Button,
  IconButton,
  Box,
  Tooltip,
  Stack,
} from "@mui/material";
import Logo from "blocks/atoms/Logo";
import { Info, Today } from "@mui/icons-material";
import TopBarLayout from "blocks/views/Layouts/features/TopBarLayout";
import TopCenterbar from "blocks/views/Layouts/foundations/TopCenterbar";
import { Menu as MenuIcon } from "react-feather";
import { AuthUserAvatarMenu, LoadingSpinner } from "blocks/atoms";
import { useAppContext, useUserInfo } from "@app21/core";
import NotificationsPopup from "blocks/views/Notifications/features/NotificationsPopup";
import toast from "react-hot-toast";

import { UnreadChatMessagesSummaryPopup } from "blocks/views/ChatNotifiers";
import { useIsMobileBreakpoint } from "hooks";
import { menuActionTestIds } from "blocks/atoms/constants";

const DashboardTopbar = ({ className, ...rest }) => {
  const isMobile = useIsMobileBreakpoint();

  const {
    data: loggedInUser,
    status: loggedInUserStatus,
    apiError: loggedInUserApiError,
  } = useUserInfo();
  const { selectedSuiteId, meetingId, isBasicDataReady } = useAppContext();
  const router = useRouter();

  React.useEffect(() => {
    if (loggedInUserApiError && loggedInUserStatus != "loading") {
      toast.error(loggedInUserApiError.message);
    }
  }, [loggedInUserApiError]);

  const MiddlePanel = () => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexGrow: 1,
      }}
    >
      <TopCenterbar viewContext={"main"} />
    </div>
  );
  const RightPanel = () => (
    <Stack
      direction="row"
      spacing={isMobile ? 0 : 2}
      alignItems="center"
      justifyContent="space-between"
    >
      <UnreadChatMessagesSummaryPopup
        iconSize={"medium"}
        viewContext={"main"}
      />
      <NotificationsPopup
        viewContext={"main"}
        selectedSuiteId={selectedSuiteId}
      />
      {!meetingId &&
        (isMobile ? (
          <Tooltip title="I have received an Invite">
            <IconButton onClick={() => router.push("/meeting")}>
              <Today />
            </IconButton>
          </Tooltip>
        ) : (
          <Button
            data-testid={menuActionTestIds.joinUpcomingMeetingTestId}
            size="small"
            variant="outlined"
            color="gray"
            sx={{ ml: 1 }}
            startIcon={<Today />}
            onClick={() => router.push("/meeting")}
          >
            Meeting
          </Button>
        ))}
      <AuthUserAvatarMenu userInfo={loggedInUser} />
    </Stack>
  );
  if (isBasicDataReady) {
    return (
      <TopBarLayout
        viewContext="main"
        MainComponent={MiddlePanel}
        RightComponent={RightPanel}
        {...rest}
      />
    );
  } else return null;
};

DashboardTopbar.propTypes = {
  className: PropTypes.string,
};

export default DashboardTopbar;
