// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Chip, Avatar, IconButton } from "@mui/material";
import { NoteAdd } from "@mui/icons-material";
import { getS3ImageWrapper, checkIfNull } from "@app21/core";

import { useFileUpload } from "use-file-upload";
import ObjectID from "bson-objectid";
import clip from "text-clipper";
import path from "path";

const FileUploaderFormComponent = ({
  value,
  onChange,
  showFileAddIcon = true,
}) => {
  const [file, selectFile] = useFileUpload();
  let unchangedFiles =
    value?.filter(function (obj) {
      return !(
        Object.prototype.hasOwnProperty.call(obj, "added") ||
        Object.prototype.hasOwnProperty.call(obj, "deleted")
      );
    }) ?? [];
  let addedFiles = value?.find((obj) => !checkIfNull(obj.added))?.added || [];
  let deletedFiles =
    value?.find((obj) => !checkIfNull(obj.deleted))?.deleted || [];
  let filteredUnchangedFiles = unchangedFiles?.filter(
    (x) => !deletedFiles.includes(x.s3Key)
  );
  let newAddedFiles = addedFiles;

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {!checkIfNull(value) &&
        filteredUnchangedFiles.map((fileInfo, index) => (
          <Chip
            key={index}
            sx={{ m: 2 }}
            size="small"
            avatar={
              <Avatar
                src={getS3ImageWrapper(fileInfo?.image, "file")}
                variant="rounded"
                alt={fileInfo?.fileName}
              />
            }
            label={clip(path.basename(fileInfo.s3Key), 15, {
              breakWords: true,
            })}
            onDelete={() => {
              deletedFiles.push(fileInfo?.s3Key),
                onChange([
                  ...unchangedFiles,
                  { added: addedFiles },
                  { deleted: deletedFiles },
                ]);
            }}
          />
        ))}
      {newAddedFiles.map((addedFile, index) => (
        <Chip
          key={index}
          sx={{ m: 2 }}
          size="small"
          avatar={
            <Avatar
              src={getS3ImageWrapper("", "file")}
              variant="rounded"
              alt={addedFile?.name}
            />
          }
          label={clip(addedFile?.name, 25)}
          onDelete={() => {
            addedFiles = newAddedFiles.filter(
              (x) => x.tempS3Key !== addedFile?.tempS3Key
            );
            onChange([
              ...unchangedFiles,
              { added: addedFiles },
              { deleted: deletedFiles },
            ]);
          }}
        />
      ))}
      {showFileAddIcon && (
        <IconButton
          size="small"
          margin="dense"
          onClick={() => {
            // Single File Upload
            selectFile({}, ({ source, name, size, file }) => {
              // file - is the raw File Object
              addedFiles.push({
                source,
                name,
                size,
                file,
                tempS3Key: ObjectID().toHexString(),
              });
              onChange([
                ...unchangedFiles,
                { added: addedFiles },
                { deleted: deletedFiles },
              ]);
              // Todo: Upload to cloud.
            });
          }}
        >
          <NoteAdd />
        </IconButton>
      )}
    </div>
  );
};

export default FileUploaderFormComponent;
