import React from "react";
import { NoSsr, Button, Typography } from "@mui/material";
import {
  useVideocallContext,
  meetingConfig,
  ChimeStateProvider,
  ChimeErrorHandlerProvider,
  MeetingProviderWrapper,
} from "providers/TrrystVideocallProvider";
import {
  LoggerProvider,
  GlobalStyles,
} from "amazon-chime-sdk-component-library-react";
import {
  JoinMeetingWaitingRoom,
  MeetingView,
  InitiateMeeting,
} from "./features";
import { useHandleLeaveVideocall } from "providers/TrrystVideocallProvider/hooks";

import { ThemeProvider } from "styled-components";
import { demoLightTheme } from "./foundations/utils";
import { FlowBox } from "blocks/atoms/uistyles";

const Theme = ({ children }) => {
  return (
    <ThemeProvider theme={demoLightTheme}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
};

export default function ChimeMeeting() {
  const { chimeRoute } = useVideocallContext();
  const { leaveVideocall } = useHandleLeaveVideocall();
  const getViewComponent = (status) => {
    switch (status) {
      case "device":
      case "settings":
        return <JoinMeetingWaitingRoom />;
      case "meeting":
        return <MeetingView />;
      case "base":
        return <InitiateMeeting />;
      default:
        return (
          <FlowBox sx={{ justifyContent: "center", alignItems: "center" }}>
            <Typography variant="h4" color="textSecondary">
              No Active Meeting
            </Typography>
            <Button onClick={async () => await leaveVideocall({})}>
              Close
            </Button>
          </FlowBox>
        );
    }
  };
  return (
    <NoSsr>
      <FlowBox>
        <LoggerProvider logger={meetingConfig.logger}>
          <ChimeStateProvider>
            <Theme>
              <ChimeErrorHandlerProvider>
                <MeetingProviderWrapper>
                  {getViewComponent(chimeRoute)}
                </MeetingProviderWrapper>
              </ChimeErrorHandlerProvider>
            </Theme>
          </ChimeStateProvider>
        </LoggerProvider>
      </FlowBox>
    </NoSsr>
  );
}
