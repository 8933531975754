// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  Grid,
  Box,
  Typography,
  Chip,
  Avatar,
  Tooltip,
  List,
  IconButton,
  Paper,
} from "@mui/material";
import { AssignmentTurnedIn, Edit } from "@mui/icons-material";
import ProfilesChipsList from "blocks/atoms/ProfilesChipsList";
import {
  populateUsers,
  useUsersInSuite,
  useAppContext,
  getS3ImageWrapper,
  checkIfNull,
  useHandleRouterPush,
} from "@app21/core";
import { format } from "date-fns";

import clip from "text-clipper";
import ListResolutionDiscussions from "blocks/modules/Surveys/foundations/ListResolutionDiscussions";
import { constantCase } from "change-case";

import { useIsMobileBreakpoint } from "hooks";
import { getDateTimeFormat } from "utils/getDateFormats";

const SurveySummaryInfo = ({ surveyInfo, handleDetailsPanel }) => {
  const { selectedSuiteId, selectedDocketId, userInfo, checkAccessRules } =
    useAppContext();
  const { data: usersInSuite } = useUsersInSuite(selectedSuiteId);
  const { loadRoute } = useHandleRouterPush();
  const userResponseStatus = Boolean(
    surveyInfo?.responses && surveyInfo?.responses[userInfo._id]
  );
  const isMobile = useIsMobileBreakpoint();
  const { accessFlag: canAccessMembers } = checkAccessRules({
    entity: "USERS-VISIBILITY",
    action: "VIEW-ALL-SUITE-USERS",
    featureName: "SUITE-USER-DETAILS",
    isInvitee: true,
  });
  React.useEffect(() => {
    handleDetailsPanel(surveyInfo?.tableData?.id);
    return () => {
      handleDetailsPanel(null);
    };
  }, []);

  const ShowProfileChip = ({ invitee }) => {
    const hasUserResponded = Boolean(
      surveyInfo?.responses && surveyInfo?.responses[invitee?._id]
    );
    return (
      <Chip
        size={"small"}
        sx={{ m: 0 }}
        margin="dense"
        avatar={
          <Avatar
            alt={invitee?.fullName}
            src={getS3ImageWrapper(invitee?.image, "person")}
          />
        }
        label={
          <Typography variant="subtitle2">
            {clip(invitee?.fullName, 20, { breakWords: true })}
          </Typography>
        }
        variant="outlined"
        deleteIcon={
          <Tooltip title={`${invitee?.fullName} has responded`} arrow>
            <AssignmentTurnedIn style={{ color: "green" }} />
          </Tooltip>
        }
        onDelete={hasUserResponded ? () => console.log("click") : null}
      />
    );
  };
  return (
    <Paper
      elevation={0}
      sx={{
        margin: (theme) => theme.spacing(0),
        backgroundColor: "transparent",
        padding: (theme) => theme.spacing(0),
        marginTop: (theme) => theme.spacing(2.5),
      }}
    >
      <Grid container spacing={2} sx={{ p: 0.5 }}>
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: isMobile && "space-between",
            }}
          >
            <Typography variant="subtitle1" style={{ marginRight: 1 }}>
              Invitees
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection:
                  populateUsers(
                    surveyInfo?.survey?.decisionInvitees,
                    usersInSuite
                  ).length > 2 && isMobile
                    ? "column"
                    : "row",
              }}
            >
              {canAccessMembers ? (
                <ProfilesChipsList
                  usersList={populateUsers(
                    surveyInfo?.survey?.decisionInvitees,
                    usersInSuite
                  )}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="subtitle1" style={{ marginRight: 10 }}>
              Tabled Questions
            </Typography>
            <List dense disablePadding>
              <ListResolutionDiscussions resolution={surveyInfo} />
            </List>
          </div>
        </Grid>

        <Grid item xs={6}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="subtitle1" style={{ marginRight: 10 }}>
              Resolution Opened On:{" "}
            </Typography>
            {surveyInfo?.survey?.decisionOpenDate &&
              format(
                new Date(surveyInfo?.survey?.decisionOpenDate),
                getDateTimeFormat({
                  startDate: surveyInfo?.survey?.decisionOpenDate,
                  noTimeFlag: true,
                })
              )}
          </div>
        </Grid>

        <Grid item xs={6}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="subtitle1" style={{ marginRight: 10 }}>
              Resolution Closed on:{" "}
            </Typography>
            {surveyInfo?.survey?.decisionCloseDate &&
              format(
                new Date(surveyInfo?.survey?.decisionCloseDate),
                getDateTimeFormat({
                  startDate: surveyInfo?.survey?.decisionOpenDate,
                  noTimeFlag: true,
                })
              )}
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="subtitle1" style={{ marginRight: 10 }}>
              Responses so far -{" "}
            </Typography>
            {checkIfNull(surveyInfo.responses)
              ? "No responses recorded."
              : Object.keys(surveyInfo.responses).length}
          </div>
          {userResponseStatus &&
            constantCase(surveyInfo.survey?.decisionStatus ?? "") ===
              "OPEN" && (
              <Tooltip title="Edit my response" arrow>
                <IconButton
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    if (selectedDocketId) {
                      loadRoute("RESPOND-RESOLUTION", {
                        decisionId: surveyInfo._id,
                      });
                    } else {
                      loadRoute("RESPOND-SURVEY", {
                        decisionId: surveyInfo._id,
                      });
                    }
                  }}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SurveySummaryInfo;
