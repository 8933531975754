import React from 'react'
import { SvgIcon } from "@mui/material";

export default function UpdatedFilesIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M91.6671 33.334C91.6671 16.6673 83.3337 8.33398 66.6671 8.33398H33.3337C16.6671 8.33398 8.33374 16.6673 8.33374 33.334V87.5007C8.33374 89.7923 10.2087 91.6673 12.5004 91.6673H66.6671C83.3337 91.6673 91.6671 83.334 91.6671 66.6673V50.0007"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M64.6247 48.9164L67.333 46.208C67.4997 46.0414 67.6664 45.8747 67.833 45.6664C71.208 42.083 72.5414 37.8747 67.333 32.6664C61.833 27.1664 57.4997 28.958 53.7914 32.6664L32.1664 54.2914C31.333 55.1247 30.5414 56.7497 30.3747 57.9164L29.2081 66.1664C28.7914 69.1664 30.8747 71.2497 33.8747 70.833L42.1246 69.6664C43.2913 69.4997 44.9164 68.708 45.7497 67.8747L53.8331 59.7914"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M50.7073 35.749C52.5406 42.2907 57.6656 47.4574 64.2489 49.2907"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
