import React from "react";
import { Divider, Box, Typography, Chip } from "@mui/material";
import { Refresh } from "@mui/icons-material";
export default function FillerMessageCard({ message }) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Divider sx={{ my: 1, borderStyle: "dotted" }} />
      <Chip
        icon={<Refresh />}
        label={"More"}
        onClick={() => console.log(message)}
        sx={{ maxWidth: 100, alignSelf: "center" }}
      />
      <Divider sx={{ my: 1, borderStyle: "dotted" }} />
    </Box>
  );
}
