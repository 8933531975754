import React from "react";
import {
  MenuItem,
  IconButton,
  Box,
  Typography,
  TextField,
  Popover,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MenuPopover } from "blocks/atoms/uicomponents";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from "material-ui-popup-state/hooks";
import { FormProvider, useForm, Controller } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

export default function DateFilterRecordingsPopover() {
  const dateRecordingsPopupState = usePopupState({
    variant: "popover",
    popupId: "date-filter-recordings-popover",
  });

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      start: null,
      end: null,
    },
  });
  const { handleSubmit, control, watch, setError, errors } = methods;
  const onSubmit = (data) => {
    console.log("DATE TEST LOG", data);
  };
  return (
    <div>
      <MenuItem {...bindTrigger(dateRecordingsPopupState)}>
        Filter by Date
        <IconButton>
          <ArrowDropDownIcon />
        </IconButton>
      </MenuItem>
      <MenuPopover
        {...bindPopover(dateRecordingsPopupState)}
        id="date-filter-recordings-popover"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        arrow="top-center"
        sx={{ bgcolor: "background.default" }}
        placement="right-start"
      >
        <MenuItem>Last 7 Days (4)</MenuItem>
        <MenuItem>Last 14 Days (6)</MenuItem>
        <Box sx={{ p: 2 }}>
          <Typography variant="content">Custom Date Range</Typography>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Controller
                  name="start"
                  control={control}
                  rules={{
                    required: { value: true, message: "Required" },
                  }}
                  render={({ field: { ref, ...rest } }) => (
                    <DatePicker
                      margin="normal"
                      label="Start"
                      ampm={false}
                      ampmInClock
                      inputVariant={"outlined"}
                      format={"dd/MM/yyyy HH:mm"}
                      KeyboardButtonProps={{
                        "aria-label": "change date / time",
                      }}
                      sx={{
                        flexGrow: 1,
                        width: "100%",
                        p: 2,
                      }}
                      InputLabelProps={{
                        sx: {
                          color: "text.primary",
                          backgroundColor: "background.default",
                        },
                      }}
                      // componentsProps={{
                      //   textField: {
                      //     margin: "normal",
                      //     fullWidth: true,
                      //     error: !!errors?.start,
                      //     helperText: errors?.start?.message,
                      //     variant: "outlined",
                      //     InputProps: {
                      //       sx: {
                      //         backgroundColor: (theme) =>
                      //           theme.palette.background.default,
                      //       },
                      //     },
                      //   },
                      // }}
                      {...rest}
                    />
                  )}
                />
                <Controller
                  name="end"
                  control={control}
                  rules={{
                    required: { value: true, message: "Required" },
                  }}
                  render={({ field: { ref, ...rest } }) => (
                    <DatePicker
                      margin="normal"
                      label="End "
                      KeyboardButtonProps={{
                        "aria-label": "change date / time",
                      }}
                      strictCompareDates
                      sx={{
                        flexGrow: 1,
                        width: "100%",
                        p: 2,
                      }}
                      ampm={false}
                      ampmInClock
                      inputVariant={"outlined"}
                      format={"dd/MM/yyyy HH:mm"}
                      InputLabelProps={{
                        sx: {
                          color: (theme) => theme.palette.text.primary,
                          backgroundColor: (theme) =>
                            theme.palette.background.default,
                        },
                      }}
                      minDate={watch("start")}
                      onError={(error, value) => {
                        if (Boolean(error) && errors?.end?.message !== error) {
                          if (error === "minDate" || error === "minTime") {
                            setError("end", {
                              type: "manual",
                              message: "End of date should be after Start",
                            });
                          } else
                            setError("end", {
                              type: "manual",
                              message: error,
                            });
                        }
                      }}
                      // componentsProps={{
                      //   textField: {
                      //     margin: "normal",
                      //     fullWidth: true,
                      //     error: !!errors?.end,
                      //     helperText: errors?.end?.message,
                      //     variant: "outlined",
                      //     InputProps: {
                      //       sx: {
                      //         backgroundColor: (theme) =>
                      //           theme.palette.background.default,
                      //       },
                      //     },
                      //   },
                      // }}
                      {...rest}
                    />
                  )}
                />
              </LocalizationProvider>
            </form>
          </FormProvider>
        </Box>
      </MenuPopover>
    </div>
  );
}
