import React from "react";
import {
  Container,
  Box,
  Typography,
  Avatar,
  TextField,
  Grid,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { EditableImage, LoadingSpinner } from "blocks/atoms";
import { getS3ImageWrapper, useAppContext, useOrganization } from "@app21/core";
import isAlphanumeric from "validator/lib/isAlphanumeric";
import SelectOrgTextField from "blocks/modules/Admin/foundations/organizations/SelectOrgTextField";

export default function AddOrganizationStepForm({
  formOrganizationName = "organizationName",
  formOrganizationImage = "organizationImage",
}) {
  const { selectedOrganizationId } = useAppContext();
  const { data: selectedOrganization, status: selectedOrganizationStatus } =
    useOrganization(selectedOrganizationId);
  const isNewOrgFlag =
    !Boolean(selectedOrganizationId) || selectedOrganizationId === "addnew";

  const { watch, control, setValue, getValues, formState } = useFormContext();
  const { errors } = formState;
  if (selectedOrganizationStatus === "loading") {
    return <LoadingSpinner />;
  }
  return (
    <Container
      maxWidth="md"
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "40vh",
      }}
    >
      <Box
        sx={{
          p: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          my: 2,
        }}
      >
        {/* <Typography variant="h5" gutterBottom>
          {isNewOrgFlag ? "Enter Organization Details" : "Your Organization"}
        </Typography> */}
        {isNewOrgFlag ? (
          <Typography variant="body1" gutterBottom sx={{ textAlign: "center" }}>
            All Suites created will be within this Organization. You will be
            made the Adminstrator for this Organization.
          </Typography>
        ) : (
          <Typography variant="body1" gutterBottom sx={{ textAlign: "center" }}>
            Select the organization you wish to add the suite to
          </Typography>
        )}
      </Box>
      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={12} md={3} sx={{ alignSelf: "center" }}>
          <Typography variant="subtitle1">
            Select Organization for Suite
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <SelectOrgTextField
            showLabel={false}
            currentContext={"addsuite"}
            variant="standard"
            sx={{ width: "100%", mr: 2 }}
          />
        </Grid>
      </Grid>
      {isNewOrgFlag && (
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={12} md={3} sx={{ alignSelf: "center" }}>
            <Typography variant="subtitle1">Organization Name</Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Controller
              name={formOrganizationName}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "A name for the Organization is needed.",
                },
                maxLength: {
                  value: 50,
                  message: "Sorry. Too long. Limit to 25 characters",
                },
                minLength: {
                  value: 3,
                  message: "Too short. Minimum 3 characters",
                },
                validate: {
                  validText: (value) =>
                    isAlphanumeric(value, "en-US", {
                      ignore: "/^(?=.*[0-9])[- +()0-9]+$/",
                    }),
                },
              }}
              render={({ field }) => {
                return (
                  <TextField
                    fullWidth
                    error={!!(errors && errors[formOrganizationName])}
                    helperText={
                      errors &&
                      (errors[formOrganizationName]?.message ||
                        (errors?.fullName?.type === "validText" &&
                          "Special characters are not allowed"))
                    }
                    placeholder="What would you like to name your Organization?"
                    onChange={(event, newValue) => {
                      field.onChange(event.target.value);
                    }}
                    value={field.value}
                    sx={{ maxWidth: 550 }}
                  />
                );
              }}
            />
            {/* : (
            <TextField
              fullWidth
              id="ourganization-name"
              variant="outlined"
              disabled={true}
              value={selectedOrganization?.fullName}
            />
          )} */}
          </Grid>
        </Grid>
      )}
      {isNewOrgFlag && (
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={12} md={3} sx={{ alignSelf: "center" }}>
            <Typography variant="subtitle1">Organization Logo</Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Controller
              control={control}
              name={formOrganizationImage}
              render={({ field }) => {
                return (
                  <EditableImage
                    labelText="Upload Org Logo or Image"
                    currentImage={field.value}
                    handleFileUpload={field.onChange}
                    imageProps={{ sx: { height: 120, width: 120 } }}
                    StyledBoxProps={{
                      sx: {
                        height: 180,
                        width: 180,
                        mt: 2,
                        alignItems: "flex-start",
                      },
                    }}
                  />
                );
              }}
            />
            {/* ) : (
            <Avatar
              variant="rounded"
              sx={{ objectFit: "scale-down", height: 120, width: 120 }}
              src={getS3ImageWrapper(selectedOrganization?.image)}
            /> */}
          </Grid>
        </Grid>
      )}
    </Container>
  );
}
