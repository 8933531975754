// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
/* eslint-disable no-use-before-define */
import React, { useEffect } from "react";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import {
  useAppContext,
  checkIfAnyOrgAdmin,
  useNotificationsCount,
  useSuite,
  useUsersInSuite,
  getRolePrivileges,
} from "@app21/core";
import getNavbarItems from "blocks/views/Layouts/foundations/getNavbarItems";
import {
  Box,
  Drawer,
  List,
  ListSubheader,
  IconButton,
  Tooltip,
  menuClasses,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

import NavItem from "blocks/views/Layouts/foundations/NavItem";

import { LAYOUTWIDTHS } from "blocks/atoms/constants";

import { useIntl } from "react-intl";
import { Logo, SuiteTypeLabelView } from "blocks/atoms";
import PerfectScrollbar from "react-perfect-scrollbar";
import Label from "blocks/atoms/uicomponents/Label";
import { Icon } from "@iconify/react";
import { ArrowRightIcon, ArrowLeftIcon } from "blocks/atoms/trrysticons";
import { Info } from "@mui/icons-material";
import { useIsMobileBreakpoint } from "hooks";
// import { useChatContext } from "providers/ChatProvider";
import { useLayoutContext } from "providers/LayoutProvider";
import toast from "react-hot-toast";

const ListSubheaderStyle = styled((props) => (
  <ListSubheader
    sx={{ color: (theme) => theme.palette.gray.lighter }}
    disableSticky
    {...props}
  />
))(({ theme }) => ({
  ...theme.typography.overline,
  marginBottom: theme.spacing(0),
  paddingLeft: theme.spacing(5),
  color: theme.palette.text.secondary,
  paddingTop: 0,
}));

function renderNavItems({
  items,
  pathname,
  depth = 0,
  viewContext,
  isAdminInOrg = false,
  isTrrystOrGlobalAdmin = false,
  notificationsCount = 0,
  messagesCount = 0,
  checkAccessRules = () => null,
  usersInSuite = [],
  selectedSuite = {},
  userInfo = {},
  t = () => null,
  menuViewMode = "full",
}) {
  return (
    <List dense>
      {items.reduce(
        (acc, item) =>
          reduceChildRoutes({
            acc,
            item,
            pathname,
            depth,
            viewContext,
            checkAccessRules,
            usersInSuite,
            selectedSuite,
            userInfo,
            isAdminInOrg,
            isTrrystOrGlobalAdmin,
            notificationsCount,
            messagesCount,
            t,
            menuViewMode,
          }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth,
  viewContext,
  checkAccessRules,
  usersInSuite,
  selectedSuite,
  userInfo,
  isAdminInOrg,
  isTrrystOrGlobalAdmin,
  notificationsCount,
  messagesCount,
  t,
  menuViewMode,
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = item.href === pathname;
    if (item?.context === viewContext || item?.context === "all") {
      if (
        !item?.permissionKey ||
        item.permissionKey === "any" ||
        (item.permissionKey === "orgAdminCheck" && isAdminInOrg) ||
        (item.permissionKey === "trrystAdminCheck" && isTrrystOrGlobalAdmin) ||
        checkAccessRules({
          entity: "MENU",
          action: item.permissionKey,
          featureName: item.featureName,
          ...getRolePrivileges({
            userId: userInfo?._id,
            baseObject: selectedSuite,
            members: usersInSuite?.map((user) => user._id) ?? [],
          }),
        })?.accessFlag
      ) {
        acc.push(
          <NavItem
            depth={depth}
            icon={item.icon}
            info={item.info}
            secondAction={item.secondAction}
            avatar={item.avatar}
            key={key}
            open={Boolean(open)}
            title={item.title}
            menuLabel={item.menuLabel}
            notificationsCount={notificationsCount}
            menuViewMode={menuViewMode}
          >
            {renderNavItems({
              depth: depth + 1,
              pathname,
              items: item.items,
              viewContext: viewContext,
              checkAccessRules,
              usersInSuite,
              selectedSuite,
              userInfo,
              isAdminInOrg,
              isTrrystOrGlobalAdmin,
              notificationsCount,
              messagesCount,
              t,
              menuViewMode,
            })}
          </NavItem>
        );
      }
    }
  } else {
    if (item?.context === viewContext || item?.context === "all") {
      if (
        !item?.permissionKey ||
        item.permissionKey === "any" ||
        (item.permissionKey === "orgAdminCheck" && isAdminInOrg) ||
        (item.permissionKey === "trrystAdminCheck" && isTrrystOrGlobalAdmin) ||
        checkAccessRules({
          entity: "MENU",
          action: item.permissionKey,
          featureName: item.featureName,
          ...getRolePrivileges({
            userId: userInfo?._id,
            baseObject: selectedSuite,
            members: usersInSuite?.map((user) => user._id) ?? [],
          }),
        })?.accessFlag
      ) {
        acc.push(
          <NavItem
            depth={depth}
            href={item.href}
            route={item.route}
            icon={item.icon}
            info={item.info}
            secondAction={item.secondAction}
            avatar={item.avatar}
            key={key}
            title={item.title}
            menuLabel={item.menuLabel}
            notificationsCount={notificationsCount}
            messagesCount={messagesCount}
            menuViewMode={menuViewMode}
          />
        );
      }
    }
  }

  return acc;
}

const NavBarLayout = ({
  viewContext = "main",
  onMobileClose,
  openMobile,
  orgsAndSuites,
}) => {
  const router = useRouter();
  const isMobile = useIsMobileBreakpoint();

  const {
    userInfo = {},
    checkAccessRules,
    selectedSuiteId,
    selectedOrganizationId,
    selectedDocketId,
  } = useAppContext();
  const { menuViewMode, setMenuViewMode } = useLayoutContext();
  const messagesCount = 0; // const { unreadMessageCount: messagesCount } = useChatContext();

  const {
    data: selectedSuite,
    status: selectedSuiteStatus,
    apiError: selectedSuiteApiError,
  } = useSuite(selectedSuiteId);
  const {
    data: usersInSuite,
    status: usersInSuiteStatus,
    apiError: usersInSuiteApiError,
  } = useUsersInSuite(selectedSuiteId);

  const dataFetchError = usersInSuiteApiError || selectedSuiteApiError;
  const dataFetchLoading =
    selectedSuiteStatus === "loading" || usersInSuiteStatus === "loading";

  React.useEffect(() => {
    if (dataFetchError && !dataFetchLoading) {
      toast.error(dataFetchError.message);
    }
  }, [dataFetchError]);

  const isAdminInOrg = checkIfAnyOrgAdmin(
    orgsAndSuites,
    userInfo?._id,
    userInfo
  );

  const isTrrystOrGlobalAdmin =
    userInfo?.role?.toUpperCase() === "GLOBALADMIN" ||
    userInfo?.role?.toUpperCase() === "TRRYSTADMIN";
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });

  const handleMenuToggle = () =>
    setMenuViewMode((current) => (current === "full" ? "compact" : "full"));

  const sections = React.useCallback(
    () =>
      getNavbarItems({
        orgId: selectedOrganizationId,
        suiteId: selectedSuiteId,
        docketId: selectedDocketId,
        selectedSuite: selectedSuite,
        userInfo: userInfo,
        t: t,
      }),
    [
      selectedSuiteId,
      selectedOrganizationId,
      selectedDocketId,
      t,
      selectedSuite,
    ]
  );
  const overallNotificationsCount = useNotificationsCount({
    selectedSuiteId,
    selectedOrganizationId,
  });
  const theme = useTheme();

  let notificationsCount = overallNotificationsCount[viewContext] ?? 0;

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname]);

  const getSuiteDashboardViewComponent = (suiteType) => {
    switch (true) {
      case suiteType?.includes("DATA"):
        return theme.palette.dataSuite;
      case suiteType?.includes("EXEC"):
        return theme.palette.executiveSuite;
      case suiteType?.includes("PERSONAL"):
        return theme.palette.personalSuite;
      case suiteType?.includes("BOARD"):
        return theme.palette.boardSuite;
      default:
        return theme.palette.personalSuite;
    }
  };

  const content = () => (
    <Box
      sx={{
        height: "98vh",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          height: 150,
        }}
      >
        <Box
          sx={{
            display: "flex",
            pt: 1,
            pb: 1,
            flexGrow: 1,

            flexDirection: menuViewMode === "full" ? "row" : "column",
            color: (theme) => theme.palette.grey[0],
            alignItems: "center",
          }}
        >
          <Logo />
          {viewContext === "suite" && (
            <SuiteTypeLabelView
              suiteType={selectedSuite?.suiteType}
              menuViewMode={menuViewMode}
            />
          )}
        </Box>
        <Box
          sx={{
            position: "relative",
            my: 2,
            height: 50,
            width: "100%",
          }}
        >
          {menuViewMode !== "menuOnly" && (
            <Box
              sx={{
                display: "flex",

                color: (theme) => theme.palette.grey[0],
                position: "absolute",
                right: -11,
              }}
            >
              <IconButton
                onClick={handleMenuToggle}
                sx={{
                  zIndex: (theme) => theme.zIndex.drawer + 100,
                  display: "flex",
                  color: (theme) => theme.palette.primary.main,
                }}
              >
                {menuViewMode === "full" ? (
                  <ArrowLeftIcon />
                ) : (
                  <ArrowRightIcon />
                )}
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>
      <PerfectScrollbar
        options={{ suppressScrollX: true }}
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          overflowX: "hidden",
          bgcolor: getSuiteDashboardViewComponent(selectedSuite?.suiteType),
          color: (theme) => theme.palette.grey[0],
        }}
      >
        {sections().map((section, index) => (
          <List
            dense
            key={index}
            subheader={
              (!section.context ||
                section.context === viewContext ||
                section.context === "all") &&
              section.context !== "none" && (
                <ListSubheaderStyle>
                  {section.subheader}
                  {/*<ListSubheader disableGutters disableSticky className={classes.listSubheader}>
                  
              </ListSubheader>*/}
                </ListSubheaderStyle>
              )
            }
          >
            {renderNavItems({
              items: section.items,
              pathname: router.pathname,
              viewContext,
              isAdminInOrg,
              isTrrystOrGlobalAdmin,
              notificationsCount,
              messagesCount,
              checkAccessRules,
              selectedSuite,
              usersInSuite,
              userInfo,
              menuViewMode,
              t,
            })}
          </List>
        ))}
      </PerfectScrollbar>
    </Box>
  );

  if (menuViewMode === "menuOnly") {
    return (
      <Drawer
        anchor="left"
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
        PaperProps={{
          sx: {
            px: 2,
            width: LAYOUTWIDTHS.NavbarWidth,
            bgcolor: "background.paper",
            border: "none",
            height: "100vh",
          },
        }}
      >
        {content()}
      </Drawer>
    );
  } else {
    return (
      <Drawer
        anchor="left"
        elevation={0}
        open
        variant={"persistent"}
        SlideProps={{ timeout: { appear: 200, enter: 1500, exit: 2200 } }}
        sx={{
          border: "none",
          bgcolor: "background.paper",
          "&::-webkit-scrollbar": { display: "none" },
        }}
        PaperProps={{
          sx: {
            px: 2,
            border: "none",
            "&::-webkit-scrollbar": { display: "none" },
            width:
              menuViewMode === "compact"
                ? LAYOUTWIDTHS.NavbarCompactWidth
                : LAYOUTWIDTHS.NavbarWidth,
            bgcolor: "background.paper",

            maxHeight: "100vh",
          },
        }}
      >
        {content()}
      </Drawer>
    );
  }
};

NavBarLayout.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBarLayout;
