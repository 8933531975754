import React from "react";
import {
  Button,
  Typography,
  Menu,
  ListItemText,
  MenuItem,
  styled,
  alpha,
  useTheme,
} from "@mui/material";
import { StyledMenuPopover, StyledMenuItem } from "blocks/atoms";
import {
  usePopupState,
  bindToggle,
  bindMenu,
} from "material-ui-popup-state/hooks";
import {
  ChangeScreenWebInactiveIcon,
  ChangeScreenWebActiveIcon,
} from "blocks/atoms/trrystCustomIcons";
import { StyledSquareMenuButton } from "blocks/atoms/uistyles";

import { pxToRem } from "utils";
import { useAppContext } from "@app21/core";
import {
  layoutMenuOptions,
  layoutVisitorMenuOptions,
} from "blocks/modules/TrrystVideocall/foundations/components/ConferenceControls/LayoutChoiceControl/menuOptionsChoices";
import { chimeTestIds } from "blocks/atoms/constants";

export default function LayoutChoiceControl() {
  const { chimeViewMode, setChimeViewMode, isVisitorFlag } = useAppContext();
  const theme = useTheme();
  const layoutOptionsPopupState = usePopupState({
    variant: "popover",
    popupId: "layoutOptionsMenu",
  });
  const handleChangeLayout = (value) => {
    setChimeViewMode(value);
    layoutOptionsPopupState.close();
  };
  return (
    <>
      <StyledSquareMenuButton
        data-testid={chimeTestIds.layoutChoiceControl}
        {...bindToggle(layoutOptionsPopupState)}
        sx={{ borderRadius: 0, padding: 0 }}
      >
        {layoutOptionsPopupState.isOpen ? (
          <ChangeScreenWebActiveIcon
            color="primary"
            stroke={theme.palette.primary.contrastText}
            fontSize="large"
            sx={{ width: pxToRem(40), height: pxToRem(40) }}
          />
        ) : (
          <ChangeScreenWebInactiveIcon
            stroke={theme.palette.grey[600]}
            fontSize="large"
            sx={{
              width: pxToRem(40),
              height: pxToRem(40),
              color: (theme) => theme.palette.gray.light,
            }}
          />
        )}
      </StyledSquareMenuButton>
      {/* <Button
          {...bindToggle(layoutOptionsPopupState)}
          size="large"
          sx={{
            bgcolor: (theme) => theme.palette.gray.dark,
            p: 0,
            borderRadius: (theme) => theme.shape.borderRadius,
            "&:hover": {
              bgcolor: (theme) => theme.palette.gray.darker,
            },
          }}
        >
          <ChangeLayOutIcon
            fontSize="large"
            sx={{
              color: (theme) => theme.palette.gray.light,
            }}
          />
        </Button> */}
      <StyledMenuPopover
        arrow="bottom-center"
        {...bindMenu(layoutOptionsPopupState)}
        sx={{ maxWidth: pxToRem(100), minWidth: 100 }}
      >
        {(isVisitorFlag ? layoutVisitorMenuOptions : layoutMenuOptions).map(
          (choice, index) => {
            return (
              <StyledMenuItem
                key={index}
                onClick={() => handleChangeLayout(choice.value)}
                disabled={chimeViewMode === choice.value}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center",
                  p: 0.5,
                }}
              >
                {choice.icon}
                <ListItemText
                  primary={
                    <Typography variant="caption">{choice.label}</Typography>
                  }
                />
              </StyledMenuItem>
            );
          }
        )}
      </StyledMenuPopover>
    </>
  );
}
