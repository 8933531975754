import React from "react";
import {
  DocketUsageGuidancePanel,
  DocketEditForm,
} from "blocks/modules/Dockets";
import { useAppContext } from "@app21/core";
import { useDocketsContext } from "providers/DocketsProvider";
import { LoadingSpinner } from "blocks/atoms";
import { FlowBox } from "blocks/atoms/uistyles";
import DisplaySelectedDocket from "./DisplaySelectedDocket";

export default function DocketDetailsPanel() {
  const { selectedSuiteId, selectedOrganizationId, selectedDocketId } =
    useAppContext();

  const { allDockets, allDocketStatus, viewContext, selectedDocket } =
    useDocketsContext();

  const getCentralDisplayComponent = () => {
    if (allDocketStatus === "loading") {
      return <LoadingSpinner variant="exp" />;
    }

    switch (true) {
      case viewContext === "add": {
        return <DocketEditForm newDocket={true} />;
      }
      case allDockets?.length === 0: {
        return <DocketUsageGuidancePanel dockets={allDockets} />;
      }
      case !selectedDocketId: {
        return <DocketUsageGuidancePanel dockets={allDockets} />;
      }
      case viewContext === "edit": {
        return <DocketEditForm docket={selectedDocket} />;
      }
      case viewContext === "list": {
        return <DisplaySelectedDocket />; //<DocketUsageGuidancePanel dockets={allDockets} />;
      }
      default:
        return <DisplaySelectedDocket />; //<DocketConsolidatedView>{children}</DocketConsolidatedView>;
    }
  };

  return <FlowBox>{getCentralDisplayComponent()}</FlowBox>;
}
