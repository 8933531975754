import React from "react";
import { FlowBox } from "blocks/atoms/uistyles";
import { Button, Typography, Stack } from "@mui/material";
import { useAtom } from "jotai";
import { selectedUploadActionAtom } from "providers/FilesProvider/files-atoms";
import { useAppContext, useHandleRouterPush } from "@app21/core";

export default function ShowEmptyFolderPanel() {
  const [, setSelectedUploadAction] = useAtom(selectedUploadActionAtom);
  const { s3Prefix, decodedS3Prefix, selectedSuiteId, selectedOrganizationId } =
    useAppContext();
  const { loadRoute } = useHandleRouterPush();
  const isDocketHome =
    decodeURIComponent(s3Prefix) ===
    `${selectedOrganizationId}/${selectedSuiteId}/dockets/`;

  return (
    <FlowBox sx={{ justifyContent: "center", alignItems: "center" }}>
      <Typography variant="h6" sx={{ color: "text.secondary" }}>
        {isDocketHome
          ? "You do not have any breakouts. Create a Breakout group"
          : "This folder is empty. Upload files to get started."}
      </Typography>
      {isDocketHome ? (
        <Button
          variant="contained"
          onClick={() => loadRoute("CREATE-DOCKET", {})}
          sx={{ my: 3 }}
        >
          Goto Breakouts
        </Button>
      ) : (
        <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
          <Button
            onClick={() => setSelectedUploadAction("upload_files")}
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            Upload Files
          </Button>
          <Button
            onClick={() => setSelectedUploadAction("create_folder")}
            variant="outlined"
            color="primary"
            sx={{ mt: 2 }}
          >
            Create Folder
          </Button>
        </Stack>
      )}
    </FlowBox>
  );
}
