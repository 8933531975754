import React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

export default function ListAgendaItems({ agendaItems = [] }) {
  return (
    <Box sx={{ overflow: "auto" }}>
      <List>
        {agendaItems.map((agendaItem, index) => (
          <ListItem key={index} button>
            <ListItemIcon>
              <Typography variant="h6">{index + 1}.</Typography>
            </ListItemIcon>
            <ListItemText
              primary={agendaItem.what}
              secondary={
                <span>
                  {agendaItem.who} | {agendaItem.when}
                </span>
              }
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
