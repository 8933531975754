// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import PropTypes from "prop-types";
import {
  checkIfNull,
  useAppContext,
  useUsersInOrganization,
  useDockets,
} from "@app21/core";
import ProfilesChipsList from "blocks/atoms/ProfilesChipsList";

import { useIntl } from "react-intl";
import { useIsMobileBreakpoint } from "hooks";
import toast from "react-hot-toast";

const DocketInviteesView = ({ viewMode }) => {
  const { selectedOrganizationId, selectedDocketId, checkAccessRules } =
    useAppContext();
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });
  const isMobile = useIsMobileBreakpoint();

  const {
    data: usersInOrganization,
    status: usersInOrganizationStatus,
    apiError: usersInOrganizationApiError,
  } = useUsersInOrganization(selectedOrganizationId);

  const {
    data: selectedDocket,
    status: selectedDocketStatus,
    apiError: selectedDocketApiError,
  } = useDockets(selectedDocketId);

  const dataFetchError = selectedDocketApiError || usersInOrganizationApiError;
  const dataFetchLoading =
    usersInOrganizationStatus === "loading" ||
    selectedDocketStatus === "loading";

  React.useEffect(() => {
    if (dataFetchError && !dataFetchLoading) {
      toast.error(dataFetchError.message);
    }
  }, [dataFetchError]);
  const { accessFlag: canAccessMembers } = checkAccessRules({
    entity: "USERS-VISIBILITY",
    action: "VIEW-ALL-DOCKET-USERS",
    featureName: "DOCKET",
    isInvitee: true,
  });
  let populatedUserArray = [];
  if (
    !checkIfNull(selectedDocket?.members) &&
    typeof selectedDocket?.members[0] === "string"
  ) {
    selectedDocket?.members.forEach((userId) =>
      populatedUserArray.push(
        usersInOrganization?.find((user) => user._id === userId)
      )
    );
  } else {
    populatedUserArray = [
      ...populatedUserArray,
      ...(selectedDocket?.members || []),
    ];
  }

  if (
    !checkIfNull(selectedDocket?.visitors) &&
    Array.isArray(selectedDocket?.visitors)
  ) {
    populatedUserArray = [
      ...populatedUserArray,
      ...(selectedDocket?.visitors || []),
    ];
  }

  return (
    <div style={{ weight: "100%", height: "100%" }}>
      {canAccessMembers ? (
        <ProfilesChipsList
          usersList={populatedUserArray}
          dense={false}
          showAs={isMobile ? "avatar" : "chip"}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

DocketInviteesView.prototypes = {
  viewMode: PropTypes.string,
};

DocketInviteesView.defaultProps = {
  viewMode: "normal",
};

export default DocketInviteesView;
