import React from "react";
import { Typography, Box, styled, Avatar } from "@mui/material";

const MessageCountBoxStyle = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: "#FFFFFF",

  height: 20,
  width: 20,
  fontSize: 12,
}));

export default function MessageCountBox({ count }) {
  if (parseInt(count) > 0) {
    return <MessageCountBoxStyle>{count}</MessageCountBoxStyle>;
  } else return null;
}
