import React from 'react'
import { SvgIcon } from '@mui/material';

export default function MicOnIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M33.3318 41.7502V47.9168C33.3318 57.1252 40.7901 64.5835 49.9985 64.5835C59.2068 64.5835 66.6651 57.1252 66.6651 47.9168V25.0002C66.6651 15.7918 59.2068 8.3335 49.9985 8.3335C40.7901 8.3335 33.3318 15.7918 33.3318 25.0002"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.1234 40.208V47.2913C18.1234 64.8747 32.415 79.1663 49.9984 79.1663C67.5817 79.1663 81.8733 64.8747 81.8733 47.2913V40.208"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M44.2067 26.792C47.9567 25.417 52.0401 25.417 55.7901 26.792"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M46.6649 35.624C48.8733 35.0407 51.1649 35.0407 53.3733 35.624"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M49.9985 79.1665V91.6665"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
