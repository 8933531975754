// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Paper, Box } from "@mui/material";
import MoreChoicesMenuControl from "./MoreChoicesMenuControl";
import EndConferenceControl from "./EndConferenceControl";
//import MultiShareControl from "./MultiShareControl";
import { useAppContext } from "@app21/core";
import AudioInputControl from "./AudioInputControl";
import ContentShareControl from "./ContentShareControl";
import VideoInputControl from "./VideoInputControl";
import ChatMessageControl from "./ChatMessageControl";
import { isMobile } from "react-device-detect";
import { LAYOUTWIDTHS } from "blocks/atoms/constants";
//import LayoutChoiceControl from "./LayoutChoiceControl";
import MeetingInfoControl from "./MeetingInfoControl";
import RecordingControl from "./RecordingControl";
import { useVideocallContext } from "providers/TrrystVideocallProvider";

const ConferenceControlBar = () => {
  //const { isUserActive } = useUserActivityState();
  const { chimeViewMode } = useAppContext();
  const { recommendedButtonSize = 40 } = useVideocallContext();
  const bottomOffset = 5;
  const compactViewFlag = isMobile || chimeViewMode === "compact";
  return (
    <Paper
      elevation={0}
      sx={{
        flex: 1,
        width: "100%",
        opacity: 1,
        backgroundColor: "transparent",
        height: LAYOUTWIDTHS.chimeMenubarHeight - bottomOffset,
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        alignItems: "center",
        bottom: bottomOffset,
        zIndex: 2,
      }}
    >
      <Box
        sx={{
          maxWidth: 800,
          flexGrow: 1,
          display: "flex",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            gap:
              chimeViewMode === "full" || chimeViewMode === "featured"
                ? 3
                : compactViewFlag
                ? 1
                : 2,
            display: "flex",
            opacity: 1,
            width: "100%",
            flexGrow: 1,
            justifyContent: "center",
            transition: "opacity 250ms ease",
          }}
        >
          {/* <LayoutChoiceControl chimeViewMode={chimeViewMode} /> */}

          <AudioInputControl recommendedButtonSize={recommendedButtonSize} />
          <VideoInputControl recommendedButtonSize={recommendedButtonSize} />

          {!isMobile && (
            <ContentShareControl
              recommendedButtonSize={recommendedButtonSize}
            />
          )}
          {/* {process.env.STAGE !== "prod" && (
          <MultiShareControl chimeViewMode={chimeViewMode} />
        )} */}
          <ChatMessageControl recommendedButtonSize={recommendedButtonSize} />
          {/*<AudioOutputControl chimeViewMode={chimeViewMode} />*/}
          <RecordingControl recommendedButtonSize={recommendedButtonSize} />
          <MoreChoicesMenuControl
            chimeViewMode={chimeViewMode}
            recommendedButtonSize={recommendedButtonSize}
            compactViewFlag={compactViewFlag}
          />
          <Box
            sx={{
              flexGrow: 2,
              width: recommendedButtonSize,
              minWidth: 0.3 * recommendedButtonSize,
              maxWidth: 0.6 * recommendedButtonSize,
            }}
          />
          <EndConferenceControl recommendedButtonSize={recommendedButtonSize} />
          {!compactViewFlag && (
            <MeetingInfoControl
              chimeViewMode={chimeViewMode}
              recommendedButtonSize={recommendedButtonSize}
              compactViewFlag={compactViewFlag}
            />
          )}
        </Box>
      </Box>
    </Paper>
  );
};

export default ConferenceControlBar;
