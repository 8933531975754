import { SvgIcon } from "@mui/material";
import React from "react";
export default function InfoActiveWebIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 56 56"
      stroke="#FAD2A0"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <rect
          width="56"
          height="56"
          stroke="none"
          rx="5.6"
          fill="currentColor"
        />
        <path
          d="M28.0005 33.6006L28.0005 26.6006"
          strokeWidth="2.07495"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.8 19.601C15.05 21.939 14 24.851 14 28.001C14 35.729 20.272 42.001 28 42.001C35.728 42.001 42 35.729 42 28.001C42 20.273 35.728 14.001 28 14.001C25.998 14.001 24.08 14.421 22.358 15.191"
          strokeWidth="2.07495"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.0059 22.4004L27.9933 22.4004"
          strokeWidth="2.7666"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
