// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Paper } from "@mui/material";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useAppContext, useTasks, checkIfNull } from "@app21/core";

import { AddNewListTask, TasksListAccordion } from "blocks/modules/Tasks";
import TaskEditModal from "blocks/modules/Tasks/foundations/TaskEditModal";
import { LoadingSpinner } from "blocks/atoms";
import toast from "react-hot-toast";

const TaskBoardListView = ({
  context,
  handleEditTaskDetails,
  handleTaskEditClick,
  handleClose,
}) => {
  const { selectedDocketId, selectedSuiteId } = useAppContext();

  const contextId = context === "docket" ? selectedDocketId : selectedSuiteId;

  const {
    data: tasks,
    status: tasksStatus,
    apiError: taskApiError,
  } = useTasks({
    objectId: contextId,
    idType: context === "docket" ? "docket" : "mytasks",
  });

  const tasksList = tasks?.allTasks || [];
  const [expandedTaskId, setExpandedTaskId] = React.useState(null);
  const [editTaskDetails, setEditTaskDetails] = React.useState(null);
  //
  // const [addAssigneeCheck, setAddAssigneeCheck] = React.useState(false);
  //
  // const [isOpened, setIsOpened] = React.useState(false);
  // const [collapseCheck, setCollapseCheck] = React.useState('');

  const handleExpandedTaskId = (taskId) => (event, isExpanded) => {
    setExpandedTaskId(isExpanded ? taskId : null);
  };

  const handleEditModalClose = () => {
    setEditTaskDetails(null);
  };

  React.useEffect(() => {
    if (taskApiError && tasksStatus != "loading") {
      toast.error(taskApiError.message, {
        appearance: "error",
        autoDismiss: false,
      });
    }
  }, [taskApiError]);

  if (tasksStatus === "loading") {
    return <LoadingSpinner />;
  }
  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        overflowY: "auto",
        justifyContent: "center",
        pt: 1,
        mt: 1,
      }}
    >
      <AddNewListTask handleEditTaskDetails={handleEditTaskDetails} />
      <TasksListAccordion
        tasksList={tasksList}
        expandedTaskId={expandedTaskId}
        handleExpandedTaskId={handleExpandedTaskId}
        handleEditTaskDetails={handleEditTaskDetails}
        handleTaskEditClick={handleTaskEditClick}
        handleClose={handleClose}
      />

      {/*taskList.map((taskData, index) => {
          return (
            <Card sx={{ mx: 2, my: 2 }} onClick={() => handleCollapseCheck(`collapse${index}`)}>
              <ListItemButton onClick={handleCollapse}>
                <ListItemIcon>
                  <UserAvatar userId={taskData.createdBy} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant={collapseCheck == `collapse${index}` ? 'h5' : 'h6'}>
                      {taskData.title}
                    </Typography>
                  }
                />
                {collapseCheck == `collapse${index}` ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Divider />
              <Collapse in={collapseCheck == `collapse${index}`} timeout="auto" unmountOnExit>
                <ListItemButton>
                  <ListItemText
                    primary={
                      <Typography variant="h6">{`TASK DUE DATE : ${taskData.dueBy}`.substring(0, 26)}</Typography>
                    }
                  />
                  <IconButton>
                    <EditIcon fontSize="large" color="primary" onClick={() => openEditTask(taskData)} />
                  </IconButton>
                </ListItemButton>

                <Box sx={{ display: 'flex', alignItems: 'center', mx: 2, mb: 2 }}>
                  <Typography variant="subtitle1">{'TASK ASSIGNEES : '}</Typography>
                  {taskData.assignees ? (
                    <AvatarGroup
                      max={5}
                      componentsProps={{
                        additionalAvatar: {
                          sx: { height: 30, width: 30 },
                        },
                      }}
                    >
                      {taskData.assignees.map((e, index) => (
                        <UserAvatar userId={e} key={index} alt={'img'} size={30} />
                      ))}
                    </AvatarGroup>
                  ) : (
                    <AccountCircle />
                  )}

                  <Typography variant="h6" sx={{ mx: 2 }}>{`Description : ${
                    taskData.description != undefined ? taskData.description : ' '
                  }`}</Typography>
                </Box>
              </Collapse>
            </Card>
          );
        })*/}

      {!checkIfNull(editTaskDetails) && (
        <TaskEditModal
          taskId={null}
          open={Boolean(editTaskDetails)}
          onClose={handleEditModalClose}
          taskData={editTaskDetails}
          context={context}
        />
      )}
    </Paper>
  );
};

// TaskBoardListView.propTypes = {
//   taskData: PropTypes.object.isRequired,
//   handleTaskClick: PropTypes.func.isRequired,
//   handleAddTask: PropTypes.func.isRequired,
// };

export default TaskBoardListView;
