import {
  Box,
  Select,
  MenuItem,
  FormControl,
  useTheme,
  OutlinedInput,
  IconButton,
} from "@mui/material";
import React from "react";
import { useChatContext } from "providers/TrrystChatProvider";
import { baseMenuOptions, moreMenuOptions } from "./ContextMenuOptions";
import { ArrowDropDown } from "@mui/icons-material";

export default function SelectMoreChatContextsDropdown() {
  const { viewChatContext, handleChangeViewContext } = useChatContext();
  const [dropdownOpenFlag, setDropdownOpenFlag] = React.useState(false);
  const allMenuOptions = [...moreMenuOptions];
  const modChatContext =
    (baseMenuOptions.map((t) => t.value).includes(viewChatContext)
      ? ""
      : viewChatContext) ?? "";
  let valuesSet = new Set(allMenuOptions.map((item) => item.value));
  const theme = useTheme();

  const menuProps = {
    sx: {
      mt: 1.2,
      "& .MuiMenu-paper": {
        borderRadius: theme.shape.borderRadiusSm,
        mt: 1,
      },
      "& .MuiMenu-list": {
        paddingTop: 0,
        paddingBottom: 0,
        background: theme.palette.primary.lighter,
        "& li": {
          pt: 1,
          pb: 1,
        },
        "& li:hover": {
          background: theme.palette.primary.light,
        },
        "& li.Mui-selected": {
          color: "white",
          background: theme.palette.primary.main,
        },
        "& li.Mui-selected:hover": {
          background: theme.palette.primary.dark,
        },
      },
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "right",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "right",
    },
  };

  return (
    <FormControl sx={{ ml: 1 }}>
      <Select
        displayEmpty
        MenuProps={menuProps}
        value={modChatContext}
        variant="outlined"
        input={<OutlinedInput />}
        open={dropdownOpenFlag}
        onOpen={(event) => {
          event.preventDefault();
          event.stopPropagation();
          setDropdownOpenFlag(true);
        }}
        onClose={(event) => {
          event.preventDefault();
          event.stopPropagation();
          setDropdownOpenFlag(false);
        }}
        onChange={(event) => {
          event.preventDefault();
          event.stopPropagation();
          handleChangeViewContext(event.target.value);
        }}
        IconComponent={ArrowDropDown}
        renderValue={(selected) => {
          if (typeof selected === "string") {
            if (valuesSet.has(selected)) {
              return allMenuOptions.find((option) => option.value === selected)
                .label;
            } else return "More";
          } else if (valuesSet.has(selected?.target?.value)) {
            return allMenuOptions.find(
              (option) => option.value === selected.target.value
            ).label;
          } else return "More";
        }}
        SelectDisplayProps={{
          style: {
            borderRadius: 20,
          },
        }}
        inputProps={{
          "aria-label": "Without label",
          style: { borderRadius: 20 },
        }}
        size="small"
        sx={{
          borderRadius: 20,
          "&.MuiInputBase-colorPrimary": {
            borderRadius: 20,
          },
          "& .MuiSelect-icon ": {
            color: valuesSet.has(viewChatContext)
              ? theme.palette.primary.contrastText
              : theme.palette.text.primary,
          },

          height: "2.2rem",
          background: valuesSet.has(viewChatContext)
            ? theme.palette.primary.main
            : theme.palette.grey[300],
          color: valuesSet.has(viewChatContext)
            ? theme.palette.primary.contrastText
            : theme.palette.text.primary,

          fontWeight: 200,
          borderStyle: "none",
          borderWidth: 1,
          borderRadius: `${theme.shape.borderRadiusSm}px`,
          fontSize: 13,
          "&:selected": {
            borderRadius: 12,
            background: "white",
            outline: "none",
          },
          "&:focus": {
            borderRadius: 12,
            background: "white",
            outline: "none",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "& .MuiSelect-iconOpen .MuiSelect-iconOutlined .MuiSelect-icon": {
            color: (theme) =>
              `${theme.palette.primary.contrastText} !important`,
          },
        }}
      >
        {allMenuOptions.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
