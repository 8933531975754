// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
export default function processSurveyData(
  questionsInfo,
  answersInfo,
  usersInSuite = []
) {
  let data = [];

  questionsInfo?.forEach((question, qnIndex) => {
    let modQuestionObj = {};
    modQuestionObj.totalResponsesCount = answersInfo.length;
    modQuestionObj.questionItem = question?.questionItem;
    modQuestionObj.answerChoices = question?.answerChoices.map((answer) => ({
      choice: answer.choice,
      count: 0,
    }));
    modQuestionObj.commentsBoxQuestion = question?.commentsBoxQuestion;
    modQuestionObj.answers = [];
    answersInfo.forEach((answer, ansIndex) => {
      let answerObj = {};
      answerObj.respondent = usersInSuite?.find(
        (user) => user._id === answer.respondentId
      );
      answerObj.answer = answer.answers[`question${qnIndex}`];
      modQuestionObj.answers.push(answerObj);
      const foundAnwerChoiceIndex = modQuestionObj.answerChoices.findIndex(
        (answerChoice) =>
          answerChoice.choice === answer.answers[`question${qnIndex}`].choice
      );
      if (foundAnwerChoiceIndex > -1) {
        modQuestionObj.answerChoices[foundAnwerChoiceIndex].count++;
      }
    });
    data.push(modQuestionObj);
  });
  return data;
}
