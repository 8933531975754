// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import { Typography, useMediaQuery, Box } from "@mui/material";
import { useAppContext, checkIfNull } from "@app21/core";
import * as React from "react";
import UserAvatarList from "blocks/views/SuiteDashboard/foundations/UserAvatarList";
import ShowPathCrumbs from "blocks/views/Layouts/features/ShowPathCrumbs";

const TopCenterbar = ({ viewContext, showPathCrumbs }) => {
  const { userInfo = {} } = useAppContext();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  if (checkIfNull(userInfo)) return null;
  return (
    <div style={{ flexGrow: 1, display: "flex" }}>
      <div
        style={{
          width: "100%",
          opacity: 0.95,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: 60,
        }}
      >
        {viewContext === "suite" ? (
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {showPathCrumbs ? <ShowPathCrumbs /> : <div />}
            {/*<UserAvatarList />*/}
          </Box>
        ) : (
          <Typography
            variant={isMobile ? "subtitle1" : "h6"}
            color="text.primary"
            fontWeight="bold"
          >
            Welcome {userInfo?.fullName}.
          </Typography>
        )}
      </div>
    </div>
  );
};

export default TopCenterbar;
