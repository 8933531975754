// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import {
  PlanFreeIcon,
  PlanStarterIcon,
  PlanPremiumIcon,
} from "blocks/atoms/graphics";

export const subscriptionOptions = [
  {
    subscription: "PERSONAL-SUITE-BASIC",
    icon: <PlanFreeIcon />,
    free: true,
    beta: false,
    price: 0,
    caption: "For personal use",
    lists: [
      { text: "50 hours of free Trryst calls", isAvailable: true },
      { text: "100 GB Secure Data vault", isAvailable: true },
      { text: "Scheduler", isAvailable: true },
      { text: "Recording", isAvailable: false },
      { text: "Transcription & Search", isAvailable: false },
      { text: "2-factor Authentication", isAvailable: false },
    ],
    labelAction: "Sign Up",
    actionEnabled: true,
  },
  {
    subscription: "PERSONAL-SUITE-PRO",
    icon: <PlanStarterIcon />,
    free: false,
    beta: true,
    price: 9.99,
    caption: "Unlock the full potential of Trryst",
    lists: [
      { text: "Invite upto 5 friends & family", isAvailable: true },
      { text: "250 hours of included Trryst calls", isAvailable: true },
      { text: "500 GB Secure Data vault", isAvailable: true },
      { text: "Tasks, Meetings, Surveys", isAvailable: true },
      { text: "Recording", isAvailable: true },
      { text: "Transcription & Search", isAvailable: true },
      { text: "2-factor Authentication", isAvailable: false },
    ],
    labelAction: "Upgrade",
    actionEnabled: true,
  },
  {
    subscription: "BOARD-SUITE-BASIC",
    icon: <PlanPremiumIcon />,
    free: false,
    beta: true,
    price: 69.99,
    caption: "Tailored for Boardroom interactions",
    lists: [
      { text: "Upto 25 Suite members", isAvailable: true },
      { text: "1000 hours of included Trryst Calls", isAvailable: true },
      { text: "1 TB Secure Data vault", isAvailable: true },
      { text: "Resolutions, Minutes, Actions, Scheduler", isAvailable: true },
      { text: "Recording & Transcriptions", isAvailable: true },
      { text: "Search Recordings", isAvailable: true },
      { text: "2FA & Additional Security", isAvailable: false },
    ],
    labelAction: "Select",
    actionEnabled: true,
  },
  {
    subscription: "BOARD-SUITE-PRO",
    icon: <PlanPremiumIcon />,
    free: false,
    beta: true,
    price: 199.99,
    caption: "for Turbocharged boardrooms",
    lists: [
      { text: "Unlimited Suite members", isAvailable: true },
      { text: "Unlimited team Videocalls", isAvailable: true },
      { text: "10 TB Secure Data vault", isAvailable: true },
      { text: "All of Basic plus tailored security", isAvailable: true },
      { text: "Recording", isAvailable: true },
      { text: "Transcription & Search", isAvailable: true },
      { text: "2FA & Additional Security", isAvailable: true },
    ],
    labelAction: "Select",
    actionEnabled: true,
  },
  {
    subscription: "EXEC-SUITE-BASIC",
    icon: <PlanPremiumIcon />,
    free: false,
    beta: true,
    price: 29.99,
    caption: "Boundaryless conversations for your executives",
    lists: [
      { text: "Upto 20 Suite members", isAvailable: true },
      { text: "1000 hours of included Trryst Calls", isAvailable: true },
      { text: "1 TB Secure Data vault", isAvailable: true },
      { text: "Surveys, Tasks, Scheduler", isAvailable: true },
      { text: "Recording & Transcriptions", isAvailable: true },
      { text: "Search Recordings", isAvailable: false },
      { text: "2FA & Additional Security", isAvailable: false },
    ],
    labelAction: "Select",
    actionEnabled: true,
  },
  {
    subscription: "EXEC-SUITE-PRO",
    icon: <PlanPremiumIcon />,
    free: false,
    beta: true,
    price: 59.99,
    caption: "for large enterprises",
    lists: [
      { text: "Upto 100 Suite members", isAvailable: true },
      { text: "Unlimited team Videocalls", isAvailable: true },
      { text: "10 TB Secure Data vault", isAvailable: true },
      { text: "All in Basic plus Dataroom features", isAvailable: true },
      { text: "Recording", isAvailable: true },
      { text: "Transcription & Search", isAvailable: true },
      { text: "2FA & Additional Security", isAvailable: true },
    ],
    labelAction: "Select",
    actionEnabled: true,
  },
  {
    subscription: "DATA-SUITE-BASIC",
    icon: <PlanPremiumIcon />,
    free: false,
    beta: true,
    price: 69.99,
    caption: "Tailored for Data Room interactions",
    lists: [
      { text: "Upto 25 Suite members", isAvailable: true },
      { text: "1000 hours of included Trryst Calls", isAvailable: true },
      { text: "1 TB Secure Data vault", isAvailable: true },
      {
        text: "Resolutions, Minutes, Actions, Board Meeting Scheduler",
        isAvailable: true,
      },
      { text: "Recording & Transcriptions", isAvailable: true },
      { text: "Search Recordings", isAvailable: true },
      { text: "2FA & Additional Security", isAvailable: false },
    ],
    labelAction: "Select",
    actionEnabled: true,
  },
  {
    subscription: "DATA-SUITE-PRO",
    icon: <PlanPremiumIcon />,
    free: false,
    beta: true,
    price: 69.99,
    caption: "Tailored for Data Room interactions",
    lists: [
      { text: "Upto 25 Suite members", isAvailable: true },
      { text: "1000 hours of included Trryst Calls", isAvailable: true },
      { text: "1 TB Secure Data vault", isAvailable: true },
      {
        text: "Resolutions, Minutes, Actions, Board Meeting Scheduler",
        isAvailable: true,
      },
      { text: "Recording & Transcriptions", isAvailable: true },
      { text: "Search Recordings", isAvailable: true },
      { text: "2FA & Additional Security", isAvailable: false },
    ],
    labelAction: "Select",
    actionEnabled: true,
  },
];

const getSubscriptionPlanInfo = (option) => {
  const planOption = subscriptionOptions.find(
    (subOption) => subOption.subscription === option
  );
  return planOption;
};
export default getSubscriptionPlanInfo;
