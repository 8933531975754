// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from "react";
import { isMobile } from "react-device-detect";
import {
  useContentShareState,
  useContentShareControls,
} from "amazon-chime-sdk-component-library-react";
import { pxToRem } from "utils";
import {
  useAppContext,
  useMeeting,
  useHandleMeetingActions,
} from "@app21/core";
import { useVideocallContext } from "providers/TrrystVideocallProvider";
import { handleToggleCollaboration } from "blocks/atoms/handlers";

import { ScreenShare as ScreenShareIcon } from "@mui/icons-material";
import { Button, Tooltip, useTheme, Box } from "@mui/material";
import {
  ShareScreenWebInactiveIcon,
  ShareScreenWebActiveIcon,
} from "blocks/atoms/trrystCustomIcons";
import { StyledSquareMenuButton } from "blocks/atoms/uistyles";
import { chimeTestIds } from "blocks/atoms/constants";

const ContentShareControl = ({ recommendedButtonSize = 40 }) => {
  const { isLocalUserSharing } = useContentShareState();
  const { meetingId, userInfo, setChimeViewMode } = useAppContext();
  const { handleMeetingActions } = useHandleMeetingActions();

  const {
    data: meeting,
    status: meetingStatus,
    apiError: meetingApiError,
  } = useMeeting(meetingId);
  const { isCollaborationActive, setIsCollaborationActive } =
    useVideocallContext();

  const { paused, toggleContentShare, togglePauseContentShare } =
    useContentShareControls();
  const theme = useTheme();
  const handleContentShare = async () => {
    const collaborationParams = {
      userInfo,
      meeting,
      handleMeetingActions,
      setIsCollaborationActive,
      setChimeViewMode,
      meetingId,
    };
    if (isCollaborationActive) {
      handleToggleCollaboration({
        type: null,
        action: "STOP-COLLABORATION",
        ...collaborationParams,
      });
      //   setIsCollaborationActive(false);
    }

    toggleContentShare();
  };
  /*const dropdownOptions: PopOverItemProps[] = [
    {
      children: <span>{paused ? unpauseLabel : pauseLabel}</span>,
      onClick: togglePauseContentShare,
    },
  ];*/
  const MessageWrapper = ({ children }) => {
    if (isMobile)
      return (
        <Tooltip title="Screenshare not enabled for mobile devices" arrow>
          {children}
        </Tooltip>
      );
    return children;
  };

  return (
    <StyledSquareMenuButton
      data-testid={chimeTestIds.contentShareControl}
      onClick={handleContentShare}
      size="small"
      sx={{
        width: recommendedButtonSize,
        height: recommendedButtonSize,
        maxWidth: recommendedButtonSize,
        maxHeight: recommendedButtonSize,
        minWidth: recommendedButtonSize,
        minHeight: recommendedButtonSize,
      }}
    >
      {isLocalUserSharing ? (
        <ShareScreenWebActiveIcon
          color="primary"
          stroke={theme.palette.primary.contrastText}
          fontSize="large"
          sx={{
            width: recommendedButtonSize,
            height: recommendedButtonSize,

            maxWidth: pxToRem(40),
            maxHeight: pxToRem(40),
            "&:hover": {
              color: "primary.dark",
            },
          }}
        />
      ) : (
        <MessageWrapper>
          <ShareScreenWebInactiveIcon
            stroke={theme.palette.grey[600]}
            fontSize="large"
            sx={{
              width: recommendedButtonSize,
              height: recommendedButtonSize,

              maxWidth: pxToRem(40),
              maxHeight: pxToRem(40),
              color: (theme) => theme.palette.gray.lighter,
              "&:hover": {
                color: "gray.light",
              },
            }}
          />
        </MessageWrapper>
      )}
    </StyledSquareMenuButton>
  );
};

export default ContentShareControl;

{
  /*<ControlBarButton
        icon={<ScreenShare />}
        onClick={toggleContentShare}
        label={label}
        popOver={isLocalUserSharing ? dropdownOptions : null}
      />
 (
      //   <Box
      //     sx={{
      //       display: "flex",
      //       border: `2px solid ${theme.palette.primary.main}`,
      //       padding: 0,
      //       borderColor: "primary.main",
      //       bgcolor: "background.paper",
      //       maxHeight: 50,
      //       alignSelf: "center",
      //     }}
      //   >
      //     <Button
      //       size="small"
      //       onClick={togglePauseContentShare}
      //       sx={{ borderRadius: 0, padding: 0, bgcolor: "" }}
      //     >
      //       {paused ? (
      //         <PlayCircleOutline fontSize="large" />
      //       ) : (
      //         <PausePresentation fontSize="large" />
      //       )}
      //     </Button>
      //     <Button
      //       size="small"
      //       onClick={() => toggleContentShare()}
      //       sx={{ borderRadius: 0, padding: 0 }}
      //     >
      //       <StopScreenShare fontSize="large" />
      //     </Button>
      //   </Box>
      // ) : isMobile ? (
      //   <Tooltip title="Screenshare not enabled for mobile devices" arrow>
      //     <span>
      //       <Button
      //         disabled
      //         size={chimeViewMode === "compact" ? "small" : "medium"}
      //         sx={{ borderRadius: 0, padding: 0 }}
      //       >
      //         <ScreenShareIcon fontSize="inherit" />
      //       </Button>
      //     </span>
      //   </Tooltip>
      // ) */
}
