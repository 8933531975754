// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Link } from "@mui/icons-material";
import {
  Paper,
  Box,
  Typography,
  MenuItem,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  IconButton,
} from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Icon } from "@iconify/react";
import toast from "react-hot-toast";

const CopyMeetingLinkControl = ({
  componentType = "panel",
  meetingId,
  size = "small",
  handleComplete = () => {
    /** */
  },
}) => {
  const shapedLinkUrl = `${process.env.ROOT_URL}/meeting?meetingId=${meetingId}`;

  const [copyState, setCopyState] = React.useState({
    value: shapedLinkUrl,
    copied: false,
  });

  const onCopy = (props, result) => {
    setCopyState({ ...copyState, copied: result });
    if (props && result) {
      toast.success("Meeting Link copied. Ctrl-V to paste");
    } else
      toast.error(
        `Sorry. There was an error in copying this meeting link to your clipboard --  ${props}`
      );

    handleComplete();
  };
  const MeetingLinkClipboardPanel = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <Typography variant="subtitle2" sx={{ my: 1 }}>
        Meeting link for Invitees.
      </Typography>
      <CopyToClipboard text={copyState.value} onCopy={onCopy}>
        <Tooltip title="Tap to Copy" arrow>
          <Paper
            elevation={2}
            sx={{
              display: "flex",
              p: 2,
            }}
          >
            <Typography
              variant="caption"
              sx={{
                flexGrow: 1,
                fontWeight: "bold",

                wordWrap: "break-word",
                overflowWrap: "break-word",
              }}
            >
              {shapedLinkUrl}
            </Typography>
            <Link sx={{ flexShrink: 0 }} />
          </Paper>
        </Tooltip>
      </CopyToClipboard>
    </Box>
  );

  switch (componentType) {
    case "icon":
      return (
        <CopyToClipboard text={copyState.value} onCopy={onCopy}>
          <Tooltip title="Copy meeting link" arrow>
            <IconButton size={size}>
              <Link fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </CopyToClipboard>
      );
    case "panel":
      return <MeetingLinkClipboardPanel />;
    case "menuitem":
      return (
        <CopyToClipboard text={copyState.value} onCopy={onCopy}>
          <MenuItem>
            <ListItemIcon>
              <Link />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  Copy Meeting Link
                </Typography>
              }
            />
          </MenuItem>
        </CopyToClipboard>
      );
    default:
      return <MeetingLinkClipboardPanel />;
  }
};

export default CopyMeetingLinkControl;
