import React from 'react'
import { SvgIcon } from "@mui/material";

export default function SurveyFinishedIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 200 200"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M3.79114 77.9896L63.1289 43.6943C68.0525 40.8929 75.9472 40.8929 80.7859 43.6943L140.209 77.9896C145.047 80.791 145.047 85.375 140.209 88.1764L80.7859 122.387C75.9472 125.273 68.0525 125.273 63.1289 122.387L3.79114 88.1764C-1.04757 85.375 -1.04757 80.791 3.79114 77.9896Z"
          fill="url(#paint0_linear_3967_231853)"
        />
        <path
          d="M143.859 83.2533C143.774 85.036 142.5 86.8186 140.208 88.1769L80.7857 122.387C75.947 125.274 68.0522 125.274 63.1286 122.387L3.79087 88.1769C1.32908 86.7337 0.140625 84.8662 0.140625 83.0835V92.1667C0.395294 93.8645 1.58375 95.4774 3.79087 96.7507L63.1286 131.046C68.0522 133.847 75.947 133.847 80.7857 131.046L140.208 96.7507C142.755 95.3076 143.943 93.3551 143.774 91.4027H143.859V89.2804V83.2533Z"
          fill="#9B9BA8"
        />
        <rect
          width="63.7143"
          height="63.7143"
          rx="2.94284"
          transform="matrix(0.866025 -0.5 2.20305e-08 1 38.3408 31.8569)"
          fill="url(#paint1_linear_3967_231853)"
          fillOpacity="0.4"
        />
        <rect
          width="63.7143"
          height="63.7143"
          rx="2.94284"
          transform="matrix(0.866025 -0.5 2.20305e-08 1 42.5859 34.4038)"
          fill="url(#paint2_linear_3967_231853)"
          fillOpacity="0.6"
        />
        <rect
          width="63.7143"
          height="63.7143"
          rx="2.94284"
          transform="matrix(0.866025 -0.5 2.20305e-08 1 48.5273 37.7983)"
          fill="url(#paint3_linear_3967_231853)"
        />
        <circle
          r="10.6034"
          transform="matrix(0.866025 -0.5 2.20305e-08 1 78.9757 44.7953)"
          fill="#FAD2A0"
        />
        <path
          d="M95.7206 68.1851C95.7206 57.5063 88.2236 53.1779 78.9755 58.5173C69.7274 63.8567 62.2305 76.8418 62.2305 87.5206L95.7206 68.1851Z"
          fill="#FAD2A0"
        />
        <path
          d="M64.958 37.3999L56.7441 51.6267"
          stroke="#FF585F"
          strokeWidth="4.11133"
          strokeLinecap="round"
        />
        <path
          d="M56.7461 42.1421L64.96 46.8844"
          stroke="#FF585F"
          strokeWidth="2.82383"
          strokeLinecap="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_3967_231853"
            x1="139.36"
            y1="77.6497"
            x2="-1.55682"
            y2="83.1676"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D8D8DD" />
            <stop offset="1" stopColor="#F8F8F8" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_3967_231853"
            x1="7.91085"
            y1="57.7358"
            x2="69.4046"
            y2="10.6861"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#024336" />
            <stop offset="0.630351" stopColor="#038A70" />
            <stop offset="1" stopColor="#68C3B1" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_3967_231853"
            x1="7.91085"
            y1="57.7358"
            x2="69.4046"
            y2="10.6861"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#024336" />
            <stop offset="0.630351" stopColor="#038A70" />
            <stop offset="1" stopColor="#68C3B1" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_3967_231853"
            x1="7.91085"
            y1="57.7358"
            x2="69.4046"
            y2="10.6861"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#024336" />
            <stop offset="0.630351" stopColor="#038A70" />
            <stop offset="1" stopColor="#68C3B1" />
          </linearGradient>
        </defs>
      </g>
    </SvgIcon>
  );
}
