import React from "react";
import { styled, Box } from "@mui/material";
import {
  BasicMessageLayout,
  BasicMessageCard,
  FileMessageCard,
  DeletedMessageCard,
} from "./MessageCards";
import { convertPubnubTime } from "utils";
import { checkIfNull } from "@app21/core";

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
}));

export default function ChatMessageItemShow({
  message,
  currentUser,
  isContiguous = false,
  isMobile = false,
  handleFileDownload,
  handleMessageDelete,
  handleMessageReply,
  handleFileView,
}) {
  const uuid = message.uuid || message.publisher || "";
  const isMe = uuid === currentUser._id;

  const [showMenuOptions, setShowMenuOptions] = React.useState(false);
  let messageType = "text";
  if (Boolean(message?.message?.customElements?.s3Key)) {
    messageType = "file";
  }
  let replyMessage = checkIfNull(
    message?.message?.customElements?.originalMessageOnReply
  )
    ? null
    : message?.message?.customElements?.originalMessageOnReply;
  let canDelete = isMe;
  const urlMeta = message?.message?.customElements?.urlMetadata ?? null;

  const getMessageTemplate = (message) => {
    if (message.actions?.deleted) return <DeletedMessageCard />;

    switch (messageType) {
      case "text":
        return (
          <BasicMessageCard message={message} isMobile={isMobile} isMe={isMe} />
        );
      case "file":
        return (
          <FileMessageCard
            message={message}
            isMobile={isMobile}
            isMe={isMe}
            handleFileDownload={handleFileDownload}
            handleFileView={handleFileView}
          />
        );
    }
  };

  return (
    <RootStyle
      sx={{ mt: isContiguous ? 1 : 3 }}
      onMouseEnter={() => setShowMenuOptions(true)}
      onMouseLeave={() => setShowMenuOptions(false)}
    >
      <BasicMessageLayout
        isMe={isMe}
        uuid={uuid}
        isDeleted={message.actions?.deleted}
        isMobile={isMobile}
        timeStamp={convertPubnubTime(message.timetoken)}
        isContiguous={isContiguous}
        replyMessage={replyMessage}
        urlMeta={urlMeta}
        showMenuOptions={showMenuOptions || isMobile}
        setShowMenuOptions={setShowMenuOptions}
        messageType={messageType}
        messageId={message.message.id}
        s3Key={message.message?.customElements?.s3Key}
        handleFileDownload={handleFileDownload}
        handleMessageDelete={handleMessageDelete}
        handleMessageReply={handleMessageReply}
        handleFileView={handleFileView}
        canDelete={canDelete}
      >
        {getMessageTemplate(message)}
      </BasicMessageLayout>
    </RootStyle>
  );
}
