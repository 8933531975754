import React from 'react'
import { SvgIcon } from "@mui/material";

export default function EyeIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 17 17"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M6.77293 10.4999C6.48043 10.0724 6.31543 9.55494 6.31543 8.99994C6.31543 7.51494 7.51543 6.31494 9.00043 6.31494C10.4854 6.31494 11.6854 7.51494 11.6854 8.99994C11.6854 10.4849 10.4854 11.6849 9.00043 11.6849"
          stroke="currentColor"
          strokeWidth="1.125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.1699 4.18499C11.9024 3.28499 10.4774 2.79749 8.99988 2.79749C6.35238 2.79749 3.88488 4.35749 2.16738 7.05749C1.49238 8.11499 1.49238 9.89249 2.16738 10.95C3.88488 13.65 6.35238 15.21 8.99988 15.21C11.6474 15.21 14.1149 13.65 15.8324 10.95C16.5074 9.89249 16.5074 8.11499 15.8324 7.05749"
          stroke="currentColor"
          strokeWidth="1.125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
