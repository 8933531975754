import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Divider,
  Chip,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import {
  useUsersInSuite,
  useAppContext,
  useHandleRouterPush,
  app21APIInstance,
  useSelectedBucketId,
  checkIfNull,
  useUpdateFileInChannel,
} from "@app21/core";
import ChatMessageItemShow from "./ChatMessageItemShow";
import { usePubNub } from "pubnub-react";
import { useAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { LoadingSpinner } from "blocks/atoms";

import {
  CurrentChannelAtom,
  CurrentChannelMessagesAtom,
  CurrentChannelPaginationAtom,
  ErrorFunctionAtom,
  RetryFunctionAtom,
  ThemeAtom,
  UsersMetaAtom,
  OriginalMessageOnReplyAtom,
  OriginalMessageInReplyClickedAtom,
  RefetchOnNetworkReconnectAtom,
} from "blocks/modules/TrrystChat/state-atoms";
import {
  useOuterClick,
  isFilePayload,
  convertPubnubTime,
  getDateTimeFormat,
} from "utils";
import { KeyboardDoubleArrowDown, Refresh } from "@mui/icons-material";
import PerfectScrollbar from "react-perfect-scrollbar";
import { isSameDay, format } from "date-fns";
import { useIsMobileBreakpoint } from "hooks";
import { useChatContext } from "providers/TrrystChatProvider";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import { useQueryClient } from "react-query";

export default function ChatMessageList(props) {
  const { fetchMessagesCount = 20 } = props;
  const { userInfo, selectedSuiteId } = useAppContext();
  const { data: usersInSuite, status: usersInSuiteStatus } =
    useUsersInSuite(selectedSuiteId);
  const isMobile = useIsMobileBreakpoint(480, true);
  const { loadRoute } = useHandleRouterPush();
  const pubnubChatClient = usePubNub();
  const [currentChannel] = useAtom(CurrentChannelAtom);
  const [currentChannelMessages] = useAtom(CurrentChannelMessagesAtom);
  const [originalMessageOnReply, setOriginalMessageOnReply] = useAtom(
    OriginalMessageOnReplyAtom
  );
  const [originalMessageInReplyClicked, setOriginalMessageInReplyClicked] =
    useAtom(OriginalMessageInReplyClickedAtom);

  const queryClient = useQueryClient();
  const [theme] = useAtom(ThemeAtom);

  const [users] = useAtom(UsersMetaAtom);
  const [retryObj] = useAtom(RetryFunctionAtom);
  const [onErrorObj] = useAtom(ErrorFunctionAtom);
  const [messages] = useAtom(CurrentChannelMessagesAtom);
  const [paginationEnd] = useAtom(CurrentChannelPaginationAtom);
  const retry = retryObj.function;
  const onError = onErrorObj.function;
  const [scrolledBottom, setScrolledBottom] = useState(true);
  const [prevMessages, setPrevMessages] = useState([]);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [fetchingMessages, setFetchingMessages] = useState(false);
  const [picker, setPicker] = useState();
  const [emojiPickerShown, setEmojiPickerShown] = useState(false);
  const [reactingToMessage, setReactingToMessage] = useState(null);
  const [scrollTimetoken, setScrollTimetoken] = useState(null);

  const [refetchOnNetworkReconnect, setRefetchOnNetworkReconnect] = useAtom(
    RefetchOnNetworkReconnectAtom
  );

  const { inputsForUnreadCounts } = useChatContext();

  let currentTime = new Date().getTime();

  const [currentLastReadTimetoken, setCurrentLastReadTimetoken] =
    React.useState(currentTime);

  React.useEffect(() => {
    setCurrentLastReadTimetoken(
      inputsForUnreadCounts?.data.find((e) => e.channel === currentChannel)
        ?.channelTimetoken ?? currentTime
    );
  }, [inputsForUnreadCounts]);

  const endRef = useRef(null);
  const listRef = useRef(null);
  const spinnerRef = useRef(null);

  const pickerRef = useOuterClick((event) => {
    if (event.target.closest(".pn-msg__reactions-toggle")) return;
    setEmojiPickerShown(false);
  });
  const listSizeObserver = useRef(
    new ResizeObserver(() => handleListMutations())
  );
  const listMutObserver = useRef(
    new MutationObserver(() => handleListMutations())
  );
  const spinnerIntObserver = useRef(
    new IntersectionObserver(
      (e) => e[0].isIntersecting === true && fetchMoreHistory()
    )
  );
  const bottomIntObserver = useRef(
    new IntersectionObserver((e) =>
      handleBottomIntersection(e[0].isIntersecting)
    )
  );

  /*
  /* Helper functions
  */
  const getTime = (timestamp) => {
    const ts = String(timestamp);
    const date = new Date(parseInt(ts) / 10000);
    const formatter = new Intl.DateTimeFormat([], { timeStyle: "short" });
    return formatter.format(date);
  };

  const scrollToBottom = () => {
    if (!endRef.current) return;
    endRef.current.scrollIntoView({ block: "end" });
    setScrolledBottom(true);
  };

  const setupSpinnerObserver = () => {
    if (!spinnerRef.current) return;
    spinnerIntObserver.current.observe(spinnerRef.current);
  };

  const setupBottomObserver = () => {
    if (!endRef.current) return;
    bottomIntObserver.current.disconnect();
    bottomIntObserver.current.observe(endRef.current);
  };

  const setupListObservers = () => {
    if (!listRef.current) return;

    listSizeObserver.current.disconnect();
    listSizeObserver.current.observe(listRef.current);

    listMutObserver.current.disconnect();
    listMutObserver.current.observe(listRef.current, { childList: true });
  };

  const getUser = (uuid) => {
    return usersInSuite?.find((u) => u._id === uuid);
  };

  const isOwnMessage = (uuid) => {
    return userInfo._id === uuid;
  };

  /*** Commands */

  const fetchHistory = async (startTimetoken) => {
    if (!fetchMessagesCount) return;
    try {
      setFetchingMessages(true);

      const history = await retry(() =>
        pubnubChatClient.fetchMessages({
          channels: [currentChannel],
          count: fetchMessagesCount,
          includeMessageActions: true,
          start: startTimetoken ?? undefined,
        })
      );
      handleHistoryFetch({ response: history, refetchOnNetworkReconnect });

      //scrollToBottom();
      setupSpinnerObserver();
      setupBottomObserver();
    } catch (e) {
      onError(e);
    } finally {
      setFetchingMessages(false);
    }
  };

  /** useAtomCallback to accesses jotai atoms inside of a Intersection Observer callback */
  const fetchMessagesInTimeWindow = useAtomCallback(
    useCallback(async (get, set, { start, end }) => {
      if (!fetchMessagesCount) return;
      const firstMessage = listRef.current?.querySelector("div");

      const messages = get(CurrentChannelMessagesAtom);
      if (!messages.length) return;
      try {
        const history = await retry(() =>
          pubnubChatClient.fetchMessages({
            channels: [currentChannel],
            count: fetchMessagesCount,
            start: start || undefined,
            end: end || undefined,
            includeMessageActions: true,
          })
        );
        let isFillerMessageNeeded = "after";
        handleHistoryFetch({
          response: history,
          refetchOnNetworkReconnect,
          isFillerMessageNeeded,
        });
        if (firstMessage) firstMessage.scrollIntoView();
        setupSpinnerObserver();
        setupBottomObserver();
      } catch (e) {
        onError(e);
      }
    }, [])
  );

  const fetchMoreHistory = useAtomCallback(
    useCallback(async (get) => {
      if (!fetchMessagesCount) return;
      const firstMessage = listRef.current?.querySelector("div");

      const messages = get(CurrentChannelMessagesAtom);
      if (!messages.length) return;
      try {
        const history = await retry(() =>
          pubnubChatClient.fetchMessages({
            channels: [currentChannel],
            count: fetchMessagesCount,
            start: messages?.[0].timetoken || undefined,
            includeMessageActions: true,
          })
        );
        handleHistoryFetch({ response: history, refetchOnNetworkReconnect });
        if (firstMessage) firstMessage.scrollIntoView();
      } catch (e) {
        onError(e);
      }
    }, [])
  );

  const addReaction = (reaction, messageTimetoken) => {
    try {
      pubnubChatClient.addMessageAction({
        currentChannel,
        messageTimetoken,
        action: {
          type: "reaction",
          value: reaction,
        },
      });
    } catch (e) {
      onError(e);
    }
  };

  const removeReaction = (reaction, messageTimetoken, actionTimetoken) => {
    try {
      pubnubChatClient.removeMessageAction({
        currentChannel,
        messageTimetoken,
        actionTimetoken,
      });
    } catch (e) {
      onError(e);
    }
  };

  const fetchFileUrl = (envelope) => {
    if (!isFilePayload(envelope.message)) return envelope;

    try {
      const url = pubnubChatClient.getFileUrl({
        channel: envelope.channel,
        id: envelope.message.file.id,
        name: envelope.message.file.name,
      });

      envelope.message.file.url = url;
    } catch (e) {
      onError(e);
    } finally {
      return envelope;
    }
  };

  /*
  /* Event handlers
  */

  const selectedBucketId = useSelectedBucketId(null, true);

  const handleFileDownload = async (messageId) => {
    const messageToDownloadFileFrom = currentChannelMessages.find(
      (m) => m.message.id === messageId
    );
    if (messageToDownloadFileFrom) {
      const response = await app21APIInstance.post("/s3/fetchurl", {
        bucket: selectedBucketId,
        key: messageToDownloadFileFrom?.message?.customElements?.s3Key,
        urlTypeRequested: "get",
        contentDisposition: "attachment",
        calledBy: `${userInfo?._id}`,
      });
      window.open(response?.data?.signedUrl, "_blank", "noopener noreferrer");
    }
  };
  const handleFileView = async (s3Key) => {
    console.log("s3Key", s3Key);
    s3Key &&
      loadRoute("VIEW-CHATFILE", {
        chatId: currentChannel,
        s3Key: s3Key,
      });
  };

  const updateFileInChatChannel = useUpdateFileInChannel();

  const handleMessageDelete = async (messageId) => {
    const messageToSoftDelete = currentChannelMessages.find(
      (m) => m.message.id === messageId
    );

    pubnubChatClient.addMessageAction(
      {
        channel: messageToSoftDelete.channel,
        messageTimetoken: messageToSoftDelete.timetoken,
        action: { type: "deleted", value: "deletedBy" },
      },
      (status, response) => console.log(status, response)
    );

    // if there is a file the associated file entry in channels also needs
    // to be deleted as well
    if (messageToSoftDelete.message.customElements.s3Key) {
      await updateFileInChatChannel.mutate({
        message: messageToSoftDelete.message,
        channelId: currentChannel,
        action: "REMOVE",
        additionalData: { suiteId: selectedSuiteId },
      });
    }
  };

  const handleMessageReply = (messageId) => {
    const originalMessageOnReply = currentChannelMessages.find(
      (m) => m.message.id === messageId
    );

    setOriginalMessageOnReply(originalMessageOnReply);
  };

  const handleEmojiInsertion = useCallback(
    (emoji = {}) => {
      try {
        if (!("native" in emoji)) return;
        addReaction(emoji.native, reactingToMessage);
        setEmojiPickerShown(false);
      } catch (e) {
        onError(e);
      }
    },
    [reactingToMessage]
  );

  const handleBottomIntersection = (isIntersecting) => {
    try {
      if (isIntersecting) setUnreadMessages(0);
      setScrolledBottom(isIntersecting);
    } catch (e) {
      onError(e);
    }
  };

  const handleListMutations = () => {
    try {
      setScrolledBottom((scrolledBottom) => {
        if (scrolledBottom) scrollToBottom();
        return scrolledBottom;
      });
    } catch (e) {
      onError(e);
    }
  };

  const handleHistoryFetch = useAtomCallback(
    useCallback((get, set, arg) => {
      const { response, isFillerMessageNeeded, refetchOnNetworkReconnect } =
        arg;
      const currentChannel = get(CurrentChannelAtom);
      const messages = !refetchOnNetworkReconnect
        ? get(CurrentChannelMessagesAtom)
        : [];
      const newMessages = [];

      if (isFillerMessageNeeded === "before") {
        // A filler message is being added so that this can be rendered here.
        newMessages.push({
          channel: currentChannel,
          timetoken: parseInt(
            Number(response?.channels[currentChannel][0].timetoken) - 1
          ),
          message: {
            id: uuidv4(),
            customElements: { isFillerMessage: true },
          },
        });
      }

      response?.channels[currentChannel] &&
        newMessages.push(...response?.channels[currentChannel]);

      if (isFillerMessageNeeded === "after") {
        // A filler message is being added so that this can be rendered here.
        newMessages.push({
          channel: currentChannel,
          timetoken: parseInt(
            Number(newMessages[newMessages.length - 1].timetoken) + 1
          ),
          message: {
            id: uuidv4(),
            customElements: { isFillerMessage: true },
          },
        });
      }

      if (messages === [] && response?.channels[currentChannel]) {
        messages.push(response?.channels[currentChannel]);
      }

      const allMessages = [...messages, ...newMessages].sort(
        (a, b) => a.timetoken - b.timetoken
      );
      const allUniqMessages = _.uniqBy(allMessages, "timetoken");

      setEmojiPickerShown(false);
      setPrevMessages(allMessages);
      set(CurrentChannelMessagesAtom, allUniqMessages);
      setRefetchOnNetworkReconnect(false);
      set(
        CurrentChannelPaginationAtom,
        !allMessages.length || newMessages.length !== fetchMessagesCount
      );
    }, [])
  );

  /*
  /* Lifecycle
  */

  useEffect(() => {
    if (!pubnubChatClient || !currentChannel) return;
    if (!messages?.length || refetchOnNetworkReconnect) fetchHistory(); // giving nothing will fetch from current time

    // if messages are there, but last message timetoken is less than current time
    // fetch again as there might be a few new messages
    if (
      messages.length &&
      messages[messages.length - 1].timetoken < Date.now() * 10000
    )
      fetchMessagesInTimeWindow({
        start: Date.now() * 10000,
        end: parseInt(Number(messages[messages.length - 1].timetoken) + 1),
      });

    setupSpinnerObserver();
    setupListObservers();

    const timer = setTimeout(async () => {
      let lastUnreadMessageElement = document.getElementById(
        `tr-chat-msg-${scrollTimetoken}`
      );
      if (lastUnreadMessageElement) {
        lastUnreadMessageElement.scrollIntoView(true);
      }
      // need to update the currentLastReadTimetoken
      const lastReadTimetoken = Date.now() * 10000;
      await app21APIInstance.post(`/channels/updatelastreadtimetoken`, {
        channelId: currentChannel,
        lastReadTimetoken: lastReadTimetoken,
        calledBy: userInfo?._id,
        additionalData: { suiteId: selectedSuiteId },
      });
      queryClient.setQueryData(
        ["chat-channels", `${userInfo._id}`],
        (oldData) => {
          let localData = _.cloneDeep(oldData ?? {});
          localData?.data?.data?.map((channel) => {
            if (channel.id === currentChannel) {
              channel.members.map((m) => {
                if (m.id === userInfo?._id) {
                  m.custom.lastReadTimetoken = lastReadTimetoken;
                }
              });
            }
          });
          return localData;
        }
      );
    }, 500);
    return async () => {
      clearTimeout(timer);
      const lastReadTimetoken = Date.now() * 10000;

      await app21APIInstance.post(`/channels/updatelastreadtimetoken`, {
        channelId: currentChannel,
        lastReadTimetoken: lastReadTimetoken,
        calledBy: userInfo?._id,
        additionalData: { suiteId: selectedSuiteId },
      });

      queryClient.setQueryData(
        ["chat-channels", `${userInfo._id}`],
        (oldData) => {
          let localData = _.cloneDeep(oldData ?? {});
          localData?.data?.data?.map((channel) => {
            if (channel.id === currentChannel) {
              channel.members.map((m) => {
                if (m.id === userInfo?._id) {
                  m.custom.lastReadTimetoken = lastReadTimetoken;
                }
              });
            }
          });
          return localData;
        }
      );
    };
  }, [currentChannel, refetchOnNetworkReconnect]);

  useEffect(() => {
    if (React.isValidElement(props.reactionsPicker)) {
      setPicker(
        React.cloneElement(props.reactionsPicker, {
          onSelect: handleEmojiInsertion,
        })
      );
    }
  }, [props.reactionsPicker, handleEmojiInsertion]);

  useEffect(() => {
    if (!currentChannelMessages?.length) return;

    const messagesFromListener =
      currentChannelMessages.length - prevMessages.length;

    if (scrolledBottom) scrollToBottom();
    if (!scrolledBottom && messagesFromListener)
      setUnreadMessages(unreadMessages + messagesFromListener);

    setupBottomObserver();
    setPrevMessages(currentChannelMessages);

    // if all the unread messages have not been fetched, fetch more.
    // fetch 20 more ... keep going until all unread messages have been fetched
    // update the unread time token as and when messages are read.
    if (
      Number(currentChannelMessages[0].timetoken) >
      Number(currentLastReadTimetoken)
    ) {
      fetchHistory(currentChannelMessages[0].timetoken);
      // update the last read time token for the current channel to current time
      let lastReadTimetoken = Date.now() * 10000;
      app21APIInstance.post(`/channels/updatelastreadtimetoken`, {
        channelId: currentChannel,
        lastReadTimetoken: lastReadTimetoken,
        calledBy: userInfo?._id,
        additionalData: { suiteId: selectedSuiteId },
      });
      queryClient.setQueryData(
        ["chat-channels", `${userInfo._id}`],
        (oldData) => {
          let localData = _.cloneDeep(oldData);
          localData?.data?.data?.map((channel) => {
            if (channel.id === currentChannel) {
              channel.members.map((m) => {
                if (m.id === userInfo?._id) {
                  m.custom.lastReadTimetoken = lastReadTimetoken;
                }
              });
            }
          });
          return localData;
        }
      );
    }
  }, [currentChannelMessages]);

  useEffect(() => {
    let messageFound = false;
    if (!checkIfNull(originalMessageInReplyClicked)) {
      // check if the clicked message is already in the currentChannelMessages array
      messageFound = currentChannelMessages.find(
        (m) => m?.message?.id === originalMessageInReplyClicked?.message?.id
      );

      !messageFound &&
        fetchMessagesInTimeWindow({
          start: parseInt(originalMessageInReplyClicked.timetoken) + 60000,
          end: parseInt(originalMessageInReplyClicked.timetoken) - 60000,
        });
      setOriginalMessageInReplyClicked(false);
    }
  }, [originalMessageInReplyClicked]);

  /*
  /* Renderers
  */

  let isContiguous = false;
  let isSameDayFlag = false;
  let prevMessage = null;
  if (usersInSuiteStatus === "loading") return <LoadingSpinner />;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        position: "relative",
        bgcolor: "background.paper",
        height: "100%",
        pb: 1,
        pl: 1,
      }}
    >
      <PerfectScrollbar
        options={{ suppressScrollX: true }}
        onYReachStart={() => {
          // console.log("start");
        }}
        onYReachEnd={() => {
          setScrolledBottom(true);
          setUnreadMessages(0);
        }}
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          height: "100%",
          paddingRight: 10,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            pr: 1,
            position: "relative",
            height: "100%",
            width: "100%",
          }}
          // onScroll={props.onScroll}
          ref={listRef}
        >
          {!!fetchMessagesCount && !paginationEnd && (
            <IconButton
              sx={{ alignSelf: "center" }}
              onClick={async () => await fetchMoreHistory()}
              ref={spinnerRef}
            >
              <Refresh />
            </IconButton>
          )}

          {unreadMessages > 0 && (
            <Box
              onClick={() => scrollToBottom()}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                bgcolor: "background.default",
                mx: 3,
                borderRadius: 2,
                mb: 1,
              }}
            >
              <Typography variant="body2" sx={{ my: "auto" }}>
                {unreadMessages} new message{unreadMessages > 1 ? "s" : ""}
              </Typography>
              <KeyboardDoubleArrowDown sx={{ ml: 2 }} />
            </Box>
          )}
          {currentChannelMessages
            ? currentChannelMessages.map((m) => {
                if (m.timetoken < currentLastReadTimetoken && !scrollTimetoken)
                  setScrollTimetoken(m.timetoken);

                //below conditions is if prev message is from same person and the time difference between two messages is less than 15 mins then treat it as contiguous messages
                if (
                  (prevMessage?.uuid || prevMessage?.publisher) ===
                    (m.uuid || m.publisher) &&
                  m.timetoken - prevMessage?.timetoken < 9000000000
                ) {
                  isContiguous = true;
                } else {
                  isContiguous = false;
                }
                if (
                  !checkIfNull(prevMessage?.timetoken) &&
                  !checkIfNull(m?.timetoken)
                ) {
                  if (
                    isSameDay(
                      convertPubnubTime(prevMessage?.timetoken),
                      convertPubnubTime(m?.timetoken)
                    )
                  ) {
                    isSameDayFlag = true;
                  } else {
                    isSameDayFlag = false;
                  }
                }

                prevMessage = m;

                const isFillerMessage =
                  m?.message?.customElements?.isFillerMessage ?? false;

                if (isFillerMessage) {
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Divider sx={{ my: 1, borderStyle: "dotted" }} />
                    <Chip
                      icon={<Refresh />}
                      label={"More"}
                      onClick={() =>
                        fetchMessagesInTimeWindow({
                          start: m.timetoken + 600000,
                          end: m.timetoken,
                        })
                      }
                      sx={{ maxWidth: 100, alignSelf: "center" }}
                    />
                    <Divider sx={{ my: 1, borderStyle: "dotted" }} />
                  </Box>;
                } else
                  return (
                    <div
                      key={m.timetoken}
                      id={`tr-chat-msg-${m.timetoken}`}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div>
                        {!isSameDayFlag && (
                          <Divider>
                            {!checkIfNull(convertPubnubTime(m.timetoken)) && (
                              <Chip
                                size="small"
                                label={format(
                                  convertPubnubTime(m.timetoken),
                                  getDateTimeFormat({
                                    startDate: convertPubnubTime(m.timetoken),
                                    noTimeFlag: true,
                                  })
                                )}
                              />
                            )}
                          </Divider>
                        )}
                      </div>
                      <ChatMessageItemShow
                        isContiguous={isContiguous}
                        message={m}
                        isMobile={isMobile}
                        currentUser={userInfo}
                        handleFileDownload={handleFileDownload}
                        handleFileView={handleFileView}
                        handleMessageDelete={handleMessageDelete}
                        handleMessageReply={handleMessageReply}
                      />
                    </div>
                  );
              })
            : "No Messages to Display. Select a group"}

          {props.children}
          <Box ref={endRef}></Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );
}
