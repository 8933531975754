export const handleToggleCollaboration = async ({
  type,
  action,
  userInfo,
  meeting,
  handleMeetingActions,
  setIsCollaborationActive,
  setChimeViewMode,
  meetingId,
}) => {
  if (action === "START-COLLABORATION") {
    let collabLogsEntry = {};
    if (type === "TRRYST-WHITEBOARD") {
      collabLogsEntry = {
        status: "ACTIVE",
        type: "TRRYST-WHITEBOARD",
        startedBy: userInfo._id,
        participants: [userInfo._id],
        sessionStartedAt: Date.now(),
        sessionEndedAt: null,
      };
    } else if (type === "TRRYST-DOCSHARE") {
      collabLogsEntry = {
        status: "ACTIVE",
        type: "TRRYST-DOCSHARE",
        s3KeyForShare: "key for share",
        startedBy: userInfo._id,
        participants: [userInfo._id],
        sessionStartedAt: Date.now(),
        sessionEndedAt: null,
      };
    }

    // to avoid race conditions, it may be necessary to always fetch the latest meeting object
    // before adding a collaboration session. if there is already an active session, start from
    // another user will just take him / her to that active session.

    let updatedMeetingData = Object.assign({}, meeting.data);
    if (!updatedMeetingData.collaborationLogs)
      updatedMeetingData.collaborationLogs = [];
    updatedMeetingData.collaborationLogs.push(collabLogsEntry);
    await handleMeetingActions({
      action: "UPDATE-MEETING",
      meetingData: updatedMeetingData,
      meetingId: meetingId,
    });

    // instantly set for the user requesting a start, for others it will come via the meeting object update
    // and subsequent pubnub action to refetch meeting object.
    // setIsCollaborationActive(true);
    // setChimeViewMode("featured");
  } else if (action === "STOP-COLLABORATION") {
    let updatedMeetingData = Object.assign({}, meeting.data);

    updatedMeetingData?.collaborationLogs?.map((l, index) => {
      if (
        l.status === "ACTIVE" &&
        index === updatedMeetingData.collaborationLogs.length - 1
      ) {
        l.status = "STOPPED";
        l.sessionEndedAt = Date.now();
      }
    });

    await handleMeetingActions({
      action: "UPDATE-MEETING",
      meetingData: updatedMeetingData,
      meetingId: meetingId,
    });

    // instantly set for the user requesting a stop, for others it will come via the meeting object update
    // and subsequent pubnub action to refetch meeting object.
    // setIsCollaborationActive(false);
    //  setChimeViewMode("normal");
  }
};
