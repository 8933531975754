import React, { useState } from "react";
import PropTypes from "prop-types";

// @mui
import { useTheme, styled } from "@mui/material/styles";
import { Box, IconButton, ClickAwayListener } from "@mui/material";
import { InsertEmoticon } from "@mui/icons-material";
import dynamic from "next/dynamic";

// ----------------------------------------------------------------------

const RootStyle = styled(Box)({
  position: "relative",
});

const PickerStyle = styled("div")(({ theme }) => ({
  bottom: 40,
  overflow: "hidden",
  position: "absolute",

  boxShadow: theme.customShadows.z20,
  borderRadius: Number(theme.shape.borderRadius) * 2,
  "&.em-emoji-picker": { categoryIconSize: 10 },
  "& .emoji-mart": {
    backgroundColor: theme.palette.background.paper,
  },
  "& .emoji-mart-anchor": {
    color: theme.palette.text.disabled,
    "&:hover, &:focus, &.emoji-mart-anchor-selected": {
      color: theme.palette.text.primary,
    },
  },
  "& .emoji-mart-bar": { borderColor: theme.palette.divider },
  "& .emoji-mart-search input": {
    backgroundColor: "transparent",
    color: theme.palette.text.primary,
    borderColor: theme.palette.grey[500_32],
    "&::placeholder": {
      ...theme.typography.body2,
      color: theme.palette.text.disabled,
      fontSize: 12,
    },
  },
  "& .emoji-mart-search-icon svg": {
    opacity: 1,
    fill: theme.palette.text.disabled,
  },
  "& .emoji-mart-category-label span": {
    ...theme.typography.subtitle2,
    //...cssStyles().bgBlur({ color: theme.palette.background.paper }),
    color: theme.palette.text.primary,
  },
  "& .emoji-mart-title-label": {
    color: theme.palette.text.primary,
    fontSize: 14,
  },

  "& .emoji-mart-category .emoji-mart-emoji:hover:before": {
    backgroundColor: theme.palette.action.selected,
  },
  "&: .preview-placeholder": {
    border: "1px solid red",
  },
  "&.emoji-mart-emoji": {
    outline: "none",
    border: "1px solid red",
    fontSize: 10,
  },
  "& .emoji-mart-preview-name": {
    color: theme.palette.text.primary,
  },
  "& .emoji-mart-preview-emoji": {
    color: theme.palette.text.primary,
  },
  "& .emoji-mart-preview-shortname, .emoji-mart-preview-emoticon": {
    color: theme.palette.text.secondary,
  },
}));

// ----------------------------------------------------------------------

EmojiPicker.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
  setValue: PropTypes.func,
  alignRight: PropTypes.bool,
};

export default function EmojiPicker({
  disabled,
  value,
  setValue,
  alignRight = false,
  ...other
}) {
  const theme = useTheme();
  const Picker = dynamic(
    () => {
      return import("emoji-picker-react");
    },
    { ssr: false }
  );

  const [emojiPickerState, SetEmojiPicker] = useState(false);
  const [chosenEmoji, setChosenEmoji] = useState(null);
  let emojiPicker;
  if (emojiPickerState) {
    emojiPicker = (
      <Picker
        emojiStyle="facebook"
        lazyLoadEmojis={true}
        title="Pick emoji..."
        emoji="point_up"
        onEmojiClick={(emoji, event) => {
          console.log(emoji);
          setValue(emoji);
        }}
      />
    );
  }

  const triggerPicker = (event) => {
    event.preventDefault();
    SetEmojiPicker(!emojiPickerState);
  };

  const handleClickAway = () => {
    SetEmojiPicker(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <RootStyle {...other}>
        <PickerStyle>{emojiPicker}</PickerStyle>
        <IconButton disabled={disabled} size="small" onClick={triggerPicker}>
          <InsertEmoticon />
        </IconButton>
      </RootStyle>
    </ClickAwayListener>
  );
}
