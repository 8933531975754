import React from 'react'
import { SvgIcon } from '@mui/material';

export default function RemoveIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 106"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M62.4583 56H66.6666"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.3333 56H50"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.6667 31.0002C11.4583 37.9585 8.33334 46.6252 8.33334 56.0002C8.33334 79.0002 27 97.6668 50 97.6668C73 97.6668 91.6667 79.0002 91.6667 56.0002C91.6667 33.0002 73 14.3335 50 14.3335C44.0417 14.3335 38.3333 15.5835 33.2083 17.8752"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
