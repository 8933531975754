// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { ProfilesChipsList, LoadingSpinner } from "blocks/atoms";
import { FlowBox } from "blocks/atoms/uistyles";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardHeader,
  Button,
  Paper,
} from "@mui/material";
import {
  EditIcon,
  BreakoutIcon,
  FilesIcon,
  CalendarIcon,
  AverageViewIcon,
  ViewRecordingIcon,
  MyTaskIcon,
} from "blocks/atoms/trrysticons";
import { VideoCall } from "@mui/icons-material";
import { checkIfNull, useAppContext } from "@app21/core";
import { DocketActionCard } from "blocks/modules/Dockets/foundations";
import { useDocketsContext } from "providers/DocketsProvider";
import { useIsMobileBreakpoint } from "hooks";
import PerfectScrollbar from "react-perfect-scrollbar";

const DisplaySelectedDocket = ({}) => {
  const {
    baseDocketsStatus,
    selectedDocket = {},
    selectedDocketId,
    docketPermissions,
    handleDocketEdit,
    handleDocketChatClick,
    handleDocketMeetingClick,
    handleDocketFilesClick,
    handleDocketTasksClick,
    handleDocketSurveysClick,
    handleDocketRecordingsClick,
    handleDocketWalkinVideocallClick,
  } = useDocketsContext();

  const isMobile = useIsMobileBreakpoint();

  const docketActionOptions = [
    ...(docketPermissions.checkCanViewChat(selectedDocket)
      ? [
          {
            label: "Chat",
            icon: <BreakoutIcon />,
            description: "Chat with this breakout Group",
            onClickAction: () => handleDocketChatClick(selectedDocketId),
          },
        ]
      : []),
    ...(docketPermissions.checkCanViewFiles(selectedDocket)
      ? [
          {
            label: "Documents",
            icon: <FilesIcon />,
            description: "Share files within this breakout",
            onClickAction: () => handleDocketFilesClick(selectedDocketId),
          },
        ]
      : []),
    ...(docketPermissions.checkCanViewCalendar(selectedDocket)
      ? [
          {
            label: "Meetings",
            icon: <CalendarIcon />,
            description: "Schedule a meeting with breakout members",
            onClickAction: () => handleDocketMeetingClick(selectedDocketId),
          },
        ]
      : []),
    ...(docketPermissions.checkCanViewRecords(selectedDocket)
      ? [
          {
            label: "Recording",
            icon: <ViewRecordingIcon />,
            description: "Browse transcripts of recorded meetings",
            onClickAction: () => handleDocketRecordingsClick(selectedDocketId),
          },
        ]
      : []),
    ...(docketPermissions.checkCanViewSurveys(selectedDocket)
      ? [
          {
            label: "Surveys",
            icon: <AverageViewIcon />,
            description: "Poll this breakout group",
            onClickAction: () => handleDocketSurveysClick(selectedDocketId),
          },
        ]
      : []),
    ...(docketPermissions.checkCanViewTasks(selectedDocket)
      ? [
          {
            label: "Task",
            icon: <MyTaskIcon />,
            description: "Team task tracker",
            onClickAction: () => handleDocketTasksClick(selectedDocketId),
          },
        ]
      : []),
  ];

  if (checkIfNull(selectedDocket) || baseDocketsStatus === "loading")
    return <LoadingSpinner />;

  return (
    <FlowBox>
      <PerfectScrollbar
        options={{ suppressScrollX: true }}
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",

            mb: 2,
            px: 2,
          }}
        >
          <Box sx={{ p: 2 }}>
            <Typography variant="h5"> {selectedDocket.title}</Typography>
          </Box>
          {docketPermissions.checkCanEditDocket(selectedDocket) && (
            <Button
              startIcon={<EditIcon />}
              onClick={() => handleDocketEdit(selectedDocketId)}
              sx={{ px: 4, borderRadius: 2 }}
              size="small"
            >
              Edit
            </Button>
          )}
        </Box>
        <Typography variant="body2" sx={{ mt: 2, ml: 4 }}>
          This breakout group is for a closed group discussion with a subset of
          Suite members. All discussions, files and chats within this breakout
          group will be visible ONLY to members of this breakout group
        </Typography>
        {docketPermissions.checkCanAccessMembers(selectedDocket) ? (
          <Box
            sx={{ display: "flex", justifyContent: "flex-start", pl: 4, mt: 2 }}
          >
            <ProfilesChipsList
              usersList={selectedDocket?.members}
              dense={true}
              size={40}
              showAs={"avatar"}
            />
          </Box>
        ) : (
          <></>
        )}

        <Box sx={{ px: 3, pt: 3, pb: 1 }}>
          {docketPermissions.checkCanStartDocketWalkinCall(selectedDocket) && (
            <Card
              sx={{
                display: "flex",
                justifyContent: "space-between",

                height: "100%",
              }}
            >
              <CardHeader
                sx={{ width: "100%", py: 4 }}
                title={
                  <Typography variant="subtitle1">
                    Start a walk-In Videocall within your Breakout team
                  </Typography>
                }
                action={
                  <Button
                    variant="contained"
                    onClick={async () =>
                      await handleDocketWalkinVideocallClick(selectedDocketId)
                    }
                    startIcon={isMobile ? <VideoCall /> : null}
                  >
                    {isMobile ? "" : "Walk-in Call"}
                  </Button>
                }
              />
            </Card>
          )}
        </Box>
        {!docketPermissions.checkCanViewDocket(selectedDocket) ? (
          <Paper
            sx={{
              mt: 2,
              p: 2,
              width: "100%",
              minHeight: 100,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="h6">
              As you are not a member of this breakout group, you do not have
              access to resources within the Breakout.
            </Typography>
          </Paper>
        ) : (
          <Grid container sx={{ pt: 3, m: 0.3 }}>
            {docketActionOptions.map((docketOption, index) => {
              return (
                <Grid
                  item
                  xs={6}
                  md={4}
                  key={index}
                  alignItems="stretch"
                  sx={{ display: "flex", flexDirection: "column", p: 3 }}
                >
                  <DocketActionCard action={docketOption} />
                </Grid>
              );
            })}
          </Grid>
        )}
      </PerfectScrollbar>
    </FlowBox>
  );
};
export default React.memo(DisplaySelectedDocket);
