import * as React from "react";
import {
  useTheme,
  Box,
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Chip,
  Typography,
} from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";

function getStyles(name, statusName, theme) {
  return {
    fontWeight:
      statusName?.findIndex((item) => item === name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function StatusFilterSelectionDropdown({
  selectedStatusFilterValue,
  onStatusSelect,
  statusOptions,
  sx = {},
}) {
  const theme = useTheme();
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    onStatusSelect(value);
  };

  return (
    <FormControl sx={{ width: 300, ...sx }}>
      <Select
        size="small"
        multiple
        sx={{ p: 0 }}
        displayEmpty
        IconComponent={ArrowDropDown}
        placeholder="Select Status to view"
        value={selectedStatusFilterValue}
        onChange={handleChange}
        input={
          <OutlinedInput
            id="select-multiple-chip"
            placeholder={"Select Status"}
            sx={{
              fontSize: theme.typography.caption.fontSize,
              fontStyle: "normal",
              color: theme.palette.gray.main,
            }}
          />
        }
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <em>Select Status</em>;
          }
          return (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, ml: -1 }}>
              {selected.map((option) => {
                return (
                  <Chip
                    size="small"
                    key={option}
                    label={<Typography variant="caption">{option}</Typography>}
                  />
                );
              })}
            </Box>
          );
        }}
      >
        {statusOptions.map((statusLabel) => (
          <MenuItem
            key={statusLabel.value}
            value={statusLabel.value}
            style={getStyles(
              statusLabel.value,
              selectedStatusFilterValue,
              theme
            )}
          >
            {statusLabel.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
