// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  Chip,
  Avatar,
  Tooltip,
  Card,
  CardHeader,
  CardActionArea,
} from "@mui/material";
import PropTypes from "prop-types";
import { getS3ImageWrapper, checkIfNull } from "@app21/core";

import clip from "text-clipper";
import path from "path";

const ShowFilesList = (props) => {
  const { filesList, showAs, dense, handleFileClick } = props;

  const ShowFileComponent = ({ handleFileClick, fileInfo, showAs, dense }) => {
    if (showAs === "chip") {
      return Boolean(fileInfo) ? (
        <Chip
          sx={{
            "& .MuiChip-root": { m: 2 },
            "& .MuiChip-sizeSmall": { m: 2, p: 0 },
            "& .MuiChip-avatarSmall": { m: 0, p: 0, height: 30, width: 30 },
            "& .MuiChip-labelSmall": { fontSize: 10, fontWeight: 300 },
          }}
          size={dense ? "small" : "medium"}
          avatar={
            <Avatar
              alt={fileInfo?.fileName}
              src={getS3ImageWrapper(fileInfo?.image, "file")}
            />
          }
          label={clip(path.basename(fileInfo?.s3Key), 25)}
          variant="outlined"
          onClick={() => handleFileClick(fileInfo)}
        />
      ) : null;
    } else {
      return (
        <Tooltip title={fileInfo.fileName} arrow>
          {fileInfo && (
            <Card>
              <CardActionArea onClick={() => handleFileClick(fileInfo)}>
                <CardHeader
                  avatar={
                    <Avatar
                      src={getS3ImageWrapper(fileInfo?.image, "person")}
                      variant="rounded"
                      alt={fileInfo.fileName}
                      sx={{
                        ...(dense
                          ? { height: 30, width: 30, marginLeft: -5 }
                          : { height: 40, width: 40, margin: 5 }),
                      }}
                    />
                  }
                  title={fileInfo.fileName}
                />
              </CardActionArea>
            </Card>
          )}
        </Tooltip>
      );
    }
  };
  if (checkIfNull(filesList)) {
    return null;
  } else if (Array.isArray(filesList)) {
    return (
      <span style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
        {filesList.map((fileInfo, index) => (
          <ShowFileComponent
            key={index}
            fileInfo={fileInfo}
            showAs={showAs}
            dense={dense}
            handleFileClick={handleFileClick}
          />
        ))}
      </span>
    );
  } else if (typeof filesList === "object") {
    return (
      <ShowFileComponent fileInfo={filesList} showAs={showAs} dense={dense} />
    );
  } else return null;
};
ShowFilesList.propTypes = {
  filesList: PropTypes.any.isRequired,
  dense: PropTypes.bool,
  showAs: PropTypes.oneOf(["chip", "block", null, undefined]),
};
ShowFilesList.defaultProps = {
  filesList: [],
  dense: true,
  showAs: "block",
};

export default ShowFilesList;
