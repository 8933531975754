// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { isString } from "lodash";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import { useDropzone } from "react-dropzone";
import fileFill from "@iconify/icons-eva/file-fill";
import closeFill from "@iconify/icons-eva/close-fill";
import { motion, AnimatePresence } from "framer-motion";
// material
import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  List,
  Link,
  Stack,
  Paper,
  Button,
  ListItem,
  Typography,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
} from "@mui/material";
// utils
import { fData, checkIfNull } from "@app21/core";
//
import { IconButtonAnimate } from "blocks/atoms";
import { varFade } from "blocks/atoms/uieffects/animate";
import { UploadIllustration } from "blocks/atoms/graphics";
import { useForm, useFieldArray, FormProvider } from "react-hook-form";
import UploadSingleFileComponent from "./UploadSingleFileComponent";
import mime from "mime-types";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

UploadSingleFileDialog.propTypes = {
  error: PropTypes.bool,
  showPreview: PropTypes.bool,
  open: PropTypes.bool,
  file: PropTypes.any,
  onSubmit: PropTypes.func,
  title: PropTypes.any,
  onRemove: PropTypes.func,
  onClose: PropTypes.func,
  sx: PropTypes.object,
};

export default function UploadSingleFileDialog({
  file = null,
  sx,
  open,
  loading,
  onSubmit,
  title = "Add File",
  onClose,
  maxSize,
  ...other
}) {
  const [uploadFile, setUploadFile] = React.useState(
    Array.isArray(file) ? file : file ? [file] : null
  );

  React.useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      Array.isArray(uploadFile) &&
        uploadFile?.forEach((file) => URL.revokeObjectURL(file?.preview));
    },
    [uploadFile]
  );

  const handleFileDrop = (data) => {
    let dataWithPreview = data?.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    let dataWithConfirmedContentType = dataWithPreview?.map((file) => {
      if (checkIfNull(file?.type)) {
        return Object.defineProperty(file, "type", {
          value: mime.lookup(file.name?.split(".")?.pop()),
          writable: true,
          enumerable: true,
          configurable: true,
        });
      } else {
        return file;
      }
    });
    setUploadFile(dataWithConfirmedContentType);
  };
  const handleFileRemove = () => {
    setUploadFile(null);
  };

  const handleClose = () => {
    setUploadFile([]);
    onClose();
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <UploadSingleFileComponent
          onDrop={handleFileDrop}
          onRemove={handleFileRemove}
          loading={loading}
          file={uploadFile && uploadFile[0]}
          maxFiles={1}
          maxSize={maxSize}
          title={title}
          sx={sx}
          {...other}
        />
      </DialogContent>
      <DialogActions>
        <Stack direction="row" justifyContent="flex-end">
          <Button onClick={handleClose} sx={{ mr: 1.5 }}>
            Close
          </Button>
          <Button
            variant="contained"
            disabled={
              loading || !Boolean(uploadFile) || uploadFile?.length === 0
            }
            onClick={() => onSubmit(uploadFile)}
          >
            {loading ? <CircularProgress color="secondary" size={20} /> : "Add"}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
