// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useEffect, useRef } from "react";
import {
  useAudioVideo,
  useApplyVideoObjectFit,
} from "amazon-chime-sdk-component-library-react";
import CustomVideoTile from "./CustomVideoTile";
import { checkIfNull } from "@app21/core";
import { LoadingSpinner } from "blocks/atoms";

const CustomRemoteTile = ({ pipRef, tile, ...rest }) => {
  const audioVideo = useAudioVideo();
  const videoEl = useRef();
  useApplyVideoObjectFit(videoEl);
  const active = tile?.videoEnabled ?? false;

  useEffect(() => {
    if (
      !audioVideo ||
      (typeof tile?.tileId === "string" && tile?.tileId?.includes("blank"))
    ) {
      return;
    }
    if (
      !(typeof tile?.tileId === "string" && tile?.tileId?.includes("blank"))
    ) {
      audioVideo.bindVideoElement(
        tile?.tileId,
        checkIfNull(pipRef) ? videoEl?.current : pipRef?.current
      );
    }

    return () => {
      const tileDetails = audioVideo.getVideoTile(tile?.tileId);
      if (tileDetails) {
        audioVideo.unbindVideoElement(tile?.tileId);
      }
    };
  }, [audioVideo, tile.tileId, active, pipRef]);

  // <StyledLocalVideo active={active} nameplate={nameplate} ref={videoEl} {...rest} />;
  if (!tile) return <LoadingSpinner variant="exp" />;
  return (
    <CustomVideoTile
      ref={checkIfNull(pipRef) ? videoEl : pipRef}
      pipRef={pipRef}
      active={active}
      tile={tile}
      context={"remote"}
      {...rest}
    />
  );
};

export default CustomRemoteTile;
