import React from "react";
import {
  Box,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  LinearProgress,
  List,
  ListItem,
  ListSubheader,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  useTheme,
} from "@mui/material";

import { UserAvatar, SkeletonList } from "blocks/atoms";
import { getS3ImageWrapper, checkIfNull } from "@app21/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import { msToTime } from "utils/timeHelperUtils";
import { format } from "date-fns";
import { formatBytes, parseStringDate } from "utils";
import { capitalCase } from "change-case";
// import dynamic from "next/dynamic";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as ChartTooltip,
  Legend,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  LineChart,
  Line,
  Tooltip,
  ComposedChart,
} from "recharts";

export const RADIAN = Math.PI / 180;

export const renderCustomizedLabel = (props) => {
  const { cx, cy, midAngle, innerRadius, outerRadius, value } = props;

  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <>
      <text
        x={x > cx ? x : x + 10}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${Number(value).toFixed(0)}`}
      </text>
    </>
  );
};

const DisplayMeetingsList = ({ displayMeetings = [] }) => {
  return (
    <div style={{ padding: 5 }}>
      <ul>
        {displayMeetings.map((displayMeeting, index) => {
          !checkIfNull(displayMeeting?.createdAt) && (
            <li key={index}>
              {format(new Date(displayMeeting?.createdAt), "hh:mm a")} - (
              {msToTime(displayMeeting?.metadata?.totalDurationMs)})
            </li>
          );
        })}
      </ul>
    </div>
  );
};
// const customizedBarChartLabel = props => {
//   const { label, active, payload, ...rest } = props;

//   return (
//     <Box
//       sx={{
//         borderRadius: '5px',
//         p: 2,
//         background: '#fff',
//         boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
//       }}
//     >
//       {/* <Typography sx={{ color: 'blue' }}>{(label != undefined) && label.toDateString()}</Typography> */}
//       <Typography sx={{ color: 'blue' }}>{label}</Typography>
//       {active && payload && payload.length >= 1 ? (
//         <Typography>Total Views : {msToTime(parseInt(payload[0].value, 10))}</Typography>
//       ) : (
//         <></>
//       )}
//     </Box>
//   );
// };

const CreditsConsolidatedPieChart = ({
  suiteCreditsConsolidatedUsageData = [],
}) => {
  // const {
  //   Legend,
  //   PieChart,
  //   Pie,
  //   Cell,
  //   ResponsiveContainer,

  //   Tooltip,
  // } = dynamic(() => import("recharts"), {
  //   ssr: false,
  // });
  return (
    <ResponsiveContainer width="100%" height={250}>
      <PieChart>
        <Legend
          layout="horizontal"
          wrapperStyle={{ margin: 0 }}
          verticalAlign="bottom"
          height={36}
          align="center"
        />
        <Tooltip />
        <Pie
          data={suiteCreditsConsolidatedUsageData}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={"80%"}
          fill="#8884d8"
          dataKey="value"
        >
          {suiteCreditsConsolidatedUsageData?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} minAngle={15} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export const SuiteCreditsConsolidatedPieChart = React.memo(
  CreditsConsolidatedPieChart
);

const StorageCreditsBarChart = ({ suiteCreditsStorageChartData = [] }) => {
  // const {
  //   BarChart,
  //   Bar,
  //   XAxis,
  //   YAxis,
  //   CartesianGrid,
  //   Legend,
  //   ResponsiveContainer,

  //   Tooltip
  // } = dynamic(() => import("recharts"), {
  //   ssr: false
  // });
  return (
    <ResponsiveContainer width="100%" height={250}>
      <BarChart data={suiteCreditsStorageChartData} barSize={20}>
        <XAxis
          dataKey="key"
          scale="point"
          padding={{ left: 10, right: 10 }}
          tickFormatter={(tickDate) =>
            !checkIfNull(tickDate) ? format(new Date(tickDate), "dd MM") : ""
          }
        />

        <YAxis dataKey="value" />
        <Tooltip
          content={(props) => {
            const { active, payload, label } = props;
            if (active && payload && payload.length > 0) {
              const { key, bytesUsed, value } = payload[0].payload;
              return (
                <Box
                  sx={{
                    borderRadius: "5px",
                    p: 2,
                    background: "#fff",
                    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  }}
                >
                  {!checkIfNull(key) && (
                    <Typography>
                      {format(new Date(key), "p - dd/MM/yy")}
                    </Typography>
                  )}

                  <Typography>{formatBytes(bytesUsed)} used</Typography>
                  <Typography>Credits - {value} used</Typography>
                </Box>
              );
            }
            return <></>;
          }}
        />
        <Legend />
        <CartesianGrid strokeDasharray="3 3" />
        <Bar dataKey="value" fill="#8884d8" background={{ fill: "#eee" }} />
      </BarChart>
    </ResponsiveContainer>
  );
};
export const SuiteStorageCreditsBarChart = React.memo(StorageCreditsBarChart);

export const SuiteRecordingCreditsBarChart = ({ suiteRecordingsData = [] }) => {
  const theme = useTheme();
  // const {
  //   Bar,
  //   XAxis,
  //   YAxis,
  //   CartesianGrid,
  //   Tooltip,
  //   ResponsiveContainer,
  //   Line,
  //   ComposedChart
  // } = dynamic(() => import("recharts"), {
  //   ssr: false
  // });

  const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value } = props;
    const radius = 10;

    return (
      <g style={{ marginBottom: "4px" }}>
        <circle
          cx={x + width / 2}
          cy={y - radius}
          r={radius}
          fill={theme.palette.primary.main}
        />
        <text
          x={x + width / 2}
          y={y - radius}
          style={{ fontSize: "10px" }}
          fill="#fff"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {Math.round(value)}
        </text>
      </g>
    );
  };
  return (
    <ResponsiveContainer width="100%" height={250}>
      <ComposedChart data={suiteRecordingsData} name="value">
        <XAxis
          dataKey="recordingDate"
          padding={{ left: 1, right: 1 }}
          tickFormatter={(tickDate) => parseStringDate(tickDate, "dd MM")}
        />
        <YAxis
          orientation="left"
          yAxisId="left"
          stroke="#8884d8"
          label={{
            value: "Credits used",
            angle: -90,
            position: "insideLeft",
            offset: 15,
          }}
        />
        <YAxis
          orientation="right"
          yAxisId="right"
          stroke="#82ca9d"
          label={{
            value: "Recording Mins",
            angle: -90,
            position: "insideRight",
            offset: 15,
          }}
        />

        <CartesianGrid />
        <Tooltip
          content={(props) => {
            const { active, payload, label } = props;
            if (active && payload && payload.length > 0) {
              const { key, value, entries } = payload[0].payload;
              return (
                <Box
                  sx={{
                    borderRadius: "5px",
                    p: 2,
                    background: "#fff",
                    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  }}
                >
                  <Typography>{key}</Typography>
                  <Typography>
                    Credits Used {Number(value).toPrecision(4)}
                  </Typography>
                  {entries && (
                    <Typography>Total recordings {entries.length}</Typography>
                  )}
                </Box>
              );
            }
            return <></>;
          }}
        />

        <Bar yAxisId="left" dataKey="value" fill={theme.palette.primary.main} />
        <Line
          yAxisId="right"
          type="monotone"
          dataKey="recordingDurationMins"
          stroke={theme.palette.secondary.main}
        />
        {/* <LabelList dataKey="value" content={renderCustomizedLabel} /> */}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export const SuiteAICreditsLineChart = ({ suiteAICreditsUsageData = [] }) => {
  // const {
  //   XAxis,
  //   YAxis,
  //   CartesianGrid,
  //   Tooltip,
  //   Legend,
  //   ResponsiveContainer,
  //   LineChart,
  //   Line
  // } = dynamic(() => import("recharts"), {
  //   ssr: false
  // });
  return (
    <ResponsiveContainer width="100%" height={250}>
      <LineChart
        data={suiteAICreditsUsageData}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="value" stroke="#8884d8" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export const SuiteMeetingCreditsBarChart = ({
  suiteMeetingCreditsUsageData = [],
}) => {
  const theme = useTheme();
  // const {
  //   BarChart,
  //   Bar,
  //   XAxis,
  //   YAxis,
  //   CartesianGrid,
  //   Tooltip,
  //   ResponsiveContainer
  // } = dynamic(() => import("recharts"), {
  //   ssr: false
  // });
  const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value } = props;
    const radius = 10;

    return (
      <g style={{ marginBottom: "4px" }}>
        <circle
          cx={x + width / 2}
          cy={y - radius}
          r={radius}
          fill={theme.palette.primary.main}
        />
        <text
          x={x + width / 2}
          y={y - radius}
          style={{ fontSize: "10px" }}
          fill="#fff"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {Math.round(value)}
        </text>
      </g>
    );
  };
  return (
    <ResponsiveContainer width="100%" height={250}>
      <BarChart data={suiteMeetingCreditsUsageData}>
        <XAxis
          dataKey="key"
          tickFormatter={(tickDate) => parseStringDate(tickDate, "dd")}
        />
        <YAxis dataKey="value" />
        <CartesianGrid />
        <Tooltip
          content={(props) => {
            const { active, payload, label } = props;
            if (active && payload && payload.length > 0) {
              const { key, value, entries } = payload[0].payload;
              return (
                <Box
                  sx={{
                    borderRadius: "5px",
                    p: 2,
                    background: "#fff",
                    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  }}
                >
                  <Typography>{key}</Typography>
                  <Typography>
                    Credits Used {Number(value).toPrecision(4)}
                  </Typography>
                  {!checkIfNull(entries) && (
                    <>
                      <Typography>Total meetings {entries.length}</Typography>
                      <br />
                      <DisplayMeetingsList displayMeetings={entries} />
                    </>
                  )}
                </Box>
              );
            }
            return <></>;
          }}
        />

        <Bar dataKey="value" fill={theme.palette.primary.main}>
          {/* <LabelList dataKey="value" content={renderCustomizedLabel} /> */}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

const UserTableRow = (props) => {
  const { value, user } = props;

  if (!user) return <SkeletonList />;

  return (
    <ListItem disableGutters>
      <ListItemAvatar>
        <UserAvatar
          userId={user?._id}
          alt={user.fullName}
          src={getS3ImageWrapper(user?.image, "person")}
        />
      </ListItemAvatar>
      <ListItemText
        primary={<Typography variant="subtitle2"> {user.fullName}</Typography>}
        secondary={
          <Typography variant="caption">
            {" "}
            {capitalCase(user?.roleInfo?.role ?? "")}
          </Typography>
        }
      />
      <ListItemSecondaryAction>
        <Typography variant="body2" color="textPrimary">
          {Number(value).toPrecision(1)}
        </Typography>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export const SuiteUserRoleCreditsUsageChart = ({
  suiteUserRoleCreditsUsageData = [],
}) => {
  return (
    <Paper>
      <PerfectScrollbar style={{ maxHeight: 250 }}>
        <List
          stickyHeader
          size="small"
          subheader={<li />}
          sx={{ maxWidth: 250 }}
          dense
        >
          <ListSubheader>
            <Typography variant="h6">
              {" "}
              Users Role-wise Credits Usage{" "}
            </Typography>
          </ListSubheader>
          {suiteUserRoleCreditsUsageData?.map((user, idx) => (
            <UserTableRow
              key={idx}
              userId={user.key}
              user={user.user}
              value={user.value}
            />
          ))}
        </List>
      </PerfectScrollbar>
    </Paper>
  );
};

export const OverallCreditsUsageProgressBar = ({
  suiteCreditsUtilizedSummary,
}) => {
  const { usedCredits, totalCredits } = suiteCreditsUtilizedSummary ?? {};

  if (!totalCredits) {
    return <></>;
  }
  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }
  const progress = (usedCredits / totalCredits) * 100;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="body2" color="textSecondary">
          Credits - Used : {Math.round(usedCredits)}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Total : {totalCredits}
        </Typography>
      </Box>
      <LinearProgressWithLabel
        value={progress}
        used={usedCredits}
        total={totalCredits}
      />
    </Box>
  );
};
