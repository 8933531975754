import * as React from "react";
import {
  Checkbox,
  ListItemText,
  ListItemIcon,
  ListItem,
  Avatar,
  Divider,
  ListItemAvatar,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Box,
  TextField,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import {
  getS3ImageWrapper,
  useHandleOrganizationActions,
  useHandleSuiteActions,
  useAppContext,
} from "@app21/core";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";
import { constantCase } from "change-case";
import { useConfirm } from "material-ui-confirm";
import { Label } from "blocks/atoms";

export default function UserListCard({
  handleToggle,
  user,
  selLeft,
  selRight,
  keyValue,
  usersInOrganization,
}) {
  const confirm = useConfirm();
  const { handleSuiteActions, detachUserFromSuiteStatus } =
    useHandleSuiteActions();
  const { handleOrganizationActions, detachUserFromOrganizationStatus } =
    useHandleOrganizationActions();
  const {
    userInfo,
    selectedSuiteId,
    selectedOrganizationId,
    checkAccessRules,
  } = useAppContext();

  const [roleType, selRoleType] = React.useState(user?.roleInfo?.role);

  const allRoleOptionLabels = [
    { label: "Suite Manager", value: "MANAGER" },
    { label: "Normal User", value: "NORMAL" },
    { label: "Guest", value: "GUEST" },
  ];

  const handleRoleType = (event) => {
    selRoleType(event.target.value);
    handleRoleChangeClick(event.target.value, null, null);
  };

  const labelId = `transfer-list-item-${user._id}-label`;

  const actionSelectPopupState = usePopupState({
    variant: "popover",
    popupId: "actionsMenu",
  });

  const handleDetachUser = async (user, context) => {
    actionSelectPopupState?.close();
    if (
      constantCase(user?.roleInfo?.role ?? "") === "ADMIN" &&
      !checkIfNotLastAdminUser(usersInOrganization)
    ) {
      confirm({
        title: "Alert!",
        description:
          "You cannot remove the last Administrator from the organization.",
        confirmationText: "Close",
      })
        .then(() => {
          console.log("closed");
        })
        .catch(() => {
          console.log("Cancelled.");
        })
        .finally(() => {
          console.log("deleted.");
        });
    } else {
      const entityPhrase =
        context === "organization-panel" ? "organization" : "suite";
      const questionPhrase =
        user._id === userInfo._id
          ? `You are removing yourself from the ${entityPhrase}. You will lose access to the resource once completed. Proceed?`
          : `Are you sure you want to remove ${user.fullName} from the ${entityPhrase}?`;

      confirm({
        description: questionPhrase,
      })
        .then(() => {
          if (context === "organization-panel") {
            handleOrganizationActions({
              action: "DETACH-USER",
              userId: user._id,
            });
          } else {
            handleSuiteActions({ action: "DETACH-USER", userId: user._id });
          }
        })
        .catch(() => {
          console.log("Deletion cancelled.");
        })
        .finally(() => {
          console.log("Deletion cancelled.");
        });
    }
  };

  const checkIfNotLastAdminUser = (usersList) => {
    let sum = 0;
    let notLastAdminFlag = true;
    notLastAdminFlag = usersList?.some((user) => {
      if (constantCase(user.roleInfo?.role ?? "") === "ADMIN") {
        sum++;
      }
      if (sum > 1) {
        return true;
      } else return false;
    });
    return notLastAdminFlag;
  };

  const handleRoleChangeClick = async (
    targetRole,
    roleSelectPopupState,
    context
  ) => {
    roleSelectPopupState?.close();

    if (
      constantCase(user?.roleInfo?.role ?? "") === "ADMIN" &&
      !checkIfNotLastAdminUser(usersInOrganization)
    ) {
      confirm({
        title: "Alert!",
        description:
          "You cannot remove the last Administrator from the organization.",
        confirmationText: "Close",
      })
        .then(() => {
          console.log("closed");
        })
        .catch(() => {
          console.log("Cancelled.");
        });
    } else {
      if (context === "organization-panel") {
        await handleOrganizationActions({
          action: "CHANGE-USER-ROLE",
          userId: user?._id,
          role:
            constantCase(user?.roleInfo?.role ?? "") === "ADMIN"
              ? "NORMAL"
              : "ADMIN",
        });
      } else {
        await handleSuiteActions(
          {
            action: "CHANGE-USER-ROLE",
            userId: user?._id,
            role: targetRole,
          },
          null,
          null
        );
      }
    }
  };

  return (
    <>
      <ListItem key={user._id} role="listitem" button>
        <ListItemIcon>
          {keyValue == "left" ? (
            <Checkbox
              onClick={handleToggle(user, keyValue)}
              checked={
                selLeft.find((orgUser, index) => orgUser._id === user._id)
                  ? true
                  : false
              }
              tabIndex={-1}
              disableRipple
              inputProps={{
                "aria-labelledby": labelId,
              }}
            />
          ) : (
            <Checkbox
              onClick={handleToggle(user, keyValue)}
              checked={
                selRight.find((orgUser, index) => orgUser._id === user._id)
                  ? true
                  : false
              }
              tabIndex={-1}
              disableRipple
              inputProps={{
                "aria-labelledby": labelId,
              }}
            />
          )}
        </ListItemIcon>
        <ListItemAvatar>
          <Avatar
            src={getS3ImageWrapper(user?.image, "person")}
            variant="rounded"
          />
        </ListItemAvatar>
        <ListItemText
          id={labelId}
          primary={
            <Box>
              <Typography variant="h5">{user.fullName}</Typography>
              {user?.roleInfo?.role === "ADMIN" && keyValue == "left" && (
                <Label
                  variant="ghost"
                  color="secondary"
                  sx={{
                    ml: 1,
                    mt: -1,
                  }}
                >
                  <Typography variant="caption">Admin</Typography>
                </Label>
              )}
            </Box>
          }
        />
        {keyValue == "left" ? (
          <IconButton {...bindTrigger(actionSelectPopupState)}>
            <MoreVert />
          </IconButton>
        ) : (
          <TextField
            id="select-role-type"
            select
            value={roleType}
            onChange={handleRoleType}
            size="small"
            sx={{ my: 2 }}
          >
            {allRoleOptionLabels.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        )}
      </ListItem>
      <Divider sx={{ mt: 2 }} />
      {user?.roleInfo?.role && (
        <Menu {...bindMenu(actionSelectPopupState)}>
          <MenuItem
            onClick={() =>
              handleRoleChangeClick(
                null,
                actionSelectPopupState,
                "organization-panel"
              )
            }
          >
            {user?.roleInfo?.role === "ADMIN"
              ? "Change Role to Normal"
              : "Make Admin"}
          </MenuItem>
          <MenuItem
            onClick={() => handleDetachUser(user, "organization-panel")}
          >
            Remove
          </MenuItem>
        </Menu>
      )}
    </>
  );
}
