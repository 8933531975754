import React from "react";
import {
  Box,
  Container,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import DisplayMinutesDoc from "blocks/modules/Dockets/foundations/showmodules/DisplayMinutesDoc";
import { LoadingSpinner } from "blocks/atoms";
import { useDocketsContext } from "providers/DocketsProvider";
import { useDebounce } from "use-debounce";
import { useHandleMeetingActions } from "@app21/core";
import { useConfirm } from "material-ui-confirm";
import toast from "react-hot-toast";

export default function ShowMinutesDialog({
  meeting,
  minutesDialogFlag,
  onClose,
}) {
  const [localMinutes, setLocalMinutes] = React.useState(
    meeting?.minutes?.replace(/\n/g, "<br>")
  );
  const [debouncedMinutes] = useDebounce(localMinutes, 10000);
  const confirm = useConfirm();
  const { handleMeetingActions } = useHandleMeetingActions();
  React.useEffect(() => setLocalMinutes(meeting?.minutes), [meeting?.minutes]);
  React.useEffect(() => {
    const updateMinutes = async () =>
      await handleMeetingActions(
        {
          action: "UPDATE-MEETING",
          meetingData: {
            minutes: localMinutes,
          },
          meetingId: meeting?._id,
        },
        null,
        null
      );
    if (
      debouncedMinutes === localMinutes &&
      debouncedMinutes !== meeting?.minutes
    )
      updateMinutes();
  }, [debouncedMinutes]);

  const onUploadedDocumentSave = async (data, actionType) => {
    if (actionType === "delete") {
      confirm({
        description:
          "Are you sure you want to delete the Minutes document. All the documents contents will be erased and cannot be reversed?",
      })
        .then(async () => {
          await handleMeetingActions(
            {
              action: "UPDATE-MEETING",
              meetingData: {
                minutes: null,
              },
              meetingId: meeting?._id,
            },
            null,
            null
          );

          toast("Minutes Deleted");
          onClose();
        })
        .catch((err) => {
          console.log("Deletion cancelled.", err);
        });
    } else {
      await handleMeetingActions(
        {
          action: "UPDATE-MEETING",
          meetingData: {
            minutes: data,
          },
          meetingId: meeting?._id,
        },
        null,
        null
      );
    }
  };
  return (
    <Dialog open={minutesDialogFlag} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>Minutes</DialogTitle>
      <DialogContent>
        <DisplayMinutesDoc
          meeting={meeting}
          minutes={localMinutes}
          fetchMeetingStatus={"idle"}
          onChange={(value) => setLocalMinutes(value)}
          onUploadedDocumentSave={onUploadedDocumentSave}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
