export const chimeTestIds = {
  contentShareControl: "chime-content-share-control",
  audioInputControl: "chime-audio-input-control",
  videoInputControl: "chime-video-input-control",
  endMeetingControl: "chime-end-meeting-control",
  meetingInfoControl: "chime-meeting-info-control",
  chatMessageControl: "chat-message-control",
  layoutChoiceControl: "chime-layout-choice-control",
  recordMeetingControl: "chime-record-meeting-control",
};

export const adminModuleTestIds = {
  // Ashraf to suggest a few
};

export const actionsModuleTestIds = {
  // Ashraf to suggest a few
};

export const fileServerTestIds = {
  // Ashraf to suggest a few
};

export const fileViewerTestIds = {
  // Ashraf to suggest a few
};

export const meetingRecordsTestIds = {
  // Ashraf to suggest a few
};

export const schedulerModuleTestIds = {
  // Ashraf to suggest a few
};

export const surveysModuleTestIds = {
  surveyAddButton: "add-new-survey",
  individualSurveyDetails: "goto-survey-details",
  surveyDetails: "survey-details",
};

export const chatModuleTestIds = {
  unreadChatButtonTestId: "unread-chat",
  sendMessageInput: "input-send-message",
  sendMessageButtonId: "button-send-message",
  fileUploadButton: "button-file-upload",
};

export const menuActionTestIds = {
  logoutDataTestId: "button-logout",
  popupMenuItemTestId: "item-popup-menu",
  userAvatarMenuButtonTestId: "button-user-avatar-menu",
  notificationPopupButtonTestId: "button-notification",
  conferenceButtonTestId: "button-conference",
  joinUpcomingMeetingTestId: "join-upcoming-meeting",
  scheduleVideoCallTestId: "schedule-video-call",
  walkinCallTestId: "walkin-call",
};
export const miscellaneousTestIds = {};
