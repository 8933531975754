import { faNavicon } from "@fortawesome/free-solid-svg-icons";
import { atom } from "jotai";

export const ThemeAtom = atom("light");
export const showChatSidebarFlagAtom = atom(false);
export const CurrentChannelAtom = atom("");
export const SubscribeChannelsAtom = atom([]);
export const SubscribeChannelGroupsAtom = atom([]);
export const UsersMetaAtom = atom([]);
export const MessagesAtom = atom({});
export const PaginationAtom = atom({});
export const TypingIndicatorAtom = atom({});
export const TypingIndicatorTimeoutAtom = atom(10);
export const SearchFilterAtom = atom("");
export const OriginalMessageOnReplyAtom = atom({});
export const OriginalMessageInReplyClickedAtom = atom({});
export const RefetchOnNetworkReconnectAtom = atom(false);

const retryOnError = async (fn) => {
  const retryOptionsProp = {
    maxRetries: 1,
    timeout: 0,
    exponentialFactor: 1,
  };
  const { maxRetries, timeout, exponentialFactor } = retryOptionsProp;
  for (let i = 0; i < maxRetries; i++) {
    try {
      return await fn();
    } catch (error) {
      if (maxRetries === i + 1) throw error;
      await new Promise((resolve) =>
        setTimeout(resolve, timeout * exponentialFactor ** i)
      );
    }
  }
};
export const RetryFunctionAtom = atom({
  function: (fn) => retryOnError(fn),
});

export const ErrorFunctionAtom = atom({
  function: () => null,
});

export const CurrentChannelMessagesAtom = atom(
  (get) =>
    get(MessagesAtom) ? get(MessagesAtom)[get(CurrentChannelAtom)] || [] : [],
  (get, set, value) =>
    set(
      MessagesAtom,
      Object.assign({}, get(MessagesAtom), { [get(CurrentChannelAtom)]: value })
    )
);

export const CurrentChannelPaginationAtom = atom(
  (get) => get(PaginationAtom)[get(CurrentChannelAtom)] || false,
  (get, set, value) =>
    set(
      PaginationAtom,
      Object.assign({}, get(PaginationAtom), {
        [get(CurrentChannelAtom)]: value,
      })
    )
);

export const CurrentChannelTypingIndicatorAtom = atom(
  (get) => get(TypingIndicatorAtom)[get(CurrentChannelAtom)] || {},
  (get, set, value) =>
    set(
      TypingIndicatorAtom,
      Object.assign({}, get(TypingIndicatorAtom), {
        [get(CurrentChannelAtom)]: value,
      })
    )
);
