import React from 'react'
import { SvgIcon } from '@mui/material';

export default function NotificationIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M75.0837 49.1662V37.1245C75.0837 23.3745 63.8337 12.1245 50.0837 12.1245C36.292 12.1245 25.0837 23.3328 25.0837 37.1245V49.1662C25.0837 51.7078 24.0004 55.5828 22.7087 57.7495L17.917 65.7079C14.9587 70.6245 17.0004 76.0829 22.417 77.9162C40.3754 83.9162 59.7504 83.9162 77.7087 77.9162C82.7504 76.2495 84.9587 70.2912 82.2087 65.7079"
          stroke="currentColor"
          strokeWidth="6.67188"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M57.7903 13.334C56.4987 12.959 55.1653 12.6673 53.7903 12.5007C49.7903 12.0007 45.957 12.2923 42.3737 13.334C43.582 10.2507 46.582 8.08398 50.082 8.08398C53.582 8.08398 56.582 10.2507 57.7903 13.334Z"
          stroke="currentColor"
          strokeWidth="6.67188"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M62.5824 79.416C62.5824 86.291 56.9574 91.916 50.0824 91.916C46.6657 91.916 43.4991 90.4994 41.2491 88.2494C38.9991 85.9994 37.5824 82.8327 37.5824 79.416"
          stroke="currentColor"
          strokeWidth="6.67188"
          strokeMiterlimit="10"
        />
      </g>
    </SvgIcon>
  );
}
