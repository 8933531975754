import React, { useMemo, useState } from "react";
import { FlowBox } from "blocks/atoms/uistyles";
import PerfectScrollbar from "react-perfect-scrollbar";
import { LoadingSpinner } from "blocks/atoms";
import { useIntl } from "react-intl";
import { useAppContext } from "@app21/core";
import { useDocketsContext } from "providers/DocketsProvider";
import { DisplaySidebarDocketCard } from "blocks/modules/Dockets/foundations";

export default function ShowSidebarDocketsList({}) {
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });
  const { userInfo } = useAppContext();

  const { baseDocketsStatus, sortedDocketsList = [] } = useDocketsContext();

  if (baseDocketsStatus === "loading") {
    return <LoadingSpinner type="exp" />;
  }
  return (
    <FlowBox sx={{ overflow: "auto" }}>
      <PerfectScrollbar
        options={{ suppressScrollX: true }}
        style={{ paddingRight: 10 }}
      >
        {sortedDocketsList.map((docketObj, index) => {
          return <DisplaySidebarDocketCard key={index} docketObj={docketObj} />;
        })}
      </PerfectScrollbar>
    </FlowBox>
  );
}
