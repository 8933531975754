// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { useAppContext, useSuite } from "@app21/core";
import { Box, Typography } from "@mui/material";
import { pxToRem } from "utils";

const SuiteTypeLabelView = ({
  menuViewMode = "compact",
  suiteType = "EXEC",
  ...rest
}) => {
  const getSuiteDashboardViewComponent = (suiteType) => {
    switch (true) {
      case suiteType?.includes("DATA"):
        return menuViewMode === "full" ? "Data Suite" : "Data";
      case suiteType?.includes("EXEC"):
        return menuViewMode === "full" ? "Executive" : "Exec";
      case suiteType?.includes("PERSONAL"):
        return menuViewMode === "full" ? "Personal" : "Personal";
      case suiteType?.includes("BOARD"):
        return menuViewMode === "full" ? "Board Suite" : "Board";
      default:
        return "Trryst";
    }
  };

  return (
    <Box
      sx={{
        p: 1,
        textAlign: "center",
        color: (theme) => theme.palette.grey[0],
        bgcolor: "primary.light",
        borderRadius: (theme) => theme.shape.borderRadius,
        width: menuViewMode === "full" ? pxToRem(100) : pxToRem(55),
        mb: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: menuViewMode === "full" ? "11px" : "9px",
      }}
      {...rest}
    >
      <Typography variant="caption" sx={{ textAlign: "center" }}>
        {getSuiteDashboardViewComponent(suiteType)}
      </Typography>
    </Box>
  );
};
export default SuiteTypeLabelView;
