import * as React from "react";
import {
  Box,
  TextField,
  Grid,
  Typography,
  IconButton,
  MenuItem,
  Card,
  Divider,
} from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";
import { InviteNewUser } from "blocks/modules/Admin/foundations";
import UserListCard from "./UserListCard";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {
  useAppContext,
  useUsersInOrganization,
  useUsersInSuite,
  useHandleSuiteActions,
  useOrganization,
} from "@app21/core";

export default function AddUsersToNewSuite() {
  const { selectedOrganizationId, selectedSuiteId, userInfo } = useAppContext();
  const { data: selectedOrganization, apiError: organizationsApiEror } =
    useOrganization(selectedOrganizationId);

  const { data: usersInOrganization, apiError: usersInOrganizationApiError } =
    useUsersInOrganization(selectedOrganizationId);
  const { data: usersInSuite } = useUsersInSuite(selectedSuiteId);
  const { handleSuiteActions, addUserToSuiteStatus } = useHandleSuiteActions();

  const [selLeft, setSelLeft] = React.useState([]);
  const [selRight, setSelRight] = React.useState([]);
  const [selSuite, setSelSuite] = React.useState("accounting");

  const handleSuiteSelect = (event) => {
    setSelSuite(event.target.value);
  };

  const handleToggle = (newUser, key) => () => {
    if (key == "left") {
      const currentIndex = selLeft.findIndex((el) => el._id == newUser._id);
      const userList = [...selLeft];
      selLeft.find((user, index) => user._id === newUser._id)
        ? userList.splice(currentIndex, 1)
        : userList.push(newUser);
      setSelLeft([...userList]);
    } else if (key == "right") {
      const currentIndex = selRight.findIndex((el) => el._id == newUser._id);
      const userList = [...selRight];
      selRight.find((user, index) => user._id === newUser._id)
        ? userList.splice(currentIndex, 1)
        : userList.push(newUser);
      setSelRight([...userList]);
    }
  };

  const handleCheckedRight = () => {
    selLeft.map((user, index) => {
      addUserToSuite(user);
    });
    setSelLeft([]);
  };

  const handleCheckedLeft = () => {
    selRight.map((user, index) => {
      detachUserFromSuite(user);
    });
    setSelRight([]);
  };

  const addUserToSuite = async (user) => {
    await handleSuiteActions(
      {
        action: "ADD-USER",
        userList: [{ userId: user?._id, role: "Normal" }],
        selectedSuiteId,
      },
      null,
      null
    );
  };

  const detachUserFromSuite = async (user) => {
    handleSuiteActions({ action: "DETACH-USER", userId: user._id });
  };

  return (
    <Grid container spacing={1}>
      <Grid
        item
        xs={12}
        md={5}
        sx={{
          p: 2,
          my: 4,
        }}
      >
        <Card
          sx={{
            p: 2,
            boxShadow: (theme) => theme.customShadows.z20,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              mb: 3,
            }}
          >
            <Typography variant="h5" sx={{}}>
              Users currently in your Organization
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ display: "flex", my: 3, justifyContent: "space-between" }}>
            <Typography variant="h5" color="primary">
              {selectedOrganization.fullName}
            </Typography>
            <InviteNewUser icon={false} label="INVITE" />
          </Box>
          {usersInOrganization.map((user, index) => {
            if (!usersInSuite?.some((obj) => obj._id === user?._id)) {
              return (
                <UserListCard
                  handleToggle={handleToggle}
                  user={user}
                  selLeft={selLeft}
                  selRight={selRight}
                  keyValue="left"
                  key={user._id}
                  usersInOrganization={usersInOrganization}
                />
              );
            }
          })}
        </Card>
      </Grid>
      <Grid item xs={12} md={1} style={{ marginTop: "10%" }}>
        <Grid container direction="column" alignItems="center">
          <IconButton
            sx={{ my: 10 }}
            color="primary"
            onClick={handleCheckedRight}
            disabled={selLeft.length == 0}
            aria-label="move selected right"
          >
            <ArrowRightIcon sx={{ fontSize: 40 }} />
          </IconButton>
          <IconButton
            sx={{ my: 10 }}
            color="primary"
            onClick={handleCheckedLeft}
            disabled={selRight.length == 0}
            aria-label="move selected left"
          >
            <ArrowLeftIcon sx={{ fontSize: 40 }} />
          </IconButton>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          p: 2,
          my: 4,
        }}
      >
        <Card
          sx={{
            p: 2,
            boxShadow: (theme) => theme.customShadows.z20,
            height: "100%",
          }}
        >
          <Typography variant="h5">Members in Your New Suite</Typography>
          <Divider sx={{ my: 3 }} />
          {usersInSuite &&
            usersInSuite.map((user) => {
              return (
                <UserListCard
                  handleToggle={handleToggle}
                  user={user}
                  selLeft={selLeft}
                  selRight={selRight}
                  keyValue="right"
                  key={user._id}
                  usersInOrganization={usersInOrganization}
                />
              );
            })}
        </Card>
      </Grid>
    </Grid>
  );
}
