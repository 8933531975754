import React, { useMemo, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Switch,
  Button,
  TextField,
  SvgIcon,
  InputAdornment,
  Popover,
  Card,
  Chip,
  AvatarGroup,
  Grid,
  Tooltip,
} from "@mui/material";
import { Label, MoreActionsDropdownMenu } from "blocks/atoms/uicomponents";
import {
  LoadingSpinner,
  UserAvatar,
  ExpandingSearchIconButton,
} from "blocks/atoms";
import dynamic from "next/dynamic";
import {
  ViewTimeline,
  Feed,
  Search,
  Close,
  MoreVert,
  HourglassEmpty,
  Delete,
  DeleteSweep,
} from "@mui/icons-material";
import { showTimeDuration } from "utils/formatTimeUtils";
import {
  StyledRoundedTabs,
  StyledRoundedTab,
  StyledRoundedTabList,
  FlowBox,
} from "blocks/atoms/uistyles";

import { SortRecordingsPopover, AddMarkerMenu } from "..";
import AddMarkerPopover from "../../features/AddMarkerPopover";
import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from "material-ui-popup-state/hooks";

import { format } from "date-fns";
import {
  useUserInfo,
  useSelectedBucketId,
  executeApi,
  checkIfNull,
} from "@app21/core";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import { applySearchFilter } from "utils/applySearchFilter";

import ReactPlayer from "react-player";
import { useIsMobileBreakpoint } from "hooks";
import PerfectScrollbar from "react-perfect-scrollbar";

import videoLoadingAnimation from "blocks/atoms/lottieanimations/video-processing";
import {
  ShowDetailedTranscript,
  ShowAggregatedTranscript,
  ShowMagicSummary,
} from "blocks/modules/MeetingRecords";
import { useRecordingsContext } from "providers/RecordingsProvider";

const defaultAnimationOptions = {
  loop: true,
  autoplay: true,
  animationData: videoLoadingAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const TranscriptionTimelineWithMedia = ({
  activeTranscriptIndex,
  transcription = {},
  TimelineChip,
}) => {
  //  const [VIDEO_SKIP_SECONDS, setVIDEO_SKIP_SECONDS] = React.useState(3.0711);
  const {
    filteredMeetings,
    recordingsPermissions,
    selectedMeetingId,
    searchContent,
    handleDeleteRecording,
    handleDeleteTranscription,
  } = useRecordingsContext();
  const meeting =
    filteredMeetings?.find((meeting) => meeting._id === selectedMeetingId) ??
    {};
  const inputSearchString = searchContent;
  const isMobile = useIsMobileBreakpoint(800);
  const Lottie = dynamic(() => import("lottie-react"), { ssr: false });
  // //const ReactPlayer = dynamic(() => import("react-player"), { ssr: false });
  const [timelineView, setTimelineView] = React.useState("summary");
  const [processedTranscriptSummary, setProcessedTranscriptSummary] =
    React.useState([]);
  const [playedSeconds, setPlayedSeconds] = React.useState(0);
  const [videoTotalDuration, setVideoTotalDuration] = React.useState(0);
  const [videoOffsetSeconds, setVideoOffsetSeconds] = React.useState(0);
  const { data: loggedInUser } = useUserInfo();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [markerModalOpen, setMarkerModalOpen] = React.useState(false);
  const [meetMarkerValue, setMeetMarkerValue] = React.useState("");
  const [selectedKey, setSelectedKey] = React.useState(null);
  const [searchQuery, setSearchQuery] = React.useState(inputSearchString || "");
  React.useEffect(() => setSearchQuery(inputSearchString), [inputSearchString]);
  const [meetingMarkerVisibility, setMeetingMarkerVisibility] =
    React.useState("ONLY-ME");

  let videoPlayerRef = React.useRef(null);
  const activeTranscriptRef = React.useRef(null);

  const [audioUrl, setAudioUrl] = React.useState(null);
  const [videoUrl, setVideoUrl] = React.useState(null);
  const [markerTranscriptKey, setMarkerTranscriptKey] = React.useState(null);
  const addMarkerPopupState = usePopupState({
    variant: "popover",
    popupId: "note-specify-popover",
  });

  const selectedBucketId = useSelectedBucketId(null, true);

  const menuOptions = [
    ...(recordingsPermissions?.checkCanDeleteTranscription(transcription)
      ? [
          {
            label: "Delete Video Recording",
            icon: <Delete />,
            onClick: () => handleDeleteRecording(transcription),
          },
        ]
      : []),
    ...(recordingsPermissions?.checkCanDeleteRecording(transcription)
      ? [
          {
            label: "Delete Meeting Trancript",
            icon: <DeleteSweep />,
            onClick: () => handleDeleteTranscription(transcription),
          },
        ]
      : []),
  ];

  React.useEffect(() => {
    setMarkerModalOpen(Boolean(selectedKey));
  }, [selectedKey]);

  const handleSearchQueryChange = (event) => {
    event.persist();
    setSearchQuery(event.target.value);
  };

  const handleOpenContentMenuHandler = React.useCallback(
    (event) => event.preventDefault(),
    []
  );

  const handleSelectTimelineView = (event, value) => {
    setTimelineView(value);
  };

  const checkForMarkers = (transcript, meetingMarkers = []) => {
    let segmentMarkers = [];
    if (Array.isArray(meetingMarkers) && meetingMarkers.length > 0) {
      segmentMarkers = meetingMarkers.filter((marker) => {
        return (
          transcript.start <= marker?.startTimestamp &&
          marker?.startTimestamp <= transcript.end
        );
      });
    }
    return segmentMarkers;
  };
  React.useEffect(() => {
    if (transcription && !checkIfNull(transcription.processedTranscript)) {
      let summaryTranscription = [];
      let lastProcessedTranscript = {
        content: transcription.processedTranscript[0].content,
        start: Date(
          transcription?.processedTranscript[0]?.speaker?.startTimestamp ??
            transcription.processedTranscript[0].start
        ).valueOf(),
        relativeStartTime:
          transcription.processedTranscript[0].relativeStartTime,
        end: Date(transcription.processedTranscript[0].end).valueOf(),
        relativeEndTime: transcription.processedTranscript[0].relativeEndTime,
        speaker: {
          userId: transcription.processedTranscript[0].speaker?.userId,
        },
      };

      transcription.processedTranscript.forEach((transcript, index) => {
        const segmentMarkers = checkForMarkers(
          lastProcessedTranscript,
          transcription.meetingMarkers
        );

        if (index === 0) {
          if (index === transcription.processedTranscript.length - 1) {
            lastProcessedTranscript.meetingMarkers = segmentMarkers;
            summaryTranscription.push(lastProcessedTranscript);
          }
        } else if (
          lastProcessedTranscript.speaker.userId ===
            transcript.speaker?.userId &&
          lastProcessedTranscript.relativeEndTime <
            lastProcessedTranscript.relativeStartTime + 120000
        ) {
          lastProcessedTranscript.content =
            lastProcessedTranscript.content + " " + transcript.content;
          lastProcessedTranscript.end = Date(transcript.end).valueOf();
          lastProcessedTranscript.relativeEndTime = transcript.relativeEndTime;

          if (index === transcription.processedTranscript.length - 1) {
            lastProcessedTranscript.meetingMarkers = segmentMarkers;
            summaryTranscription.push(lastProcessedTranscript);
          }
        } else {
          lastProcessedTranscript.meetingMarkers = segmentMarkers;
          summaryTranscription.push(lastProcessedTranscript);
          lastProcessedTranscript = {
            content: transcript.content,
            start: Date(transcript.start).valueOf(),
            relativeStartTime: transcript.relativeStartTime,
            end: Date(transcript.end).valueOf(),
            relativeEndTime: transcript.relativeEndTime,
            speaker: { userId: transcript.speaker?.userId },
          };
          if (index === transcription.processedTranscript.length - 1) {
            lastProcessedTranscript.meetingMarkers = segmentMarkers;
            summaryTranscription.push(lastProcessedTranscript);
          }
        }
      });
      setProcessedTranscriptSummary(summaryTranscription);
    }
  }, [selectedMeetingId, transcription, activeTranscriptIndex]);

  const handleAddMeetingMarker = React.useCallback(
    async (key, content, meetingMarkerVisibility) => {
      const payload = {
        startTimestamp: key,
        markerType: content,
        markerVisibility: meetingMarkerVisibility,
        userId: loggedInUser._id,
        transcriptionId: transcription._id,
        action: "ADD",
      };
      await (
        await fetch(`${process.env.AWS_APIPATH}/transcript/marker/mutate`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        })
      ).json();
      setSelectedKey(null);
    },
    [(loggedInUser._id, transcription._id)]
  );

  const handleAddMarker = (markerArray) => {
    //nothing to be done here
  };

  const handleSelectMarkerTranscript = (key) => {
    setMarkerTranscriptKey(key);
  };
  // const handleAddMarker = (event, key) => {
  //   console.log("key", key);
  //   setAnchorEl(event.currentTarget);
  //   setSelectedKey(key);
  // };
  // console.log(
  //   videoOffsetSeconds,
  //   transcription,
  //   transcription.endTime,
  //   transcription.startTime
  // );
  React.useEffect(() => {
    if (transcription) {
      setVideoOffsetSeconds(
        (Date(transcription.endTime).valueOf() -
          Date(transcription.startTime).valueOf()) /
          1000 -
          videoTotalDuration
      );
    }
  }, [transcription, videoTotalDuration]);

  React.useEffect(() => {
    document.addEventListener("contextmenu", handleOpenContentMenuHandler);
    return () => {
      document.removeEventListener("contextmenu", handleOpenContentMenuHandler);
    };
  }, [handleOpenContentMenuHandler]);

  const loadAudioFromS3 = React.useCallback(
    async (key) => {
      if (!audioUrl) {
        const response = await executeApi("FETCH-SIGNED-URL", {
          bucket: selectedBucketId,
          key: key,
          urlTypeRequested: "get",
          contentType: "audio/wav",
          contentDisposition: "inline",
        });

        setAudioUrl(response.signedUrl || null);
      }
    },
    [selectedBucketId]
  );

  const loadVideoFromS3 = React.useCallback(
    async (key) => {
      const response = await executeApi("FETCH-SIGNED-URL", {
        bucket: selectedBucketId,
        key: key,
        urlTypeRequested: "get",
        contentType: "video/mp4",
        contentDisposition: "inline",
      });
      setVideoUrl(response?.signedUrl || null);
    },
    [selectedBucketId, transcription._id]
  );

  {
    /* React.useEffect(() => {
    // if (activeTranscriptRef.current)
    //   activeTranscriptRef.current.scrollIntoView({
    //     behavior: 'smooth',
    //     block: 'center',
    //     inline: 'center',
    //   });
  }, [VIDEO_SKIP_SECONDS, currentAudioTimeStamp]);
*/
  }

  const audioVideoKey = useMemo(
    () =>
      `${transcription.audioRecordingKey}-${transcription.videoRecordingKey}`,
    [transcription]
  );

  React.useEffect(() => {
    transcription.audioRecordingKey &&
      loadAudioFromS3(transcription.audioRecordingKey);
    transcription.videoRecordingKey &&
      loadVideoFromS3(transcription.videoRecordingKey);
  }, [
    loadAudioFromS3,
    loadVideoFromS3,
    audioVideoKey,
    transcription._id,
    meeting._id,
    selectedMeetingId,
  ]);

  const handleVideoProgress = (state) => {
    setPlayedSeconds(state.playedSeconds * 1000);
    setVideoTotalDuration(state.loadedSeconds);
    // We only want to update time slider if we are not currently seeking
  };

  const filteredProcessedTranscript = React.useMemo(() => {
    return applySearchFilter(processedTranscriptSummary, searchQuery, {}, [
      "content",
    ]);
  }, [activeTranscriptIndex, searchQuery, processedTranscriptSummary]);

  const filteredTimelineTranscript = React.useMemo(
    () =>
      applySearchFilter(transcription.processedTranscript, searchQuery, {}, [
        "content",
      ]),
    [activeTranscriptIndex, searchQuery, transcription.processedTranscript]
  );

  return (
    <FlowBox sx={{ overflow: "hidden" }}>
      <PerfectScrollbar
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          height: "100%",
          paddingRight: 10,
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",

            pt: 2,
          }}
        >
          <Grid container alignItems="stretch" spacing={2}>
            <Grid item xs={12} md={7} lg={6}>
              {videoUrl ? (
                <Box
                  sx={{
                    position: "relative",
                    width: `${1 * 100}%`,
                    alignSelf: "right",
                    display: "flex",
                    flexDirection: "column",

                    justifyContent: "center",
                    paddingTop: `${1 * 100 * (9 / 16)}%`, // 16:9 . 61.75%
                  }}
                >
                  <ReactPlayer
                    controls
                    playsinline
                    pip
                    width="100%"
                    height="100%"
                    onProgress={handleVideoProgress}
                    muted
                    url={videoUrl}
                    ref={videoPlayerRef}
                    style={{
                      position: "absolute",
                      top: 0,

                      left: 0,
                    }}
                  />
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography textAlign={"center"}>
                    Please wait while we process video for you
                  </Typography>
                  <Lottie
                    loop={true}
                    autoplay={true}
                    animationData={videoLoadingAnimation}
                    //  options={defaultAnimationOptions}
                    style={{ height: 250, width: 250, marginTop: -50 }}
                  />
                </Box>
              )}
            </Grid>
            <Grid item xs={12} md={5} lg={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      mb: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Label variant="ghost" color="success" sx={{ p: 3 }}>
                        {meeting.meetingType}
                      </Label>

                      {meeting?.state?.startedAt && meeting?.state?.endedAt && (
                        <Chip
                          label={showTimeDuration(
                            meeting?.state?.startedAt,
                            meeting?.state?.endedAt
                          )}
                          size="small"
                          sx={{ height: 20, ml: 5 }}
                          avatar={<HourglassEmpty />}
                        />
                      )}
                    </Box>
                    {menuOptions.length > 0 ? (
                      <MoreActionsDropdownMenu
                        menuOptions={menuOptions}
                        tooltipLabel="More Transcript Options"
                      />
                    ) : null}
                  </Box>
                  <Typography
                    variant="h5"
                    component="span"
                    sx={{ mb: 2, maxHeight: 20 }}
                  >
                    {meeting.meetingType === "GROUP-MEETING"
                      ? "Walk-In Group Call"
                      : meeting?.meetingJSON?.summary ?? "Trryst Meeting"}
                  </Typography>
                  <AvatarGroup
                    max={4}
                    sx={{
                      "& .MuiAvatar-root": { width: 20, height: 20 },
                      justifyContent: "flex-end",
                      mb: 2,
                    }}
                  >
                    {meeting?.meetingJSON?.members?.map((user, index) => {
                      if (user.emailId !== "app21chimerecorder@trryst.com") {
                        return (
                          <UserAvatar key={index} size={20} userId={user._id} />
                        );
                      }
                    })}
                  </AvatarGroup>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <TimelineChip />
                  <StyledRoundedTabs
                    value={timelineView}
                    onChange={handleSelectTimelineView}
                    selectionFollowsFocus
                  >
                    <StyledRoundedTabList>
                      <StyledRoundedTab value="summary" sx={{}}>
                        <Feed fontSize="small" />
                        <Typography variant="caption">Transcript</Typography>
                      </StyledRoundedTab>
                      {/* <StyledRoundedTab value="detailed">
                        <ViewTimeline fontSize="small" />
                        <Typography variant="caption">Detailed</Typography>
                      </StyledRoundedTab> */}

                      {transcription?.processedTranscript?.length > 10 && (
                        <StyledRoundedTab value="magic">
                          <AutoFixHighIcon fontSize="small" />
                          <Typography variant="caption">AI Summary</Typography>
                        </StyledRoundedTab>
                      )}
                    </StyledRoundedTabList>
                  </StyledRoundedTabs>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                  <Tooltip title="Search within this Transcript">
                    <div>
                      <ExpandingSearchIconButton
                        searchQuery={searchQuery}
                        handleSearchQuery={(val) => setSearchQuery(val)}
                      />
                    </div>
                  </Tooltip>
                  {/* <TextField
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small">
                          <Search />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={() => {
                            setSearchQuery("");
                          }}
                        >
                          <Close fontSize="inherit" />
                        </IconButton>
                      </InputAdornment>
                    ),
                    // ),
                  }}
                  onChange={handleSearchQueryChange}
                  placeholder="Search transcript"
                  value={searchQuery}
                /> */}
                  {/* <SortRecordingsPopover />
                <RecordingsDetailsFilter meeting={meeting} /> */}
                </Box>
              </Box>
            </Grid>
          </Grid>

          {transcription.status === "COMPLETED" ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                height: "100%",
                overflow: "hidden",
                minHeight: "40vh",
                p: 1,
              }}
            >
              {timelineView === "summary" && (
                <ShowAggregatedTranscript
                  filteredProcessedTranscript={filteredProcessedTranscript}
                  playedSeconds={playedSeconds}
                  videoOffsetSeconds={videoOffsetSeconds}
                  activeTranscriptRef={activeTranscriptRef}
                  videoPlayerRef={videoPlayerRef}
                  setPlayedSeconds={setPlayedSeconds}
                  handleSelectMarkerTranscript={handleSelectMarkerTranscript}
                  handleAddMeetingMarker={handleAddMeetingMarker}
                  addMarkerPopupState={addMarkerPopupState}
                  searchQuery={searchQuery}
                  transcription={transcription}
                />
              )}
              {timelineView === "detailed" && (
                <ShowDetailedTranscript
                  filteredTimelineTranscript={filteredTimelineTranscript}
                  playedSeconds={playedSeconds}
                  videoOffsetSeconds={videoOffsetSeconds}
                  activeTranscriptRef={activeTranscriptRef}
                  videoPlayerRef={videoPlayerRef}
                  searchQuery={searchQuery}
                />
              )}
              {timelineView === "magic" && (
                <ShowMagicSummary
                  meeting={meeting}
                  transcription={transcription}
                />
              )}
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Typography variant="h6">
                Transcript is being processed. Please wait...
              </Typography>
            </Box>
          )}
          <AddMarkerMenu
            addMarkerPopupState={addMarkerPopupState}
            handleAddMarker={(key, markerArray) =>
              handleAddMarker(key, markerArray)
            }
          />
          <Popover
            open={markerModalOpen}
            anchorEl={anchorEl}
            onClose={(e) => {
              setMarkerModalOpen(false);
              setSelectedKey(null);
              setAnchorEl(null);
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Box
              sx={{
                background: "#fff",
                p: 3,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
                <Typography>Marker Title:</Typography>
                <TextField
                  sx={{ marginLeft: "10px" }}
                  variant="outlined"
                  margin="dense"
                  value={meetMarkerValue ?? ""}
                  onChange={(e) => setMeetMarkerValue(e.target.value)}
                />
              </Box>
              <Box>
                <Typography
                  variant="caption"
                  color={
                    meetingMarkerVisibility === "ALL" ? "default" : "primary"
                  }
                  sx={{
                    fontWeight:
                      meetingMarkerVisibility === "ALL" ? "normal" : "bold",
                  }}
                >
                  Only Me
                </Typography>
                <Switch
                  onChange={(e) =>
                    e.target.checked
                      ? setMeetingMarkerVisibility("ALL")
                      : setMeetingMarkerVisibility("ONLY-ME")
                  }
                  value={meetingMarkerVisibility === "ALL"}
                />{" "}
                <Typography
                  variant="caption"
                  color={
                    meetingMarkerVisibility === "ALL" ? "primary" : "default"
                  }
                  sx={{
                    fontWeight:
                      meetingMarkerVisibility === "ALL" ? "bold" : "normal",
                  }}
                >
                  Visible to All Invited
                </Typography>
              </Box>

              <Button
                onClick={(e) =>
                  handleAddMeetingMarker(selectedKey, meetMarkerValue)
                }
                variant="contained"
              >
                Add
              </Button>
            </Box>
          </Popover>
        </Box>
      </PerfectScrollbar>
    </FlowBox>
  );
};

export default TranscriptionTimelineWithMedia;
