// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Box } from "@mui/material";
import { getSubscriptionPlanInfo } from "blocks/modules/Subscriptions";
import { MotionInView, varFade } from "blocks/atoms/uieffects/animate";
import { PricingPlanTemplateCard } from "blocks/modules/Subscriptions";

export function animateAction(index) {
  if (index % 3 === 0) {
    return varFade().inLeft;
  } else if (index % 3 === 2) {
    return varFade().inRight;
  } else return varFade().in;
}

export default function SubscriptionPlanTemplate({ option, handleChoice }) {
  const planOptions = getSubscriptionPlanInfo(option);

  return (
    <MotionInView
      variants={animateAction(Math.floor(Math.random() * (3 - 0) + 0))}
      style={{ height: "100%" }}
    >
      <PricingPlanTemplateCard
        card={planOptions}
        currentPlan={"PERSONAL-SUITE-BASIC"}
        handleChoice={handleChoice}
      />
    </MotionInView>
  );
}
