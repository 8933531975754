// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import {
  Card,
  CardHeader,
  CardMedia,
  Chip,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Avatar,
  Box,
} from "@mui/material";

import { blueGrey } from "@mui/material/colors";
import { MoreVert } from "@mui/icons-material";
import {
  useDetachUserFromOrganization,
  useDetachUserFromSuite,
  useUsersInOrganization,
  useUsersInSuite,
  useAppContext,
  getS3ImageWrapper,
  useHandleSuiteActions,
  useHandleOrganizationActions,
} from "@app21/core";
import { useConfirm } from "material-ui-confirm";

import React, { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";
import { constantCase } from "change-case";

import SkeletonUserCard from "blocks/atoms/SkeletonUserCard";
import clip from "text-clipper";
import { useIsMobileBreakpoint } from "hooks";
import { Label } from "blocks/atoms";
import toast from "react-hot-toast";

const UserCard = (props) => {
  const { index, user, context, dragKey } = props;
  const confirm = useConfirm();
  const isMobile = useIsMobileBreakpoint();
  const [isDeleting, setIsDeleting] = useState(false);
  const {
    userInfo,
    selectedSuiteId,
    selectedOrganizationId,
    checkAccessRules,
  } = useAppContext();

  const { data: usersInOrganization, apiError: usersInOrganizationApiError } =
    useUsersInOrganization(selectedOrganizationId);
  const { data: usersInSuite, apiError: usersInSuiteApiError } =
    useUsersInSuite(selectedSuiteId);

  const dataFetchError = usersInOrganizationApiError || usersInSuiteApiError;

  React.useEffect(() => {
    if (dataFetchError) {
      toast.error(dataFetchError.message);
    }
  }, [dataFetchError]);
  const { accessFlag: canDetachUser } = checkAccessRules({
    entity: context === "organization-panel" ? "ORGANIZATION" : "SUITE",
    action: context === "organization-panel" ? "DETACH-USER" : "DETACH-USERS",
    featureName: "MANAGEMENT-CONSOLE",
  });
  const { accessFlag: canChangeRole } = checkAccessRules({
    entity: context === "organization-panel" ? "ORGANIZATION" : "SUITE",
    action: "CHANGE-USER-ROLE",
    featureName: "MANAGEMENT-CONSOLE",
  });

  const allRoleOptionLabels = {
    MANAGER: "Suite Manager",
    NORMAL: "Normal User",
    GUEST: "Guest",
  };

  const { handleSuiteActions, detachUserFromSuiteStatus } =
    useHandleSuiteActions();
  const { handleOrganizationActions, detachUserFromOrganizationStatus } =
    useHandleOrganizationActions();
  const actionSelectPopupState = usePopupState({
    variant: "popover",
    popupId: "actionsMenu",
  });

  let userPresent = false;
  let isAdmin = false;
  let isManager = false;
  let isDraggable = false;

  switch (context) {
    case "organization-panel":
      if (selectedSuiteId) {
        if (usersInSuite?.some((obj) => obj._id === user?._id)) {
          userPresent = true;
        } else {
          isDraggable = true;
        }
      }

      usersInOrganization?.map((u) => {
        if (u._id === user._id) {
          if (constantCase(u?.roleInfo?.role ?? "") === "ADMIN") isAdmin = true;
        }
      });
      break;
    case "suite-panel":
      usersInSuite?.map((u) => {
        if (u._id === user._id) {
          if (constantCase(u?.roleInfo?.role ?? "") === "MANAGER")
            isManager = true;
        }
      });
      break;
    default:
      break;
  }

  const checkIfNotLastAdminUser = (usersInOrganization) => {
    let sum = 0;
    let notLastAdminFlag = true;
    notLastAdminFlag = usersInOrganization?.some((user) => {
      if (constantCase(user?.roleInfo?.role ?? "") === "ADMIN") {
        sum++;
      }
      if (sum > 1) {
        return true;
      } else return false;
    });
    return notLastAdminFlag;
  };

  const handleDetachUser = async (user) => {
    setIsDeleting(true);
    actionSelectPopupState?.close();
    if (
      constantCase(user?.roleInfo?.role ?? "") === "ADMIN" &&
      !checkIfNotLastAdminUser(usersInOrganization)
    ) {
      confirm({
        title: "Alert!",
        description:
          "You cannot remove the last Administrator from the organization.",
        confirmationText: "Close",
      })
        .then(() => {
          console.log("closed");
        })
        .catch(() => {
          console.log("Cancelled.");
        })
        .finally(() => {
          setIsDeleting(false);
        });
    } else {
      const entityPhrase =
        context === "organization-panel" ? "organization" : "suite";
      const questionPhrase =
        user._id === userInfo._id
          ? `You are removing yourself from the ${entityPhrase}. You will lose access to the resource once completed. Proceed?`
          : `Are you sure you want to remove ${user.fullName} from the ${entityPhrase}?`;

      confirm({
        description: questionPhrase,
      })
        .then(() => {
          if (context === "organization-panel") {
            handleOrganizationActions({
              action: "DETACH-USER",
              userId: user._id,
            });
          } else {
            handleSuiteActions({ action: "DETACH-USER", userId: user._id });
          }
        })
        .catch(() => {
          console.log("Deletion cancelled.");
        })
        .finally(() => {
          setIsDeleting(false);
        });
    }
  };

  const handleRoleChangeClick = async (targetRole, roleSelectPopupState) => {
    roleSelectPopupState?.close();

    if (
      constantCase(user?.roleInfo?.role ?? "") === "ADMIN" &&
      !checkIfNotLastAdminUser(usersInOrganization)
    ) {
      confirm({
        title: "Alert!",
        description:
          "You cannot remove the last Administrator from the organization.",
        confirmationText: "Close",
      })
        .then(() => {
          console.log("closed");
        })
        .catch(() => {
          console.log("Cancelled.");
        });
    } else {
      if (context === "organization-panel") {
        await handleOrganizationActions({
          action: "CHANGE-USER-ROLE",
          userId: user?._id,
          role: isAdmin ? "NORMAL" : "ADMIN",
        });
      } else {
        await handleSuiteActions(
          { action: "CHANGE-USER-ROLE", userId: user?._id, role: targetRole },
          null,
          null
        );
      }
    }
  };

  const getStyleInfo = (role) => {
    switch (role) {
      case "MANAGER":
        return "error";
      case "ADMIN":
        return "success";
      case "NORMAL":
        return "accent";
      case "GUEST":
        return "info";
      case "ORGANIZER":
        return "primary";
      default:
        return "secondary";
    }
  };

  const getRoleMenuOptions = (role, roleSelectPopupState) => {
    const allOptions = ["MANAGER", "NORMAL", "GUEST"];
    allOptions.splice(
      allOptions.findIndex(
        (allRoles) => constantCase(allRoles ?? "") === constantCase(role ?? "")
      ),
      1
    );

    return allOptions.map((allRole, index) => (
      <MenuItem
        key={index}
        onClick={() => handleRoleChangeClick(allRole, roleSelectPopupState)}
      >
        {allRoleOptionLabels[allRole]}
      </MenuItem>
    ));
  };

  const NameAndRoleInfo = ({ context }) => {
    const roleSelectPopupState = usePopupState({
      variant: "popover",
      popupId: "roleSelectMenu",
    });

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="body1"
            sx={{
              ...(isAdmin
                ? {
                    color: (theme) => theme.palette.primary.main,
                    fontWeight: 500,
                    display: "flex",
                    textAlign: "center",
                  }
                : { display: "flex", textAlign: "center" }),
            }}
          >
            {clip(user?.fullName ?? "", 25)}
          </Typography>
          {isAdmin && (
            <Label
              variant="ghost"
              color="secondary"
              sx={{
                ml: 1,
                mt: -1,
              }}
            >
              <Typography variant="caption">Admin</Typography>
            </Label>
          )}
        </Box>
        {context === "suite-panel" && (
          <Chip
            sx={{ mt: isMobile ? 0.5 : 0, width: isMobile ? "70%" : "auto" }}
            size="small"
            color={getStyleInfo(constantCase(user?.roleInfo?.role ?? ""))}
            label={allRoleOptionLabels[constantCase(user?.roleInfo.role ?? "")]}
            {...bindTrigger(roleSelectPopupState)}
          />
        )}
        {canChangeRole && (
          <Menu
            {...bindMenu(roleSelectPopupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            {getRoleMenuOptions(user?.roleInfo?.role, roleSelectPopupState)}
          </Menu>
        )}
      </Box>
    );
  };
  if (
    detachUserFromSuiteStatus?.isLoading ||
    detachUserFromOrganizationStatus?.isLoading
  ) {
    return <SkeletonUserCard />;
  }

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        marginTop: 5,
        paddingRight: 13,
        paddingLeft: 5,
        marginRight: 5,
      }}
    >
      <Draggable
        draggableId={dragKey}
        index={index}
        isDragDisabled={!isDraggable}
      >
        {(provided, snapshot) => (
          <Card
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            sx={{
              borderRadius: 1,
              zIndex: 10,
              display: "flex",
              maxWidth: "100%",
              flexGrow: 1,
              border: "1px solid LightGrey",
              margin: 0,
              padding: 0,
              ...(userPresent || isDeleting
                ? { backgroundColor: blueGrey[100] }
                : null),
            }}
            elevation={
              userPresent || isDeleting
                ? 0
                : context === "organization-panel"
                ? 3
                : 0
            }
          >
            {isDeleting ? (
              <div
                style={{
                  display: "flex",
                  padding: 5,
                  justifyContent: "center",
                  width: "100%",
                  border: (theme) =>
                    `1px dashed ${theme.palette.primary.main} `,
                }}
              >
                <CircularProgress size={25} />
              </div>
            ) : (
              <React.Fragment>
                {context === "organization-panel" ? null : (
                  <CardMedia
                    component="img"
                    sx={{
                      maxWidth: 50,
                      backgroundSize: "cover",
                      borderRadius: "5px 0 0 5px",
                      maxHeight: 50,
                    }}
                    src={getS3ImageWrapper(user?.image, "person")}
                    title={clip(user?.fullName ?? "", 25)}
                    onError={(e) =>
                      (e.target.src = getS3ImageWrapper(null, "person"))
                    }
                  />
                )}
                <CardHeader
                  sx={{ p: 1, m: 0, flexGrow: 1 }}
                  avatar={
                    context !== "organization-panel" ? null : (
                      <Avatar
                        src={getS3ImageWrapper(user?.image, "person")}
                        variant="rounded"
                      />
                    )
                  }
                  title={<NameAndRoleInfo context={context} />}
                  subheader={clip(user?.emailId ?? "", 25)}
                  action={
                    <IconButton {...bindTrigger(actionSelectPopupState)}>
                      <MoreVert />
                    </IconButton>
                  }
                />
                <Menu {...bindMenu(actionSelectPopupState)}>
                  {context === "organization-panel" && canChangeRole && (
                    <MenuItem onClick={handleRoleChangeClick}>
                      {isAdmin ? "Change Role to Normal" : "Make as Admin"}
                    </MenuItem>
                  )}
                  {canDetachUser && (
                    <MenuItem onClick={() => handleDetachUser(user)}>
                      {context === "organization-panel"
                        ? "Delete User"
                        : "Remove from Suite"}
                    </MenuItem>
                  )}
                </Menu>
              </React.Fragment>
            )}
          </Card>
        )}
      </Draggable>
    </div>
  );
};
export default UserCard;
