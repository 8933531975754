import React from "react";
import { Card, Avatar, CardHeader, Icon } from "@mui/material";
import { Close, CheckCircle } from "@mui/icons-material";
import clip from "text-clipper";
import { IconButtonAnimate, CircularProgressWithLabel } from "blocks/atoms";

import { varFade } from "blocks/atoms/uieffects/animate";
import { isString } from "lodash";
import { motion } from "framer-motion";
import { fData } from "@app21/core";
import fileFill from "@iconify/icons-eva/file-fill";
import { alpha } from "@mui/material/styles";
import axios from "axios";

export default function UploadFileListItemComponent({
  index,
  uploadingFile,
  showPreview,
  onRemove,
  onUploadProcessed,
}) {
  const file = uploadingFile ? uploadingFile.file : null;
  const postAPIUrl = uploadingFile?.postAPIUrl;

  const defaultAttachmentImage = (event) => {
    event.target.src = "/static/images/AttachmentIcon.png";
  };
  const [uploadedBytes, setUploadedBytes] = React.useState(0);

  const uploadedPercentage = React.useMemo(
    () => Math.round((uploadedBytes / file.size) * 100),
    [file.size, uploadedBytes]
  );

  React.useEffect(() => {
    if (uploadedPercentage === 100) if (onUploadProcessed) onUploadProcessed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedPercentage]);

  const initializeFileUploadWithUploadProgress = React.useCallback(
    async (file, url) => {
      try {
        await axios.put(url, file, {
          headers: {
            "Content-Type": file.type,
          },
          onUploadProgress: (progress) => {
            setUploadedBytes(progress.loaded);
          },
        });
      } catch (error) {
        console.log(error);
      }
    },
    []
  );

  React.useEffect(() => {
    if (file && postAPIUrl) {
      initializeFileUploadWithUploadProgress(file, postAPIUrl);
    }
  }, [file, initializeFileUploadWithUploadProgress, postAPIUrl]);

  return (
    <Card
      component={motion.div}
      {...varFade().inRight}
      sx={{
        width: "100%",
        my: 0.5,
        flexGrow: 1,
        border: "1px solid Lightgrey",
        borderRadius: 1.5,
        overflow: "hidden",
        position: "relative",
      }}
    >
      <CardHeader
        sx={{
          p: 1,
          display: "flex",
          flexDirection: "space-between",
          alignItems: "center",
        }}
        avatar={
          uploadedPercentage > 0 ? (
            uploadedPercentage === 100 ? (
              <CheckCircle color="success" />
            ) : (
              <CircularProgressWithLabel value={uploadedPercentage} />
            )
          ) : showPreview ? (
            <Avatar
              src={isString(file) ? file : file?.preview}
              variant="rounded"
              alt={isString(file) ? file : file?.name}
              onError={defaultAttachmentImage}
            />
          ) : (
            <Avatar>
              <Icon icon={fileFill} width={28} height={28} />
            </Avatar>
          )
        }
        title={clip(isString(file) ? file : file?.name, 42, {
          breakWords: true,
        })}
        subheader={isString(file) ? "" : fData(file?.size)}
        titleTypographyProps={{ variant: "body2" }}
        subheaderTypographyProps={{ variant: "caption" }}
        action={
          <IconButtonAnimate
            disabled={uploadedPercentage === 100}
            size="small"
            onClick={() => onRemove(index)}
            sx={{
              mr: "3px",
              color: "common.white",
              bgcolor: (theme) => alpha(theme.palette.grey[500], 0.72),
              "&:hover": {
                bgcolor: (theme) => alpha(theme.palette.grey[700], 0.4),
              },
            }}
          >
            <Close fontSize="inherit" />
          </IconButtonAnimate>
        }
      />
    </Card>
  );
}
