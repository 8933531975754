import React, { useRef } from "react";
import { Box, Container, Typography, Grid, styled } from "@mui/material";
import FileViewer from "blocks/modules/FileViewer";
import { checkIfNull, useAppContext } from "@app21/core";
import { FlowBox } from "blocks/atoms/uistyles";
import { useFilesContext } from "providers/FilesProvider";
import { FilesListPanel } from "blocks/modules/TrrystFiles/foundations";

export default function FilesCenterPanel({
  viewContext = "SUITE",
  isMobile = false,
}) {
  const { viewFile } = useAppContext();
  const fileViewerFlag = !checkIfNull(viewFile);
  return (
    <FlowBox sx={{ pt: 2 }}>
      {fileViewerFlag ? (
        <FileViewer viewContext={viewContext} />
      ) : (
        <FlowBox
          sx={{
            overflow: "hidden",
          }}
        >
          <FlowBox sx={{}}>
            <FlowBox
              sx={{
                overflow: "hidden",
              }}
            >
              <FilesListPanel viewContext={viewContext} isMobile={isMobile} />
            </FlowBox>
          </FlowBox>
        </FlowBox>
      )}
    </FlowBox>
  );
}
