import React from "react";
import { Box, Typography } from "@mui/material";
import { FlowBox } from "blocks/atoms/uistyles";
import { useDocketsContext } from "providers/DocketsProvider";
import { BoardMeetingsListPanel } from "blocks/modules/Dockets/foundations";

export default function DocketUsageGuidancePanel({ dockets = [] }) {
  const { isBoardContext } = useDocketsContext();

  if (isBoardContext && dockets.length !== 0) {
    return <BoardMeetingsListPanel dockets={dockets} />;
  }
  return (
    <FlowBox sx={{ justifyContent: "center", alignItems: "center", mt: 2 }}>
      <Box sx={{ maxWidth: 450 }}>
        {dockets?.length === 0 ? (
          <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
            You are not a member of any{" "}
            {isBoardContext ? "scheduled Board meeting" : "breakout"}.{" "}
            {isBoardContext
              ? ""
              : " Breakouts are smaller groups of members where you can have secure conversations between a sub-group of your suite members"}
            . Create a new {isBoardContext ? "Board Meeting" : "Breakout"}.
          </Typography>
        ) : (
          <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
            You are a part of {dockets?.length}{" "}
            {isBoardContext ? "Board meeting" : "Breakout"}
            {dockets?.length > 1 ? "s" : ""}. <br />
            <br />
            Select a {isBoardContext ? "Board meeting" : "Breakout"} to view
            details or create a new one.
          </Typography>
        )}
      </Box>
    </FlowBox>
  );
}
