import React from 'react'
import { SvgIcon } from '@mui/material';

export default function DiskIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M25 26.0415V34.3748"
          stroke="currentColor"
          strokeWidth="5.96875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M41.6666 26.0415V34.3748"
          stroke="currentColor"
          strokeWidth="5.96875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.33337 37.5002C8.33337 16.6668 16.6667 8.3335 37.5 8.3335H62.5C83.3334 8.3335 91.6667 16.6668 91.6667 37.5002V62.5002C91.6667 83.3335 83.3334 91.6668 62.5 91.6668H37.5C16.6667 91.6668 8.33337 83.3335 8.33337 62.5002V50.0002H75"
          stroke="currentColor"
          strokeWidth="5.96875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25 66.6665V74.9998"
          stroke="currentColor"
          strokeWidth="5.96875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M41.6666 66.6665V74.9998"
          stroke="currentColor"
          strokeWidth="5.96875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M58.3333 30.209H74.9999"
          stroke="currentColor"
          strokeWidth="5.96875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M58.3333 70.834H74.9999"
          stroke="currentColor"
          strokeWidth="5.96875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
