// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  Button,
  Typography,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  useTheme,
} from "@mui/material";
import {
  useChimeState,
  useVideocallContext,
} from "providers/TrrystVideocallProvider";
import { VoiceFocusTransformDevice } from "amazon-chime-sdk-js";
import {
  CascadingMenu,
  CascadingMenuItem,
  CascadingSubmenu,
} from "blocks/atoms/uicomponents/cascadingMenuComponents";
import { useConfirm } from "material-ui-confirm";
import { usePopupState, bindToggle } from "material-ui-popup-state/hooks";
import {
  FiberManualRecord,
  Tune,
  StopCircle,
  Info,
  Construction,
  Addchart,
  Done,
} from "@mui/icons-material";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import VoiceOverOffIcon from "@mui/icons-material/VoiceOverOff";
import {
  executeApi,
  useAppContext,
  useSelectedBucketId,
  useUserInfo,
  useMeeting,
  useMeetingRecordingMetadata,
  useOrgsAndSuitesForUser,
  useHandleMeetingActions,
} from "@app21/core";

import toast from "react-hot-toast";
import MeetingInfoSnippet from "blocks/modules/TrrystVideocall/foundations/components/ConferenceControls/MeetingInfoControl/MeetingInfoSnippet";
import { pxToRem } from "utils/styleHelpers";
import CopyMeetingLinkControl from "../CopyMeetingLinkControl";
import {
  useMeetingManager,
  useVoiceFocus,
  useAudioInputs,
} from "amazon-chime-sdk-component-library-react";
import { findOrgIdFromSuiteId } from "utils";
import {
  layoutMenuOptions,
  layoutVisitorMenuOptions,
} from "blocks/modules/TrrystVideocall/foundations/components/ConferenceControls/LayoutChoiceControl/menuOptionsChoices";
import {
  MoreChoiceWebActiveIcon,
  MoreChoiceWebInactiveIcon,
} from "blocks/atoms/trrystCustomIcons";
import { handleToggleCollaboration } from "blocks/atoms/handlers";
import { StyledSquareMenuButton } from "blocks/atoms/uistyles";
import { chimeTestIds } from "blocks/atoms/constants";

const MoreChoicesMenuControl = ({ recommendedButtonSize = 40 }) => {
  const confirm = useConfirm();
  const { data: userInfo } = useUserInfo();
  const { data: orgsAndSuites } = useOrgsAndSuitesForUser(userInfo?._id);
  const { chimeViewMode, setChimeViewMode, isVisitorFlag, meetingId } =
    useAppContext();
  const meetingManager = useMeetingManager();

  const moreOptionsSelectPopupState = usePopupState({
    variant: "popover",
    popupId: "moreOptionsSelectMenu",
  });
  const layoutChoicesMenuOptions = usePopupState({
    variant: "popover",
    popupId: "layoutChoicesMenu",
  });
  const theme = useTheme();

  // const [voiceDevice] = React.useState(meetingManager.selectedAudioInputDevice);
  const { handleMeetingActions } = useHandleMeetingActions();

  const {
    isRecording,
    isRecordingBotReady,
    setChimeRoute,
    isCollaborationActive,
    setIsCollaborationActive,
  } = useVideocallContext();

  const selectedBucketId = useSelectedBucketId(null, true);
  // meetingId is taken from appContext and not chimeContext, because it will be not yet there in the Chime Context.
  // get inserted after the device screen.
  const { data: meeting } = useMeeting(meetingId);
  const { data: meetingRecordingMetadata } =
    useMeetingRecordingMetadata(meetingId);
  const meetingOrgId = findOrgIdFromSuiteId(meeting?.suiteId, orgsAndSuites);

  const collaborationParams = {
    userInfo,
    meeting,
    handleMeetingActions,
    setIsCollaborationActive,
    setChimeViewMode,
    meetingId,
  };

  const { isVoiceFocusEnabled, setIsVoiceFocusEnabled } = useChimeState();
  const { selectedDevice } = useAudioInputs();
  const { addVoiceFocus } = useVoiceFocus();

  React.useEffect(() => {
    async function toggleVF() {
      try {
        let current = selectedDevice;
        if (isVoiceFocusEnabled) {
          if (typeof selectedDevice === "string") {
            current = await addVoiceFocus(selectedDevice);
          }
        } else {
          if (selectedDevice instanceof VoiceFocusTransformDevice) {
            current = selectedDevice.getInnerDevice();
          }
        }
        await meetingManager.startAudioInputDevice(current);
      } catch (error) {
        // Handle device selection failure here
        console.error("Failed to toggle Voice Focus");
      }
    }

    toggleVF();
  }, [isVoiceFocusEnabled]);

  const handleChangeLayout = (value) => {
    setChimeViewMode(value);
    moreOptionsSelectPopupState?.close();
  };
  const handleAddMeetingMarker = React.useCallback(
    async (key, content, visibility) => {
      const payload = {
        startTimestamp: key,
        markerType: content,
        markerVisibility: visibility,
        userId: userInfo?._id,
        externalMeetingId: meetingId,
        action: "ADD",
      };
      await (
        await fetch(`${process.env.AWS_APIPATH}/transcript/marker/mutate`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        })
      ).json();
    },
    [userInfo?._id, meeting?._id]
  );

  const handleRecording = async (action) => {
    if (action === "start") {
      confirm({
        description:
          "Please ensure you have consent of all meeting participants prior to proceeding. Also please note that recording will take a few seconds (upto a minute) to start recording and transcribing the conversations.",
      })
        .then(async () => {
          const response = await executeApi("RECORD-CHIME-MEETING", {
            recordingAction: "recording-start",
            meetingId: meetingId,
            chimeMeetingId: meetingManager.meetingId,
            mediaCaptureBucket: selectedBucketId,
            organizationId: meetingOrgId,
            suiteId: meeting.suiteId,
            calledBy: userInfo?._id,
          });
        })
        .catch(() => {
          console.log("Recording cancelled.");
        })
        .finally(() => {
          // if anyting to be done
          moreOptionsSelectPopupState?.close();
        });
    } else if (action === "stop") {
      confirm({
        description:
          "We will now stop the recording. The processed recordings and transcript will be ready and in the Recordings and Transcripts area after around 2 minutes.",
      })
        .then(async () => {
          await executeApi("RECORD-CHIME-MEETING", {
            recordingAction: "recording-stop",
            meetingId: meetingId,
            chimeMeetingId: meetingManager.meetingId,
            mediaCaptureBucket: selectedBucketId,
            organizationId: meetingOrgId,
            suiteId: meeting.suiteId,
            calledBy: userInfo?._id,
          });
          //enqueueSnackbar('The meeting is no longer being recorded.', { variant: 'error', autoHideDuration: 3000 });
        })
        .catch(() => {
          console.log("recording Stop request cancelled.");
        })
        .finally(() => {
          // if anyting to be done
          moreOptionsSelectPopupState?.close();
        });
    } else if (action === "prepare-recording") {
      confirm({
        description:
          "We will start preparing the recording infrastructure. You can start recording in about a minute",
      })
        .then(async () => {
          await executeApi("PREPARE-CHIME-RECORDING", {
            clientTokenId: meetingId,
            calledBy: userInfo?._id,
            chimeMeetingId: meetingManager.meetingId,
            startRecordingOnStartup: "YES",
            organizationId: meetingOrgId,
          });
        })
        .catch(() => {
          console.log("preparing chime recording infrastructure cancelled.");
        })
        .finally(() => {
          // if anyting to be done
          moreOptionsSelectPopupState?.close();
        });
    }
  };

  const handleMeetingInfo = () => {
    toast.custom(
      <MeetingInfoSnippet popupState={moreOptionsSelectPopupState} />
    );
  };

  const markerMenuChoices = [
    {
      label: "Important",
      value: "important",
      action: () => {
        handleAddMeetingMarker(Date.now(), "Important", "ONLY-ME");
        moreOptionsSelectPopupState.close();
      },
    },
    {
      label: "Urgent",
      value: "urgent",
      action: () => {
        handleAddMeetingMarker(Date.now(), "Urgent", "ONLY-ME");
        moreOptionsSelectPopupState.close();
      },
    },
    {
      label: "Action Item",
      value: "actionitem",

      action: () => {
        handleAddMeetingMarker(Date.now(), "Action Item", "ONLY-ME");
        moreOptionsSelectPopupState.close();
      },
    },
    {
      label: "Review",
      value: "review",

      action: () => {
        handleAddMeetingMarker(Date.now(), "Review", "ONLY-ME");
        moreOptionsSelectPopupState.close();
      },
    },
  ];

  const menuChoiceOptions = [
    {
      label: "Prepare and Start Recording",
      value: "preparerecording",
      disabledFlag: Boolean(
        meetingRecordingMetadata?.data?.metadata?.ecsTaskArn
      ),
      icon: <Construction fontSize="inherit" />,
      action: async () => {
        await handleRecording("prepare-recording");
        moreOptionsSelectPopupState.close();
      },
    },
    {
      label: "Start Recording",
      value: "startrecording",
      icon: <FiberManualRecord color="error" fontSize="inherit" />,
      action: async () => {
        await handleRecording("start");
        moreOptionsSelectPopupState.close();
      },
    },
    {
      label: "Stop Recording",
      value: "stoprecording",
      disabledFlag: !isRecording,
      icon: <StopCircle fontSize="inherit" color="warning" />,
      action: async () => {
        await handleRecording("stop");
        moreOptionsSelectPopupState.close();
      },
    },

    {
      label: isVoiceFocusEnabled ? "Disable voice focus" : "Enable voice focus",
      value: "voicefocusoption",
      icon: isVoiceFocusEnabled ? (
        <VoiceOverOffIcon fontSize="inherit" />
      ) : (
        <RecordVoiceOverIcon fontSize="inherit" />
      ),
      action: () => {
        setIsVoiceFocusEnabled((current) => !current);
        moreOptionsSelectPopupState.close();
      },
    },
    // {
    //   label: "Meeting Information",
    //   value: "meetinginfo",
    //   icon: <Info fontSize="inherit" />,
    //   action: () => {
    //     handleMeetingInfo();
    //     moreOptionsSelectPopupState.close();
    //   },
    // },
    {
      label: "Discuss on a Whiteboard",
      value: "startwhiteboard",
      icon: <Addchart fontSize="inherit" />,
      action: () => {
        handleToggleCollaboration({
          type: "TRRYST-WHITEBOARD",
          action: "START-COLLABORATION",
          ...collaborationParams,
        });
        moreOptionsSelectPopupState.close();
      },
    },
    {
      label: "Stop Whiteboard Collaboration",
      value: "stopwhiteboard",
      icon: <Addchart fontSize="inherit" />,
      action: () => {
        handleToggleCollaboration({
          type: null,
          action: "STOP-COLLABORATION",
          ...collaborationParams,
        });
        moreOptionsSelectPopupState.close();
      },
    },
    {
      label: "Change videocall settings",
      value: "videocallsettings",
      icon: <Tune fontSize="inherit" />,
      action: () => {
        setChimeRoute("settings");
        moreOptionsSelectPopupState.close();
      },
    },
  ];

  const getMenuChoices = () => {
    let availableMenuChoices = [];
    menuChoiceOptions.forEach((option) => {
      switch (option.value) {
        case "preparerecording":
          if (!isRecordingBotReady) {
            availableMenuChoices.push(option);
          }
          break;
        case "startrecording":
          if (isRecordingBotReady && !isRecording) {
            availableMenuChoices.push(option);
          }
          break;
        case "stoprecording":
          if (isRecordingBotReady && isRecording) {
            availableMenuChoices.push(option);
          }
          break;
        case "voicefocusoption":
          availableMenuChoices.push(option);
          break;
        case "meetinginfo":
          availableMenuChoices.push(option);
          break;
        case "videocallsettings":
          availableMenuChoices.push(option);
          break;
        case "startwhiteboard":
          if (!isCollaborationActive) availableMenuChoices.push(option);

          break;
        case "stopwhiteboard":
          if (isCollaborationActive) availableMenuChoices.push(option);

          break;
        default:
          break;
      }
    });
    return availableMenuChoices;
  };

  const renderMenu = (
    <CascadingMenu
      popupState={moreOptionsSelectPopupState}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
    >
      {/* <BackgroundImageChangeControl
        controlType="menu"
        label="Background"
        RenderComponent={CascadingMenuItem}
        handleClick={() => moreOptionsSelectPopupState.close()}
      /> */}
      {getMenuChoices().map((choice) => (
        <CascadingMenuItem
          key={choice.value}
          onClick={choice.action}
          disabled={choice.disabledFlag}
        >
          <ListItemIcon>{choice.icon}</ListItemIcon>
          <ListItemText
            primary={
              <Typography color="textPrimary" variant="subtitle2">
                {choice.label}
              </Typography>
            }
          />
        </CascadingMenuItem>
      ))}
      <CascadingSubmenu
        popupId="moreChoicesCascadingMenu"
        title="Add Recording Markers"
        disabledFlag={!isRecording}
      >
        <ListSubheader>Mark segment in recording as...</ListSubheader>
        {markerMenuChoices.map((choice) => (
          <CascadingMenuItem
            key={choice.value}
            onClick={choice.action}
            disabled={choice.disabledFlag}
          >
            <ListItemIcon>
              {" "}
              {chimeViewMode === choice.value ? (
                <Done color="success" />
              ) : (
                choice.icon
              )}
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  {choice.label}
                </Typography>
              }
            />
          </CascadingMenuItem>
        ))}
      </CascadingSubmenu>
      <CascadingSubmenu
        popupId="layoutChoicesCascadingMenu"
        title="Change Layout"
      >
        <ListSubheader>Layout Options</ListSubheader>

        {(isVisitorFlag ? layoutVisitorMenuOptions : layoutMenuOptions).map(
          (choice) => (
            <CascadingMenuItem
              key={choice.value}
              onClick={() => handleChangeLayout(choice.value)}
              disabled={chimeViewMode === choice.value}
            >
              <ListItemIcon>
                {" "}
                {chimeViewMode === choice.value ? (
                  <Done color="success" />
                ) : (
                  choice.icon
                )}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography color="textPrimary" variant="subtitle2">
                    {choice.label}
                  </Typography>
                }
              />
            </CascadingMenuItem>
          )
        )}
      </CascadingSubmenu>
      <CopyMeetingLinkControl
        meetingId={meetingId}
        componentType={"menuitem"}
        handleComplete={() => moreOptionsSelectPopupState.close()}
      />
    </CascadingMenu>
  );

  return (
    <>
      <StyledSquareMenuButton
        data-testid={chimeTestIds.recordMeetingControl}
        size="small"
        {...bindToggle(moreOptionsSelectPopupState)}
        aria-label="more menu"
        sx={{
          borderRadius: 0,
          padding: 0,
          width: recommendedButtonSize,
          height: recommendedButtonSize,
          maxWidth: recommendedButtonSize,
          maxHeight: recommendedButtonSize,
          minWidth: recommendedButtonSize,
          minHeight: recommendedButtonSize,
        }}
      >
        {moreOptionsSelectPopupState.isOpen ? (
          <MoreChoiceWebActiveIcon
            color="primary"
            stroke={theme.palette.primary.contrastText}
            fontSize="large"
            sx={{
              width: recommendedButtonSize,
              height: recommendedButtonSize,

              maxWidth: pxToRem(40),
              maxHeight: pxToRem(40),
              transform: "rotate(90deg)",
              "&:hover": {
                color: "primary.dark",
              },
            }}
          />
        ) : (
          <MoreChoiceWebInactiveIcon
            stroke={theme.palette.grey[600]}
            fontSize="large"
            sx={{
              width: recommendedButtonSize,
              height: recommendedButtonSize,

              maxWidth: pxToRem(40),
              maxHeight: pxToRem(40),
              color: (theme) => theme.palette.gray.lighter,
              "&:hover": {
                color: "gray.light",
              },
              transform: "rotate(90deg)",
            }}
          />
        )}
      </StyledSquareMenuButton>
      {renderMenu}
    </>
  );
};
export default MoreChoicesMenuControl;
