import React from 'react'
import { SvgIcon } from "@mui/material";

export default function DirectSendIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M50.0001 37.5002V8.3335L41.6667 16.6668"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M50 8.3335L58.3333 16.6668"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.25 54.1665H26.625C28.2083 54.1665 29.625 55.0415 30.3333 56.4582L35.2083 66.2082C36.625 69.0415 39.5 70.8332 42.6667 70.8332H57.375C60.5417 70.8332 63.4167 69.0415 64.8333 66.2082L69.7083 56.4582C70.4167 55.0415 71.875 54.1665 73.4167 54.1665H91.5833"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.208 79.1662C15.208 87.9162 23.5413 91.6662 37.4997 91.6662H62.4997C83.333 91.6662 91.6663 83.3328 91.6663 62.4995V49.9995C91.6663 32.2078 85.583 23.5412 70.833 21.3745"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.1666 21.3745C14.4166 23.5412 8.33325 32.2078 8.33325 49.9995V62.4995"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
