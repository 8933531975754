import { SvgIcon } from "@mui/material";
import React from "react";

export default function TrrystAnimatedLoading1(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M20.4995 84.5002L27.8745 90.2086C28.8329 91.1669 30.9578 91.6251 32.4162 91.6251H41.4578C44.3328 91.6251 47.4162 89.5003 48.1245 86.6253L53.8329 69.2501C55.0412 65.9168 52.8745 63.0418 49.2912 63.0418H39.7495C38.3329 63.0418 37.1245 61.8332 37.3745 60.1665L38.5829 52.5417C39.0412 50.4167 37.6245 48 35.4995 47.2917C33.5828 46.5834 31.2079 47.5418 30.2495 48.9585L20.4995 63.5001"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
        />
        <path
          d="M8.33301 84.5003C8.33301 87.8336 9.74967 89.0418 13.083 89.0418H15.458C18.7913 89.0418 20.208 87.8336 20.208 84.5003V61.167C20.208 57.8337 18.7913 56.625 15.458 56.625H13.083C9.74967 56.625 8.33301 57.8337 8.33301 61.167V67.417"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M79.5001 15.5003L72.1251 9.79201C71.1667 8.83368 69.0418 8.37549 67.5834 8.37549H58.5418C55.6668 8.37549 52.5834 10.5003 51.8751 13.3753L46.1668 30.7505C44.9584 34.0838 47.1251 36.9588 50.7084 36.9588H60.2501C61.6667 36.9588 62.8751 38.1674 62.6251 39.834L61.4167 47.4588C60.9584 49.5838 62.3751 52.0005 64.5001 52.7089C66.4168 53.4172 68.7917 52.4588 69.7501 51.0421L79.5001 36.5005"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
        />
        <path
          d="M91.6672 15.5C91.6672 12.1667 90.2506 10.9585 86.9172 10.9585H84.5422C81.2089 10.9585 79.7922 12.1667 79.7922 15.5V38.8333C79.7922 42.1666 81.2089 43.3753 84.5422 43.3753H86.9172C90.2506 43.3753 91.6672 42.1666 91.6672 38.8333V33.3335"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
