import React from "react";
import { Icon } from "@iconify/react";
import googleFill from "@iconify/icons-eva/google-fill";
import twitterFill from "@iconify/icons-eva/twitter-fill";
import facebookFill from "@iconify/icons-eva/facebook-fill";
import linkedinFill from "@iconify/icons-eva/linkedin-fill";
import { Link as ScrollLink } from "react-scroll";
// next
import NextLink from "next/link";
// material
import { styled } from "@mui/material/styles";
import {
  Grid,
  Link,
  Stack,
  Divider,
  Container,
  Typography,
  IconButton,
} from "@mui/material";

//
import Logo from "blocks/atoms/Logo";
import { useIntl } from "react-intl";

// ----------------------------------------------------------------------

const SOCIALS = [
  { name: "FaceBook", icon: facebookFill },
  { name: "Google", icon: googleFill },
  { name: "Linkedin", icon: linkedinFill },
  { name: "Twitter", icon: twitterFill },
];

const LINKS = [
  {
    headline: "Trryst",
    children: [
      { name: "About us", href: "/home/aboutus" },
      { name: "Contact us", href: "/home/contactus" },
      // { name: "FAQs", href: "mailto:support@trryst.com" },
    ],
  },
  {
    headline: "Legal",
    children: [
      {
        name: "Terms and Condition",
        href: `${process.env.ROOT_URL}/home/terms`,
      },
      {
        name: "Privacy Policy",
        href: `${process.env.ROOT_URL}/home/privacypolicy`,
      },
    ],
  },
  {
    headline: "Contact",
    children: [
      { name: "support @ trryst.com", href: "mailto:support@trryst.com" },
      { name: "Milton Keynes, United Kingdom", href: "/home/contactus" },
    ],
  },
];

const RootStyle = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: theme.palette.primary.darker,
}));

// ----------------------------------------------------------------------

export default function LandingFooter() {
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });
  return (
    <RootStyle sx={{ pt: 5, color: "primary.contrastText" }}>
      <Container maxWidth="lg">
        <Grid
          container
          justifyContent={{ xs: "center", md: "space-between" }}
          sx={{ textAlign: { xs: "center", md: "left" } }}
          spacing={3}
        >
          <Grid item xs={12} sx={{ mb: 3 }}>
            <ScrollLink to="back_to_top" spy smooth>
              <Logo
                variant={"white"}
                sx={{ mb: 1, mx: "auto", cursor: "pointer" }}
              />
            </ScrollLink>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
              {`${t("landingFooter.companyBlurbMessage")}`}
            </Typography>

            {/* <Stack
                spacing={1.5}
                direction="row"
                justifyContent={{ xs: "center", md: "flex-start" }}
                sx={{ mt: 5, mb: { xs: 5, md: 0 } }}
              >
                {SOCIALS.map((social) => (
                  <IconButton
                    key={social.name}
                    sx={{ p: 1, color: "primary.contrastText" }}
                  >
                    <Icon
                      color="inherit"
                      icon={social.icon}
                      width={16}
                      height={16}
                    />
                  </IconButton>
                ))}
              </Stack> */}
          </Grid>

          <Grid item container xs={12} md={7}>
            {LINKS.map((list) => {
              const { headline, children } = list;
              return (
                <Grid item xs={4} key={headline}>
                  <Stack key={headline} spacing={2}>
                    <Typography component="p" variant="overline">
                      {headline}
                    </Typography>
                    {children.map((link) => (
                      <NextLink
                        key={link.name}
                        href={link.href}
                        passHref
                        legacyBehavior
                      >
                        <Link
                          color="inherit"
                          variant="body2"
                          sx={{
                            display: "block",
                            fontSize: { xs: 8, sm: 10, md: 12 },
                          }}
                        >
                          {link.name}
                        </Link>
                      </NextLink>
                    ))}
                  </Stack>
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 10,
            pb: 5,
            fontSize: 13,
            textAlign: { xs: "center", md: "left" },
          }}
        >
          © 2023. All rights reserved
        </Typography>
      </Container>
    </RootStyle>
  );
}
