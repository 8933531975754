import {
  Download,
  Edit,
  DeleteForever,
  FileOpen,
  Share,
  Insights,
  DriveFileMove,
} from "@mui/icons-material";
import FolderZipIcon from "@mui/icons-material/FolderZip";

export const downloadFileActionsList = [
  {
    icon: Download,
    value: "download_file",
    label: "Download",
    needsSelection: true,
    permissionKey: "canDownloadFiles",
  },
  {
    icon: FolderZipIcon,
    value: "download_zip",
    label: "Download Zip",
    needsSelection: true,
    permissionKey: "canDownloadFiles",
  },
];
export const baseFileActionsList = [
  {
    icon: FileOpen,
    value: "open_selection",
    label: "Open / View Selection",
    needsSelection: true,
    permissionKey: "canViewFiles",
  },
  {
    icon: DriveFileMove,
    value: "move_files",
    label: "Move to",
    needsSelection: true,
    permissionKey: "canOrganizeFiles",
  },
  {
    icon: DeleteForever,
    value: "delete_files",
    label: "Delete",
    needsSelection: true,
    permissionKey: "canDeleteFiles",
  },
  {
    icon: Edit,
    value: "rename_file",
    label: "Rename",
    needsSelection: true,
    permissionKey: "canRenameFiles",
  },
  {
    icon: Share,
    value: "share_files",
    label: "Share Selection",
    needsSelection: true,
    permissionKey: "canShareFiles",
  },
  {
    icon: Insights,
    value: "generate_ai_summary",
    label: "Generate AI Summary",
    needsSelection: true,
    permissionKey: "canUseAI",
  },
];

export function fileActionListOptions({
  isItemSelected = false,
  isMobile = false,
  filesPermissions = {},
  numOfFilesSelected = 0,
  isItemSelectedAFolder = false,
}) {
  const baseList = [];
  baseFileActionsList.forEach((action) => {
    (filesPermissions[action.permissionKey] ?? true) && baseList.push(action);
  });

  filesPermissions.canDownloadFilesSuitePreference &&
    downloadFileActionsList.forEach((action) => {
      if (
        (numOfFilesSelected > 1 || isItemSelectedAFolder) &&
        action.value === "download_zip"
      ) {
        // if more than one file is selected, show the download zip option
        (filesPermissions[action.permissionKey] ?? true) &&
          baseList.push(action);
      } else if (
        numOfFilesSelected === 1 &&
        !isItemSelectedAFolder &&
        action.value === "download_file"
      ) {
        // if only one file is selected, show the download option

        (filesPermissions[action.permissionKey] ?? true) &&
          baseList.push(action);
      }
    });

  return baseList;
}
