// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  AppBar,
  Toolbar,
  Box,
  Typography,
  ListItemIcon,
  ListItemText,
  Drawer,
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
//import { useUserActivityState } from 'amazon-chime-sdk-component-library-react';
import { useVideocallContext } from "providers/TrrystVideocallProvider";
import { useAppContext, useMeeting, useSuite, useDockets } from "@app21/core";
import {
  usePopupState,
  bindToggle,
  bindMenu,
} from "material-ui-popup-state/hooks";
import { getSuiteAndOrganizationLabel } from "utils";
import {
  SettingsOverscan,
  People,
  Clear,
  Done,
  HighlightOff,
} from "@mui/icons-material";
import CopyMeetingLinkControl from "../ConferenceControls/CopyMeetingLinkControl";
import { LAYOUTWIDTHS } from "blocks/atoms/constants";
import {
  ChangeScreenWebInactiveIcon,
  ChangeScreenWebActiveIcon,
} from "blocks/atoms/trrystCustomIcons";
import { useHandleVideocallActions } from "providers/TrrystVideocallProvider/hooks";
import {
  layoutMenuOptions,
  layoutVisitorMenuOptions,
} from "../ConferenceControls/LayoutChoiceControl/menuOptionsChoices";
import toast from "react-hot-toast";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),

  [theme.breakpoints.up("lg")]: {
    // width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: LAYOUTWIDTHS.TopbarHeight,
  display: "flex",
  justifyContent: "space-between",
  padding: "0px !important",

  [theme.breakpoints.up("lg")]: {},
}));

const GridHeaderBar = ({ viewContext = "meeting" }) => {
  const layoutOptionsPopupState = usePopupState({
    variant: "popover",
    popupId: "layoutOptionsMenu",
  });
  const { isGuestUser, meeting } = useVideocallContext();
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  //const { isUserActive } = useUserActivityState();

  const { chimeViewMode, setChimeViewMode, meetingId, isVisitorFlag } =
    useAppContext();

  const { handleMeetingJoinAction } = useHandleVideocallActions();

  const isGroupMeeting = meeting?.data?.meetingType === "GROUP-MEETING";
  const {
    data: selectedSuite,
    status: selectedSuiteStatus,
    apiError: suiteApiError,
  } = useSuite(meeting?.data?.suiteId);
  const {
    data: selectedDocket,
    status: docketStatus,
    apiError: docketApiError,
  } = useDockets(meeting?.data?.docketId);

  const selectedSuiteName = getSuiteAndOrganizationLabel(selectedSuite);
  const selectedDocketName = selectedDocket?.title || "";
  const meetingInfo = meeting?.data?.meetingJSON;
  const getMeetingTitle = (meeting, viewContext = "meeting") => {
    if (meeting?.data?.meetingJSON?.summary?.includes("My Personal Suite")) {
      return "Walk-In Call";
    } else
      return (
        (isGroupMeeting
          ? `Walk-in Meeting in ${
              meeting?.data?.docketId ? selectedDocketName : selectedSuiteName
            }`
          : meetingInfo?.summary) ?? "Trryst Meeting"
      );
  };

  const handleChangeLayout = (value) => {
    setChimeViewMode(value);
    layoutOptionsPopupState.close();
  };

  const CloseMenuBar = () => (
    <Box
      sx={{
        p: 2,
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <IconButton onClick={() => setDrawerOpen(false)}>
        <HighlightOff />
      </IconButton>
    </Box>
  );
  const renderLayoutMenu = () => {
    return (
      <>
        {/* <IconButton {...bindToggle(layoutOptionsPopupState)}>
          <SettingsOverscan />
        </IconButton> */}
        <IconButton size="small" {...bindToggle(layoutOptionsPopupState)}>
          <ChangeScreenWebActiveIcon
            color="none"
            stroke={"green"}
            sx={{ width: 40, height: 40 }}
          />
        </IconButton>
        <Menu
          {...bindMenu(layoutOptionsPopupState)}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
        >
          {(isVisitorFlag ? layoutVisitorMenuOptions : layoutMenuOptions).map(
            (choice, index) => {
              return (
                <MenuItem
                  key={index}
                  onClick={() => handleChangeLayout(choice.value)}
                  disabled={chimeViewMode === choice.value}
                >
                  <ListItemIcon>
                    {" "}
                    {chimeViewMode === choice.value ? (
                      <Done color="success" />
                    ) : (
                      choice.icon
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography color="textPrimary" variant="subtitle2">
                        {choice.label}
                      </Typography>
                    }
                  />
                </MenuItem>
              );
            }
          )}
        </Menu>
      </>
    );
  };
  const MeetingTitleComponent = () => {
    let titleMessage = getMeetingTitle(meeting, viewContext) ?? "Meeting";

    return (
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          pl: 2,
        }}
      >
        <Typography color="primary" variant="subtitle2">
          {titleMessage}
        </Typography>
        {/* <RecordingInfo viewContext={viewContext} /> */}

        <CopyMeetingLinkControl
          meetingId={meetingId}
          componentType={"icon"}
          size={"medium"}
        />
      </Box>
    );
  };
  return (
    <StyledAppBar elevation={0} position="sticky">
      <StyledToolbar>
        <MeetingTitleComponent meeting={meeting} />

        {viewContext === "setup" ? (
          <IconButton
            onClick={() => handleMeetingJoinAction({ action: "cancel" })}
            aria-label="Close"
          >
            <Clear fontSize="inherit" />
          </IconButton>
        ) : (
          <div style={{ display: "flex", minWidth: 25 }}>
            {renderLayoutMenu()}

            {/* <IconButton
              onClick={() => setDrawerOpen(true)}
              disabled={isGuestUser}
              aria-label="Attendees"
            >
              <People />
            </IconButton> */}
          </div>
        )}
      </StyledToolbar>
      {/*isRecording && (
          <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <Grid item>
              <Chip
                onClick={e => handleAddMeetingMarker(Date.now(), 'Important', 'ONLY-ME')}
                variant="outlined"
                color="primary"
                size="small"
                label="Important"
              />
            </Grid>
            <Grid item>
              <Chip
                onClick={e => handleAddMeetingMarker(Date.now(), 'Action Item', 'ONLY-ME')}
                variant="outlined"
                color="secondary"
                size="small"
                label="Action Item"
              />
            </Grid>
            <Grid item>
              <Chip
                onClick={e => handleAddMeetingMarker(Date.now(), 'Local', 'ONLY-ME')}
                variant="outlined"
                color="primary"
                size="small"
                label="Local"
              />
            </Grid>
          </Grid>
        )*/}
      {/* {!isGuestUser && (
        <Drawer
          anchor={"right"}
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          <CloseMenuBar />
          <AttendeesRoster />
        </Drawer>
      )} */}
    </StyledAppBar>
  );
};
export default GridHeaderBar;
