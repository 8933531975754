import React from 'react'
import { SvgIcon } from '@mui/material';

export default function OtherMenuIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M8.32806 53.9955V62.4957C8.32806 83.3293 16.6615 91.6628 37.4952 91.6628H62.4956C83.3293 91.6628 91.6627 83.3293 91.6627 62.4957V37.4953C91.6627 16.6616 83.3293 8.32812 62.4956 8.32812H37.4952C16.6615 8.32812 8.32806 16.6616 8.32806 37.4953"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M66.6502 50.0038H66.6877"
          stroke="currentColor"
          strokeWidth="8.10198"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M49.967 50.0038H50.0045"
          stroke="currentColor"
          strokeWidth="8.10198"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.311 50.0038H33.3484"
          stroke="currentColor"
          strokeWidth="8.10198"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
