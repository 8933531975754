// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Grid, Typography } from "@mui/material";
import {
  useUsersInSuite,
  useAppContext,
  populateUsers,
  useHandleMeetingActions,
} from "@app21/core";

import ProfilesChipsList from "blocks/atoms/ProfilesChipsList";

import LoadingSpinner from "blocks/atoms/LoadingSpinner";
import RSVPHandlerChip from "blocks/modules/Scheduler/foundations/RSVPHandlerChip";

const ShowMeetingInviteesAndRsvp = ({ meeting, action, meetingFlags }) => {
  const { data: usersInSuite, status: usersInSuiteStatus } = useUsersInSuite(
    meeting?.suiteId
  );
  const { handleMeetingActions } = useHandleMeetingActions();
  const { userInfo, checkAccessRules } = useAppContext();
  const { accessFlag: canAccessMembers } = checkAccessRules({
    entity: "USERS-VISIBILITY",
    action: "VIEW-ALL-SUITE-USERS",
    featureName: "SUITE-USER-DETAILS",
    isInvitee: true,
  });
  const meetingInfo = meeting?.data;
  let userRsvpStatus =
    meetingInfo?.meetingJSON?.members?.find(
      (attendee) => attendee._id === userInfo._id
    )?.rsvpStatus ?? "Invited";

  const handleRSVPChange = async (status) => {
    await handleMeetingActions(
      {
        meetingJSON: meetingInfo?.meetingJSON,
        action: "RSVP-UPDATE",
        changedrsvpStatus: status,
      },
      null,
      null
    );
  };

  return (
    <>
      {usersInSuiteStatus === "loading" ? (
        <LoadingSpinner />
      ) : canAccessMembers ? (
        <ProfilesChipsList
          usersList={populateUsers(
            meetingInfo?.meetingJSON?.members?.map((member) => member._id),
            usersInSuite
          )}
        />
      ) : (
        <></>
      )}
      {action === "rsvp" &&
        !meetingFlags.upcomingMeetingFlag &&
        !meetingFlags.hasMeetingEndedFlag && (
          <RSVPHandlerChip
            rsvpStatus={userRsvpStatus}
            handleRSVPChange={handleRSVPChange}
            pastMeetingFlag={meetingFlags.pastMeetingFlag}
          />
        )}
    </>
  );
};

export default ShowMeetingInviteesAndRsvp;
