// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import PropTypes from "prop-types";
import { LinearProgress, Typography, Box } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";

function LinearProgressWithLabel({ value, overdue, ...rest }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          mr: 1,
        }}
      >
        <LinearProgress
          sx={{
            "& .MuiLinearProgress-bar1Determinate": {
              backgroundColor:
                value === 100 ? "LightSeaGreen" : overdue ? "red" : null,
            },
          }}
          variant="determinate"
          value={value}
          {...rest}
        />
      </Box>
      <Box
        sx={{
          minWidth: 35,
        }}
      >
        {value === 100 ? (
          <CheckCircle fontSize="small" style={{ color: "LightSeaGreen" }} />
        ) : (
          <Typography
            variant="subtitle1"
            color={overdue ? "error" : "textPrimary"}
          >{`${Math.round(value)}%`}</Typography>
        )}
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default LinearProgressWithLabel;
