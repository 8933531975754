import React from 'react'
import { SvgIcon } from "@mui/material";

export default function PresentingIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M8.33325 26.8335C8.33325 12.0418 12.0416 8.3335 26.8333 8.3335H33.3333"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M91.6666 49.5418V53.2502C91.6666 68.0835 87.9583 71.7502 73.1666 71.7502H26.8333C12.0416 71.7502 8.33325 68.0418 8.33325 53.2502V44.9585"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M50 71.751V91.6676"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.33325 54.1665H91.6666"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.25 91.6665H68.75"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M74.4158 39.0407H54.5824C48.8324 39.0407 46.9158 35.2074 46.9158 31.374V16.7074C46.9158 12.124 50.6658 8.37402 55.2491 8.37402H74.4158C78.6658 8.37402 82.0824 11.7907 82.0824 16.0407V31.374C82.0824 35.624 78.6658 39.0407 74.4158 39.0407Z"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M87.1217 33L82.0801 29.4583V17.9583L87.1217 14.4166C89.6217 12.7083 91.6634 13.75 91.6634 16.7916V30.6666C91.6634 33.7083 89.6217 34.75 87.1217 33Z"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
