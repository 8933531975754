// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from "react";
import { useMeetingManager } from "amazon-chime-sdk-component-library-react";
import {
  executeApi,
  useAppContext,
  useSelectedBucketId,
  useMeeting,
  useMeetingRecordingMetadata,
  useOrgsAndSuitesForUser,
  checkIfNull,
} from "@app21/core";
import { useConfirm } from "material-ui-confirm";
import {
  StopCircleOutlined,
  RadioButtonCheckedOutlined,
  BrokenImage,
} from "@mui/icons-material";
import { LoadingSpinner } from "blocks/atoms";
import { Typography, Tooltip } from "@mui/material";
import { StyledSquareMenuButton } from "blocks/atoms/uistyles";
import { chimeTestIds } from "blocks/atoms/constants";
import { useVideocallContext } from "providers/TrrystVideocallProvider";
import { findOrgIdFromSuiteId } from "utils";
import { sort } from "fast-sort";
import DisplayRecordingTimeSnippet from "./DisplayRecordingTimeSnippet";
import useDeepCompareEffect from "use-deep-compare-effect";

const RecordingControl = ({ recommendedButtonSize = 40 }) => {
  const { meetingId, userInfo } = useAppContext();
  const { data: orgsAndSuites } = useOrgsAndSuitesForUser(userInfo?._id);
  const confirm = useConfirm();
  const meetingManager = useMeetingManager();
  const {
    isRecording,
    setIsRecording,
    isRecordingBotReady,
    isRecordingBotError,
    chimePermissions,
  } = useVideocallContext();
  const selectedBucketId = useSelectedBucketId(null, true);
  const { data: meeting } = useMeeting(meetingId);
  const { data: meetingRecordingMetadata } =
    useMeetingRecordingMetadata(meetingId);
  const [isRecordingLoading, setIsRecordingLoading] = React.useState(false);
  const canOperateRecording = chimePermissions?.canOperateRecording ?? false;
  const meetingOrgId = findOrgIdFromSuiteId(meeting?.suiteId, orgsAndSuites);

  let meetingRecordingsData =
    meetingRecordingMetadata?.data?.recordingLogs ?? [];
  let lastRecording = sort(meetingRecordingsData)
    .asc((a) => a.initiatedAt)
    .slice(-1)
    .pop();

  useDeepCompareEffect(() => {
    if (!checkIfNull(lastRecording)) {
      setIsRecording(lastRecording?.status === "ACTIVE");
      setIsRecordingLoading(false);
    }
  }, [meetingRecordingMetadata || {}]);

  const handleRecordingButtonClick = async () => {
    setIsRecordingLoading(true);
    if (isRecording) {
      confirm({
        description:
          "We will now stop the recording. The processed recordings and transcript will be ready and in the Recordings and Transcripts area after around 2 minutes.",
      })
        .then(async () => {
          await executeApi("RECORD-CHIME-MEETING", {
            recordingAction: "recording-stop",
            meetingId: meetingId,
            chimeMeetingId: meetingManager.meetingId,
            mediaCaptureBucket: selectedBucketId,
            organizationId: meetingOrgId,
            suiteId: meeting.suiteId,
            calledBy: userInfo?._id,
          });
          //enqueueSnackbar('The meeting is no longer being recorded.', { variant: 'error', autoHideDuration: 3000 });
        })
        .catch(() => {
          console.log("recording Stop request cancelled.");
          setIsRecordingLoading(false);
        })
        .finally(() => {
          // if anyting to be done
        });
    } else if (isRecordingBotReady) {
      confirm({
        description:
          "Please ensure you have consent of all meeting participants prior to proceeding. Please note that the AI Agent might take a few moments to join the conversation and start recording / transcribing.",
      })
        .then(async () => {
          const response = await executeApi("RECORD-CHIME-MEETING", {
            recordingAction: "recording-start",
            meetingId: meetingId,
            chimeMeetingId: meetingManager.meetingId,
            mediaCaptureBucket: selectedBucketId,
            organizationId: meetingOrgId,
            suiteId: meeting.suiteId,
            calledBy: userInfo?._id,
          });
        })
        .catch(() => {
          console.log("Recording cancelled.");
          setIsRecordingLoading(false);
        })
        .finally(() => {
          // if anyting to be done
        });
    } else {
      confirm({
        description:
          "Please ensure you have consent of all meeting participants prior to proceeding. Also please note that recording will take upto a minute to start the AI agent which will record and transcribe the conversations.",
      })
        .then(async () => {
          await executeApi("PREPARE-CHIME-RECORDING", {
            clientTokenId: meetingId,
            calledBy: userInfo?._id,
            chimeMeetingId: meetingManager.meetingId,
            startRecordingOnStartup: "YES",
            organizationId: meetingOrgId,
          });
        })
        .catch(() => {
          console.log("preparing chime recording infrastructure cancelled.");
          setIsRecordingLoading(false);
        })
        .finally(() => {
          // if anyting to be done
        });
    }
  };

  const getRecordingButtonIcon = () => {
    switch (true) {
      case isRecordingLoading:
        return (
          <Tooltip label="Initiating AI Bot joining the conference and Recording. Can take a minute to start.">
            <LoadingSpinner />
          </Tooltip>
        );
      case isRecordingBotError:
        return <BrokenImage />;
      case isRecording:
        return (
          <StopCircleOutlined
            color="error"
            sx={{
              width: 0.5 * recommendedButtonSize,
              height: 0.5 * recommendedButtonSize,
              maxWidth: 0.5 * recommendedButtonSize,
              maxHeight: 0.5 * recommendedButtonSize,
              minWidth: 0.5 * recommendedButtonSize,
              minHeight: 0.5 * recommendedButtonSize,
            }}
          />
        );
      case meetingRecordingMetadata?.data?.metadata?.ecsTaskArn.length > 0 &&
        lastRecording?.status !== "STOPPED" &&
        !isRecordingBotReady:
        return (
          <Tooltip label="Initiating AI Bot joining the conference and Recording. Can take a minute to start.">
            <LoadingSpinner />
          </Tooltip>
        );
      default:
        return (
          <RadioButtonCheckedOutlined
            color="gray"
            sx={{
              width: 0.6 * recommendedButtonSize,
              height: 0.6 * recommendedButtonSize,
              maxWidth: 0.6 * recommendedButtonSize,
              maxHeight: 0.6 * recommendedButtonSize,
              minWidth: 0.6 * recommendedButtonSize,
              minHeight: 0.6 * recommendedButtonSize,
              color: (theme) => theme.palette.gray.main,
              "&:hover": {
                color: "gray.main",
              },
            }}
          />
        );
    }
  };
  if (!canOperateRecording && !isRecording) {
    // if the user is not allowed to operate recording and there is no recording going on, then don't show the button
    return null;
  }
  return (
    <StyledSquareMenuButton
      data-testid={chimeTestIds.recordMeetingControl}
      onClick={handleRecordingButtonClick}
      disabled={
        isRecordingBotError || isRecordingLoading || !canOperateRecording
      }
      sx={{
        borderRadius: 2,

        padding: 0,
        width: recommendedButtonSize,
        height: recommendedButtonSize,
        maxWidth: recommendedButtonSize,
        maxHeight: recommendedButtonSize,
        minWidth: recommendedButtonSize,
        minHeight: recommendedButtonSize,
        position: "relative",
        bgcolor:
          isRecording && canOperateRecording ? "primary.main" : "gray.lighter",
        "&:hover": {
          bgcolor: isRecording
            ? "primary.dark"
            : canOperateRecording
            ? "gray.light"
            : "gray.lighter",
        },
      }}
    >
      {getRecordingButtonIcon()}
      {isRecording && (
        <Typography
          variant="caption"
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            textAlign: "center",
            color: "primary.contrastText",
            mb: -0.4,
            color: canOperateRecording
              ? "primary.contrastText"
              : "text.primary",
          }}
        >
          <DisplayRecordingTimeSnippet
            initiatedTime={lastRecording?.initiatedAt}
          />
        </Typography>
      )}
    </StyledSquareMenuButton>
  );
};

export default RecordingControl;
