import React from "react";
import { Box, Typography, List } from "@mui/material";
import {
  useAppContext,
  app21APIInstance,
  useHandleRouterPush,
  checkIfNull,
} from "@app21/core";
import ChatGroupCard from "./ChatGroupCard";
import { sort } from "fast-sort";
import { useChatContext } from "providers/TrrystChatProvider";
import { useAtom } from "jotai";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  ThemeAtom,
  CurrentChannelAtom,
} from "blocks/modules/TrrystChat/state-atoms";
import ShowChatInfoPanel from "./ShowChatInfoPanel";

/**
 * Renders an interactive list of channels.
 *
 * It can represent all channels of the application, only
 * channels joined by the current user, all channels available to be joined, or whatever else is
 * passed into it. A common pattern in chat applications is to render two instances of the
 * component - one visible all the time to show joined channels, and another one hidden inside a
 * modal with channels available to join. Make sure to handle the onChannelSwitched event to switch
 * the current channel passed to the Chat provider.
 */

const ShowChatGroupCards = (props) => {
  const {
    allChannelsData,
    viewChatContext,
    setSidebarActive,
    selectedChannel,
  } = useChatContext();
  const { userInfo } = useAppContext();
  const [currentChannel, setCurrentChannel] = useAtom(CurrentChannelAtom);
  const { selectedSuiteId } = useAppContext();

  const [theme] = useAtom(ThemeAtom);
  const { loadRoute } = useHandleRouterPush();

  /* Helper functions
   */
  const isChannelActive = (ch) => {
    return currentChannel === ch.id;
  };

  // const channelSorter = (a, b) => {
  //   if (props.sort) return props.sort(a, b);
  //   return a?.name?.localeCompare(b.name, "en", { sensitivity: "base" });
  // };

  const channelFromString = (channel) => {
    if (typeof channel === "string") {
      return {
        id: channel,
        name: channel,
      };
    }
    return channel;
  };

  /*
  /* Commands
  */

  const switchChannel = async (channel) => {
    if (setCurrentChannel) {
      // update the last read time token for the current channel to current time
      // await app21APIInstance.post(`/channels/updatelastreadtimetoken`, {
      //   channelId: currentChannel,
      //   lastReadTimetoken: Date.now() * 10000,
      //   calledBy: userInfo?._id,
      //   additionalData: { suiteId: selectedSuiteId },
      // });
      setCurrentChannel(channel.id);
    }
  };

  const handleSelectDirectChannel = async (payload) => {
    const response = await app21APIInstance.post(`/channels/fetch`, {
      objectType: "direct",
      objectId: payload.userId,
      calledBy: userInfo._id,
      additionalData: {
        suiteId: selectedSuiteId,
      },
    });

    if (response) {
      setSidebarActive(false);
      // switchChannel(response.data.data.data[0]);
      // channel switching should happen via the loadRoute and activeChatId
      loadRoute("GROUP-CHAT", {
        chatId: response.data.data.data[0].id,
        chatType: "suite",
      });
    }
  };

  /*
  /* Renderers
  */

  const renderChannels = React.useCallback(
    (channelsList) => {
      //below filtering to handle the situation where we want to only show current suite and not all the suites
      const filteredChannelsList =
        viewChatContext === "suite"
          ? channelsList?.filter(
              (channel) => channel.id === `${selectedSuiteId}-CHAT`
            )
          : viewChatContext === "dockets"
          ? channelsList?.filter(
              (channel) => channel.docket?.suiteId === selectedSuiteId
            )
          : viewChatContext === "tasks"
          ? channelsList?.filter(
              (channel) => channel.task?.suiteId === selectedSuiteId
            )
          : viewChatContext === "meetings"
          ? channelsList?.filter(
              (channel) => channel.meeting?.suiteId === selectedSuiteId
            )
          : viewChatContext === "files"
          ? channelsList?.filter(
              (channel) => channel.file?.suiteId === selectedSuiteId
            )
          : viewChatContext === "surveys"
          ? channelsList?.filter(
              (channel) => channel.survey?.suiteId === selectedSuiteId
            )
          : channelsList;
      const sortedChannelsList = sort(filteredChannelsList ?? []).desc(
        (channel) => channel.updatedAt
      );
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              height: "100%",
              overflow: "hidden",
              mb: 3,
            }}
          >
            <PerfectScrollbar
              options={{ suppressScrollX: true }}
              style={{
                display: "flex",
                flexDirection: "column",
                paddingRight: 0,
                flexGrow: 1,
                height: "100%",
              }}
            >
              <List dense sx={{ my: 1 }}>
                {sortedChannelsList?.map((channel) => {
                  const channelActive = isChannelActive(channel);

                  return (
                    <ChatGroupCard
                      key={channel.id}
                      channel={channel}
                      isActive={channelActive}
                      viewChatContext={viewChatContext}
                      handleClick={() => {
                        setSidebarActive(false);
                        //   switchChannel(channel);
                        loadRoute("GROUP-CHAT", {
                          chatId: channel.id,
                          chatType: "suite",
                        });
                      }}
                    />
                  );
                })}
              </List>
            </PerfectScrollbar>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 3,
              height: "100%",
              overflow: "hidden",
            }}
          >
            <ShowChatInfoPanel
              selectedChannel={selectedChannel}
              handleSelectDirectChannel={handleSelectDirectChannel}
            />
          </Box>
        </Box>
      );
    },
    [viewChatContext, allChannelsData, selectedChannel]
  );

  const filteredChannels = React.useMemo(() => {
    if (!allChannelsData) return [];
    switch (viewChatContext) {
      case "suite":
        return (allChannelsData ?? []).filter(
          (channel) => channel.type === "SUITES"
        );
      case "dockets":
        return (allChannelsData ?? []).filter(
          (channel) => channel.type === "DOCKETS"
        );
      case "meetings":
        return (allChannelsData ?? []).filter(
          (channel) => channel.type === "MEETINGS"
        );
      case "tasks":
        return (allChannelsData ?? []).filter(
          (channel) => channel.type === "TASKS"
        );
      case "files":
        return (allChannelsData ?? []).filter(
          (channel) => channel.type === "FILES"
        );
      case "surveys":
        return (allChannelsData ?? []).filter(
          (channel) => channel.type === "SURVEYS"
        );
      case "direct":
        return (allChannelsData ?? []).filter(
          (channel) => channel.type === "DIRECT"
        );
      default:
        return [];
    }
  }, [allChannelsData, viewChatContext]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
        overflow: "hidden",
      }}
    >
      {renderChannels(filteredChannels)}
    </Box>
  );
};

export default ShowChatGroupCards;
