// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Box, Typography, Button, Divider, styled } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useQueryClient } from "react-query";

import {
  filterOrgsBasedOnAdmin,
  useAppContext,
  useOrgsAndSuitesForUser,
  useSubscriptions,
  useStripeInvoices,
} from "@app21/core";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useIsMobileBreakpoint } from "hooks";
import { getSuiteDetailsFromType, getSuitePrices } from "utils";
import InvoiceCard from "./InvoiceCard";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main,
    fontWeight: "bold",
    fontSize: "0.9rem",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },

  // hide last border
  "&:last-child td": {
    border: 0,
  },
}));

const BillingAdminPanel = () => {
  const queryClient = useQueryClient();
  const { selectedOrganizationId } = useAppContext();

  const [activeSubsriptionId, setActiveSubscriptionId] = React.useState(null);
  const isMobile = useIsMobileBreakpoint();

  const { data: subscriptionData } = useSubscriptions({
    scope: "ORG",
    list: [selectedOrganizationId],
  });
  const { data: invoiceData } = useStripeInvoices({
    id: activeSubsriptionId,
    idType: "subscription",
  });

  React.useEffect(() => {
    if (subscriptionData && subscriptionData.length > 0) {
      setActiveSubscriptionId(subscriptionData[0].subscriptionId);
    }
  }, [subscriptionData]);

  const handleRefreshAllData = React.useCallback(
    () => queryClient.invalidateQueries(["allInvoices", "allSubscriptions"]),
    [queryClient]
  );

  const handlePayInvoice = React.useCallback(
    async (invoiceId) => {
      await (
        await fetch(`${process.env.AWS_APIPATH}/stripe/invoice/pay`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ invoiceId }),
        })
      ).json();
      queryClient.invalidateQueries(["allInvoices"]);
    },
    [queryClient]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
      }}
    >
      <Button
        variant="contained"
        onClick={handleRefreshAllData}
        sx={{ alignSelf: "flex-end", mb: 3 }}
      >
        Refresh
      </Button>

      <TableContainer
        component={Paper}
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          height: "100%",
          p: 2,
          pt: 4,
          mt: 2,
        }}
      >
        <Table>
          <TableHead
            sx={{
              border: (theme) => `1px solid ${theme.palette.primary.main}`,
            }}
          >
            <StyledTableRow>
              <StyledTableCell style={{ boxShadow: "inset 0px 0 0 #fff" }}>
                Suite Name
              </StyledTableCell>
              <StyledTableCell align="left" sx={{ px: isMobile && 1 }}>
                Subscribed Suite Type
              </StyledTableCell>
              <StyledTableCell align="left">Start</StyledTableCell>
              <StyledTableCell align="left">End</StyledTableCell>
              <StyledTableCell
                align="left "
                style={{ boxShadow: "inset 0px 0 0 #fff" }}
                sx={{ px: isMobile && 0 }}
              >
                Status
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody
            sx={{
              border: (theme) => `1px solid ${theme.palette.primary.main}`,
            }}
          >
            {subscriptionData &&
              subscriptionData.map((subscription, index) => {
                const suiteSubscriptionTierDetails = getSuiteDetailsFromType(
                  subscription.product.name
                );

                return (
                  <StyledTableRow
                    key={index}
                    onClick={() =>
                      setActiveSubscriptionId(subscription.subscriptionId)
                    }
                    sx={{
                      cursor: "pointer",
                      background:
                        subscription.subscriptionId === activeSubsriptionId
                          ? "primary.lighter"
                          : "white",
                    }}
                  >
                    <StyledTableCell scope="row">
                      {subscription?.suiteInfo[0]?.fullName || "Unknown"}
                    </StyledTableCell>
                    <StyledTableCell scope="row" sx={{ px: isMobile && 1 }}>
                      {suiteSubscriptionTierDetails
                        ? `${suiteSubscriptionTierDetails.suiteLabel} (${suiteSubscriptionTierDetails.suiteTier}) `
                        : subscription.product.name}
                    </StyledTableCell>
                    <StyledTableCell scope="row">
                      {new Date(subscription.startPeriod * 1000).toDateString()}
                    </StyledTableCell>
                    <StyledTableCell scope="row">
                      {new Date(subscription.endPeriod * 1000).toDateString()}
                    </StyledTableCell>
                    <StyledTableCell
                      scope="row"
                      align="left"
                      sx={{ px: isMobile && 0 }}
                    >
                      {subscription.status === "trialing"
                        ? "Trialing"
                        : subscription.status}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ pt: 4 }}>
        {invoiceData &&
          invoiceData.map((invoice, index) => (
            <Box key={index}>
              <InvoiceCard
                invoiceNumber={invoice.invoiceNumber}
                isPaid={invoice.isPaid}
                downloadUrl={invoice.downloadUrl}
                previewUrl={invoice.previewUrl}
                amount={invoice.total}
                currencySymbol="$"
              />
              {!invoice.isPaid && (
                <Button onClick={() => handlePayInvoice(invoice.invoiceId)}>
                  Mark as Paid (Beta)
                </Button>
              )}
            </Box>
          ))}
      </Box>
    </Box>
  );
};
export default BillingAdminPanel;
