import React from 'react'
import { SvgIcon } from "@mui/material";

export default function DirectBoxReceiveIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 69 69"
      stroke="currentColor"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M40.2502 46.4592L28.8652 35.0742"
          stroke="currentColor"
          strokeWidth="4.30925"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M40.134 35.1904L28.749 46.5754"
          stroke="currentColor"
          strokeWidth="4.30925"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.511 5.75098H28.751C25.876 5.75098 23.001 5.75098 23.001 11.501C23.001 17.251 25.876 17.251 28.751 17.251H40.251C46.001 17.251 46.001 14.376 46.001 11.501C46.001 5.75098 43.126 5.75098 40.251 5.75098"
          stroke="currentColor"
          strokeWidth="4.30925"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.625 28.7501C8.625 15.6401 13.4263 12.0751 23 11.5576"
          stroke="currentColor"
          strokeWidth="4.30925"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M46 11.5576C55.5737 12.0751 60.375 15.6114 60.375 28.7501V46.0001C60.375 57.5001 57.5 63.2501 43.125 63.2501H25.875C11.5 63.2501 8.625 57.5001 8.625 46.0001V39.9914"
          stroke="currentColor"
          strokeWidth="4.30925"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
