import React from 'react'
import { SvgIcon } from "@mui/material";

export default function DropDownArrowIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M66.8002 41.6001H33.2002L50.0002 58.4001L66.8002 41.6001Z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
}
