// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Button, Typography, Box } from "@mui/material";
import intervalToDuration from "date-fns/intervalToDuration";

const CountdownButton = ({
  targetDate,
  timeFormat = "MM DD YYYY, h:mm a",
  startMeetingEnabled,
  setStartMeetingEnabled,
  ...rest
}) => {
  const [check, setCheck] = React.useState(0);
  const [splitTime, setSplitTime] = React.useState("");

  React.useEffect(() => {
    const id = setInterval(() => {
      if (!startMeetingEnabled && Boolean(targetDate)) {
        const NewCountdown = intervalToDuration({
          start: new Date(),
          end: new Date(targetDate),
        });
        if (targetDate - new Date() < 300) {
          setStartMeetingEnabled(true);
        }
        const days = NewCountdown.days;
        const hours = NewCountdown.hours;
        const minutes = NewCountdown.minutes;
        const seconds = NewCountdown.seconds;
        const overall = days
          ? `${days}days : ${hours}hr: ${minutes}min: ${seconds}`
          : `${hours}hr: ${minutes}min: ${seconds}`;
        setSplitTime(overall);
        setCheck(check + 1);
      }
    }, 1000);
    return () => clearInterval(id);
  }, []);

  return (
    <Box sx={{ display: "flex", mt: 2, alignItems: "center" }}>
      <Typography variant="h6" sx={{ mr: 2 }}>
        Meeting starts in:{" "}
      </Typography>
      <Button disabled variant="contained" color="primary" {...rest}>
        {splitTime}
      </Button>
    </Box>
  );
};

export default CountdownButton;
