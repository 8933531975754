import React from "react";
import { Container } from "@mui/material";

const CustomMSDocRenderer = ({ mainState: { currentDocument } }) => {
  if (!currentDocument) return null;

  return (
    <Container id="msdoc-renderer" style={{ padding: 0, overflow: "hidden" }}>
      <iframe
        id="msdoc-iframe"
        title="msdoc-iframe"
        src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
          currentDocument.uri
        )}&wdnd=1&wdStartOn=1&wdPrint=0&wdShowMenu=0&wdEmbedCode=0&ui=en-GB`}
        style={{
          margin: 0,
          padding: 0,
          width: "100%",
          height: "100%",
          border: "none",
        }}
        border="0"
      />
    </Container>
  );
};
//&wdStartOn=1&wdPrint=0&wdEmbedCode=0&ui=es-ES (for localization.. keeping it here as reference for a later date)
export default CustomMSDocRenderer;

const MSDocFTMaps = {
  odt: ["odt", "application/vnd.oasis.opendocument.text"],
  doc: ["doc", "application/msword"],
  docx: [
    "docx",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/octet-stream",
  ],
  xls: ["xls", "application/vnd.ms-excel"],
  xlsx: [
    "xlsx",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ],
  ppt: ["ppt", "application/vnd.ms-powerpoint"],
  pptx: [
    "pptx",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ],
};

CustomMSDocRenderer.fileTypes = [
  ...MSDocFTMaps.odt,
  ...MSDocFTMaps.doc,
  ...MSDocFTMaps.docx,
  ...MSDocFTMaps.xls,
  ...MSDocFTMaps.xlsx,
  ...MSDocFTMaps.ppt,
  ...MSDocFTMaps.pptx,
];
CustomMSDocRenderer.weight = 1;
CustomMSDocRenderer.fileLoader = ({ fileLoaderComplete }) =>
  fileLoaderComplete();
