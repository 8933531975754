import { Skeleton } from "@mui/material";

export default function SkeletonList({ sx, ...rest }) {
  return (
    <Skeleton
      variant="rectangular"
      width="100%"
      height={40}
      style={{ borderRadius: "4px" }}
      sx={{ display: "flex", p: 1, ...sx }}
      {...rest}
    >
      <Skeleton variant="circular" />
      <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <Skeleton variant="text" width="100%" height={20} sx={{ m: 1 }} />
        <Skeleton variant="text" width="100%" height={20} sx={{ m: 1 }} />
      </div>
    </Skeleton>
  );
}
