import React from 'react'
import { SvgIcon } from '@mui/material';

export default function IncreaseIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M50.0003 64.584V39.584"
          stroke="currentColor"
          strokeWidth="6.03541"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.5002 47.916L50.0002 35.416L62.5002 47.916"
          stroke="currentColor"
          strokeWidth="6.03541"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.667 24.9992C11.4586 31.9575 8.33362 40.6242 8.33362 49.9992C8.33362 72.9992 27.0003 91.6659 50.0003 91.6659C73.0003 91.6659 91.667 72.9992 91.667 49.9992C91.667 26.9992 73.0003 8.33252 50.0003 8.33252C44.042 8.33252 38.3336 9.58252 33.2086 11.8742"
          stroke="currentColor"
          strokeWidth="6.03541"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
