import { SvgIcon } from "@mui/material";
import React from "react";

export default function EndCallWebIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 56 56"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <rect
          width="56"
          height="56"
          rx="4.8"
          stroke="none"
          fill="currentColor"
        />
        <path
          d="M25.6431 37.7584C25.8391 37.9124 26.0351 38.0524 26.2451 38.2064C27.8411 39.3684 29.4511 40.2924 31.0611 40.9784C32.6711 41.6644 34.1971 42.0004 35.6251 42.0004C36.6051 42.0004 37.5151 41.8184 38.3551 41.4684C39.2091 41.1184 39.9651 40.5724 40.6511 39.8164C41.0571 39.3684 41.3651 38.8924 41.6031 38.3884C41.8411 37.8844 41.9531 37.3664 41.9531 36.8624C41.9531 36.4704 41.8691 36.1204 41.7291 35.7704C41.5751 35.4204 41.3231 35.1264 40.9591 34.8744L36.3251 31.5844C35.9751 31.3464 35.6531 31.1644 35.3451 31.0384C35.0371 30.9124 34.7571 30.8564 34.4911 30.8564C34.1411 30.8564 33.8191 30.9544 33.4971 31.1504C33.1751 31.3184 32.8391 31.5844 32.4891 31.9344L31.4251 32.9844C31.2711 33.1384 31.0891 33.2224 30.8511 33.2224C30.7251 33.2224 30.6131 33.2084 30.5011 33.1664C30.4031 33.1244 30.3191 33.0824 30.2491 33.0544C29.9831 32.9144 29.6751 32.7184 29.3391 32.4664"
          strokeWidth="2.1"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.0941 15.038C20.8421 14.674 20.5201 14.422 20.1561 14.254C19.8061 14.086 19.4141 14.002 19.0221 14.002C17.9861 14.002 17.0061 14.436 16.1101 15.318C15.3681 16.018 14.8361 16.802 14.5001 17.656C14.1641 18.496 13.9961 19.406 13.9961 20.358C13.9961 21.814 14.3321 23.34 15.0041 24.936C15.6761 26.518 16.6001 28.114 17.7481 29.71C18.9101 31.306 20.2121 32.832 21.6541 34.288"
          strokeWidth="2.1"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.2179 29.7092C25.4899 28.9672 24.8179 28.2252 24.1879 27.4972C23.5719 26.7692 23.1379 26.1672 22.8859 25.7052C22.8579 25.6212 22.8159 25.5372 22.7739 25.4252C22.7459 25.3132 22.7319 25.2152 22.7319 25.1032C22.7319 24.8792 22.8019 24.6972 22.9559 24.5432L24.0199 23.4372C24.3559 23.1012 24.6219 22.7652 24.8039 22.4432C24.9999 22.1212 25.0979 21.7852 25.0979 21.4492C25.0979 21.1832 25.0279 20.8892 24.9019 20.5952C24.7759 20.2872 24.5939 19.9652 24.3419 19.6152"
          strokeWidth="2.1"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M41.9961 14.002L13.9961 42.002"
          strokeWidth="2.1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
