// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
export default function processChartData(compositeData = {}) {
  let chartData = [];
  const totalResponses = compositeData.totalResponsesCount ?? 1;
  compositeData.answerChoices.forEach((answerChoice) => {
    let chartObj = {};
    chartObj.choice = answerChoice.choice;
    chartObj.choiceCount = 0;
    chartObj.choiceRespondents = [];
    compositeData.answers.forEach((answerResponse) => {
      if (answerResponse.answer.choice === answerChoice.choice) {
        chartObj.choiceCount += 1;
        chartObj.choiceRespondents.push(answerResponse.respondent);
      }
    });

    chartData.push(chartObj);
  });

  return chartData.map((chartObj) => {
    return {
      ...chartObj,
      choicePercentage: (chartObj.choiceCount / totalResponses) * 100,
    };
  });
}
