// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useContext, useState } from "react";
import {
  DeviceLabels,
  useMeetingManager,
} from "amazon-chime-sdk-component-library-react";
import { MeetingSessionConfiguration } from "amazon-chime-sdk-js";
import { useChimeState } from "providers/TrrystVideocallProvider/wrappers/ChimeStateProvider";
import {
  useVideocallContext,
  meetingConfig,
  AvailableMeetingModes,
  VideoFiltersCpuUtilization,
} from "providers/TrrystVideocallProvider";
import { getErrorContext } from "providers/TrrystVideocallProvider/wrappers/ChimeErrorHandlerProvider";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  CardHeader,
  Card,
  Box,
} from "@mui/material";
import { DevicePermissionPrompt } from "blocks/modules/TrrystVideocall";
import { useAppContext, executeApi, getS3ImageWrapper } from "@app21/core";
import { LoadingSpinner } from "blocks/atoms";
import { useHandleLeaveVideocall } from "providers/TrrystVideocallProvider/hooks";
import { FlowBox } from "blocks/atoms/uistyles";

const VIDEO_TRANSFORM_FILTER_OPTIONS = [
  { value: VideoFiltersCpuUtilization.Disabled, label: "Disable Video Filter" },
  {
    value: VideoFiltersCpuUtilization.CPU10Percent,
    label: "Video Filter CPU 10%",
  },
  {
    value: VideoFiltersCpuUtilization.CPU20Percent,
    label: "Video Filter CPU 20%",
  },
  {
    value: VideoFiltersCpuUtilization.CPU40Percent,
    label: "Video Filter CPU 40%",
  },
];

const InitiateMeeting = () => {
  const meetingManager = useMeetingManager();
  const { setChimeAttendeeId, setChimeRoute, isBotJoining, setJoinInfo } =
    useVideocallContext();
  const { leaveVideocall } = useHandleLeaveVideocall();
  const { userInfo, meetingId, setChimeViewMode } = useAppContext();
  const {
    region,
    setRegion,
    meetingMode,
    enableSimulcast,
    priorityBasedPolicy,
    keepLastFrameWhenPaused,
    isVoiceFocusEnabled,
    isEchoReductionEnabled,
  } = useChimeState();
  const [, setUserInfoErr] = useState(false);
  const [, setMeetingErr] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { errorMessage, updateErrorMessage } = useContext(getErrorContext());

  const handleJoinMeeting = async () => {
    if (!meetingId || !userInfo) {
      if (!userInfo) setUserInfoErr(true);
      if (!meetingId) setMeetingErr(true);
      return;
    }

    setIsLoading(true);
    meetingManager.getAttendee = async (chimeAttendeeId, externalUserId) => {
      const attendee = await executeApi("FETCH-CHIME-ATTENDEE", {
        userId: externalUserId,
        meetingId: meetingId,
      });
      let attendeeObj = {
        name: "Default",
        image: { url: getS3ImageWrapper("", "person") },
      };

      if (attendee?.data) {
        attendeeObj.name = attendee.data.fullName ?? "Default";
        attendeeObj.image.url = getS3ImageWrapper(
          attendee.data.imageUrl,
          "person"
        );
        attendeeObj.emailId = attendee.data.emailId;
      }
      setChimeAttendeeId(chimeAttendeeId);
      return attendeeObj;
    };

    try {
      let JoinInfo = null;

      const response = await executeApi("JOIN-CHIME-MEETING", {
        clientTokenId: meetingId,
        userId: userInfo?._id,
        region: region, //added  new feature for now.. need to make region selection more closer to user geo
        echoReductionFeature: isEchoReductionEnabled, //added  new feature for now.. need to tie it up with backend
      });

      if (response.status === "error") {
        /* TODO:  handle error message */
        //  leaveChimeMeeting();
      } else JoinInfo = response?.data?.JoinInfo ?? {};

      setJoinInfo(JoinInfo);
      const meetingSessionConfiguration = new MeetingSessionConfiguration(
        JoinInfo?.Meeting,
        JoinInfo?.Attendee
      );
      if (
        meetingConfig.postLogger &&
        meetingSessionConfiguration.meetingId &&
        meetingSessionConfiguration.credentials &&
        meetingSessionConfiguration.credentials.attendeeId
      ) {
        const existingMetadata = meetingConfig.postLogger.metadata;
        meetingConfig.postLogger.metadata = {
          ...existingMetadata,
          meetingId: meetingSessionConfiguration.meetingId,
          attendeeId: meetingSessionConfiguration.credentials.attendeeId,
        };
      }

      setRegion(JoinInfo.Meeting.MediaRegion);
      meetingSessionConfiguration.enableSimulcastForUnifiedPlanChromiumBasedBrowsers =
        enableSimulcast;
      if (priorityBasedPolicy) {
        meetingSessionConfiguration.videoDownlinkBandwidthPolicy =
          priorityBasedPolicy;
      }
      meetingSessionConfiguration.keepLastFrameWhenPaused =
        keepLastFrameWhenPaused;
      const options = {
        deviceLabels:
          meetingMode === AvailableMeetingModes.Spectator
            ? DeviceLabels.None
            : DeviceLabels.AudioAndVideo,
        enableWebAudio: isVoiceFocusEnabled,
      };

      await meetingManager.join(meetingSessionConfiguration, options);
      if (meetingMode === AvailableMeetingModes.Spectator || isBotJoining) {
        await meetingManager.start();
        setChimeRoute("meeting");
        isBotJoining && setChimeViewMode("fullscreen");
      } else {
        //  setMeetingMode(AvailableMeetingModes.Attendee);
        setChimeRoute("device");
      }
    } catch (error) {
      updateErrorMessage(error.message);
    }
  };

  const closeChimeMeeting = async () => {
    updateErrorMessage("");
    setIsLoading(false);
    await leaveVideocall({ meetingManager });
  };

  React.useEffect(() => {
    if (meetingId) {
      setMeetingErr(false);
      handleJoinMeeting();
    } else {
      closeChimeMeeting();
    }
  }, []);

  return (
    <FlowBox sx={{ mt: 1, justifyContent: "center", alignItems: "center" }}>
      <LoadingSpinner />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Button label="Continue" onClick={handleJoinMeeting} />
      )}
      <Dialog
        size="md"
        open={Boolean(errorMessage)}
        onClose={closeChimeMeeting}
      >
        <DialogTitle>
          Unable to join meeting with Meeting ID: {meetingId}
        </DialogTitle>
        <DialogContent>
          <Card>
            <CardHeader
              title="There was an issue finding that meeting. The meeting may have already ended, or your authorization may have expired."
              subheader={errorMessage}
            />
          </Card>
        </DialogContent>
      </Dialog>
      <DevicePermissionPrompt />
    </FlowBox>
  );
};

export default InitiateMeeting;
