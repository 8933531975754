import React from "react";
import {
  Box,
  Typography,
  Grid,
  Stack,
  Card,
  CardHeader,
  CardActionArea,
  CardContent,
  CardActions,
  Button,
  Divider,
  List,
} from "@mui/material";
import { UserAvatar, ProfilesChipsList, PrintUserInfo } from "blocks/atoms";
import { format } from "date-fns";
import {
  checkIfNull,
  useResolutionsInDocket,
  useAppContext,
} from "@app21/core";
import { useDocketsContext } from "providers/DocketsProvider";
import { Forward } from "@mui/icons-material";
import {
  ListAgendaItems,
  ListDocketResolutions,
} from "blocks/modules/Dockets/foundations";
import { ListResolutionQuestions } from "blocks/modules/Surveys/foundations";

export default function ShowUpcomingDocketCard({ docket }) {
  const { loadRoute, docketPermissions } = useDocketsContext();

  const handleDocketClick = () =>
    loadRoute("VIEW-DOCKET", { docketId: docket._id });
  const {
    data: resolutionsInDocket,
    status: resolutionsInDocketStatus,
    apiError: resolutionsInDocketApiError,
  } = useResolutionsInDocket(docket._id);
  const lastMeeting = docket.relatedMeetings?.slice(-1)[0];
  const allInvitees = [...(docket.members ?? []), ...(docket.visitors ?? [])];
  return (
    <Card>
      <CardActionArea onClick={handleDocketClick}>
        <CardHeader
          avatar={<UserAvatar userId={lastMeeting?.meetingJSON?.createdBy} />}
          title={
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              <PrintUserInfo userId={lastMeeting?.meetingJSON?.createdBy} /> |{" "}
              {lastMeeting?.meetingStartTime ? (
                <span>
                  {" "}
                  {format(
                    new Date(lastMeeting.meetingStartTime),
                    "h:mm a d LLL yy "
                  )}{" "}
                  -{" "}
                  {format(
                    new Date(lastMeeting.meetingEndTime),
                    "h:mm a d LLL yy "
                  )}
                </span>
              ) : (
                "Not yet Scheduled"
              )}
            </Typography>
          }
        />
        <CardContent sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h5" color="primary">
            {docket.title}
          </Typography>
          {docketPermissions.checkCanAccessMembers(docket) ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                Invitees -
              </Typography>
              <ProfilesChipsList
                usersList={allInvitees}
                dense={false}
                showAs={"avatar"}
              />
            </Box>
          ) : (
            <></>
          )}
          {Boolean(
            resolutionsInDocket?.length || lastMeeting?.agendaItems?.length
          ) && (
            <Box sx={{ display: "flex", flexGrow: 1, gap: 3 }}>
              {Boolean(lastMeeting?.agendaItems?.length) && (
                <Box
                  sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
                >
                  <Typography variant="subtitle1" sx={{ my: 2 }}>
                    Agenda
                  </Typography>
                  <Divider />
                  <ListAgendaItems agendaItems={lastMeeting?.agendaItems} />
                </Box>
              )}
              <Box
                sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
              >
                <Typography variant="subtitle1" sx={{ my: 2 }}>
                  Resolutions
                </Typography>
                <Divider />
                {resolutionsInDocket?.length === 0 ? (
                  <Typography variant="body2" sx={{ my: 2 }}>
                    No Resolutions tabled
                  </Typography>
                ) : (
                  <List dense disablePadding>
                    {resolutionsInDocket?.map((resolution, allIndex) => (
                      <ListResolutionQuestions
                        key={allIndex}
                        resolution={resolution}
                      />
                    ))}
                  </List>
                )}
              </Box>
            </Box>
          )}
        </CardContent>
      </CardActionArea>
      <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
        {/* <Button>RSVP</Button> */}
        <Button onClick={handleDocketClick} endIcon={<Forward />}>
          Details
        </Button>
        {/* <Button>Join</Button> */}
      </CardActions>
    </Card>
  );
}
