// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import PropTypes from "prop-types";
import { Link } from "@mui/material";
import Logo from "blocks/atoms/Logo";
import TopBarLayout from "blocks/views/Layouts/features/TopBarLayout";
import { useIntl } from "react-intl";

import { MHidden } from "blocks/atoms/@material-extend";

const GenericTopbar = ({ className, ...rest }) => {
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });

  const LeftPanel = () => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        marginRight: -50,
        paddingLeft: (theme) => theme.spacing(1),
        minWidth: 120,
      }}
    >
      <Logo style={{ marginRight: (theme) => theme.spacing(2) }} />
    </div>
  );

  const MiddlePanel = () => (
    <MHidden width="xsUp">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexGrow: 1,
        }}
      >
        <Link
          sx={{
            fontWeight: (theme) => theme.typography.fontWeightMedium,
            paddingLeft: (theme) => theme.spacing(1),
            paddingRight: (theme) => theme.spacing(1),
            "& + &": {
              marginLeft: (theme) => theme.spacing(2),
              marginRight: (theme) => theme.spacing(2),
            },
          }}
          color="textSecondary"
          href="/#features"
          underline="none"
          variant="body2"
        >
          {`${t("TopNavbar.features")}`}
        </Link>
        <Link
          sx={{
            fontWeight: (theme) => theme.typography.fontWeightMedium,
            paddingLeft: (theme) => theme.spacing(1),
            paddingRight: (theme) => theme.spacing(1),
            "& + &": {
              marginLeft: (theme) => theme.spacing(2),
              marginRight: (theme) => theme.spacing(2),
            },
          }}
          color="textSecondary"
          href="#FAQs"
          underline="none"
          variant="body2"
        >
          {`${t("TopNavbar.pricing")}`}
        </Link>
        <Link
          sx={{
            fontWeight: (theme) => theme.typography.fontWeightMedium,
            paddingLeft: (theme) => theme.spacing(1),
            paddingRight: (theme) => theme.spacing(1),
            "& + &": {
              marginLeft: (theme) => theme.spacing(2),
              marginRight: (theme) => theme.spacing(2),
            },
          }}
          color="textSecondary"
          href="/#FAQs"
          underline="none"
          variant="body2"
        >
          {`${t("TopNavbar.faq")}`}
        </Link>
        <Link
          sx={{
            fontWeight: (theme) => theme.typography.fontWeightMedium,
            paddingLeft: (theme) => theme.spacing(1),
            paddingRight: (theme) => theme.spacing(1),
            "& + &": {
              marginLeft: (theme) => theme.spacing(2),
              marginRight: (theme) => theme.spacing(2),
            },
          }}
          color="textSecondary"
          href="/#home"
          underline="none"
          variant="body2"
        >
          {`${t("TopNavbar.about")}`}
        </Link>
      </div>
    </MHidden>
  );

  const RightPanel = () => <></>;

  return (
    <TopBarLayout
      viewContext="main"
      LeftComponent={LeftPanel}
      MainComponent={MiddlePanel}
      RightComponent={RightPanel}
    />
  );
};

GenericTopbar.propTypes = {
  className: PropTypes.string,
};

export default GenericTopbar;
