import { SvgIcon } from "@mui/material";
import React from "react";

export default function AddUserWebIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 56 56"
      stroke="#FAD2A0"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      fill="#07493C"
      {...props}
    >
      <g fill="none">
        <rect
          width="56"
          height="56"
          rx="4.8"
          stroke="none"
          fill="currentColor"
        />
        <path
          d="M33.02 17.0095C32.18 16.3695 31.14 15.9995 30 15.9995C27.24 15.9995 25 18.2395 25 20.9995C25 23.7595 27.24 25.9995 30 25.9995C32.76 25.9995 35 23.7595 35 20.9995"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.4102 36C21.4102 32.13 25.2602 29 30.0002 29C30.9602 29 31.8902 29.13 32.7602 29.37"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M40 31.9995C40 32.3195 39.96 32.6295 39.88 32.9295C39.79 33.3295 39.63 33.7195 39.42 34.0595C38.73 35.2195 37.46 35.9995 36 35.9995C34.97 35.9995 34.04 35.6095 33.34 34.9695C33.04 34.7095 32.78 34.3995 32.58 34.0595C32.21 33.4595 32 32.7495 32 31.9995C32 30.9195 32.43 29.9295 33.13 29.2095C33.86 28.4595 34.88 27.9995 36 27.9995C37.18 27.9995 38.25 28.5095 38.97 29.3295C39.61 30.0395 40 30.9795 40 31.9995Z"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.49 31.98H34.51"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M35.9983 30.52V33.51"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
