import React from "react";
import { Box, Chip, Typography } from "@mui/material";
import clip from "text-clipper";

export default function ShowSelectedDocketsChips({
  onClear = () => null,
  dockets = [],
}) {
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", m: 1 }}>
      {dockets?.map((docket, index) => (
        <Chip
          key={index}
          label={
            <Typography variant="caption">
              {clip(docket?.label ?? docket?.title, 20)}
            </Typography>
          }
          onDelete={onClear}
        />
      ))}
    </Box>
  );
}
