// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import path from "path";
import clip from "text-clipper";
import { Home, Folder } from "@mui/icons-material";

export default function constructFolderChain({
  decodedS3Prefix,
  filesPermissions,
  viewContext,
  selectedOrganizationId,
  selectedSuiteId,
  docketsInSuite,
  selectedDocketId,
  clipTextLength = 30,
}) {
  let folderChain = [],
    tempFolderChain = [];
  if (decodedS3Prefix === "/" || decodedS3Prefix === "null") {
    folderChain = [];
  } else {
    let currentPrefix = "";
    tempFolderChain = decodedS3Prefix
      .replace(/\/*$/, "")
      .split("/")
      .map((prefixPart) => {
        currentPrefix = currentPrefix
          ? path.join(currentPrefix, prefixPart)
          : prefixPart;
        let nameString = null;
        let folderChainIcon = null;
        if (viewContext === "SUITE") {
          if (prefixPart === selectedOrganizationId) {
            nameString = null;
          } else if (
            prefixPart === selectedSuiteId ||
            prefixPart === "records"
          ) {
            currentPrefix = currentPrefix + "/";
            nameString = "Home"; //selectedSuite?.fullName
            folderChainIcon = Home;
          } else if (prefixPart === "dockets") {
            nameString = "Breakouts";
          } else {
            nameString =
              docketsInSuite?.find((d) => d._id === prefixPart)?.title ||
              prefixPart;
          }
        }
        if (viewContext === "DOCKET") {
          if (prefixPart === selectedOrganizationId) {
            nameString = null;
          } else if (
            prefixPart === selectedSuiteId ||
            prefixPart === "dockets" ||
            prefixPart === "meetings" ||
            prefixPart === "records"
          ) {
            nameString = null;
            folderChainIcon = Home;
          } else if (
            prefixPart === selectedDocketId ||
            prefixPart === "meetings" ||
            prefixPart === "records"
          ) {
            nameString = docketsInSuite?.find(
              (d) => d._id === selectedDocketId
            )?.title;
            folderChainIcon = Folder;
          } else nameString = prefixPart;
        }
        return {
          id: currentPrefix,
          name: nameString
            ? clip(nameString, clipTextLength, { breakWords: true })
            : nameString,
          folderChainIcon: folderChainIcon,
          isDir: true,
        };
      });
  }

  tempFolderChain?.forEach((f) => {
    if (f.name !== null) folderChain.push(f);
  });

  return folderChain;
}
