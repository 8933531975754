// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Button } from "@mui/material";
import { useIntl } from "react-intl";
import { useAuth0 } from "@auth0/auth0-react";
import { Icon } from "@iconify/react";
import flashFill from "@iconify/icons-eva/flash-fill";

const SignupButton = ({
  variant = "contained",
  label,
  routeToPush = null,
  color = "primary",
  sx,
  ...rest
}) => {
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });
  const { loginWithRedirect } = useAuth0();

  return (
    <Button
      size="large"
      variant={variant}
      color={color}
      startIcon={<Icon icon={flashFill} width={20} height={20} />}
      onClick={() => {
        if (routeToPush && typeof window !== "undefined") {
          localStorage.setItem("savedRoute", routeToPush);
        }
        loginWithRedirect({
          appState: { targetUrl: `${process.env.ROOT_URL}/auth0_callback` },
          screen_hint: "signup",
          isPasswordlessLogin: false,
        });
      }}
      sx={{ ...sx }}
      {...rest}
    >
      {label ? label : `${t("ctaButton.text")}`}
    </Button>
  );
};

export default SignupButton;
