import React from 'react'
import { SvgIcon } from '@mui/material';

export default function MonitorIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M91.6585 44.2918V53.2502C91.6585 68.0835 87.9502 71.7502 73.1585 71.7502H26.8252C12.0335 71.7502 8.3252 68.0418 8.3252 53.2502V26.8335C8.3252 12.0418 12.0335 8.3335 26.8252 8.3335H73.1169C87.9502 8.3335 91.6585 12.0418 91.6585 26.8335"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M50 71.749V91.6657"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.3252 54.1665H91.6585"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.25 91.6665H68.75"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
