// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { forwardRef, useRef } from "react";
import PropTypes from "prop-types";
import {
  useAudioVideo,
  useActiveSpeakersState,
} from "amazon-chime-sdk-component-library-react";
import { styled } from "@mui/material/styles";
import { checkIfNull } from "@app21/core";
import NoVideoTile from "./NoVideoTile";

export const StyledVideoTile = styled("div")(({ theme, ownerState }) => {
  const { objectFit, active, featured } = ownerState;
  return {
    flexGrow: 1,
    height: "100%",
    width: "100%",
    position: "relative",
    borderRadius: 12,
    background: "unset",

    overflow: "hidden",
    outline: featured ? `3px solid ${theme.palette.primary.light}` : null,
    boxSizing: "content-box",
    display: "flex",
    flexDirection: "column",
    "& video": {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: "100%",
      height: "100%",
      borderRadius: 10,
      objectFit: objectFit,
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
    },
    "& .ch-offTile": {
      height: "100%",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexGrow: 1,
      borderRadius: 15,
      border: "1px solid Lightgrey",
    },
    "& .ch-offTile .ch-avatar": {
      width: "36%",
      height: "64%",
      minHeight: 25,
      maxHeight: 120,
      minWidth: 25,
      maxWidth: 120,
      aspectRatio: "auto 1 / 1",
    },
    "& .ch-nameplate": {
      backdropFilter: "blur(20px)",
      backgroundColor: "rgba(46, 47, 52, 0.65)",
      borderRadius: "0.5rem",
      bottom: "0.5rem",
      color: theme.palette.primary.contrastText,
      left: "0.5rem",
      maxWidth: "calc(100% - 2rem)",
      padding: "0.5rem",
      position: "absolute",
      "& .ch-text": {
        fontSize: theme.typography.caption.fontSize,
        margin: 0,
      },
    },
  };
});

export const CustomVideoTile = forwardRef((props, ref) => {
  const { objectFit, tile, active, context, showBottomBar = true } = props;
  const audioVideo = useAudioVideo();

  const activeSpeakers = useActiveSpeakersState();
  const featured =
    activeSpeakers.includes(tile.chimeAttendeeId) || tile.featured;

  React.useEffect(() => {
    let currentRef = ref?.current;
    if (audioVideo && tile.tileId && !checkIfNull(currentRef)) {
      ref.current.disablePictureInPicture = false;
      ref.current.autoPictureInPicture = true;
      ref.current.userActivationRequired = false;
      ref.current.playingRequired = true;
    }
  }, [audioVideo, ref?.current]);

  return (
    <StyledVideoTile ownerState={{ objectFit, active, featured }}>
      {active ? (
        <video
          ref={ref}
          style={{ transform: context !== "remote" ? null : "none" }}
        />
      ) : (
        <NoVideoTile
          refObj={ref}
          name={tile?.tileInfo?.name}
          avatarUrl={tile?.tileInfo?.image?.url}
        />
      )}
    </StyledVideoTile>
  );
});

CustomVideoTile.propTypes = {
  objectFit: PropTypes.oneOf([
    "contain",
    "cover",
    "fill",
    "none",
    "scale-down",
  ]),

  context: PropTypes.string,
};
CustomVideoTile.defaultProps = {
  objectFit: "cover",
  active: true,
  context: "remote",
};

export default CustomVideoTile;
