export const LANGUAGES = [
  {
    value: "en",
    label: "English",
    icon: "/static/icons/ic_flag_en.svg",
  },
  {
    value: "de",
    label: "German",
    icon: "/static/icons/ic_flag_de.svg",
  },
  {
    value: "fr",
    label: "French",
    icon: "/static/icons/ic_flag_fr.svg",
  },
];
