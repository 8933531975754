export const formalAgenda = [
  {
    seq: "1",
    when: "7:30 - 8 AM",
    what: '<p><span class="ql-cursor">﻿</span><strong>In-camera Session</strong></p>',
    who: "BOD",
    files: [],
  },
  {
    seq: "2",
    when: "30 mins",
    what: '<p><strong>2.	Meeting Opening </strong></p><p class="ql-indent-1">2.1	Apologies </p><p class="ql-indent-1">2.2	Director Conflict of Interest Disclosures </p><p class="ql-indent-1">2.3	Previous Minutes</p><p class="ql-indent-2">2.3.1	Confirmation </p><p class="ql-indent-2">2.3.2	Matters Arising/ActionsList</p>',
    who: "Chair",
    files: [],
  },

  {
    seq: "3",
    when: "60 - 120 minutes",
    what: '<p><strong>3. Matters for Decision </strong></p><p class="ql-indent-1">3.1  Major strategy decisions </p><p class="ql-indent-1">3.2 Routine decisions including capital equipment approval and expenditure (discussed only if requested by a director)</p>',
    who: "Chair / Secretary",
    files: [],
  },

  {
    seq: "4",
    when: "60 - 120 mins",
    what: '<p><strong>4.	Matters for Discussion</strong></p><p class="ql-indent-1">4.1	CEO’s Report</p><p class="ql-indent-2">4.1.1	Current Significant Issues</p><p class="ql-indent-2">4.1.2	Matters for Approval </p><p class="ql-indent-2">4.1.3	Update on Strategic Plan Implementation</p><p class="ql-indent-2">4.1.4	Major Key Performance Indicators</p><p class="ql-indent-2">4.1.5	Risk and Compliance Update</p><p class="ql-indent-2">4.1.6	Matters for Noting</p><p class="ql-indent-1">4.2	CFO’s Report</p><p class="ql-indent-1">4.3	Discussions concerning forthcoming strategic decisions</p><p class="ql-indent-1">4.4	Committee Minutes</p><p class="ql-indent-2">4.4.1	Audit</p><p class="ql-indent-2">4.4.2	Other</p><p class="ql-indent-1">4.5	Other matters for discussion</p><p class="ql-indent-1">4.6	Chairman’s Update</p><p class="ql-indent-1">4.7	Presentation by management (if required)</p>',
    who: "CEO, CFO, Chairman, Special Invitees",
    files: [],
  },

  {
    seq: "5",
    when: "10 mins",
    what: '<p><strong>5.	Matters for Noting</strong> [discussed only on exception basis]</p><p class="ql-indent-1">5.1	Major Correspondence</p><p class="ql-indent-1">5.2	Company Seal</p><p class="ql-indent-1">5.3	Updated board calendar</p><p class="ql-indent-1">5.4	Other matters for noting</p>',
    who: "Company Secretary",
    files: [],
  },

  {
    seq: "6",
    when: "10 mins",
    what: '<p><strong>6.	Meeting Finalization </strong></p><p class="ql-indent-1">6.1	Review actions to be taken </p><p class="ql-indent-1">6.2	Meeting evaluation </p><p class="ql-indent-1">6.3	Next meeting </p><p class="ql-indent-1">6.4	Meeting close"</p>',
    who: "Chair",
    files: [],
  },
];
