import React from 'react'
import { SvgIcon } from "@mui/material";

export default function TickIconSquare(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 305 306"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <svg width="305" height="306" fill="none">
        <g filter="url(#filter0_f_1830_150288)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M70.6219 82.5887C60.9409 95.9135 60.9409 114.921 60.9409 152.936C60.9409 190.951 60.9409 209.959 70.6219 223.284C73.7485 227.587 77.5329 231.371 81.8363 234.498C95.1611 244.179 114.169 244.179 152.184 244.179C190.199 244.179 209.206 244.179 222.531 234.498C226.835 231.371 230.619 227.587 233.746 223.284C243.427 209.959 243.427 190.951 243.427 152.936C243.427 114.921 243.427 95.9135 233.746 82.5887C230.619 78.2854 226.835 74.5009 222.531 71.3744C209.206 61.6934 190.199 61.6934 152.184 61.6934C114.169 61.6934 95.1611 61.6934 81.8363 71.3744C77.5329 74.5009 73.7485 78.2854 70.6219 82.5887Z"
            fill="#FAD2A0"
            fillOpacity="0.2"
          />
        </g>
        <g filter="url(#filter1_b_1830_150288)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M64.5384 73.4647C54.8574 86.7895 54.8574 105.797 54.8574 143.812C54.8574 181.827 54.8574 200.835 64.5384 214.16C67.665 218.463 71.4494 222.247 75.7528 225.374C89.0776 235.055 108.085 235.055 146.1 235.055C184.115 235.055 203.123 235.055 216.448 225.374C220.751 222.247 224.536 218.463 227.662 214.16C237.343 200.835 237.343 181.827 237.343 143.812C237.343 105.797 237.343 86.7895 227.662 73.4647C224.536 69.1613 220.751 65.3769 216.448 62.2504C203.123 52.5693 184.115 52.5693 146.1 52.5693C108.085 52.5693 89.0776 52.5693 75.7528 62.2504C71.4494 65.3769 67.665 69.1613 64.5384 73.4647Z"
            fill="#FAD2A0"
          />
        </g>
        <g filter="url(#filter2_d_1830_150288)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M202.455 118.469C205.256 115.341 204.99 110.534 201.862 107.733C198.733 104.932 193.926 105.197 191.125 108.326L156.968 146.477C150.047 154.208 145.386 159.387 141.4 162.736C137.604 165.927 135.374 166.623 133.427 166.623C131.479 166.623 129.249 165.927 125.453 162.736C121.468 159.387 116.807 154.208 109.885 146.477L101.074 136.635C98.2726 133.506 93.4657 133.241 90.337 136.042C87.2084 138.843 86.9428 143.65 89.7439 146.779L98.9321 157.041C105.378 164.242 110.755 170.248 115.668 174.378C120.865 178.746 126.471 181.83 133.427 181.83C140.382 181.83 145.988 178.746 151.185 174.378C156.098 170.248 161.475 164.242 167.921 157.041L202.455 118.469Z"
            fill="url(#paint0_linear_1830_150288)"
          />
        </g>
        <defs>
          <filter
            id="filter0_f_1830_150288"
            x="0.112347"
            y="0.864788"
            width="304.143"
            height="304.142"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="30.4143"
              result="effect1_foregroundBlur_1830_150288"
            />
          </filter>
          <filter
            id="filter1_b_1830_150288"
            x="-18.1369"
            y="-20.425"
            width="328.474"
            height="328.474"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="36.4971" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_1830_150288"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_1830_150288"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_1830_150288"
            x="72.598"
            y="90.5868"
            width="165.251"
            height="124.699"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="9.12429" dy="9.12429" />
            <feGaussianBlur stdDeviation="12.1657" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.980392 0 0 0 0 0.823529 0 0 0 0 0.627451 0 0 0 0.5 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1830_150288"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1830_150288"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_1830_150288"
            x1="147.081"
            y1="198.029"
            x2="243.894"
            y2="52.5148"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#07493C" />
            <stop offset="1" stopColor="#07493C" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
}
