// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";

{
  /*import { faFilm } from '@fortawesome/free-solid-svg-icons';
import { faFileVideo } from '@fortawesome/free-solid-svg-icons';
import { faFileAudio } from '@fortawesome/free-solid-svg-icons';
import { faFistRaised } from '@fortawesome/free-solid-svg-icons';
import { faFolder } from '@fortawesome/free-solid-svg-icons';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { faFolderPlus } from '@fortawesome/free-solid-svg-icons';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import { faLevelUpAlt } from '@fortawesome/free-solid-svg-icons';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { faMusic } from '@fortawesome/free-solid-svg-icons';
import { faObjectGroup } from '@fortawesome/free-solid-svg-icons';
import { faPaste } from '@fortawesome/free-solid-svg-icons';
import { faRunning } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { faSortAmountDownAlt } from '@fortawesome/free-solid-svg-icons';
import { faSortAmountUpAlt } from '@fortawesome/free-solid-svg-icons';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { faTerminal } from '@fortawesome/free-solid-svg-icons';
import { faTh } from '@fortawesome/free-solid-svg-icons';
import { faThLarge } from '@fortawesome/free-solid-svg-icons';
import { faThList } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faToggleOff } from '@fortawesome/free-solid-svg-icons';
import { faToggleOn } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; */
}
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { faFileArchive } from "@fortawesome/free-solid-svg-icons";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { faFilePowerpoint } from "@fortawesome/free-solid-svg-icons";
import { faFileImage } from "@fortawesome/free-solid-svg-icons";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { faFileWord } from "@fortawesome/free-solid-svg-icons";
import { faFileVideo } from "@fortawesome/free-solid-svg-icons";
import { faFileAudio } from "@fortawesome/free-solid-svg-icons";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { faFolderOpen } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const FileIcons = (props) => {
  return {
    File: (
      <FontAwesomeIcon icon={faFile} style={{ marginRight: 5 }} {...props} />
    ),
    Image: (
      <FontAwesomeIcon
        icon={faFileImage}
        style={{ marginRight: 5 }}
        color="#4682b4"
        {...props}
      />
    ),
    Video: (
      <FontAwesomeIcon
        icon={faFileVideo}
        style={{ marginRight: 5 }}
        {...props}
      />
    ),
    Audio: (
      <FontAwesomeIcon
        icon={faFileAudio}
        style={{ marginRight: 5 }}
        {...props}
      />
    ),
    Archive: (
      <FontAwesomeIcon
        icon={faFileArchive}
        style={{ marginRight: 5 }}
        {...props}
      />
    ),
    Word: (
      <FontAwesomeIcon
        icon={faFileWord}
        style={{ marginRight: 5 }}
        color="#144EB0"
        {...props}
      />
    ),
    Excel: (
      <FontAwesomeIcon
        icon={faFileExcel}
        style={{ marginRight: 5 }}
        color="#1D6F42"
        {...props}
      />
    ),
    PowerPoint: (
      <FontAwesomeIcon
        icon={faFilePowerpoint}
        style={{ marginRight: 5 }}
        color="#D04423"
        {...props}
      />
    ),
    Text: (
      <FontAwesomeIcon icon={faFileAlt} style={{ marginRight: 5 }} {...props} />
    ),
    PDF: (
      <FontAwesomeIcon
        icon={faFilePdf}
        style={{ marginRight: 5 }}
        color="#F40F02"
        {...props}
      />
    ),
    Rename: (
      <i className="fa fa-i-cursor" style={{ marginRight: 5 }} {...props} />
    ),
    Folder: (
      <FontAwesomeIcon icon={faFolder} style={{ marginRight: 5 }} {...props} />
    ),
    FolderOpen: (
      <FontAwesomeIcon
        icon={faFolderOpen}
        style={{ marginRight: 5 }}
        {...props}
      />
    ),
    Delete: (
      <FontAwesomeIcon icon={faTrash} style={{ marginRight: 5 }} {...props} />
    ),
    Loading: (
      <i
        className="fa fa-circle-o-notch fa-spin"
        style={{ marginRight: 5 }}
        {...props}
      />
    ),
    Download: (
      <FontAwesomeIcon
        icon={faDownload}
        style={{ marginRight: 5 }}
        {...props}
      />
    ),
  };
};
export default FileIcons;
