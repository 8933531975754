import React from 'react'
import { SvgIcon } from '@mui/material';

export default function FilesViewedIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M8.33358 35.4168C8.33358 20.8335 16.6669 14.5835 29.1669 14.5835H70.8336C83.3336 14.5835 91.6669 20.8335 91.6669 35.4168V47.9168"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M50.0002 85.4163H29.1669C16.6669 85.4163 8.33358 79.1663 8.33358 64.583V51.833"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M70.8336 37.5L57.7919 47.9167C53.5002 51.3333 46.4586 51.3333 42.1669 47.9167L29.1669 37.5"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M75.8336 89.1666C83.1974 89.1666 89.1669 83.1972 89.1669 75.8334C89.1669 68.4696 83.1974 62.5 75.8336 62.5C68.4698 62.5 62.5002 68.4696 62.5002 75.8334C62.5002 83.1972 68.4698 89.1666 75.8336 89.1666Z"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M91.6669 91.6667L87.5002 87.5"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
