import React from "react";
import { Box, Typography, List } from "@mui/material";
import { FlowBox } from "blocks/atoms/uistyles";
import { useAppContext, useHandleRouterPush, checkIfNull } from "@app21/core";
import { useAtom } from "jotai";
import PerfectScrollbar from "react-perfect-scrollbar";
import { viewFilesContextAtom } from "providers/FilesProvider/files-atoms";
import ShowNestedFolders from "./ShowNestedFolders";

export default function ShowBreakoutFolders({ foldersList = [] }) {
  const breakoutFolders = foldersList?.length > 0 ? foldersList[0].folders : [];
  return (
    <FlowBox>
      <ShowNestedFolders foldersList={breakoutFolders} />
    </FlowBox>
  );
}
