// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import { useState, useEffect } from "react";
import menu2Fill from "@iconify/icons-eva/menu-2-fill";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
import arrowIosUpwardFill from "@iconify/icons-eva/arrow-ios-upward-fill";
// next
import NextLink from "next/link";
import { useRouter } from "next/router";

// material
import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  List,
  Drawer,
  Link,
  Collapse,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
// components
import Logo from "blocks/atoms/Logo";
import NavSection from "blocks/atoms/uicomponents/NavSection";
import Scrollbar from "blocks/atoms/uicomponents/Scrollbar";
import { IconButtonAnimate } from "blocks/atoms";
//
import MenuConfig from "./MenuConfig";
import { checkIfNull } from "@app21/core";
import { Close } from "@mui/icons-material";
// ----------------------------------------------------------------------

const ICON_SIZE = 22;
const ITEM_SIZE = 48;
const PADDING = 2.5;

const ListItemStyle = styled(ListItem)(({ theme }) => ({
  ...theme.typography.h4,
  height: ITEM_SIZE,
  textTransform: "capitalize",
  paddingLeft: theme.spacing(PADDING),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.primary.contrastText,
}));

// ----------------------------------------------------------------------

MenuMobileItem.propTypes = {
  item: PropTypes.object,
  isOpen: PropTypes.bool,
  isActive: PropTypes.bool,
  onOpen: PropTypes.func,
};

function MenuMobileItem({ item, isOpen, isActive, onOpen, onClose, allItems }) {
  const { title, path, icon, children } = item;
  const router = useRouter();
  if (children) {
    return (
      <div key={title}>
        <ListItemStyle>
          <ListItemButton onClick={onOpen}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText disableTypography primary={title} />
            <Box
              component={Icon}
              icon={isOpen ? arrowIosUpwardFill : arrowIosDownwardFill}
              sx={{ width: 16, height: 16, ml: 1 }}
            />
          </ListItemButton>
        </ListItemStyle>

        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <Box sx={{ display: "flex", flexDirection: "column-reverse" }}>
            <NavSection navConfig={children} />
          </Box>
        </Collapse>
      </div>
    );
  }

  return (
    <ListItemStyle
      key={title}
      sx={{
        ...(isActive && {
          color: "secondary.main",
          fontWeight: "fontWeightMedium",
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.selectedOpacity
            ),
        }),
      }}
    >
      <ListItemButton
        onClick={() => {
          router.push(path);
          onClose();
        }}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText disableTypography primary={title} />
      </ListItemButton>
    </ListItemStyle>
  );
}

MenuMobile.propTypes = {
  isOffset: PropTypes.bool,
  isHome: PropTypes.bool,
};

export default function MenuMobile({ isOffset, isHome }) {
  const { pathname, asPath } = useRouter();
  const [open, setOpen] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const menuConfig = MenuConfig();

  useEffect(() => {
    if (mobileOpen) {
      handleDrawerClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleDrawerOpen = () => {
    setMobileOpen(true);
  };

  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      {!checkIfNull(menuConfig) && (
        <IconButtonAnimate
          onClick={handleDrawerOpen}
          sx={{
            ml: 1,
            ...(isHome && { color: "text.secondary" }),
            ...(isOffset && { color: "text.primary" }),
          }}
        >
          <Icon icon={menu2Fill} />
        </IconButtonAnimate>
      )}

      <Drawer
        open={mobileOpen}
        anchor="right"
        onClose={handleDrawerClose}
        ModalProps={{ keepMounted: true }}
        PaperProps={{ sx: { pb: 5, width: 260, bgcolor: "primary.main" } }}
      >
        <Scrollbar>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 2,
            }}
          >
            <Box
              sx={{
                display: "inline-flex",
                mx: PADDING,
                my: 3,
              }}
            >
              <Logo variant="white" />
            </Box>
            <IconButtonAnimate onClick={handleDrawerClose}>
              <Close
                sx={{ width: 24, height: 24, color: "primary.contrastText" }}
              />
            </IconButtonAnimate>
          </Box>

          <List>
            {menuConfig.map((link) => (
              <MenuMobileItem
                key={link.title}
                item={link}
                allItems={menuConfig}
                isOpen={open}
                onOpen={handleOpen}
                onClose={handleDrawerClose}
                isActive={asPath.includes(link.path) && link.path !== "/"}
              />
            ))}
          </List>
        </Scrollbar>
      </Drawer>
    </>
  );
}
