// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  useAppContext,
  useOrganization,
  useHandleOrganizationActions,
  useHandleManageImage,
  useApp21ProductsAndCoupons,
  useHandleRouterPush,
} from "@app21/core";
// import { LoadingSpinner } from 'blocks/atoms';
import {
  Box,
  Button,
  Container,
  Card,
  CardContent,
  CardActions,
  Typography,
  Grid,
  useMediaQuery,
  Collapse,
} from "@mui/material";
import {
  StyledHorizontalTab,
  StyledVerticalTab,
  StyledVerticalTabs,
  StyledHorizontalTabs,
  FlowBox,
} from "blocks/atoms/uistyles";
import { useForm, FormProvider } from "react-hook-form";
import { LoadingSpinner } from "blocks/atoms";
import ObjectID from "bson-objectid";
import {
  getSuiteTypeFromMap,
  getSuitePrices,
  getSuiteCreditsInfo,
} from "utils";
import { useRouter } from "next/router";
import {
  AddOrganizationStepForm,
  AddSuiteStepForm,
  SelectSubscriptionTierForm,
  SelectSubscriptionTemplateForm,
  CompleteSetupStepForm,
} from "blocks/modules/Subscriptions";
import { format, isAfter } from "date-fns";

export default function AddNewOrgAndSuite() {
  const { userInfo, selectedOrganizationId } = useAppContext();
  const [stripeSubscriptionLoading, setStripeSubscriptionLoading] =
    React.useState(false);
  const router = useRouter();

  //Below is to accommodate the special case during launch of having onl Datasuite being offered.
  const isOnlyDataSuiteSpecialSituation = true;
  const [activeStep, setActiveStep] = React.useState(
    Number(router?.query?.activeStep) || 0
  );
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [completed, setCompleted] = React.useState({});
  const { data: selectedOrganization, status: selectedOrganizationStatus } =
    useOrganization(selectedOrganizationId);
  const isNewOrg =
    !Boolean(selectedOrganizationId) || selectedOrganizationId === "addnew";
  const { loadRoute } = useHandleRouterPush();
  const {
    data: app21ProductsAndCouponsData = {},
    status: app21ProductsAndCouponsDataStatus,
  } = useApp21ProductsAndCoupons();
  const {
    handleOrganizationActions,
    addOrganizationStatus,
    addSuiteToOrganizationStatus,
    addSuiteToOrganizationData,
  } = useHandleOrganizationActions();

  const { handleManageImage } = useHandleManageImage();
  const [couponSuccess, setCouponSuccess] = React.useState(false);
  const [couponHelperMessage, setCouponHelperMessage] = React.useState("");
  const [chosenPromoCode, setChosenPromoCode] = React.useState(null);

  const defaultValues = {
    organizationName: (isNewOrg ? "" : selectedOrganization?.fullName) ?? "",
    organizationImage: (isNewOrg ? "" : selectedOrganization?.image) ?? "",
    organizationId: (isNewOrg ? null : selectedOrganizationId) ?? null,
    suiteName: "",
    suiteImage: null,
    subscriptionChoice: "BASIC",
    subscriptionTemplate: "DATA-SUITE",
    couponCode: "",
    subscriptionPrice: 0,
  };
  const methods = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
  });
  const {
    handleSubmit,
    reset,
    formState: { errors },
    trigger,
    watch,
    getValues,
  } = methods;

  const priceInfo = getSuitePrices(
    app21ProductsAndCouponsData.products,
    watch("subscriptionChoice")
  );
  const creditsInfo = getSuiteCreditsInfo(app21ProductsAndCouponsData.products);
  const promoCouponCodes = React.useMemo(
    () =>
      app21ProductsAndCouponsData ? app21ProductsAndCouponsData.coupons : null,
    [app21ProductsAndCouponsData]
  );

  const handleCheckCouponCode = () => {
    let couponCode = getValues("couponCode");
    let promoCodes;
    if (promoCouponCodes) {
      promoCodes = promoCouponCodes;
    } else {
      promoCodes = promoCouponCodes;
    }
    if (couponCode && couponCode?.length > 0) {
      let foundPromoCode = promoCodes
        ? promoCodes[(couponCode ?? "").toUpperCase()]
        : {};

      let today = new Date();
      let splitDateArray = foundPromoCode?.validity?.split("-") ?? [];
      let reconstructedDateObject = splitDateArray.length
        ? new Date(splitDateArray[2], splitDateArray[1] - 1, splitDateArray[0])
        : new Date();

      let codeStillValid = isAfter(reconstructedDateObject, today);
      if (foundPromoCode && codeStillValid) {
        setCouponSuccess(true);
        setCouponHelperMessage(
          `Promo Code of ${100 * foundPromoCode.discount} % discount Applied`
        );
        setChosenPromoCode(foundPromoCode);
      } else {
        setCouponSuccess(false);
        setCouponHelperMessage(
          !foundPromoCode
            ? "Promo Code not found"
            : codeStillValid
            ? "Promo Code not Valid"
            : "Promo Code expired"
        );
      }
    }
  };

  React.useEffect(() => {
    reset(defaultValues);
  }, [selectedOrganizationId]); //Dont put reset as a dependency here. It will cause an infinite loop.

  const origin = React.useMemo(() => {
    if (typeof window !== "undefined") {
      return window.location.origin;
    }
    return null;
  }, []);

  React.useEffect(() => {
    if (
      addSuiteToOrganizationStatus &&
      activeStep > 1 &&
      addSuiteToOrganizationStatus.isSuccess
    ) {
      if (addSuiteToOrganizationData) {
        if (addSuiteToOrganizationData.paymentURL) {
          // Redirect user to payment screen
          window.location.href = addSuiteToOrganizationData.paymentURL;
        }
      }
    }
  }, [activeStep, addSuiteToOrganizationData, addSuiteToOrganizationStatus]);

  const subscriptionSteps = [
    { label: "Set up your organization" },
    { label: "Set up your suite" },
    { label: "Select subscription" },
    { label: "Complete Setup" },
  ];
  const totalSteps = () => {
    return subscriptionSteps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };
  const isSubscriptionStep = () => {
    return activeStep === (isOnlyDataSuiteSpecialSituation ? 2 : 3);
  };
  // const allStepsCompleted = () => {
  //   return completedSteps() === totalSteps();
  // };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleStep = step => () => {
  //   setActiveStep(step);
  // };

  // const handleComplete = () => {
  //   const newCompleted = completed;
  //   newCompleted[activeStep] = true;
  //   setCompleted(newCompleted);
  //   handleNext();
  // };

  const checkStepValidity = (step) => {
    if (step === 0) return !Boolean(errors?.organizationName);
    else if (step === 1) return !Boolean(errors?.suiteName);
    else if (step === 2) return !stripeSubscriptionLoading;
    else if (step === 3) return !stripeSubscriptionLoading;
    else return true;
  };

  const createNewOrganization = async (orgId, newData) => {
    // upload organizationImage
    const orgImageResult = await handleManageImage(
      {
        files: [newData.organizationImage],
        imageType: "ORG-IMAGE",
        actionType: "ADDNEW",
        orgId: orgId,
      },
      null,
      null
    );
    // Create an organization
    await handleOrganizationActions(
      {
        action: "ADD-ORGANIZATION",
        inputData: {
          data: {
            _id: orgId,
            image: orgImageResult?.data?.key || null,
            fullName: newData.organizationName,
            createdBy: userInfo._id,
          },
        },
      },
      null,
      null
    );
    // Make the creator an administrator of this organization
    await handleOrganizationActions(
      {
        action: "MAKE-ORG-CREATOR-AS-ORGADMIN",
        organizationId: orgId,
      },
      null,
      null
    );
  };

  const onSubmit = async (newData) => {
    setStripeSubscriptionLoading(true);
    const suiteType = getSuiteTypeFromMap(
      newData.subscriptionChoice,
      newData.subscriptionTemplate
    );
    const targetSuiteProductCode =
      newData.subscriptionTemplate + "-" + newData.subscriptionChoice;

    const targetSuiteProduct =
      app21ProductsAndCouponsData?.products[targetSuiteProductCode];

    const orgId = newData.organizationId
      ? newData.organizationId
      : ObjectID().toString();
    if (!newData.organizationId) {
      await createNewOrganization(orgId, newData);
    }

    // Create a suite
    const suiteId = ObjectID().toString();
    const suiteImageResult = await handleManageImage(
      {
        files: [newData.suiteImage],
        imageType: "SUITE-IMAGE",
        actionType: "ADDNEW",
        suiteId: suiteId,
      },
      null,
      null
    );

    await handleOrganizationActions(
      {
        action: "ADD-SUITE",
        suiteData: {
          organizationId: orgId,
          data: {
            _id: suiteId,
            image: suiteImageResult?.data?.key || null,
            fullName: newData.suiteName,
            createdBy: userInfo._id,
            suiteType: suiteType,
            notifyUsers: false,
            subscriptionData: {
              priceId: targetSuiteProduct.pricing.GBP.monthly.priceId,
              productId: targetSuiteProduct.productId,
              userId: userInfo._id,
              couponCode:
                newData && newData.couponCode
                  ? newData.couponCode.toUpperCase()
                  : null, // could be a trial code or a discount code - API will handle it accordingly.
              successUrl: `${origin}/user/manage?activeStep=4&choice=addsuite&isSetupSuccessful=true&orgId=${orgId}&suiteId=${suiteId}`,
              // if the user fails to complete payment, we are just taking him back to /user dashboard.
              // the suite for which payment was not completed will have a pending_subscription as
              // the lifecyeclStatus.status. We will use this to show a "Pay Again" button.
              cancelUrl: `${origin}/user`,
            },
          },
        },
        adminUsers: [{ userId: userInfo._id, role: "MANAGER" }],
      },
      null,
      null
    );
    setStripeSubscriptionLoading(false);
    // if (addSuiteToOrganizationStatus.isSuccess) {
    //   if (addSuiteToOrganizationData) {
    //     if (addSuiteToOrganizationData.data.paymentURL) {
    //       setActiveStep(prevActiveStep => prevActiveStep + 1);
    //       // Redirect user to payment screen
    //       loadRoute('GOTO-LINK', { hrefLink: addSuiteToOrganizationData.data.paymentURL });
    //     }
    //   }
    // }
  };
  const handleNext = async (event) => {
    event.stopPropagation();
    if (isSubscriptionStep()) {
      await handleSubmit(onSubmit)();
    } else {
      if (activeStep === 0) {
        await trigger("organizationName", { shouldFocus: true });
        if (checkStepValidity(activeStep))
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else if (activeStep === 1) {
        await trigger("suiteName", { shouldFocus: true });
        if (checkStepValidity(activeStep))
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else if (activeStep === 2) {
        if (checkStepValidity(activeStep))
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else if (activeStep === 3) {
        await handleSubmit(onSubmit)();
      } else {
        await loadRoute("GOTO-MAINDASHBOARD", {});
        setActiveStep(0);
      }
    }
  };
  if (
    selectedOrganizationStatus === "loading" ||
    app21ProductsAndCouponsDataStatus === "loading"
  ) {
    return <LoadingSpinner />;
  }

  const StyledTabs = isMobile ? StyledHorizontalTabs : StyledVerticalTabs;
  const StyledTab = isMobile ? StyledHorizontalTab : StyledVerticalTab;
  const couponCodeProps = {
    couponSuccess,
    setCouponSuccess,
    couponHelperMessage,
    setCouponHelperMessage,
    chosenPromoCode,
    setChosenPromoCode,
    promoCouponCodes,
    priceInfo,
    handleCheckCouponCode,
    app21ProductsAndCouponsData,
    creditsInfo,
  };
  return (
    <FlowBox>
      <Typography variant="h4" sx={{ my: 2 }}>
        Add a new suite
      </Typography>

      <FormProvider {...methods}>
        <form>
          <Card
            sx={{
              p: 3,
              minHeight: "75vh",
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              height: "100%",
              maxWidth: 1400,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                height: "100%",
                alignItems: "stretch",
              }}
            >
              <StyledTabs
                value={activeStep}
                aria-label="Select your Suite"
                textColor="secondary"
                orientation={isMobile ? "horizontal" : "vertical"}
                indicatorStyle="primary"
                variant="scrollable"
                allowScrollButtonsMobile
                sx={{
                  width: "100%",
                  display: "flex",
                  maxWidth: !isMobile ? "220px" : null,
                }}
                selectionFollowsFocus
              >
                <StyledTab
                  disabled={activeStep !== 0}
                  wrapped
                  label={
                    <Typography variant="h5">Organization Details</Typography>
                  }
                  value={0}
                  sx={{ maxWidth: isMobile ? 100 : null }}
                />
                <StyledTab
                  wrapped
                  disabled={activeStep !== 1}
                  label={<Typography variant="h5">Suite Setup</Typography>}
                  value={1}
                  sx={{ maxWidth: isMobile ? 100 : null }}
                />
                {activeStep < 3 ? (
                  <StyledTab
                    wrapped
                    disabled={activeStep !== 2}
                    label={
                      <Typography variant="h5">Subscription Package</Typography>
                    }
                    value={2}
                    sx={{ maxWidth: isMobile ? 100 : null }}
                  />
                ) : (
                  <StyledTab
                    wrapped
                    disabled={activeStep !== 3}
                    label={
                      <Typography variant="h5">Subscription Package</Typography>
                    }
                    value={3}
                    sx={{ maxWidth: isMobile ? 100 : null }}
                  />
                )}
                <StyledTab
                  wrapped
                  disabled={activeStep !== 4}
                  label={<Typography variant="h5">Complete</Typography>}
                  value={4}
                  sx={{ maxWidth: isMobile ? 100 : null }}
                />
              </StyledTabs>
              <CardContent sx={{ position: "relative" }}>
                <Collapse in={activeStep === 0} timeout={500}>
                  <AddOrganizationStepForm
                    isMobile={isMobile}
                    formOrganizationName="organizationName"
                    formOrganizationImage="organizationImage"
                  />
                </Collapse>
                <Collapse in={activeStep === 1} timeout={500}>
                  <AddSuiteStepForm
                    isMobile={isMobile}
                    formSuiteName="suiteName"
                    formSuiteImage="suiteImage"
                  />
                </Collapse>
                <Collapse in={activeStep === 2} timeout={500}>
                  <SelectSubscriptionTierForm
                    isMobile={isMobile}
                    formSubscriptionChoice={"subscriptionChoice"}
                    formSubscriptionTemplate={"subscriptionTemplate"}
                    formCouponCode={"couponCode"}
                    {...couponCodeProps}
                  />
                </Collapse>
                <Collapse in={activeStep === 3} timeout={500}>
                  <SelectSubscriptionTemplateForm
                    isMobile={isMobile}
                    formSubscriptionChoice={"subscriptionChoice"}
                    formSubscriptionTemplate={"subscriptionTemplate"}
                    formCouponCode={"couponCode"}
                    {...couponCodeProps}
                  />
                </Collapse>
                <Collapse in={activeStep === 4} timeout={500}>
                  <CompleteSetupStepForm isMobile={isMobile} />
                </Collapse>
              </CardContent>

              <CardActions
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  p: 2,
                  alignItems: "flex-end",
                }}
              >
                {activeStep < 5 && (
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                )}
                <Box sx={{ flex: "1 1 auto" }} />
                <Button
                  onClick={handleNext}
                  type={"button"}
                  variant="contained"
                  color={isSubscriptionStep() ? "secondary" : "primary"}
                  disabled={!checkStepValidity(activeStep)}
                  sx={{ mr: 1, minWidth: 120 }}
                >
                  {isSubscriptionStep()
                    ? "Subscribe"
                    : isLastStep()
                    ? "Finish"
                    : "Next"}
                </Button>
                {/* {activeStep !== subscriptionSteps.length &&
                (completed[activeStep] ? (
                  <Typography variant="caption" sx={{ display: 'inline-block' }}>
                    Step {activeStep + 1} already completed
                  </Typography>
                ) : (
                  <Button onClick={handleComplete}>
                    {completedSteps() === totalSteps() - 1 ? 'Finish' : 'Complete Step'}
                  </Button>
                ))}

              <Button variant="contained" onClick={handleSubmit(onSubmit)} sx={{ m: 1 }}>
                Create
              </Button> */}
              </CardActions>
            </Box>
            <CardContent>
              {/* <Stepper activeStep={activeStep} alternativeLabel>
                {subscriptionSteps.map((step, index) => (
                  <Step key={step.label} completed={completed[index]}>
                    <StepLabel>{step.label}</StepLabel>
                  </Step>
                ))}
              </Stepper> */}
            </CardContent>
          </Card>
        </form>
      </FormProvider>
    </FlowBox>
  );
}
