// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
// ----------------------------------------------------------------------

export const mockImgCover = (index) =>
  `/static/mock-images/covers/cover_${index}.jpg`;
export const mockImgFeed = (index) =>
  `/static/mock-images/feeds/feed-${index}.jpg`;
export const mockImgProduct = (index) =>
  `/static/mock-images/products/product_${index}.jpg`;
export const mockImgAvatar = (index) =>
  `/static/mock-images/avatars/avatar_${index}.jpg`;
export const mockImgLandingPage = (index) =>
  `/static/mock-images/landingPage/Landing_${index}.jpg`;
