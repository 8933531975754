// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Chip, Avatar, Button, Grid, Box, Typography } from "@mui/material";
import { AddIcon } from "blocks/atoms/trrysticons";
import { getS3ImageWrapper, checkIfNull } from "@app21/core";

import { useFileUpload } from "use-file-upload";
import ObjectID from "bson-objectid";
import clip from "text-clipper";
import path from "path";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const FileUploaderFormComponent = ({
  value,
  onChange,
  showFileAddIcon = true,
}) => {
  const [file, selectFile] = useFileUpload();
  let unchangedFiles =
    value?.filter(function (obj) {
      return !(
        Object.prototype.hasOwnProperty.call(obj, "added") ||
        Object.prototype.hasOwnProperty.call(obj, "deleted")
      );
    }) ?? [];
  let addedFiles = value?.find((obj) => !checkIfNull(obj.added))?.added || [];
  let deletedFiles =
    value?.find((obj) => !checkIfNull(obj.deleted))?.deleted || [];
  let filteredUnchangedFiles = unchangedFiles?.filter(
    (x) => !deletedFiles.includes(x.s3Key)
  );
  let newAddedFiles = addedFiles;

  return (
    <>
      {showFileAddIcon && (
        <Grid item md={3} sx={{ mt: 5 }}>
          <Button
            variant="outlined"
            sx={{ borderRadius: 0, width: "max-content" }}
            startIcon={<AddIcon />}
            margin="dense"
            onClick={() => {
              selectFile({}, ({ source, name, size, file }) => {
                addedFiles.push({
                  source,
                  name,
                  size,
                  file,
                  tempS3Key: ObjectID().toHexString(),
                });
                onChange([
                  ...unchangedFiles,
                  { added: addedFiles },
                  { deleted: deletedFiles },
                ]);
              });
            }}
          >
            ADD ATTACHMENTS
          </Button>
        </Grid>
      )}
      <Grid item md={12} sx={{ mt: 5 }}>
        {!checkIfNull(value) &&
          filteredUnchangedFiles.map((fileInfo, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  py: 2,
                }}
              >
                <Avatar
                  src={getS3ImageWrapper(fileInfo?.image, "file")}
                  variant="rounded"
                  alt={fileInfo?.fileName}
                  sx={{ width: 18, height: 18 }}
                />
                <Typography
                  variant="h6"
                  sx={{ px: 2, justifyContent: "space-around" }}
                >
                  {clip(path.basename(fileInfo.s3Key))}
                </Typography>
              </Box>
              <Button
                variant="outlined"
                color="error"
                startIcon={<DeleteForeverIcon />}
                size="small"
                sx={{ height: "fit-content" }}
                onClick={() => {
                  deletedFiles.push(fileInfo?.s3Key),
                    onChange([
                      ...unchangedFiles,
                      { added: addedFiles },
                      { deleted: deletedFiles },
                    ]);
                }}
              >
                REMOVE
              </Button>
            </Box>
          ))}
        {newAddedFiles.map((addedFile, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                py: 2,
              }}
            >
              <Avatar
                src={getS3ImageWrapper("", "file")}
                variant="rounded"
                alt={addedFile?.fileName}
                sx={{ width: 18, height: 18 }}
              />
              <Typography
                variant="h6"
                sx={{ px: 2, justifyContent: "space-around" }}
              >
                {clip(addedFile?.name, 25)}
              </Typography>
            </Box>
            <Button
              variant="outlined"
              color="error"
              startIcon={<DeleteForeverIcon />}
              size="small"
              sx={{ height: "fit-content" }}
              onClick={() => {
                addedFiles = newAddedFiles.filter(
                  (x) => x.tempS3Key !== addedFile?.tempS3Key
                );
                onChange([
                  ...unchangedFiles,
                  { added: addedFiles },
                  { deleted: deletedFiles },
                ]);
              }}
            >
              REMOVE
            </Button>
          </Box>
        ))}
      </Grid>
    </>
  );
};

export default FileUploaderFormComponent;
