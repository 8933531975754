import React, { useCallback, useEffect, useRef, useState } from "react";
import { MenuPopover } from "blocks/atoms";
import { MoreVert } from "@mui/icons-material";
import { IconButton, MenuItem } from "@mui/material";
import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from "material-ui-popup-state/hooks";
import { usePubNub } from "pubnub-react";
import { useAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import {
  app21APIInstance,
  useUserInfo,
  useAppContext,
  useHandleRouterPush,
} from "@app21/core";
import {
  CurrentChannelAtom,
  CurrentChannelMessagesAtom,
  RetryFunctionAtom,
} from "blocks/modules/TrrystChat/state-atoms";
import { useChatContext } from "providers/TrrystChatProvider";
import { useConfirm } from "material-ui-confirm";

export default function ChatChannelManageOptions({}) {
  const confirm = useConfirm();
  const popupState = usePopupState({
    variant: "popover",
    popupId: "channel-manage-options",
  });

  const fetchMessagesCount = 20;

  const [retryObj] = useAtom(RetryFunctionAtom);
  const retry = retryObj.function;
  const { loadRoute } = useHandleRouterPush();

  const { data: userInfo } = useUserInfo();
  const { selectedSuiteId } = useAppContext();
  const pubnubChatClient = usePubNub();
  const { allChannelsData, handleChangeViewContext } = useChatContext();
  const [currentChannel, setCurrentChannel] = useAtom(CurrentChannelAtom);
  const [currentChannelMessages] = useAtom(CurrentChannelMessagesAtom);

  let lastMessageTimetoken =
    Number(
      currentChannelMessages[currentChannelMessages.length - 1]?.timetoken
    ) ?? Date.now() * 10000;

  lastMessageTimetoken = lastMessageTimetoken + 10;

  const currentChannelObj = allChannelsData?.filter(
    (ch) => ch.id === currentChannel
  );

  const channelCreatedAt = new Date(currentChannelObj[0]?.createdAt);

  const toDeleteUntilTimetoken = channelCreatedAt?.getTime() * 10000;

  const [fetchingMessages, setFetchingMessages] = React.useState(false);

  const fetchHistory = async () => {
    if (!fetchMessagesCount) return;
    try {
      setFetchingMessages(true);
      const history = await retry(() =>
        pubnubChatClient.fetchMessages({
          channels: [currentChannel],
          count: fetchMessagesCount,
          includeMessageActions: true,
        })
      );
      handleHistoryFetch({ response: history });
    } catch (e) {
      console.log("Messages Fetch Error : ", e);
    } finally {
      setFetchingMessages(false);
    }
  };

  const handleHistoryFetch = useAtomCallback(
    useCallback((get, set, arg) => {
      const { response } = arg;
      const currentChannel = get(CurrentChannelAtom);
      const messages = [];
      const newMessages = response?.channels[currentChannel] || [];

      if (messages === [] && response?.channels[currentChannel]) {
        messages.push(response?.channels[currentChannel]);
      }

      const allMessages = [...messages, ...newMessages].sort(
        (a, b) => a.timetoken - b.timetoken
      );
      set(CurrentChannelMessagesAtom, allMessages);
    }, [])
  );

  const handleDeleteChannel = async () => {
    confirm({
      description:
        "Warning! All Chats in this thread will be deleted and cannot be recovered. Please confirm to Proceed. ?",
    })
      .then(async () => {
        lastMessageTimetoken &&
          toDeleteUntilTimetoken &&
          (await pubnubChatClient.deleteMessages({
            channel: currentChannel,
            start: `${lastMessageTimetoken}`,
            end: `${toDeleteUntilTimetoken}`,
          }));
        await app21APIInstance.post("/channels/deleteFilesFromChannel", {
          channelId: currentChannel,
          fromToken: toDeleteUntilTimetoken,
          toToken: lastMessageTimetoken,
          calledBy: userInfo?._id,
          additionalData: { suiteId: selectedSuiteId },
        });

        await fetchHistory();

        if (currentChannel !== `${selectedSuiteId}-CHAT`) {
          await app21APIInstance.post("/channels/delete", {
            channelId: currentChannel,
            calledBy: userInfo?._id,
            additionalData: { suiteId: selectedSuiteId },
          });
        }
        handleChangeViewContext("suite");
        loadRoute("GROUP-CHAT", {
          chatId: `${selectedSuiteId}-CHAT`,
          chatType: "suite",
        });
      })
      .catch(() => {
        console.log("Not proceeding with Delete.");
      })
      .finally(() => popupState.close());
  };

  return (
    <>
      <IconButton
        {...bindTrigger(popupState)}
        aria-label="more"
        sx={{
          color: "text",
          fontSize: "1.5rem",
          alignSelf: "flex-start",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <MoreVert />
      </IconButton>
      <MenuPopover {...bindPopover(popupState)}>
        <MenuItem onClick={handleDeleteChannel}>Delete Chat</MenuItem>
      </MenuPopover>
    </>
  );
}
