// // !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  SvgIcon,
  IconButton,
  Tooltip,
  Badge,
  Box,
  Typography,
  Divider,
} from "@mui/material";
import { Settings } from "@mui/icons-material";
import { MenuPopover } from "blocks/atoms";
import { MessageCircle } from "react-feather";
import { ChatIcon } from "blocks/atoms/trrysticons";
import { useChatContext } from "providers/TrrystChatProvider";
import { useAppContext } from "@app21/core";
import { useCheckActiveLink } from "hooks";
import { UnreadChatMessagesWall } from "blocks/views/ChatNotifiers";
import { usePubNub } from "pubnub-react";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";
import _ from "lodash";
import { chatModuleTestIds } from "blocks/atoms/constants";

export default function UnreadChatMessageSummaryPopup({
  iconSize = "medium",
  viewContext = "main",
}) {
  const unreadChatsPopupState = usePopupState({
    variant: "popover",
    popupId: "unreadChatsPopupList",
  });

  const isChatLinkActive = useCheckActiveLink("GROUP-CHAT");
  const { totalUnreadMessageCount } = useChatContext();

  // const { accessFlag: canAccessChat } = checkAccessRules({
  //   entity: "MENU",
  //   action: "SHOW-DISCUSS-MENU",
  //   featureName: "CHAT",
  //   isInvitee: true,
  // });

  return (
    <>
      <Tooltip title="Your recent unread Chats" arrow>
        <IconButton
          data-testid={chatModuleTestIds.unreadChatButtonTestId}
          size={iconSize}
          {...bindTrigger(unreadChatsPopupState)}
        >
          <Badge
            badgeContent={totalUnreadMessageCount}
            invisible={!totalUnreadMessageCount}
            color="secondary"
          >
            <SvgIcon fontSize="inherit">
              <ChatIcon
                fontSize="inherit"
                sx={{
                  color: (theme) =>
                    isChatLinkActive
                      ? "secondary"
                      : theme.palette.text.secondary,
                }}
              />
            </SvgIcon>
          </Badge>
        </IconButton>
      </Tooltip>
      <MenuPopover
        {...bindMenu(unreadChatsPopupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        arrow="top-center"
        sx={{ width: 320, maxHeight: 800 }}
      >
        <Box
          sx={{
            my: 1,
            px: 2,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="subtitle2" noWrap>
            Unread Messages
          </Typography>
          {/* <Settings sx={{ color: "text.secondary" }} /> */}
        </Box>
        <Divider sx={{}} />
        <UnreadChatMessagesWall />
      </MenuPopover>
    </>
  );
}
