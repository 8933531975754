import React from "react";
import { IconButton, Popover, Box } from "@mui/material";
import { DateRange } from "@mui/icons-material";
import { DateRangeSelector } from "blocks/atoms";
import isAfterDay from "react-dates/lib/utils/isAfterDay";
import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from "material-ui-popup-state/hooks";
import moment from "moment";

const ChartDatePicker = ({ handleDateSelect, value = {} }) => {
  const chartPickerPopupState = usePopupState({
    variant: "popover",
    popupId: "chartDatePickerId",
  });

  return (
    <React.Fragment>
      <IconButton size="small" {...bindTrigger(chartPickerPopupState)}>
        <DateRange fontSize="inherit" />
      </IconButton>
      <Popover
        {...bindPopover(chartPickerPopupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{ height: "100%" }}
      >
        <Box
          sx={{
            height: 450,
            width: 650,
            display: "flex",
            alignItems: "flex-start",
            overflow: "auto",
          }}
        >
          <DateRangeSelector
            autoComplete="on"
            startDate={value.startDate}
            endDate={value.endDate}
            onDatesChange={({ startDate, endDate }) => {
              handleDateSelect({ startDate, endDate });
            }}
            onClose={() => chartPickerPopupState?.close()}
            isOutsideRange={(day) => isAfterDay(day, moment())}
            minDate={moment().subtract(3, "months").startOf("month")}
            maxDate={moment()}
            numberOfMonths={2}
          />
        </Box>
      </Popover>
    </React.Fragment>
  );
};
export default ChartDatePicker;
