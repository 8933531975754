import { SvgIcon } from "@mui/material";
import React from "react";
export default function ChangeScreenWebInActiveIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 56 56"
      stroke="#D8D8DD"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <rect
          width="56"
          height="56"
          rx="8.6"
          stroke="none"
          fill="currentColor"
        />
        <path
          d="M14.3945 38.1064V34.3069C14.3945 31.1405 15.6611 29.874 18.8274 29.874H22.6269C25.7933 29.874 27.0598 31.1405 27.0598 34.3069V38.1064C27.0598 41.2728 25.7933 42.5393 22.6269 42.5393H18.8274"
          strokeWidth="2.14103"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M42.5392 32.6885C42.5392 38.1345 38.1345 42.5392 32.6885 42.5392L34.1661 40.0765"
          strokeWidth="2.14103"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.3945 24.2453C14.3945 18.7992 18.7992 14.3945 24.2453 14.3945L22.7677 16.8572"
          strokeWidth="2.14103"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36.2067 27.0598C39.7041 27.0598 42.5393 24.2246 42.5393 20.7272C42.5393 17.2297 39.7041 14.3945 36.2067 14.3945C32.7092 14.3945 29.874 17.2297 29.874 20.7272C29.874 24.2246 32.7092 27.0598 36.2067 27.0598Z"
          strokeWidth="2.14103"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
