import React from "react";
import { Box, Typography, IconButton, ClickAwayListener } from "@mui/material";
import {
  SelectFilesSidebarContext,
  ShowSidebarFilesList,
} from "../foundations";
import { FlowBox } from "blocks/atoms/uistyles";

export default function FilesSidebar({ isMobile = false }) {
  return (
    <FlowBox
      sx={{
        width: "100%",
        maxWidth: isMobile ? "100%" : "300px",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <SelectFilesSidebarContext />
      </Box>
      <ShowSidebarFilesList />
    </FlowBox>
  );
}
