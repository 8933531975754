// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import { SvgIcon } from "@mui/material";

export default function CompactScreenIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 60 75"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <path d="M54,5H6C5.4477539,5,5,5.4477539,5,6v48c0,0.5522461,0.4477539,1,1,1h48c0.5522461,0,1-0.4477539,1-1V6  C55,5.4477539,54.5522461,5,54,5z M41.0605469,19H53v10H41.0605469V19z M41.0605469,31H53v10H41.0605469V31z M53,17H41.0605469V7H53  V17z M7,7h32.0605469v46H7V7z M41.0605469,53V43H53v10H41.0605469z" />
    </SvgIcon>
  );
}
