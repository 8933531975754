import React from 'react'
import { SvgIcon } from '@mui/material';

export default function NoteSelfIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M62.5835 12.5414C59.0835 9.87471 54.7501 8.33301 50.0001 8.33301C38.5001 8.33301 29.1667 17.6663 29.1667 29.1663C29.1667 40.6663 38.5001 49.9997 50.0001 49.9997C61.5001 49.9997 70.8334 40.6663 70.8334 29.1663"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M80.0415 65.5827L65.2913 80.3328C64.708 80.9162 64.1664 81.9994 64.0414 82.791L63.2497 88.4159C62.9581 90.4576 64.3748 91.8743 66.4164 91.5826L72.0413 90.791C72.833 90.666 73.9581 90.1243 74.4998 89.541L89.2497 74.7911C91.7913 72.2494 92.9997 69.291 89.2497 65.541C85.5413 61.8326 82.5832 63.041 80.0415 65.5827Z"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M77.9164 67.7083C79.1664 72.2083 82.6663 75.7081 87.1663 76.9581"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.208 91.6667C14.208 75.5417 30.2498 62.5 49.9998 62.5C54.3331 62.5 58.4996 63.125 62.3746 64.2916"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
