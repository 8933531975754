// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Menu, MenuItem, Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";
import { useRouter } from "next/router";
import { useHandleRouterPush } from "@app21/core";

export default function AddUpgradeMenuButton({ viewContext = "single" }) {
  const router = useRouter();
  const upgradeSelectPopupState = usePopupState({
    variant: "popover",
    popupId: "upgradeMenu",
  });
  const { loadRoute } = useHandleRouterPush();

  const allMenuOptionLabels = {
    ADDSUITE: "Add Suite to an existing organization",
    ADDORGANIZATION: "Request Add Organization",
  };

  const handleMenuOptionClick = (option) => {
    if (option === "ADDORGANIZATION") {
      loadRoute("MANAGE-ORGANIZATION", { orgId: "addnew" });
    } else if (option === "ADDSUITE") {
      loadRoute("MANAGE-ORGANIZATION", { orgId: "addnew" });
    }
  };

  const getUpgradeMenuOptions = () => {
    const allOptions = ["ADDSUITE", "ADDORGANIZATION"];

    return allOptions.map((option, index) => (
      <MenuItem key={index} onClick={() => handleMenuOptionClick(option)}>
        {allMenuOptionLabels[option]}
      </MenuItem>
    ));
  };

  return (
    <>
      {/* {viewContext === 'multiple' ? (
        <Button variant="contained" color="secondary" startIcon={<Add />} {...bindTrigger(upgradeSelectPopupState)}>
          Add
        </Button>
      ) : ( 
      <Button
        variant="contained"
        color="secondary"
        startIcon={<Add />}
        onClick={() =>
          router.push('/user/subscribe/requestorgcreate', '/user/subscribe/requestorgcreate', { shallow: true })
        }
      >
        Add
      </Button>
       )} */}
      <Button
        variant="contained"
        color="secondary"
        startIcon={<Add />}
        onClick={() => loadRoute("ADD-SUITE", { choice: "addsuite" })}
      >
        Add
      </Button>
      {/* <Menu
        {...bindMenu(upgradeSelectPopupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {getUpgradeMenuOptions()}
      </Menu> */}
    </>
  );
}
