import queryString from "query-string";

export const handleViewerClose = async ({ router }) => {
  const { viewFile, ...otherParams } = JSON.parse(
    localStorage.getItem("lastIds")
  );
  const { asPath } = JSON.parse(localStorage.getItem("asPath"));
  localStorage.setItem("lastIds", JSON.stringify(otherParams));
  const { url } = queryString.parseUrl(asPath);

  if (
    url.includes("/user/organization/suite/docket/files/view") ||
    url.includes("/user/organization/suite/docket/files/[action]")
  ) {
    const updatedUrl = "/user/organization/suite/docket/files/view";
    const backPathname = queryString.stringifyUrl({ url: updatedUrl });
    router.push(backPathname, backPathname, { shallow: true });
  }
  if (
    url.includes("/user/organization/suite/dataroom/view") ||
    url.includes("/user/organization/suite/dataroom/[action]")
  ) {
    const updatedUrl = "/user/organization/suite/dataroom/view";
    const backPathname = queryString.stringifyUrl({ url: updatedUrl });
    router.push(backPathname, backPathname, { shallow: true });
  } else {
    const backPathname = queryString.stringifyUrl({ url: url });
    router.push(backPathname, backPathname, { shallow: true });
  }
};
