import React from "react";
import { Paper, Avatar } from "@mui/material";

const NoVideoTile = ({ refObj, name = "", avatarUrl = "" }) => {
  return (
    <Paper
      ref={refObj}
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",

        borderRadius: 6,
        border: "1px solid Lightgrey",
      }}
    >
      <Avatar
        sx={{
          width: "36%",
          height: "64%",
          minHeight: 25,
          maxHeight: 120,
          minWidth: 25,
          maxWidth: 120,
          aspectRatio: "auto 1 / 1",
        }}
        alt={name}
        src={avatarUrl}
      />
    </Paper>
  );
};

export default NoVideoTile;
