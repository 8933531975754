import React from "react";
import {
  Box,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { MenuPopover } from "blocks/atoms/uicomponents";
import { bindPopover } from "material-ui-popup-state/hooks";
import ReviewsIcon from "@mui/icons-material/Reviews";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import QuickreplyIcon from "@mui/icons-material/Quickreply";
import StarBorderIcon from "@mui/icons-material/StarBorder";

const standardMarkerOptions = [
  { label: "Review", icon: ReviewsIcon, value: "review" },
  { label: "Action", icon: ThumbUpAltIcon, value: "action" },
  { label: "Urgent", icon: QuickreplyIcon, value: "urgent" },
  { label: "Important", icon: StarBorderIcon, value: "important" },
];
export default function AddMarkerMenu({ addMarkerPopupState }) {
  const [currentOption, setCurrentOption] = React.useState(() => [
    "review",
    "urgent",
  ]);

  const handleOption = (event, newVal) => {
    setCurrentOption(newVal);
  };

  return (
    <MenuPopover
      {...bindPopover(addMarkerPopupState)}
      id="note-specify-popover"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      arrow="top-center"
      sx={{ bgcolor: "background.default", width: "max-content" }}
    >
      <ToggleButtonGroup
        size="small"
        value={currentOption}
        onChange={handleOption}
      >
        {standardMarkerOptions.map((option, index) => (
          <ToggleButton key={index} value={option.value}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {<option.icon fontSize="inherit" />}
              <Typography variant="caption">{option.label}</Typography>
            </Box>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </MenuPopover>
  );
}
