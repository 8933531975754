// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Typography } from "@mui/material";
import { useIntl } from "react-intl";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { ViewList, ViewColumn } from "@mui/icons-material";

const TasksViewToggle = (props) => {
  const { view, setView } = props;
  const handleView = (event, newChoice) => {
    setView(newChoice);
  };
  return (
    <ToggleButtonGroup
      value={view}
      exclusive
      onChange={handleView}
      aria-label="Layout Selection"
      sx={{ mt: 1 }}
    >
      <ToggleButton
        size="small"
        value="listView"
        sx={{
          px: 2,
          "& .MuiToggleButton-root": {
            backgroundColor: (theme) => theme.palette.background.default,
            color: (theme) => theme.palette.text.primary,
          },
          "&.Mui-selected": {
            backgroundColor: (theme) =>
              `${theme.palette.primary.dark} !important`,
            color: (theme) =>
              `${theme.palette.primary.contrastText} !important`,
          },
        }}
        aria-label="List View"
      >
        <ViewList fontSize="large" />
        <Typography
          variant="subtitle2"
          sx={{ ml: (theme) => theme.spacing(1), textTransform: "none" }}
        >
          List View
        </Typography>
      </ToggleButton>
      <ToggleButton
        size="small"
        value="kanbanView"
        sx={{
          px: 2,
          "& .MuiToggleButton-root": {
            backgroundColor: (theme) => theme.palette.background.default,
            color: (theme) => theme.palette.text.primary,
          },
          "&.Mui-selected": {
            backgroundColor: (theme) =>
              `${theme.palette.primary.dark} !important`,
            color: (theme) =>
              `${theme.palette.primary.contrastText} !important`,
          },
        }}
        aria-label="Kanban View"
      >
        <ViewColumn fontSize="large" />
        <Typography
          variant="subtitle2"
          sx={{ ml: (theme) => theme.spacing(1), textTransform: "none" }}
        >
          Kanban
        </Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default TasksViewToggle;
