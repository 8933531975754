// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Box, Typography } from "@mui/material";
import { useAppContext, useHandleRouterPush } from "@app21/core";

import BackTitleWrapperPaper from "blocks/atoms/BackTitleWrapperPaper";
import { SubscriptionPlanTemplate } from "blocks/modules/Subscriptions";

const SuiteUpgradeOptions = ({
  handleChoice = (e) => null,
  selectedWalkthroughOption = "BOARD-SUITE",
  viewContext = "PERSONAL",
}) => {
  const [allSuiteUpgradeChoicesArray, setAllSuiteUpgradeChoicesArray] =
    React.useState([]);

  const { userInfo, selectedOrganizationId, selectedSuiteId } = useAppContext();
  const { loadRoute } = useHandleRouterPush();
  const handleChoiceSelect = React.useCallback(
    async (e) => {
      // Create a checkout session for imediate payment & effect;

      if (e === "PERSONAL-SUITE-PRO") {
        if (!selectedSuiteId || !selectedOrganizationId) return;

        const response = await (
          await fetch(
            `${process.env.AWS_APIPATH}/stripe/subscription/session/create`,
            {
              method: "POST",
              headers: {
                "content-type": "application/json",
              },
              body: JSON.stringify({
                suiteId: selectedSuiteId,
                calledBy: userInfo._id,
                successUrl: `${window.location.origin}/user/organization/suite?orgId=${selectedOrganizationId}&suiteId=${selectedSuiteId}`,
                cancelUrl: window.location.href,
                priceId: "price_1JyhdnIypjEV9l9bdWwtDj3q",
                orgId: selectedOrganizationId,
              }),
            }
          )
        ).json();

        loadRoute("GOTO-LINK", { hrefLink: response.sessionUrl });
      } else {
        handleChoice(e);
      }
    },
    [handleChoice, selectedOrganizationId, selectedSuiteId, userInfo._id]
  );

  const getSuiteUpgradeChoices = (currentState) => {
    switch (currentState) {
      case "ALL":
        setAllSuiteUpgradeChoicesArray([
          "PERSONAL-SUITE-BASIC",
          "PERSONAL-SUITE-PRO",
          "BOARD-SUITE-BASIC",
          "BOARD-SUITE-PRO",
          "EXEC-SUITE-BASIC",
          "EXEC-SUITE-PRO",
          "DATA-SUITE-BASIC",
          "DATA-SUITE-PRO",
        ]);
        break;
      case "PERSONAL":
        setAllSuiteUpgradeChoicesArray([
          "PERSONAL-SUITE-BASIC",
          "PERSONAL-SUITE-PRO",
        ]);
        break;
      case "ENTERPRISE":
        setAllSuiteUpgradeChoicesArray([
          "BOARD-SUITE-BASIC",
          "BOARD-SUITE-PRO",
          "DATA-SUITE-BASIC",
          "DATA-SUITE-PRO",
          "EXEC-SUITE-BASIC",
          "EXEC-SUITE-PRO",
        ]);
        break;
      case "BOARD-SUITE":
        setAllSuiteUpgradeChoicesArray([
          "BOARD-SUITE-BASIC",
          "BOARD-SUITE-PRO",
        ]);
        break;
      case "EXEC-SUITE":
        setAllSuiteUpgradeChoicesArray(["EXEC-SUITE-BASIC", "EXEC-SUITE-PRO"]);
        break;
      case "DATA-SUITE":
        setAllSuiteUpgradeChoicesArray(["DATA-SUITE-BASIC", "DATA-SUITE-PRO"]);
        break;
      default:
        setAllSuiteUpgradeChoicesArray([
          "PERSONAL-SUITE-BASIC",
          "PERSONAL-SUITE-PRO",
          "BOARD-SUITE-BASIC",
          "BOARD-SUITE-PRO",
          "EXEC-SUITE-BASIC",
          "EXEC-SUITE-PRO",
          "DATA-SUITE-BASIC",
          "DATA-SUITE-PRO",
        ]);
        break;
    }
  };
  React.useEffect(() => {
    getSuiteUpgradeChoices(selectedWalkthroughOption);
  }, [selectedWalkthroughOption]);

  const viewOptions = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "stretch",
        }}
      >
        {allSuiteUpgradeChoicesArray.map((option, index) => {
          return (
            <Box sx={{ m: 1 }} key={index}>
              <SubscriptionPlanTemplate
                option={option}
                handleChoice={handleChoiceSelect}
              />
            </Box>
          );
        })}
      </Box>
    );
  };

  if (viewContext === "PERSONAL") {
    return (
      <BackTitleWrapperPaper title={"Select Upgrade Plan"}>
        <Typography variant="body1" sx={{ m: 2 }}>
          Please select the Plan you wish to upgrade to
        </Typography>
        {viewOptions()}
      </BackTitleWrapperPaper>
    );
  } else return <Box sx={{ m: 1 }}>{viewOptions()}</Box>;
};

export default SuiteUpgradeOptions;
