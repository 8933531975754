// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { Fragment } from "react";
import {
  Chip,
  Avatar,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import PropTypes from "prop-types";
import { getS3ImageWrapper, checkIfNull } from "@app21/core";
import clip from "text-clipper";
import HoverExpandChip from "blocks/atoms/uicomponents/HoverExpandChip";
import { AvatarGroupWithMore, UserAvatar } from "blocks/atoms";

const ProfilesChipsList = ({
  usersList,
  showAs = "avatar",
  dense,
  compactView,
  AvatarGroupProps = {},
  maxItems = 9,
  size = 25,
}) => {
  const minWidthFlag = useMediaQuery("(min-width:1000px)");
  const compactFlag =
    Array.isArray(usersList) && usersList.length > (minWidthFlag ? 5 : 1);

  if (usersList && Array.isArray(usersList)) {
    if (showAs !== "chip") {
      return (
        <AvatarGroupWithMore
          max={maxItems}
          {...AvatarGroupProps}
          sx={{
            "& .MuiAvatar-root": { width: size, height: size },
          }}
        >
          {usersList?.map((user, index) => (
            <UserAvatar
              key={index}
              size={size}
              userId={typeof user === "object" ? user?._id : user}
            />
          ))}
        </AvatarGroupWithMore>
      );
    } else {
      return (
        <span style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
          {usersList.map((user, index) => {
            const fullSplitName = user?.fullName?.split(" ") ?? [];
            const initials = !checkIfNull(fullSplitName)
              ? (fullSplitName?.shift() ?? "").charAt(0) +
                (fullSplitName?.pop() ?? "")?.charAt(0)
              : "";
            let compactLabel = initials.toUpperCase();
            return (
              <HoverExpandChip
                compactFlag={compactView ?? compactFlag}
                key={index}
                component="span"
                sx={{
                  "& .MuiChip-root": { mx: 0.5 },
                  "& .MuiChip-sizeSmall": { mx: 0.5 },
                  "& .MuiChip-avatarSmall": {
                    m: 0,
                    p: 0,
                    height: 30,
                    width: 30,
                  },
                  "& .MuiChip-labelSmall": {
                    fontSize: 10,
                    fontWeight: 300,
                  },
                }}
                size={dense ? "small" : "medium"}
                avatar={
                  <Avatar
                    alt={user?.fullName}
                    src={getS3ImageWrapper(
                      user?.image || user?.avatar,
                      "person"
                    )}
                  />
                }
                compactLabel={compactLabel}
                label={
                  <Typography variant="body2">
                    {clip(user?.fullName, 20, { breakWords: true })}
                  </Typography>
                }
                variant="outlined"
              />
            );
          })}
        </span>
      );
    }
  }
  return null;
};

ProfilesChipsList.propTypes = {
  usersList: PropTypes.array.isRequired,
  dense: PropTypes.bool,
  showAs: PropTypes.oneOf(["chip", "avatar", null, undefined]),
  maxItems: PropTypes.number,
  viewMode: PropTypes.oneOf(["compact", "normal"]),
  compactView: PropTypes.bool,
};

export default ProfilesChipsList;
