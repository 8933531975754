import React from "react";
import { AvatarGroup } from "@mui/material";

export default function AvatarGroupWithMore({
  children,
  max = 9,
  size = 32,
  ...rest
}) {
  const [maxUsersViewCount, setMaxUsersViewCount] = React.useState(max);
  const toggleUsersViewCount = (event) => {
    event.stopPropagation();
    event.preventDefault();
    maxUsersViewCount === max
      ? setMaxUsersViewCount(50)
      : setMaxUsersViewCount(max);
  };
  return (
    <AvatarGroup
      max={maxUsersViewCount}
      sx={{
        width: size,
        height: size,
      }}
      componentsProps={{
        additionalAvatar: {
          sx: { cursor: "pointer", height: size, width: size },
        },
      }}
      onClick={toggleUsersViewCount}
      {...rest}
    >
      {children}
    </AvatarGroup>
  );
}
