import React from 'react'
import { SvgIcon } from '@mui/material';

export default function ExecutiveIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M89.0421 58.3333L90.4587 43.4583C91.5421 33.2917 88.6254 25 70.8337 25H29.1671C11.3754 25 8.45875 33.2917 9.58375 43.4583L12.7088 76.7917C13.5838 84.9583 16.5838 91.6667 33.3338 91.6667H66.6671C83.4171 91.6667 86.4171 84.9583 87.2921 76.7917"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.3333 25.0002V21.6668C33.3333 14.2918 33.3333 8.3335 46.6666 8.3335H53.3333C66.6666 8.3335 66.6666 14.2918 66.6666 21.6668V25.0002"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M58.3334 54.1667V58.3333C58.3334 58.375 58.3334 58.375 58.3334 58.4167C58.3334 62.9583 58.2917 66.6667 50.0001 66.6667C41.7501 66.6667 41.6667 63 41.6667 58.4583V54.1667C41.6667 50 41.6667 50 45.8334 50H54.1667C58.3334 50 58.3334 50 58.3334 54.1667Z"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M90.2082 45.8335C80.5832 52.8335 69.5833 57.0002 58.3333 58.4168"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.9172 46.9585C20.2922 53.3752 30.8756 57.2502 41.6672 58.4585"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
