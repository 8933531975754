import React from "react";
import { Link as ScrollLink } from "react-scroll";
// next
import { useRouter } from "next/router";
// material
import { Box, Link, Container, Typography, IconButton } from "@mui/material";
// components
import Logo from "blocks/atoms/Logo";
import { Icon } from "@iconify/react";
import arrowCircleUpOutline from "@iconify/icons-eva/arrow-circle-up-outline";

const GenericFooter = () => {
  return (
    <Box
      sx={{
        py: 1,
        textAlign: "center",
        position: "relative",
        bgcolor: "background.default",
      }}
    >
      <Container maxWidth="lg">
        <ScrollLink to="back_to_top" spy smooth>
          <IconButton
            color="primary"
            sx={{ mx: { xs: "auto", md: "inherit" } }}
          >
            <Icon icon={arrowCircleUpOutline} />
          </IconButton>
        </ScrollLink>

        <Typography variant="caption" component="p">
          © All rights reserved
          <br /> Trryst Ltd.
          <Link
            href="mailto:support@trryst.com?subject=Website Enquiry"
            sx={{ mx: 1 }}
          >
            2021
          </Link>
        </Typography>
      </Container>
    </Box>
  );
};

export default GenericFooter;
