import { atom } from "jotai";

export const showTasksSidebarFlagAtom = atom(false);
export const viewTaskContextAtom = atom("mytasks");
export const centerpanelContextAtom = atom("questions");
export const selectedTaskActionAtom = atom("");
export const selectedUploadActionAtom = atom("");
export const selectedTasksListAtom = atom([]);
export const viewTasksFilterContextAtom = atom([]);
export const selectedBreakoutsInTaskFiltersAtom = atom([]);
export const selectedStatusInTaskFiltersAtom = atom([]);
export const selectedTasksDateRangeAtom = atom({});
