import React from 'react'
import { SvgIcon } from '@mui/material';

export default function ViewRecordingIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M62.1257 58.8321C69.6257 54.4988 69.6257 47.4155 62.1257 43.0821L56.084 39.5821L50.0424 36.0821C42.5424 31.7488 36.4174 35.2905 36.4174 43.9571V50.9155V57.8738C36.4174 64.7905 40.334 68.4571 45.7507 67.4155"
          stroke="currentColor"
          strokeWidth="6.29163"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.6666 25.0016C11.4583 31.96 8.33325 40.6266 8.33325 50.0016C8.33325 73.0016 26.9999 91.6683 49.9999 91.6683C72.9999 91.6683 91.6666 73.0016 91.6666 50.0016C91.6666 27.0016 72.9999 8.33496 49.9999 8.33496C44.0416 8.33496 38.3333 9.58496 33.2083 11.8766"
          stroke="currentColor"
          strokeWidth="6.29163"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
