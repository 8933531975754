import React from "react";
import { MenuItem, Button, Box } from "@mui/material";
import { MenuPopover } from "blocks/atoms/uicomponents";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from "material-ui-popup-state/hooks";

export default function SortRecordingsPopover({
  handleSortOrderChange,
  sortOrder,
}) {
  const sortRecordingsPopupState = usePopupState({
    variant: "popover",
    popupId: "sort-recordings-popover",
  });
  return (
    <Box>
      <Button
        size="small"
        sx={{
          borderRadius: 0,
          height: "100%",
          minWidth: 30,
          width: 30,
          maxWidth: 30,
        }}
        {...bindTrigger(sortRecordingsPopupState)}
      >
        <ImportExportIcon fontSize="small" />
      </Button>
      <MenuPopover
        {...bindPopover(sortRecordingsPopupState)}
        id="sort-recordings-popover"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        arrow="top-center"
        sx={{ bgcolor: "background.default" }}
      >
        <MenuItem>Title(A-Z)</MenuItem>
        <MenuItem>Title(Z-A)</MenuItem>
        <MenuItem>Date(Newer - Older)</MenuItem>
        <MenuItem>Date(Older - Newer)</MenuItem>
      </MenuPopover>
    </Box>
  );
}
