import React from "react";
import { Box, Typography, Button, IconButton } from "@mui/material";
import {
  FlowBox,
  StyledRoundedTabs,
  StyledRoundedTabList,
  StyledRoundedTab,
} from "blocks/atoms/uistyles";
import { useAtom } from "jotai";
import { useAppContext, checkIfNull } from "@app21/core";
import { useSurveysContext } from "providers/SurveysProvider";
import { centerpanelContextAtom } from "blocks/modules/Surveys/surveys-atoms";
import { Close, Reply, Edit } from "@mui/icons-material";

export const baseMenuOptions = [
  { value: "questions", label: "Your Response" },
  { value: "results", label: "Results" },
];

export default function SurveysMenubar({ isMobile }) {
  const [centerpanelContext, setCenterpanelContext] = useAtom(
    centerpanelContextAtom
  );
  const {
    surveysPermissions,
    selectedDecision,
    actionContext,
    selectedDecisionId,
    loadRoute,
    isDocket,
  } = useSurveysContext();

  const handleChangeCenterpanelContext = (event, newValue) => {
    setCenterpanelContext(newValue);
  };
  console.log(actionContext);
  if (actionContext?.includes("add") || actionContext?.includes("edit"))
    return null;
  return (
    <FlowBox sx={{ px: 2, maxHeight: 50 }}>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,

          justifyContent: "space-between",
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <StyledRoundedTabs
            value={centerpanelContext}
            onChange={handleChangeCenterpanelContext}
            selectionFollowsFocus
            sx={{ maxWidth: 250 }}
          >
            <StyledRoundedTabList sx={{ p: 1 }}>
              {baseMenuOptions
                .filter((option) => {
                  if (
                    option.value === "questions" &&
                    !selectedDecision?.userIsInvited
                  ) {
                    return false;
                  } else {
                    return true;
                  }
                })
                .map((option, index) => (
                  <StyledRoundedTab
                    key={index}
                    value={option.value}
                    textColor="secondary"
                    sx={{ fontSize: "1.1 rem" }}
                  >
                    {option.label}
                  </StyledRoundedTab>
                ))}
            </StyledRoundedTabList>
          </StyledRoundedTabs>
        </Box>
        <Box sx={{ maxWidth: 220, display: "flex", alignItems: "center" }}>
          {isDocket && (
            <Button
              size="small"
              variant="outlined"
              startIcon={<Reply />}
              sx={{ mr: 2 }}
              onClick={() =>
                loadRoute(isDocket ? "VIEW-RESOLUTION" : "VIEW-SURVEY", {
                  decisionId: null,
                })
              }
            >
              View All
            </Button>
          )}
          {surveysPermissions?.checkCanEdit(selectedDecision) &&
            selectedDecision.survey.decisionStatus !== "CLOSED" &&
            checkIfNull(selectedDecision?.responses) &&
            (actionContext === "edit" ? (
              <Button
                size="small"
                variant="outlined"
                startIcon={<Close />}
                onClick={() =>
                  loadRoute(isDocket ? "VIEW-RESOLUTION" : "VIEW-SURVEY", {
                    decisionId: selectedDecisionId,
                  })
                }
              >
                Cancel
              </Button>
            ) : (
              <Button
                size="small"
                startIcon={<Edit />}
                variant="outlined"
                onClick={() =>
                  loadRoute(isDocket ? "EDIT-RESOLUTION" : "EDIT-SURVEY", {
                    decisionId: selectedDecisionId,
                  })
                }
              >
                Edit
              </Button>
            ))}
        </Box>
      </Box>
    </FlowBox>
  );
}
