import React, { useContext } from "react";
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import { alpha, styled } from "@mui/material/styles";
import clip from "text-clipper";
import { Box, Collapse, Typography } from "@mui/material";

function TransitionComponent(props) {
  return <Collapse {...props} />;
}

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 10,
    paddingLeft: 11,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(1),
    },
  },
  [`& .${treeItemClasses.content}`]: { fontSize: "0.8rem" },
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
  "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
    backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.hover})`,
    color: "var(--tree-view-color)",
  },
  [`& .${treeItemClasses.label}`]: {
    fontWeight: theme.typography.subtitle1.fontWeight,
    color: "inherit",
  },
}));

export default function FolderTreeItemView(props) {
  const {
    bgcolor,
    color,
    labelIcon,
    labelInfo,
    labelText,
    isActiveFolder,
    nodeId,

    ...other
  } = props;
  //  const [focused, setFocused] = React.useState(false);
  //  const fileClickHandlers = useFileClickHandlers(file, props.displayIndex);
  //const file = useParamSelector(selectFileData, nodeId);

  //  const selected = useParamSelector(selectIsFileSelected, nodeId);

  return (
    <StyledTreeItemRoot
      {...other}
      nodeId={nodeId}
      TransitionComponent={TransitionComponent}
      label={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            my: 0.3,
            mx: -0.5,
            pr: 0,
            ...(isActiveFolder
              ? {
                  backgroundColor: (theme) =>
                    alpha(theme.palette.action.hover, 0.1),
                }
              : {}),
            borderRadius: 5,
          }}
        >
          {labelIcon ? labelIcon : null}
          {/*<Box component={labelIcon} color="inherit" sx={{ mr: 1 }} />
          {labelIcon}*/}
          <Typography
            variant="caption"
            component="h6"
            sx={{ flexGrow: 1, ml: 1, my: 0.2 }}
          >
            {clip(labelText, 30, { breakWords: true })}
          </Typography>
        </Box>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgcolor,
      }}
    />
  );
}
