// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { ArrowLeft } from "@mui/icons-material";
//import HoverMenu from 'material-ui-popup-state/HoverMenu';
import {
  MenuItem,
  Menu,
  Typography,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  usePopupState,
  bindMenu,
  bindHover,
  bindFocus,
  bindToggle,
} from "material-ui-popup-state/hooks";

export const CascadingContext = React.createContext({
  parentPopupState: null,
  rootPopupState: null,
});

export function CascadingMenuItem({ onClick, ...props }) {
  const { rootPopupState } = React.useContext(CascadingContext);
  if (!rootPopupState) throw new Error("must be used inside a CascadingMenu");
  const handleClick = React.useCallback(
    (event) => {
      rootPopupState.close(event);
      if (onClick) onClick(event);
    },
    [rootPopupState, onClick]
  );

  return <MenuItem {...props} onClick={handleClick} sx={{ width: "100%" }} />;
}

export function CascadingMenu({ popupState, ...props }) {
  const { rootPopupState } = React.useContext(CascadingContext);
  const context = React.useMemo(
    () => ({
      rootPopupState: rootPopupState || popupState,
      parentPopupState: popupState,
    }),
    [rootPopupState, popupState]
  );
  return (
    <CascadingContext.Provider value={context}>
      <Menu {...props} {...bindMenu(popupState)} />
    </CascadingContext.Provider>
  );
}

export function CascadingSubmenu({ title, popupId, disabledFlag, ...props }) {
  const { parentPopupState } = React.useContext(CascadingContext);
  const popupState = usePopupState({
    popupId,
    variant: "popover",
    parentPopupState,
  });
  return (
    <React.Fragment>
      <MenuItem {...bindToggle(popupState)} disabled={disabledFlag}>
        <ListItemIcon>
          <ArrowLeft />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              color="textPrimary"
              variant="subtitle2"
              sx={{ flexGrow: 1 }}
            >
              {title}
            </Typography>
          }
        />
      </MenuItem>
      <CascadingMenu
        {...props}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "bottom", horizontal: "right" }}
        popupState={popupState}
      />
    </React.Fragment>
  );
}
