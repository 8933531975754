import React from 'react'
import { SvgIcon } from '@mui/material';

export default function BreakoutViewIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M55.0003 89.0423C52.2503 92.709 47.7502 92.709 45.0002 89.0423L38.7502 80.709C38.0419 79.7923 36.5419 79.0423 35.4169 79.0423H33.3336C16.6669 79.0423 8.33358 74.8756 8.33358 54.0423V33.209C8.33358 16.5423 16.6669 8.20898 33.3336 8.20898H66.6669C83.3336 8.20898 91.6669 16.5423 91.6669 33.209V54.0423"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M89.1669 75.8333C89.1669 83.2083 83.2086 89.1667 75.8336 89.1667C68.4586 89.1667 62.5002 83.2083 62.5002 75.8333C62.5002 68.4583 68.4586 62.5 75.8336 62.5"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M91.6669 91.6667L87.5002 87.5"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M66.6522 45.8333H66.6896"
          stroke="currentColor"
          strokeWidth="8.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M49.9814 45.8333H50.0188"
          stroke="currentColor"
          strokeWidth="8.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.3107 45.8333H33.3481"
          stroke="currentColor"
          strokeWidth="8.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
