import React from "react";
import { Card, CardContent, CardActions, Button } from "@mui/material";
import { SelectSubscriptionChoicesForm } from "blocks/modules/Subscriptions/features";
import { useForm, FormProvider } from "react-hook-form";
import toast from "react-hot-toast";
import {
  useAppContext,
  useOrganization,
  useSuite,
  useUserInfo,
  app21APIInstance,
  useApp21ProductsAndCoupons,
} from "@app21/core";
import { getSuiteDetailsFromType, getSuitePrices } from "utils";
import { LoadingSpinner } from "blocks/atoms";

export default function ChangeSubscriptionForm({
  targetOrgId = null,
  targetSuiteId = null,
  subscriptionContext,
  handleClose = () => null,
}) {
  const { selectedSuiteId, userInfo } = useAppContext();
  const subscriptionSuiteId = targetSuiteId ?? selectedSuiteId;
  const { data: selectedSuite, status: selectedSuiteStatus } =
    useSuite(subscriptionSuiteId);

  const {
    data: app21ProductsAndCouponsData,
    status: app21ProductsAndCouponsDataStatus,
  } = useApp21ProductsAndCoupons();

  const suiteSubscriptionTierDetails = getSuiteDetailsFromType(
    selectedSuite?.suiteType
  );

  const defaultValues = {
    subscriptionChoice:
      suiteSubscriptionTierDetails.suiteTier?.toUpperCase() ?? "",
    subscriptionTemplate:
      suiteSubscriptionTierDetails.suiteTemplate?.toUpperCase() ?? "",
    couponCode: "",
  };

  const methods = useForm({ defaultValues });
  const { control, handleSubmit, formState, reset, getValues } = methods;
  const { isSubmitting, isDirty, isValid } = formState;

  React.useEffect(() => {
    reset(defaultValues);
  }, [selectedSuite]); //Dont put reset or default Values as a dependency here. It will cause an infinite loop .

  const onSubmit = async (data) => {
    const targetSuiteProductCode =
      data.subscriptionTemplate + "-" + data.subscriptionChoice;
    console.log(targetSuiteProductCode);
    const targetSuiteProduct =
      app21ProductsAndCouponsData?.products[targetSuiteProductCode];
    console.log(targetSuiteProduct);
    if (subscriptionContext === "NEW") {
      const { data: response } = await app21APIInstance.post(
        "/stripe/payments/sessions/create",
        {
          type: "RETRY-SUITE-PAYMENT",
          suiteId: targetSuiteId,
          priceId: targetSuiteProduct.pricing.GBP.monthly.priceId,
          productId: targetSuiteProduct.productId,
          couponCode: data.couponCode?.toUpperCase() ?? "",
          calledBy: userInfo._id,
        }
      );
      if (response && response.data && response.data.paymentURL) {
        //TODO - FIXME -  stripe limitations.. however, need a better more nextjs compatible way of doing this.. making a note for us to check back at a later date
        window.location.href = response.data.paymentURL;
      }
    } else if (subscriptionContext === "CHANGE") {
      const { data: response } = await app21APIInstance.post(
        "/stripe/subscriptions/update",
        {
          suiteId: selectedSuiteId,
          priceId: targetSuiteProduct.pricing.GBP.monthly.priceId,
          productId: targetSuiteProduct.productId,
          couponCode: data.couponCode?.toUpperCase() ?? "",
          calledBy: userInfo._id,
        }
      );
      //TODO - FIXME -   making a note for us to check back at a later date to handle error notifications
      toast.success(`Success`);
    }
    handleClose();
  };
  if (
    selectedSuiteStatus === "loading" ||
    app21ProductsAndCouponsDataStatus === "loading"
  ) {
    return <LoadingSpinner />;
  }
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardContent>
            <SelectSubscriptionChoicesForm
              control={control}
              formSubscriptionChoice={"subscriptionChoice"}
              formSubscriptionTemplate={"subscriptionTemplate"}
              formCouponCode={"couponCode"}
              subscriptionContext={subscriptionContext}
            />
          </CardContent>
          <CardActions>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting || !isDirty || !isValid}
            >
              {isDirty ? "Apply Subscription" : "Your Current Subscription"}
            </Button>
          </CardActions>
        </Card>
      </form>
    </FormProvider>
  );
}
