import React from 'react'
import { SvgIcon } from "@mui/material";

export default function FileEngagementIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M62.75 80.7917C60.1666 82.5417 56.9166 84 52.9583 85.2917L46.375 87.4583C29.8333 92.7917 21.125 88.3333 15.75 71.7917L10.4166 55.3333C5.08329 38.7917 9.49996 30.0417 26.0416 24.7083L32.625 22.5417C34.3333 22 35.9583 21.5417 37.5 21.25C36.25 23.7917 35.25 26.875 34.4166 30.4167L30.3333 47.875C26.25 65.2917 31.625 73.875 49 78L56 79.6667C58.4166 80.25 60.6666 80.625 62.75 80.7917Z"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M52.6667 35.5415L72.8751 40.6665"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M48.5835 51.667L60.6668 54.7503"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M87.3333 21.2496C91.8333 26.2496 92.6249 33.4163 90.2499 43.4996L86.1666 60.9163C82.6666 75.958 75.7499 82.0413 62.7499 80.7913C60.6666 80.6246 58.4166 80.2496 55.9999 79.6663L48.9999 77.9996C31.6249 73.8746 26.2499 65.2913 30.3333 47.8746L34.4166 30.4163C35.2499 26.8746 36.2499 23.7913 37.4999 21.2496C42.3749 11.1663 50.6666 8.45796 64.5833 11.7496L71.5416 13.3746"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
