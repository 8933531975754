import React from "react";
import {
  FullScreenIcon,
  CompactScreenIcon,
  SplitScreenIcon,
  VideocallEventIcon,
} from "blocks/atoms/customicons";
import {
  CompactIcon,
  SplitIcon,
  FullIcon,
  FeatureIcon,
} from "blocks/atoms/trrysticons";

export const layoutVisitorMenuOptions = [
  {
    label: "Full Screen",
    value: "fullscreen",
    icon: <FullIcon fontSize="medium" />,
  },
  {
    label: "Featured Layout",
    value: "featured",
    icon: <FeatureIcon fontSize="medium" />,
  },
];

export const layoutMenuOptions = [
  {
    label: "Full",
    value: "fullscreen",
    icon: <FullIcon fontSize="medium" />,
  },
  {
    label: "Split",
    value: "normal",
    icon: <SplitIcon fontSize="medium" />,
  },
  {
    label: "Compact",
    value: "compact",
    icon: <CompactIcon fontSize="medium" />,
  },
  {
    label: "Featured",
    value: "featured",
    icon: <FeatureIcon fontSize="medium" />,
  },
];
