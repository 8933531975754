import React from "react";
import {
  Box,
  IconButton,
  Typography,
  TextField,
  Switch,
  Button,
} from "@mui/material";
import { MenuPopover } from "blocks/atoms/uicomponents";
import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from "material-ui-popup-state/hooks";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AddIcon from '@mui/icons-material/Add';

export default function AddMarkerPopover({ selectedKey, handleAddMeetingMarker}) {
  const AddMarkerPopupState = usePopupState({
    variant: "popover",
    popupId: "add-marker-popover",
  });
  const [meetMarkerValue, setMeetMarkerValue] = React.useState();
  const [meetingMarkerVisibility, setMeetingMarkerVisibility] =
    React.useState();
  return (
    <div>
      <AddCircleOutlineOutlinedIcon {...bindTrigger(AddMarkerPopupState)} />
      <MenuPopover
        {...bindPopover(AddMarkerPopupState)}
        id="add-marker-popover"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        arrow="top-center"
        sx={{ bgcolor: "background.default", width: "max-content" }}
      >
        <Box
          sx={{
            background: "#fff",
            p: 3,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TextField
            variant="standard"
            margin="dense"
            value={meetMarkerValue}
            onChange={(e) => setMeetMarkerValue(e.target.value)}
            fullWidth
            placeholder="Insert tags separated with commas"
            sx={{ width: 250 }}
          />
          <Box>
            <Typography
              variant="caption"
              color={meetingMarkerVisibility === "ALL" ? "default" : "primary"}
              sx={{
                fontWeight:
                  meetingMarkerVisibility === "ALL" ? "normal" : "bold",
              }}
            >
              Only Me
            </Typography>
            <Switch
              onChange={(e) =>
                e.target.checked
                  ? setMeetingMarkerVisibility("ALL")
                  : setMeetingMarkerVisibility("ONLY-ME")
              }
              value={meetingMarkerVisibility === "ALL"}
            />{" "}
            <Typography
              variant="caption"
              color={meetingMarkerVisibility === "ALL" ? "primary" : "default"}
              sx={{
                fontWeight:
                  meetingMarkerVisibility === "ALL" ? "bold" : "normal",
              }}
            >
              Visible to All Invited
            </Typography>
          </Box>
          <Button
            onClick={(e) =>
              handleAddMeetingMarker(
                selectedKey,
                meetMarkerValue,
                meetingMarkerVisibility
              )
            }
            variant="outlined"
            startIcon={<AddIcon />}
            size="small"
            sx={{ alignSelf: "end", width: "fit-content", P: 0 }}
            disabled={!meetMarkerValue}
          >
            Add
          </Button>
        </Box>
      </MenuPopover>
    </div>
  );
}
