import React from "react";
import {
  IconButton,
  SvgIcon,
  Checkbox,
  ListSubheader,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  FormControl,
} from "@mui/material";
import { MergeType } from "@mui/icons-material";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";

const optionsList = [
  { value: "none", label: "No Grouping" },
  { value: "daily", label: "Daily" },
  { value: "monthly", label: "Monthly" },
];
const ChartGroupByPicker = ({ handleGroupBySelect, value = "daily" }) => {
  const chartPickerPopupState = usePopupState({
    variant: "popover",
    popupId: "chartGroupByPickerId",
  });

  return (
    <React.Fragment>
      <IconButton size="small" {...bindTrigger(chartPickerPopupState)}>
        <MergeType fontSize="inherit" />
      </IconButton>
      <Menu {...bindMenu(chartPickerPopupState)}>
        <ListSubheader>Group Chart by:</ListSubheader>
        {optionsList.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === value}
            value={value}
            onClick={() => {
              handleGroupBySelect(option.value);
              chartPickerPopupState?.close();
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};
export default ChartGroupByPicker;
