import React from 'react'
import { SvgIcon } from '@mui/material';

export default function FolderIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M33.3333 8.33301H70.8333C79.1666 8.33301 83.3333 12.4997 83.3333 20.833V26.583"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.33325 54.2497V29.1663C8.33325 12.4997 12.4999 8.33301 29.1666 8.33301H35.4166C41.6666 8.33301 43.0416 10.1663 45.4166 13.333L51.6666 21.6663C53.2499 23.7497 54.1666 24.9997 58.3333 24.9997H70.8332C87.4999 24.9997 91.6666 29.1663 91.6666 45.833"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M91.6666 62.459V70.834C91.6666 87.5007 87.4999 91.6673 70.8332 91.6673H29.1666C12.4999 91.6673 8.33325 87.5007 8.33325 70.834"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
