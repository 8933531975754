import React from "react";
import { FlowBox } from "blocks/atoms/uistyles";
import { useAppContext } from "@app21/core";
import { useAtom } from "jotai";
import PerfectScrollbar from "react-perfect-scrollbar";
import { viewFilesContextAtom } from "providers/FilesProvider/files-atoms";
import ShowSuiteFolders from "./ShowSuiteFolders";
import ShowBreakoutFolders from "./ShowBreakoutFolders";
import { useFilesContext } from "providers/FilesProvider";
import { reservedFolderNames } from "blocks/modules/TrrystFiles/foundations";
import { LoadingSpinner } from "blocks/atoms";
import _ from "lodash";

export default function ShowSidebarFilesList() {
  const [viewFilesContext] = useAtom(viewFilesContextAtom);
  const { userInfo } = useAppContext();
  const { updatedTreeKeys, allS3KeysStatus, docketsInSuite } =
    useFilesContext();

  const keysData = updatedTreeKeys?.folders?.[0]?.folders?.[0] ?? {};
  const breakoutKeysData =
    keysData?.folders?.filter(
      (key) => key.name === "Breakouts" && key.origKey === "dockets"
    ) ?? [];

  const updatedBreakoutKeysData = _.cloneDeep(breakoutKeysData);

  let filteredDockets = [];
  docketsInSuite?.map((d) => {
    d.members.map((m) => {
      if (m === userInfo._id) filteredDockets.push(d._id);
    });
  });

  const foldersToInclude = [];
  if (breakoutKeysData[0]?.folders) {
    breakoutKeysData[0].folders.map((f) => {
      if (filteredDockets.includes(f.origKey)) foldersToInclude.push(f);
    });
    updatedBreakoutKeysData[0].folders = foldersToInclude;
  }

  const suiteKeysData =
    keysData?.folders
      ?.filter(
        (key) =>
          !(
            (key.name == "Breakouts" && key.origKey === "dockets") ||
            reservedFolderNames.includes(key.name)
          )
      )
      .reverse() ?? [];

  return (
    <FlowBox
      sx={{
        transition: "display 1s ease-in-out",

        overflow: "hidden",
      }}
    >
      {allS3KeysStatus === "loading" ? (
        <LoadingSpinner />
      ) : viewFilesContext === "suites" ? (
        <FlowBox
          sx={{
            overflow: "hidden",
            pl: 1,
          }}
        >
          <PerfectScrollbar
            style={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              paddingRight: 10,
              height: "100%",
            }}
          >
            <ShowSuiteFolders foldersList={suiteKeysData} />
          </PerfectScrollbar>
        </FlowBox>
      ) : viewFilesContext === "dockets" ? (
        <FlowBox
          sx={{
            overflow: "hidden",
            pl: 1,
          }}
        >
          <PerfectScrollbar
            style={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              height: "100%",
            }}
          >
            <ShowBreakoutFolders foldersList={updatedBreakoutKeysData} />
          </PerfectScrollbar>
        </FlowBox>
      ) : (
        <></>
      )}
    </FlowBox>
  );
}
