// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { IconButton, Typography } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import {
  ShowSchedule,
  ShowTimeDifference,
} from "blocks/modules/Dockets/foundations";

import {
  useAppContext,
  useMeetingsInDockets,
  useDockets,
  getRolePrivileges,
  useHandleRouterPush,
} from "@app21/core";

import { useIntl } from "react-intl";

import LoadingSpinner from "blocks/atoms/LoadingSpinner";

import toast from "react-hot-toast";

const DocketScheduleView = (props) => {
  const { viewMode } = props;
  const { selectedDocketId } = useAppContext();
  const { loadRoute } = useHandleRouterPush();
  const { checkAccessRules, userInfo } = useAppContext();
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });

  const {
    data: docket,
    status: docketStatus,
    apiError: docketApiError,
  } = useDockets(selectedDocketId);
  const {
    data: docketMeetings,
    status: docketMeetingsStatus,
    apiError: docketMeetingsApiError,
  } = useMeetingsInDockets(selectedDocketId);

  const dataFetchError = docketApiError || docketMeetingsApiError;
  const dataFetchLoading =
    docketStatus === "loading" || docketMeetingsStatus === "loading";

  React.useEffect(() => {
    if (dataFetchError && !dataFetchLoading) {
      toast.error(dataFetchError.message);
    }
  }, [dataFetchError]);

  const relatedMeeting =
    docketMeetings && docketMeetings.slice(-1)[0]?.meetingJSON;
  const isMeeting = Boolean(relatedMeeting?.start || relatedMeeting?.end);

  const { accessFlag: canCreateBoardMeeting } = checkAccessRules({
    entity: "DOCKET",
    action: "CREATE-BOARD-MEETING",
    featureName: "DOCKET",
    ...getRolePrivileges({
      userId: userInfo?._id,
      baseObject: docket ?? {},
      members: docket?.members ?? [],
    }),
  });

  return docketMeetingsStatus === "loading" || docketStatus === "loading" ? (
    <LoadingSpinner size={20} />
  ) : (
    <div
      style={{
        ...(viewMode === "summary"
          ? { weight: "100%", height: "100%" }
          : {
              weight: "100%",
              height: "100%",
              minHeight: 300,
              padding: (theme) => theme.spacing(2),
            }),
      }}
    >
      {!isMeeting ? (
        <div
          style={{
            ...(viewMode !== "summary"
              ? {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }
              : { display: "flex", alignItems: "center" }),
          }}
        >
          <Typography variant="body1">{`${t(
            "DocketDashboard.ShowSchedule.noScheduleMessage"
          )}`}</Typography>
          {canCreateBoardMeeting && viewMode === "summary" && (
            <IconButton
              color="primary"
              size="small"
              sx={{ ml: 2 }}
              onClick={() =>
                loadRoute("EDIT-DOCKET", { docketId: selectedDocketId })
              }
            >
              <AddCircle fontSize="inherit" color="primary" />
            </IconButton>
          )}
        </div>
      ) : (
        <div>
          <ShowTimeDifference />
          {/* <ShowSchedule viewMode={viewMode} /> */}
        </div>
      )}
    </div>
  );
};

export default DocketScheduleView;
