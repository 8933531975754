// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Box, Button, MobileStepper, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import BackTitleWrapperPaper from "blocks/atoms/BackTitleWrapperPaper";
import { useRouter } from "next/router";
import {
  useAppContext,
  checkIfNull,
  useHandleResolutionActions,
} from "@app21/core";
import { FormProvider, useForm, useFieldArray } from "react-hook-form";
import ResponseQuestionCard from "blocks/modules/Surveys/features/SimpleSurveyResponse/ResponseQuestionCard";
import { Send } from "@mui/icons-material";
import { LoadingSpinner } from "blocks/atoms";
import Slider from "react-slick";
import { useSurveysContext } from "providers/SurveysProvider";

const SimpleSurveyResponse = ({}) => {
  let surveyCarouselRef = React.useRef(null);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const { userInfo, selectedDocketId } = useAppContext();
  const { isDocket, selectedDecision, selectedDecisionId, loadRoute } =
    useSurveysContext();
  const { handleResolutionActions } = useHandleResolutionActions();
  const resolution = selectedDecision;
  let foundUserSurveyResponse =
    !checkIfNull(resolution?.responses) && resolution?.responses[userInfo._id];
  let localSurveyResponse =
    (foundUserSurveyResponse ? Object.values(foundUserSurveyResponse) : {}) ??
    {};

  const blankChoice = {
    choice: null,
    otherComments: "",
  };

  const [defaultResponseArray, setDefaultResponseArray] = React.useState(
    !checkIfNull(localSurveyResponse)
      ? Object.values(foundUserSurveyResponse)
      : resolution?.survey?.questionBlock?.map(() => blankChoice)
  );

  const methods = useForm({
    mode: "onChange",
    defaultValues: { responses: defaultResponseArray },
  });
  const {
    handleSubmit,
    formState,
    reset,
    control,
    getValues,
    setValue,
    watch,
    trigger,
    clearErrors,
  } = methods;
  const { isDirty, errors, isSubmitting, isValid } = formState;
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: "responses",
      keyName: "tempId",
    }
  );

  React.useEffect(() => {
    setDefaultResponseArray([]);
    if (!checkIfNull(localSurveyResponse)) {
      setDefaultResponseArray(Object.values(foundUserSurveyResponse));
    } else {
      resolution?.survey?.questionBlock?.forEach((question) =>
        setDefaultResponseArray((prev) => [...prev, blankChoice])
      );
    }
    reset({ responses: defaultResponseArray });
  }, [resolution?.responses, reset]);

  const handleIndexChange = (index) => setActiveIndex(index);

  const handlePrevious = async () => {
    surveyCarouselRef.current?.slickGoTo(activeIndex - 1);
    handleIndexChange(activeIndex - 1);
  };

  const handleNext = async () => {
    await trigger(`responses[${activeIndex}].choice`, { shouldFocus: true });
    if (!errors?.responses?.[activeIndex]?.choice) {
      surveyCarouselRef.current?.slickGoTo(activeIndex + 1);
      handleIndexChange(activeIndex + 1);
    }
  };

  const sliderProps = {
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  const onSubmit = async (data) => {
    isDocket
      ? await handleResolutionActions(
          {
            action: "RESOLUTION-RESPONSE",
            resolution: resolution,
            responseData: data,
          },
          null,
          null
        )
      : await handleResolutionActions(
          {
            action: "SURVEY-RESPONSE",
            resolution: resolution,
            responseData: data,
          },
          null,
          null
        );
    loadRoute(isDocket ? "VIEW-RESOLUTION" : "VIEW-SURVEY", {
      decisionId: resolution._id,
    });
  };

  if (resolution?.survey?.decisionStatus === "CLOSED") {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="subtitle2">{`This ${
          isDocket ? "resolution" : " poll"
        } is now Closed.`}</Typography>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          p: 1,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        <FormProvider {...methods}>
          {/* <BackTitleWrapperPaper
            title={resolution?.survey?.decisionName || "Poll"}
          > */}
          <Typography variant="h6" sx={{ mb: 1 }}>
            {resolution?.survey?.decisionName || "Poll"}
          </Typography>

          <Slider
            ref={(el) => {
              surveyCarouselRef.current = el;
            }}
            {...sliderProps}
          >
            {fields.map((responseField, index) => {
              return (
                <ResponseQuestionCard
                  key={responseField.tempId}
                  responseQuestion={resolution?.survey?.questionBlock[index]}
                  response={responseField}
                  index={index}
                />
              );
            })}
          </Slider>
          <MobileStepper
            steps={fields.length}
            position="static"
            variant="text"
            activeStep={activeIndex}
            sx={{ mt: 3, fontSize: 12 }}
            nextButton={
              activeIndex < fields.length - 1 ? (
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={Boolean(errors?.responses?.[activeIndex]?.choice)}
                >
                  Next
                </Button>
              ) : (
                <LoadingButton
                  variant="contained"
                  size="small"
                  type="submit"
                  disabled={!isValid}
                  onClick={handleSubmit(async (data) => await onSubmit(data))}
                  endIcon={<Send />}
                  loading={isSubmitting}
                  loadingPosition="end"
                >
                  Submit
                </LoadingButton>
              )
            }
            backButton={
              <Button
                size="small"
                onClick={handlePrevious}
                disabled={activeIndex === 0}
              >
                Previous
              </Button>
            }
          />
        </FormProvider>
      </Box>
    );
  }
};

export default SimpleSurveyResponse;
