// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Avatar, Typography, Box } from "@mui/material";
import { getS3ImageWrapper, useUserInfo } from "@app21/core";
import Autolinker from "autolinker";
import dompurify from "dompurify";

const ChatMessageShape = ({ userId, message, userDetails }) => {
  const { data: userInfo } = useUserInfo(userId);

  //  userDetails ?? usersInSuite?.find(user => user.id === attendeeId) ?? {};
  let userObj = userDetails ?? userInfo;
  const autolinkedMessage = Autolinker.link(message, {
    truncate: { length: 50, location: "middle" },
    newWindow: true,
  });
  return (
    <div style={{ display: "flex", flexGrow: 1, width: "100%", maxWidth: 450 }}>
      <Avatar
        src={getS3ImageWrapper(userObj?.image, "person")}
        sx={{ mr: 1, width: 20, height: 20 }}
      />
      <Box sx={{ flexGrow: 1 }}>
        <Typography
          variant="body2"
          dangerouslySetInnerHTML={{
            __html: dompurify.sanitize(autolinkedMessage ?? " ", {
              ADD_ATTR: ["target"],
            }),
          }}
        />
      </Box>
    </div>
  );
};

export default ChatMessageShape;
