import React from 'react'
import { SvgIcon } from "@mui/material";

export default function DropBoxIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 16 16"
      stroke="currentColor"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M8.00817 15.1095C7.96367 15.1095 7.91867 15.0975 7.87867 15.0735L3.63417 12.5075C3.55917 12.4625 3.51367 12.381 3.51367 12.2935V10.875C3.51367 10.737 3.62567 10.625 3.76367 10.625C3.90167 10.625 4.01367 10.737 4.01367 10.875V12.1525L8.00817 14.5675L12.0032 12.1525V10.875C12.0032 10.737 12.1152 10.625 12.2532 10.625C12.3912 10.625 12.5032 10.737 12.5032 10.875V12.2935C12.5032 12.381 12.4577 12.462 12.3827 12.5075L8.13767 15.0735C8.09767 15.0975 8.05267 15.1095 8.00817 15.1095Z"
          fill="currentColor"
        />
        <path
          d="M5.03612 11.9134C4.98862 11.9134 4.94062 11.8999 4.89862 11.8719L0.662618 9.0849C0.596118 9.0414 0.554118 8.9679 0.550118 8.8884C0.546118 8.8084 0.580618 8.7319 0.643118 8.6814L3.57212 6.3169C3.65512 6.2509 3.77112 6.2429 3.86112 6.2994L8.13212 8.9574C8.20012 8.9994 8.24362 9.0719 8.24912 9.1519C8.25512 9.2319 8.22212 9.3094 8.16062 9.3609L5.19662 11.8544C5.15062 11.8939 5.09362 11.9134 5.03612 11.9134ZM1.22362 8.8554L5.01762 11.3524L7.57562 9.2004L3.74762 6.8179L1.22362 8.8554Z"
          fill="currentColor"
        />
        <path
          d="M3.86155 6.72357L3.59755 6.29907L7.57605 3.82307L5.01805 1.67057L1.22355 4.16757L2.95055 5.56157C3.05805 5.64807 3.07505 5.80557 2.98805 5.91307C2.90205 6.02057 2.74355 6.03807 2.63655 5.95057L0.643051 4.34157C0.580551 4.29157 0.546551 4.21457 0.550051 4.13457C0.554051 4.05507 0.595551 3.98157 0.662551 3.93807L4.89855 1.15057C4.99105 1.08957 5.11205 1.09707 5.19705 1.16807L8.16105 3.66157C8.22255 3.71307 8.25505 3.79107 8.24955 3.87057C8.24405 3.95057 8.20005 4.02257 8.13255 4.06507L3.86155 6.72357ZM10.9641 11.9131C10.9066 11.9131 10.8496 11.8931 10.8031 11.8546L7.83905 9.36107C7.77755 9.30957 7.74505 9.23157 7.75055 9.15207C7.75605 9.07207 7.80005 9.00007 7.86755 8.95757L12.1386 6.29907C12.2291 6.24257 12.3451 6.25007 12.4276 6.31657L15.3566 8.68107C15.4191 8.73157 15.4531 8.80807 15.4496 8.88807C15.4456 8.96757 15.4041 9.04107 15.3371 9.08457L11.1011 11.8721C11.0596 11.8996 11.0116 11.9131 10.9641 11.9131ZM8.42455 9.20007L10.9826 11.3521L14.7766 8.85507L12.2526 6.81707L8.42455 9.20007Z"
          fill="currentColor"
        />
        <path
          d="M12.2707 6.76087C12.2252 6.76087 12.1792 6.74837 12.1387 6.72287L7.8677 4.06537C7.7997 4.02337 7.7562 3.95086 7.7507 3.87086C7.7447 3.79086 7.7777 3.71336 7.8392 3.66186L10.8032 1.16787C10.8877 1.09687 11.0087 1.08987 11.1017 1.15037L15.3377 3.93787C15.4042 3.98137 15.4462 4.05487 15.4502 4.13437C15.4542 4.21437 15.4197 4.29086 15.3572 4.34136L12.4282 6.70537C12.3822 6.74237 12.3267 6.76087 12.2707 6.76087ZM8.4242 3.82287L12.2522 6.20486L14.7762 4.16737L10.9822 1.67037L8.4242 3.82287Z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
}
