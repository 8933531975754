import React from "react";
import {
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  ListItemSecondaryAction,
} from "@mui/material";
import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from "material-ui-popup-state/hooks";
import { PrintUserInfo, getFileIcon, MenuPopover } from "blocks/atoms";
import { getExtensionFromFilename, getChatFilename, formatBytes } from "utils";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import clip from "text-clipper";
import { format } from "date-fns";
import { checkIfNull } from "@app21/core";
import { getDateTimeFormat } from "utils/getDateFormats";

export default function ShowAttachmentItem({
  file,
  isMobile = false,
  handleFileView,
  handleFileDownload,
  handleFileAndAssociatedMessageDelete,
}) {
  const [hovered, setHovered] = React.useState(false);
  const s3Key = file?.customElements?.s3Key ?? "";
  var fileName = getChatFilename(s3Key);
  const attachmentsCardPopupState = usePopupState({
    variant: "popover",
    popupId: "attachments-card-popover",
  });

  return (
    <ListItem
      button
      dense
      onClick={() => handleFileView(file?.customElements?.s3Key)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <ListItemIcon>
        {getFileIcon({
          fileExt: getExtensionFromFilename(fileName),
          size: "lg",
        })}
      </ListItemIcon>
      <ListItemText
        primary={clip(fileName ?? "", 25, { breakWords: true })}
        secondary={
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{ display: "flex", alignItems: "center" }}
          >
            {parseInt(file?.customElements?.fileSize)
              ? formatBytes(file?.customElements?.fileSize, 0)
              : ""}{" "}
            ,
            <PrintUserInfo userId={file?.sender} /> ,
            {!checkIfNull(file?.createdAt) &&
              format(
                new Date(file?.createdAt ?? Date.now()),
                getDateTimeFormat({
                  startDate: file?.createdAt,
                  noTimeFlag: true,
                })
              )}
          </Typography>
        }
      />
      {hovered || isMobile ? (
        <ListItemSecondaryAction>
          <MoreHorizOutlinedIcon
            {...bindTrigger(attachmentsCardPopupState)}
            sx={{ width: "1rem", height: "1rem", cursor: "pointer" }}
          />
          <MenuPopover
            {...bindPopover(attachmentsCardPopupState)}
            id="attachments-card-popover"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            arrow="top-center"
            sx={{ p: 1 }}
          >
            <MenuItem onClick={() => handleFileDownload(file)}>
              Download
            </MenuItem>
            <MenuItem
              onClick={() => handleFileAndAssociatedMessageDelete(file)}
            >
              Delete
            </MenuItem>
          </MenuPopover>
        </ListItemSecondaryAction>
      ) : (
        <span style={{ width: 30, height: 30 }} />
      )}
    </ListItem>
  );
}
