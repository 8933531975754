// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import { Typography, Box, Button, Stack } from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";
import { ConferenceInputChoices } from "blocks/modules/TrrystVideocall";
import { useVideocallContext } from "providers/TrrystVideocallProvider";
import LoadingSpinner from "blocks/atoms/LoadingSpinner";
import {
  useAudioInputs,
  useVideoInputs,
} from "amazon-chime-sdk-component-library-react";
import { useHandleVideocallActions } from "providers/TrrystVideocallProvider/hooks";

const DeviceSetup = ({ meetingInfo }) => {
  const { guestAccessStatus, meeting } = useVideocallContext();
  const { selectDeviceError: audioDeviceError } = useAudioInputs();
  const { selectDeviceError: videoDeviceError } = useVideoInputs();
  const [handleAccessRequestMessage, setHandleAccessRequestMessage] =
    React.useState(false);
  const { handleMeetingJoinAction } = useHandleVideocallActions();

  React.useEffect(() => {
    if (guestAccessStatus === "GRANTED" || guestAccessStatus === "DENIED")
      setHandleAccessRequestMessage(false);
  }, [guestAccessStatus]);

  const handleRequestAccess = () => {
    setHandleAccessRequestMessage(true);
    handleMeetingJoinAction({ action: "REQUEST-ACCESS" });
  };

  const intl = useIntl();
  const { formatMessage } = intl;

  const t = (id) => formatMessage({ id });
  return (
    <Box
      justify="center"
      sx={{
        height: "100%",
        width: "100%",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ConferenceInputChoices meetingInfo={meetingInfo} />
      {handleAccessRequestMessage && (
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
            py: 2,
            maxWidth: 450,
            textAlign: "center",
          }}
        >
          <Typography variant="h6">
            Request to join the meeting has been sent
          </Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
            Please, wait untill the Coordinator lets you in. You can press the
            remind button to send a reminder in case of long wait
          </Typography>
        </Box>
      )}
      <Stack
        direction="row"
        spacing={1}
        sx={{ justifyContent: "center", mt: 2 }}
      >
        {!handleAccessRequestMessage ? (
          <Button
            variant={
              guestAccessStatus === "DENIED" ||
              guestAccessStatus === "REQUESTED"
                ? "default"
                : "contained"
            }
            disabled={
              guestAccessStatus === "DENIED" ||
              guestAccessStatus === "REQUESTED" ||
              handleAccessRequestMessage === true ||
              audioDeviceError ||
              videoDeviceError
            }
            onClick={() => {
              if (guestAccessStatus === "NEEDED") {
                handleRequestAccess();
              } else if (guestAccessStatus === "DENIED") {
                handleMeetingJoinAction({ action: "cancel" });
              } else handleMeetingJoinAction({ action: "enter" });
            }}
            sx={{ minWidth: 50 }}
          >
            {guestAccessStatus === "DENIED" ? (
              "Access Denied"
            ) : guestAccessStatus === "GRANTED" ? (
              `${t("SuiteDashboard.Meeting.enterMeeting")}`
            ) : guestAccessStatus === "REQUESTED" ? (
              <LoadingSpinner variant="exp" size={10} />
            ) : (
              "Request Access"
            )}
          </Button>
        ) : (
          <Button
            color="primary"
            onClick={() => {
              handleRequestAccess();
            }}
          >
            Remind again
          </Button>
        )}
        <Button
          onClick={() => handleMeetingJoinAction({ action: "cancel" })}
        >{`${t("global.buttons.cancel")}`}</Button>
      </Stack>
    </Box>
  );
};

export default DeviceSetup;
