// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)

import React from "react";
import { pxToRem } from "utils/styleHelpers";
import { useToggleLocalMute } from "amazon-chime-sdk-component-library-react";

import { Mic, MicOff } from "@mui/icons-material";
import { Button, useTheme } from "@mui/material";
import { MicOnWebIcon, MicOffWebIcon } from "blocks/atoms/trrystCustomIcons";
import { StyledSquareMenuButton } from "blocks/atoms/uistyles";
import { chimeTestIds } from "blocks/atoms/constants";

const AudioInputControl = ({ recommendedButtonSize = 40 }) => {
  const { muted, toggleMute } = useToggleLocalMute();
  const theme = useTheme();

  return (
    <StyledSquareMenuButton
      data-testid={chimeTestIds.audioInputControl}
      onClick={toggleMute}
      sx={{
        borderRadius: 0,
        padding: 0,
        width: recommendedButtonSize,
        height: recommendedButtonSize,
        maxWidth: recommendedButtonSize,
        maxHeight: recommendedButtonSize,
        minWidth: recommendedButtonSize,
        minHeight: recommendedButtonSize,
      }}
    >
      {muted ? (
        <MicOnWebIcon
          stroke={theme.palette.error.main}
          sx={{
            width: recommendedButtonSize,
            height: recommendedButtonSize,

            maxWidth: pxToRem(40),
            maxHeight: pxToRem(40),
            color: (theme) => theme.palette.gray.lighter,
            "&:hover": {
              color: "gray.light",
            },
          }}
        />
      ) : (
        <MicOffWebIcon
          color="primary"
          stroke="white"
          sx={{
            width: recommendedButtonSize,
            height: recommendedButtonSize,

            maxWidth: pxToRem(40),
            maxHeight: pxToRem(40),
            "&:hover": {
              color: "primary.dark",
            },
          }}
        />
      )}
    </StyledSquareMenuButton>
  );
};

export default AudioInputControl;
