// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  Popover,
  Button,
  MenuItem,
  Typography,
  ListItemIcon,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import AddTaskIcon from "@mui/icons-material/AddTask";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import DescriptionIcon from "@mui/icons-material/Description";
import UploadFileIcon from "@mui/icons-material/UploadFile";

const DocketAgendaMenu = ({ handleNewAgendaCreate, setUploadDialogOpen }) => {
  const [listAnchor, setListAnchor] = React.useState(null);
  const open = Boolean(listAnchor);
  const id = open ? "simple-popover" : undefined;
  const handleOpen = (event) => {
    setListAnchor(event.currentTarget);
  };
  const handleClose = () => {
    setListAnchor(null);
  };
  return (
    <div>
      <Button
        aria-describedby={id}
        variant="contained"
        onClick={handleOpen}
        sx={{ borderRadius: 0 }}
        startIcon={<AddTaskIcon />}
        endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      >
        ADDING AGENDA
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={listAnchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <MenuItem value={10} onClick={() => handleNewAgendaCreate("blank")}>
          <ListItemIcon>
            <ContactPageIcon fontSize="medium" />
          </ListItemIcon>
          <Typography sx={{ p: 2 }}>BLANK TEMPLATE</Typography>
        </MenuItem>
        <MenuItem value={20} onClick={() => handleNewAgendaCreate("formal")}>
          <ListItemIcon>
            <DescriptionIcon fontSize="medium" />
          </ListItemIcon>
          <Typography sx={{ p: 2 }}>FORMAL AGENDA TEMPLATE</Typography>
        </MenuItem>
        <MenuItem value={30} onClick={() => setUploadDialogOpen(true)}>
          <ListItemIcon>
            <UploadFileIcon fontSize="medium" />
          </ListItemIcon>
          <Typography sx={{ p: 2 }}>UPLOAD EXISTING DOCUMENT</Typography>
        </MenuItem>
      </Popover>
    </div>
  );
};

export default DocketAgendaMenu;
