import React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

export default function ListDocketResolutions({ resolutions = [] }) {
  return (
    <Box>
      <Typography variant="h6" sx={{ textAlign: "center" }}>
        Resolutions
      </Typography>
      <List>
        {resolutions.map((resolution, index) => (
          <ListItem key={index} button>
            <ListItemIcon>
              <Typography variant="h6">{index + 1}.</Typography>
            </ListItemIcon>
            <ListItemText
              primary={resolution.what}
              secondary={
                <span>
                  {resolution.who} | {resolution.when}
                </span>
              }
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
