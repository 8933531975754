// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  Box,
  Typography,
  Button,
  Paper,
  Container,
  Stack,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import { LoadingSpinner } from "blocks/atoms";
import { useDebounce } from "use-debounce";

import { constructBasicMinutesTemplate } from "blocks/modules/Dockets/foundations";
import dompurify from "dompurify";
import ScopedCssBaseline from "@mui/material/ScopedCssBaseline";
import {
  useAppContext,
  useMeeting,
  checkIfNull,
  useTranscript,
  useHandleMeetingActions,
  useHandleRouterPush,
  getRolePrivileges,
} from "@app21/core";
import { useConfirm } from "material-ui-confirm";
import toast from "react-hot-toast";
import { useIntl } from "react-intl";
import DisplayMinutesDoc from "blocks/modules/Dockets/foundations/showmodules/DisplayMinutesDoc";

const ShowMinutes = (props) => {
  const { viewMode, meetingId, searchContent = null } = props;
  const [minutesOpen, setMinutesOpen] = React.useState(true);

  const [minutesConstructStatusFlag, setMinutesConstructStatusFlag] =
    React.useState(false);
  const { checkAccessRules, userInfo } = useAppContext();
  const confirm = useConfirm();

  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });

  const { handleMeetingActions } = useHandleMeetingActions();

  const {
    data: transcriptions,
    status: transcriptionsStatus,
    apiError: transcriptionsApiError,
  } = useTranscript({
    meetingId: meetingId,
    searchString: searchContent,
  });
  const {
    data: relatedMeeting,
    status: fetchMeetingStatus,
    apiError: fetchMeetingApiError,
  } = useMeeting(meetingId);
  const meeting = relatedMeeting?.data;

  const dataFetchError = fetchMeetingApiError || transcriptionsApiError;
  const dataFetchLoading =
    transcriptionsStatus === "loading" || fetchMeetingStatus === "loading";

  React.useEffect(() => {
    if (dataFetchError && !dataFetchLoading) {
      toast.error(dataFetchError.message);
    }
  }, [dataFetchError]);

  const isMeetingScheduled =
    Boolean(meeting?.meetingJSON?.start) ||
    Boolean(meeting?.meetingJSON?.end) ||
    meeting?.isRecordingPresent ||
    meeting?.isTranscriptionPresent ||
    meeting?.associatedChimeMeetingStatus === "ended";

  const [minutes, setMinutes] = React.useState(meeting?.minutes);
  const [debouncedMinutes] = useDebounce(minutes, 10000);
  let meetingMinutes = meeting?.minutes;
  React.useEffect(() => setMinutes(meeting?.minutes), [meetingMinutes]);
  const { loadRoute } = useHandleRouterPush();

  const { accessFlag: canEditMinutes } = checkAccessRules({
    entity: "MEETINGS",
    action: "EDIT-MINUTES",
    featureName: "CALENDAR",
    ...getRolePrivileges({
      userId: userInfo._id,
      baseObject: meeting?.meetingJSON,
      members: meeting?.meetingJSON?.members?.map((member) => member._id) ?? [],
    }),
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => {
    const updateMinutes = async () =>
      await handleMeetingActions(
        {
          action: "UPDATE-MEETING",
          meetingData: {
            minutes: minutes,
          },
          meetingId: meeting?._id,
        },
        null,
        null
      );
    if (debouncedMinutes === minutes && debouncedMinutes !== meeting?.minutes)
      updateMinutes();
  }, [debouncedMinutes]);

  const onUploadedDocumentSave = async (data, actionType) => {
    if (actionType === "delete") {
      confirm({
        description:
          "Are you sure you want to delete the Minutes document. All the documents contents will be erased and cannot be reversed?",
      })
        .then(async () => {
          await handleMeetingActions(
            {
              action: "UPDATE-MEETING",
              meetingData: {
                minutes: null,
              },
              meetingId: meeting?._id,
            },
            null,
            null
          );
          setMinutesOpen(false);
          toast("Minutes Deleted");
        })
        .catch((err) => {
          console.log("Deletion cancelled.", err);
        });
    } else {
      await handleMeetingActions(
        {
          action: "UPDATE-MEETING",
          meetingData: {
            minutes: data,
          },
          meetingId: meeting?._id,
        },
        null,
        null
      );
    }
  };

  const handleMinutesConstruct = async () => {
    setMinutesConstructStatusFlag(true);
    await handleMeetingActions(
      {
        action: "UPDATE-MEETING",
        meetingData: {
          minutes: constructBasicMinutesTemplate({ meeting, transcriptions }),
        },
        meetingId: meeting?._id,
      },
      null,
      null
    );
    setMinutesConstructStatusFlag(false);
  };

  const toggleMinutes = () => {
    setMinutesOpen(!minutesOpen);
  };

  if (
    fetchMeetingStatus === "loading" ||
    transcriptionsStatus === "loading" ||
    minutesConstructStatusFlag
  ) {
    return <LoadingSpinner size={25} />;
  } else if (!isMeetingScheduled) {
    return (
      <Box
        sx={{
          my: 3,
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="body1">
          A meeting has not been scheduled.
        </Typography>
      </Box>
    );
  } else if (viewMode === "summary") {
    return (
      <ScopedCssBaseline>
        <div
          dangerouslySetInnerHTML={{
            __html: dompurify.sanitize(
              dompurify.sanitize(meeting?.minutes || "")
            ),
          }}
        />
      </ScopedCssBaseline>
    );
  } else
    return (
      <>
        <Box
          sx={{
            p: 1,
            flexGrow: 1,
            weight: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          {!checkIfNull(meeting) && !meeting?.minutes?.length > 0 ? (
            <>
              <Typography variant="body1">
                No Minutes have been drafted.
              </Typography>

              {canEditMinutes && viewMode !== "summary" && (
                <Stack direction="row" spacing={2}>
                  {checkIfNull(
                    transcriptions?.filter(
                      (transcription) => !checkIfNull(transcription?.openAi)
                    )
                  ) ? (
                    <Button
                      sx={{ marginTop: (theme) => theme.spacing(2) }}
                      variant="contained"
                      onClick={handleMinutesConstruct}
                    >
                      Create Basic Minutes
                    </Button>
                  ) : (
                    <Button
                      sx={{ marginTop: (theme) => theme.spacing(2) }}
                      variant="contained"
                      onClick={handleMinutesConstruct}
                    >
                      Create Minutes from AI-generated Summary
                    </Button>
                  )}
                </Stack>
              )}
            </>
          ) : (
            <>
              {!minutesOpen ? (
                <Button
                  sx={{ m: "auto" }}
                  variant="contained"
                  onClick={toggleMinutes}
                >
                  Open Minutes
                </Button>
              ) : (
                <DisplayMinutesDoc
                  meeting={meeting}
                  minutes={minutes}
                  onChange={(value) => setMinutes(value)}
                  onClose={toggleMinutes}
                  onUploadedDocumentSave={onUploadedDocumentSave}
                />
              )}
            </>
          )}
        </Box>
      </>
    );
};

export default ShowMinutes;
