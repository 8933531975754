import React from "react";
import {
  Box,
  Typography,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CardActionArea,
  alpha,
  Tooltip,
  Button,
  Stack,
} from "@mui/material";
import { useDocketsContext } from "providers/DocketsProvider";
import {
  EditIcon,
  FilesIcon,
  CalendarIcon,
  AverageViewIcon,
  PlayRecordingsIcon,
  MyTaskIcon,
  PollIcon,
  DataroomIcon,
  TaskIcon,
  ChatIcon,
  VideocallIcon,
  CollapseArrowIcon,
  DurationIcon,
  UserIcon,
  SelectActiveIcon,
  SurveyFinishedIcon,
  SurveyIcon,
  ArrowRightIcon,
  BreakoutIcon,
} from "blocks/atoms/trrysticons";

import { ProfilesChipsList, Label, UserAvatar } from "blocks/atoms";
import { populateUsers } from "@app21/core";

import {
  Done,
  AccessTime,
  AssignmentTurnedIn,
  Edit,
  DeleteForever,
  Assignment,
  Settings,
} from "@mui/icons-material";

import clip from "text-clipper";
import useIsMobileBreakpoint from "hooks/useIsMobileBreakpoint";

export default function DisplayDashboardDocketCard({
  docketObj,
  isSuiteManager = false,

  ...props
}) {
  const [isHovered, setIsHovered] = React.useState(false);
  const {
    loadRoute,
    docketPermissions,
    handleDocketDelete,
    handleDocketStatusChange,
    handleDocketView,
    usersInSuite,
    selectedDocket = {},
    selectedDocketId,
    handleDocketEdit,
    handleDocketWalkinVideocallClick,
    handleDocketChatClick,
    handleDocketMeetingClick,
    handleDocketFilesClick,
    handleDocketTasksClick,
    handleDocketSurveysClick,
    handleDocketRecordingsClick,
  } = useDocketsContext();
  const isMobile = useIsMobileBreakpoint();
  const docketActionOptions = [
    // ...(docketPermissions.checkCanStartDocketWalkinCall(docketObj)
    //   ? [
    //       {
    //         label: "Walkin Videocall",
    //         icon: <VideocallIcon fontSize="inherit" />,
    //         description: "Walkin Videocall with this breakout Group",
    //         onClickAction: (event, id) => {
    //           event.preventDefault();
    //           event.stopPropagation();
    //           handleDocketWalkinVideocallClick(id);
    //         },
    //       },
    //     ]
    //   : []),
    ...(docketPermissions.checkCanViewChat(docketObj)
      ? [
          {
            label: "Chat",
            icon: <ChatIcon fontSize="inherit" />,
            description: "Chat with this breakout Group",
            onClickAction: (event, id) => {
              event.preventDefault();
              event.stopPropagation();
              handleDocketChatClick(id);
            },
          },
        ]
      : []),
    ...(docketPermissions.checkCanViewFiles(docketObj)
      ? [
          {
            label: "Documents",
            icon: <DataroomIcon fontSize="inherit" />,
            description: "Share files within this breakout",
            onClickAction: (event, id) => {
              event.preventDefault();
              event.stopPropagation();
              handleDocketFilesClick(id);
            },
          },
        ]
      : []),
    ...(docketPermissions.checkCanViewCalendar(docketObj)
      ? [
          {
            label: "Meetings",
            icon: <CalendarIcon fontSize="inherit" />,
            description: "Schedule a meeting with breakout members",
            onClickAction: (event, id) => {
              event.preventDefault();
              event.stopPropagation();
              handleDocketMeetingClick(id);
            },
          },
        ]
      : []),
    ...(docketPermissions.checkCanViewTasks(docketObj)
      ? [
          {
            label: "Task",
            icon: <TaskIcon fontSize="inherit" />,
            description: "Team task tracker",
            onClickAction: (event, id) => {
              event.preventDefault();
              event.stopPropagation();
              handleDocketTasksClick(id);
            },
          },
        ]
      : []),
    ...(docketPermissions.checkCanViewRecords(docketObj)
      ? [
          {
            label: "Recording",
            icon: <PlayRecordingsIcon fontSize="inherit" />,
            description: "Browse transcripts of recorded meetings",
            onClickAction: (event, id) => {
              event.preventDefault();
              event.stopPropagation();
              handleDocketRecordingsClick(id);
            },
          },
        ]
      : []),
    ...(docketPermissions.checkCanViewSurveys(docketObj)
      ? [
          {
            label: "Surveys",
            icon: <PollIcon fontSize="inherit" />,
            description: "Poll this breakout group",
            onClickAction: (event, id) => {
              event.preventDefault();
              event.stopPropagation();
              handleDocketSurveysClick(id);
            },
          },
        ]
      : []),
  ];

  const isCardActive = docketObj._id === selectedDocketId;
  const isDocketCompleted = docketObj?.status === "completed";

  const menuOptions = [
    ...(docketPermissions.checkCanEditDocket(docketObj) && !isDocketCompleted
      ? [
          {
            label: "Edit",
            icon: <Edit />,
            onClick: () => handleDocketEdit(docketObj._id),
          },
        ]
      : []),
    ...(docketPermissions.checkCanDeleteDocket(docketObj)
      ? [
          {
            label: "Delete",
            icon: <DeleteForever />,
            onClick: () => handleDocketDelete(docketObj._id),
          },
        ]
      : []),
    ...(docketPermissions.checkCanEditDocket(docketObj) &&
    docketObj.status !== "completed"
      ? [
          {
            label: "Mark as Completed",
            icon: <AssignmentTurnedIn />,
            onClick: () => handleDocketStatusChange("completed", docketObj),
          },
        ]
      : [
          {
            label: "Re-open Docket",
            icon: <Assignment />,
            onClick: () => handleDocketStatusChange("OPEN", docketObj),
          },
        ]),
  ];

  return (
    <Card
      sx={{
        width: "100%",
        maxWidth: isMobile ? "40vw" : 250,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: 1,
        m: 1,
        minHeight: 135,
        border: (theme) =>
          `1px solid ${
            isHovered ? theme.palette.primary.lighter : theme.palette.grey[300]
          }`,
        backgroundColor: (theme) =>
          isCardActive
            ? alpha(theme.palette.primary.lighter, 0.1)
            : theme.palette.background.paper,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...props}
    >
      <CardHeader
        sx={{ pb: 1, width: "100%", position: "absolute", top: 0 }}
        //  avatar={<UserAvatar userId={docketObj.createdBy} />}
        title={
          <Typography
            variant={"subtitle1"}
            color={isCardActive ? "primary" : "text.secondary"}
          >
            {clip(docketObj?.title, 30)}
          </Typography>
        }
        {...(docketPermissions.checkCanStartDocketWalkinCall(docketObj)
          ? {
              action: (
                <Tooltip
                  title={"Walkin Videocall with this breakout Group"}
                  arrow
                >
                  <IconButton
                    size="small"
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      handleDocketWalkinVideocallClick(docketObj._id);
                    }}
                  >
                    <VideocallIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              ),
            }
          : {})}
        disableTypography
      />
      <CardActionArea
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          position: "relative",
          py: 8,
        }}
        onClick={() => handleDocketView(docketObj._id)}
      >
        <CardContent
          sx={{
            py: 0,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexGrow: 1,
            width: "100%",
          }}
        >
          {docketPermissions.checkCanAccessMembers(docketObj) ? (
            <ProfilesChipsList
              dense
              usersList={populateUsers(docketObj?.members, usersInSuite)}
              maxItems={4}
            />
          ) : (
            <></>
          )}
          {!isMobile && (
            <Tooltip title="View Breakout">
              <span>
                <ArrowRightIcon
                  fontSize="small"
                  sx={{
                    color: "text.secondary",
                    alignSelf: "center",
                    mr: -1,
                  }}
                />
              </span>
            </Tooltip>
          )}
        </CardContent>
      </CardActionArea>

      <CardActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 0,
          width: "100%",
          flexGrow: 1,
          minHeight: 30,
          maxHeight: 30,

          pr: 2,
          position: "absolute",
          bottom: 0,
        }}
      >
        {isHovered || isMobile ? (
          docketPermissions.checkCanViewDocket(docketObj) ? (
            <Stack
              direction="row"
              spacing={1}
              justifyContent="center"
              alignItems="center"
            >
              {docketActionOptions.map((docketOption, index) => {
                return (
                  <Tooltip key={index} title={docketOption.description} arrow>
                    <IconButton
                      size="small"
                      onClick={(event) =>
                        docketOption.onClickAction(event, docketObj._id)
                      }
                    >
                      <span>{docketOption.icon}</span>
                    </IconButton>
                  </Tooltip>
                );
              })}
            </Stack>
          ) : (
            <Tooltip
              title={
                "You are not a part of this breakout. Goto Edit / Manage breakout."
              }
              arrow
            >
              <IconButton
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  handleDocketView(docketObj._id);
                }}
              >
                <Settings fontSize="inherit" />
              </IconButton>
            </Tooltip>
          )
        ) : (
          <Box />
        )}
      </CardActions>
    </Card>
  );
}
