// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from "react";
import { useLocalAudioInputActivityPreview } from "amazon-chime-sdk-component-library-react";
import { Typography } from "@mui/material";

const MicrophoneActivityPreview = () => {
  const elementRef = React.useRef();
  useLocalAudioInputActivityPreview(elementRef);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        marginBottom: 10,
      }}
    >
      <Typography variant="subtitle2"> Microphone activity</Typography>
      <div
        ref={elementRef}
        style={{
          width: "100%",
          minWidth: 100,
          marginTop: 10,
          border: "1px solid blue",
        }}
      ></div>
    </div>
  );
};

export default MicrophoneActivityPreview;
