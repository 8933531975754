import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { DocketSelectionDropdown } from "blocks/modules/Dockets";
import { useHandleRouterPush, useAppContext } from "@app21/core";
import AddIcon from "@mui/icons-material/Add";

export default function DocketHeaderPanel({ dockets = [] }) {
  const { loadRoute } = useHandleRouterPush();
  const { selectedOrganizationId } = useAppContext();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mb: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography variant="h5">Board Meetings</Typography>
        <Button
          data-testid="add-board-meeting"
          variant="outlined"
          size="small"
          onClick={() => {
            loadRoute("CREATE-DOCKET", {
              orgId: selectedOrganizationId,
            });
          }}
          startIcon={<AddIcon />}
          sx={{ ml: 6 }}
        >
          ADD NEW
        </Button>
      </Box>
      {dockets?.length ? <DocketSelectionDropdown dockets={dockets} /> : null}
    </Box>
  );
}
