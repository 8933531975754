import React from 'react'
import { SvgIcon } from "@mui/material";

export default function DetailsViewIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M91.6666 41.6665H8.33325"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M50 41.6665V91.6665"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.4999 13.8335C10.8333 18.4585 8.33325 26.1252 8.33325 37.5002V62.5002C8.33325 83.3335 16.6666 91.6668 37.4999 91.6668H62.4999C83.3333 91.6668 91.6666 83.3335 91.6666 62.5002V37.5002C91.6666 16.6668 83.3333 8.3335 62.4999 8.3335H37.4999"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
