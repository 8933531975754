import React from 'react'
import { SvgIcon } from '@mui/material';

export default function FeatureIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M8.33331 37.4997V27.083C8.33331 16.708 16.7083 8.33301 27.0833 8.33301H37.5"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M62.5 8.33301H72.9167C83.2917 8.33301 91.6667 16.708 91.6667 27.083V37.4997"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M91.6667 66.6675V72.9175C91.6667 83.2925 83.2917 91.6675 72.9167 91.6675H66.6667"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.5 91.6667H27.0833C16.7083 91.6667 8.33331 83.2917 8.33331 72.9167V62.5"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
