// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import { PhotoSizeSelectLarge } from "@mui/icons-material";
import { FileIcons } from "blocks/atoms";
import {
  VideoExtensions,
  ImageExtensions,
  AudioExtensions,
} from "utils/icon-extension-helper";

export const getFileIcon = ({ fileExt, size = "lg", color }) => {
  let fileIcons =
    (color
      ? FileIcons({ size: size, color: color })
      : FileIcons({ size: size })) ?? {};
  let extType = "File";

  switch (fileExt) {
    case "folder":
      extType = "Folder";
      break;
    case "doc":
    case "docx":
      extType = "Word";
      break;
    case "pdf":
      extType = "PDF";
      break;
    case "xls":
    case "xlsx":
      extType = "Excel";
      break;
    case "ppt":
    case "pptx":
      extType = "PowerPoint";
      break;
    case "zip":
    case "7z":
      extType = "Archive";
      break;
    case "txt":
      extType = "Text";
      break;
    case "html":
      extType = "HTML";
      break;
    case "css":
      extType = "CSS";
      break;
    case "js":
      extType = "JavaScript";
      break;
    case "json":
      extType = "JSON";
      break;
    case "mp3":
      extType = "Audio";
      break;
    case "ogg":
      extType = "Audio";
      break;
    case "wav":
      extType = "Audio";
      break;
    case "webm":
      extType = "Video";
      break;
    case "mp4":
      extType = "Video";
      break;
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
    case "bmp":
    case "tiff":
    case "webp":
      extType = "Image";
      break;
  }

  if (ImageExtensions.includes(fileExt)) {
    extType = "Image";
  } else if (AudioExtensions.includes(fileExt)) {
    extType = "Audio";
  } else if (VideoExtensions.includes(fileExt)) {
    extType = "Video";
  }

  return fileIcons[extType];
};

export default getFileIcon;
