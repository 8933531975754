import React from 'react'
import { SvgIcon } from '@mui/material';

export default function AgendaAnalysisIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 109 115"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M12.5 38.1668C12.5 25.6668 18.75 17.3335 33.3333 17.3335H66.6667C81.25 17.3335 87.5 25.6668 87.5 38.1668V79.8335C87.5 92.3335 81.25 100.667 66.6667 100.667H33.3333C18.75 100.667 12.5 92.3335 12.5 79.8335V54.6252"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M64.5833 33.9585V50.0834C64.5833 51.9168 62.4165 52.8334 61.0832 51.6251L51.4168 42.7086C50.6251 41.9586 49.3749 41.9586 48.5832 42.7086L38.9168 51.6251C37.5834 52.8334 35.4167 51.9168 35.4167 50.0834V17.3335H64.5833"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M55.2083 67.3335H72.9167"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.5 84H72.9167"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
