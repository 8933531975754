import React from 'react'
import { SvgIcon } from '@mui/material';

export default function HomeIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path d="M50 74.9585V62.4585Z" fill="white" />
        <path
          d="M50 74.9585V62.4585"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M60.2088 8.77402C56.6502 6.28305 52.163 5.18694 47.8487 5.28811C43.5344 5.38927 39.1012 6.69465 35.6648 9.3674L13.2065 26.8674C10.8586 28.6943 8.89169 31.4015 7.51745 34.2024C6.1434 37.0029 5.20837 40.2071 5.20837 43.1674V74.0425C5.20837 85.4254 14.4728 94.7508 25.875 94.7508H36.6851H37.5C41.6667 94.7508 41.6667 88.5008 37.5 88.5008H36.6851H25.875C17.9439 88.5008 11.4584 81.9929 11.4584 74.0425V43.1674C11.4584 41.4194 12.0442 39.1653 13.1285 36.9554C14.2121 34.7468 15.6425 32.8906 17.0436 31.8008L39.5019 14.3008C41.6905 12.5991 44.7579 11.6123 47.9952 11.5364C51.2324 11.4605 54.3488 12.3016 56.6235 13.8934L82.3735 31.9351L82.3799 31.9395C83.922 33.0144 85.4978 34.9066 86.6933 37.1969C87.8887 39.4869 88.5417 41.8641 88.5417 43.7508V74.0841C88.5417 81.9881 82.061 88.5008 74.125 88.5008H63.366H62.5C57.9989 88.5008 58.0554 94.7508 62.5 94.7508H63.366H74.125C85.5224 94.7508 94.7917 85.4302 94.7917 74.0841V43.7508C94.7917 40.5958 93.7572 37.223 92.2339 34.3047C90.7112 31.3877 88.5379 28.6142 85.9563 26.814L60.2088 8.77402Z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
}
