import React from "react";
import { Box } from "@mui/material";
import {
  StyledRoundedTabs,
  StyledRoundedTab,
  StyledRoundedTabList,
} from "blocks/atoms/uistyles";
import { useAtom } from "jotai";
import { useHandleRouterPush, useAppContext } from "@app21/core";
import {
  showFilesSidebarFlagAtom,
  viewFilesContextAtom,
} from "providers/FilesProvider/files-atoms";

export const baseMenuOptions = [
  { value: "suites", label: "Suite" },
  { value: "dockets", label: "Breakouts" },
];

export default function SelectFilesSidebarContext() {
  const [viewFilesContext, setViewFilesContext] = useAtom(viewFilesContextAtom);
  const { selectedOrganizationId, selectedSuiteId } = useAppContext();
  const { loadRoute } = useHandleRouterPush();

  const handleChangeViewContext = (value) => {
    setViewFilesContext(value);
    if (value === "suites") {
      loadRoute("VIEW-DATAROOM", {
        docketId: null,
        s3Key: `${selectedOrganizationId}/${selectedSuiteId}/`,
      });
    } else if (value === "dockets") {
      loadRoute("VIEW-DATAROOM", {
        s3Key: `${selectedOrganizationId}/${selectedSuiteId}/dockets/`,
      });
    }
  };

  return (
    <Box sx={{ display: "flex", flexGrow: 1, alignItems: "stretch", px: 2 }}>
      <StyledRoundedTabs
        value={viewFilesContext}
        onChange={(event, value) => {
          handleChangeViewContext(value);
        }}
        selectionFollowsFocus
        sx={{ flexGrow: 1, my: 2 }}
      >
        <StyledRoundedTabList sx={{ p: 1 }}>
          {baseMenuOptions.map((option, index) => (
            <StyledRoundedTab
              key={index}
              value={option.value}
              textColor="secondary"
              sx={{ fontSize: (theme) => theme.typography.h6.fontSize }}
            >
              {option.label}
            </StyledRoundedTab>
          ))}
        </StyledRoundedTabList>
      </StyledRoundedTabs>
    </Box>
  );
}
