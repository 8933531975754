// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { useAppContext, useUserInfo } from "@app21/core";

import { MainVideocallComponent } from "blocks/modules/TrrystVideocall";
import { useMediaQuery } from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import { chimeGridsizeCalculator } from "blocks/atoms/constants";
import { useLayoutContext } from "providers/LayoutProvider";
import { styled } from "@mui/material/styles";
import { ChimeNotistackProvider } from "providers/TrrystVideocallProvider";
import { LoadingSpinner } from "blocks/atoms";

const MainContentWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  height: "100vh",
  //backgroundImage: `url(${backgroundImage})`,
  backgroundImage: `linear-gradient(135deg, #f5f7fa 0%, #fafffa 100%)`, //#c3cfe2
  backgroundSize: "cover",
  width: "100vw",
  maxWidth: "100vw",
  maxHeight: "100vh",
  minHeight: "100vh",
  minWidth: "100vw",
  alignItems: "stretch",
  flexDirection: "row",
}));

const NonChimeContentWrapper = styled("div")(({ theme, ownerState }) => {
  const { chimeGridSize = 0 } = ownerState;
  return {
    width: `${100 - chimeGridSize}%`,
    display: "flex",
    flexDirection: "column",
    // width: '100%',
    height: "100vh",
    flexGrow: 1,
    maxHeight: "100vh",
    overflow: "hidden",
  };
});

const ChimeContentWrapper = styled("div")(({ ownerState }) => {
  const { chimeGridSize = 0 } = ownerState;
  return {
    width: `${chimeGridSize}%`,
  };
});

const AppLayout = ({ children }) => {
  const { chimeViewMode, meetingId, isBasicDataReady, isChimeBot } =
    useAppContext() ?? {};
  const { proceedToMeetingLayout } = useLayoutContext();

  const activeMeeting = Boolean(meetingId) && proceedToMeetingLayout;
  const mobileMinWidth = useMediaQuery("(min-width:850px)");
  let defaultFullScreenFlag = chimeViewMode === "fullscreen" || !mobileMinWidth;

  let chimeGridSize = chimeGridsizeCalculator(
    activeMeeting,
    defaultFullScreenFlag,
    chimeViewMode
  );
  if (!activeMeeting) {
    return (
      <MainContentWrapper>
        <NonChimeContentWrapper ownerState={{ chimeGridSize }}>
          {isBasicDataReady ? children : <LoadingSpinner variant="exp" />}
        </NonChimeContentWrapper>
      </MainContentWrapper>
    );
  } else
    return (
      <MainContentWrapper>
        <NonChimeContentWrapper ownerState={{ chimeGridSize }}>
          {isBasicDataReady ? children : <LoadingSpinner variant="exp" />}
        </NonChimeContentWrapper>
        <ChimeContentWrapper ownerState={{ chimeGridSize }}>
          <ChimeNotistackProvider>
            <PerfectScrollbar
              options={{ suppressScrollX: true }}
              style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
            >
              <MainVideocallComponent />
            </PerfectScrollbar>
          </ChimeNotistackProvider>
        </ChimeContentWrapper>
      </MainContentWrapper>
    );
};

export default AppLayout;
