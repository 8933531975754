import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  Box,
  Stack,
  Typography,
  Card,
  CardHeader,
  Icon,
  useMediaQuery,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { TickIconSquare } from "blocks/atoms/trrysticons";
import { CheckBoxTwoTone } from "@mui/icons-material";
import NotAvailableIcon from "public/static/customicons/not-available.svg";
import AvailableIcon from "public/static/customicons/available.svg";
import PartiallyAvailableIcon from "public/static/customicons/partially-available.svg";

const comparisonTable = [
  {
    Feature: "Included Credits",
    Free: 50,
    Basic: 500,
    Pro: 2500,
  },
  {
    Feature: "Videocalls",
    Free: "Limited",
    Basic: "Full",
    Pro: "Full",
  },
  {
    Feature: "Max # of videocall participants",
    Free: 4,
    Basic: 15,
    Pro: "Unlimited",
  },
  {
    Feature: "Chat",
    Free: "Partial",
    Basic: "Full",
    Pro: "Full",
  },
  {
    Feature: "Surveys",
    Free: "Full",
    Basic: "Full",
    Pro: "Full",
  },
  {
    Feature: "Tasks",
    Free: "Full",
    Basic: "Full",
    Pro: "Full",
  },
  {
    Feature: "Scheduler",
    Free: "Full",
    Basic: "Full",
    Pro: "Full",
  },
  {
    Feature: "Meeting Recording",
    Free: "No",
    Basic: "Full",
    Pro: "Full",
  },
  {
    Feature: "Transcription",
    Free: "No",
    Basic: "Full",
    Pro: "Full",
  },
  {
    Feature: "AI Meeting Minutes",
    Free: "No",
    Basic: "Full",
    Pro: "Full",
  },
  {
    Feature: "Analytics",
    Free: "Partial",
    Basic: "Full",
    Pro: "Full",
  },
  {
    Feature: "Organization Setup",
    Free: "No",
    Basic: "Full",
    Pro: "Full",
  },
  {
    Feature: "Customized Suites",
    Free: "No",
    Basic: "Full",
    Pro: "Full",
  },
  {
    Feature: "Addl Document Protections",
    Free: "No",
    Basic: "Partial",
    Pro: "Full",
  },
  {
    Feature: "Video Search Enabled",
    Free: "No",
    Basic: "No",
    Pro: "Full",
  },
  {
    Feature: "2FA Additional Protection",
    Free: "No",
    Basic: "No",
    Pro: "Contact Us",
  },
];

const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile = false }) => {
  return {
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: isMobile ? 10 : 14,
      paddingTop: 5,
      paddingBottom: 5,
    },
  };
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function ComparePricingTiers({
  LabelComponent = <Typography variant="button">Compare Tiers</Typography>,
}) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [openDialog, setOpenDialog] = React.useState(false);
  const handleDialogOpen = () => {
    setOpenDialog(true);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const processData = (data) => {
    switch (data) {
      case "Full":
        return (
          <Icon sx={{ height: 20, width: 20 }}>
            <img src={AvailableIcon} alt="Not Available" />
          </Icon>
        );
      case "Partial":
        return (
          <Icon sx={{ height: 20, width: 20 }}>
            <img src={PartiallyAvailableIcon} alt="Not Available" />
          </Icon>
        );
      case "No":
        return (
          <Icon sx={{ height: 20, width: 20 }}>
            <img src={NotAvailableIcon} alt="Not Available" />
          </Icon>
        );
      default:
        return data;
    }
  };
  return (
    <>
      <Button
        color="primary"
        variant="contained"
        sx={{ alignSelf: "center", mt: 4, mb: 4 }}
        onClick={handleDialogOpen}
      >
        {LabelComponent}
      </Button>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={openDialog}
        onClose={handleDialogClose}
      >
        <DialogTitle component={Typography} variant="h3">
          Feature Comparison
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflow: "none",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              justifyConent: "center",
              my: 2,
            }}
          >
            <Typography variant="caption">* Legend</Typography>
            <Stack direction="row" spacing={2}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {processData("No")}
                <Typography sx={{ ml: 2 }}>Not available</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {processData("Partial")}
                <Typography sx={{ ml: 2 }}>Partially available</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {processData("Full")}
                <Typography sx={{ ml: 2 }}>Fully available</Typography>
              </Box>
            </Stack>
          </Box>

          <TableContainer
            component={Paper}
            sx={{
              pl: isMobile ? 0 : 3,
              display: "flex",
              flexDirection: "column",
              height: "100%",
              overflow: "auto",

              "&::-webkit-scrollbar": {
                width: 10,
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#f2f2f2",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: (theme) => theme.palette.primary.main,
                borderRadius: 2,
              },
            }}
          >
            <Table
              stickyHeader
              sx={{
                minWidth: isMobile ? 300 : 650,
                borderSpacing: `0 0px !important`,
                borderCollapse: `separate !important`,
              }}
            >
              <TableHead
                sx={{
                  bgcolor: "primary.main",
                  color: "primary.contrastText",
                }}
              >
                <TableRow>
                  <StyledTableCell>
                    <Typography variant={isMobile ? "h6" : "h4"}>
                      Feature
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ maxWidth: 100 }}>
                    <Typography variant={isMobile ? "h6" : "h4"}>
                      Free
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ maxWidth: 100 }}>
                    <Typography variant={isMobile ? "h6" : "h4"}>
                      Basic
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ maxWidth: 100 }}>
                    <Typography variant={isMobile ? "h6" : "h4"}>
                      Pro
                    </Typography>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {comparisonTable.map((row) => (
                  <StyledTableRow
                    key={row.Feature}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      isMobile={isMobile}
                    >
                      {processData(row.Feature)}
                    </StyledTableCell>
                    <StyledTableCell align="center" isMobile={isMobile}>
                      {processData(row.Free)}
                    </StyledTableCell>
                    <StyledTableCell align="center" isMobile={isMobile}>
                      {processData(row.Basic)}
                    </StyledTableCell>
                    <StyledTableCell align="center" isMobile={isMobile}>
                      {processData(row.Pro)}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button sx={{ alignSelf: "center" }} onClick={handleDialogClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
