/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react";
import {
  IconButton,
  SvgIcon,
  Checkbox,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  FormControl,
} from "@mui/material";
import { FilterIcon } from "blocks/atoms/trrysticons";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";
import PerfectScrollbar from "react-perfect-scrollbar";

export interface Suite {
  _id: string;
  fullName: string;
}

export interface ChartSuitePickerProps {
  listOfSuites: Suite[];
  value: Suite[];
  handleSuiteSelect: (suites: Suite[]) => void;
}

const ChartSuitePicker: React.FC<ChartSuitePickerProps> = (props) => {
  const { listOfSuites, value, handleSuiteSelect } = props;
  const chartPickerPopupState = usePopupState({
    variant: "popover",
    popupId: "chartSuitePickerId",
  });

  const isAllSelected = React.useMemo(
    () => listOfSuites?.length > 0 && value.length === listOfSuites?.length,
    [listOfSuites?.length, value?.length]
  );

  const handleChange = (option: Suite | "all") => {
    if (option === "all") {
      handleSuiteSelect(
        value.length === listOfSuites.length ? [] : listOfSuites
      );
      return;
    }
    handleSuiteSelect(
      value.includes(option)
        ? value.filter((v) => v !== option)
        : [...value, option]
    );
  };

  return (
    <React.Fragment>
      <IconButton size="small" {...bindTrigger(chartPickerPopupState)}>
        <SvgIcon component={FilterIcon} fontSize="inherit" />
      </IconButton>
      <Menu {...bindMenu(chartPickerPopupState)}>
        {/* @ts-ignore */}
        <PerfectScrollbar sx={{ maxHeight: 500 }}>
          <FormControl>
            <MenuItem value="all" onClick={() => handleChange("all")}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={isAllSelected}
                  indeterminate={
                    value.length > 0 && value.length < listOfSuites.length
                  }
                />
              </ListItemIcon>
              <ListItemText primary="Select All" />
            </MenuItem>
            {listOfSuites.map((suite) => (
              <MenuItem
                key={suite._id}
                value={suite._id}
                onClick={() => handleChange(suite)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={value?.findIndex((e) => e._id === suite._id) > -1}
                  />
                </ListItemIcon>
                <ListItemText primary={suite.fullName} />
              </MenuItem>
            ))}
          </FormControl>
        </PerfectScrollbar>
      </Menu>
    </React.Fragment>
  );
};
export default ChartSuitePicker;
