import React from "react";
import { Box, styled, Typography } from "@mui/material";
import { ChatMessagePanel } from "./features";
import { checkIfNull, useAppContext } from "@app21/core";
import { useChatContext } from "providers/TrrystChatProvider";
import { useAtom } from "jotai";
import { CurrentChannelAtom } from "blocks/modules/TrrystChat/state-atoms";
export const StyledBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
}));

export default function ContextualChatComponent({ channelId }) {
  const { allChannelsData } = useChatContext();
  const { selectedSuiteId } = useAppContext();
  const [, setCurrentChannel] = useAtom(CurrentChannelAtom);

  React.useEffect(() => {
    if (channelId) setCurrentChannel(channelId);
    return () => setCurrentChannel(`${selectedSuiteId}-CHAT`);
  }, [channelId]);

  if (!checkIfNull(allChannelsData)) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "stretch",
            flexGrow: 1,
            height: "100%",
            gap: 2,
            position: "relative",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 2,
              height: "100%",
              transition: "all 1s ease-in-out",
            }}
          >
            <ChatMessagePanel channelId={channelId} viewMode="compact" />
          </Box>
        </Box>
      </Box>
    );
  } else
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          p: 2,
        }}
      >
        <Typography variant="h6">
          No Chats fetched. If it is a new Suite, click on the Chat button above
          and start discussing amongst your Suite members
        </Typography>
      </Box>
    );
}
