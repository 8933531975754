// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Typography, Slider, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { CheckCircle, MoreHorizOutlined, Timelapse } from "@mui/icons-material";
import { Controller } from "react-hook-form";
import { checkIfNull } from "@app21/core";

const marks = [
  {
    value: 0,
    label: 0,
  },
  {
    value: 25,
    label: "25%",
  },
  {
    value: 50,
    label: "50%",
  },
  {
    value: 75,
    label: "75%",
  },
  {
    value: 100,
    label: <CheckCircle fontSize="small" />,
  },
];

const CustomSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.primary.light,
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, & .Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1,
    fontSize: 10,
    background: "unset",
    padding: 0,
    width: 22,
    height: 22,
    borderRadius: "50% 0% 50% 50%",
    backgroundColor: theme.palette.primary.main,
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "& .MuiSlider-valueLabelOpen": {
      transform: "translate(50%, 180%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
}));

{
  /*const CustomSlider = withStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 1,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
}))(Slider);*/
}

function valuetext(value) {
  return `${value}%`;
}

function CompletionSlider({
  showTitle = true,
  showMarks = true,
  showLabel = "auto",
  control,
  errors,
  name,
}) {
  return (
    <Box sx={{ width: "100%", flexGrow: 1, mt: 1, p: 1 }}>
      {showTitle && (
        <Typography gutterBottom sx={{ display: "flex", alignItems: "center" }}>
          % Completed
        </Typography>
      )}
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <CustomSlider
            {...field}
            getAriaValueText={valuetext}
            aria-labelledby="Completion Slider"
            onChange={(_, value) => {
              field.onChange(value);
            }}
            valueLabelDisplay={showLabel}
            step={5}
            marks={showMarks ? marks : false}
          />
        )}
      />
      {!checkIfNull(errors) && (
        <Typography color="error" variant="caption">
          {errors[name]}
        </Typography>
      )}
    </Box>
  );
}
CompletionSlider.propTypes = {
  control: PropTypes.any,
  errors: PropTypes.any,
};

CompletionSlider.defaultProps = {};
export default CompletionSlider;
