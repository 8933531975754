// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import arrowIosUpwardFill from "@iconify/icons-eva/arrow-ios-upward-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
//next
import NextLink from "next/link";

import { useRouter } from "next/router";
import NextMuiLink from "blocks/atoms/NextMuiLink";
// material
import { styled } from "@mui/material/styles";
import {
  Box,
  Link,
  Grid,
  List,
  Stack,
  Popover,
  ListItem,
  ListItemButton,
  ListSubheader,
  CardActionArea,
  Typography,
} from "@mui/material";

// ----------------------------------------------------------------------

const LinkStyle = styled(Link)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.primary,
  "&:hover": {
    opacity: 0.48,
    textDecoration: "none",
  },
}));

const NextMuiLinkStyle = styled(NextMuiLink)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.primary,
  "&:hover": {
    opacity: 0.78,
    textDecoration: "none",
  },
}));

// ----------------------------------------------------------------------

IconBullet.propTypes = {
  type: PropTypes.oneOf(["subheader", "item"]),
};

function IconBullet({ type = "item" }) {
  return (
    <Box sx={{ width: 24, height: 16, display: "flex", alignItems: "center" }}>
      <Box
        component="span"
        sx={{
          ml: "2px",
          width: 4,
          height: 4,
          borderRadius: "50%",
          bgcolor: "currentColor",
          ...(type !== "item" && {
            ml: 0,
            width: 8,
            height: 2,
            borderRadius: 2,
          }),
        }}
      />
    </Box>
  );
}

MenuDesktopItem.propTypes = {
  item: PropTypes.object,
  pathname: PropTypes.string,
  isHome: PropTypes.bool,
  isOffset: PropTypes.bool,
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

function MenuDesktopItem({
  item,
  pathname,
  isHome,
  isOpen,
  isOffset,
  onOpen,
  onClose,
  anchorEl,
}) {
  const { title, path, children } = item;
  const isActive = pathname?.includes(path) && path !== "/";

  if (children) {
    return (
      <div key={title}>
        <LinkStyle
          onClick={onOpen}
          underline="none"
          sx={{
            display: "flex",
            cursor: "pointer",
            alignItems: "center",
            color: "text.primary",
            ...(isActive && { color: "primary.main" }),
            ...(isHome && { color: "text.primary" }),
            ...(isOffset && { color: "text.secondary" }),
            ...(isOpen && { opacity: 0.48 }),
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {title}
          </Typography>
          <Box
            component={Icon}
            icon={isOpen ? arrowIosUpwardFill : arrowIosDownwardFill}
            sx={{ width: 16, height: 16 }}
          />
        </LinkStyle>

        <Popover
          open={isOpen}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={onClose}
          PaperProps={{
            sx: {
              p: 1,
              margin: "auto",
              mt: 2.5,
              borderRadius: 2,
              boxShadow: (theme) => theme.customShadows.z24,
              borderTop: (theme) => `3px solid ${theme.palette.secondary.main}`,
            },
          }}
        >
          <Grid container spacing={3}>
            {children.map((list) => {
              const { subheader, items } = list;

              return (
                <Grid
                  key={subheader}
                  item
                  xs={12}
                  md={subheader === "Dashboard" ? 6 : 12}
                >
                  <List>
                    <ListSubheader
                      disableSticky
                      disableGutters
                      sx={{
                        display: "flex",
                        lineHeight: "unset",
                        alignItems: "center",
                        color: "text.primary",
                        typography: "overline",
                      }}
                    >
                      {/*<IconBullet type="subheader" />*/} {subheader}
                    </ListSubheader>

                    {items
                      .filter((item) => item.isActive)
                      .map((item) => (
                        <NextMuiLink key={item.title} href={item.path}>
                          <ListItem
                            underline="none"
                            sx={{
                              typography: "body1",
                              color: "text.primary",
                              p: 0,
                              "&:hover": {
                                color: "primary.main",
                                textDecoration: "underline",
                              },
                              ...(item.path === pathname && {
                                typography: "subtitle2",
                                color: "text.primary",
                              }),
                            }}
                          >
                            {
                              <ListItemButton>
                                {/*<IconBullet />*/}
                                {item.title}
                              </ListItemButton>
                            }
                          </ListItem>
                        </NextMuiLink>
                      ))}
                  </List>
                </Grid>
              );
            })}
          </Grid>
        </Popover>
      </div>
    );
  }
  return (
    <NextMuiLinkStyle
      key={title}
      href={path ?? "/"}
      passHref
      underline="none"
      sx={{
        color: "text.primary",
        ...(isHome && { color: "text.primary" }),
        ...(isOffset && { color: "text.secondary" }),
        ...(isActive && { color: "primary.main" }),
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
        {title}
      </Typography>
    </NextMuiLinkStyle>
  );
}

MenuDesktop.propTypes = {
  isOffset: PropTypes.bool,
  isHome: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function MenuDesktop({ isOffset, isHome, navConfig }) {
  const { pathname, asPath } = useRouter();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    if (open) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <Stack direction="row" spacing={{ md: 10, sm: 5, xs: 2 }} sx={{ mr: 3 }}>
      {navConfig?.map((link) => (
        <MenuDesktopItem
          key={link.title}
          item={link}
          pathname={asPath}
          isOpen={open}
          onOpen={handleOpen}
          onClose={handleClose}
          isOffset={isOffset}
          isHome={isHome}
          anchorEl={anchorEl}
        />
      ))}
    </Stack>
  );
}
