// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
export const processFeaturesAccessCheck = (checkAccessRules) => {
  let accessCheckRules = {};
  accessCheckRules.canViewDocketSummary = checkAccessRules({
    entity: "DOCKET",
    action: "VIEW-DOCKET",
    featureName: "DOCKET-SUMMARY",
    isInvitee: true,
  }).accessFlag;
  accessCheckRules.canViewDocketAgenda = checkAccessRules({
    entity: "DOCKET",
    action: "VIEW-AGENDA",
    featureName: "DOCKET-AGENDA",
    isInvitee: true,
  }).accessFlag;
  accessCheckRules.canViewDocketChat = checkAccessRules({
    entity: "DOCKET",
    action: "CREATE-CHAT",
    featureName: "DOCKET-CHAT",
    isInvitee: true,
  }).accessFlag;
  accessCheckRules.canViewDocketInfoPack = checkAccessRules({
    entity: "DOCKET",
    action: "VIEW-FILES-INFOPACK",
    featureName: "DOCKET-INFOPACK",
    isInvitee: true,
  }).accessFlag;
  accessCheckRules.canViewDocketResolutions = checkAccessRules({
    entity: "RESOLUTIONS",
    action: "LIST-RESOLUTIONS",
    featureName: "DOCKET-RESOLUTIONS",
    isInvitee: true,
  }).accessFlag;
  accessCheckRules.canViewDocketMinutes = checkAccessRules({
    entity: "MEETINGS",
    action: "VIEW-MINUTES",
    featureName: "DOCKET-MINUTES",
    isInvitee: true,
  }).accessFlag;
  accessCheckRules.canViewDocketActions = checkAccessRules({
    entity: "ACTIONS",
    action: "VIEW-ACTIONS",
    featureName: "DOCKET-ACTIONS",
    isInvitee: true,
  }).accessFlag;
  accessCheckRules.canViewDocketRecords = checkAccessRules({
    entity: "MEETINGS",
    action: "VIEW-RECORDS",
    featureName: "DOCKET-RECORDS",
    isInvitee: true,
  }).accessFlag;
  return accessCheckRules;
};
