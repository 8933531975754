// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import { SvgIcon } from "@mui/material";

export default function JoinVideocallIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 450 350"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g>
        <path d="M376.25,327.75c0,3.176-2.574,5.75-5.75,5.75h-161c-3.176,0-5.75-2.574-5.75-5.75s2.574-5.75,5.75-5.75h23v-23h115v23h23   C373.676,322,376.25,324.574,376.25,327.75z" />
        <path d="M140.5,241.5v17.25c0,15.879,12.872,28.75,28.75,28.75h241.5c15.878,0,28.75-12.871,28.75-28.75V241.5H140.5z" />
        <path d="M249.75,143.75V230h80.846v-86.25H249.75z M318.75,212.75c0,3.176-2.574,5.75-5.75,5.75h-40.25   c-3.176,0-5.75-2.574-5.75-5.75v-2.875c0.005-8.389,4.078-16.256,10.925-21.103c-7.482-8.304-6.816-21.102,1.488-28.585   s21.102-6.817,28.585,1.487c6.938,7.7,6.938,19.397,0,27.098c6.805,4.865,10.815,12.737,10.752,21.103V212.75z" />
        <path d="M140.5,143.75V230h97.75v-86.25H140.5z M221,212.75c0,3.176-2.574,5.75-5.75,5.75H175c-3.176,0-5.75-2.574-5.75-5.75   v-2.875c0.005-8.389,4.078-16.256,10.925-21.103c-7.482-8.304-6.816-21.102,1.488-28.585s21.102-6.817,28.585,1.487   c6.938,7.7,6.938,19.397,0,27.098c6.805,4.865,10.816,12.737,10.752,21.103V212.75z" />
        <path d="M342.096,143.75V230H439.5v-86.25H342.096z M410.75,212.75c0,3.176-2.574,5.75-5.75,5.75h-40.25   c-3.176,0-5.75-2.574-5.75-5.75v-2.875c0.006-8.389,4.078-16.256,10.925-21.103c-7.482-8.304-6.816-21.102,1.487-28.585   c8.305-7.483,21.103-6.817,28.585,1.487c6.938,7.7,6.938,19.397,0,27.098c6.805,4.865,10.816,12.737,10.753,21.103V212.75z" />
        <path d="M410.75,34.5h-68.654v97.75H439.5v-69C439.5,47.372,426.628,34.5,410.75,34.5z M410.75,115.288   c0,3.175-2.574,5.75-5.75,5.75h-40.25c-3.176,0-5.75-2.575-5.75-5.75v-2.875c-0.004-8.392,4.07-16.262,10.925-21.103   c-7.482-8.304-6.816-21.102,1.487-28.585c8.305-7.483,21.103-6.817,28.585,1.487c6.938,7.7,6.938,19.397,0,27.098   c6.805,4.865,10.816,12.738,10.753,21.103V115.288z" />
        <path d="M249.75,34.5v97.75h80.846V34.5H249.75z M318.75,115.288c0,3.175-2.574,5.75-5.75,5.75h-40.25   c-3.176,0-5.75-2.575-5.75-5.75v-2.875c-0.004-8.392,4.07-16.262,10.925-21.103c-7.482-8.304-6.816-21.102,1.488-28.585   s21.102-6.817,28.585,1.487c6.938,7.7,6.938,19.397,0,27.098c6.805,4.865,10.815,12.738,10.752,21.103V115.288z" />
        <path d="M169.25,34.5c-15.878,0-28.75,12.872-28.75,28.75v69h97.75V34.5H169.25z M221,115.288c0,3.175-2.574,5.75-5.75,5.75H175   c-3.176,0-5.75-2.575-5.75-5.75v-2.875c-0.004-8.392,4.07-16.262,10.925-21.103c-7.482-8.304-6.816-21.102,1.488-28.585   s21.102-6.817,28.585,1.487c6.938,7.7,6.938,19.397,0,27.098c6.805,4.865,10.816,12.738,10.752,21.103V115.288z" />
      </g>
    </SvgIcon>
  );
}
