// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Typography, Button, Collapse, ScopedCssBaseline } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { ShowAgenda } from "blocks/modules/Dockets/foundations";
import { checkIfNull, useMeetingsInDockets, useAppContext } from "@app21/core";
import { useDocketsContext } from "providers/DocketsProvider";
import dompurify from "dompurify";
import { useIntl } from "react-intl";
import toast from "react-hot-toast";

const DocketAgendaView = (props) => {
  const { viewMode } = props;
  const { selectedDocketId } = useAppContext();
  const { selectedDocket = {} } = useDocketsContext();

  const [viewAgenda, setViewAgenda] = React.useState(false);

  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });

  const lastMeeting = selectedDocket?.relatedMeetings?.slice(-1)[0];

  if (viewMode === "summary") {
    if (
      checkIfNull(lastMeeting?.agendaItems) &&
      checkIfNull(lastMeeting?.uploadedAgenda)
    ) {
      return (
        <Typography variant="body1">
          {" "}
          {`${t("DocketDashboard.ShowAgenda.noAgendaMessage")}`}{" "}
        </Typography>
      );
    } else
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {lastMeeting?.uploadedAgenda ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="body1">{`${t(
                  "DocketDashboard.ShowAgenda.agendaUploadedMessage"
                )}`}</Typography>
                <Button
                  onClick={() => setViewAgenda(!viewAgenda)}
                  variant="outlined"
                  sx={{ marginLeft: (theme) => theme.spacing(1) }}
                  endIcon={
                    !viewAgenda ? <KeyboardArrowDown /> : <KeyboardArrowUp />
                  }
                >
                  <Typography variant="body1" style={{ textTransform: "none" }}>
                    {`${t("DocketDashboard.ShowAgenda.viewAgenda")}`}
                  </Typography>
                </Button>
              </div>
              <Collapse
                in={viewAgenda}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: (theme) => theme.spacing(1),
                }}
              >
                <div
                  style={{ padding: 5 }}
                  dangerouslySetInnerHTML={{
                    __html: dompurify.sanitize(
                      lastMeeting?.uploadedAgenda || " "
                    ),
                  }}
                />

                {/*ReactHtmlParser(dompurify.sanitize(relatedMeeting?.uploadedAgenda || ' '))
                  </div>*/}
              </Collapse>
            </div>
          ) : (
            <ShowAgenda viewMode={"summary"} selectedMeeting={lastMeeting} />
          )}
        </div>
      );
  }
  return (
    <div style={{ weight: "100%", height: "100%" }}>
      <ShowAgenda viewMode={viewMode} selectedMeeting={lastMeeting} />
    </div>
  );
};

export default DocketAgendaView;
