import React, { useRef } from "react";
import { Box, Container, Typography, Grid, styled } from "@mui/material";
import FileViewer from "blocks/modules/FileViewer";
import { checkIfNull, useAppContext } from "@app21/core";
import { FlowBox } from "blocks/atoms/uistyles";
import { useRecordingsContext } from "providers/RecordingsProvider";
import { ShowAllTranscriptionsForMeeting } from "blocks/modules/MeetingRecords/features";
import { LoadingSpinner } from "blocks/atoms";

export default function RecordingsCenterPanel({ isMobile = false }) {
  const { viewFile } = useAppContext();
  const {
    viewContext,
    actionContext,
    selectedMeetingId,
    transcriptionsStatus,
    transcriptions,
    searchContent,
    filteredMeetings,
    handleCloseTranscript,
    useMeetingsInSuiteStatus,
  } = useRecordingsContext();
  const fileViewerFlag = !checkIfNull(viewFile);

  if (useMeetingsInSuiteStatus === "loading") return <LoadingSpinner />;

  return (
    <FlowBox sx={{ gap: 1 }}>
      {fileViewerFlag ? (
        <FileViewer viewContext={viewContext} />
      ) : (
        <FlowBox
          sx={{
            overflow: "hidden",
          }}
        >
          <FlowBox
            sx={{
              overflow: "hidden",
            }}
          >
            {transcriptionsStatus?.isLoading ? (
              <LoadingSpinner variant="exp" />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  flexGrow: 1,
                  height: "100%",
                  overflow: "hidden",
                }}
              >
                {transcriptions && (
                  <ShowAllTranscriptionsForMeeting
                    inputSearchString={searchContent}
                    handleBackActionClick={handleCloseTranscript}
                    noRecordingsFlag={filteredMeetings?.length === 0}
                  />
                )}
              </Box>
            )}
          </FlowBox>
        </FlowBox>
      )}
    </FlowBox>
  );
}
