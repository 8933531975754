import React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListSubheader,
} from "@mui/material";
import { CheckBoxTwoTone } from "@mui/icons-material";
import { TickIconSquare } from "blocks/atoms/trrysticons";
import { isMobile } from "react-device-detect";

export default function RenderBulletedHeadlineList({
  title = "",
  listItems = [],
  ListProps = {},
  ListSubheaderTypographyProps = {},
  ListItemProps = {},
  ListItemTypographyProps = {},
  ...rest
}) {
  return (
    <List {...ListProps} {...rest}>
      {title && (
        <ListSubheader
          disableGutters
          sx={{
            bgcolor: "transparent",
            display: "flex",
            justifyContent: "flex-start",
            mb: isMobile ? 1 : 3,
          }}
        >
          <Typography
            variant={isMobile ? "h4" : "h3"}
            sx={{ color: "text.primary", ...ListSubheaderTypographyProps }}
          >
            {title}
          </Typography>
        </ListSubheader>
      )}
      {listItems.map((item, index) => (
        <ListItem key={index} disableGutters {...ListItemProps}>
          <ListItemIcon sx={{ my: -1 }}>
            <TickIconSquare fontSize="large" />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              variant: "h5",
              ...ListItemTypographyProps,
            }}
            primary={item}
          />
        </ListItem>
      ))}
    </List>
  );
}
