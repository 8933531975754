import * as React from "react";
import {
  Button,
  alpha,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTasksContext } from "providers/TasksProvider";

export default function TaskCardActionMenuButton({ taskObj }) {
  const { handleTaskSave } = useTasksContext();

  const buttonOptions = [
    { value: "open", label: "Open", action: () => null },
    { value: "wip", label: "In-Progress", action: () => null },
    { value: "completed", label: "Completed", action: () => null },
  ];
  const selectedOption =
    buttonOptions.find((option) => option.value === taskObj?.status) ??
    buttonOptions[0];

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleOpenPopper = (option) => {
    setOpen(true);
  };

  const handleMenuItemClick = async (event, value) => {
    await handleTaskSave({ ...taskObj, status: value }, taskObj._id);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant="text"
        sx={{
          backgroundColor: (theme) => alpha(theme.palette.primary.lighter, 0.2),
          borderRadius: 2,

          "& .MuiButtonGroup-grouped:not(:last-of-type)": {
            borderRight: "none",
            p: 0,
            pl: 1,
            m: 0,
          },
          "& .MuiButtonGroup-grouped:last-of-type": {
            p: 0,
            m: 0,
            minWidth: 25,
            width: 25,
            maxWidth: 25,
          },
        }}
        ref={anchorRef}
        aria-label="split button"
        size="small"
      >
        <Button size="small" onClick={handleToggle} sx={{ borderRadius: 2 }}>
          {selectedOption.label}{" "}
        </Button>
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select task status"
          aria-haspopup="menu"
          onClick={handleToggle}
          sx={{ borderRadius: 2 }}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {buttonOptions.map((option, index) => (
                    <MenuItem
                      key={option.value}
                      selected={index === "wip"}
                      onClick={(event) =>
                        handleMenuItemClick(event, option.value)
                      }
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
