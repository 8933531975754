// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  IconButton,
  SvgIcon,
  Button,
  TextField,
  Chip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { GroupAdd, Delete, Send } from "@mui/icons-material";
import {
  XCircle as CloseIcon,
  Eye as EyeIcon,
  EyeOff as EyeOffIcon,
} from "react-feather";
import { useIntl } from "react-intl";
import Details from "./Details";
import Checklist from "./Checklist";
import Comment from "./Comment";
import CommentAdd from "./CommentAdd";
import ActionButton from "./ActionButton";
import {
  populateUsers,
  useDocketsInSuite,
  useUsersInSuite,
  useDockets,
  useAppContext,
  getRolePrivileges,
  checkIfNull,
  createSelectOptions,
  useHandleTaskActions,
} from "@app21/core";
import CompletionSlider from "blocks/modules/Tasks/foundations/TaskEditModal/CompletionSlider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import MuiSelectFormField from "blocks/atoms/MuiSelectFormField";
import { useConfirm } from "material-ui-confirm";
import {
  FormProvider,
  useForm,
  useFieldArray,
  Controller,
} from "react-hook-form";
import { LoadingSpinner, MuiSelectDocketFormField } from "blocks/atoms";
import { stringPurify } from "utils/inputUtils";
import isEmpty from "validator/lib/isEmpty";

const statusOptions = [
  { value: "pending", label: "Planned Task" },
  { value: "wip", label: "Work In Progress" },
  { value: "completed", label: "Completed" },
];
const TaskEditModal = ({
  taskData = {},
  taskGroupData = "pending",
  context,
  onClose,
  taskId,
  open,
  ...rest
}) => {
  const { selectedSuiteId, checkAccessRules, selectedDocketId } =
    useAppContext();
  const {
    data: usersInSuite,
    apiError: usersInSuiteError,
    status: usersInSuiteStatus,
  } = useUsersInSuite(selectedSuiteId);
  const {
    data: docketsInSuite,
    apiError: docketInSuiteError,
    status: docketsInSuiteStatus,
  } = useDocketsInSuite(selectedSuiteId);
  const {
    data: selectedDocket,
    apiError: selectedDocketApiError,
    status: selectedDocketStatus,
  } = useDockets(taskData?.docketId);
  const usersInDocket = populateUsers(selectedDocket?.members, usersInSuite);
  const { userInfo } = useAppContext();

  const { handleTaskActions } = useHandleTaskActions();
  const confirm = useConfirm();
  const [showTaskMembersFlag, setShowTaskMembersFlag] = React.useState(false);
  const [watchers, setWatchers] = React.useState(taskData?.watchers ?? []);
  const [dirtyFlag, setDirtyFlag] = React.useState(false);

  const dataFetchError =
    usersInSuiteError || docketInSuiteError || selectedDocketApiError;

  const dataFetchLoading =
    usersInSuiteStatus === "loading" ||
    docketsInSuiteStatus === "loading" ||
    selectedDocketStatus === "loading";

  React.useEffect(() => {
    if (dataFetchError && !dataFetchLoading) {
      toast.error(dataFetchError.message);
    }
  }, [dataFetchError]);

  const intl = useIntl();
  const t = (id) => intl.formatMessage({ id });

  const { accessFlag: canDeleteAction } = checkAccessRules({
    entity: context === "docket" ? "DOCKET" : "ACTIONS",
    action: context === "docket" ? "DELETE-DOCKET-ACTIONS" : "DELETE-ACTIONS",
    featureName: context === "docket" ? "DOCKET-ACTIONS" : "ACTIONS",
    ...getRolePrivileges({
      userId: userInfo._id,
      baseObject: taskData,
      members: taskData?.assignees ?? [],
    }),
  });

  let populatedAssignees = createSelectOptions(
    populateUsers(taskData?.assignees, usersInSuite)
  );

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      title: taskData?.title || "",
      docketId: taskData?.docketId || selectedDocketId || null,
      description: taskData?.description || "",
      dueBy: taskData?.dueBy || null,
      status: taskData?.status || "pending",
      assignees: populatedAssignees ?? [],
      watchers: taskData?.watchers ?? [],
      comments: taskData?.comments ?? [],
      completionPercentage: taskData?.completionPercentage ?? 0,
      newComment: "",
    },
  });
  const {
    handleSubmit,
    control,
    formState,
    reset,
    getValues,
    setValue,
    watch,
  } = methods;
  const { isDirty, isSubmitting, isValid, errors } = formState;
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: "comments",
    }
  );

  function getTaskGroupTitle(taskGroupData) {
    switch (taskGroupData) {
      case "pending":
        return "Planned Tasks";
      case "wip":
        return " Work In Progress Tasks";
      case "completed":
        return "Completed Tasks";
      default:
        return "Tasks";
    }
  }
  const resetForm = () => {
    setShowTaskMembersFlag(false);
    setWatchers([]);
    setDirtyFlag(false);
    reset();
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const onSubmit = async (formData) => {
    const modFormData = { ...formData };

    modFormData.title = stringPurify(formData.title);
    modFormData.description = stringPurify(formData.description);

    if (!isEmpty(formData.newComment, { ignore_whitespace: true })) {
      modFormData.comments.unshift({
        who: userInfo._id,
        comment: stringPurify(formData.newComment),
        when: Date.now(),
      });
    }
    modFormData.watchers = watchers;
    await handleTaskActions(
      { taskId: taskId, taskData: modFormData },
      null,
      null
    );
    handleClose();
  };

  const handleSubscribeToggle = () => {
    let index = watchers.findIndex((x) => x === userInfo._id);
    setDirtyFlag(true);
    if (index === -1) {
      setWatchers((watchers) => [...watchers, userInfo._id]);
    } else {
      const newWatchers = watchers.filter((id) => id !== userInfo._id);
      setWatchers(newWatchers);
    }
  };

  const handleDelete = async () => {
    confirm({
      description: "Do you want to delete the task?",
    })
      .then(async () => {
        await handleTaskActions(
          { action: "DELETE-TASK", taskId: taskId },
          null,
          null
        );

        handleClose();
      })
      .catch((err) => {
        console.log("Deletion cancelled.", err);
      });
  };

  const handleFormClose = () => {
    if (dirtyFlag || isDirty) {
      confirm({
        description: "You have some unsaved changes. Do you want to exit?",
      })
        .then(() => {
          handleClose();
        })
        .catch((err) => {
          console.log("Close cancelled.", err);
        });
    } else {
      handleClose();
    }
  };
  console.log("date", watch("dueBy"));
  return (
    <FormProvider {...methods}>
      <Dialog
        sx={{ maxHeight: "calc(100vh - 1px)" }}
        onClose={handleFormClose}
        open={open}
        maxWidth="md"
        PaperProps={{
          sx: { position: "relative", pb: 40, overflow: "hidden" },
        }}
        fullWidth
        {...rest}
      >
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>{`From your ${getTaskGroupTitle(taskGroupData)} group`}</span>
            <IconButton onClick={handleFormClose}>
              <SvgIcon>
                <CloseIcon />
              </SvgIcon>
            </IconButton>
          </Box>
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DialogContent
              sx={{
                maxHeight: `calc(100vh - 250px)`,
                m: 1,
                p: 1,
                overflow: "auto",
                p: (theme) => theme.spacing(2),
                pb: (theme) => theme.spacing(5),
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} sm={8}>
                  <Details control={control} errors={errors} />
                  <Box
                    sx={{
                      mt: 2,
                    }}
                  >
                    <Typography variant="overline" color="textSecondary">
                      Task Members
                    </Typography>
                    <Box
                      sx={{
                        mt: 2,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        disabled={
                          showTaskMembersFlag || taskData?.assignees?.length > 0
                        }
                        onClick={() => setShowTaskMembersFlag(true)}
                      >
                        <GroupAdd fontSize="inherit" />
                      </IconButton>
                      <Box
                        sx={{
                          flexGrow: 1,
                        }}
                      >
                        {(showTaskMembersFlag ||
                          taskData?.assignees?.length > 0) && (
                          <Controller
                            control={control}
                            name={"assignees"}
                            render={({ field: { ref, ...rest } }) => (
                              <MuiSelectFormField
                                listOfUsers={
                                  context === "docket"
                                    ? usersInDocket
                                    : usersInSuite
                                }
                                refs={ref}
                                placeholder="Add to Task"
                                limitTags={10}
                                allowGuests={false}
                                inputValue={""}
                                variant="outlined"
                                {...rest}
                                onChange={(data) => {
                                  setValue("assignees", data, {
                                    shouldDirty: true,
                                  });
                                }}
                                errors={errors?.assignees}
                              />
                            )}
                            rules={{
                              required: {
                                value: false,
                                message:
                                  "Atleast one person needs to be added to the Task .",
                              },
                            }}
                          />
                        )}
                      </Box>
                    </Box>
                  </Box>
                  {taskData?.checklists?.length > 0 && (
                    <Box
                      sx={{
                        mt: 5,
                      }}
                    >
                      {taskData?.checklists?.map((checklist) => (
                        <Checklist
                          key={checklist?.id}
                          card={taskData}
                          checklist={checklist}
                          style={{
                            "& + &": {
                              marginTop: (theme) => theme.spacing(3),
                            },
                          }}
                        />
                      ))}
                    </Box>
                  )}

                  <Box
                    sx={{
                      mt: 2,
                    }}
                  >
                    <Typography variant="overline" color="textSecondary">
                      Activity
                    </Typography>
                    <Box
                      sx={{
                        mt: 2,
                      }}
                    >
                      <CommentAdd prepend={prepend} userInfo={userInfo} />
                      {fields?.length > 0 && (
                        <Box
                          sx={{
                            mt: 3,
                          }}
                        >
                          {fields.map((comment, index) => (
                            <Comment key={comment.id} comment={comment} />
                          ))}
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Typography variant="overline" color="textSecondary">
                    {`Link to a Breakout`}
                  </Typography>

                  <Controller
                    name={"docketId"}
                    control={control}
                    rules={{
                      required: { value: false, message: "Optional" },
                    }}
                    render={({ field: { ref, ...rest } }) => (
                      <MuiSelectDocketFormField
                        refs={ref}
                        listOfDockets={docketsInSuite}
                        label=""
                        placeholder="Link to a Breakout (optional)"
                        inputValue={""}
                        {...rest}
                        onChange={(data) => {
                          let selectedDocket = docketsInSuite?.find(
                            (docket) => docket._id === data
                          );
                          setValue("docketId", data, { shouldDirty: true });
                        }}
                        variant="outlined"
                        sx={{ my: 1 }}
                      />
                    )}
                  />

                  <Typography variant="overline" color="textSecondary">
                    {`${t("SuiteDashboard.Tasks.status")}`}
                  </Typography>
                  <Controller
                    name="dueBy"
                    control={control}
                    render={({ field: { ref, ...rest } }) => (
                      // DatePicker
                      <DatePicker
                        label="Due by Date"
                        sx={{
                          marginRight: (theme) => theme.spacing(2),
                          "&:last-child": {
                            marginRight: 0,
                          },
                          width: "100%",
                          marginBottom: (theme) => theme.spacing(2),
                        }}
                        format={"dd/MM/yyyy"}
                        // componentsProps={{
                        //   textField: {
                        //     margin: "normal",
                        //     fullWidth: true,
                        //     error: !!errors?.dueBy,
                        //     helperText: errors?.dueBy?.message,
                        //     variant: "outlined",
                        //     InputProps: {
                        //       sx: {
                        //         backgroundColor: (theme) =>
                        //           theme.palette.background.default,
                        //       },
                        //     },
                        //   },
                        // }}
                        {...rest}
                      />
                    )}
                  />
                  {/*<DueByDatePicker currentDueByDate={dueBy} handleDueDateChange={handleDueDateChange} />
                
            <ActionButton icon={<FileIcon />} disabled>
              Attachments
            </ActionButton>*/}
                  <Box
                    sx={{
                      mt: 1,
                    }}
                  >
                    <Typography
                      variant="overline"
                      color="textSecondary"
                      sx={{ my: 2 }}
                    >
                      {`${t("SuiteDashboard.Tasks.actions")}`}
                    </Typography>
                    <Controller
                      control={control}
                      name="status"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          error={!!errors?.status}
                          helperText={errors?.status?.message}
                          size="small"
                          margin="dense"
                          label="Change Status"
                          select
                          SelectProps={{ native: true }}
                          sx={{ mt: 2 }}
                        >
                          {statusOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </TextField>
                      )}
                    />

                    {getValues("status") === "wip" && (
                      <CompletionSlider
                        control={control}
                        errors={errors}
                        name={"completionPercentage"}
                      />
                    )}
                    {!(
                      userInfo._id === taskData?.createdBy ||
                      taskData?.assignees?.includes(userInfo._id)
                    ) &&
                      !checkIfNull(taskData) &&
                      (watchers?.includes(userInfo._id) ? (
                        <ActionButton
                          icon={<EyeOffIcon />}
                          color="accent"
                          onClick={handleSubscribeToggle}
                        >
                          {`${t("SuiteDashboard.Tasks.unwatch")}`}
                        </ActionButton>
                      ) : (
                        <ActionButton
                          icon={<EyeIcon />}
                          onClick={handleSubscribeToggle}
                        >
                          {`${t("SuiteDashboard.Tasks.watch")}`}
                        </ActionButton>
                      ))}
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              sx={{
                position: "absolute",
                bottom: 0,
                height: 60,
                width: "100%",
                background: "rgba(255,255,255, 0.5)",
                p: 1,
                pr: 3,
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {canDeleteAction ? (
                  <IconButton onClick={handleDelete}>
                    <Delete fontSize="inherit" />
                  </IconButton>
                ) : (
                  <div></div>
                )}
                <div style={{ display: "flex" }}>
                  <Button size="small" onClick={handleFormClose}>
                    {`${t("global.buttons.cancel")}`}
                  </Button>
                  <LoadingButton
                    type={"submit"}
                    size="small"
                    variant="contained"
                    loadingPosition="start"
                    startIcon={<Send />}
                    disabled={!isValid || !(dirtyFlag || isDirty)}
                    loading={isSubmitting}
                    onClick={handleSubmit(onSubmit)}
                  >
                    Save and Exit
                  </LoadingButton>
                </div>
              </Box>
            </DialogActions>
          </LocalizationProvider>
        </form>
      </Dialog>
    </FormProvider>
  );
};

TaskEditModal.propTypes = {
  taskData: PropTypes.object,
  className: PropTypes.string,
  taskGroupData: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
};

TaskEditModal.defaultProps = {
  open: false,
  taskData: {},
  onClose: () => {
    //
  },
};

export default TaskEditModal;
