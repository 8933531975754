import React from 'react'
import { SvgIcon } from '@mui/material';

export default function MyTaskIcon(props) {
  return (
    <SvgIcon
      viewBox="0 0 109 109"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M51.5416 45.9995H73.4166"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.5834 45.9995L29.7084 49.1245L39.0834 39.7495"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M51.5416 75.166H73.4166"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.5834 75.166L29.7084 78.291L39.0834 68.916"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.33337 62.9585V71.5002C8.33337 92.3335 16.6667 100.667 37.5 100.667H62.5C83.3334 100.667 91.6667 92.3335 91.6667 71.5002V46.5002C91.6667 25.6668 83.3334 17.3335 62.5 17.3335H37.5C16.6667 17.3335 8.33337 25.6668 8.33337 46.5002"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
