import React from "react";
import {
  useHandleRouterPush,
  checkIfNull,
  useAppContext,
  useSelectedBucketId,
  useUserInfo,
  app21APIInstance,
  useUpdateFileInChannel,
} from "@app21/core";
import { Box, List, Divider } from "@mui/material";
import { useChatContext } from "providers/TrrystChatProvider";
import ShowAttachmentItem from "./ShowAttachmentItem";
import PerfectScrollbar from "react-perfect-scrollbar";
import { search } from "fast-fuzzy";
import {
  SearchFilterAtom,
  CurrentChannelAtom,
} from "blocks/modules/TrrystChat/state-atoms";
import { useAtom } from "jotai";
import { useIsMobileBreakpoint } from "hooks";
import { usePubNub } from "pubnub-react";

const ShowChatGroupAttachments = ({ selectedChannel }) => {
  const { setSidebarActive } = useChatContext();
  const { loadRoute } = useHandleRouterPush();
  const [searchFilter] = useAtom(SearchFilterAtom);
  const isMobile = useIsMobileBreakpoint(480, true);

  const handleFileView = (s3Key) => {
    s3Key &&
      loadRoute("VIEW-CHATFILE", {
        chatId: selectedChannel?.id,
        s3Key: s3Key,
      });
    setSidebarActive(false);
  };
  const attachmentsList = selectedChannel?.files ?? [];

  const searchOptions = {
    keySelector: (obj) => [obj.text, obj?.customElements?.s3Key],
  };

  const filteredList = React.useMemo(
    () =>
      !checkIfNull(searchFilter)
        ? search(searchFilter, attachmentsList, searchOptions)
        : attachmentsList,
    [searchFilter, attachmentsList]
  );
  const { selectedSuiteId } = useAppContext();
  const { data: userInfo } = useUserInfo();
  const selectedBucketId = useSelectedBucketId(null, true);
  const [currentChannel] = useAtom(CurrentChannelAtom);
  const updateFileInChatChannel = useUpdateFileInChannel();

  const pubnubChatClient = usePubNub();

  const handleFileDownload = async (file) => {
    const s3Key = file?.customElements?.s3Key ?? "";
    // this does not take an id as the file object that is stored in the
    // channels.files in MongoDB has all the needed information.
    const response = await app21APIInstance.post("/s3/fetchurl", {
      bucket: selectedBucketId,
      key: s3Key,
      urlTypeRequested: "get",
      contentDisposition: "attachment",
      calledBy: `${userInfo?._id}`,
    });
    window.open(response?.data?.signedUrl, "__blank", "noopener noreferrer");
  };

  const handleFileAndAssociatedMessageDelete = async (file) => {
    // delete the file from our channels collection

    // update the chat channel with this file deletion.
    await updateFileInChatChannel.mutate({
      message: file,
      channelId: currentChannel,
      action: "REMOVE",
      additionalData: { suiteId: selectedSuiteId },
    });

    // add a action on the associated message as deleted
    pubnubChatClient.addMessageAction({
      channel: currentChannel,
      messageTimetoken: file.timetoken,
      action: { type: "deleted", value: "deletedBy" },
    });
  };

  return (
    <PerfectScrollbar
      options={{ suppressScrollX: true }}
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        flexGrow: 1,
      }}
    >
      <List dense>
        {filteredList.length > 0
          ? filteredList.map((file) => (
              <ShowAttachmentItem
                key={file.id}
                file={file}
                isMobile={isMobile}
                handleFileView={handleFileView}
                handleFileDownload={handleFileDownload}
                handleFileAndAssociatedMessageDelete={
                  handleFileAndAssociatedMessageDelete
                }
              />
            ))
          : "No Attachments to display"}
      </List>
    </PerfectScrollbar>
  );
};

export default React.memo(ShowChatGroupAttachments);
