import React, { Fragment } from "react";
import {
  Box,
  Typography,
  Paper,
  Button,
  IconButton,
  Stack,
  Avatar,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Grid,
} from "@mui/material";
import { FlowBox } from "blocks/atoms/uistyles";
import Slider from "react-slick";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  processSurveyData,
  ShowSurveyBarChart,
  ShowSurveyResultsTable,
} from "blocks/modules/Surveys/foundations";

import { UserAvatar } from "blocks/atoms";
import { useAppContext, useUsersInSuite, checkIfNull } from "@app21/core";

export default function ShowSurveyResultsChartMode({ selectedDecision = {} }) {
  const { selectedSuiteId } = useAppContext();
  const { data: usersInSuite, status: usersInSuiteStatus } =
    useUsersInSuite(selectedSuiteId);
  let chartCarouselRef = React.useRef(null);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const handleIndexChange = (index) => setActiveIndex(index);

  const questionsInfo = selectedDecision?.survey?.questionBlock ?? [];
  const answersInfo = [];
  for (const id in selectedDecision?.responses) {
    let newObj = {};
    newObj.respondentId = id;
    newObj.answers = selectedDecision?.responses[id];

    answersInfo.push(newObj);
  }

  let processedSurveyData = processSurveyData(
    questionsInfo,
    answersInfo,
    usersInSuite
  );

  const handlePrevious = async () => {
    chartCarouselRef.current?.slickGoTo(activeIndex - 1);
    handleIndexChange(activeIndex - 1);
  };

  const handleNext = async () => {
    chartCarouselRef.current?.slickGoTo(activeIndex + 1);
    handleIndexChange(activeIndex + 1);
  };

  const sliderProps = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    lazyLoad: true,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };
  return (
    <FlowBox>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          height: "100%",
          p: 2,
        }}
      >
        <Slider
          ref={(el) => {
            chartCarouselRef.current = el;
          }}
          {...sliderProps}
          style={{
            minHeight: 200,
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            height: "100%",

            paddingBottom: 10,
          }}
        >
          {processedSurveyData.map((question, index) => {
            return (
              <FlowBox
                key={index}
                sx={{
                  maxHeight: "65vh",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    display: "flex",

                    justifyContent: "space-between",
                    my: 2,
                    py: 2,
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Avatar
                      sx={{
                        mr: 2,
                        border: (theme) =>
                          `1px solid ${theme.palette.primary.main}`,

                        color: "primary.main",
                        height: 25,
                        width: 25,
                        fontSize: 13,
                      }}
                    >
                      {index + 1}
                    </Avatar>
                    <Typography variant="h6" color="textSecondary">
                      {question.questionItem}
                    </Typography>
                  </Box>
                  <Stack direction="row" spacing={2}>
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={handlePrevious}
                      disabled={activeIndex <= 0}
                    >
                      Previous
                    </Button>
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={handleNext}
                      disabled={activeIndex >= processedSurveyData.length - 1}
                    >
                      Next
                    </Button>
                  </Stack>
                </Box>
                <FlowBox>
                  <PerfectScrollbar
                    options={{
                      suppressScrollX: true,
                      scrollXMarginOffset: 20,
                      scrollYMarginOffset: 10,
                    }}
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      flexGrow: 1,
                      paddingRight: 10,
                    }}
                  >
                    <Grid container spacing={3} sx={{ maxHeight: "100%" }}>
                      <Grid item xs={12} md={7}>
                        <Box sx={{ maxHeight: 300 }}>
                          <ShowSurveyBarChart compositeData={question} />
                        </Box>
                        <ShowSurveyResultsTable question={question} />
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <FlowBox>
                          <Typography variant="h6" color="textSecondary">
                            Comments
                          </Typography>
                          {question?.answers?.map((answer, commentIndex) => {
                            if (!checkIfNull(answer.answer.otherComments)) {
                              return (
                                <Box
                                  key={commentIndex}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",

                                    my: 2,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <UserAvatar
                                      size={20}
                                      userId={answer?.respondent?._id}
                                    />
                                    <Typography
                                      variant="caption"
                                      color="text.secondary"
                                      sx={{ ml: 1 }}
                                    >
                                      {answer?.respondent?.fullName}
                                    </Typography>
                                  </Box>

                                  <Typography sx={{ pl: 5 }} variant="body2">
                                    {answer?.answer?.otherComments}
                                  </Typography>
                                </Box>
                              );
                            } else return <Fragment key={commentIndex} />;
                          })}
                        </FlowBox>
                      </Grid>
                    </Grid>
                  </PerfectScrollbar>
                </FlowBox>
              </FlowBox>
            );
          })}
        </Slider>
      </Paper>
    </FlowBox>
  );
}
