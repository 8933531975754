// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import {
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Tooltip,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { AddBox } from "@mui/icons-material";
import React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import ObjectID from "bson-objectid";
import LoadingSpinner from "blocks/atoms/LoadingSpinner";
import EditableImage from "blocks/atoms/EditableImage";
import {
  checkIfNull,
  useUserInfo,
  useHandleManageImage,
  useHandleOrganizationActions,
  useHandleRouterPush,
} from "@app21/core";
import isAlphanumeric from "validator/lib/isAlphanumeric";
import toast from "react-hot-toast";

export default function AddNewOrganization({
  handleMenuClose = () => {
    /*
     */
  },
}) {
  const { loadRoute } = useHandleRouterPush();

  const { data: userInfo, apiError: usersInfoApiError } = useUserInfo();
  const { handleManageImage } = useHandleManageImage();
  const { handleOrganizationActions, addOrganizationStatus } =
    useHandleOrganizationActions();
  const [open, setOpen] = React.useState(false);

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      fullName: "",
      imageFile: null,
    },
  });
  const { control, handleSubmit, formState, reset } = methods;
  const { errors, isValid, isSubmitting } = formState;

  React.useEffect(() => {
    if (usersInfoApiError) {
      toast.errorror(usersInfoApiError.message);
    }
  }, [usersInfoApiError]);

  const handleClickOpen = () => {
    handleMenuClose();
    setOpen(true);
  };

  const handleClickClose = () => {
    reset();
    setOpen(false);
  };

  {
    /*  const [initialOrgImage, setInitialOrgImage] = React.useState(null);

  React.useEffect(() => {
    if (initialOrgImage) return;
    setInitialOrgImage(`images/suitestockimages/suiteImage-${Math.ceil(Math.random() * 10)}.jpg`);
  }, []);

*/
  }
  const orgId = ObjectID().toString();

  const onSubmit = async (formData) => {
    let orgImageResult;
    if (!checkIfNull(formData?.imageFile)) {
      orgImageResult = await handleManageImage(
        {
          files: [formData.imageFile],
          imageType: "ORG-IMAGE",
          actionType: "ADDNEW",
          orgId: orgId,
        },
        null,
        null
      );
    }
    await handleOrganizationActions(
      {
        action: "ADD-ORGANIZATION",
        inputData: {
          data: {
            _id: orgId,
            image: orgImageResult?.data?.key || null,
            fullName: formData.fullName,
            createdBy: userInfo._id,
          },
        },
      },
      null,
      null
    );
    addOrganizationStatus.status !== "loading" &&
      loadRoute("MANAGE-ORGANIZATION", { orgId });

    // ideally the admin users for an organization should also be taken like we do in the add new suite
    // however since only global admins are going to be adding Organizations for now, we can do it as a
    // manual step post org create. Hence commenting this code for now.

    // In any case making ORG-CREATOR as ORGADMIN might not be correct, until we let users create
    // organizations themselves.

    // Update : 12/08/23. We now allow users to create their own organizations. So the need to have the
    // if check below is no longer needed.
    // if (userInfo?.role?.toUpperCase() !== "GLOBALADMIN") {
    await handleOrganizationActions(
      {
        action: "MAKE-ORG-CREATOR-AS-ORGADMIN",
        organizationId: orgId,
      },
      null,
      null
    );
    // }
    handleClickClose();
  };

  return (
    <div>
      <Tooltip title="Add New Organization" arrow>
        <IconButton onClick={handleClickOpen} color="secondary">
          <AddBox />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClickClose}
        fullWidth={true}
        maxWidth={"md"}
        aria-labelledby="Add New Organization"
      >
        <FormProvider {...methods}>
          <form>
            <DialogTitle id="form-dialog-title">
              Add a new Organization
            </DialogTitle>
            <DialogContent>
              <DialogContentText></DialogContentText>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                spacing={2}
                style={{ padding: 10 }}
              >
                <Grid item xs={12} md={4}>
                  <Controller
                    control={control}
                    name="imageFile"
                    rules={{ required: true }}
                    render={({ field }) => {
                      return (
                        <EditableImage
                          labelText="Upload Organization Logo"
                          currentImage={field.value}
                          handleFileUpload={field.onChange}
                          imageProps={{ sx: { height: 100, width: 100 } }}
                          sx={{ height: 130, width: 130 }}
                        />
                      );
                    }}
                  />
                  {/*<EditableImage
                    labelText="Upload Organization Logo"
                    currentImage={initialOrgImage}
                    handleCurrentImageChange={() => setInitialOrgImage(null)}
                    handleFileUpload={uploadedFiles => setFiles(uploadedFiles)}
                  />*/}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={7}
                  container
                  direction="column"
                  spacing={1}
                >
                  <Grid item>
                    <Controller
                      control={control}
                      name="fullName"
                      rules={{
                        required: {
                          value: true,
                          message: "A name for the Organization is needed.",
                        },
                        maxLength: {
                          value: 50,
                          message: "Sorry. Too long. Limit to 25 characters",
                        },
                        minLength: {
                          value: 3,
                          message: "Too short. Minimum 3 characters",
                        },
                        validate: {
                          validText: (value) =>
                            isAlphanumeric(value, "en-US", {
                              ignore: "/^(?=.*[0-9])[- +()0-9]+$/",
                            }),
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={!!errors.fullName}
                          helperText={
                            errors.fullName?.message ||
                            (errors?.fullName?.type === "validText" &&
                              "Special characters are not allowed")
                          }
                          autoFocus
                          fullWidth
                          margin="dense"
                          label="Name of the Organization"
                          type="name"
                          variant="standard"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClickClose}>Cancel</Button>
              <LoadingButton
                variant="contained"
                loading={addOrganizationStatus?.isLoading || isSubmitting}
                disabled={!isValid}
                onClick={handleSubmit(onSubmit)}
              >
                Create
              </LoadingButton>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </div>
  );
}
