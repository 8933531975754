// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { AvatarGroup } from "@mui/material";
import DisplayAttendeeAvatar from "./DisplayAttendeeAvatar";

const DisplayMeetingAttendeesList = ({ attendees = [] }) => {
  return (
    <AvatarGroup max={8}>
      {attendees.map((attendee) => {
        if (attendee?.emailId !== "app21chimerecorder@trryst.com") {
          return (
            <DisplayAttendeeAvatar
              key={attendee.ExternalUserId}
              id={attendee.ExternalUserId}
            />
          );
        }
      })}
    </AvatarGroup>
  );
};
export default DisplayMeetingAttendeesList;
