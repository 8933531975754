import * as React from "react";
import {
  Box,
  Breadcrumbs,
  Typography,
  Link,
  emphasize,
  Chip,
  styled,
} from "@mui/material";
import { Home } from "@mui/icons-material";
import { useHandleRouterPush } from "@app21/core";
import { useFilesContext } from "providers/FilesProvider";
import cliTruncate from "cli-truncate";

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,

    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.body2.fontSize,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

export default function FilePathCrumbs({ isMobile = false }) {
  const { folderChain } = useFilesContext();
  const { loadRoute } = useHandleRouterPush();

  function handleClick(event, node) {
    event.preventDefault();
    event.stopPropagation();

    if (node?.id) {
      let path = node.id;
      if (node.isDir) {
        if (!path.endsWith("/")) {
          path = node.id + "/";
        }

        loadRoute("VIEW-DATAROOM", {
          s3Key: path,
        });
      }
    }
  }
  return (
    <Box
      role="presentation"
      onClick={handleClick}
      sx={{
        flexGrow: 1,
        width: "100%",
      }}
    >
      <Breadcrumbs
        maxItems={2}
        aria-label="file path"
        separator="›"
        sx={{ "& .MuiBreadcrumbs-separator": { mx: 0 } }}
      >
        {folderChain.map((linkitem, index) => {
          return (
            <StyledBreadcrumb
              clickable
              key={index}
              {...(linkitem.folderChainIcon
                ? { icon: <linkitem.folderChainIcon fontSize="inherit" /> }
                : {})}
              size="small"
              label={cliTruncate(linkitem.name, 20, {
                position: "middle",
                preferTruncationOnSpace: true,
              })}
              onClick={(event) => handleClick(event, linkitem)}
            />
          );
        })}
      </Breadcrumbs>
    </Box>
  );
}
