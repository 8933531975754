// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
export const TEXTFIELDLIMITS = {
  suiteNameLength: 25,
  taskNameLength: 100,
  taskDescriptionLength: 5000,
  orgNameLength: 25,
};
export const SIZELIMITS = {
  objectImageMaxFileSize: 5000000,
  uploadMaxFileSize: 30000000,
};

export const LAYOUTWIDTHS = {
  TopbarHeight: 60,
  NavbarWidth: 230,
  NavbarCompactWidth: 70,
  DefaultRightbar: "22vw",
  compactVCWidth: 20,
  standardVCWidth: 40,
  featuredVCWidth: 100,
  chimeMenubarHeight: 75,
};

export function chimeGridsizeCalculator(
  meetingActiveFlag,
  fullScreenFlag,
  chimeViewMode
) {
  return meetingActiveFlag
    ? fullScreenFlag
      ? 100
      : chimeViewMode === "normal"
      ? LAYOUTWIDTHS.standardVCWidth
      : chimeViewMode === "featured" || chimeViewMode === "fullscreen"
      ? LAYOUTWIDTHS.featuredVCWidth
      : LAYOUTWIDTHS.compactVCWidth
    : 0;
}
