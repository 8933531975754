import React from 'react'
import { SvgIcon } from '@mui/material';

export default function CameraOnIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M8.33331 32.4587C8.33331 19.292 12.7083 14.917 25.875 14.917H52.2083C65.375 14.917 69.75 19.292 69.75 32.4587V67.542C69.75 80.7087 65.375 85.0837 52.2083 85.0837H25.875C12.7083 85.0837 8.33331 76.3337 8.33331 67.542V50.5003"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M81.3333 71.2505L69.7499 63.1255V36.8339L81.3333 28.7089C86.9999 24.7505 91.6666 27.1672 91.6666 34.1255V65.8755C91.6666 72.8339 86.9999 75.2505 81.3333 71.2505Z"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M54.1667 39.5835C54.1667 36.1252 51.375 33.3335 47.9167 33.3335C44.4584 33.3335 41.6667 36.1252 41.6667 39.5835C41.6667 43.0418 44.4584 45.8335 47.9167 45.8335"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
