import React from "react";
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  Box,
  ListItemSecondaryAction,
  ListItemButton,
  alpha,
} from "@mui/material";
import { getNameInitials, getDateTimeFormat } from "utils";
import { getS3ImageWrapper } from "@app21/core";
import MessageCountBox from "./MessageCountBox";
import { useChatContext } from "providers/TrrystChatProvider";
import { Event } from "@mui/icons-material";
import { format } from "date-fns";
import { BreakoutIcon } from "blocks/atoms/trrysticons";

const ChatGroupCard = ({ channel, isActive, handleClick, viewChatContext }) => {
  // it needs to be fetched from pubnub directly here for the channel
  const { unreadMessageCounts } = useChatContext();
  const [unreadMessageCount, setUnreadMessageCount] = React.useState(0);

  let baseObject = {};
  switch (channel.type) {
    case "DOCKETS":
      baseObject = channel.docket ?? {};
      break;
    case "MEETINGS":
      baseObject = channel.meeting ?? {};
      break;
    case "TASKS":
      baseObject = channel.task ?? {};
      break;
    case "SUITES":
      baseObject = channel.suite ?? {};
      break;
    case "FILES":
      baseObject = channel.file ?? {};
      break;
    case "SURVEYS":
      baseObject = channel.survey ?? {};
      break;
    default:
      break;
  }

  React.useEffect(() => {
    setUnreadMessageCount(unreadMessageCounts[channel.id]);
  }, [channel, unreadMessageCounts]);

  const objectTitle = baseObject.fullName ?? baseObject.title;
  const objectImage = baseObject.image ?? baseObject.avatar;
  return (
    <ListItem
      sx={{
        border: (theme) =>
          `1px solid ${
            isActive ? theme.palette.primary.lighter : theme.palette.grey[300]
          }`,
        backgroundColor: (theme) =>
          isActive
            ? alpha(theme.palette.primary.main, 0.1)
            : "background.paper",
        my: 1,
        px: 1,
      }}
    >
      <ListItemButton onClick={handleClick} sx={{ p: 0 }} disableGutters>
        <ListItemAvatar>
          {viewChatContext === "suite" ? (
            <Avatar
              variant="rounded"
              src={getS3ImageWrapper(
                objectImage,
                "initials",
                getNameInitials(objectTitle ?? viewChatContext)
              )}
              alt={getNameInitials(objectTitle ?? viewChatContext)}
            />
          ) : channel.type === "MEETINGS" ? (
            <Event />
          ) : channel.type === "DOCKETS" ||
            channel.type === "TASKS" ||
            channel.type === "SURVEYS" ||
            channel.type === "FILES" ? (
            <BreakoutIcon />
          ) : (
            <Avatar variant="rounded">
              {getNameInitials(objectTitle ?? viewChatContext)}
            </Avatar>
          )}
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
              {objectTitle}
            </Typography>
          }
          secondary={
            channel.type === "MEETINGS"
              ? format(
                  new Date(channel.createdAt),
                  getDateTimeFormat({
                    startDate: channel.createdAt,
                    showDayFlag: true,
                  })
                )
              : null
          }
        />
        <ListItemSecondaryAction>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <MessageCountBox count={unreadMessageCount} />
            {/* <Typography
            variant="caption"
            display="block"
            gutterBottom
            sx={{ mb: 2 }}
          >
            May 12
          </Typography> */}
          </Box>
        </ListItemSecondaryAction>
      </ListItemButton>
    </ListItem>
  );
};

export default React.memo(ChatGroupCard);
