import React from 'react'
import { SvgIcon } from "@mui/material";

export default function FileFrameIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M100 32.6227V32.3242L99.8132 32.1374C99.6724 31.8469 99.4839 31.5735 99.2475 31.3287L70.7475 1.82872C69.9129 0.964877 68.701 0.685702 67.6227 1H8C3.58172 1 0 4.58172 0 9V93C0 97.4183 3.58172 101 8 101H92C96.4183 101 100 97.4183 100 93V34.3769C100.166 33.8055 100.167 33.1953 100 32.6227ZM67.2947 7.25H8C7.0335 7.25 6.25 8.0335 6.25 9V93C6.25 93.9665 7.0335 94.75 8 94.75H92C92.9665 94.75 93.75 93.9665 93.75 93V34.6336L67.2947 7.25Z"
          fill="currentColor"
        />
        <mask id="path-2-inside-1_272_127104" fill="currentColor">
          <path d="M30 2H69V24C69 26.2091 67.2091 28 65 28H34C31.7909 28 30 26.2091 30 24V2Z" />
        </mask>
        <path
          d="M30 2H69V24C69 26.2091 67.2091 28 65 28H34C31.7909 28 30 26.2091 30 24V2Z"
          stroke="currentColor"
          strokeWidth="6.5"
        />
        <mask id="path-3-inside-2_272_127104" fill="currentColor">
          <path d="M83 101L16 101L16 52C16 49.7909 17.7909 48 20 48L79 48C81.2091 48 83 49.7909 83 52L83 101Z" />
        </mask>
        <path
          d="M83 101L16 101L16 52C16 49.7909 17.7909 48 20 48L79 48C81.2091 48 83 49.7909 83 52L83 101Z"
          stroke="currentColor"
          strokeWidth="6.5"
        />
        <path
          d="M31 66H69.5"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
        />
        <path
          d="M31 82H69.5"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
        />
      </g>
    </SvgIcon>
  );
}
