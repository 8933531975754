// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import { SvgIcon } from "@mui/material";

export default function CollaborateIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 50 50"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g>
        <path d="M 39.501953 5.3769531 C 39.374314 5.3804379 39.247388 5.3965646 39.123047 5.4238281 C 38.625051 5.5330207 38.168098 5.8287445 37.873047 6.2832031 L 34.806641 11 L 6 11 C 4.3545455 11 3 12.354545 3 14 L 3 41 C 3 42.645455 4.3545455 44 6 44 L 44 44 C 45.645455 44 47 42.645455 47 41 L 47 14 C 47 12.354545 45.645455 11 44 11 L 43.916016 11 L 44.308594 10.380859 C 44.894832 9.45595 44.612206 8.2032695 43.6875 7.6191406 L 40.619141 5.6816406 L 40.617188 5.6816406 C 40.274027 5.4652303 39.884871 5.3664989 39.501953 5.3769531 z M 39.550781 7.3730469 L 42.619141 9.3105469 L 41.330078 11.345703 A 1.0001 1.0001 0 0 0 41.171875 11.59375 L 34.175781 22.640625 L 30.902344 20.675781 L 36.113281 12.660156 A 1.0001 1.0001 0 0 0 36.271484 12.417969 L 39.550781 7.3730469 z M 6 13 L 33.507812 13 L 28.75 20.320312 A 1.0001 1.0001 0 0 0 28.490234 21.066406 A 1.0001 1.0001 0 0 0 28.490234 21.070312 L 27.998047 26.916016 A 1.0001 1.0001 0 0 0 28.177734 27.578125 C 27.514688 28.170014 26.469292 29 24.441406 29 C 20.388406 29 19.457453 21 13.314453 21 C 9.4944531 21 7.25 24.478 7.25 25.5 C 7.25 25.72 7.3099531 26 7.7519531 26 C 8.4229531 26 9.6426875 24 12.304688 24 C 17.272688 24 17.365641 32 23.431641 32 C 26.407457 32 28.675024 29.309805 29.306641 27.949219 A 1.0001 1.0001 0 0 0 29.472656 27.878906 L 34.847656 24.951172 L 34.851562 24.949219 A 1.0001 1.0001 0 0 0 35.431641 24.400391 L 42.650391 13 L 44 13 C 44.554545 13 45 13.445455 45 14 L 45 41 C 45 41.554545 44.554545 42 44 42 L 6 42 C 5.4454545 42 5 41.554545 5 41 L 5 14 C 5 13.445455 5.4454545 13 6 13 z M 30.361328 22.681641 L 32.486328 23.957031 L 30.146484 25.232422 L 30.361328 22.681641 z" />
      </g>
    </SvgIcon>
  );
}
