// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { Fragment } from "react";
import { Avatar, Tooltip, Skeleton, Box, Typography } from "@mui/material";
import { getS3ImageWrapper, useUserInfo } from "@app21/core";
import toast from "react-hot-toast";
import { getNameInitials } from "utils/getNameInitials";
import { useLayoutContext } from "providers/LayoutProvider";
import { FetchUserAvatar } from "blocks/atoms";
import { useOrgsAndSuitesInfoContext } from "providers/OrgsAndSuitesInfoProvider";

export default function UserAvatar({
  userId,
  suiteId,
  viewContext = "avatar",
  defaultText = "Trryst User",
  tooltipFlag = true,
  disableOnClick = false,
  size = 25,
  sx = {},
  ...rest
}) {
  const { fetchUserInfo = () => null } = useOrgsAndSuitesInfoContext();
  const { setDisplayUserInfo } = useLayoutContext();
  const userInfo = fetchUserInfo(userId);

  const ConditionalTooltip = ({ userDetails, defaultText, children }) => {
    if (tooltipFlag) {
      return (
        <Tooltip
          title={
            userDetails?.fullName ? (
              <Box style={{ display: "flex", flexDirection: "column", gap: 3 }}>
                <Typography variant="caption">
                  {userDetails?.fullName}
                </Typography>
                <Typography variant="caption">
                  {userDetails?.emailId}
                </Typography>
              </Box>
            ) : (
              defaultText
            )
          }
          arrow
        >
          {children}
        </Tooltip>
      );
    } else return <Fragment>{children}</Fragment>;
  };
  if (!userId) return <></>;

  if (userInfo) {
    if (viewContext === "name") {
      return userInfo?.fullName ?? defaultText;
    }
    return (
      <ConditionalTooltip userDetails={userInfo} defaultText={defaultText}>
        <Avatar
          src={getS3ImageWrapper(userId ? userInfo?.image : null, "person")}
          alt={getNameInitials(userInfo?.fullName ?? defaultText)}
          sx={{ height: size, width: size, ...sx }}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            disableOnClick ? null : setDisplayUserInfo(userInfo);
          }}
          {...rest}
        />
      </ConditionalTooltip>
    );
  } else
    return (
      <FetchUserAvatar
        ConditionalTooltip={ConditionalTooltip}
        viewContext={viewContext}
        setDisplayUserInfo={setDisplayUserInfo}
        userId={userId}
        defaultText={defaultText}
        tooltipFlag={tooltipFlag}
        disableOnClick={disableOnClick}
        size={size}
        sx={sx}
        {...rest}
      />
    );
}
