import React from 'react'
import { SvgIcon } from '@mui/material';

export default function UserIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M62.5834 12.5419C59.0834 9.8752 54.7501 8.3335 50.0001 8.3335C38.5001 8.3335 29.1667 17.6668 29.1667 29.1668C29.1667 40.6668 38.5001 50.0002 50.0001 50.0002C61.5001 50.0002 70.8334 40.6668 70.8334 29.1668"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M85.7913 91.6667C85.7913 75.5417 69.7497 62.5 49.9997 62.5C30.2497 62.5 14.208 75.5417 14.208 91.6667"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
