import React from "react";
import {
  Box,
  Typography,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CardActionArea,
  alpha,
} from "@mui/material";
import { useDocketsContext } from "providers/DocketsProvider";

import { ProfilesChipsList, Label, UserAvatar } from "blocks/atoms";
import { useAppContext, useUsersInSuite, populateUsers } from "@app21/core";
import {
  CollapseArrowIcon,
  DurationIcon,
  UserIcon,
  SelectActiveIcon,
  SurveyFinishedIcon,
  SurveyIcon,
  ArrowRightIcon,
  FilesIcon,
  BreakoutIcon,
} from "blocks/atoms/trrysticons";
import {
  Done,
  AccessTime,
  AssignmentTurnedIn,
  Edit,
  DeleteForever,
  Assignment,
  AttachFile,
  Attachment,
} from "@mui/icons-material";

import clip from "text-clipper";
import { MoreActionsDropdownMenu } from "blocks/atoms/uicomponents";
import { format } from "date-fns";

export default function DisplaySidebarDocketCard({ docketObj, ...props }) {
  const { selectedDocketId, checkAccessRules } = useAppContext();
  const {
    handleDocketEdit,
    docketPermissions,
    handleDocketDelete,
    handleDocketStatusChange,
    handleDocketView,
    usersInSuite,
  } = useDocketsContext();

  const isCardActive = docketObj._id === selectedDocketId;
  const isDocketCompleted = docketObj?.status === "completed";

  const menuOptions = [
    ...(docketPermissions.checkCanEditDocket(docketObj) && !isDocketCompleted
      ? [
          {
            label: "Edit",
            icon: <Edit />,
            onClick: () => handleDocketEdit(docketObj._id),
          },
        ]
      : []),
    ...(docketPermissions.checkCanDeleteDocket(docketObj)
      ? [
          {
            label: "Delete",
            icon: <DeleteForever />,
            onClick: () => handleDocketDelete(docketObj._id),
          },
        ]
      : []),
    ...(docketObj.type === "BOARD-MEETING"
      ? docketPermissions.checkCanEditDocket(docketObj) &&
        docketObj.status !== "completed"
        ? [
            {
              label: "Mark as Completed",
              icon: <AssignmentTurnedIn />,
              onClick: () => handleDocketStatusChange("completed", docketObj),
            },
          ]
        : [
            {
              label: "Re-open Docket",
              icon: <Assignment />,
              onClick: () => handleDocketStatusChange("OPEN", docketObj),
            },
          ]
      : []),
  ];

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        position: "relative",
        gap: 1,
        my: 1,
        backgroundColor: (theme) =>
          isCardActive
            ? alpha(theme.palette.primary.lighter, 0.1)
            : docketPermissions.checkCanViewDocket(docketObj)
            ? theme.palette.background.paper
            : theme.palette.background.neutral,
        border: (theme) =>
          docketPermissions.checkCanViewDocket(docketObj) &&
          `1px solid ${theme.palette.divider}`,
      }}
      {...props}
    >
      <CardActionArea
        disabled={!docketPermissions.checkCanViewDocket(docketObj)}
        onClick={() => handleDocketView(docketObj._id)}
      >
        <CardHeader
          sx={{ pb: 1 }}
          avatar={<UserAvatar userId={docketObj.createdBy} />}
          disableTypography
        />

        <CardContent
          sx={{
            py: 0,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant={isCardActive ? "h6" : "subtitle1"}
            color={isCardActive ? "text.primary" : "text.secondary"}
          >
            {clip(docketObj?.title, 100)}
          </Typography>
          {docketPermissions.checkCanViewDocket(docketObj) && (
            <ArrowRightIcon
              sx={{ color: "text.secondary", alignSelf: "center" }}
            />
          )}
        </CardContent>

        <CardActions
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",

            justifyContent: "space-between",
          }}
        >
          {docketPermissions.checkCanAccessMembers(docketObj) ? (
            <Box>
              <ProfilesChipsList
                dense
                usersList={populateUsers(docketObj?.members, usersInSuite)}
                maxItems={4}
              />
            </Box>
          ) : (
            <></>
          )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 2,
              color: "text.secondary",
            }}
          >
            {Boolean(docketObj?.comments?.length) && (
              <Box sx={{ color: "text.secondary" }}>
                <BreakoutIcon /> {docketObj?.attachments?.length}
              </Box>
            )}
            {Boolean(docketObj?.attachments?.length) && (
              <Box
                sx={{
                  color: "text.secondary",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Attachment fontSize="small" sx={{ color: "text.secondary" }} />
                {docketObj?.attachments?.length}
              </Box>
            )}
          </Box>
        </CardActions>
      </CardActionArea>

      {!docketPermissions.checkCanViewDocket(docketObj) && (
        <MoreActionsDropdownMenu
          menuOptions={menuOptions}
          tooltipLabel="More Breakout Options"
          IconButtonProps={{ sx: { position: "absolute", top: 2, right: 2 } }}
        />
      )}
    </Card>
  );
}
