import React from "react";
import { Box, Typography, Button, IconButton } from "@mui/material";
import { FlowBox } from "blocks/atoms/uistyles";
import { useAtom } from "jotai";
import { useAppContext } from "@app21/core";
import { useSurveysContext } from "providers/SurveysProvider";
import { centerpanelContextAtom } from "blocks/modules/Surveys/surveys-atoms";
import {
  SimpleSurveyBuilder,
  SimpleSurveyResponse,
} from "blocks/modules/Surveys/features";
import SurveyResponseAnalytics from "./SurveyResponseAnalytics";

export default function SurveysResponseAnalyticsTabPanel({}) {
  const [centerpanelContext, setCenterpanelContext] = useAtom(
    centerpanelContextAtom
  );
  const { decisionsData, selectedDecisionId, actionContext } =
    useSurveysContext();

  if (!decisionsData) return null;

  const selectedDecision = decisionsData?.find(
    (decision) => decision._id === selectedDecisionId
  );

  if (!selectedDecision) return null;

  if (centerpanelContext === "questions") {
    return (
      <FlowBox sx={{ p: 2 }}>
        <SimpleSurveyResponse
          actionContext={actionContext}
          resolution={selectedDecision}
        />
      </FlowBox>
    );
  }
  return (
    <FlowBox sx={{ p: 2 }}>
      <SurveyResponseAnalytics
        resolution={selectedDecision}
        resolutionId={selectedDecisionId}
      />
    </FlowBox>
  );
}
