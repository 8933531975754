import { executeApi } from "@app21/core";
import _ from "lodash";

export async function handleWalkinCall({
  docketId = "",
  userInfo = {},
  selectedSuiteId,
  addIdToRoute,
  router,
}) {
  let response;
  let meetingId;
  if (docketId) {
    response = await executeApi("FETCH-ACTIVE-GROUP-MEETING-IN-DOCKET", {
      listOfDocketIds: docketId,
      groupMeetings: "active-only",
      calledBy: userInfo?._id,
    });
    meetingId = response?.data[0]?._id;
  } else {
    response = await executeApi("FETCH-ACTIVE-GROUP-MEETING-IN-SUITE", {
      suiteId: selectedSuiteId,
      groupMeetings: "active-only",
      calledBy: userInfo?._id,
    });
    meetingId = response.data?._id;
  }

  let newRoutePath = addIdToRoute({
    paramKey: "meetingId",
    paramValue: meetingId,
  });

  // there is a chance that action might be there in the router.query
  // if yes, add it in as well.

  if (_.isEqual(Object.keys(router.query), ["action"]))
    newRoutePath.query = { ...newRoutePath.query, ...router?.query };
  router.push(newRoutePath, newRoutePath, { shallow: true });
}
