// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import { SvgIcon } from "@mui/material";

export default function SplitScreenIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 125"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g data-name="Four, grid, layout, stacked">
        <path d="M91.87,4H8.13A4.13,4.13,0,0,0,4,8.13V91.87A4.13,4.13,0,0,0,8.13,96H91.87A4.13,4.13,0,0,0,96,91.87V8.13A4.13,4.13,0,0,0,91.87,4ZM52.5,37.72H91V62H52.5Zm38.5-5H52.5V9H91ZM9,9H47.5V91H9ZM52.5,91V67H91V91Z" />
      </g>
    </SvgIcon>
  );
}
