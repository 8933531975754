// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
// @ts-nocheck
import {
  SpeakerSelection,
  useAudioOutputs,
} from "amazon-chime-sdk-component-library-react";
import React, { useState } from "react";
import TestSound from "blocks/modules/TrrystVideocall/foundations/utils/TestSound";
import { Button } from "@mui/material";
import { VolumeUp } from "@mui/icons-material";

const SpeakerDevices = () => {
  const { selectedDevice } = useAudioOutputs();
  const [selectedOutput, setSelectedOutput] = useState(selectedDevice);

  const handleChange = (deviceId: string): void => {
    setSelectedOutput(deviceId);
  };

  const handleTestSpeaker = () => {
    new TestSound(selectedOutput);
  };

  return (
    <div style={{ display: "flex" }}>
      <SpeakerSelection onChange={handleChange} />
      <div style={{ padding: "25px 5px 0 15px" }}>
        <Button variant="outlined" onClick={handleTestSpeaker}>
          <VolumeUp style={{ marginRight: 5 }} /> Test
        </Button>
      </div>
    </div>
  );
};

export default SpeakerDevices;
