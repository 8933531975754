import { SvgIcon } from "@mui/material";
import React from "react";
export default function CopyLinkWebIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 56 56"
      stroke="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <rect width="56" height="56" rx="4.8" fill="#07493C" />
        <path
          d="M32.9883 31.5H34.4983C37.5183 31.5 39.9983 29.03 39.9983 26C39.9983 22.98 37.5283 20.5 34.4983 20.5H32.9883"
          stroke="#FAD2A0"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.37 24.0103C20.13 24.6303 20 25.3003 20 26.0003C20 29.0203 22.47 31.5003 25.5 31.5003H27"
          stroke="#FAD2A0"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.9997 20.5H25.4997C24.6697 20.5 23.8897 20.68 23.1797 21.01"
          stroke="#FAD2A0"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26 26H34"
          stroke="#FAD2A0"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
