// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  Card,
  TextField,
  Typography,
  CardHeader,
  CardContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  RadioGroup,
  Radio,
  FormLabel,
} from "@mui/material";

import { Controller, useFormContext } from "react-hook-form";
import { useAppContext, useHandleRouterPush } from "@app21/core";
import ShowFilesList from "blocks/atoms/ShowFilesList";
import { useSurveysContext } from "providers/SurveysProvider";

const ResponseQuestionCard = ({ responseQuestion, index, responseField }) => {
  const { watch, control, setValue, getValues, formState } = useFormContext();
  const { errors } = formState;
  const { selectedSuiteId, selectedDocketId, selectedOrganizationId } =
    useAppContext();
  const { isMobile, loadRoute, isDocket } = useSurveysContext();

  return (
    <Card
      data-testid="ResponseQuestionCard"
      elevation={2}
      sx={{ borderRadius: 0 }}
    >
      <FormControl
        component="fieldset"
        error={!!errors?.responses?.[index]?.choice}
        sx={{ display: "flex", flexDirection: "column" }}
        variant="standard"
      >
        <CardHeader
          title={<FormLabel>{responseQuestion?.questionItem}</FormLabel>}
        />

        <CardContent
          sx={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <Controller
            name={`responses[${index}].choice`}
            control={control}
            defaultValue={responseField?.choice}
            rules={{ required: { value: true, message: "Required" } }}
            render={({ field }) => (
              <RadioGroup aria-label="Survey Response" {...field}>
                {responseQuestion?.answerChoices?.map(
                  (answerChoice, subindex) => (
                    <FormControlLabel
                      key={subindex}
                      value={answerChoice.choice}
                      control={<Radio />}
                      label={answerChoice.choice}
                      variant="standard"
                    />
                  )
                )}
              </RadioGroup>
            )}
          />
          <FormHelperText>
            {errors?.responses?.[index]?.choice?.message}
          </FormHelperText>
          {responseQuestion?.commentsBoxQuestion && (
            <Controller
              control={control}
              name={`responses[${index}].otherComments`}
              defaultValue={responseQuestion.otherComments}
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    rows={4}
                    placeholder="Please enter your comments, if any"
                  />
                );
              }}
            />
          )}
        </CardContent>
      </FormControl>
      {responseQuestion?.files?.length ? (
        <CardContent
          sx={{
            padding: (theme) => theme.spacing(1),
            minWidth: 150,
            width: "100%",
            maxWidth: 250,
          }}
        >
          <Card elevation={0}>
            <CardHeader
              title="Supporting material provided"
              titleTypographyProps={{ variant: "subtitle1" }}
            />
            <CardContent style={{ marginTop: 0, paddingTop: 0 }}>
              <ShowFilesList
                showAs={isMobile ? "icon" : "chip"}
                filesList={responseQuestion?.files}
                handleFileClick={(fileInfo) => {
                  isDocket
                    ? loadRoute("VIEW-RESOLUTIONFILE", {
                        s3Key: fileInfo?.s3Key,
                        docketId: selectedDocketId,
                      })
                    : loadRoute("VIEW-SURVEYFILE", {
                        s3Key: fileInfo?.s3Key,
                      });
                }}
              />
            </CardContent>
          </Card>
        </CardContent>
      ) : null}
    </Card>
  );
};

export default ResponseQuestionCard;
