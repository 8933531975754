import React from "react";
import { LoadingSpinner } from "blocks/atoms";
import { useDockets } from "@app21/core";

export default function PrintDocketInfo({ docketId }) {
  const {
    data: selectedDocket,
    status: docketStatus,
    apiError: docketApiError,
  } = useDockets(docketId);

  if (docketStatus === "loading") return <LoadingSpinner />;
  return <span>{selectedDocket?.title}</span>;
}
