// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { useState } from "react";
import clsx from "clsx";
import toast from "react-hot-toast";
import {
  Avatar,
  TextField,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  getS3ImageWrapper,
  useAppContext,
  useHandleResolutionActions,
} from "@app21/core";
import { Send } from "@mui/icons-material";

import Comment from "blocks/modules/Tasks/foundations/TaskEditModal/Comment";
import { constantCase } from "change-case";

const SurveyDiscussionCommentsForm = ({ question, resolution, index }) => {
  let baseDiscussions = question.discussions ?? [];

  const [message, setMessage] = useState("");
  const { userInfo } = useAppContext();

  const { handleResolutionActions } = useHandleResolutionActions();
  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  const handleKeyUp = async (event) => {
    try {
      event.stopPropagation();
      if (event.keyCode === 13 && message) {
        handleClick();
        toast.success("Comment added");
      }
    } catch (err) {
      toast.error(`Something went wrong - ${JSON.stringify(err)} `);
    }
  };

  const handleClick = async () => {
    await handleResolutionActions({
      action: "ADD-SURVEY-DISCUSSION-COMMENT",
      comment: message,
      baseDiscussions: baseDiscussions,
      commentIndex: index,
    });
    setMessage("");
  };
  if (question) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          padding: (theme) => theme.spacing(1),
        }}
      >
        {baseDiscussions.map((comment, tempindex) => (
          <Comment key={tempindex} comment={comment} />
        ))}
        {!baseDiscussions?.length && (
          <Typography variant="caption">Please provide your Inputs</Typography>
        )}
        {constantCase(resolution?.survey?.decisionStatus ?? "") === "DRAFT" && (
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <Avatar
              sx={{ height: 25, width: 25 }}
              alt={userInfo?.fullName || "Person"}
              src={getS3ImageWrapper(userInfo?.image, "person")}
            />
            <TextField
              fullWidth
              size="small"
              margin="dense"
              sx={{ marginLeft: (theme) => theme.spacing(2) }}
              value={message}
              onChange={handleChange}
              onKeyUp={handleKeyUp}
              placeholder="Write a comment..."
              InputProps={{
                sx: {
                  backgroundColor: (theme) => theme.palette.background.default,
                },
              }}
              InputLabelProps={{
                sx: {
                  color: (theme) => theme.palette.text.primary,
                  backgroundColor: (theme) => theme.palette.background.default,
                },
              }}
            />
            <Tooltip title="Send" arrow>
              <IconButton
                onClick={handleClick}
                color={message.length > 0 ? "primary" : "default"}
              >
                <Send />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </div>
    );
  } else return null;
};

SurveyDiscussionCommentsForm.propTypes = {};

export default SurveyDiscussionCommentsForm;
