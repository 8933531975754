import React from "react";
// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)

import { Box, Typography, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useIntl } from "react-intl";
import TaskEditModal from "blocks/modules/Tasks/foundations/TaskEditModal";
import TaskBoardView from "blocks/modules/Tasks/features/TaskBoardView";
import TaskBoardListView from "blocks/modules/Tasks/features/TaskBoardListView";
import TasksViewSwitcher from "blocks/modules/Tasks/foundations/TasksViewSwitcher";
import TasksViewToggle from "blocks/modules/Tasks/foundations/TasksViewToggle";
import {
  useTasks,
  useUsersInSuite,
  useDockets,
  useAppContext,
  checkIfNull,
  useHandleTaskActions,
} from "@app21/core";

import { processTasksData } from "blocks/modules/Tasks/foundations/processTasksData";
import LoadingSpinner from "blocks/atoms/LoadingSpinner";

const ConsolidatedTasksView = ({ context }) => {
  const theme = useTheme();
  const [isTaskDetailsModalOpen, setIsTaskDetailsModalOpen] =
    React.useState(false);
  const [initialTaskInfo, setInitialTaskInfo] = React.useState(null);
  const [openTaskId, setOpenTaskId] = React.useState(null);
  const [taskData, setTaskData] = React.useState(null);
  const [openTaskGroupId, setOpenTaskGroupId] = React.useState(null);
  const [viewMode, setViewMode] = React.useState(
    context === "docket" ? "alltasks" : "mytasks"
  );
  const { userInfo } = useAppContext();
  const { selectedDocketId, selectedSuiteId } = useAppContext();
  const { data: selectedDocket, status: selectedDocketStatus } =
    useDockets(selectedDocketId);
  const contextId = context === "docket" ? selectedDocketId : selectedSuiteId;
  const { data: tasks, status: tasksStatus } = useTasks({
    objectId: contextId,
    idType: context === "docket" ? "docket" : "mytasks",
  });

  const [view, setView] = React.useState("listView");

  const handleEditTaskDetails = (taskId, taskDetails) => {
    if (typeof taskId === "boolean") setIsTaskDetailsModalOpen(value);
    else setIsTaskDetailsModalOpen(!isTaskDetailsModalOpen);
    setTaskData(taskDetails);
  };

  const { data: usersInSuite } = useUsersInSuite(selectedSuiteId);
  const { handleTaskActions } = useHandleTaskActions();

  const intl = useIntl();
  const { formatMessage } = intl;

  const t = (id) => formatMessage({ id });

  const processedTasksInfo = React.useMemo(() => {
    return processTasksData({
      tasks,
      context,
      contextId,
      usersInSuite,
      userInfo,
      theme,
    });
  }, [tasks, context, contextId, usersInSuite, userInfo]);

  const isDocketCreator = selectedDocket?.createdBy === userInfo?._id;
  const isDocketInvitee = Boolean(
    selectedDocket?.members?.find((user) => user._id === userInfo?._id)
  );

  const handleTaskEditClick = (cardId, metadata, laneId) => {
    setOpenTaskId(cardId);
    setOpenTaskGroupId(laneId);
    setTaskData(metadata);
    setIsTaskDetailsModalOpen(true);
  };

  const handleClose = () => {
    setIsTaskDetailsModalOpen(false);
    setOpenTaskId(null);
    setTaskData(null);
    setOpenTaskGroupId(null);
  };

  const viewTasksData =
    viewMode === "alltasks"
      ? processedTasksInfo.allTasks
      : viewMode === "watching"
      ? processedTasksInfo.watchingTasks
      : processedTasksInfo.myTasks;

  const handleAddTask = () => {
    setOpenTaskGroupId("pending");
    setIsTaskDetailsModalOpen(true);
  };
  const handleTaskStatusUpdate = async (
    cardId,
    sourceLaneId,
    targetLaneId,
    position,
    cardDetails
  ) => {
    if (!checkIfNull(cardDetails?.metadata) && sourceLaneId !== targetLaneId) {
      let newFormData = { ...cardDetails.metadata };
      newFormData.status = targetLaneId;

      handleTaskActions({ taskId: cardId, taskData: newFormData }, null, null);
    }
  };
  const handleViewSwitch = (value) => {
    setViewMode(value);
  };

  let emptyTasksList =
    checkIfNull(viewTasksData?.lanes?.[0]?.cards) &&
    checkIfNull(viewTasksData?.lanes?.[1]?.cards) &&
    checkIfNull(viewTasksData?.lanes?.[2]?.cards);

  if (selectedDocketStatus === "loading" || tasksStatus === "loading") {
    return <LoadingSpinner />;
  } else
    return (
      <Box
        data-testid="TaskView"
        sx={{
          height: "100%",
          display: "flex",
          overflow: "hidden",
          flexDirection: "column",
          flexGrow: 1,
        }}
        title="Tasks Board"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 0,
            flexShrink: 1,
          }}
        >
          <TasksViewToggle view={view} setView={setView} />
          {/* <TasksViewSwitcher defaultValue={viewMode} context={context} handleChoice={handleViewSwitch} /> */}
        </Box>

        {view === "listView" && (
          <TaskBoardListView
            context={context}
            handleEditTaskDetails={handleEditTaskDetails}
            handleTaskEditClick={handleTaskEditClick}
            handleClose={handleClose}
          />
        )}
        {view === "kanbanView" && (
          <Box
            sx={{
              bgcolor: (theme) => theme.palette.background.paper,
              borderRadius: 0,
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {emptyTasksList ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography variant="body1" sx={{ alignSelf: "center", mt: 2 }}>
                  {viewMode === "watching"
                    ? "You are not watching any actions."
                    : "No actions in your Inbox."}
                </Typography>
                {context === "docket" && (
                  <Button onClick={handleAddTask} variant="contained">
                    {`${t("SuiteDashboard.Tasks.createTask")}`}
                  </Button>
                )}
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  minHeight: 450,
                  padding: 0,
                  flexGrow: 1,
                }}
              >
                {/* <TaskBoardView
                  handleTaskEditClick={handleTaskEditClick}
                  handleAddTask={handleAddTask}
                  handleTaskStatusUpdate={handleTaskStatusUpdate}
                  taskData={viewTasksData}
                  context={context}
                  isDocketCreator={isDocketCreator}
                  isDocketInvitee={isDocketInvitee}
                /> */}
              </Box>
            )}
          </Box>
        )}

        {isTaskDetailsModalOpen && (
          <TaskEditModal
            taskId={openTaskId}
            open={isTaskDetailsModalOpen}
            onClose={handleClose}
            taskData={taskData}
            taskGroupData={openTaskGroupId}
            context={context}
          />
        )}
      </Box>
    );
};

export default ConsolidatedTasksView;
