const CHART_CONFIG = {
  dev: {
    MEETING: {
      totalMeetingHours: "632ac2e6-6248-42ab-8c4a-805548c70fd9",
      totalRecordingHours: "632b1063-5397-4e51-8db1-16f7df06f1ca",
      analytics: {
        none: "632ac2e6-6248-4dea-8314-805548c70fd3",
        yearly: "0177cf82-60c9-4384-8720-076df4178fd7",
        monthly: "632ac2e6-6248-4041-8d19-805548c70fd1",
        daily: "632ac2e6-6248-4dea-8314-805548c70fd3",
        attendeeReport: "632b17d4-7e44-4e2b-8513-fa65c531d813",
        recordingAnalytics: {
          none: "20d94abd-cfff-407c-977e-4df13ce5a061",
          yearly: "3cc18b6b-db19-4f65-b645-030ad3cfc2f7",
          monthly: "6ee29472-b4d7-45f8-ad43-5fee5c15e370",
          daily: "20d94abd-cfff-407c-977e-4df13ce5a061",
        },
      },
    },
    STORAGE: {
      totalStorageMbs: "632ac2e6-6248-4ccd-878f-805548c70fdb",
      fileVsRecordingAnalytics: "632da902-5f9d-4e47-8dab-e9a6e02ec456",
      analytics: {
        none: "cd41a6bd-15aa-422b-8b78-f0a5dbd73129",
        yearly: "cd41a6bd-15aa-422b-8b78-f0a5dbd73129",
        monthly: "d8dcbfa5-038e-4ee6-9989-cc3a704837c7",
        daily: "632efcfb-d3bd-4c1c-80c7-347800f914d4",
      },
    },
    FILE: {
      allFileAnalyticsMinutesViewed: "632b12bf-717b-4af3-8f61-ef09c20304ab",
      allFileAnalyticsNumberOfViews: "9540b1bf-fdd2-41cf-adf6-6073c6595e5e",
      crudAnalytics: "632b133b-6248-4822-8f5b-80554801d7ea",
      viewsByFileType: "6331901e-5f9d-41ff-83bd-e9a6e0c50d99",
      totalReadDuration: "63dcf320-3346-4225-8539-c583c4cd8a59",
      totalOperations: "63dcf6b8-0833-4938-8e22-b3f42be7bf7e",
      totalAISummaryGenerated: "63dcf7eb-2b02-4b77-8396-a07daf1a610e",
    },
    ROUTE: {
      pathVisitAnalytics: "632ef75d-4a00-4d16-8972-d76dd99f9f70",
    },
    CREDIT: {
      mainAnalyticsBar: "632b168f-7895-4e4b-84e4-76db737068a2",
      storageCreditAnalytics: "632f3847-e42c-4418-801d-6c1292e89e79",
      userCreditAnalytics: "632f5331-e989-49c2-847f-8ce2239e39aa",
      meetingCreditAnalytics: "632f3d54-5f9d-402d-8701-e9a6e0765b77",
      recordingCreditAnalytics: "632f3d54-5f9d-402d-8701-e9a6e0765b77",
      aiMinutesCreditAnalytics: "632f3d54-5f9d-402d-8701-e9a6e0765b77",
    },
  },
  prod: {
    MEETING: {
      totalMeetingHours: "633729de-f9a9-4fc8-84b6-bb703367fe2e",
      totalRecordingHours: "633729de-f9a9-4e64-87b4-bb703367fe32",
      analytics: {
        none: "633729de-f9a9-4a97-885a-bb703367fe2c",
        yearly: "633729de-f9a9-4c7e-825e-bb703367fe4c",
        monthly: "633729de-f9a9-4ad8-877d-bb703367fe2a",
        daily: "633729de-f9a9-4a97-885a-bb703367fe2c",
        attendeeReport: "633729de-f9a9-4c56-8652-bb703367fe3a",
        recordingAnalytics: {
          none: "633729de-f9a9-478d-8148-bb703367fe3c",
          yearly: "633729de-f9a9-4e76-85a6-bb703367fe50",
          monthly: "633729de-f9a9-4f23-8fc1-bb703367fe4e",
          daily: "633729de-f9a9-478d-8148-bb703367fe3c",
        },
      },
    },
    STORAGE: {
      totalStorageMbs: "633729de-f9a9-45bf-8449-bb703367fe30",
      fileVsRecordingAnalytics: "633729de-f9a9-42b5-8764-bb703367fe3e",
      analytics: {
        none: "633729de-f9a9-4c49-8a3b-bb703367fe42",
        yearly: "633729de-f9a9-4a8f-8d52-bb703367fe54",
        monthly: "633729de-f9a9-4e9e-8209-bb703367fe52",
        daily: "633729de-f9a9-4c49-8a3b-bb703367fe42",
      },
    },
    FILE: {
      allFileAnalyticsMinutesViewed: "633729de-f9a9-473e-8213-bb703367fe34",
      allFileAnalyticsNumberOfViews: "633729de-f9a9-49fd-8f23-bb703367fe56",
      crudAnalytics: "633729de-f9a9-4008-8765-bb703367fe36",
      viewsByFileType: "633729de-f9a9-442a-8d1c-bb703367fe4a",
      totalReadDuration: "63dcf320-3346-4225-8539-c583c4cd8a59",
    },
    ROUTE: {
      pathVisitAnalytics: "633729de-f9a9-4c09-8a48-bb703367fe40",
    },
    CREDIT: {
      allAnalytics: "633729de-f9a9-4d35-8ece-bb703367fe38",
      mainAnalyticsBar: "633729de-f9a9-4d35-8ece-bb703367fe38",
      storageCreditAnalytics: "633729de-f9a9-4b77-8687-bb703367fe44",
      userCreditAnalytics: "633729de-f9a9-4b74-8930-bb703367fe48",
      meetingCreditAnalytics: "",
      recordingCreditAnalytics: "",
      aiMinutesCreditAnalytics: "",
    },
  },
};

const STAGE = process.env.STAGE as "dev" | "prod";

export default Object.freeze(CHART_CONFIG[STAGE]);
