// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
export { default as FullScreenIcon } from "./FullScreenIcon";
export { default as SplitScreenIcon } from "./SplitScreenIcon";
export { default as CompactScreenIcon } from "./CompactScreenIcon";
export { default as VideocallSettingsIcon } from "./VideocallSettingsIcon";
export { default as VideoSettingsIcon } from "./VideoSettingsIcon";
export { default as VideocallEventIcon } from "./VideocallEventIcon";
export { default as JoinVideocallIcon } from "./JoinVideocallIcon";
export { default as CollaborateIcon } from "./CollaborateIcon";
export { default as WhiteboardingIcon } from "./WhiteboardingIcon";
export { default as PersonalSuiteIcon } from "./PersonalSuiteIcon";
export { default as BoardroomIcon } from "./BoardroomIcon";
export { default as ExecSuiteIcon } from "./ExecSuiteIcon";
