// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  Typography,
  Button,
  Divider,
  Avatar,
  Tooltip,
  Box,
  Skeleton,
} from "@mui/material";
import { ExitToApp } from "@mui/icons-material";
import { useAuth0 } from "@auth0/auth0-react";
import { getS3ImageWrapper, checkIfNull } from "@app21/core";
import { useIntl } from "react-intl";
import MenuPopover from "blocks/atoms/uicomponents/MenuPopover";
import { IconButtonAnimate } from "blocks/atoms";
import { alpha } from "@mui/material/styles";
import { resetStorage, getNameInitials } from "utils";

const LoggedInUser = ({ ...rest }) => {
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });
  const anchorRef = React.useRef(null);

  const { user, isAuthenticated, loginWithRedirect, logout, isLoading } =
    useAuth0();
  //const { data: loggedInUser, status: loggedInUserStatus } = useUserInfo() ?? {};

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  if (isLoading)
    return (
      <Skeleton variant="circular" width={30} height={30} sx={{ mx: 2 }} />
    );
  else if (isAuthenticated && !checkIfNull(user)) {
    return (
      <>
        <IconButtonAnimate
          ref={anchorRef}
          onClick={handleOpen}
          aria-describedby={user?.name}
          sx={{
            padding: 0,
            ml: 1,
            ...(open && {
              "&:before": {
                zIndex: 1,
                content: "''",
                width: "100%",
                height: "100%",
                borderRadius: "50%",
                position: "absolute",
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
              },
            }),
          }}
          {...rest}
        >
          <Tooltip title={user?.name} arrow>
            {user?.picture ? (
              <Avatar
                alt={getNameInitials(user?.name)}
                src={user?.picture}
                sx={{ margin: 0, height: 25, width: 25 }}
              />
            ) : (
              <Avatar
                alt={getNameInitials(user?.name)}
                sx={{ margin: 0, height: 25, width: 25 }}
              >
                {getNameInitials(user?.name)}
              </Avatar>
            )}
          </Tooltip>
        </IconButtonAnimate>

        <MenuPopover
          open={open}
          onClose={handleClose}
          anchorEl={anchorRef.current}
          sx={{ width: 220 }}
        >
          <Box sx={{ my: 1.5, px: 2.5 }}>
            <Typography variant="subtitle1" noWrap>
              {user?.name}
            </Typography>
          </Box>

          <Divider sx={{ my: 1 }} />

          {/*MENU_OPTIONS.map(option => (
            <NextLink key={option.label} href={option.linkTo}>
              <MenuItem onClick={handleClose} sx={{ typography: 'body2', py: 1, px: 2.5 }}>
                <Box
                  component={Icon}
                  icon={option.icon}
                  sx={{
                    mr: 2,
                    width: 24,
                    height: 24,
                  }}
                />

                {option.label}
              </MenuItem>
            </NextLink>
                ))*/}

          <Box sx={{ p: 2, pt: 1.5 }}>
            <Button
              fullWidth
              color="inherit"
              variant="outlined"
              startIcon={<ExitToApp />}
              onClick={() => {
                resetStorage();

                logout({ returnTo: process.env.ROOT_URL });
              }}
            >
              Logout
            </Button>
          </Box>
        </MenuPopover>
      </>
    );
  } else {
    return (
      <Button
        variant="contained"
        color="secondary"
        sx={{ mr: 1 }}
        onClick={() =>
          loginWithRedirect({
            screen_hint: "login",
            isPasswordlessLogin: false,
          })
        }
      >
        {`${t("TopNavbar.signIn")}`}
      </Button>
    );
  }
};

export default LoggedInUser;
