// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Box, Button, Card, Container } from "@mui/material";
import GetMeetingId from "./GetMeetingId";
import { ListUpcomingMeetings } from "blocks/views/SuiteDashboard";

const GetMeetingIdAndJoin = ({ message }) => {
  return (
    <Container
      maxWidth="md"
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
      }}
    >
      <Card
        elevation={0}
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: 1,
          height: "100%",
          overflowY: "auto",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", m: 2 }}>
          <GetMeetingId message={message} />

          <ListUpcomingMeetings />
        </Box>
      </Card>
    </Container>
  );
};

export default GetMeetingIdAndJoin;
