import React from 'react'
import { SvgIcon } from '@mui/material';

export default function CloseIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M58.2927 41.708L61.7927 38.208"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M38.208 61.7918L49.6663 50.3335"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M61.7913 61.7913L38.208 38.208"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.6666 25.0002C11.4583 31.9585 8.33331 40.6252 8.33331 50.0002C8.33331 73.0002 27 91.6668 50 91.6668C73 91.6668 91.6667 73.0002 91.6667 50.0002C91.6667 27.0002 73 8.3335 50 8.3335C44.0416 8.3335 38.3333 9.5835 33.2083 11.8752"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
