import React from "react";
import { MainChatComponent } from "blocks/modules/TrrystChat";
import {
  Modal,
  Fade,
  Typography,
  Box,
  Backdrop,
  IconButton,
} from "@mui/material";
import { useVideocallContext } from "providers/TrrystVideocallProvider";
import { Close } from "@mui/icons-material";
import { FlowBox } from "blocks/atoms/uistyles";

const style = {
  position: "absolute",
  top: "50%",
  right: 30,
  transform: "translate(-25px, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  minHeight: "50vh",
  maxHeight: "75vh",
  overflow: "auto",
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  height: "100%",
};

export default function VideocallChatModal({}) {
  const { isChatModalOpen, setIsChatModalOpen } = useVideocallContext();

  const handleModalClose = () => {
    setIsChatModalOpen(false);
  };

  return (
    <Modal
      aria-labelledby="chat-modal"
      aria-describedby="chat-for-videocall"
      open={isChatModalOpen}
      onClose={handleModalClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: { timeout: 500 },
      }}
    >
      <Box sx={style}>
        <Fade in={true}>
          <FlowBox sx={{ position: "relative" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                In-Meeting Chat
              </Typography>
              <IconButton onClick={handleModalClose}>
                <Close />
              </IconButton>
            </Box>
            <MainChatComponent viewMode="compact" />
          </FlowBox>
        </Fade>
      </Box>
    </Modal>
  );
}
