// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Box, Typography } from "@mui/material";
import { FlowBox } from "blocks/atoms/uistyles";
import { useIsMobileBreakpoint } from "hooks";
import { useAtom } from "jotai";
import { showDocketsSidebarFlagAtom } from "./dockets-atoms";
import { DocketsSidebar, DocketsCenterPanel } from "./features";
import {
  ModulesTitleComponent,
  SidePanelDrawer,
} from "blocks/atoms/uicomponents";
import { BreakoutsMenubar } from "./foundations";
import DocketsProvider from "providers/DocketsProvider";

export default function BreakoutRoomDocketView({
  viewContext = "list",
  actionContext = "summary",
}) {
  const isMobile = useIsMobileBreakpoint(480, true);
  const sidebarModeActive = isMobile;

  const showSidebar = true;

  const TitleComponent = (
    <Typography variant="h4" color="text.secondary">
      Breakouts
    </Typography>
  );
  const sidebarWidthRatio = 25;
  const centralTitleBarHeight = 48;

  return (
    <DocketsProvider
      isMobile={isMobile}
      viewContext={viewContext}
      actionContext={actionContext}
    >
      <FlowBox sx={{ position: "relative" }}>
        <ModulesTitleComponent
          sidebarActive={isMobile}
          width={`${sidebarWidthRatio}%`}
          TitleComponent={TitleComponent}
          centralTitleBarHeight={centralTitleBarHeight}
          HeaderComponent={<BreakoutsMenubar isMobile={isMobile} />}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "stretch",
            flexGrow: 1,
            height: "100%",
            position: "relative",
          }}
        >
          {showSidebar ? (
            <SidePanelDrawer
              sidebarModeActive={sidebarModeActive}
              centralTitleBarHeight={centralTitleBarHeight}
              TitleComponent={TitleComponent}
              width={`${sidebarWidthRatio}%`}
              DrawerSxProps={{ minWidth: 250 }}
              sx={{ width: `min(max(${sidebarWidthRatio}%, 200px), 400px)` }}
            >
              <DocketsSidebar isMobile={isMobile} />
            </SidePanelDrawer>
          ) : (
            <></>
          )}
          <FlowBox
            sx={{
              transition: "all 1s ease-in-out",
              paddingTop: `${centralTitleBarHeight}px`,

              width: showSidebar
                ? `calc(100% - min(max(${sidebarWidthRatio}%, 200px), 400px))}`
                : "100%",
            }}
          >
            <DocketsCenterPanel isMobile={isMobile} />
          </FlowBox>
        </Box>
      </FlowBox>
    </DocketsProvider>
  );
}
