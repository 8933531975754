// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { forwardRef, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

const Page = forwardRef(({ children, title = "", ...rest }, ref) => {
  const location = useRouter();

  const sendPageViewEvent = useCallback(() => {
    {
      /* track.pageview({
      page_path: location.pathname,
    }); */
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    sendPageViewEvent();
  }, [sendPageViewEvent]);

  return (
    <Box style={{ width: "100%", height: "100%" }} ref={ref} {...rest}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </Box>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};
Page.displayName = "Page";

export default Page;

export const proptype = Page.PropTypes;
