import React from 'react'
import { SvgIcon } from '@mui/material';

export default function DecreaseIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M50.0002 35.416L50.0002 60.416"
          stroke="currentColor"
          strokeWidth="6.03541"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M62.5003 52.084L50.0003 64.584L37.5003 52.084"
          stroke="currentColor"
          strokeWidth="6.03541"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M83.3335 75.0008C88.5419 68.0425 91.6669 59.3758 91.6669 50.0008C91.6669 27.0008 73.0002 8.33414 50.0002 8.33414C27.0002 8.33415 8.33352 27.0008 8.33353 50.0008C8.33353 73.0008 27.0002 91.6675 50.0002 91.6675C55.9585 91.6675 61.6669 90.4175 66.7919 88.1258"
          stroke="currentColor"
          strokeWidth="6.03541"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
