// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  IconButton,
  Button,
  DialogContent,
  Grid,
} from "@mui/material";

import { Ballot, ExpandMore, Comment, Clear, Grade } from "@mui/icons-material";
import SurveyDiscussionCommentsForm from "blocks/modules/Surveys/foundations/SurveyDiscussionCommentsForm";
import DraggablePaper from "blocks/atoms/uicomponents/DraggablePaper";
import { constantCase } from "change-case";

const ListResolutionDiscussions = ({ resolution }) => {
  const [selectedQuestion, setSelectedQuestion] = React.useState(null);
  const [index, setIndex] = React.useState(null);

  const handleCommentsClick = (question, index) => {
    setSelectedQuestion(question);
    setIndex(index);
  };
  const handleCommentsClose = () => {
    setSelectedQuestion(null);
    setIndex(null);
  };
  return (
    <div style={{ paddingLeft: (theme) => theme.spacing(0) }}>
      {resolution?.survey?.questionBlock?.map((question, localindex) => (
        <Grid container item xs={12} lg={12} sm={12} xl={12}>
          <Paper
            sx={{
              display: "flex",
              // alignItems: 'center',
              padding: (theme) => theme.spacing(1),
              marginTop: (theme) => theme.spacing(1),
              flexGrow: 1,
            }}
            key={localindex}
          >
            <Ballot
              sx={{
                width: 25,
                height: 25,
                marginRight: (theme) => theme.spacing(2),
              }}
            />
            <Typography
              sx={{
                fontSize: (theme) => theme.typography.pxToRem(15),
                flexGrow: 1,
              }}
            >
              {question?.questionItem}
            </Typography>
            {question?.discussions?.length ? (
              <Button
                sx={{
                  width: 50,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 5,
                  border: "1px solid Lightgrey",
                  color: (theme) => theme.palette.primary.light,
                }}
                onClick={() => handleCommentsClick(question, localindex)}
              >
                <Comment
                  sx={{
                    width: 20,
                    height: 20,
                    marginRight: (theme) => theme.spacing(1),
                  }}
                />
                <Typography variant="subtitle2">
                  {" "}
                  {question?.discussions?.length}
                </Typography>
              </Button>
            ) : (
              constantCase(resolution?.survey?.decisionStatus ?? "") ===
                "DRAFT" && (
                <IconButton
                  onClick={() => handleCommentsClick(question, localindex)}
                >
                  <Comment />
                </IconButton>
              )
            )}
          </Paper>
        </Grid>
      ))}
      <Dialog
        PaperComponent={DraggablePaper}
        open={Boolean(selectedQuestion)}
        onClose={handleCommentsClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            flexGrow: 1,
            position: "relative",
          }}
          id="dragModal"
        >
          Comments
          <IconButton
            sx={{ position: "absolute", right: 5, top: 5 }}
            onClick={handleCommentsClose}
          >
            <Clear />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ overflowY: "auto" }}>
          {Boolean(selectedQuestion) && (
            <SurveyDiscussionCommentsForm
              question={selectedQuestion}
              index={index}
              resolution={resolution}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ListResolutionDiscussions;
