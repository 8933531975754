import React from "react";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useAppContext, useDockets, useMeetingsInDockets } from "@app21/core";
import LoadingSpinner from "blocks/atoms/LoadingSpinner";
import toast from "react-hot-toast";
import { intervalToDuration } from "date-fns";

const ShowSchedule = () => {
  const { selectedDocketId } = useAppContext();

  const {
    data: selectedDocket,
    status: selectedDocketStatus,
    apiError: selectedDocketApiError,
  } = useDockets(selectedDocketId);

  const {
    data: docketMeetings,
    status: docketMeetingStatus,
    apiError: docketMeetingsApiError,
  } = useMeetingsInDockets([selectedDocketId]);

  const dataFetchError = docketMeetingsApiError || selectedDocketApiError;
  const dataFetchLoading =
    selectedDocketStatus === "loading" || docketMeetingStatus === "loading";

  React.useEffect(() => {
    if (dataFetchError && !dataFetchLoading) {
      toast.error(dataFetchError.message);
    }
  }, [dataFetchError]);

  const relatedMeeting = docketMeetings && docketMeetings[selectedDocketId];
  const meeting =
    Array.isArray(relatedMeeting) && relatedMeeting.slice(-1)[0]?.meetingJSON;

  const isMeeting = Boolean(meeting?.start || meeting?.end);

  let diffrencedate = { days: 0, hours: 0, minutes: 0 };
  if (meeting?.start && meeting?.end) {
    diffrencedate = intervalToDuration({
      start: new Date(meeting?.start),
      end: new Date(meeting?.end),
    });
  }

  const days = diffrencedate?.days;
  const hours = diffrencedate?.hours;
  const minutes = diffrencedate?.minutes;

  const overall = days
    ? `${days}days : ${hours}hours: ${minutes}minutes`
    : hours
    ? `${hours}hours: ${minutes}minutes`
    : `${minutes}minutes `;

  return (
    <>
      {isMeeting && docketMeetingStatus === "loading" ? (
        <LoadingSpinner size={25} />
      ) : (
        <Typography variant="body1">{overall}</Typography>
      )}
    </>
  );
};

ShowSchedule.prototypes = {
  viewMode: PropTypes.string,
};

ShowSchedule.defaultProps = {
  viewMode: "normal",
};

export default ShowSchedule;
