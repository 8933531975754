// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Box, Typography } from "@mui/material";
import { FlowBox } from "blocks/atoms/uistyles";
import { useIsMobileBreakpoint } from "hooks";
import { useAtom } from "jotai";
import { showTasksSidebarFlagAtom } from "./tasks-atoms";
import { TasksSidebar, TasksCenterPanel } from "./features";
import { TasksMenubar } from "blocks/modules/Tasks/foundations";
import TasksProvider from "providers/TasksProvider";
import {
  SidePanelDrawer,
  ModulesTitleComponent,
} from "blocks/atoms/uicomponents";

//import SurveySummaryList from "blocks/modules/Surveys/features/SurveySummaryList";

export default function MainTasksComponent({
  viewContext = "SUITE",
  viewMode = "full",
  actionContext = "list",
}) {
  const isMobile = useIsMobileBreakpoint(480, true) || viewMode === "compact";
  const sidebarModeActive = isMobile;

  const showSidebar = viewContext === "SUITE";
  console.log("showSidebar", showSidebar);
  const TitleComponent = (
    <Typography variant="h4" color="text.secondary">
      Tasks
    </Typography>
  );
  const sidebarWidthRatio = 20;
  const centralTitleBarHeight = 68;
  return (
    <TasksProvider
      viewContext={viewContext}
      actionContext={actionContext}
      isMobile={isMobile}
    >
      <FlowBox sx={{ position: "relative" }}>
        <ModulesTitleComponent
          sidebarActive={isMobile}
          width={`${sidebarWidthRatio}%`}
          TitleComponent={TitleComponent}
          centralTitleBarHeight={centralTitleBarHeight}
          HeaderComponent={<TasksMenubar isMobile={isMobile} />}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "stretch",
            flexGrow: 1,
            height: "100%",
            position: "relative",
          }}
        >
          {showSidebar ? (
            <SidePanelDrawer
              sidebarModeActive={sidebarModeActive}
              centralTitleBarHeight={centralTitleBarHeight}
              TitleComponent={TitleComponent}
              width={`${sidebarWidthRatio}%`}
              DrawerSxProps={{ minWidth: 250 }}
              sx={{ width: `min(max(${sidebarWidthRatio}%, 200px), 400px)` }}
            >
              <TasksSidebar viewContext={viewContext} isMobile={isMobile} />
            </SidePanelDrawer>
          ) : (
            <></>
          )}
          <FlowBox
            sx={{
              transition: "all 1s ease-in-out",
              paddingTop: `${centralTitleBarHeight}px`,

              width: showSidebar
                ? `calc(100% - min(max(${sidebarWidthRatio}%, 200px), 400px))}`
                : "100%",
            }}
          >
            <TasksCenterPanel viewContext={viewContext} isMobile={isMobile} />
          </FlowBox>
        </Box>
      </FlowBox>
    </TasksProvider>
  );
}
