import React from "react";
import { Box, styled, Typography } from "@mui/material";
import { ChatSidebar, ChatMessagePanel } from "./features";
import { checkIfNull } from "@app21/core";
import { useChatContext } from "providers/TrrystChatProvider";
import { useIsMobileBreakpoint } from "hooks";
import {
  ModulesTitleComponent,
  SidePanelDrawer,
} from "blocks/atoms/uicomponents";
import { FlowBox } from "blocks/atoms/uistyles";
import { ChatMessageMenubar } from "./foundations";

export const StyledBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
}));

export default function MainChatComponent({ viewFile, viewMode = "full" }) {
  const { allChannelsData } = useChatContext();
  const isMobile = useIsMobileBreakpoint(480, true) || viewMode === "compact";
  const sidebarModeActive = isMobile;

  const showSidebar = viewMode !== "compact";

  const TitleComponent =
    viewMode === "compact" ? (
      <></>
    ) : (
      <Typography variant="h4" color="text.secondary">
        My Chats
      </Typography>
    );
  const sidebarWidthRatio = 25;
  const centralTitleBarHeight = 48;

  if (!checkIfNull(allChannelsData)) {
    return (
      <FlowBox sx={{ position: "relative" }}>
        <ModulesTitleComponent
          sidebarActive={isMobile && showSidebar}
          width={`${sidebarWidthRatio}%`}
          TitleComponent={TitleComponent}
          centralTitleBarHeight={centralTitleBarHeight}
          HeaderComponent={
            <ChatMessageMenubar viewMode={viewMode} isMobile={isMobile} />
          }
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "stretch",
            flexGrow: 1,
            height: "100%",
            position: "relative",
          }}
        >
          {showSidebar ? (
            <SidePanelDrawer
              sidebarModeActive={sidebarModeActive}
              centralTitleBarHeight={centralTitleBarHeight}
              TitleComponent={TitleComponent}
              width={`${sidebarWidthRatio}%`}
              DrawerSxProps={{ minWidth: 250 }}
              sx={{ width: `min(max(${sidebarWidthRatio}%, 200px), 400px)` }}
            >
              <ChatSidebar isMobile={isMobile} />
            </SidePanelDrawer>
          ) : (
            <></>
          )}
          <FlowBox
            sx={{
              transition: "all 1s ease-in-out",
              paddingTop: `${centralTitleBarHeight}px`,

              width: showSidebar
                ? `calc(100% - min(max(${sidebarWidthRatio}%, 200px), 400px))}`
                : "100%",
            }}
          >
            <ChatMessagePanel
              viewMode={viewMode}
              viewFile={viewFile}
              isMobile={isMobile}
            />
          </FlowBox>
        </Box>
      </FlowBox>
    );
  } else
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          p: 2,
        }}
      >
        <Typography variant="h6">
          No Chats fetched. If it is a new Suite, click on the Chat button above
          and start discussing amongst your Suite members
        </Typography>
      </Box>
    );
}
