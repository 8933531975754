import React from 'react'
import { SvgIcon } from "@mui/material";

export default function TimeLineIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M82.9166 56.25H17.0833C10.8333 56.25 8.33325 58.9167 8.33325 65.5417V82.375C8.33325 89 10.8333 91.6667 17.0833 91.6667H82.9166C89.1666 91.6667 91.6666 89 91.6666 82.375V65.5417C91.6666 58.9167 89.1666 56.25 82.9166 56.25Z"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M82.9166 8.3335H17.0833C10.8333 8.3335 8.33325 11.0002 8.33325 17.6252V34.4585C8.33325 41.0835 10.8333 43.7502 17.0833 43.7502H82.9166C89.1666 43.7502 91.6666 41.0835 91.6666 34.4585V17.6252C91.6666 11.0002 89.1666 8.3335 82.9166 8.3335Z"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
