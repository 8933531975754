// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  Tab,
  Tabs,
  Typography,
  Paper,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  Description,
  ViewAgenda,
  Attachment,
  ListAlt,
  AssignmentTurnedIn,
  Ballot,
  SpeakerNotes,
} from "@mui/icons-material";
import { ChatIcon } from "blocks/atoms/trrysticons";
import { alpha } from "@mui/material/styles";
import useDimensions from "react-cool-dimensions";
import {
  DocketHeaderPanel,
  DocketSummaryView,
  DocketActionsView,
  DocketAgendaView,
  DocketFilesView,
  DocketMinutesView,
  DocketRecordsView,
  DocketChatView,
  DocketSelectionDropdown,
} from "blocks/modules/Dockets";
import { useDocketsContext } from "providers/DocketsProvider";
//import DocketResolutionsView from 'blocks/views/DocketDashboard/foundations/DocketResolutionsView';
import { MainSurveyComponent } from "blocks/modules/Surveys";
import { LAYOUTWIDTHS } from "blocks/atoms/constants";
import { useAppContext, useHandleRouterPush, useSuite } from "@app21/core";
import { useIntl } from "react-intl";
import { varFade } from "blocks/atoms/uieffects/animate";
import { AnimatePresence } from "framer-motion";
import { processFeaturesAccessCheck } from "utils/processFeaturesAccessCheck";
import { useIsMobileBreakpoint } from "hooks";
import toast from "react-hot-toast";
import {
  FlowBox,
  MuiStyledRoundedTabs,
  MuiStyledRoundedTab,
  StyledRoundedTabs,
  StyledRoundedTab,
  StyledRoundedTabList,
} from "blocks/atoms/uistyles";

const TABSDIVHEIGHT = 25;

function TabPanel(props) {
  const { children, value, index, sx = {}, ...other } = props;
  return (
    value === index && (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...varFade().inRight}
        {...other}
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          ...sx,
        }}
      >
        {children}
      </Box>
    )
  );
}

function a11yProps(index) {
  return {
    id: `normal-tab-${index}`,
    "aria-controls": `normal-tabpanel-${index}`,
  };
}

const DocketConsolidatedView = ({ children }) => {
  // const [windowWidth, setWindowWidth] = React.useState(0);
  const {
    tabValue,
    selectedOrganizationId,
    selectedSuiteId,
    selectedDocketId,
    checkAccessRules,
    chimeViewMode,
    meetingId,
  } = useAppContext();
  const { actionContext } = useDocketsContext();

  const theme = useTheme();
  const {
    data: selectedSuite,
    status: selectedSuiteStatus,
    apiError: selectedSuiteApiError,
  } = useSuite(selectedSuiteId);

  React.useEffect(() => {
    if (selectedSuiteApiError && selectedSuiteStatus != "loading") {
      toast.error(selectedSuiteApiError.message);
    }
  }, [selectedSuiteApiError]);
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });
  const { loadRoute } = useHandleRouterPush();
  const [value, setValue] = React.useState(tabValue || "summary");
  //const ref = React.useRef();
  //const { observe, width: windowWidth } = useDimensions();
  const isSmallWidth = useMediaQuery("(min-width: 550px)");
  const isMediumWidth = useMediaQuery("(min-width: 950px)");
  const smallSizeFlag =
    !isSmallWidth ||
    (!isMediumWidth && chimeViewMode === "normal" && meetingId);
  const largeSizeFlag =
    (isMediumWidth && !meetingId) ||
    (isMediumWidth && meetingId && chimeViewMode !== "normal");
  const suiteType = selectedSuite?.suiteType;
  const isMobile = useIsMobileBreakpoint();

  const featuresAccessCheck = React.useMemo(
    () => processFeaturesAccessCheck(checkAccessRules),
    [suiteType]
  );
  const allDocketOptions = [
    {
      label: `${t("DocketMenu.summary")}`,
      value: "summary",
      routeString: "summary?",
      icon: <Description fontSize="small" />,
      criteria: featuresAccessCheck.canViewDocketSummary,
    },
    {
      label: `${t("DocketMenu.agenda")}`,
      value: "agenda",
      routeString: "agenda?",
      icon: <ViewAgenda fontSize="small" />,
      criteria: featuresAccessCheck.canViewDocketAgenda,
    },

    {
      label: `${t("DocketMenu.chat")}`,
      value: "chat",
      routeString: "chat?",
      icon: <ChatIcon fontSize="small" />,
      criteria: featuresAccessCheck.canViewDocketChat,
    },

    {
      label: `${t("DocketMenu.files")}`,
      value: "files",
      routeString: `files?s3Key=${encodeURIComponent(
        `${selectedSuiteId}/dockets/${selectedDocketId}/`
      )}&`,
      icon: <Attachment fontSize="small" />,
      criteria: featuresAccessCheck.canViewDocketInfoPack,
    },
    {
      label: `${t("DocketMenu.resolutions")}`,
      value: "resolutions",
      routeString: "resolutions/list?",
      icon: <Ballot fontSize="small" />,
      criteria: featuresAccessCheck.canViewDocketResolutions,
    },
    {
      label: `${t("DocketMenu.minutes")}`,
      value: "minutes",
      routeString: "minutes?",
      icon: <ListAlt />,
      criteria: featuresAccessCheck.canViewDocketMinutes,
    },
    {
      label: `${t("DocketMenu.tasks")}`,
      value: "tasks",
      routeString: "tasks?",
      icon: <AssignmentTurnedIn fontSize="small" />,
      criteria: featuresAccessCheck.canViewDocketActions,
    },
    {
      label: `${t("DocketMenu.records")}`,
      value: "records",
      routeString: "records?",
      icon: <SpeakerNotes fontSize="small" />,
      criteria: featuresAccessCheck.canViewDocketRecords,
    },
  ];

  //  const AllTabIndices = ['summary', 'agenda', 'files', 'resolutions', 'minutes', 'tasks', 'records'];

  React.useEffect(() => {
    setValue(tabValue || "summary");
  }, [tabValue]);

  const handleTabChange = (event, newValue) => {
    loadRoute("VIEW-DOCKET", {
      ...{
        orgId: selectedOrganizationId,
        suiteId: selectedSuiteId,
        docketId: selectedDocketId,
        tabValue: newValue,
      },
      ...(newValue === "files"
        ? {
            s3Key: `${selectedOrganizationId}/${selectedSuiteId}/dockets/${selectedDocketId}/`,
          }
        : {}),
      ...(newValue === "resolutions"
        ? {
            decisionId: null,
          }
        : {}),
    });
    {
      /*router.push(}
      `/user/organization/suite/docket/${
        getTabParams(newValue).routeString
      }orgId=${selectedOrganizationId}&suiteId=${selectedSuiteId}&docketId=${selectedDocketId}&tabValue=${newValue}`,
      `/user/organization/suite/docket/${
        getTabParams(newValue).routeString
      }orgId=${selectedOrganizationId}&suiteId=${selectedSuiteId}&docketId=${selectedDocketId}&tabValue=${newValue}`,
      { shallow: true },
    );*/
    }
    //setValue(newValue);
  };

  return (
    <FlowBox
      sx={{
        maxHeight: `calc(100vh - ${LAYOUTWIDTHS.TopbarHeight}px )`,
        maxWidth: "100%",
        width: "100%",
        // padding: theme.spacing(1),
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <MuiStyledRoundedTabs
          value={value}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          tabsHeight={35}

          // TabIndicatorProps={{
          //   style: {
          //     background: theme.palette.primary.light,
          //     height: "100px",
          //     top: "5px",
          //     bottom: 10,

          //     borderRadius: 15,
          //   },
          // }}
        >
          {allDocketOptions
            .filter((option) => option.criteria)
            .map((option, i) => (
              <MuiStyledRoundedTab
                key={i}
                value={option.value}
                tabHeight={35}
                sx={{}}
                // icon={
                //   smallSizeFlag || largeSizeFlag
                //     ? getTabParams(tabIndex)?.labelIcon
                //     : null
                // }
                label={option?.label}
              />
            ))}
        </MuiStyledRoundedTabs>

        {/* <StyledRoundedTabs
          value={value}
          onChange={handleTabChange}
          selectionFollowsFocus
          sx={{ mb: 2 }}
        >
          <StyledRoundedTabList
            sx={{
              borderRadius: 25,
              backgroundColor: (theme) => theme.palette.background.paper,
            }}
          >
            {tabIndices.map((tabIndex, i) => (
              <StyledRoundedTab key={i} value={tabIndex} sx={{}}>
                {smallSizeFlag || largeSizeFlag
                  ? getTabParams(tabIndex)?.labelIcon
                  : null}
                {smallSizeFlag ? null : getTabParams(tabIndex)?.label}
              </StyledRoundedTab>
            ))}
          </StyledRoundedTabList>
        </StyledRoundedTabs> */}

        <AnimatePresence>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              maxHeight: `calc(100vh - ${LAYOUTWIDTHS.TopbarHeight}px - ${
                TABSDIVHEIGHT + 5
              }px)`,
              flexGrow: 1,
              mt: 3,
            }}
          >
            <Paper
              sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
            >
              <TabPanel value={value} index={"summary"}>
                <DocketSummaryView />
              </TabPanel>
              <TabPanel value={value} index={"agenda"}>
                <DocketAgendaView />
              </TabPanel>
              <TabPanel value={value} index={"chat"}>
                <DocketChatView />
              </TabPanel>
              <TabPanel value={value} index={"files"}>
                <DocketFilesView />
              </TabPanel>
              <TabPanel value={value} index={"resolutions"} sx={{ p: 1 }}>
                <MainSurveyComponent
                  viewContext="resolution"
                  actionContext={actionContext}
                />
              </TabPanel>
              <TabPanel value={value} index={"minutes"}>
                <DocketMinutesView />
              </TabPanel>
              <TabPanel value={value} index={"tasks"}>
                <DocketActionsView />
              </TabPanel>
              <TabPanel value={value} index={"records"}>
                <DocketRecordsView />
              </TabPanel>
            </Paper>
          </Box>
        </AnimatePresence>
      </Box>
    </FlowBox>
  );
};

export default DocketConsolidatedView;
