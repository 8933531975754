import React from "react";
import {
  Select,
  FormControl,
  MenuItem,
  ListItemIcon,
  ListItemText,
  InputLabel,
  styled,
  InputBase,
  alpha,
  Typography,
  Box,
} from "@mui/material";
import { useAtom } from "jotai";
import { selectedUploadActionAtom } from "providers/FilesProvider/files-atoms";
import { uploadActionListOptions } from "./uploadActionOptions";
import { useFilesContext } from "providers/FilesProvider";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(1),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: (theme) => theme.typography.subtitle1.fontSize,

    padding: "3px 4px 3px 4px",

    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.

    "&:focus": {
      borderRadius: 4,

      borderColor: theme.palette.primary.lighter,
      boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.light, 0.15)}`,
    },
  },
}));

export default function FileActionsDropdown() {
  const [selectedUploadAction, setSelectedUploadAction] = useAtom(
    selectedUploadActionAtom
  );
  const { filesPermissions } = useFilesContext();
  const handleUploadActionSelect = (event) => {
    setSelectedUploadAction(event.target.value);
  };
  const uploadActionsList = React.useMemo(
    () =>
      uploadActionListOptions({
        isItemSelected: false,
        isMobile: false,
        filesPermissions,
      }),
    [filesPermissions]
  );

  return (
    <FormControl sx={{ minWidth: 80 }} size="small">
      <Select
        value={selectedUploadAction}
        onChange={handleUploadActionSelect}
        displayEmpty
        renderValue={(val) => {
          let action = uploadActionsList?.find(
            (action) => action.value === val
          );
          return action ? action.label : "Add";
        }}
        SelectDisplayProps={{
          sx: {},
        }}
        inputProps={{
          "aria-label": "File Actions",
          sx: {
            fontSize: (theme) => theme.typography.body2.fontSize,
            fontWeight: (theme) => theme.typography.subtitle1.fontWeight,
          },
        }}
        input={<BootstrapInput />}
      >
        <MenuItem value="" disabled>
          <Typography variant="body2">Choose an Option</Typography>
        </MenuItem>
        {uploadActionsList.length > 0 ? (
          uploadActionsList.map((action) => (
            <MenuItem key={action.value} value={action.value}>
              <ListItemIcon
                sx={{
                  "&.Mui-selected": {
                    display: "none",
                  },
                }}
              >
                {action.icon && <action.icon />}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="subtitle2">{action.label} </Typography>
                }
              />
            </MenuItem>
          ))
        ) : (
          <Box sx={{ p: 1, maxWidth: 150 }}>
            <Typography variant="body2">
              You do not have privileges to add to this folder
            </Typography>
          </Box>
        )}
      </Select>
    </FormControl>
  );
}
