import React from 'react'
import { SvgIcon } from '@mui/material';

export default function PauseIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M16.6667 25.0002C11.4584 31.9585 8.33337 40.6252 8.33337 50.0002C8.33337 73.0002 27 91.6668 50 91.6668C73 91.6668 91.6667 73.0002 91.6667 50.0002C91.6667 27.0002 73 8.3335 50 8.3335C44.0417 8.3335 38.3334 9.5835 33.2084 11.8752"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M44.7918 60.126V39.0426C44.7918 37.0426 43.9585 36.251 41.8335 36.251H36.4585C34.3335 36.251 33.5001 37.0426 33.5001 39.0426V60.126C33.5001 62.126 34.3335 62.9176 36.4585 62.9176H41.8335C43.9585 62.9176 44.7918 62.126 44.7918 60.126Z"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M66.7914 60.126V39.0426C66.7914 37.0426 65.9581 36.251 63.8331 36.251H58.4581C56.3331 36.251 55.4998 37.0426 55.4998 39.0426V60.126C55.4998 62.126 56.3331 62.9176 58.4581 62.9176"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
