// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Box, Typography, Stack, Tooltip, Button } from "@mui/material";
import { Info, ArrowCircleRight, CheckCircle } from "@mui/icons-material";
import { useAppContext, use } from "@app21/core";

import BackTitleWrapperPaper from "blocks/atoms/BackTitleWrapperPaper";
import { SubscriptionPlanTemplate } from "blocks/modules/Subscriptions";

const SuiteOptionsWalkthrough = ({
  activeStep = 1,
  handleWalkthroughChoice = (e) => null,
  handleSelectChoice = (e) => null,
  viewContext = "PERSONAL",
}) => {
  const [hoveredSuiteChoice, setHoveredSuiteChoice] = React.useState(0);
  const { userInfo } = useAppContext();
  console.log("unser info", userInfo);
  const viewOptions = () => {
    const suiteHelpMessages = {
      boardSuiteHelp:
        "In this suite, We have included several feature modules to make it easier for you to conduct Boardroom conversations with utmost ease and complete privacy. These features include Integrated videocalls, Secure Datasharing, Breakouts for each of your board meetings so that all data stays in one place, Scheduling action items as follow-on after your board meeting, Automated minutes generation etc etc",
      execSuiteHelp:
        "Executive Suites have been specially designed to make it easy to create and coordinate communications and activities between your Executive teams",
    };
    return (
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "stretch",
          pt: 2,
        }}
      >
        What kind of a suite would you like to create today? <br /> <br />
        Not all business interactions are alike. So at Trryst, we have created
        different kinds of suites. Each suite is a skin created on top of the
        core Trryst platform to support your different business stakeholder
        interactions and workflows differently. Pick the one closest to your
        requirements. If in doubt, ask us, we will be happy to help.
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            flexGrow: 1,
          }}
        >
          <Stack spacing={2} sx={{ my: 2, alignItems: "stretch" }}>
            <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
              {hoveredSuiteChoice === 1 ? (
                <ArrowCircleRight color="primary" />
              ) : (
                <Box sx={{ width: 24 }} />
              )}
              <Button
                variant="contained"
                size="large"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  py: 2,
                  flexGrow: 1,
                }}
                onClick={() => handleWalkthroughChoice("BOARD-SUITE")}
                onMouseEnter={() => setHoveredSuiteChoice(1)}
                onMouseLeave={() => setHoveredSuiteChoice(0)}
              >
                <Typography variant="h6">Boardroom Suite</Typography>
                <Typography variant="caption" sx={{ lineHeight: 1 }}>
                  Best Suited for Boardroom or Committee interactions
                </Typography>
              </Button>
              <Tooltip title={suiteHelpMessages.boardSuiteHelp}>
                <Info fontSize="large" color="primary" />
              </Tooltip>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
              {hoveredSuiteChoice === 2 ? (
                <ArrowCircleRight color="secondary" />
              ) : (
                <Box sx={{ width: 24 }} />
              )}
              <Button
                variant="contained"
                color="secondary"
                size="large"
                onClick={() => handleWalkthroughChoice("EXEC-SUITE")}
                onMouseEnter={() => setHoveredSuiteChoice(2)}
                onMouseLeave={() => setHoveredSuiteChoice(0)}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  py: 2,
                  flexGrow: 1,
                }}
              >
                <Typography variant="h6">Executive Suite</Typography>
                <Typography variant="caption" sx={{ lineHeight: 1 }}>
                  General purpose suite for your Executive conversations
                </Typography>
              </Button>
              <Tooltip title={suiteHelpMessages.execSuiteHelp}>
                <Info fontSize="large" color="secondary" />
              </Tooltip>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
              {hoveredSuiteChoice === 3 ? (
                <ArrowCircleRight color="success" />
              ) : (
                <Box sx={{ width: 24 }} />
              )}
              <Button
                variant="contained"
                color="success"
                size="large"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  py: 2,
                  flexGrow: 1,
                }}
                onClick={() => handleWalkthroughChoice("DATA-SUITE")}
                onMouseEnter={() => setHoveredSuiteChoice(3)}
                onMouseLeave={() => setHoveredSuiteChoice(0)}
              >
                <Typography variant="h6">Data Suite</Typography>
                <Typography variant="caption" sx={{ lineHeight: 1 }}>
                  Virtual Datarooms for your RFP, Due Diligence, M&A and other
                  usecases
                </Typography>
              </Button>
              <Tooltip title="Help">
                <Info fontSize="large" color="success" />
              </Tooltip>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
              {<CheckCircle sx={{ color: "#FFe7d9" }} />}
              <Button
                variant="contained"
                disabled
                size="large"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  py: 2,
                  flexGrow: 1,
                  backgroundColor: (theme) =>
                    `${theme.palette.error.lighter} !important`,
                  color: "#797979 !important",
                }}
              >
                <Typography variant="h6">Personal Suite</Typography>
                <Typography variant="caption" sx={{ lineHeight: 1 }}>
                  Already included for you as a thanks for joining us at Beta
                </Typography>
              </Button>
              <Tooltip title="Help">
                <Info fontSize="large" />
              </Tooltip>
            </Stack>
            {userInfo?.role === "GlobalAdmin" && (
              <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
                {hoveredSuiteChoice === 4 ? (
                  <ArrowCircleRight color="error" />
                ) : (
                  <Box sx={{ width: 24 }} />
                )}
                <Button
                  variant="contained"
                  color="error"
                  size="large"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    py: 2,
                    flexGrow: 1,
                  }}
                  onClick={() => handleSelectChoice("APP21-VISITORS-SUITE")}
                  onMouseEnter={() => setHoveredSuiteChoice(4)}
                  onMouseLeave={() => setHoveredSuiteChoice(0)}
                >
                  <Typography variant="h6">Visitor Suite</Typography>
                  <Typography variant="caption" sx={{ lineHeight: 1 }}>
                    Special Purpose Suite. Use with Caution.
                  </Typography>
                </Button>
                <Tooltip title="Help">
                  <Info fontSize="large" color="error" />
                </Tooltip>
              </Stack>
            )}
          </Stack>
        </Box>
      </Box>
    );
  };

  if (viewContext === "PERSONAL") {
    return (
      <BackTitleWrapperPaper title={"Select Upgrade Plan"}>
        <Typography variant="body1" sx={{ m: 2 }}>
          Please select the Plan you wish to upgrade to
        </Typography>
        {viewOptions()}
      </BackTitleWrapperPaper>
    );
  } else return <Box sx={{ m: 1 }}>{viewOptions()}</Box>;
};

export default SuiteOptionsWalkthrough;
