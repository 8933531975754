import React from "react";
import { Box, Typography, IconButton } from "@mui/material";

export default function RecordingsMenubar({ viewMode, isMobile = "false" }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    ></Box>
  );
}
