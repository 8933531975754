import React from "react";
import {
  Grid,
  Container,
  Box,
  Typography,
  Avatar,
  TextField,
  Button,
  styled,
  Paper,
  alpha,
  Badge,
  InputAdornment,
  IconButton,
  SvgIcon,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { FlowBox } from "blocks/atoms/uistyles";
import {
  PlaylistAddCheck,
  PieChart,
  BarChart,
  CheckCircle,
  Done,
  Send,
  Difference,
} from "@mui/icons-material";
import SubscriptionTierComparisonDialog from "./SubscriptionTierComparisonDialog";
import Image from "next/image";
import { ComparePricingTiers } from "blocks/modules/Subscriptions/foundations";

const StyledPaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "isSelected",
})(({ theme, isSelected }) => {
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    padding: 10,
    backgroundColor: isSelected
      ? theme.palette.primary.lighter
      : theme.palette.background.paper,
    color: isSelected
      ? theme.palette.primary.contrastText
      : theme.palette.text.secondary,
  };
});

export default function SelectSubscriptionTierForm({
  formSubscriptionChoice,
  formSubscriptionTemplate,
  formCouponCode,
  isMobile = false,
  ...couponCodeProps
}) {
  const [packageTierDialogOpen, setPackageTierDialogOpen] =
    React.useState(false);

  const { watch, control, getValues } = useFormContext();
  const {
    couponHelperMessage,
    couponSuccess,
    handleCheckCouponCode,
    app21ProductsAndCouponsData,
    priceInfo = {},
    creditsInfo = {},
    chosenPromoCode,
  } = couponCodeProps;

  const handlePackageTierDialogOpen = () => {
    setPackageTierDialogOpen(true);
  };
  const handlePackageTierDialogClose = () => {
    setPackageTierDialogOpen(false);
  };

  const TierSelectionPanel = ({
    image,
    title,
    originalPrice,
    discountedPrice,
    description,
    isSelected,
    buttonText,
    tierValue,
  }) => {
    return (
      <StyledPaper
        elevation={3}
        isSelected={isSelected}
        sx={{ gap: 3, position: "relative" }}
      >
        {isSelected && (
          <CheckCircle
            sx={{
              position: "absolute",
              top: 5,
              right: 5,
              color: "secondary.lighter",
            }}
          />
        )}
        <Image height={80} width={80} src={image} alt={title} />

        <Box sx={{ display: "flex" }}>
          <Typography variant="h4">
            <sup>$</sup>
          </Typography>
          <Typography
            variant="h2"
            sx={{
              ml: 2,
              textDecoration: chosenPromoCode ? "line-through" : "none",
              fontWeight: chosenPromoCode ? "normal" : "bold",
              color: chosenPromoCode
                ? "text.disabled"
                : isSelected
                ? "primary.contrastText"
                : "text.secondary",
            }}
          >
            {originalPrice}
          </Typography>
          {chosenPromoCode && (
            <Typography
              variant="h2"
              sx={{
                ml: 2,
              }}
            >
              {discountedPrice}
            </Typography>
          )}
        </Box>
        <Typography variant="h4">{title}</Typography>
        <Typography variant="subtitle2">{description}</Typography>
        <Controller
          control={control}
          name={formSubscriptionChoice}
          render={({ field }) => {
            return (
              <Button
                variant={isSelected ? "contained" : "outlined"}
                size="medium"
                disabled={isSelected}
                onClick={() => {
                  field.onChange(tierValue);
                }}
                {...(isSelected ? { startIcon: <Done /> } : {})}
                sx={{
                  mt: 2,
                  "&.Mui-disabled": {
                    bgcolor: "secondary.lighter",
                    color: "primary.main",
                  },
                }}
              >
                {isSelected ? "Selected" : buttonText}
              </Button>
            );
          }}
        />
      </StyledPaper>
    );
  };
  const originalBasicPrice = priceInfo["BASIC"];
  const originalProPrice = priceInfo["PRO"];
  const discountedBasicPrice = chosenPromoCode
    ? Math.round(originalBasicPrice * (1 - chosenPromoCode.discount))
    : 0;
  const discountedProPrice = chosenPromoCode
    ? Math.round(originalProPrice * (1 - chosenPromoCode.discount))
    : 0;

  return (
    <FlowBox sx={{ p: 2 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {isMobile ? (
          <Typography variant="subtitle1">
            What is the package tier you need? You can always upgrade /
            downgrade in your suite settings later if you need to.
          </Typography>
        ) : (
          <Typography variant="subtitle1">
            What is the package tier you need?. Select one based on your usage.
            For most purposes, Basic Tier should be good. You can always upgrade
            / downgrade whenever you want.
          </Typography>
        )}

        <ComparePricingTiers
          LabelComponent={
            isMobile ? (
              <Difference />
            ) : (
              <Typography variant="button">Compare</Typography>
            )
          }
        />
      </Box>
      <FlowBox
        sx={{
          flexDirection: isMobile ? "column" : "row",
          gap: isMobile ? 2 : 5,
          alignItems: "stretch",
          height: "100%",
          mt: 5,
        }}
      >
        <FlowBox sx={{ width: isMobile ? "100%" : "50%" }}>
          <TierSelectionPanel
            image={"/static/customicons/basicsuiteicon.svg"}
            title="BASIC"
            originalPrice={originalBasicPrice}
            discountedPrice={discountedBasicPrice}
            buttonText={"Select"}
            description={`${creditsInfo["BASIC"]} included credits. Good for medium usage.`}
            isSelected={watch(formSubscriptionChoice) === "BASIC"}
            tierValue="BASIC"
          />
        </FlowBox>
        <FlowBox sx={{ width: isMobile ? "100%" : "50%" }}>
          <TierSelectionPanel
            image={"/static/customicons/prosuiteicon.svg"}
            title="PRO"
            originalPrice={originalProPrice}
            discountedPrice={discountedProPrice}
            buttonText={"Select"}
            description={`${creditsInfo["PRO"]} included credits. Great for heavy usage.`}
            isSelected={watch(formSubscriptionChoice) === "PRO"}
            tierValue="PRO"
          />
        </FlowBox>
      </FlowBox>

      <Box
        sx={{
          gap: 3,
          display: "flex",
          flexDirection: isMobile ? "row" : "column",
          mt: isMobile ? 3 : 6,
        }}
      >
        <Typography variant="h6">Have a promo code?</Typography>

        <Controller
          control={control}
          name={formCouponCode}
          render={({ field }) => {
            return (
              <TextField
                size="small"
                {...field}
                helperText={couponHelperMessage}
                placeholder="Enter Promo Code"
                FormHelperTextProps={{ error: !couponSuccess }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {isMobile ? (
                        <IconButton
                          aria-label="Apply Coupon Code"
                          onClick={handleCheckCouponCode}
                          onMouseDown={handleCheckCouponCode}
                          edge="end"
                        >
                          <Send />
                        </IconButton>
                      ) : (
                        <Button
                          aria-label="Apply Coupon Code"
                          onClick={handleCheckCouponCode}
                          onMouseDown={handleCheckCouponCode}
                          edge="end"
                        >
                          Apply
                        </Button>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            );
          }}
        />
      </Box>

      <SubscriptionTierComparisonDialog
        dialogOpen={packageTierDialogOpen}
        handleDialogClose={handlePackageTierDialogClose}
      />
    </FlowBox>
  );
}
