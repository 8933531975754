// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { useState } from "react";
import PropTypes from "prop-types";
import LoadingSpinner from "blocks/atoms/LoadingSpinner";
import {
  Avatar,
  Box,
  Card,
  IconButton,
  InputAdornment,
  useMediaQuery,
  SvgIcon,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Tooltip,
  Fab,
  Grid,
} from "@mui/material";
import Label from "blocks/atoms/uicomponents/Label";
import { alpha } from "@mui/material/styles";
import {
  Edit as EditIcon,
  ArrowRight as ArrowRightIcon,
  Search as SearchIcon,
  PlusCircle as PlusCircleIcon,
} from "react-feather";
import { Close } from "@mui/icons-material";
import {
  useAppContext,
  getS3ImageWrapper,
  checkIfNull,
  getRolePrivileges,
  useUsersInOrganization,
  useDocketsInSuite,
  useMeetingsInDockets,
  useUsersInSuite,
  useSuite,
  useHandleRouterPush,
} from "@app21/core";

import ProfilesChipsList from "blocks/atoms/ProfilesChipsList";

import { CopyMeetingLinkControl } from "blocks/modules/TrrystVideocall/foundations/components";
import { getDateTimeFormat } from "utils/getDateFormats";
import isDate from "validator/lib/isDate";
import { checkTimeWithinRange } from "utils/timeHelperUtils";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import { useIsMobileBreakpoint } from "hooks";
import { sort } from "fast-sort";
import toast from "react-hot-toast";
import { differenceInMinutes, format, differenceInDays } from "date-fns";

const applyFilters = (dockets, query, filters = []) => {
  return dockets?.filter((docket) => {
    let matches = true;
    let stringSum = JSON.stringify(docket);
    if (query) {
      const properties = ["title", "status"];
      let containsQuery = false;
      // the regex expression is to avoid parsing through html expressions in minutes etc
      // regex expression not being used.. use if needed.. stringSum.toLowerCase().replace(/<[^>]+>/g, '').includes(query.toLowerCase())
      if (stringSum.toLowerCase().includes(query.toLowerCase())) {
        containsQuery = true;
      }
      properties.forEach((property) => {
        if (docket[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if (value && docket[key] !== value) {
        matches = false;
      }
    });

    return matches;
  });
};

const applyPagination = (dockets, page, limit) => {
  return dockets?.slice(page * limit, page * limit + limit);
};

const applySort = (
  dockets = [],
  sortBy = "meetingStartTime",
  sortOrder = "desc"
) => {
  const sortedArray =
    sortOrder === "desc"
      ? sort(dockets).desc((docket) => docket[sortBy])
      : sort(dockets).asc((docket) => docket[sortBy]);
  return sortedArray;
};

const Results = () => {
  const minWidthFlag = useMediaQuery("(min-width:600px)");

  const { userInfo, checkAccessRules } = useAppContext();
  const { selectedSuiteId, selectedOrganizationId } = useAppContext();
  const {
    data: usersInSuite,
    status: usersInSuiteStatus,
    apiError: usersInSuiteApiError,
  } = useUsersInSuite(selectedSuiteId);
  const {
    data: selectedSuite,
    status: selectedSuiteStatus,
    apiError: selectedSuiteApiError,
  } = useSuite(selectedSuiteId);
  const {
    data: usersInOrganization,
    status: usersInOrganizationStatus,
    apiError: usersInOrganizationApiError,
  } = useUsersInOrganization(selectedOrganizationId);
  const {
    data: dockets,
    status: docketsStatus,
    apiError: docketsApiError,
  } = useDocketsInSuite(selectedSuiteId);
  const [listOfDocketIds, setListOfDocketIds] = useState([]);
  const {
    data: docketMeetings,
    status: docketMeetingsStatus,
    apiError: docketMeetingsApiError,
  } = useMeetingsInDockets(listOfDocketIds);

  const dataFetchError =
    selectedSuiteApiError ||
    usersInOrganizationApiError ||
    docketsApiError ||
    docketMeetingsApiError;
  const dataFetchLoading =
    selectedSuiteStatus === "loading" ||
    usersInOrganizationStatus === "loading" ||
    docketsStatus === "loading" ||
    docketMeetingsStatus === "loading";

  React.useEffect(() => {
    if (dataFetchError && !dataFetchLoading) {
      toast.error(dataFetchError.message);
    }
  }, [dataFetchError]);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState("");
  const [sortBy, setSortBy] = React.useState("statusOrder");
  const [sortOrder, setSortOrder] = React.useState("asc");
  const [paginatedDockets, setPaginatedDockets] = useState([]);
  const [filteredDockets, setFilteredDockets] = useState([]);

  const { loadRoute } = useHandleRouterPush();

  const isMobile = useIsMobileBreakpoint();
  const isFetchingData =
    docketsStatus === "loading" ||
    usersInSuiteStatus === "loading" ||
    usersInOrganizationStatus === "loading" ||
    selectedSuiteStatus === "loading" ||
    docketMeetingsStatus === "loading";

  React.useEffect(() => {
    const list = dockets?.map((d) => d._id) ?? [];
    setListOfDocketIds(list);
  }, [docketsStatus]);

  const getChipStyle = (chipType) => {
    switch (chipType) {
      case "draftChip":
        return { backgroundColor: "LemonChiffon" };
        break;
      case "publishedChip":
        return { backgroundColor: "PaleGreen" };
        break;
      case "archivedChip":
        return { backgroundColor: "LightGrey", color: "DarkGrey" };
        break;
      case "completedChip":
        return { backgroundColor: "Snow" };
        break;
      case "scheduledChip":
        return {
          backgroundColor: (theme) => theme.palette.primary.main,
          color: (theme) => theme.palette.primary.contrastText,
        };
    }
  };
  const getStatusLabelInfo = React.useCallback(
    (docket) => {
      let status = "DRAFT";

      if (docket?.status && docket?.status !== "DRAFT") {
        status = docket.status;
        if (
          docketMeetings &&
          docketMeetings[docket._id]?.length &&
          docketMeetings[docket._id][0]?.meetingJSON?.start
        ) {
          let meetingStatusTime = checkTimeWithinRange(
            Date.now(),
            Date.parse(docketMeetings[docket._id][0]?.meetingJSON?.start),
            Date.parse(docketMeetings[docket._id][0]?.meetingJSON?.end)
          );
          if (meetingStatusTime > 0) status = "COMPLETED";
          else if (meetingStatusTime === 0) status = "ONGOING";
        }
      } else if (
        docketMeetings &&
        docketMeetings[docket._id]?.length &&
        isDate(docketMeetings[docket._id][0]?.meetingJSON?.start)
      ) {
        status = "SCHEDULED";
      }

      switch (status) {
        // first part is style, second part status label, third part the status order number for sorting
        case "DRAFT":
          return [getChipStyle("draftChip"), "Draft", 1, "secondary"];
        case "SCHEDULED":
          return [
            getChipStyle("scheduledChip"),
            "Draft - Scheduled",
            2,
            "primary",
          ];
        case "ONGOING":
          return [getChipStyle("scheduledChip"), "Ongoing", 2, "primary"];
        case "PUBLISHED":
          return [getChipStyle("publishedChip"), "Published", 3, "success"];
        case "COMPLETED":
          return [getChipStyle("completedChip"), "Completed", 4, "warning"];
        case "ARCHIVED":
          return [getChipStyle("archivedChip"), "Archived", 5, "error"];
        default:
          return [getChipStyle("draftChip"), "Draft", 1, "default"];
      }
    },
    [docketMeetings]
  );

  const getDocketStatusChip = (docket) => (
    <Label variant={"ghost"} color={getStatusLabelInfo(docket)[3]}>
      {getStatusLabelInfo(docket)[1]}
    </Label>
  );

  React.useEffect(() => {
    let filledDockets = [];

    dockets?.forEach((docket) => {
      let tempDocket;
      let membersInfo = [];
      docket.members?.forEach((member) =>
        membersInfo.push(
          usersInOrganization?.find((user) => user._id === member)
        )
      );
      let combinedInviteesInfo = [...membersInfo, ...(docket?.visitors || [])];
      let statusLabel = getStatusLabelInfo(docket)[1];
      let statusOrder = getStatusLabelInfo(docket)[2];

      let meetingInfo =
        docketMeetings && docketMeetings[docket._id]?.length
          ? docketMeetings[docket._id][0]
          : {};
      let meetingStartTime = new Date(
        meetingInfo?.meetingJSON?.start
      ).valueOf();
      let meetingEndTime = new Date(meetingInfo?.meetingJSON?.end).valueOf();
      tempDocket = {
        ...docket,
        membersInfo,
        statusLabel,
        statusOrder,
        meetingInfo,
        combinedInviteesInfo,
        meetingStartTime,
        meetingEndTime,
      };

      filledDockets.push(tempDocket);
    });

    let filtered = applyFilters(filledDockets, query);
    setFilteredDockets(filtered);
    const sortedDockets = applySort(filtered, sortBy, sortOrder);
    setPaginatedDockets(applyPagination(sortedDockets, page, limit));
  }, [dockets, docketMeetingsStatus, sortBy, sortOrder, query, page, limit]);

  const handleQueryChange = (event) => {
    event.persist();
    setQuery(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value));
  };

  const handleDocketViewClick = (
    event,
    docketId,
    orgId,
    suiteId,
    userIsDocketVisitor
  ) => {
    event.stopPropagation();
    loadRoute("VIEW-DOCKET", { docketId, orgId, suiteId, userIsDocketVisitor });
  };

  const handleDocketEditClick = (event, docketId) => {
    event.stopPropagation();
    loadRoute("EDIT-DOCKET", { docketId: docketId });
  };

  const handleSortToggle = (event, field) => {
    event.persist();
    setSortBy(field);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const SortButton = ({ fieldName }) => {
    return (
      <IconButton
        size="small"
        sx={{ ml: 1 }}
        onClick={(event) => handleSortToggle(event, fieldName)}
      >
        {sortBy === fieldName ? (
          sortOrder === "asc" ? (
            <ArrowUpwardOutlinedIcon fontSize={"inherit"} color="primary" />
          ) : (
            <ArrowDownwardOutlinedIcon fontSize={"inherit"} color="primary" />
          )
        ) : (
          <ArrowDownwardOutlinedIcon fontSize={"inherit"} color="disabled" />
        )}
      </IconButton>
    );
  };

  const { accessFlag: canCreateDocket } = checkAccessRules({
    entity: "SUITE",
    action: "CREATE-DOCKET",
    featureName: "DOCKET",
    ...getRolePrivileges({
      userId: userInfo._id,
      baseObject: selectedSuite,
      members: usersInSuite.map((user) => user._id),
    }),
  });
  const { accessFlag: canAccessMembers } = checkAccessRules({
    entity: "USERS-VISIBILITY",
    action: "VIEW-ALL-SUITE-USERS",
    featureName: "SUITE-USER-DETAILS",
    isInvitee: true,
  });

  if (isFetchingData) return <LoadingSpinner size={20} />;
  else if (checkIfNull(query) && checkIfNull(paginatedDockets)) {
    return (
      <Card sx={{ flexGrow: 1, overflow: "auto !important" }}>
        <Box
          sx={{
            m: 1,
            p: 1,
            minHeight: 56,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" sx={{ m: 1 }}>
            No Breakouts scheduled yet.
          </Typography>
          {canCreateDocket && (
            <>
              <Typography variant="h6" sx={{ mb: 2 }}>
                You can get started by creating one.
              </Typography>
              <Fab
                color="secondary"
                variant="extended"
                onClick={() =>
                  loadRoute("CREATE-DOCKET", { orgId: selectedOrganizationId })
                }
                sx={{ ml: 2 }}
              >
                <SvgIcon style={{ marginRight: 10 }}>
                  <PlusCircleIcon />
                </SvgIcon>
                New
              </Fab>
            </>
          )}
        </Box>
      </Card>
    );
  } else
    return (
      <Card sx={{ flexGrow: 1, overflow: "auto !important" }}>
        <Box
          sx={{
            mt: 1,
            p: 1,
            minHeight: 56,
            display: "flex",
            alignItems: "center",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item xs={12} sm={6} lg={6} xl={6}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                  endAdornment: !checkIfNull(query) && (
                    <InputAdornment position="end">
                      <IconButton size="small" onClick={() => setQuery("")}>
                        <Close fontSize="inherit" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={handleQueryChange}
                placeholder="Search meetings"
                value={query}
              />
            </Grid>
            <Grid container item xs={12} sm={6} lg={6} xl={6}>
              <Grid item xs={2}>
                {canCreateDocket && (
                  <Fab
                    color="secondary"
                    variant={minWidthFlag ? "extended" : "circular"}
                    onClick={() => {
                      loadRoute("CREATE-DOCKET", {
                        orgId: selectedOrganizationId,
                      });
                    }}
                    sx={{ ml: 1, minWidth: 50, height: 50 }}
                  >
                    <SvgIcon sx={{ mr: minWidthFlag ? 2 : 0 }}>
                      <PlusCircleIcon />
                    </SvgIcon>
                    {minWidthFlag && "New"}
                  </Fab>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <TableContainer
          sx={{
            px: 1,

            display: "flex",
            flexDirection: "column",
            overflow: "auto !important",
            minHeight: 150,
          }}
        >
          <Table
            stickyHeader={true}
            size="small"
            sx={{
              borderSpacing: ({ viewMode }) =>
                viewMode === "summary" ? 0 : `0 4px !important`,
              borderCollapse: `separate !important`,
            }}
          >
            <TableHead
              sx={{
                borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                "& th": { backgroundColor: "transparent" },
              }}
            >
              <TableRow>
                <TableCell>
                  Breakout Meeting
                  <SortButton fieldName="title" />
                </TableCell>
                {minWidthFlag && (
                  <TableCell sx={{ width: 160 }}>
                    Schedule <SortButton fieldName="meetingStartTime" />
                  </TableCell>
                )}
                {minWidthFlag && (
                  <TableCell sx={{ width: 110, maxWidth: 110 }}>
                    Status
                    <SortButton fieldName="statusOrder" />
                  </TableCell>
                )}
                <TableCell
                  align="center"
                  sx={{
                    width: 110,
                    maxWidth: 110,
                    paddingRight: "0 !important",
                    marginRight: "0 !important",
                  }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {checkIfNull(paginatedDockets) ? (
                <tr>
                  <td colSpan={5}>
                    <Typography variant="body1" sx={{ mt: 2, ml: 3 }}>
                      No matching searches.
                    </Typography>
                  </td>
                </tr>
              ) : (
                paginatedDockets?.map((docket, index) => {
                  const createdByUser = usersInOrganization?.find(
                    (user) => user._id === docket.createdBy
                  );

                  let userIsDocketVisitor = false;
                  docket.visitors?.map((v) => {
                    if (v._id === userInfo._id) userIsDocketVisitor = true;
                  });

                  const { accessFlag: canViewDocket } = checkAccessRules({
                    entity: "DOCKET",
                    action: "VIEW-DOCKET",
                    featureName: "DOCKET",
                    ...getRolePrivileges({
                      userId: userInfo._id,
                      baseObject: docket,
                      members: [
                        ...(docket.members ?? []),
                        ...(docket.visitors
                          ? docket.visitors.map((v) => v._id)
                          : []),
                      ],
                    }),
                  });
                  const { accessFlag: canEditDocket } = checkAccessRules({
                    entity: "DOCKET",
                    action: "UPDATE-DOCKET",
                    featureName: "DOCKET",
                    ...getRolePrivileges({
                      userId: userInfo._id,
                      baseObject: docket,
                      members: docket.members ?? [],
                    }),
                  });

                  let meetingDuration = differenceInMinutes(
                    new Date(docket.meetingInfo?.meetingJSON?.end),
                    new Date(docket.meetingInfo?.meetingJSON?.start)
                  );
                  let meetingDurationString = null;

                  console.log(
                    "MEETINGDURATION TEST",
                    parseFloat(meetingDuration)
                  );
                  if (parseFloat(meetingDuration) === 0) {
                    meetingDurationString = null;
                  } else if (parseFloat(meetingDuration) < 60) {
                    meetingDuration = differenceInMinutes(
                      new Date(docket.meetingInfo?.meetingJSON?.end),
                      new Date(docket.meetingInfo?.meetingJSON?.start)
                    );
                    meetingDurationString = `${meetingDuration} minutes`;
                  } else {
                    if (parseFloat(meetingDuration) < 1440) {
                      meetingDurationString = `${
                        Math.round((meetingDuration * 10) / 60) / 10
                      } hours`;
                    } else {
                      meetingDurationString = `${differenceInDays(
                        new Date(docket.meetingInfo?.meetingJSON?.end),
                        new Date(docket.meetingInfo?.meetingJSON?.start)
                      )} days`;
                    }
                  }

                  return (
                    <TableRow
                      hover
                      key={docket._id}
                      onClick={(event) =>
                        canViewDocket &&
                        handleDocketViewClick(
                          event,
                          docket._id,
                          docket.organizationId,
                          docket.suiteId,
                          userIsDocketVisitor
                        )
                      }
                      sx={{
                        "& .MuiTableRow-root": {
                          borderBottom: "none",
                          borderRadius: 5,
                          padding: 0,
                          cursor: "pointer",
                          "&$tableRowHovered": { padding: 0 },
                          "&$tableRowHovered:hover": {
                            cursor: "pointer",
                            backgroundColor: (theme) =>
                              alpha(theme.palette.secondary.lighter, 0.2),
                            boxShadow:
                              "0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15) !important",
                          },
                        },
                        "& .MuiTableRow-hover": {
                          cursor: "pointer",
                          backgroundColor: (theme) =>
                            theme.palette.background.default,
                        },
                      }}
                    >
                      <TableCell
                        sx={{
                          paddingLeft: (theme) => theme.spacing(2),
                          borderBottom: "none !important",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Tooltip
                            title={`Created by ${createdByUser?.fullName}`}
                            arrow
                          >
                            <Avatar
                              sx={{
                                height: 32,
                                width: 32,
                                marginRight: (theme) => theme.spacing(2),
                              }}
                              src={getS3ImageWrapper(
                                createdByUser?.image,
                                "person"
                              )}
                              alt={createdByUser?.fullName || index}
                            />
                          </Tooltip>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                variant="subtitle1"
                                sx={{ color: "text.secondary" }}
                              >
                                {docket.title}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                mt: 0,
                              }}
                            >
                              {docket?.combinedInviteesInfo.length ? (
                                canAccessMembers ? (
                                  <ProfilesChipsList
                                    usersList={docket?.combinedInviteesInfo}
                                    dense={true}
                                    showAs={isMobile ? "avatar" : "chip"}
                                  />
                                ) : (
                                  <></>
                                )
                              ) : (
                                "No Invitees added"
                              )}
                            </Box>
                            {!minWidthFlag && (
                              <Box>
                                {checkIfNull(
                                  docket?.meetingInfo?.meetingJSON?.start
                                ) ? null : (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    {format(
                                      new Date(
                                        docket.meetingInfo.meetingJSON.start
                                      ),
                                      getDateTimeFormat({
                                        startDate:
                                          docket.meetingInfo.meetingJSON.start,
                                      })
                                    )}

                                    {meetingDurationString && (
                                      <span>
                                        {" "}
                                        Duration - {meetingDurationString}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </Box>
                            )}
                            {!minWidthFlag && (
                              <Box>{getDocketStatusChip(docket)}</Box>
                            )}
                          </div>
                        </Box>
                      </TableCell>

                      {minWidthFlag && (
                        <TableCell sx={{ borderBottom: "none !important" }}>
                          {checkIfNull(
                            docket?.meetingInfo?.meetingJSON?.start
                          ) ? null : (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <span>
                                {format(
                                  new Date(
                                    docket.meetingInfo.meetingJSON.start
                                  ),
                                  getDateTimeFormat({
                                    startDate:
                                      docket.meetingInfo.meetingJSON.start,
                                  })
                                )}
                              </span>
                              {meetingDurationString && (
                                <span>Duration - {meetingDurationString}</span>
                              )}
                              {getStatusLabelInfo(docket)[1] !== "ARCHIVED" ||
                              getStatusLabelInfo(docket)[1] !== "COMPLETED" ? (
                                <CopyMeetingLinkControl
                                  meetingId={docket?.meetingInfo?._id}
                                  componentType={"icon"}
                                />
                              ) : null}
                            </div>
                          )}
                        </TableCell>
                      )}
                      {minWidthFlag && (
                        <TableCell sx={{ borderBottom: "none !important" }}>
                          {getDocketStatusChip(docket)}
                        </TableCell>
                      )}
                      <TableCell
                        align="right"
                        sx={{
                          borderBottom: "none !important",
                          width: 110,
                          maxWidth: 110,
                          paddingRight: "0 !important",
                          marginRight: "0 !important",
                        }}
                      >
                        {canEditDocket && (
                          <IconButton
                            onClick={(event) =>
                              handleDocketEditClick(event, docket._id)
                            }
                          >
                            <SvgIcon fontSize="small">
                              <EditIcon />
                            </SvgIcon>
                          </IconButton>
                        )}
                        {canViewDocket && (
                          <IconButton
                            onClick={(event) =>
                              handleDocketViewClick(
                                event,
                                docket._id,
                                docket.organizationId,
                                docket.suiteId,
                                userIsDocketVisitor
                              )
                            }
                          >
                            <SvgIcon fontSize="small">
                              <ArrowRightIcon />
                            </SvgIcon>
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {filteredDockets.length > 5 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredDockets.length}
            rowsPerPage={limit}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
          />
        )}
      </Card>
    );
};

Results.propTypes = {
  className: PropTypes.string,
  dockets: PropTypes.array.isRequired,
};

Results.defaultProps = {
  dockets: [],
};

export default Results;
