import React from "react";
import { Box, Typography, Avatar, TextField, Grid } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { EditableImage } from "blocks/atoms";
import { getS3ImageWrapper } from "@app21/core";
import isAlphanumeric from "validator/lib/isAlphanumeric";

export default function AddSuiteStepForm({
  formSuiteName = "suiteName",
  formSuiteImage = "suiteImage",
}) {
  const { watch, control, setValue, getValues, formState } = useFormContext();
  const { errors } = formState;

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          p: 1,
          mb: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* <Typography variant="h5" gutterBottom>
          Enter your suite information
        </Typography> */}

        <Typography variant="body1" gutterBottom sx={{ mb: 4 }}>
          What would you like to call your suite? You can always change this
          later. Further, adding a picture helps you and your team to identify
          your suite easily.
        </Typography>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={9} lg={3} sx={{ alignSelf: "center" }}>
          <Typography variant="subtitle1">Name of Suite</Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={9}>
          <Controller
            name={formSuiteName}
            control={control}
            rules={{
              required: {
                value: true,
                message: "A name for the suite is needed.",
              },
              maxLength: {
                value: 50,
                message: "Sorry. Too long. Limit to 25 characters",
              },
              minLength: {
                value: 4,
                message: "Too short. Minimum 4 characters",
              },
              validate: {
                validText: (value) =>
                  isAlphanumeric(value, "en-US", {
                    ignore: "/^(?=.*[0-9])[- +()0-9]+$/",
                  }),
              },
            }}
            render={({ field }) => {
              return (
                <TextField
                  fullWidth
                  error={!!(errors && errors[formSuiteName])}
                  helperText={
                    errors &&
                    (errors[formSuiteName]?.message ||
                      (errors?.fullName?.type === "validText" &&
                        "Special characters are not allowed"))
                  }
                  label="Name of the suite"
                  placeholder="What would you like to call your Suite?"
                  onChange={(event, newValue) => {
                    field.onChange(event.target.value);
                  }}
                  value={field.value}
                />
              );
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 4 }}>
        <Grid item xs={12} md={6} lg={3}>
          <Typography variant="subtitle1" sx={{ alignSelf: "center" }}>
            Suite Logo
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={9}>
          <Controller
            control={control}
            name={formSuiteImage}
            render={({ field }) => {
              return (
                <EditableImage
                  labelText="Upload Picture for Suite"
                  currentImage={field.value}
                  handleFileUpload={field.onChange}
                  imageProps={{ sx: { height: 120, width: 120 } }}
                  StyledBoxProps={{
                    sx: {
                      height: 180,
                      width: 180,
                      mt: 2,
                      alignItems: "flex-start",
                    },
                  }}
                />
              );
            }}
          />
        </Grid>
      </Grid>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Box
          sx={{
            p: 1,
            width: 180,
            height: 180,
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        ></Box>
        <Box sx={{ p: 1, flexGrow: 3, width: 400, maxWidth: 550 }}></Box>
      </Box>
    </Box>
  );
}
