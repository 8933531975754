// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Typography } from "@mui/material";
import { useIntl } from "react-intl";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Visibility, FilterList, ClearAll } from "@mui/icons-material";

export default function TasksViewSwitcher({
  defaultValue,
  handleChoice,
  context,
}) {
  const handleViewSwitch = (event, newChoice) => {
    handleChoice(newChoice);
  };

  const intl = useIntl();
  const { formatMessage } = intl;

  const t = (id) => formatMessage({ id });

  return (
    <ToggleButtonGroup
      value={defaultValue}
      exclusive
      onChange={handleViewSwitch}
      aria-label="Layout Selection"
      sx={{ mt: 1 }}
    >
      {context === "docket" && (
        <ToggleButton
          size="small"
          value="alltasks"
          sx={{
            "& .MuiToggleButton-root": {
              backgroundColor: (theme) => theme.palette.background.default,
              color: (theme) => theme.palette.text.primary,
            },
            "&.Mui-selected": {
              backgroundColor: (theme) =>
                `${theme.palette.primary.dark} !important`,
              color: (theme) =>
                `${theme.palette.primary.contrastText} !important`,
            },
          }}
          aria-label="All"
        >
          <ClearAll fontSize="inherit" />
          <Typography
            variant="subtitle2"
            sx={{ ml: (theme) => theme.spacing(1), textTransform: "none" }}
          >
            {`${t("SuiteDashboard.Tasks.allTasks")}`}
          </Typography>
        </ToggleButton>
      )}
      <ToggleButton
        size="small"
        value="mytasks"
        sx={{
          "& .MuiToggleButton-root": {
            backgroundColor: (theme) => theme.palette.background.default,
            color: (theme) => theme.palette.text.primary,
          },
          "&.Mui-selected": {
            backgroundColor: (theme) =>
              `${theme.palette.primary.dark} !important`,
            color: (theme) =>
              `${theme.palette.primary.contrastText} !important`,
          },
          px: 2,
        }}
        aria-label="My Tasks"
      >
        <FilterList fontSize="inherit" />
        <Typography
          variant="subtitle2"
          sx={{ ml: (theme) => theme.spacing(1), textTransform: "none" }}
        >
          {`${t("SuiteDashboard.Tasks.myTasks")}`}
        </Typography>
      </ToggleButton>
      <ToggleButton
        size="small"
        value="watching"
        sx={{
          "& .MuiToggleButton-root": {
            backgroundColor: (theme) => theme.palette.background.default,
            color: (theme) => theme.palette.text.primary,
          },
          "&.Mui-selected": {
            backgroundColor: (theme) =>
              `${theme.palette.primary.dark} !important`,
            color: (theme) =>
              `${theme.palette.primary.contrastText} !important`,
          },
          px: 2,
        }}
        aria-label="Watching"
      >
        <Visibility fontSize="inherit" />
        <Typography
          variant="subtitle2"
          sx={{ ml: (theme) => theme.spacing(1), textTransform: "none" }}
        >
          {`${t("SuiteDashboard.Tasks.watching")}`}
        </Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
