import { SvgIcon } from "@mui/material";
import React from "react";

export default function ShareScreenWebInactiveIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 56 56"
      stroke="#727287"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <rect
          width="56"
          height="56"
          rx="5.6"
          stroke="none"
          fill="currentColor"
        />
        <path
          d="M41.1475 28.1484V32.9809C41.1475 37.0646 38.425 39.7871 34.3412 39.7871H32.98"
          strokeWidth="2.0083"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.9229 23.4517V22.0904C13.9229 18.0067 16.6454 15.2842 20.7291 15.2842H34.3416C38.4254 15.2842 41.1479 18.0067 41.1479 22.0904"
          strokeWidth="2.0083"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.2246 27.1406C22.5136 27.9438 27.1282 32.572 27.945 38.861"
          strokeWidth="2.0083"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.7676 31.7148C19.3822 32.3002 22.7717 35.7033 23.3707 40.318"
          strokeWidth="2.0083"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.8955 36.874C16.196 37.1735 17.8976 38.8615 18.1971 41.1756"
          strokeWidth="2.0083"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
