import React from 'react'
import { SvgIcon } from "@mui/material";

export default function ForwardIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M16.6661 25.0011C11.4578 31.9595 8.33276 40.6261 8.33276 50.0011C8.33276 73.0011 26.9994 91.6678 49.9994 91.6678C72.9994 91.6678 91.6661 73.0011 91.6661 50.0011C91.6661 27.0011 72.9994 8.33447 49.9994 8.33447C44.0411 8.33447 38.3328 9.58447 33.2078 11.8761"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.1672 42.3727V57.6227C29.1672 60.7477 32.5631 62.7061 35.2714 61.1436L41.8756 57.3519L48.4797 53.5394C48.8964 53.2894 49.2298 53.0186 49.5006 52.6852V47.3519C49.2298 47.0186 48.8964 46.7477 48.4797 46.4977L41.8756 42.6852L35.2714 38.8936C32.5631 37.2894 29.1672 39.2477 29.1672 42.3727Z"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M55.5839 38.8548C52.8756 37.2923 49.4797 39.2507 49.4797 42.3757V57.6257C49.4797 60.7507 52.8756 62.709 55.5839 61.1465L62.1881 57.3548L68.7922 53.5424C71.5005 51.9799 71.5005 48.084 68.7922 46.5007L64.2506 43.8757"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
