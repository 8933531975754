// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Box, Grid, Typography } from "@mui/material";

export interface InvoiceCardProps {
  invoiceNumber: string;
  amount?: number;
  currencySymbol?: string;
  isPaid?: boolean;
  downloadUrl?: string;
  previewUrl?: string;
}

const InvoiceCard: React.FC<InvoiceCardProps> = (props) => {
  const {
    invoiceNumber,
    currencySymbol,
    amount,
    isPaid,
    downloadUrl,
    previewUrl,
  } = props;

  const currency = React.useMemo(() => {
    switch (currencySymbol) {
      case "$":
        return "USD";
    }
  }, [currencySymbol]);

  return (
    <Box sx={{ background: "#fff", margin: "5px 0px", padding: "10px 20px" }}>
      <Grid container spacing={2}>
        <Grid item sm={4} lg={4}>
          <Typography sx={{ display: "inline" }}>{invoiceNumber}</Typography>
        </Grid>
        <Grid item sm={4} lg={4}>
          <Typography sx={{ display: "inline" }}>
            {amount?.toLocaleString("en-us", {
              style: "currency",
              currency,
            })}
          </Typography>
        </Grid>
        <Grid item sm={4} lg={4}>
          <a target="_blank" href={previewUrl}>
            {isPaid ? "View" : "Pay online"}
          </a>
          <Box sx={{ margin: "0px 5px" }} />
          <a target="_blank" href={downloadUrl}>
            Download
          </a>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InvoiceCard;
