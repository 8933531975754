import { Fragment } from "react";
import { Box, Typography, SvgIcon, IconButton } from "@mui/material";
import {
  Chat,
  MarkChatUnread,
  MarkChatUnreadOutlined,
  ChatBubble,
} from "@mui/icons-material";
import { MessageCircle } from "react-feather";
{
  /* <SvgIcon sx={{ bgcolor: 'primary.dark' }} {...rest}>
          <MessageCircle fontSize="inherit" />
        </SvgIcon>
      */
}
export default function TextInsideIcon({
  label = "",
  onClick = () => null,
  LabelProps = {},
  children,
  ...rest
}) {
  const WrapperComponent = onClick ? IconButton : Fragment;
  const WrapperProps = onClick ? { onClick, sx: { p: 0 } } : {};
  return (
    <Box sx={{ position: "relative", m: 0, p: 0.5, borderRadius: 1 }}>
      {children || (
        <WrapperComponent {...WrapperProps}>
          <ChatBubble color="primary" {...rest} />
        </WrapperComponent>
      )}
      {Boolean(label) && (
        <Typography
          variant="caption"
          sx={{
            m: 0,
            p: 0,
            position: "absolute",
            top: "40%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "white",
          }}
          {...LabelProps}
        >
          {label}
        </Typography>
      )}
    </Box>
  );
}
