// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import { SvgIcon } from "@mui/material";

export default function VideocallEventIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g stroke="none" strokeWidth="2" fill="none" fillRule="evenodd">
        <path
          d="M17,4 L17,4.5 C17,4.77614237 16.7761424,5 16.5,5 C16.2238576,5 16,4.77614237 16,4.5 L16,4 L12,4 L12,4.5 C12,4.77614237 11.7761424,5 11.5,5 C11.2238576,5 11,4.77614237 11,4.5 L11,4 L7,4 L7,4.5 C7,4.77614237 6.77614237,5 6.5,5 C6.22385763,5 6,4.77614237 6,4.5 L6,4 L4.5,4 C4.22356503,4 4,4.22371954 4,4.5 L4,6 L19,6 L19,4.5 C19,4.22371954 18.776435,4 18.5,4 L17,4 Z M17,3 L18.5,3 C19.3288744,3 20,3.67158938 20,4.5 L20,18.5 C20,19.3284106 19.3288744,20 18.5,20 L4.5,20 C3.67112557,20 3,19.3284106 3,18.5 L3,4.5 C3,3.67158938 3.67112557,3 4.5,3 L6,3 L6,1.5 C6,1.22385763 6.22385763,1 6.5,1 C6.77614237,1 7,1.22385763 7,1.5 L7,3 L11,3 L11,1.5 C11,1.22385763 11.2238576,1 11.5,1 C11.7761424,1 12,1.22385763 12,1.5 L12,3 L16,3 L16,1.5 C16,1.22385763 16.2238576,1 16.5,1 C16.7761424,1 17,1.22385763 17,1.5 L17,3 Z M19,7 L4,7 L4,18.5 C4,18.7762805 4.22356503,19 4.5,19 L18.5,19 C18.776435,19 19,18.7762805 19,18.5 L19,7 Z M13,9.5 L13,10.5657415 L15.2226499,9.08397485 C15.5549273,8.86245661 16,9.10065231 16,9.5 L16,15.5 C16,15.8993477 15.5549273,16.1375434 15.2226499,15.9160251 L13,14.4342585 L13,15.5 C13,15.7761424 12.7761424,16 12.5,16 L7.5,16 C7.22385763,16 7,15.7761424 7,15.5 L7,9.5 C7,9.22385763 7.22385763,9 7.5,9 L12.5,9 C12.7761424,9 13,9.22385763 13,9.5 Z M15,14.5657415 L15,10.4342585 L12.7773501,11.9160251 C12.4450727,12.1375434 12,11.8993477 12,11.5 L12,10 L8,10 L8,15 L12,15 L12,13.5 C12,13.1006523 12.4450727,12.8624566 12.7773501,13.0839749 L15,14.5657415 Z"
          fill="currentColor"
          fillRule="nonzero"
        />
      </g>
    </SvgIcon>
  );
}
