// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import { checkIfNull } from "@app21/core";

export default function processSimpleSurveyBuilder(
  surveyData,
  selectedSuiteId,
  defaultSurvey
) {
  let modQuestions = [];
  let currentDateTime = Date.now();

  surveyData.questionBlock.forEach((question) => {
    let modQuestion = { ...question };
    modQuestion.fileAddition = [];
    modQuestions.push(modQuestion);
  });
  let surveyJson = {
    decisionName: surveyData.decisionName,
    questionBlock: modQuestions,
    signatureNeeded: false,
    decisionStatus:
      (checkIfNull(defaultSurvey)
        ? "OPEN"
        : defaultSurvey?.survey?.decisionStatus) ?? "OPEN",
    decisionOpenDate: checkIfNull(defaultSurvey)
      ? currentDateTime
      : defaultSurvey?.survey?.decisionOpenDate,
    decisionCloseDate: null,
    suiteId: selectedSuiteId,
    decisionInvitees: surveyData.decisionInvitees?.map(
      (invitee) => invitee._id
    ), // currently only user is possible as a respondent
  };
  return surveyJson;
}
