import React from 'react'
import { SvgIcon } from '@mui/material';

export default function ImportantIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M83.7525 35.8769C92.2528 37.2936 94.2529 43.4604 88.1277 49.544L77.794 59.8776C76.0439 61.6277 75.0856 65.0028 75.6273 67.4195L78.5857 80.2116C80.9191 90.3369 75.5439 94.2537 66.5853 88.9619L54.1266 81.5867C51.8765 80.2533 48.1681 80.2533 45.8763 81.5867L33.4176 88.9619C24.5006 94.2537 19.0838 90.2953 21.4172 80.2116L24.3756 67.4195C24.9173 65.0028 23.9589 61.6277 22.2089 59.8776L11.8752 49.544C5.79169 43.4604 7.75009 37.2936 16.2504 35.8769L29.5425 33.6684C31.7509 33.2934 34.4176 31.335 35.4176 29.2933L42.7512 14.6262C46.7097 6.66758 53.2099 6.66758 57.21 14.6262L64.5436 29.2933C64.9603 30.1683 65.7103 31.0434 66.5853 31.7517"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
