import * as React from "react";
import { Box, Grid, Typography, IconButton, List } from "@mui/material";
import { InviteNewUser, UserListCard } from "blocks/modules/Admin/foundations";
import {
  ArrowCircleRight,
  ArrowCircleLeft,
  ArrowCircleRightOutlined,
  ArrowCircleLeftOutlined,
} from "@mui/icons-material";
import {
  useAppContext,
  useUsersInOrganization,
  useUsersInSuite,
  useHandleSuiteActions,
} from "@app21/core";
import { FlowBox } from "blocks/atoms";
import PerfectScrollbar from "react-perfect-scrollbar";
import { checkIfNotLastAdminUser } from "utils/suiteHelperUtils";
import { constantCase } from "change-case";
import { useConfirm } from "material-ui-confirm";

export default function SuiteUsersManageTransferList() {
  const { selectedOrganizationId, selectedSuiteId, userInfo } = useAppContext();
  const { data: usersInOrganization, apiError: usersInOrganizationApiError } =
    useUsersInOrganization(selectedOrganizationId);
  const { data: usersInSuite } = useUsersInSuite(selectedSuiteId);
  const { handleSuiteActions, addUserToSuiteStatus } = useHandleSuiteActions();
  const confirm = useConfirm();
  const [selLeft, setSelLeft] = React.useState([]);
  const [selRight, setSelRight] = React.useState([]);

  const handleToggle = (newUser, key) => () => {
    if (key == "left") {
      const currentIndex = selLeft.findIndex((el) => el._id == newUser._id);
      const userList = [...selLeft];
      selLeft.find((user, index) => user._id === newUser._id)
        ? userList.splice(currentIndex, 1)
        : userList.push(newUser);
      setSelLeft([...userList]);
    } else if (key == "right") {
      const currentIndex = selRight.findIndex((el) => el._id == newUser._id);
      const userList = [...selRight];
      selRight.find((user, index) => user._id === newUser._id)
        ? userList.splice(currentIndex, 1)
        : userList.push(newUser);
      setSelRight([...userList]);
    }
  };

  const handleDetachUser = async (user, context) => {
    if (
      constantCase(user?.roleInfo?.role ?? "") === "ADMIN" &&
      !checkIfNotLastAdminUser(usersInOrganization)
    ) {
      confirm({
        title: "Alert!",
        description:
          "You cannot remove the last Administrator from the organization.",
        confirmationText: "Close",
      })
        .then(() => {
          console.log("closed");
        })
        .catch(() => {
          console.log("Cancelled.");
        })
        .finally(() => {
          console.log("deleted.");
        });
    } else {
      const entityPhrase =
        context === "organization-panel" ? "organization" : "suite";
      const questionPhrase =
        user._id === userInfo._id
          ? `You are removing yourself from the ${entityPhrase}. You will lose access to the resource once completed. Proceed?`
          : `Are you sure you want to remove ${user.fullName} from the ${entityPhrase}?`;

      confirm({
        description: questionPhrase,
      })
        .then(() => {
          handleSuiteActions({ action: "DETACH-USER", userId: user._id });
        })
        .catch(() => {
          console.log("Deletion cancelled.");
        })
        .finally(() => {
          console.log("Deletion cancelled.");
        });
    }
  };

  const addUserToSuite = async (user) => {
    await handleSuiteActions(
      {
        action: "ADD-USER",
        userList: [{ userId: user?._id, role: "Normal" }],
        selectedSuiteId,
      },
      null,
      null
    );
  };

  const handleCheckedRight = () => {
    selLeft.map((user, index) => {
      addUserToSuite(user);
    });
    setSelLeft([]);
  };

  const handleCheckedLeft = () => {
    selRight.map((user, index) => {
      handleDetachUser(user, "suite-panel");
    });
    setSelRight([]);
  };
  return (
    <FlowBox sx={{ my: 2, p: 2, bgcolor: "background.paper", borderRadius: 5 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          alignItems: "stretch",
          flexGrow: 1,
          height: "100%",
          gap: 2,
          position: "relative",
        }}
      >
        <FlowBox sx={{ width: "35%", maxWidth: "40%" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="subtitle2">
              Organization Members not in Suite
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <InviteNewUser icon={true} label="INVITE" />
          </Box>
          <PerfectScrollbar
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              flexGrow: 1,
            }}
          >
            <List>
              {usersInOrganization.map((user, index) => {
                if (!usersInSuite?.some((obj) => obj._id === user?._id)) {
                  return (
                    <UserListCard
                      handleToggle={handleToggle}
                      user={user}
                      selLeft={selLeft}
                      selRight={selRight}
                      keyValue="left"
                      key={user._id}
                      usersInOrganization={usersInOrganization}
                    />
                  );
                }
              })}
            </List>
          </PerfectScrollbar>
        </FlowBox>
        <FlowBox
          sx={{
            width: 35,
            flexShrink: 0,
            flexGrow: 0,
            justifyContent: "center",
          }}
        >
          <IconButton
            size="small"
            color="primary"
            onClick={handleCheckedRight}
            disabled={selLeft.length == 0}
            aria-label="move selected right"
          >
            {selLeft.length === 0 ? (
              <ArrowCircleRightOutlined />
            ) : (
              <ArrowCircleRight color="primary" sx={{}} />
            )}
          </IconButton>
          <IconButton
            size="small"
            color="primary"
            onClick={handleCheckedLeft}
            disabled={selRight.length == 0}
            aria-label="move selected left"
            sx={{ mt: 3 }}
          >
            {selRight.length === 0 ? (
              <ArrowCircleLeftOutlined />
            ) : (
              <ArrowCircleLeft color={"primary"} />
            )}
          </IconButton>
        </FlowBox>
        <FlowBox sx={{ minWidth: "40%", flexGrow: 2 }}>
          <Typography variant="subtitle2">Members in Suite</Typography>
          <PerfectScrollbar
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              flexGrow: 1,
            }}
          >
            <List>
              {usersInSuite &&
                usersInSuite.map((user) => {
                  return (
                    <UserListCard
                      handleToggle={handleToggle}
                      user={user}
                      selLeft={selLeft}
                      selRight={selRight}
                      keyValue="right"
                      key={user._id}
                      usersInOrganization={usersInOrganization}
                    />
                  );
                })}
            </List>
          </PerfectScrollbar>
        </FlowBox>
      </Box>
      <Grid container spacing={1} sx={{ p: 2 }}>
        <Grid item xs={5}></Grid>
        <Grid item xs={2}>
          <Grid container direction="column" alignItems="center"></Grid>
        </Grid>
        <Grid item xs={5}></Grid>
      </Grid>
    </FlowBox>
  );
}
