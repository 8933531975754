import React from 'react'
import { SvgIcon } from '@mui/material';

export default function DashboardIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 109 109"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M4 36.4991C4 20.7491 13 13.999 26.5001 13.999H40.0002C39.3502 15.899 39.0002 17.999 39.0002 20.2491V39.7493C39.0002 44.5993 40.6002 48.6992 43.4502 51.5492C46.3002 54.3993 50.4002 55.9993 55.2502 55.9993V62.9494C55.2502 65.4995 58.1502 67.049 60.3003 65.649L74.7503 55.9993H87.7504C90.0004 55.9993 92.1004 55.649 94.0004 54.999V63.4994C94.0004 79.2494 85.0004 85.9995 71.5003 85.9995H26.5001C13 85.9995 4 79.2494 4 63.4994V55.9993"
          stroke="currentColor"
          strokeWidth="6.52174"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M104 39.7503C104 47.2003 100.2 52.8 94.0003 55C92.1002 55.65 90.0002 56.0004 87.7502 56.0004H74.7502L60.3001 65.6501C58.1501 67.0501 55.2501 65.5005 55.2501 62.9505V56.0004C50.4001 56.0004 46.3 54.4003 43.45 51.5503C40.6 48.7003 39 44.6003 39 39.7503V20.2501C39 18.0001 39.35 15.9001 40 14C42.2 7.80002 47.8 4 55.2501 4H87.7502C97.5003 4 104 10.5 104 20.2501"
          stroke="currentColor"
          strokeWidth="6.52174"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.002 104H67.0021"
          stroke="currentColor"
          strokeWidth="6.52174"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M49.0005 85.9995V103.999"
          stroke="currentColor"
          strokeWidth="6.52174"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M86.4779 30.2505H86.5244"
          stroke="currentColor"
          strokeWidth="6.52174"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M72.474 30.25H72.519"
          stroke="currentColor"
          strokeWidth="6.52174"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M58.4795 30.25H58.5245"
          stroke="currentColor"
          strokeWidth="6.52174"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
