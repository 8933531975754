import React from 'react'
import { SvgIcon } from '@mui/material';

export default function UploadFileIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M34.5 52.3248V42.1665L42.1667 49.8332"
          stroke="currentColor"
          strokeWidth="5.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.5 65.1668V61.3335"
          stroke="currentColor"
          strokeWidth="5.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.4999 42.1665L26.8333 49.8332"
          stroke="currentColor"
          strokeWidth="5.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.66675 34.4998C7.66675 15.3332 15.3334 7.6665 34.5001 7.6665H53.6667"
          stroke="currentColor"
          strokeWidth="5.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M84.3334 38.3335V57.5002C84.3334 76.6668 76.6667 84.3335 57.5001 84.3335H34.5001C15.3334 84.3335 7.66675 76.6668 7.66675 57.5002V49.7568"
          stroke="currentColor"
          strokeWidth="5.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M69.0001 38.3332C57.5001 38.3332 53.6667 34.4998 53.6667 22.9998V7.6665L84.3334 38.3332"
          stroke="currentColor"
          strokeWidth="5.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
