// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Chip, Divider, Box, Typography } from "@mui/material";
import { TranscriptionTimelineWithMedia } from "blocks/modules/MeetingRecords/foundations";
import { checkIfNull } from "@app21/core";
import { format } from "date-fns";
import { FlowBox } from "blocks/atoms/uistyles";
import { useRecordingsContext } from "providers/RecordingsProvider";
import { getDateTimeFormat } from "utils/getDateFormats";

const ShowAllTranscriptionsForMeeting = ({}) => {
  const [activeTranscriptIndex, setActiveTranscriptIndex] = React.useState(0);
  const {
    viewContext,
    actionContext,
    selectedMeetingId,
    transcriptionsStatus,
    transcriptions,
    searchContent,
    filteredMeetings,
    handleCloseTranscript,
  } = useRecordingsContext();

  const TimelineChip = () => {
    return (
      <Box>
        <Box
          sx={{
            flexWrap: "wrap",
            my: 1,
            justifyContent: "center",
          }}
        >
          {transcriptions.map((transcription, index) => (
            <Chip
              key={index}
              color={index === activeTranscriptIndex ? "primary" : "default"}
              variant={index === activeTranscriptIndex ? "filled" : "outlined"}
              label={format(
                new Date(transcription?.createdAt),
                getDateTimeFormat({
                  startDate: transcription?.createdAt,
                })
              )}
              onClick={() => {
                setActiveTranscriptIndex(index);
              }}
              size="small"
              sx={{
                height: 20,
                m: 1,
                color: (theme) =>
                  index === activeTranscriptIndex
                    ? theme.palette.primary.contrastText
                    : "default",
              }}
            />
          ))}
        </Box>
      </Box>
    );
  };

  if (!selectedMeetingId) {
    if (!Boolean(filteredMeetings?.length)) {
      return "There are no meetings to display";
    }
    return (
      <FlowBox
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="body2">
          Select a meeting to view recordings & transcripts
        </Typography>{" "}
      </FlowBox>
    );
  } else if (checkIfNull(transcriptions)) {
    return (
      <Box sx={{ p: 2 }}>
        <Typography variant="subtitle2" sx={{ p: 1 }}>
          There are no recorded transcripts for this meeting.
        </Typography>
      </Box>
    );
  } else
    return (
      <FlowBox
        sx={{
          overflow: "hidden",
        }}
      >
        {!checkIfNull(transcriptions) && (
          <TranscriptionTimelineWithMedia
            activeTranscriptIndex={activeTranscriptIndex}
            transcription={transcriptions[activeTranscriptIndex]}
            TimelineChip={TimelineChip}
          />
        )}
      </FlowBox>
    );
};

export default ShowAllTranscriptionsForMeeting;
