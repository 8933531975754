import { app21APIInstance } from "@app21/core";
import axios from "axios";

export const uploadFilesUsingSignedUrlToS3 = async (
  files,
  uploadPrefix,
  selectedBucketId,
  calledBy
) => {
  await Promise.all(
    files.map(async (elem) => {
      const { data } = await app21APIInstance.post(`/s3/fetchurl`, {
        list: [
          {
            bucket: selectedBucketId,
            key: `${uploadPrefix}${elem.file?.name}`,
            contentType: elem.file?.type,
            calledBy: calledBy,
          },
        ],
      });
      const signedUrl = data.result[0].key.signedURL;
      return axios.put(signedUrl, elem.file, {
        headers: {
          "Content-Type": elem.file?.type,
        },
      });
    })
  );
};
