import React from "react";
import {
  Box,
  Typography,
  IconButton,
  ClickAwayListener,
  Button,
} from "@mui/material";

import { FlowBox } from "blocks/atoms/uistyles";
import { useAtom } from "jotai";
import { showRecordingsSidebarFlagAtom } from "blocks/modules/MeetingRecords/recordings-atoms";
import { Close, Add } from "@mui/icons-material";
import { useRecordingsContext } from "providers/RecordingsProvider";
import RecordingsList from "../foundations/sidebar/RecordingsList";
import RecordingsSearchbar from "../foundations/sidebar/RecordingsSearchbar";
import { LoadingSpinner } from "blocks/atoms";

export default function RecordingsSidebar({ isMobile = false }) {
  const [sortOrder, setSortOrder] = React.useState("date|desc");
  const handleSortOrderChange = (val) => setSortOrder(val);
  const {
    useMeetingsInSuiteStatus,
    filteredMeetings,
    setSearchContent,
    searchContent,
    handleSelectMeeting,
    handleCloseTranscript,
    selectedMeetingId,
    transcriptions,
  } = useRecordingsContext();
  const { loadRoute } = useRecordingsContext();

  if (useMeetingsInSuiteStatus === "loading") return <LoadingSpinner />;
  return (
    <FlowBox
      sx={{
        width: "100%",
        maxWidth: isMobile ? "100%" : 450,
        overflow: "hidden",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <RecordingsSearchbar
          useMeetingsInSuiteStatus={useMeetingsInSuiteStatus}
          filteredMeetings={filteredMeetings}
          setSearchContent={setSearchContent}
          searchContent={searchContent}
          sortOrder={sortOrder}
          handleSortOrderChange={handleSortOrderChange}
          handleCloseTranscript={handleCloseTranscript}
        />
      </Box>
      <RecordingsList
        sortOrder={sortOrder}
        handleSelectMeeting={handleSelectMeeting}
        filteredMeetings={filteredMeetings}
        selectedMeetingId={selectedMeetingId}
        transcriptions={transcriptions}
      />
    </FlowBox>
  );
}
