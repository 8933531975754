// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import { SvgIcon } from "@mui/material";

export default function PersonalSuiteIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 700 550"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      sx={{ width: "100%", height: "100%" }}
      {...props}
    >
      <g>
        <path d="m629.01 223.08-209.39-195.64c-18.953-17.703-43.695-27.441-69.621-27.441s-50.668 9.7383-69.621 27.441l-209.39 195.64c-16.188 15.137-21.453 38.234-13.293 58.895 8.1602 20.664 27.703 33.957 49.945 33.957h6.1875v191.1c0 29.219 23.754 52.906 52.906 52.906h366.54c29.219 0 52.906-23.754 52.906-52.906v-191.03h6.1875c22.176 0 41.785-13.359 49.945-33.957 8.1562-20.66 2.957-43.824-13.297-58.961zm-36.586 65.344h-25.598c-4.5391 0-8.1602 3.6836-8.1602 8.1602v210.51c0 14.016-11.383 25.402-25.402 25.402l-174.51-0.003906 10.859-47.773h83.043c0.46094 0 0.92188 0 1.3828-0.066406 15.531-0.65625 28.426-12.898 29.547-28.758 3.4883-49.09-4.4766-99.035-10.66-120.62-4.7383-16.453-21.914-25.926-38.297-21.188-6.9102 1.9727-12.633 6.1875-16.582 11.648l-32.902 38.168h-48.828c-7.6328 0-13.754 6.1875-13.754 13.754 0 7.6328 6.1875 13.754 13.754 13.754h55.145c4.0156 0 7.832-1.7109 10.398-4.8047l15.199-17.637c3.1602 17.438 6.4492 44.812 5.1992 74.359h-69.094c-9.6094 0-18.031 6.6445-20.137 16.055l-16.648 73.043h-149.64c-14.016 0-25.402-11.383-25.402-25.402v-88.898h216.7c3.8164 0 6.9102-3.0938 6.9102-6.9102s-3.0938-6.9102-6.9102-6.9102h-138.39l40.141-76.137 12.176 49.617c1.25 5.1992 6.5156 8.4219 11.777 7.1055 5.2656-1.3164 8.4219-6.5156 7.1055-11.777l-28.164-114.5c-1.25-5.1992-6.5156-8.4219-11.777-7.1055-5.1992 1.25-8.4219 6.5156-7.1055 11.777l12.238 49.75-48.102 91.207-66.598-0.003907v-107.66c0-4.5391-3.6836-8.1602-8.1602-8.1602h-25.598c-23.691 0-35.141-29.02-17.832-45.207l209.46-195.64c14.277-13.359 32.574-20.07 50.801-20.07s36.523 6.6445 50.801 20.004l209.46 195.64c17.309 16.254 5.8555 45.273-17.832 45.273z" />
        <path
          fill="#fdc500"
          d="m447.06 266.11c0 19.445-15.762 35.207-35.207 35.207-19.441 0-35.203-15.762-35.203-35.207 0-19.441 15.762-35.203 35.203-35.203 19.445 0 35.207 15.762 35.207 35.203"
        />
        <path
          fill="#fdc500"
          d="m518.59 340.02c-3.8164-0.32812-7.1055 2.5-7.4375 6.25l-10.199 116.87c-0.92188 10.527-5.7266 20.27-13.488 27.375-7.7656 7.1055-17.898 11.055-28.426 11.055h-60.348c-3.8164 0-6.9102 3.0938-6.9102 6.9102 0 3.8164 3.0938 6.9102 6.9102 6.9102h60.344c14.016 0 27.441-5.1992 37.773-14.676 10.332-9.4766 16.715-22.375 17.898-36.324l10.199-116.87c0.26172-3.8164-2.5-7.1719-6.3164-7.5z"
        />
      </g>
    </SvgIcon>
  );
}
