// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { CircularProgress, Box } from "@mui/material";
import { FlowBox } from "blocks/atoms/uistyles";
import { getNumberFromString } from "utils/getNumberFromString";

const LoadingSpinner = ({
  size = 25,
  variant = "normal",
  ProgressProps = {},
  flowScreenFlag = false,
  randomSeedString = "trryst",
  sx = {},
  ...rest
}) => {
  const BaseBox = ({ children }) => {
    if (flowScreenFlag)
      return (
        <FlowBox
          sx={{
            minHeight: "75vh",
            minWidth: "75vw",
            justifyContent: "center",
            alignItems: "center",
            ...sx,
          }}
        >
          {children}
        </FlowBox>
      );
    else
      return (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ...sx,
          }}
        >
          {children}
        </Box>
      );
  };

  if (variant === "gif") {
    return (
      <BaseBox {...rest}>
        <Box sx={{ position: "relative" }}>
          <object
            type="image/svg+xml"
            data="/static/images/AnimatedTrrystLogoImage2.svg"
            height={size}
            width={size}
          >
            Loading...
          </object>
          <CircularProgress
            size={size + 8}
            thickness={2}
            sx={{
              position: "absolute",
              top: -4,
              left: -4,
              zIndex: 1,
            }}
            {...ProgressProps}
          />
        </Box>
      </BaseBox>
    );
  } else if (variant === "exp") {
    const min = 1;
    const max = 5;
    let loadingFileIndex = getNumberFromString(randomSeedString, max - min) + 1;

    let loadingImagePath = `/static/images/LoadingAnimation${loadingFileIndex}.gif`;
    return (
      <BaseBox {...rest}>
        <Box sx={{ position: "relative" }}>
          <img src={loadingImagePath} height={size} width={size} />
          <CircularProgress
            size={size + 8}
            thickness={2}
            sx={{
              position: "absolute",
              top: -4,
              left: -4,
              zIndex: 1,
            }}
            {...ProgressProps}
          />
        </Box>
      </BaseBox>
    );
  } else {
    return (
      <BaseBox {...rest}>
        <CircularProgress size={size} {...ProgressProps} />
      </BaseBox>
    );
  }
};

export default LoadingSpinner;
