// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import { MicSelection } from "amazon-chime-sdk-component-library-react";
import React from "react";
import MicrophoneActivityPreview from "./MicrophoneActivityPreview";

const MicrophoneDevices = () => {
  return (
    <div>
      <div>Audio</div>
      <MicSelection />
      <MicrophoneActivityPreview />
    </div>
  );
};

export default MicrophoneDevices;
