// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import NextMuiLink from "blocks/atoms/NextMuiLink";
import checkmarkFill from "@iconify/icons-eva/checkmark-fill";
import closeFill from "@iconify/icons-eva/close-fill";
// material
import { styled } from "@mui/material/styles";
import { Card, Button, Typography, Box, Stack, Tooltip } from "@mui/material";
import { Info } from "@mui/icons-material";
// routes
import { PATH_DASHBOARD } from "routes/paths";
//
import Label from "blocks/atoms/uicomponents/Label";
import { SignupButton } from "blocks/atoms";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  maxWidth: 400,
  width: "100%",
  height: "100%",
  border: "1px solid yellow",
  margin: "auto",
  display: "flex",
  position: "relative",
  alignItems: "center",
  flexGrow: 1,
  flexDirection: "column",
  padding: theme.spacing(2),
  [theme.breakpoints.up(414)]: {
    padding: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

PricingPlanTemplateCard.propTypes = {
  index: PropTypes.number,
  card: PropTypes.object,
  currentPlan: PropTypes.string,
};

export default function PricingPlanTemplateCard({
  card,
  index,
  currentPlan,
  handleChoice,
}) {
  const {
    subscription,
    icon,
    price,
    caption,
    lists,
    free,
    beta,
    labelAction,
    actionEnabled,
  } = card;
  const betaPricingMessage =
    "Thanks for trying us out in our Beta Phase. As a special token of thanks, we are not charging you during this period";
  return (
    <RootStyle>
      {index === 1 && (
        <Label
          color="info"
          sx={{
            top: 8,
            right: 8,
            position: "absolute",
          }}
        >
          POPULAR
        </Label>
      )}

      <Typography variant="overline" sx={{ color: "text.secondary" }}>
        {subscription}
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "flex-end", my: 2 }}>
        {/* <Typography
          variant="subtitle1"
          sx={{ color: 'text.secondary', textDecoration: beta ? 'line-through' : 'none' }}
        >
          $
        </Typography>

        <Typography variant="h5" sx={{ mx: 1, textDecoration: beta ? 'line-through' : 'none' }}>
          {price === 0 || free ? 'Free' : price}
        </Typography>

        <Typography
          gutterBottom
          component="span"
          variant="subtitle2"
          sx={{
            alignSelf: 'flex-end',
            color: 'text.secondary',
          }}
        >
          /mo
        </Typography> */}
        {beta && (
          <Tooltip title={betaPricingMessage} arrow>
            <Typography variant="h6" sx={{ mx: 1, textDecoration: "none" }}>
              Free{" "}
              <sup>
                <Info color="info" />{" "}
              </sup>
            </Typography>
          </Tooltip>
        )}
      </Box>

      <Typography
        variant="caption"
        sx={{
          color: "primary.main",
          textTransform: "capitalize",
        }}
      >
        {caption}
      </Typography>

      {/*<Box sx={{ width: 80, height: 80, mt: 2 }}>{icon}</Box>*/}
      <Stack component="ul" spacing={2} sx={{ my: 3, width: 1 }}>
        {lists?.map((item) => (
          <Stack
            key={item.text}
            component="li"
            direction="row"
            alignItems="center"
            spacing={1.5}
            sx={{
              typography: "body2",
              color: item.isAvailable ? "text.primary" : "text.disabled",
            }}
          >
            <Box
              component={Icon}
              icon={item.isAvailable ? checkmarkFill : closeFill}
              sx={{ width: 20, height: 20 }}
            />
            <Typography variant="body2">{item.text}</Typography>
          </Stack>
        ))}
      </Stack>
      <Box sx={{ height: "100%", flexGrow: 1, width: "100%" }} />
      {currentPlan === subscription ? (
        <Button disabled variant="contained" sx={{ my: 1 }}>
          Current
        </Button>
      ) : handleChoice ? (
        <Button
          variant="contained"
          sx={{ my: 1 }}
          disabled={!actionEnabled}
          onClick={() => handleChoice(subscription)}
        >
          {actionEnabled ? labelAction : "Coming Soon"}
        </Button>
      ) : (
        <SignupButton />
      )}
      {/*<Button
        to={PATH_DASHBOARD.root}
        href={PATH_DASHBOARD.root}
        fullWidth
        size="large"
        variant="contained"
        component={Link}
      >
        {labelAction}
      </Button>*/}
    </RootStyle>
  );
}
