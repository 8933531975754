import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { useChatContext } from "providers/TrrystChatProvider";
import { AvatarGroupWithMore, UserAvatar } from "blocks/atoms";
import { ReadMore } from "@mui/icons-material";
import ChatChannelManageOptions from "./ChatChannelManageOptions";
import { format } from "date-fns";
import { getDateTimeFormat } from "utils";

export default function ChatMessageMenubar({
  viewMode = "full",
  isMobile = false,
}) {
  const { handleSidebarToggle, chatPermissions, selectedChannel } =
    useChatContext();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexGrow: 1,
        height: "fit-content",
        pr: 1,
      }}
    >
      {/* {isMobile && viewContext !== "compact" && (
        <IconButton onClick={handleSidebarToggle} sx={{ mr: 1 }}>
          <ReadMore />
        </IconButton>
      )} */}
      {viewMode !== "compact" ? (
        <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="subtitle2">Chat with</Typography>
            <Typography variant="h5" sx={{ ml: 1 }}>
              {selectedChannel.name}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {selectedChannel.type === "MEETINGS" && (
              <Typography variant="subtitle2">
                {format(
                  new Date(selectedChannel.createdAt ?? null),
                  getDateTimeFormat({
                    startDate: selectedChannel.createdAt ?? null,
                  })
                )}
              </Typography>
            )}
            {chatPermissions.canAccessMembers && (
              <AvatarGroupWithMore max={9} size={28} sx={{ ml: 1 }}>
                {selectedChannel.members?.map((member) => (
                  <UserAvatar key={member.id} userId={member.id} size={28} />
                ))}
              </AvatarGroupWithMore>
            )}
          </Box>
        </Box>
      ) : (
        <Box sx={{ flexGrow: 1 }} />
      )}
      {viewMode !== "compact" && <ChatChannelManageOptions />}
    </Box>
  );
}
