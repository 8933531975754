import React from "react";
import {
  Box,
  Typography,
  Grid,
  Stack,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Divider,
  List,
} from "@mui/material";
import { UserAvatar, PrintUserInfo, ProfilesChipsList } from "blocks/atoms";
import { format } from "date-fns";
import {
  checkIfNull,
  useResolutionsInDocket,
  useAppContext,
} from "@app21/core";
import { useDocketsContext } from "providers/DocketsProvider";
import { Forward } from "@mui/icons-material";
import {
  ListAgendaItems,
  ListDocketResolutions,
} from "blocks/modules/Dockets/foundations";
import { ListResolutionQuestions } from "blocks/modules/Surveys/foundations";

export default function ShowCompletedDocketCard({ docket }) {
  const { loadRoute, docketPermissions } = useDocketsContext();

  const {
    data: resolutionsInDocket,
    status: resolutionsInDocketStatus,
    apiError: resolutionsInDocketApiError,
  } = useResolutionsInDocket(docket._id);

  const lastMeeting = docket.relatedMeetings?.slice(-1)[0];
  const allInvitees = [
    ...(lastMeeting?.meetingJSON?.members?.map((member) => member?._id) ?? []),
    ...(lastMeeting.visitors ?? []),
  ];

  return (
    <Card>
      <CardHeader
        avatar={<UserAvatar userId={lastMeeting?.meetingJSON?.createdBy} />}
        title={
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            <PrintUserInfo userId={lastMeeting?.meetingJSON?.createdBy} />
          </Typography>
        }
        subheader={
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {lastMeeting?.meetingStartTime ? (
              <span>
                {" "}
                {format(
                  new Date(lastMeeting.meetingStartTime),
                  "h:mm a d LLL yy "
                )}{" "}
                -{" "}
                {format(
                  new Date(lastMeeting.meetingEndTime),
                  "h:mm a d LLL yy "
                )}
              </span>
            ) : (
              "Not yet Scheduled"
            )}
          </Typography>
        }
      />
      <CardContent sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="h5" color="primary">
          {docket.title}
        </Typography>
        {docketPermissions.checkCanAccessMembers(docket) ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              Attendees -
            </Typography>
            <ProfilesChipsList
              usersList={allInvitees}
              dense={false}
              showAs={"avatar"}
            />
          </Box>
        ) : (
          <></>
        )}
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          onClick={() => loadRoute("VIEW-DOCKET", { docketId: docket._id })}
        >
          View
        </Button>
      </CardActions>
    </Card>
  );
}
