import React from 'react'
import { SvgIcon } from '@mui/material';

export default function DropDownIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M20.2918 20.7909C12.8752 28.2909 8.3335 38.6242 8.3335 49.9992C8.3335 72.9992 27.0002 91.6659 50.0002 91.6659C73.0002 91.6659 91.6668 72.9992 91.6668 49.9992C91.6668 26.9992 73.0002 8.33252 50.0002 8.33252C47.1252 8.33252 44.3335 8.62419 41.5835 9.16585"
          stroke="currentColor"
          strokeWidth="6.36215"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M35.2909 44.7515L49.9992 59.4181L64.7076 44.7515"
          stroke="currentColor"
          strokeWidth="6.36215"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
