// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import { paramCase } from "change-case";
import { useRef, useState } from "react";
import editFill from "@iconify/icons-eva/edit-fill";
import trash2Outline from "@iconify/icons-eva/trash-2-outline";
import moreVerticalFill from "@iconify/icons-eva/more-vertical-fill";
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Link,
} from "@mui/material";
// routes
import { PATH_DASHBOARD } from "routes/paths";
import { useRouter } from "next/router";
import { useConfirm } from "material-ui-confirm";
import { useIsMobileBreakpoint } from "hooks";

// ----------------------------------------------------------------------

SurveyTableMoreMenu.propTypes = {
  onDelete: PropTypes.func,
  surveyEditLinkObj: PropTypes.any,
  canDelete: PropTypes.bool,
  canEdit: PropTypes.bool,
};

export default function SurveyTableMoreMenu({
  onDelete,
  surveyEditLinkObj,
  canDelete = false,
  canEdit = false,
}) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();
  const confirm = useConfirm();
  const isMobile = useIsMobileBreakpoint();

  const handleSurveyDelete = async () => {
    confirm({
      description:
        "Do you want to delete this Survey? All data and responses to the survey will be deleted.",
    })
      .then(() => onDelete())
      .catch((err) => {
        console.log("Deletion cancelled.", err);
      })
      .finally(() => setIsOpen(false));
  };
  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon
          icon={moreVerticalFill}
          width={20}
          height={20}
          style={{ marginTop: isMobile && "-53px" }}
        />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {canEdit && (
          <MenuItem
            onClick={() =>
              router.push(surveyEditLinkObj, surveyEditLinkObj, {
                shallow: true,
              })
            }
            sx={{ color: "text.secondary" }}
          >
            <ListItemIcon>
              <Icon icon={editFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Edit"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}
        {canDelete && (
          <MenuItem
            onClick={handleSurveyDelete}
            sx={{ color: "text.secondary" }}
          >
            <ListItemIcon>
              <Icon icon={trash2Outline} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Delete"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
