import React from "react";
import {
  Box,
  Typography,
  IconButton,
  ClickAwayListener,
  Button,
} from "@mui/material";
import {
  SelectTasksSidebarContext,
  ShowSidebarTasksList,
} from "../foundations";
import { FlowBox } from "blocks/atoms/uistyles";

export default function TasksSidebar({ isMobile = false }) {
  return (
    <FlowBox
      sx={{
        width: "100%",
        maxWidth: isMobile ? "100%" : 450,
        overflow: "hidden",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <SelectTasksSidebarContext />
      </Box>
      <ShowSidebarTasksList />
    </FlowBox>
  );
}
