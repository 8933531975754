// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { Fragment } from "react";
import { filter } from "lodash";
import { Icon } from "@iconify/react";
import { useState } from "react";

import { MVerticalRotateIconButton } from "blocks/atoms/@material-extend";

// material
import { useTheme, styled } from "@mui/material/styles";
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Table,
  Button,
  TableRow,
  Collapse,
  TableHead,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Avatar,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
// redux
// utils

// components
import Scrollbar from "blocks/atoms/uicomponents/Scrollbar";
import SurveyTableMoreMenu from "blocks/modules/Surveys/foundations/SurveyTableMoreMenu";
// ----------------------------------------------------------------------

import {
  checkIfNull,
  useAppContext,
  useUsersInSuite,
  useDockets,
  useResolutionsInDocket,
  useResolutionsInSuite,
  useHandleRouterPush,
  getS3ImageWrapper,
  useHandleResolutionActions,
} from "@app21/core";

//import MaterialTable, { MTableToolbar } from 'material-table';
import { CheckCircle, BarChart, ExpandMore } from "@mui/icons-material";

import LoadingSpinner from "blocks/atoms/LoadingSpinner";

import useDeepCompareEffect from "use-deep-compare-effect";

import SurveySummaryInfo from "blocks/modules/Surveys/foundations/SurveySummaryInfo";

import { constantCase } from "change-case";
import { useIntl } from "react-intl";

import { useIsMobileBreakpoint } from "hooks";
import { sort } from "fast-sort";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";

const ThumbImgStyle = styled("img")(({ theme }) => ({
  width: 32,
  height: 32,
  objectFit: "cover",
  margin: theme.spacing(0, 2),
  borderRadius: "50%",
}));

// ----------------------------------------------------------------------

const sortSurvey = (array, order, orderBy) => {
  const sortedArray =
    order === "desc"
      ? sort(array).desc((docket) => docket[orderBy])
      : sort(array).asc((docket) => docket[orderBy]);
  return sortedArray;
};

// ----------------------------------------------------------------------

export default function SurveySummaryList({ viewContext }) {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("desc");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [orderBy, setOrderBy] = useState("createdAt");
  const [collapseOpen, setCollapseOpen] = useState([]);
  const isMobile = useIsMobileBreakpoint();

  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });

  const { selectedSuiteId, selectedDocketId, checkAccessRules } =
    useAppContext();
  const { data: selectedDocket, status: selectedDocketStatus } =
    useDockets(selectedDocketId);
  const isDocket = viewContext === "resolution" && Boolean(selectedDocketId);
  const { userInfo } = useAppContext();
  const isDocketOwner = userInfo?._id === selectedDocket?.createdBy;
  const { data: usersInSuite, status: usersInSuiteStatus } =
    useUsersInSuite(selectedSuiteId);
  const [resolutions, setResolutions] = React.useState([]);
  const [decisionsData, setDecisionsData] = React.useState(null);
  const { loadRoute } = useHandleRouterPush();
  const { handleResolutionActions } = useHandleResolutionActions();

  const { data: resolutionsInDocket, status: resolutionsInDocketStatus } =
    useResolutionsInDocket(selectedDocketId);
  const { data: resolutionsInSuite, status: resolutionsInSuiteStatus } =
    useResolutionsInSuite(selectedSuiteId, false);

  const { accessFlag: canAddNew } = checkAccessRules({
    entity: isDocket ? "DOCKET" : "SUITE",
    action: isDocket ? "CREATE-RESOLUTION" : "CREATE-SURVEY",
    featureName: isDocket ? "DOCKET-RESOLUTIONS" : "SURVEYS",
    isCreator: isDocket ? isDocketOwner : false,
    isInvitee: true,
  });
  const checkCanEdit = (rowData) =>
    checkAccessRules({
      entity: isDocket ? "RESOLUTIONS" : "SURVEYS",
      action: isDocket ? "UPDATE-RESOLUTION" : "UPDATE-SURVEY",
      featureName: isDocket ? "DOCKET-RESOLUTIONS" : "SURVEYS",
      isCreator: rowData?.createdBy === userInfo?._id ?? false,
      isInvitee:
        rowData?.survey?.decisionInvitees?.includes(userInfo?._id) ?? false,
    })?.accessFlag;

  const checkCanDelete = (rowData) =>
    checkAccessRules({
      entity: isDocket ? "RESOLUTIONS" : "SURVEYS",
      action: isDocket ? "DELETE-RESOLUTION" : "DELETE-SURVEY",
      featureName: isDocket ? "DOCKET-RESOLUTIONS" : "SURVEYS",
      isCreator: rowData?.createdBy === userInfo?._id ?? false,
      isInvitee:
        rowData?.survey?.decisionInvitees?.includes(userInfo?._id) ?? false,
    })?.accessFlag;

  const checkCanView = (rowData) =>
    checkAccessRules({
      entity: isDocket ? "RESOLUTIONS" : "SURVEYS",
      action: isDocket ? "VIEW-RESOLUTION" : "VIEW-SURVEY",
      featureName: isDocket ? "DOCKET-RESOLUTIONS" : "SURVEYS",
      isCreator: rowData?.createdBy === userInfo?._id ?? false,
      isInvitee:
        rowData?.survey?.decisionInvitees?.includes(userInfo?._id) ?? false,
    })?.accessFlag;

  React.useEffect(() => {
    setResolutions((isDocket ? resolutionsInDocket : resolutionsInSuite) ?? []);
  }, [selectedDocketId, resolutionsInDocket, resolutionsInSuite]);

  const surveyStatusOptions = [];
  if (isDocket) {
    surveyStatusOptions.push({ label: "Draft Tabled", value: "DRAFT" });
  }
  surveyStatusOptions.push({ label: "Opened for Voting", value: "OPEN" });
  surveyStatusOptions.push({ label: "Closed", value: "CLOSED" });

  useDeepCompareEffect(() => {
    let surveyData = [];

    resolutions?.forEach((resolution) => {
      let userIsInvited = false;
      let isOwner = userInfo._id === resolution.createdBy;
      let userResponseStatus = "pending";

      userIsInvited = resolution?.survey?.decisionInvitees?.includes(
        userInfo._id
      );

      if (userIsInvited) {
        if (
          !checkIfNull(resolution?.responses) &&
          resolution?.responses?.[userInfo._id]
        ) {
          userResponseStatus = "completed";
        } else {
          if (
            !(
              constantCase(resolution?.survey?.decisionStatus ?? "") ===
              "CLOSED"
            )
          ) {
            userResponseStatus = "noresponse";
          }
        }
      }

      surveyData.push({
        ...resolution,
        responses: resolution.responses,
        decisionId: resolution._id,
        userIsInvited: userIsInvited,
        userResponseStatus: userResponseStatus,
        isOwner: isOwner,
      });
    });

    setDecisionsData(
      surveyData.filter((rowData) => checkCanView(rowData)) ?? []
    );
  }, [resolutions || []]);

  const handleSurveyStatusChange = async (newStatus, rowData) => {
    await handleResolutionActions(
      { action: "SURVEY-STATUS-CHANGE", newStatus, rowData },
      null,
      null
    );
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    /* asd */
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    // setFilterName(event.target.value);
  };

  const handleDeleteSurvey = async (rowData) => {
    if (rowData) {
      await handleResolutionActions(
        { action: "DELETE-SURVEY", rowData: rowData },
        null,
        null
      );
    }
  };
  const getActionRowButtons = (rowData) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: isMobile && "0px",
        }}
      >
        {!checkIfNull(rowData.responses) &&
          Object.keys(rowData.responses).length > 0 && (
            <IconButton
              onClick={(event) => {
                event.preventDefault();
                if (selectedDocketId) {
                  loadRoute("VIEW-RESOLUTION", { decisionId: rowData._id });
                } else {
                  loadRoute("VIEW-SURVEY", { decisionId: rowData._id });
                }
              }}
            >
              <BarChart style={{ marginTop: isMobile && "-48px" }} />
            </IconButton>
          )}
        {rowData.userResponseStatus === "completed" ? (
          <Tooltip title="You have already responded" arrow>
            <CheckCircle
              style={{ color: "green", marginTop: isMobile && "-48px" }}
            />
          </Tooltip>
        ) : (
          constantCase(rowData.survey.decisionStatus ?? "") === "OPEN" &&
          rowData.userIsInvited && (
            <Button
              variant="contained"
              size="small"
              margin="dense"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                if (selectedDocketId) {
                  loadRoute("RESPOND-RESOLUTION", { decisionId: rowData._id });
                } else {
                  loadRoute("RESPOND-SURVEY", { decisionId: rowData._id });
                }
              }}
              style={{ marginTop: isMobile && "-48px" }}
            >
              Respond
            </Button>
          )
        )}
      </div>
    );
  };

  const getSurveyStatusField = (rowData) => {
    return (
      <TextField
        placeholder="Status of Survey"
        fullWidth
        style={{ minWidth: 120, maxWidth: 170 }}
        select
        SelectProps={{ native: true }}
        disabled={!checkCanEdit(rowData)}
        value={constantCase(rowData.survey?.decisionStatus ?? "DRAFT")}
        onChange={(event) =>
          handleSurveyStatusChange(event.target.value, rowData)
        }
      >
        {surveyStatusOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </TextField>
    );
  };
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - decisionsData?.length)
      : 0;

  const filteredSurveys = sortSurvey(decisionsData, order, orderBy);

  const handleCollapseOpen = (_id) => {
    if (collapseOpen.find((id) => id === _id)) {
      setCollapseOpen(collapseOpen.filter((id) => id !== _id));
    } else {
      setCollapseOpen((oldCollapseOpen) => [...oldCollapseOpen, _id]);
    }
  };
  if (
    usersInSuiteStatus === "loading" ||
    selectedDocketStatus === "loading" ||
    resolutionsInDocketStatus === "loading" ||
    resolutionsInSuiteStatus === "loading"
  ) {
    return <LoadingSpinner />;
  }

  const sortSurveyByCreatedDate = () => {
    if (order === "asc") {
      sortSurvey(decisionsData, "desc", orderBy);
      setOrder("desc");
    } else {
      sortSurvey(decisionsData, "asc", orderBy);
      setOrder("asc");
    }
  };

  const SortButton = () => {
    return (
      <IconButton size="small" onClick={() => sortSurveyByCreatedDate()}>
        {order === "asc" ? (
          <ArrowUpwardOutlinedIcon fontSize={"inherit"} color="primary" />
        ) : (
          <ArrowDownwardOutlinedIcon fontSize={"inherit"} color="primary" />
        )}
      </IconButton>
    );
  };

  return (
    <Box
      data-testid="SurveySummaryList"
      sx={{ display: "flex", flexDirection: "column", py: 1 }}
    >
      <Container maxWidth="xl" sx={{ pl: 0, pr: 0 }}>
        <Card sx={{ minHeight: 200 }}>
          <CardHeader
            title={
              isDocket ? (
                "Resolutions"
              ) : (
                <Box sx={{ display: "flex" }}>
                  <Typography variant="h6">Surveys</Typography>
                  <SortButton />
                </Box>
              )
            }
            action={
              canAddNew && (
                <Button
                  variant="contained"
                  onClick={() => {
                    if (selectedDocketId) {
                      loadRoute("CREATE-RESOLUTION", {});
                    } else {
                      loadRoute("CREATE-SURVEY", {});
                    }
                  }}
                >
                  Create
                </Button>
              )
            }
          />
          {filteredSurveys?.length ? (
            //decisionsData, filteredSurveys
            <Scrollbar>
              <TableContainer sx={{ my: 2, overflowX: "hidden" }}>
                <Table>
                  <TableHead sx={{ p: 0, mx: 0 }}>
                    <TableRow>
                      <TableCell style={{ boxShadow: "inset 0px 0 0 #fff" }} />
                      <TableCell align="left" sx={{ maxWidth: 100 }}>
                        {isDocket ? "Resolutions in Breakout" : "Survey Info"}
                      </TableCell>

                      <TableCell align="left" sx={{ maxWidth: 100 }}>
                        Actions
                      </TableCell>

                      {!isMobile && (
                        <TableCell
                          align={isMobile ? "center" : "center"}
                          sx={{ maxWidth: 100 }}
                        >
                          Stage
                        </TableCell>
                      )}
                      <TableCell style={{ boxShadow: "inset 0px 0 0 #fff" }} />
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {filteredSurveys
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        const { _id, survey } = row;
                        const createdBy = usersInSuite?.find(
                          (user) => user._id === row?.createdBy
                        );

                        return (
                          <Fragment key={_id}>
                            <TableRow hover tabIndex={-1}>
                              <TableCell sx={{ maxWidth: 40 }} align="left">
                                <MVerticalRotateIconButton
                                  size="small"
                                  expand={collapseOpen.find((id) => id === _id)}
                                  onClick={() => handleCollapseOpen(_id)}
                                  aria-label="show more"
                                  style={{ marginTop: isMobile && "-61px" }}
                                >
                                  <ExpandMore fontSize="inherit" />
                                </MVerticalRotateIconButton>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                padding="none"
                                sx={{ maxWidth: 100 }}
                              >
                                <Box
                                  sx={{
                                    py: 2,
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Avatar
                                    alt={createdBy?.fullName}
                                    src={getS3ImageWrapper(
                                      createdBy?.image,
                                      "person"
                                    )}
                                    sx={{ mr: 0.5 }}
                                  />
                                  <Typography variant="subtitle2" noWrap>
                                    {survey.decisionName}
                                  </Typography>
                                </Box>
                                {isMobile && (
                                  <Box>{getSurveyStatusField(row)}</Box>
                                )}
                              </TableCell>
                              <TableCell
                                sx={{ maxWidth: 100, mt: isMobile && -3 }}
                              >
                                {getActionRowButtons(row)}
                              </TableCell>
                              {!isMobile && (
                                <TableCell
                                  align={isMobile ? "right" : "center"}
                                  sx={{ maxWidth: 110 }}
                                >
                                  {getSurveyStatusField(row)}
                                </TableCell>
                              )}
                              <TableCell
                                align="center"
                                sx={{ maxWidth: 100, mt: isMobile && "-61px" }}
                              >
                                {(checkCanDelete(row) || checkCanEdit(row)) && (
                                  <SurveyTableMoreMenu
                                    onDelete={() => handleDeleteSurvey(row)}
                                    surveyEditLinkObj={
                                      selectedDocketId
                                        ? loadRoute("EDIT-RESOLUTION", {
                                            decisionId: row._id,
                                            returnPathOnly: true,
                                          })
                                        : loadRoute("EDIT-SURVEY", {
                                            decisionId: row._id,
                                            returnPathOnly: true,
                                          })
                                    }
                                    canDelete={checkCanDelete(row)}
                                    canEdit={
                                      constantCase(
                                        row?.survey?.decisionStatus ?? ""
                                      ) !== "CLOSED" &&
                                      checkCanEdit(row) &&
                                      checkIfNull(row?.responses)
                                    }
                                  />
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                style={{
                                  paddingBottom: 0,
                                  paddingTop: 0,
                                  paddingLeft: 0,
                                  padding: 0,
                                }}
                                colSpan={6}
                              >
                                <Collapse
                                  in={Boolean(
                                    collapseOpen.find((id) => id === _id)
                                  )}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <Box
                                    sx={{
                                      m: 0,
                                      backgroundColor: (theme) =>
                                        theme.palette.background.neutral,
                                    }}
                                  >
                                    <SurveySummaryInfo
                                      surveyInfo={row}
                                      handleDetailsPanel={() => null}
                                    />
                                  </Box>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          </Fragment>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          ) : (
            <CardContent>
              <Typography variant="body1" sx={{ my: 2, textAlign: "center" }}>
                There are no {isDocket ? "Resolutions" : "Surveys"} to display.{" "}
                {canAddNew && "Start by creating one."}
              </Typography>
            </CardContent>
          )}

          {decisionsData?.length > 5 && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={decisionsData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Card>
      </Container>
    </Box>
  );
}
