// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Box,
  Avatar,
  Typography,
  Card,
  CardActions,
  CardActionArea,
  Collapse,
} from "@mui/material";
import { MVerticalRotateIconButton } from "blocks/atoms/@material-extend";
import { styled } from "@mui/material/styles";
import TaskCardFooter from "./TaskCardFooter";
import LinearProgressWithLabel from "blocks/atoms/LinearProgressWithLabel";
import { useUserInfo, getS3ImageWrapper, checkIfNull } from "@app21/core";
import { ExpandMore } from "@mui/icons-material";
import { format, isBefore } from "date-fns";
import clip from "text-clipper";
import toast from "react-hot-toast";
import { getDateTimeFormat } from "utils/getDateFormats";

const DraggableStyleCard = styled(Card)(({ theme }) => ({
  maxWidth: "100%",
  marginRight: 5,

  "&:hover": {
    backgroundColor: theme.palette.background.default,
    cursor: "grab",
  },
}));

const StyledCollapse = styled(Collapse)(({ theme }) => ({
  whiteSpace: "pre-wrap",
  maxWidth: 250,
  padding: 5,
  overflowWrap: "break-word",
  textAlign: "left",
}));
const TaskCard = ({
  showDeleteButton,
  onDelete,
  onClick,
  style,
  tagStyle,
  className,
  id,
  title,
  label,
  metadata,
  description,
  tags,
  ...rest
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const {
    data: userInfo,
    apiError: userInfoApiError,
    status: userInfoStatus,
  } = useUserInfo(metadata?.createdBy);

  React.useEffect(() => {
    if (userInfoApiError && userInfoStatus != "loading") {
      toast.error(userInfoApiError.message);
    }
  }, [userInfoApiError]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const overdue =
    !checkIfNull(metadata.dueBy) &&
    isBefore(new Date(metadata?.dueBy), new Date()) &&
    (metadata?.completionPercentage
      ? metadata.completionPercentage < 100
      : true) &&
    metadata?.status !== "completed";

  if (metadata) {
    return (
      <DraggableStyleCard sx={{ my: 1 }}>
        <Box
          data-testid="TaskCard"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pl: 0.5,
            pt: 1,
            alignItems: "center",
            mr: 1,
          }}
        >
          <Avatar
            src={getS3ImageWrapper(userInfo?.image, "person")}
            sx={{ height: 25, width: 25 }}
          />

          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              ml: 1,
            }}
          >
            {metadata.status === "wip" && metadata.completionPercentage ? (
              <LinearProgressWithLabel
                value={metadata.completionPercentage}
                overdue={overdue}
              />
            ) : metadata.status === "completed" ? (
              <LinearProgressWithLabel value={100} />
            ) : null}
            {!checkIfNull(metadata.dueBy) && (
              <Typography
                variant="caption"
                color={overdue ? "error" : "textPrimary"}
                sx={{ alignSelf: "flex-end" }}
              >
                {overdue
                  ? `Overdue (${format(
                      new Date(metadata.dueBy),
                      getDateTimeFormat({
                        startDate: metadata.dueBy,
                        noTimeFlag: true,
                      })
                    )})`
                  : `Due by ${format(
                      new Date(metadata.dueBy),
                      getDateTimeFormat({
                        startDate: metadata.dueBy,
                        noTimeFlag: true,
                      })
                    )}`}
              </Typography>
            )}
          </Box>
        </Box>

        <Box sx={{ pt: 1, display: "flex", justifyContent: "space-between" }}>
          <Typography variant="subtitle2" sx={{ m: 1 }}>
            {clip(metadata?.title ?? " ", 25)}
          </Typography>
          <MVerticalRotateIconButton
            size="small"
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMore fontSize="inherit" />
          </MVerticalRotateIconButton>
        </Box>
        <CardActionArea onClick={onClick}>
          <StyledCollapse in={expanded} timeout="auto" unmountOnExit>
            <Typography variant="caption">
              {clip(metadata.description, 250)}
            </Typography>
          </StyledCollapse>

          <CardActions>
            <TaskCardFooter card={metadata} />
          </CardActions>
        </CardActionArea>
      </DraggableStyleCard>
    );
  } else return null;
};

TaskCard.propTypes = {
  showDeleteButton: PropTypes.bool,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
  style: PropTypes.object,
  tagStyle: PropTypes.object,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
  tags: PropTypes.array,
};

TaskCard.defaultProps = {
  showDeleteButton: true,
  onDelete: () => {
    //
  },
  onClick: () => {
    //
  },
  style: {},
  tagStyle: {},
  title: "no title",
  description: "",
  label: "",
  tags: [],
  className: "",
};

export default TaskCard;
