import React from "react";
import { Box } from "@mui/material";
import { ShowSidebarDocketsList } from "../foundations";
import { FlowBox } from "blocks/atoms/uistyles";

export default function DocketsSidebar({ isMobile = false }) {
  return (
    <FlowBox
      sx={{
        width: "100%",
        maxWidth: isMobile ? "100%" : 450,
        overflow: "hidden",
      }}
    >
      <ShowSidebarDocketsList />
    </FlowBox>
  );
}
