export const reservedFolderNames = [
  "dockets",
  "chats",
  "meetings",
  "surveys",
  "tasks",
  "records",
  "breakouts",
  "resolutions",
];
