import React from "react";
import { Paper } from "@mui/material";

export default function MobileSidePanelDrawer({
  showDrawer = false,
  children,
}) {
  return (
    <Paper
      elevation={3}
      sx={{
        position: "absolute",
        display: showDrawer ? "flex" : "none",
        flexDirection: "column",
        height: "100%",
        transition: "display 0.3s ease-in-out",
        zIndex: 10,
        px: 1,
      }}
    >
      {children}
    </Paper>
  );
}
