// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
export const applySearchFilter = (
  baseArray = [],
  query = "",
  filters = {},
  toBeMatchedPropertiesArray = []
) => {
  if (!Array.isArray(baseArray)) return [];
  else
    return baseArray.filter((object) => {
      let matches = true;
      let stringSum = JSON.stringify(object);
      if (query) {
        let containsQuery = false;
        // the regex expression is to avoid parsing through html expressions in minutes etc
        // regex expression not being used.. use if needed.. stringSum.toLowerCase().replace(/<[^>]+>/g, '').includes(query.toLowerCase())
        if (stringSum.toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
        toBeMatchedPropertiesArray.forEach((property) => {
          if (object[property].toLowerCase().includes(query.toLowerCase())) {
            containsQuery = true;
          }
        });

        if (!containsQuery) {
          matches = false;
        }
      }

      Object.keys(filters).forEach((key) => {
        const value = filters[key];

        if (value && object[key] !== value) {
          matches = false;
        }
      });

      return matches;
    });
};
