// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import PropTypes from "prop-types";
import { useMediaQuery, Stack } from "@mui/material";
import TopBarLayout from "blocks/views/Layouts/features/TopBarLayout";
import TopCenterbar from "blocks/views/Layouts/foundations/TopCenterbar";
import { AuthUserAvatarMenu } from "blocks/atoms";
import { useAppContext, useUserInfo } from "@app21/core";
import NotificationsPopup from "blocks/views/Notifications/features/NotificationsPopup";
import ScheduleConference from "blocks/atoms/ScheduleConference";
import DiscussButton from "blocks/atoms/DiscussButton";
import MembersPopover from "blocks/views/Layouts/foundations/MembersPopover";
import { useTheme } from "@mui/material/styles";
import { UpgradeButtonIfPersonalFreeSuite } from "blocks/modules/Subscriptions";
import toast from "react-hot-toast";

const SuiteTopbar = ({
  onMobileNavOpen,
  chimeViewMode,
  activeConference,
  showPathCrumbs,
  viewContext,
  ...rest
}) => {
  const {
    data: loggedInUser,
    status: loggedInUserStatus,
    apiError: loggedInUserApiError,
  } = useUserInfo();
  React.useEffect(() => {
    if (loggedInUserApiError && loggedInUserStatus != "loading") {
      toast.error(loggedInUserApiError.message);
    }
  }, [loggedInUserApiError]);
  const { selectedSuiteId, selectedOrganizationId } = useAppContext();

  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("md"));
  const iconSize =
    isMobile || (activeConference && chimeViewMode === "normal")
      ? "small"
      : "medium";

  const MiddlePanel = () => (
    <div
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexGrow: 1,
      }}
    >
      <TopCenterbar
        showPathCrumbs={!isMobile && showPathCrumbs}
        viewContext={"suite"}
      />
    </div>
  );
  const RightPanel = () => {
    return (
      <Stack
        direction="row"
        spacing={{ xs: 1, lg: 2 }}
        alignItems="center"
        justifyContent="space-between"
      >
        <MembersPopover iconSize={iconSize} />
        <DiscussButton
          iconSize={iconSize}
          orgId={selectedOrganizationId}
          suiteId={selectedSuiteId}
          viewContext={viewContext}
        />
        <ScheduleConference iconSize={iconSize} type="icon" />
        <NotificationsPopup
          iconSize={iconSize}
          viewContext={"suite"}
          selectedSuiteId={selectedSuiteId}
        />
        <UpgradeButtonIfPersonalFreeSuite />
        <div sx={{ height: iconSize === "small" ? 30 : 45, display: "flex" }}>
          <AuthUserAvatarMenu iconSize={iconSize} userInfo={loggedInUser} />
        </div>
      </Stack>
    );
  };

  if (loggedInUserStatus !== "success") return null;
  return (
    <TopBarLayout
      viewContext="suite"
      MainComponent={chimeViewMode === "fullscreen" ? null : MiddlePanel}
      RightComponent={chimeViewMode === "fullscreen" ? null : RightPanel}
      activeConference={activeConference}
      chimeViewMode={chimeViewMode}
      onMobileNavOpen={onMobileNavOpen}
      {...rest}
    />
  );
};

SuiteTopbar.propTypes = {
  className: PropTypes.string,
};

export default SuiteTopbar;
