import React from 'react'
import { SvgIcon } from '@mui/material';

export default function ClockIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M65.4581 63.2513L52.5415 55.543C50.2915 54.2096 48.4581 51.0013 48.4581 48.3763V31.293"
          stroke="currentColor"
          strokeWidth="6.29163"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.6666 25.0016C11.4583 31.96 8.33325 40.6266 8.33325 50.0016C8.33325 73.0016 26.9999 91.6683 49.9999 91.6683C72.9999 91.6683 91.6666 73.0016 91.6666 50.0016C91.6666 27.0016 72.9999 8.33496 49.9999 8.33496C44.0416 8.33496 38.3333 9.58496 33.2083 11.8766"
          stroke="currentColor"
          strokeWidth="6.29163"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
