// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import { TextField } from "@mui/material";

function FormTextField({ name, ...rest }) {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      sx={{ width: "100%" }}
      render={({ field }) => (
        <TextField {...field} {...rest} variant="standard" />
      )}
    />
  );
}

export default FormTextField;
