import React from 'react'
import { SvgIcon } from '@mui/material';

export default function TopUpIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M39.5844 57.2926C39.5844 61.3343 42.7094 64.5843 46.5427 64.5843H54.376C57.7093 64.5843 60.4177 61.751 60.4177 58.2093C60.4177 54.4176 58.7511 53.0426 56.2927 52.1676L43.751 47.7926C41.2927 46.9176 39.6261 45.5843 39.6261 41.751C39.6261 38.251 42.3343 35.376 45.6676 35.376H53.501C57.3343 35.376 60.4594 38.626 60.4594 42.6676"
          stroke="currentColor"
          strokeWidth="6.0894"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M50.0012 31.25V68.75"
          stroke="currentColor"
          strokeWidth="6.0894"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M70.8332 12.5V29.1667H87.4999"
          stroke="currentColor"
          strokeWidth="6.0894"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M91.6665 8.33447L70.8332 29.1678"
          stroke="currentColor"
          strokeWidth="6.0894"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M49.9999 8.33447C26.9999 8.33447 8.33319 27.0011 8.33319 50.0011C8.33319 66.4178 17.8332 80.6261 31.6665 87.4178"
          stroke="currentColor"
          strokeWidth="6.0894"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M91.6667 50C91.6667 73 73 91.6667 50 91.6667"
          stroke="currentColor"
          strokeWidth="6.0894"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
