// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  Popover,
  Button,
  MenuItem,
  Typography,
  ListItemIcon,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import clip from "text-clipper";
import path from "path";

const AgendaFilesListPopover = ({ filesList }) => {
  const [listAnchor, setListAnchor] = React.useState(null);
  const open = Boolean(listAnchor);
  const id = open ? "simple-popover" : undefined;
  const handleOpen = (event) => {
    setListAnchor(event.currentTarget);
  };
  const handleClose = () => {
    setListAnchor(null);
  };
  return (
    <div>
      <Button
        aria-describedby={id}
        variant="contained"
        onClick={handleOpen}
        sx={{ borderRadius: 0 }}
        endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      >
        {`${filesList?.length} files`}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={listAnchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        {filesList.map((fileInfo, index) => {
          return (
            <MenuItem value={10}>
              <Typography
                variant="h6"
                sx={{ px: 2, justifyContent: "space-around" }}
              >
                {clip(path.basename(fileInfo.s3Key))}
              </Typography>
            </MenuItem>
          );
        })}
      </Popover>
    </div>
  );
};

export default AgendaFilesListPopover;
