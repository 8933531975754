import React from "react";
import { Button, Typography, Box, Stack } from "@mui/material";
import { InviteNewUser } from "blocks/modules/Admin/foundations";
import { SettingsApplications } from "@mui/icons-material";
import {
  useHandleRouterPush,
  useAppContext,
  app21APIInstance,
} from "@app21/core";
import { useRouter } from "next/router";
import Image from "next/image";
import PersonalVideoIcon from "@mui/icons-material/PersonalVideo";
import AddUsersToNewSuite from "./AddUsersToNewSuite";

export default function CompleteSetupStepForm({ isNewOrgFlag = true }) {
  const { selectedOrganizationId, selectedSuiteId, userInfo } = useAppContext();
  const router = useRouter();
  const [toggleAddUsers, setToggleAddUsers] = React.useState(false);

  const handleAddNewUser = () => {
    setToggleAddUsers(true);
  };

  const [isSetupSuccessful, setIsSetupSuccessful] = React.useState(
    router?.query?.isSetupSuccessful ?? true
  );
  const [errorCode, setErrorCode] = React.useState(
    router?.query?.errorCode ?? ""
  );

  const { loadRoute } = useHandleRouterPush();

  const handleRetryPayment = async () => {
    const { data: response } = await app21APIInstance.post(
      "/stripe/payments/sessions/create",
      {
        type: "RETRY-SUITE-PAYMENT",
        suiteId: selectedSuiteId,
        calledBy: userInfo._id,
        orgId: selectedOrganizationId,
        priceId: "",
      }
    );
    if (response && response.data && response.data.paymentURL) {
      window.location.href = response.data.paymentURL;
    }
  };

  const handleSuiteAdminClick = () => {
    loadRoute("MANAGE-ORGANIZATION", {
      orgId: selectedOrganizationId,
      suiteId: selectedSuiteId,
      choice: "orgadmin",
    });
  };

  if (isSetupSuccessful === false || isSetupSuccessful === "false") {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h4" color="error">
          Setup Failed
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          There seems to be an issue - {errorCode}
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          If you would like to try the payment again -{" "}
          <Button color="primary" onClick={handleRetryPayment}>
            Retry Payment
          </Button>
        </Typography>

        <Typography variant="body1" sx={{ mt: 2 }}>
          If you would like to retry the Suite Setup -{" "}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => loadRoute("ADD-SUITE", { choice: "addsuite" })}
        >
          Restart Add Suite
        </Button>
      </Box>
    );
  } else
    return (
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {toggleAddUsers ? (
          <>
            <Box
              sx={{
                p: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h3"
                gutterBottom
                sx={{ textAlign: "center" }}
              >
                Add Members to your Suite
              </Typography>

              <Typography
                variant="h5"
                gutterBottom
                sx={{ textAlign: "center" }}
              >
                You can pick from your current organization's members or invite
                new members to your organization and then add them to your
                suite.
              </Typography>
              <AddUsersToNewSuite />
            </Box>
          </>
        ) : (
          <>
            <Box
              sx={{
                p: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                alignSelf: "center",
                width: 420,
              }}
            >
              <Typography variant="h3" gutterBottom>
                Congratulations!
              </Typography>

              <Typography
                variant="h6"
                gutterBottom
                sx={{ textAlign: "center" }}
              >
                We have created your suite and added you in as an administrator.
                You can goto the dashboard to start using the suite. You can
                also invite other members to your suite.
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                sx={{ textAlign: "center" }}
              >
                Glad to have you on board.Please do contact us at
                Support@trryst.com in case you are facing any issues.
              </Typography>

              <Image
                alt={"Enterprise Conversations made easy with Trryst"}
                src="/static/images/Enterprise_Conversation.png"
                width={200}
                height={200}
                style={{
                  maxWidth: "100%",

                  borderRadius: 10,
                  marginTop: 10,
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <Button
                variant="outlined"
                startIcon={<PersonalVideoIcon />}
                onClick={() => {
                  loadRoute("VIEW-SUITE", {
                    suiteId: selectedSuiteId,
                    orgId: selectedOrganizationId,
                  });
                }}
                size="medium"
              >
                MY DASHBOARD
              </Button>
              <Button
                variant="contained"
                size="medium"
                onClick={handleAddNewUser}
              >
                INVITE TO ORGANIZATION
              </Button>
            </Box>
          </>
        )}

        {/* <Box
          sx={{
            p: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flexGrow: 1,
          }}
        >
          <InviteNewUser
            icon={false}
            label="Invite more Users to Organization"
            sx={{ my: 2 }}
            variant="contained"
          />
          <Button
            startIcon={<SettingsApplications />}
            variant="contained"
            sx={{ my: 2 }}
            color="primary"
            onClick={handleSuiteAdminClick}
          >
            Add/Manage Users in your Suite
          </Button>
        </Box> */}
      </Box>
    );
}
