// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Box,
  styled,
} from "@mui/material";
import {
  usePopupState,
  bindToggle,
  bindMenu,
} from "material-ui-popup-state/hooks";
import {
  useAttendeeAudioStatus,
  MicrophoneActivity,
} from "amazon-chime-sdk-component-library-react";
import { FlowBox } from "blocks/atoms/uistyles";
import { MoreVert } from "@mui/icons-material";
import { useVideocallActivity } from "providers/TrrystVideocallProvider";
import { Label } from "blocks/atoms/uicomponents";

const AttendeeInfoWrapper = ({
  attendeeId,
  nameplate = null,
  context = "remote",
  children,
}) => {
  const { handleAdminAction } = useVideocallActivity();
  const { muted } = useAttendeeAudioStatus(attendeeId);
  const participantOptionsSelectPopupState = usePopupState({
    variant: "popover",
    popupId: "participantOptionsSelectMenu",
  });
  const [hovered, setHovered] = React.useState(false);

  const StyledMicBox = styled(Box)(({ theme }) => {
    return {
      height: 20,
      width: 20,
      mr: 1,
      position: "absolute",
      right: 5,
      top: 5,
      zIndex: 2,
      background: `rgba(155,155,150,0.25)`,
      borderRadius: 5,
      "&.muted-style": {
        position: "relative",
        color: theme.palette.error.main,
      },
      "&.unmuted-style": {
        position: "relative",
        color: theme.palette.primary.darker,
      },
    };
  });

  const renderMenu = () => {
    return (
      <Menu
        {...bindMenu(participantOptionsSelectPopupState)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <MenuItem
          onClick={() => {
            handleAdminAction("KICK-OUT", attendeeId);
            participantOptionsSelectPopupState.close();
          }}
        >
          Remove
        </MenuItem>
        {!muted && (
          <MenuItem
            onClick={() => {
              handleAdminAction("TOGGLE-MUTE", attendeeId);
              participantOptionsSelectPopupState.close();
            }}
          >
            Mute
          </MenuItem>
        )}
      </Menu>
    );
  };

  //
  // }
  return (
    <FlowBox
      sx={{ position: "relative", borderRadius: 8 }}
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <StyledMicBox>
        <MicrophoneActivity
          className={muted ? "muted-style" : "unmuted-style"}
          attendeeId={attendeeId}
        />
      </StyledMicBox>
      {children}
      <Toolbar
        variant="dense"
        sx={{
          width: "100%",
          opacity: 1,
          overflow: "hidden",
          height: 30,
          padding: 0,
          transition: "opacity 1s",
          display: "flex",
          justifyContent: "space-between",
          position: "absolute",
          bottom: 0,
          borderRadius: "0 0 12px 8px",
          background: hovered
            ? "linear-gradient(106deg, rgba(245,245,245,0.03) 0%, rgba(220,210,220,0.15) 25%, rgba(155,155,150,0.35) 46%, rgba(124,124,120,0.68) 70%, rgba(94,91,91,0.95) 100%)"
            : "transparent",
        }}
      >
        {nameplate && hovered ? (
          <Label
            variant="ghost"
            color="primary"
            sx={{ borderRadius: 4, py: 1, px: 2 }}
          >
            <Typography
              variant="subtitle2"
              sx={{ fontSize: 12, fontWeight: 450 }}
              color="primary.contrastText"
            >
              {nameplate}
            </Typography>
          </Label>
        ) : (
          <div />
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: 4,
          }}
        >
          {/* {(muted || hovered) && (
            <Box
              sx={{
                height: 25,
                width: 25,
                mr: 1,
                "&.Muted-style": {
                  color: "error.main",
                },
                "& .unmuted-style": {
                  color: "LightSteelBlue",
                },
              }}
            >
              <MicrophoneActivity
                className={muted ? "muted-style" : "unmuted-style"}
                attendeeId={attendeeId}
              />
            </Box>
          )} */}
          {context === "remote" && hovered ? (
            <IconButton
              size="small"
              {...bindToggle(participantOptionsSelectPopupState)}
              sx={{ color: "rgba(255, 255, 255, 0.74)", marginRight: 3 }}
            >
              <MoreVert fontSize="inherit" />
            </IconButton>
          ) : (
            <div style={{ width: 25 }} />
          )}
          {renderMenu()}
        </div>
      </Toolbar>
    </FlowBox>
  );
};

export default AttendeeInfoWrapper;
