import React from 'react'
import { SvgIcon } from "@mui/material";

export default function SearchStatusIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 50"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M5.75 33.0625C5.75 17.9688 17.9688 5.75 33.0625 5.75"
          stroke="currentColor"
          strokeWidth="4.30925"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M60.3743 33.0625C60.3743 48.1562 48.1556 60.375 33.0618 60.375C22.3093 60.375 12.9943 54.165 8.53809 45.1087"
          stroke="currentColor"
          strokeWidth="4.30925"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M57.2832 7.81152L49.4719 7.81152"
          stroke="currentColor"
          strokeWidth="4.30925"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M57.2832 16.8701L40.3587 16.8701"
          stroke="currentColor"
          strokeWidth="4.30925"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M57.2832 25.9834L40.3587 25.9834"
          stroke="currentColor"
          strokeWidth="4.30925"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M63.25 63.25L57.5 57.5"
          stroke="currentColor"
          strokeWidth="4.30925"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
