import { faDropbox, faGoogleDrive } from "@fortawesome/free-brands-svg-icons";
import {
  Download,
  FileCopy,
  CreateNewFolder,
  Edit,
  Upload,
  DeleteForever,
  FileOpen,
  Share,
  DriveFolderUpload,
  UploadFile,
  DriveFileMove,
  Google,
  Unarchive,
} from "@mui/icons-material";

export const baseUploadActionsList = [
  {
    icon: CreateNewFolder,
    value: "create_folder",
    label: "Create Folder",
    active: true,
    permissionKey: "canAddNewFolders",
  },
  {
    icon: UploadFile,
    value: "upload_files",
    label: "Upload Files",
    active: true,
    permissionKey: "canAddNewFiles",
  },
  {
    icon: DriveFolderUpload,
    value: "upload_folder",
    label: "Upload Folder",
    active: true,
    permissionKey: "canAddNewFolders",
  },
  {
    icon: DriveFileMove,
    value: "copy_from_suite",
    label: "Copy from another Suite",
    active: true,
    permissionKey: "canAddNewFiles",
  },
  {
    icon: Google,
    value: "copy_from_gdrive",
    label: "Import from Drive",
    active: false,
    permissionKey: "default",
  },
  {
    icon: Unarchive,
    value: "copy_from_dropbox",
    label: "Import from Dropbox",
    active: false,
    permissionKey: "default",
  },
];

export function uploadActionListOptions({
  isItemSelected = false,
  isMobile = false,
  filesPermissions = {},
}) {
  const baseList = [];
  baseUploadActionsList.forEach((action) => {
    (filesPermissions[action.permissionKey] ?? true) &&
      action.active &&
      baseList.push(action);
  });

  return baseList;
}
