// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Avatar, TextField, IconButton, Tooltip, Box } from "@mui/material";
import { getS3ImageWrapper } from "@app21/core";
import { Send } from "@mui/icons-material";
import { stringPurify } from "utils/inputUtils";
import { Controller, useFormContext } from "react-hook-form";

const CommentAdd = ({ prepend, userInfo, className, ...rest }) => {
  const [message, setMessage] = useState("");
  const { watch, control, setValue, getValues, formState } = useFormContext();
  const { errors } = formState;

  const handleClick = () => {
    prepend({
      who: userInfo._id,
      comment: stringPurify(watch("newComment")),
      when: Date.now(),
    });
    setValue("newComment", "");
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }} {...rest}>
      <Avatar
        alt={userInfo?.fullName || "Person"}
        src={getS3ImageWrapper(userInfo?.image, "person")}
      />
      <Controller
        control={control}
        name={`newComment`}
        sx={{ display: "flex", alignItems: "center" }}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            sx={{ ml: (theme) => theme.spacing(2) }}
            placeholder="Write a comment..."
            InputProps={{
              sx: {
                backgroundColor: (theme) => theme.palette.background.default,
              },
            }}
            InputLabelProps={{
              sx: {
                color: (theme) => theme.palette.text.primary,
                backgroundColor: (theme) => theme.palette.background.default,
              },
            }}
            error={!!errors?.newComment}
            helperText={errors?.newComment?.message}
          />
        )}
      />

      <Tooltip title="Send" arrow>
        <IconButton
          onClick={handleClick}
          color={message.length > 0 ? "primary" : "default"}
        >
          <Send />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

CommentAdd.propTypes = {
  prepend: PropTypes.any,
  className: PropTypes.string,
  userInfo: PropTypes.object,
};

export default CommentAdd;
