import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Card,
  Button,
  ClickAwayListener,
} from "@mui/material";
import { FlowBox } from "blocks/atoms/uistyles";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Add, Search } from "@mui/icons-material";
import { checkIfNull } from "@app21/core";
import { LoadingSpinner } from "blocks/atoms";
import { useAtom } from "jotai";
import {
  viewSurveysFilterContextAtom,
  selectedDocketsInSurveysFilterAtom,
  selectedStatusInSurveysFilterAtom,
  selectedSurveysDateRangeAtom,
} from "blocks/modules/Surveys/surveys-atoms";
import { useIntl } from "react-intl";
import { sort } from "fast-sort";
import { format, isWithinInterval } from "date-fns";

import { pxToRem } from "utils";
import { useSurveysContext } from "providers/SurveysProvider";
import { DisplaySidebarSurveyCard } from "blocks/modules/Surveys/foundations";

export default function ShowSidebarSurveysList({ viewContext = "survey" }) {
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });
  const [viewSurveysFilterContext, setViewSurveysFilterContext] = useAtom(
    viewSurveysFilterContextAtom
  );
  const [selectedDocketsInSurveysFilter] = useAtom(
    selectedDocketsInSurveysFilterAtom
  );
  const [selectedStatusInSurveysFilter] = useAtom(
    selectedStatusInSurveysFilterAtom
  );
  const [selectedSurveysDateRange] = useAtom(selectedSurveysDateRangeAtom);
  const {
    resolutionsInSuite = [],
    resolutionsInSuiteStatus,
    decisionsData = [],
  } = useSurveysContext();

  const filteredSurveys = decisionsData ?? [];

  console.log(filteredSurveys);

  const filterSurveysBySelectedDockets = React.useCallback(
    (surveys = []) => {
      if (checkIfNull(selectedDocketsInSurveysFilter)) return surveys;
      return surveys?.filter((survey) => {
        if (!survey.docketId) {
          return false;
        }
        return selectedDocketsInSurveysFilter?.some(
          (docket) => (docket.value || docket._id) === survey.docketId
        );
      });
    },
    [selectedDocketsInSurveysFilter]
  );
  const filterSurveysBySelectedStatus = React.useCallback(
    (surveys = []) => {
      if (checkIfNull(selectedStatusInSurveysFilter)) return surveys;
      if (selectedStatusInSurveysFilter[0] === "ALL") return surveys;
      return surveys?.filter((survey) => {
        if (!survey.survey.decisionStatus) {
          return false;
        }

        return selectedStatusInSurveysFilter?.some(
          (status) => status === survey.survey.status
        );
      });
    },
    [selectedStatusInSurveysFilter]
  );
  const filterSurveysBySelectedDateRange = React.useCallback(
    (surveys = []) => {
      if (checkIfNull(selectedSurveysDateRange)) return surveys;

      return surveys?.filter((survey) => {
        return isWithinInterval(
          new Date(survey.updatedAt ?? survey.createdAt),
          {
            start: new Date(selectedSurveysDateRange.startDate),
            end: new Date(selectedSurveysDateRange.endDate),
          }
        );
      });
    },
    [selectedSurveysDateRange]
  );

  const filteredSurveysSelection = filterSurveysBySelectedStatus(
    filterSurveysBySelectedDockets(
      filterSurveysBySelectedDateRange(filteredSurveys)
    )
  );
  const sortedSurveys = sort(filteredSurveysSelection ?? []).by([
    { desc: (a) => a.updatedAt },
  ]);

  if (resolutionsInSuiteStatus === "loading") {
    return <LoadingSpinner type="gif" />;
  }
  if (filteredSurveys.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Typography variant="body1">
          There are no surveys with the selected criteria in this Suite.
        </Typography>
      </Box>
    );
  }
  return (
    <FlowBox sx={{ overflow: "auto" }}>
      <PerfectScrollbar
        options={{ suppressScrollX: true }}
        style={{ paddingRight: 10 }}
      >
        {filteredSurveys.map((surveyObj, index) => {
          return <DisplaySidebarSurveyCard key={index} surveyObj={surveyObj} />;
        })}
      </PerfectScrollbar>
    </FlowBox>
  );
}
