// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { useState } from "react";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import { useIntl } from "react-intl";
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import { Trash as TrashIcon } from "react-feather";

const CheckItem = ({
  card,
  checklist,
  checkItem,
  className,
  editing,
  onEditCancel,
  onEditInit,
  onEditComplete,
  ...rest
}) => {
  const [name, setName] = useState(checkItem.name);

  const handleStateChange = async (event) => {
    try {
      event.persist();

      const state = event.target.checked ? "complete" : "incomplete";

      //      await dispatch(updateCheckItem(card.id, checklist.id,checkItem.id,  { state } ));
      toast.success("Check item updated");
    } catch (err) {
      console.error(err);
      toast.error(`Something went wrong - ${JSON.stringify(err)} `);
    }
  };

  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });

  const handleNameChange = (event) => {
    event.persist();
    setName(event.target.value);
  };

  const handleSave = async () => {
    try {
      // await dispatch(updateCheckItem( card.id, checklist.id, checkItem.id,  { name } ));
      onEditComplete();
      toast.success("Check item updated");
    } catch (err) {
      console.error(err);
      toast.error(`Something went wrong - ${JSON.stringify(err)} `);
    }
  };

  const handleCancel = () => {
    setName(checkItem.name);
    onEditCancel();
  };

  const handleDelete = async () => {
    try {
      // await dispatch(deleteCheckItem( card.id, checklist.id,checkItem.id ));
      toast.success("Check item deleted");
    } catch (err) {
      console.error(err);
      toast.error(`Something went wrong - ${JSON.stringify(err)} `);
    }
  };

  return (
    <div
      style={{
        padding: (theme) => theme.spacing(1),
        display: "flex",
        alignItems: "flex-start",
        borderRadius: (theme) => theme.shape.borderRadius,
        "&:hover": {
          backgroundColor: (theme) => theme.palette.background.default,
          "& $deleteButton": {
            visibility: "visible",
          },
        },
      }}
      {...rest}
    >
      <Checkbox
        checked={checkItem.state === "complete"}
        onChange={handleStateChange}
        sx={{
          ml: (theme) => theme.spacing(-1),
          mr: (theme) => theme.spacing(1),
        }}
      />
      {editing ? (
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <TextField value={name} fullWidth onChange={handleNameChange} />
          <Box
            sx={{
              mt: 1,
            }}
          >
            <Button
              size="small"
              variant="contained"
              color="secondary"
              onClick={handleSave}
            >
              {`${t("global.buttons.save")}`}
            </Button>
            <Button size="small" onClick={handleCancel}>
              {`${t("global.buttons.cancel")}`}
            </Button>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexGrow: 1,
          }}
        >
          <Typography
            onClick={onEditInit}
            color="textPrimary"
            variant="body1"
            sx={{ flexGrow: 1, cursor: "pointer", minHeight: 32 }}
          >
            {checkItem.name}
          </Typography>
          <IconButton onClick={handleDelete} sx={{ visibility: "hidden" }}>
            <SvgIcon fontSize="small">
              <TrashIcon />
            </SvgIcon>
          </IconButton>
        </Box>
      )}
    </div>
  );
};

CheckItem.propTypes = {
  card: PropTypes.object.isRequired,
  checklist: PropTypes.object.isRequired,
  checkItem: PropTypes.object.isRequired,
  className: PropTypes.string,
  editing: PropTypes.bool,
  onEditCancel: PropTypes.func,
  onEditComplete: PropTypes.func,
  onEditInit: PropTypes.func,
};

CheckItem.defaultProps = {
  editing: false,
  onEditCancel: () => {
    //
  },
  onEditComplete: () => {
    //
  },
  onEditInit: () => {
    //
  },
};

export default CheckItem;
