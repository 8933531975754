import React from "react";
import { Box, Pagination } from "@mui/material";
import RecordingsList from "./sidebar/RecordingsList";
import RecordingsSearchbar from "./sidebar/RecordingsSearchbar";

export default function TranscriptsListSidebar({
  isCompactView,
  useMeetingsInSuiteStatus,
  filteredMeetings,
  setSearchContent,
  searchContent,
  handleSelectMeeting,
  handleCloseTranscript,
  selectedMeetingId,
  transcriptions,
}) {
  const [sortOrder, setSortOrder] = React.useState("date|desc");
  const handleSortOrderChange = (val) => setSortOrder(val);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        transition: "all 1s ease-in-out",
        width: "100%",
      }}
    >
      <RecordingsSearchbar
        useMeetingsInSuiteStatus={useMeetingsInSuiteStatus}
        filteredMeetings={filteredMeetings}
        setSearchContent={setSearchContent}
        searchContent={searchContent}
        sortOrder={sortOrder}
        handleSortOrderChange={handleSortOrderChange}
        handleCloseTranscript={handleCloseTranscript}
      />
      <RecordingsList
        sortOrder={sortOrder}
        handleSelectMeeting={handleSelectMeeting}
        filteredMeetings={filteredMeetings}
        selectedMeetingId={selectedMeetingId}
        transcriptions={transcriptions}
      />
    </Box>
  );
}
