import React from "react";
import { FlowBox } from "blocks/atoms/uistyles";
import { motion } from "framer-motion";
import { varFade } from "blocks/atoms/uieffects/animate";

export default function FlowTabPanel(props) {
  const { children, value, index, sx = {}, ...other } = props;

  return (
    <FlowBox
      component={motion.div}
      role="tabpanel"
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...varFade().in}
      sx={{
        display: value === index ? "flex" : "none",
        ...sx,
      }}
      {...other}
    >
      {children}
    </FlowBox>
  );
}
