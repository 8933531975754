// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { isString } from "lodash";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { AnimatePresence } from "framer-motion";
// material
import { alpha, styled } from "@mui/material/styles";
import { Box, Paper, Button, Typography, useTheme } from "@mui/material";
import { FlowBox } from "blocks/atoms/uistyles";
// utils
import { fData, checkIfNull } from "@app21/core";
//
import { UploadIllustration } from "blocks/atoms/graphics";
import { SIZELIMITS } from "blocks/atoms/constants/appConstants";

import { UploadFileListItemComponent } from "blocks/atoms/uicomponents/upload";
// ----------------------------------------------------------------------

const DropZoneStyle = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  flexGrow: 1,
  textAlign: "center",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(3, 2),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  height: "100%",
  width: "100%",

  margin: "auto",
  "&:hover": { opacity: 0.72, cursor: "pointer" },
  [theme.breakpoints.up("md")]: { textAlign: "left", flexDirection: "row" },
}));

// ----------------------------------------------------------------------

UploadMultiFileComponent.propTypes = {
  accept: PropTypes.any,
  maxSize: PropTypes.number,
  showPreview: PropTypes.bool,
  loading: PropTypes.bool,
  files: PropTypes.array,
  onRemove: PropTypes.func,
  onDrop: PropTypes.func,
  sx: PropTypes.object,
  error: PropTypes.bool,
};

export default function UploadMultiFileComponent({
  loading,
  error,
  files,
  showPreview = files?.length === 1 ? true : false,
  maxSize = SIZELIMITS.uploadMaxFileSize,
  onRemove,
  onComplete,
  sx,
  keyToURLMap,
  s3Prefix,
  uploadContext = "file",
  onFileCopyPaste,
  ...other
}) {
  const hasFile = files.length > 0;
  const theme = useTheme();
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    createImageThumbnails: true,
    maxSize: maxSize ?? 10000000,
    ...other,
  });

  const ShowRejectionItems = () => (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        borderColor: "error",
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.18),
      }}
    >
      {fileRejections.map(({ file, errors }) => {
        const { path, size } = file;
        return (
          <Box key={path} sx={{ my: 1 }}>
            <Typography variant="subtitle2" noWrap>
              {path} - {fData(size)}
            </Typography>
            {errors.map((e, index) => {
              if (e.code === "file-too-large") {
                return (
                  <Typography key={index} variant="caption" component="p">
                    Individual File size is restricted to {fData(maxSize)}
                  </Typography>
                );
              } else {
                return (
                  <Typography key={index} variant="caption" component="p">
                    - {e.message}
                  </Typography>
                );
              }
            })}
          </Box>
        );
      })}
    </Paper>
  );

  return (
    <FlowBox
      onPaste={onFileCopyPaste}
      sx={{
        ...sx,
      }}
    >
      <input
        {...(uploadContext === "folder"
          ? { webkitdirectory: "true", directory: "true" }
          : {})}
        {...getInputProps()}
      />
      <DropZoneStyle
        {...getRootProps()}
        style={{
          maxHeight: checkIfNull(files) ? "100%" : 40,
          maxWidth: checkIfNull(files) ? "100%" : 100,
          minWidth: 10,
          backgroundColor:
            uploadContext === "folder"
              ? alpha(theme.palette.primary.lighter, 0.2)
              : alpha(theme.palette.secondary.lighter, 0.2),
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: theme.palette.error.main,
            borderColor: theme.palette.error.light,
            backgroundColor: theme.palette.error.lighter,
          }),
        }}
      >
        {loading ? (
          <Box
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <img src="/static/images/uploadanimation.gif" height={100} />
          </Box>
        ) : checkIfNull(files) ? (
          <>
            <UploadIllustration sx={{ width: 150 }} />

            <Box sx={{ p: 1, ml: { md: 2 } }}>
              <Typography gutterBottom variant="h5">
                Drop or Select {uploadContext}
              </Typography>

              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {` Drop ${
                  uploadContext === "folder" ? "folder" : "files"
                } here or click to`}
                <span
                  style={{
                    textDecoration: "underline",
                    marginLeft: 5,
                    marginRight: 5,
                  }}
                >
                  browse
                </span>{" "}
                through your machine
              </Typography>
            </Box>
          </>
        ) : (
          <Button>Add</Button>
        )}
      </DropZoneStyle>

      {fileRejections?.length > 0 && <ShowRejectionItems />}

      <Box
        sx={{
          ...(hasFile && { my: 3 }),
          display: "flex",
          flexDirection: "column",
        }}
      >
        <AnimatePresence>
          {files?.map((file, index) => {
            const { name } = file;
            const key = isString(file) ? file + index : name + index;
            return (
              <UploadFileListItemComponent
                key={key}
                index={index}
                onUploadProcessed={onComplete}
                uploadingFile={{
                  file: file,
                  postAPIUrl: keyToURLMap?.get(s3Prefix + file.path),
                }}
                onRemove={onRemove}
                showPreview={showPreview}
                keyToURLMap
              />
            );
          })}
        </AnimatePresence>
      </Box>
    </FlowBox>
  );
}
