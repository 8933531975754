import React from "react";
import propTypes from "prop-types";
import {
  Box,
  Button,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
} from "@mui/material";

import DraggablePaper from "blocks/atoms/uicomponents/DraggablePaper";

export default function VerifyDeleteDialog(props) {
  const {
    open = false,
    handleClose = () => null,
    verifyName,
    handleConfirm = () => null,
    title = `Delete ${verifyName} now ?`,
    confirmationText = "Deleting the suite will cause all data in the suite to be erased. The action is permanent and cannot be recovered. Proceed only if you are sure!",
  } = props;

  const [nameCheck, setNameCheck] = React.useState("");

  const handleName = (event) => {
    setNameCheck(event.target.value);
  };

  return (
    <Dialog PaperComponent={DraggablePaper} open={open} onClose={handleClose}>
      <DialogTitle id="dragModal">{title}</DialogTitle>
      <DialogContent sx={{ my: 2 }}>
        <Typography variant="body1" sx={{ my: 2 }}>
          {confirmationText}
        </Typography>
        {Boolean(verifyName) && (
          <Typography variant="body1" sx={{ my: 2 }}>
            Enter name in box below to confirm:{" "}
            <span style={{ fontWeight: "bold" }}> {verifyName}</span>
          </Typography>
        )}

        {Boolean(verifyName) && (
          <Box>
            <TextField
              id="standard-basic"
              label={`Re-enter Name to confirm`}
              variant="outlined"
              placeholder={`Name entered here should match -  '${verifyName}'`}
              fullWidth
              onChange={handleName}
              value={nameCheck}
              sx={{ my: 2 }}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            setNameCheck("");
            handleConfirm();
          }}
          disabled={
            String(nameCheck?.replace(/\s+/g, "")) !==
            String(verifyName?.replace(/\s+/g, ""))
          }
        >
          Proceed
        </Button>
      </DialogActions>
    </Dialog>
  );
}

VerifyDeleteDialog.propTypes = {
  handleClose: propTypes.func,
  open: propTypes.bool,
  verifyName: propTypes.string,
  handleConfirm: propTypes.func,
  title: propTypes.any,
};
