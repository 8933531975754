// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import * as React from "react";
import PropTypes from "prop-types";
import { Typography, AppBar } from "@mui/material";
import { styled } from "@mui/material/styles";

const TypographyStatusStyle = styled(Typography)(({ theme }) => ({
  fontStyle: "italic",
  color: "white",
}));

const AppBarStyle = styled(AppBar)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(1),
}));

const StyledInput = styled("input")(({ theme }) => ({
  border: "none",
  width: "100%",
  backgroundColor: "transparent !important",
  color: theme.palette.primary.contrastText,
  paddingRight: 5,
  marginRight: 5,
  textAlign: "center",
}));

const DocStatusbar = ({
  documentName,
  allowNameChange,
  displayStatus,
  onNameChange,
}) => {
  const handleBlur = (e) => {
    if (e.target.value !== documentName) {
      onNameChange(e.target.value);
    }
  };

  return (
    <AppBarStyle position="static">
      <StyledInput
        defaultValue={documentName}
        onBlur={handleBlur}
        disabled={!allowNameChange}
      />
      <TypographyStatusStyle variant="subtitle2">
        {displayStatus}
      </TypographyStatusStyle>
    </AppBarStyle>
  );
};
DocStatusbar.propTypes = {
  displayStatus: PropTypes.string,
  allowNameChange: PropTypes.bool,
  documentName: PropTypes.string,
  onNameChange: PropTypes.func,
};
DocStatusbar.defaultProps = {
  displayStatus: "",
  allowNameChange: false,
  documentName: "Document",
  onNameChange: (val) => console.log(val),
};
export default DocStatusbar;
