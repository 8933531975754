// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { Fragment } from "react";
import { Avatar, Tooltip, Skeleton, Box, Typography } from "@mui/material";
import { getS3ImageWrapper, useUserInfo } from "@app21/core";
import toast from "react-hot-toast";
import { getNameInitials } from "utils/getNameInitials";

export default function FetchUserAvatar({
  viewContext = "avatar",
  ConditionalTooltip = Fragment,
  setDisplayUserInfo = () => null,
  userId,
  defaultText = "Trryst User",
  tooltipFlag = true,
  disableOnClick = false,
  size = 25,
  sx = {},
  ...rest
}) {
  const {
    data: originatorInfo,
    status: originatorInfoStatus,
    apiError: userInfoApiError,
  } = useUserInfo(userId);

  React.useEffect(() => {
    if (userInfoApiError && originatorInfoStatus !== "loading") {
      toast.error(`User not found - ${userInfoApiError.message}`);
    }
  }, [userInfoApiError]);

  if (originatorInfoStatus === "loading") {
    return <Skeleton variant="circular" height={size} width={size} />;
  }
  if (viewContext === "name") {
    return <>{originatorInfo?.fullName ?? defaultText}</>;
  }
  return (
    <ConditionalTooltip userDetails={originatorInfo} defaultText={defaultText}>
      <Avatar
        src={getS3ImageWrapper(userId ? originatorInfo?.image : null, "person")}
        alt={getNameInitials(originatorInfo?.fullName ?? defaultText)}
        sx={{ height: size, width: size, ...sx }}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          disableOnClick ? null : setDisplayUserInfo(originatorInfo);
        }}
        {...rest}
      />
    </ConditionalTooltip>
  );
}
