// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from "react";
import {
  Box,
  Grid,
  Checkbox,
  FormControlLabel,
  Collapse,
  Typography,
  Tooltip,
  Button,
  Toolbar,
  useTheme,
} from "@mui/material";
import { Info, ExpandMore } from "@mui/icons-material";
import MicrophoneActivityPreview from "./MicrophoneDevices/MicrophoneActivityPreview";
import SpeakerDevices from "./SpeakerDevices";
import CameraDevices from "./CameraDevices";
import { StyledSquareMenuButton } from "blocks/atoms/uistyles";
import { CustomPreviewTile } from "blocks/modules/TrrystVideocall/foundations/components";
import {
  useVideocallContext,
  VideoFiltersCpuUtilization,
} from "providers/TrrystVideocallProvider";
import { pxToRem } from "utils";

import {
  VideoCallInActiveIcon,
  VideoCallActiveIcon,
  MicOnWebIcon,
  MicOffWebIcon,
} from "blocks/atoms/trrystCustomIcons";

import { checkIfNull, useAppContext } from "@app21/core";
import {
  useToggleLocalMute,
  useLocalVideo,
  useAudioInputs,
  useVideoInputs,
  QualitySelection,
  MicSelection,
} from "amazon-chime-sdk-component-library-react";
import useIsMobileBreakpoint from "hooks/useIsMobileBreakpoint";

import { useChimeState } from "providers/TrrystVideocallProvider/wrappers/ChimeStateProvider";
import VideoTransformChoice from "./VideoTransformChoice";

const ConferenceInputChoices = () => {
  const {
    isRecordingBotEnabled,
    setIsRecordingBotEnabled,
    meetingInfo,
    isMeetingCreator,
    videocallPermissions,
  } = useVideocallContext();
  const { setOnStartVideoEnabled } = useChimeState();
  const isMobile = useIsMobileBreakpoint();
  const [settingsOpen, setSettingsOpen] = React.useState(
    isMobile ? false : true
  );
  const { muted, toggleMute } = useToggleLocalMute();
  const { isVideoEnabled, setIsVideoEnabled } = useLocalVideo();
  const { chimeViewMode } = useAppContext();
  const theme = useTheme();
  const handleRecordingEnabledChange = () => {
    if (isRecordingBotEnabled) setIsRecordingBotEnabled(false);
    else setIsRecordingBotEnabled(true);
  };

  const canUserRecord =
    checkIfNull(meetingInfo) ||
    isMeetingCreator ||
    videocallPermissions.canOperateRecording;

  const { selectDeviceError: audioDeviceError } = useAudioInputs();
  const { selectDeviceError: videoDeviceError } = useVideoInputs();

  const { videoTransformCpuUtilization } = useChimeState();
  const videoTransformsEnabled =
    videoTransformCpuUtilization !== VideoFiltersCpuUtilization.Disabled;

  const recordingOptionText = "Are you likely to record this meeting ? ";
  const recordingHelpText =
    "If you are likely to record the meeting you can tick this so that we can keep a secure recording Infrastructure ready in advance for you. Ofcourse, if you change your mind, you can always click on Prepare recording menu option later in the videocall and we will get the recording infrastructure ready for you. It typically takes about 45 seconds or so for the infrastructure to be setup. Thanks for your patience.";

  if (audioDeviceError || videoDeviceError) {
    return (
      <Box sx={{ m: 2 }}>
        <Typography sx={{ my: 2 }}>
          There are no Audio / Video Devices available. You cannot use this
          feature. Please enable Audio and Video Devices in your browser and
          refresh the screen.
        </Typography>
        <Typography
          variant="caption"
          sx={{
            my: 2,
            border: "1px solid Lightgrey",
            borderRadius: 1,
            p: 1,
            backgroundColor: (theme) => theme.palette.secondary.lighter,
          }}
        >
          {audioDeviceError &&
            audioDeviceError?.name + " . " + audioDeviceError?.cause}
          <br />
          {videoDeviceError &&
            videoDeviceError?.name + " . " + videoDeviceError?.cause}
        </Typography>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          width: "100%",
          p: 2,
          pt: 1,
          mt: 2,
        }}
      >
        <Grid
          container
          direction={"column"}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} md={9} lg={8}>
            <Box
              sx={{
                height: 200,
                width: (200 * 16) / 9,
                mb: 2,
                pb: -1,
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                position: "relative",
                borderRadius: 10,
              }}
            >
              <CustomPreviewTile />
              <Toolbar
                variant="dense"
                sx={{
                  position: "absolute",
                  bottom: 0,
                  width: "100%",
                  height: "100%",
                  zIndex: 5,

                  borderRadius: "0px 0px 12px 12px",
                  maxHeight: 30,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Tooltip
                  title={muted ? "Start Unmuted" : "Start Muted"}
                  placement="top-start"
                >
                  <StyledSquareMenuButton
                    onClick={toggleMute}
                    size={chimeViewMode === "compact" ? "small" : "medium"}
                    sx={{ borderRadius: 0, padding: 0 }}
                  >
                    {muted ? (
                      <MicOnWebIcon
                        stroke={theme.palette.error.main}
                        fontSize="large"
                        sx={{
                          width: pxToRem(40),
                          height: pxToRem(40),
                          color: theme.palette.gray.light,
                        }}
                      />
                    ) : (
                      <MicOffWebIcon
                        color="primary"
                        stroke="white"
                        fontSize="large"
                        sx={{ width: pxToRem(40), height: pxToRem(40) }}
                      />
                    )}
                  </StyledSquareMenuButton>
                </Tooltip>
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    alignItems: "stretch",
                  }}
                >
                  {isVideoEnabled &&
                    (videoTransformsEnabled ? (
                      <Box>
                        <VideoTransformChoice
                          label="Filter"
                          controlType="button"
                        />
                      </Box>
                    ) : (
                      <Box />
                    ))}
                  <Tooltip
                    title={
                      isVideoEnabled
                        ? "Start with Video On"
                        : "Start with Video Off"
                    }
                    placement="top"
                  >
                    <StyledSquareMenuButton
                      size={chimeViewMode === "compact" ? "small" : "medium"}
                      onClick={() => {
                        setOnStartVideoEnabled(!isVideoEnabled);
                        setIsVideoEnabled(!isVideoEnabled);
                      }}
                      sx={{ borderRadius: 0, padding: 0 }}
                    >
                      {isVideoEnabled ? (
                        <VideoCallActiveIcon
                          color="primary"
                          stroke={theme.palette.primary.contrastText}
                          fontSize="large"
                          sx={{ width: pxToRem(40), height: pxToRem(40) }}
                        />
                      ) : (
                        <VideoCallInActiveIcon
                          stroke={theme.palette.grey[600]}
                          fontSize="large"
                          sx={{
                            width: pxToRem(40),
                            height: pxToRem(40),
                            color: theme.palette.gray.light,
                          }}
                        />
                      )}
                    </StyledSquareMenuButton>
                  </Tooltip>
                </Box>
              </Toolbar>
            </Box>
          </Grid>
          <Grid item container xs={12} spacing={3}>
            {canUserRecord && (
              <Grid item xs={12} sx={{ pl: 6 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ mt: 1 }}
                      color="primary"
                      checked={isRecordingBotEnabled}
                      onChange={handleRecordingEnabledChange}
                      name="toggleRecording"
                    />
                  }
                  label={
                    <Typography>
                      {recordingOptionText}
                      <Tooltip
                        title={recordingHelpText}
                        arrow
                        placement="bottom-end"
                      >
                        <Info color="info" />
                      </Tooltip>
                    </Typography>
                  }
                  variant="standard"
                />
              </Grid>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            {isMobile && (
              <Button
                variant="outlined"
                sx={{ mt: 2 }}
                endIcon={
                  <ExpandMore
                    sx={{
                      transform: !settingsOpen
                        ? "rotate(0deg)"
                        : "rotate(180deg)",
                      marginLeft: "auto",
                      transition: (theme) =>
                        theme.transitions.create("transform", {
                          duration: theme.transitions.duration.shortest,
                        }),
                    }}
                  />
                }
                onClick={() => setSettingsOpen(!settingsOpen)}
              >
                More Settings
              </Button>
            )}
            {/*<Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                flexGrow: 1,
                px: 2,
                mt: 2,
                border: '1px solid LightGrey',
                borderRadius: 2,
              }}
            >
              <Typography variant="body1">More Settings</Typography>
            </Box>*/}
          </Grid>
          <Collapse in={settingsOpen} timeout="auto">
            <Grid item container xs={12} spacing={1} sx={{ mt: 2 }}>
              <Grid item xs={12} md={6}>
                <QualitySelection />
              </Grid>
              <Grid item xs={12} md={6}>
                <CameraDevices />
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={12} md={6}>
                <MicrophoneActivityPreview />
              </Grid>
              <Grid item xs={12} md={6}>
                <MicSelection />
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12}>
                <SpeakerDevices />
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Box>
    );
  }
};

export default ConferenceInputChoices;
