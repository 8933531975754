export { default as VideoCallInActiveIcon } from "./VideoCallInActiveIcon";
export { default as VideoCallActiveIcon } from "./VideoCallActiveIcon";
export { default as ChangeScreenWebInactiveIcon } from "../trrystCustomIcons/ChangeScreenWebInactiveIcon";
export { default as MicOnWebIcon } from "./MicOnWebIcon";
export { default as MicOffWebIcon } from "./MicOffWebIcon";
export { default as ShareScreenWebInactiveIcon } from "./ShareScreenWebInactiveIcon";
export { default as ChatActiveWebIcon } from "./ChatActiveWebIcon";
export { default as ChatInactiveWebIcon } from "./ChatInactiveWebIcon";
export { default as EndCallWebIcon } from "./EndCallWebIcon";
export { default as InfoInactiveWebIcon } from "./InfoInactiveWebIcon";
export { default as InfoActiveWebIcon } from "./InfoActiveWebIcon";
export { default as ChangeScreenWebActiveIcon } from "./ChangeScreenWebActiveIcon";
export { default as ShareScreenWebActiveIcon } from "./ShareScreenWebActiveIcon";
export { default as MoreChoiceWebActiveIcon } from "./MoreChoiceWebActiveIcon";
export { default as MoreChoiceWebInactiveIcon } from "./MoreChoiceWebInactiveIcon";
export { default as AddUserWebIcon } from "./AddUserWebIcon";
export { default as CopyLinkWebIcon } from "./CopyLinkWebIcon";
