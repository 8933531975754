// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";

import { Icon } from "@iconify/react";
import homeFill from "@iconify/icons-eva/home-fill";
import fileFill from "@iconify/icons-eva/file-fill";
import baselinePaid from "@iconify/icons-ic/baseline-paid";
import bookOpenFill from "@iconify/icons-eva/book-open-fill";
// routes
import { PATH_AUTH, PATH_DOCS, PATH_PAGE, PATH_DASHBOARD } from "routes/paths";

// ----------------------------------------------------------------------
import { useIntl } from "react-intl";
import { useRouter } from "next/router";

const ICON_SIZE = {
  width: 22,
  height: 22,
};

export default function MenuConfig() {
  const router = useRouter();
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });
  const { pathname } = router;
  const isHome = pathname === PATH_PAGE.root;

  let home = {
    title: `${t("TopNavbar.home")}`,
    icon: <Icon icon={homeFill} {...ICON_SIZE} />,
    path: PATH_PAGE.root,
  };
  let features = {
    title: `${t("TopNavbar.features")}`,
    icon: <Icon icon={fileFill} {...ICON_SIZE} />,
    path: PATH_PAGE.features,
  };
  let pricing = {
    title: `${t("TopNavbar.pricing")}`,
    icon: <Icon icon={baselinePaid} {...ICON_SIZE} />,
    path: PATH_PAGE.pricing,
  };

  let suites = {
    title: "Solutions",
    path: "/home/suites",
    icon: <Icon icon={homeFill} {...ICON_SIZE} />,

    children: [
      {
        subheader: "",
        items: [
          {
            title: "Boardroom Suites",
            path: PATH_PAGE.boardsuite,
            isActive: true,
          },
          {
            title: "Executive Suites",
            path: PATH_PAGE.execsuite,
            isActive: false,
          },
          {
            title: "Incident response & DR Suites",
            path: PATH_PAGE.incidentsuite,
            isActive: false,
          },
          {
            title: "Data Suites (VDR)",
            path: PATH_PAGE.datasuite,
            isActive: true,
          },
          {
            title: "Personal Suite (Free)",
            path: PATH_PAGE.personalsuite,
            isActive: false,
          },
        ],
      },
    ],
  };
  let pages = {
    title: "About",
    path: PATH_PAGE.about,
    icon: <Icon icon={fileFill} {...ICON_SIZE} />,
  };
  // return [features, pricing, pages];
  let baseConfig = !isHome ? [home] : [];

  if (process.env.STAGE !== "prod") {
    baseConfig.push(...[features, pages, pricing]);
  } else baseConfig.push(...[features, pages, pricing]);

  return baseConfig;
}
