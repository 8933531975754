import React from "react";
import { Box, Typography, IconButton, ClickAwayListener } from "@mui/material";
import { FlowBox } from "blocks/atoms/uistyles";
import { LoadingSpinner } from "blocks/atoms";
import { useAppContext } from "@app21/core";
import { useTasksContext } from "providers/TasksProvider";
import ShowEmptyTasksComponent from "./ShowEmptyTasksComponent";
import { useAtom } from "jotai";
import { centerpanelContextAtom } from "blocks/modules/Tasks/tasks-atoms";
import { TaskEditForm } from "blocks/modules/Tasks/foundations";

export default function TaskDetailsPanel({}) {
  const [centerpanelContext, setCenterpanelContext] = useAtom(
    centerpanelContextAtom
  );
  const {
    isMobile,
    isDocket,
    viewContext,
    actionContext,
    selectedTaskId,
    selectedTask,
    fetchedTasks,
    fetchedTasksStatus,
  } = useTasksContext();

  if (fetchedTasksStatus === "loading") {
    return <LoadingSpinner />;
  }

  const ShowSelectATaskComponent = () => {
    return (
      <FlowBox
        sx={{
          p: 2,
          flexGrow: 3,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">Select a task to view details</Typography>
      </FlowBox>
    );
  };

  if (fetchedTasks?.allTasks?.length === 0 && actionContext !== "add") {
    return (
      <FlowBox sx={{ p: 2, flexGrow: 3 }}>
        <ShowEmptyTasksComponent />
      </FlowBox>
    );
  }

  switch (actionContext) {
    case "list":
      return !selectedTaskId ? <ShowSelectATaskComponent /> : <TaskEditForm />;
    case "view":
      return !selectedTaskId ? <ShowSelectATaskComponent /> : <TaskEditForm />;

    case "edit":
      return !selectedTaskId ? <TaskEditForm /> : <TaskEditForm />;
    case "add":
      return <TaskEditForm />;

    default:
      return <TaskEditForm />;
  }
}
