// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Card, CardHeader, Skeleton, Avatar } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledSkeletonCard = styled(Card)(({ theme }) => ({
  width: "100%",
  borderRadius: 5,
  display: "flex",
  maxWidth: "calc(100% - 15px)",
  flexGrow: 1,
}));
const SkeletonUserCard = () => {
  return (
    <StyledSkeletonCard elevation={3} sx={{ m: 1, p: 1 }}>
      <CardHeader
        sx={{ m: 0, p: 0 }}
        title={
          <Skeleton animation="wave" variant="text" width={100} height={30} />
        }
        avatar={
          <Skeleton variant="circular">
            <Avatar />
          </Skeleton>
        }
      />
    </StyledSkeletonCard>
  );
};
export default SkeletonUserCard;
