// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import PropTypes from "prop-types";
import { forwardRef } from "react";
// material
import { alpha } from "@mui/material/styles";
import { IconButton, useTheme } from "@mui/material";
//
import { IconButtonAnimate } from "blocks/atoms/uieffects/animate";

// ----------------------------------------------------------------------

const MIconButton = forwardRef(
  ({ color = "default", children, sx, ...other }, ref) => {
    const theme = useTheme();

    if (
      color === "default" ||
      color === "inherit" ||
      color === "primary" ||
      color === "secondary"
    ) {
      return (
        <IconButtonAnimate>
          <IconButton ref={ref} color={color} sx={sx} {...other}>
            {children}
          </IconButton>
        </IconButtonAnimate>
      );
    }

    return (
      <IconButtonAnimate>
        <IconButton
          ref={ref}
          sx={{
            color: theme.palette[color].main,
            "&:hover": {
              bgcolor: alpha(
                theme.palette[color].main,
                theme.palette.action.hoverOpacity
              ),
            },
            ...sx,
          }}
          {...other}
        >
          {children}
        </IconButton>
      </IconButtonAnimate>
    );
  }
);

MIconButton.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
  color: PropTypes.oneOf([
    "default",
    "inherit",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
  ]),
};

export default MIconButton;
