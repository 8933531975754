// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import { Avatar, Box, Paper, Typography } from "@mui/material";
import { useUserInfo, getS3ImageWrapper, checkIfNull } from "@app21/core";

import toast from "react-hot-toast";

const Comment = ({ comment, viewMode = "normal", ...rest }) => {
  const {
    data: member,
    apiError: userInfoApiError,
    status: userInfoStatus,
  } = useUserInfo(comment.who);

  React.useEffect(() => {
    if (userInfoApiError && userInfoStatus != "loading") {
      toast.error(userInfoApiError.message);
    }
  }, [userInfoApiError]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", mb: 1 }} {...rest}>
      <Box sx={{ ml: 2, flexGrow: 1, display: "flex", alignItems: "center" }}>
        <Avatar
          sx={{
            height: viewMode === "compact" ? 20 : 25,
            width: viewMode === "compact" ? 20 : 25,
            mr: 1,
          }}
          variant="rounded"
          alt="Person"
          src={getS3ImageWrapper(member?.image, "person")}
        />
        <Paper
          elevation={0}
          sx={{
            flexGrow: 1,
            backgroundColor:
              viewMode === "compact"
                ? "transparent"
                : (theme) => theme.palette.background.default,
            p: (theme) => theme.spacing(viewMode === "compact" ? 0 : 1),
          }}
        >
          <Typography
            variant={viewMode === "compact" ? "caption" : "body2"}
            color="textPrimary"
            sx={{ fontStyle: "italic" }}
          >
            {comment?.comment}
          </Typography>
        </Paper>
      </Box>

      <Box sx={{ display: "flex", mt: 1, alignSelf: "flex-end" }}>
        <Typography
          variant="caption"
          color="textSecondary"
          gutterBottom
          sx={{ ml: 1, fontSize: 9 }}
        >
          {member?.fullName} -
        </Typography>
        {!checkIfNull(comment?.when) && (
          <Typography
            variant="caption"
            color="textSecondary"
            sx={{ ml: 1, fontSize: 9 }}
          >
            {format(new Date(comment?.when), "do MMM, yy")} {" at "}
            {format(new Date(comment?.when), "h:mm a")}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

Comment.propTypes = {
  comment: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default Comment;
