// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { useState } from "react";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import { useIntl } from "react-intl";
import {
  Box,
  Button,
  LinearProgress,
  Typography,
  TextField,
  SvgIcon,
} from "@mui/material";

import { List as ListIcon } from "react-feather";
import CheckItem from "./CheckItem";
import CheckItemAdd from "./CheckItemAdd";

const Checklist = ({ card, checklist, className, ...rest }) => {
  const [name, setName] = useState(checklist.name);
  const [editingName, setEditingName] = useState(false);
  const [editingCheckItem, setEditingCheckItem] = useState(null);

  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });

  const handleNameEdit = () => {
    setEditingName(true);
  };

  const handleNameChange = (event) => {
    event.persist();
    setName(event.target.value);
  };

  const handleNameSave = async () => {
    try {
      if (!name || name === checklist.name) {
        setEditingName(false);
        setName(checklist.name);
        return;
      }

      setEditingName(false);
      //      await dispatch(updateChecklist(card.id, checklist.id, { name }));
      toast.success("Checklist updated");
    } catch (err) {
      toast.error(`Something went wrong - ${JSON.stringify(err)} `);
    }
  };

  const handleNameCancel = () => {
    setEditingName(false);
    setName(checklist.name);
  };

  const handleDelete = async () => {
    try {
      //   await dispatch(deleteChecklist(card.id, checklist.id));
      toast.success("Checklist deleted");
    } catch (err) {
      console.error(err);
      toast.error(`Something went wrong - ${JSON.stringify(err)} `);
    }
  };

  const handleCheckItemEditInit = (checkItemId) => {
    setEditingCheckItem(checkItemId);
  };

  const handleCheckItemEditCancel = () => {
    setEditingCheckItem(null);
  };

  const handleCheckItemEditComplete = () => {
    setEditingCheckItem(null);
  };

  const totalCheckItems = checklist.checkItems.length;
  const completedCheckItems = checklist.checkItems.filter(
    (checkItem) => checkItem.state === "complete"
  ).length;
  const completePercentage =
    totalCheckItems === 0 ? 100 : (completedCheckItems / totalCheckItems) * 100;

  return (
    <div {...rest}>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <SvgIcon
          fontSize="small"
          color="action"
          sx={{ mr: (theme) => theme.spacing(3) }}
        >
          <ListIcon />
        </SvgIcon>
        {editingName ? (
          <Box
            sx={{
              flexGrow: 1,
            }}
          >
            <TextField value={name} fullWidth onChange={handleNameChange} />
            <Box
              sx={{
                mt: 1,
              }}
            >
              <Button size="small" variant="contained" onClick={handleNameSave}>
                Save
              </Button>
              <Button size="small" onClick={handleNameCancel}>
                Cancel
              </Button>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexGrow: 1,
            }}
          >
            <Typography
              variant="h4"
              color="textPrimary"
              onClick={handleNameEdit}
            >
              {checklist.name}
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
              }}
            />
            <Button size="small" onClick={handleDelete}>
              {`${t("global.buttons.delete")}`}
            </Button>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          mt: 1,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography variant="caption" color="textSecondary">
          {Math.round(completePercentage)}%
        </Typography>
        <Box
          sx={{
            ml: 2,
            flexGrow: 1,
          }}
        >
          <LinearProgress
            variant="determinate"
            value={completePercentage}
            color="secondary"
          />
        </Box>
      </Box>
      <Box
        sx={{
          mt: 3,
        }}
      >
        {checklist.checkItems.map((checkItem) => (
          <CheckItem
            editing={editingCheckItem === checkItem.id}
            checkItem={checkItem}
            card={card}
            checklist={checklist}
            key={checkItem.id}
            onEditCancel={handleCheckItemEditCancel}
            onEditComplete={handleCheckItemEditComplete}
            onEditInit={() => handleCheckItemEditInit(checkItem.id)}
          />
        ))}
      </Box>
      <Box
        sx={{
          mt: 1,
          ml: 6,
        }}
      >
        <CheckItemAdd card={card} checklist={checklist} />
      </Box>
    </div>
  );
};

Checklist.propTypes = {
  className: PropTypes.string,
  card: PropTypes.object.isRequired,
  checklist: PropTypes.object.isRequired,
};

export default Checklist;
