// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import NavbarLayout from "blocks/views/Layouts/features/NavbarLayout";
import { useAppContext, useOrgsAndSuitesForUser } from "@app21/core";
import toast from "react-hot-toast";
import { LoadingSpinner } from "blocks/atoms";

const DashboardNavbar = (props) => {
  const { userInfo, isBasicDataReady } = useAppContext();

  const {
    data: orgsAndSuites,
    status: orgsAndSuitesStatus,
    apiError: orgsAndSuitesApiError,
  } = useOrgsAndSuitesForUser(userInfo?._id);

  React.useEffect(() => {
    if (orgsAndSuitesApiError && orgsAndSuitesStatus != "loading") {
      toast.error(orgsAndSuitesApiError.message);
    }
  }, [orgsAndSuitesApiError]);

  if (isBasicDataReady && orgsAndSuitesStatus === "success") {
    return (
      <NavbarLayout
        orgsAndSuites={orgsAndSuites}
        viewContext="main"
        {...props}
      />
    );
  } else return <LoadingSpinner variant="exp" />;
};

export default DashboardNavbar;
