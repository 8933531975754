import React, { useRef } from "react";
import { Box, Container, Typography, Grid, styled } from "@mui/material";
import { TasksMenubar, TaskDetailsPanel } from "../foundations";
import FileViewer from "blocks/modules/FileViewer";
import { checkIfNull, useAppContext } from "@app21/core";
import { FlowBox } from "blocks/atoms/uistyles";
import { useTasksContext } from "providers/TasksProvider";

export default function TasksCenterPanel({ isMobile = false }) {
  const { viewFile } = useAppContext();
  const { viewContext, actionContext, selectedTaskId } = useTasksContext();
  const fileViewerFlag = !checkIfNull(viewFile);

  return (
    <FlowBox sx={{ gap: 3 }}>
      {fileViewerFlag ? (
        <FileViewer viewContext={viewContext} />
      ) : (
        <FlowBox
          sx={{
            overflow: "hidden",
          }}
        >
          <FlowBox sx={{}}>
            <FlowBox
              sx={{
                overflow: "hidden",
              }}
            >
              <TaskDetailsPanel isMobile={isMobile} />
            </FlowBox>
          </FlowBox>
        </FlowBox>
      )}
    </FlowBox>
  );
}
