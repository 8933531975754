import React from 'react'
import { SvgIcon } from '@mui/material';

export default function RecordIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M67.6393 49.998C67.6393 40.248 59.7643 32.373 50.0143 32.373C40.2643 32.373 32.3893 40.248 32.3893 49.998C32.3893 59.748 40.2643 67.623 50.0143 67.623C52.3893 67.623 54.6393 67.1647 56.6809 66.3314"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.6667 25.0002C11.4584 31.9585 8.33337 40.6252 8.33337 50.0002C8.33337 73.0002 27 91.6668 50 91.6668C73 91.6668 91.6667 73.0002 91.6667 50.0002C91.6667 27.0002 73 8.3335 50 8.3335C44.0417 8.3335 38.3334 9.5835 33.2084 11.8752"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="50" cy="50" r="25" fill="currentColor" />
      </g>
    </SvgIcon>
  );
}
