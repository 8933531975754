import React from "react";
import {
  Card,
  CardHeader,
  IconButton,
  Typography,
  CardActionArea,
} from "@mui/material";
import { PrintUserInfo } from "blocks/atoms";
import { Clear, RemoveRedEyeOutlinedIcon } from "@mui/icons-material";
import { useAtom } from "jotai";
import {
  OriginalMessageOnReplyAtom,
  OriginalMessageInReplyClickedAtom,
} from "blocks/modules/TrrystChat/state-atoms";
import clip from "text-clipper";
import FileMessageCard from "./FileMessageCard";

export default function ReplyMessageCard({
  isMobile,
  originalMessageOnReply,
  viewContext = "reply",
}) {
  const [, setOriginalMessageOnReply] = useAtom(OriginalMessageOnReplyAtom);
  const [, setOriginalMessageInReplyClicked] = useAtom(
    OriginalMessageInReplyClickedAtom
  );

  const contentMessage =
    (originalMessageOnReply.message.customElements.app21MessageType ===
      "IMAGE-FILE" ||
      originalMessageOnReply.message.customElements.app21MessageType ===
        "VIDEO-FILE" ||
      originalMessageOnReply.message.customElements.app21MessageType ===
        "AUDIO-FILE" ||
      originalMessageOnReply.message.customElements.app21MessageType ===
        "PDF-FILE") ??
    false;

  const handleReplyClear = () => {
    setOriginalMessageOnReply({});
  };

  const handleReplyMessageClick = () => {
    setOriginalMessageInReplyClicked(originalMessageOnReply);
    setTimeout(() => {
      let originalMessageOnReplyElement = document.getElementById(
        `tr-chat-msg-${originalMessageOnReply?.timetoken}`
      );
      if (originalMessageOnReplyElement) {
        originalMessageOnReplyElement.scrollIntoView(true);
      }
    }, 500);
  };
  // if (

  // ) {
  //   return <FileMessageCard message={originalMessageOnReply} />;
  // } else {

  return (
    <Card
      sx={{
        borderRadius: 1,
        boxShadow: "none",
        border: "1px solid #dfdfdf",
        mb: 1,
        bgcolor: (theme) => theme.palette.grey[200],
      }}
    >
      <CardActionArea onClick={handleReplyMessageClick}>
        <CardHeader
          sx={{ m: 0, p: 0.7, pr: 1 }}
          disableTypography
          {...(contentMessage
            ? {
                avatar: (
                  <FileMessageCard
                    viewContext="reply"
                    message={originalMessageOnReply}
                  />
                ),
              }
            : {})}
          title={
            <Typography variant="caption" sx={{ fontWeight: 600 }}>
              <PrintUserInfo
                userId={
                  originalMessageOnReply?.publisher ??
                  originalMessageOnReply?.uuid
                }
              />
            </Typography>
          }
          subheader={
            <Typography variant="caption" sx={{ fontWeight: 400 }}>
              <br />
              {clip(originalMessageOnReply?.message?.text ?? "", 25, {
                breakWords: true,
              })}
            </Typography>
          }
          action={
            viewContext === "reply" ? (
              <IconButton size="small" onClick={handleReplyClear}>
                <Clear fontSize="inherit" />
              </IconButton>
            ) : (
              <></>
            )
          }
        />
      </CardActionArea>
    </Card>
  );
}
