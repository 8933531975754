// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Chip,
  Breadcrumbs,
  IconButton,
  Button,
  Typography,
  Menu,
  MenuItem,
  alpha,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { ExpandMore, Home } from "@mui/icons-material";
import {
  useAppContext,
  useHandleRouterPush,
  useSuite,
  useDockets,
  useUsersInSuite,
  getRolePrivileges,
} from "@app21/core";

import { useRouter } from "next/router";
import { SuiteSwitchButton } from "blocks/views/SuiteDashboard/foundations";
import {
  usePopupState,
  bindToggle,
  bindMenu,
} from "material-ui-popup-state/hooks";
import useDeepCompareEffect from "use-deep-compare-effect";
import clip from "text-clipper";
import { getSuiteAndOrganizationLabel } from "utils";
import toast from "react-hot-toast";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useIsMobileBreakpoint } from "hooks";
import { DropDownIcon, HomeIcon } from "blocks/atoms/trrysticons";
import { UserAvatar } from "blocks/atoms";

const RootStyle = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
  overflow: "hidden",
}));

const Separator = (
  <Box
    component="span"
    sx={{ width: 4, height: 4, borderRadius: "50%", bgcolor: "text.disabled" }}
  />
);

const ShowPathCrumbs = () => {
  const router = useRouter();
  const { loadRoute } = useHandleRouterPush();
  const isMobile = useIsMobileBreakpoint();
  const routerPath = router.pathname;
  const theme = useTheme();
  const routerParams = JSON.parse(localStorage.getItem("lastIds"));
  const {
    selectedSuiteId,
    selectedDocketId,
    selectedOrganizationId,
    meetingId,
    checkAccessRules,
    userInfo,
  } = useAppContext();

  const {
    data: selectedSuite,
    status: selectedSuiteStatus,
    apiError: selectedSuiteApiError,
  } = useSuite(selectedSuiteId);
  const {
    data: selectedDocket,
    status: selectedDocketStatus,
    apiError: selectedDocketApiError,
  } = useDockets(selectedDocketId);
  const {
    data: usersInSuite,
    status: usersInSuiteStatus,
    apiError: usersInSuiteApiError,
  } = useUsersInSuite(selectedSuiteId);

  const dataFetchError =
    usersInSuiteApiError || selectedSuiteApiError || selectedDocketApiError;
  const dataFetchLoading =
    selectedSuiteStatus === "loading" ||
    usersInSuiteStatus === "loading" ||
    selectedDocketStatus === "loading";
  const isPersonalSuite = selectedSuite?.suiteType?.includes("PERSONAL");
  const isOwnPersonalSuite =
    isPersonalSuite && selectedSuite?.createdBy === userInfo?._id;

  const suiteLabel = isPersonalSuite ? (
    isOwnPersonalSuite ? (
      "My Suite"
    ) : (
      <>
        <UserAvatar userId={selectedSuite.createdBy} viewContext={"name"} />
        's Personal Suite
      </>
    )
  ) : (
    selectedSuite?.fullName
  );
  React.useEffect(() => {
    if (dataFetchError && !dataFetchLoading) {
      toast.error(dataFetchError.message);
    }
  }, [dataFetchError]);

  const [title, setTitle] = React.useState(selectedDocket?.title);
  const routeSelectPopupState = usePopupState({
    variant: "popover",
    popupId: "routeSelectMenu",
  });

  useDeepCompareEffect(() => {
    selectedDocket && setTitle(selectedDocket.title);
  }, [selectedDocket || {}]);

  const appOptions = [
    {
      value: "dashboard",
      label: "Dashboard",
      included: !(isPersonalSuite && !isOwnPersonalSuite),
      handleClickFunction: () => loadRoute("VIEW-SUITE", {}),
    },
    {
      value: "dockets",
      label: "Breakouts",
      included: checkAccessRules({
        entity: "MENU",
        action: "SHOW-DOCKET-MENU",
        featureName: "DOCKET",
        ...getRolePrivileges({
          userId: userInfo?._id,
          baseObject: selectedSuite,
          members: usersInSuite?.map((user) => user._id) ?? [],
        }),
      })?.accessFlag,
      handleClickFunction: () => loadRoute("LIST-DOCKETS", {}),
    },
    {
      value: "files",
      label: "Files",
      included: checkAccessRules({
        entity: "MENU",
        action: "SHOW-DATAROOM-MENU",
        featureName: "DATAROOM",
        ...getRolePrivileges({
          userId: userInfo?._id,
          baseObject: selectedSuite,
          members: usersInSuite?.map((user) => user._id) ?? [],
        }),
      })?.accessFlag,
      handleClickFunction: () =>
        loadRoute("VIEW-DATAROOM", {
          s3Key: `${selectedOrganizationId}/${selectedSuiteId}/`,
        }),
    },
    {
      value: "tasks",
      label: "Tasks",
      included: checkAccessRules({
        entity: "MENU",
        action: "SHOW-ACTIONS-MENU",
        featureName: "ACTIONS",
        ...getRolePrivileges({
          userId: userInfo?._id,
          baseObject: selectedSuite,
          members: usersInSuite?.map((user) => user._id) ?? [],
        }),
      })?.accessFlag,
      handleClickFunction: () => loadRoute("LIST-TASKS", {}),
    },
    {
      value: "surveys",
      label: "Surveys",
      included: checkAccessRules({
        entity: "MENU",
        action: "SHOW-SURVEYS-MENU",
        featureName: "SURVEYS",
        ...getRolePrivileges({
          userId: userInfo?._id,
          baseObject: selectedSuite,
          members: usersInSuite?.map((user) => user._id) ?? [],
        }),
      })?.accessFlag,
      handleClickFunction: () => loadRoute("LIST-SURVEYS", {}),
    },
    {
      value: "calendar",
      label: "Calendar",
      included: checkAccessRules({
        entity: "MENU",
        action: "SHOW-CALENDAR-MENU",
        featureName: "CALENDAR",
        ...getRolePrivileges({
          userId: userInfo?._id,
          baseObject: selectedSuite,
          members: usersInSuite?.map((user) => user._id) ?? [],
        }),
      })?.accessFlag,

      handleClickFunction: () => loadRoute("VIEW-CALENDAR", {}),
    },
  ];
  const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    return {
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.body2.fontSize,
      "&:hover": { bgcolor: (theme) => theme.palette.primary.lighter },
      "&:active, &:focus": {
        outline: `1px solid ${theme.palette.primary.main}`,
      },
      "& .MuiChip-deleteIcon": {
        "&:hover": {},
        "&:active, &:focus": {
          color: theme.palette.secondary.light,
        },
      },
      "& .MuiChip-icon": {},
      "& .MuiChip-clickable": {
        "&:hover": { bgcolor: (theme) => theme.palette.primary.lighter },
      },
    };
  });
  const getSuiteDashboardViewComponent = (suiteType) => {
    switch (true) {
      case suiteType?.includes("DATA"):
        return theme.palette.dataSuite.main;
      case suiteType?.includes("EXEC"):
        return theme.palette.execSuite.main;
      case suiteType?.includes("PERSONAL"):
        return theme.palette.personalSuite.main;
      case suiteType?.includes("BOARD"):
        return theme.palette.boardSuite.main;
      default:
        return theme.palette.execSuite.main;
    }
  };

  const StyledBreadcrumbIcon = styled(IconButton)(({ theme }) => {
    return {
      fontWeight: theme.typography.fontWeightRegular,
    };
  });

  const getSwitchChip = (currentChoice, index) => {
    const currentRouteInfo = appOptions?.find(
      (option) => option.value === currentChoice
    );
    const filteredOptions = appOptions.filter(
      (choice) => choice.value !== currentChoice && choice.included
    );

    const handleRouteChangeClick = (targetOption) => {
      routeSelectPopupState.close();
      appOptions
        .find((option) => option.value === targetOption)
        .handleClickFunction();
    };

    const getMenuList = () => {
      return filteredOptions.map((option, newindex) => (
        <MenuItem
          key={newindex}
          onClick={() => handleRouteChangeClick(option.value)}
        >
          {option.label}
        </MenuItem>
      ));
    };
    return (
      <span key={index}>
        <Chip
          label={currentRouteInfo.label}
          size="small"
          {...bindToggle(routeSelectPopupState)}
          onClick={() => handleRouteChangeClick(currentRouteInfo.value)}
          {...(filteredOptions.length > 0
            ? {
                deleteIcon: <DropDownIcon color="secondary" />,
                onDelete: (event) =>
                  routeSelectPopupState.open(event.currentTarget),
              }
            : {})}
          sx={{
            fontSize: theme.typography.body2.fontSize,

            bgcolor: (theme) => alpha(theme.palette.primary.light, 0.7),
            color: theme.palette.common.white,
            "&:hover": {
              bgcolor: (theme) => theme.palette.primary.lighter,
            },
          }}
        />
        <Menu
          {...bindMenu(routeSelectPopupState)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{
            mt: 1.5,
            display: "flex",
            flexDirection: "column",
            p: 0,
          }}
        >
          {getMenuList()}
        </Menu>
      </span>
    );
  };
  const processPathInfo = (routerPath, routerParams) => {
    let crumbs = [];
    let modRouterPath = routerPath ?? " ";

    crumbs[0] = {
      label: suiteLabel,
      handleClickFunction: () =>
        isPersonalSuite && !isOwnPersonalSuite
          ? null
          : loadRoute("VIEW-SUITE", {}),
    };
    if (modRouterPath.includes("docket")) {
      crumbs.push({
        value: "dockets",
        label: "Breakouts",
        handleClickFunction: () => loadRoute("LIST-DOCKETS", {}),
      });
      if (
        (modRouterPath !== "/user/organization/suite/docket/[action]" ||
          routerParams?.action !== "list") &&
        !meetingId
      ) {
        crumbs.push({ label: title });
      }
    } else if (modRouterPath.includes("tasks")) {
      crumbs.push({ value: "tasks", label: "Tasks" });
    } else if (modRouterPath.includes("surveys")) {
      crumbs.push({ value: "surveys", label: "Surveys" });
    } else if (modRouterPath.includes("dataroom")) {
      crumbs.push({ value: "files", label: "Files" });
    } else if (modRouterPath.includes("calendar")) {
      crumbs.push({ value: "calendar", label: "Schedule" });
    } else crumbs.push({ value: "dashboard", label: "Dashboard" });
    return crumbs;
  };

  const pathCrumbs = processPathInfo(routerPath, routerParams);
  return (
    <RootStyle>
      <Breadcrumbs
        separator={
          <ArrowRightIcon fontSize="small" sx={{ color: "text.secondary" }} />
        }
        aria-label="breadcrumb"
        sx={{ overflow: "hidden", "& .MuiBreadcrumbs-separator": { mx: 0 } }}
      >
        <StyledBreadcrumbIcon
          onClick={() => loadRoute("GOTO-MAINDASHBOARD", {})}
        >
          <HomeIcon
            fontSize="medium"
            sx={{ color: (theme) => theme.palette.text.secondary }}
          />
        </StyledBreadcrumbIcon>
        {pathCrumbs.map((crumb, index) => {
          if (index === 0) {
            return (
              <span
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: theme.palette.grey[0],
                }}
              >
                {/* <SuiteSwitchButton viewOption={'chipOnly'} />
                <Chip label={pathCrumbs[0].label} onClick={pathCrumbs[0].handleClickFunction} color="primary" /> */}
                <StyledBreadcrumb
                  label={pathCrumbs[0].label}
                  onClick={() =>
                    isPersonalSuite && !isOwnPersonalSuite
                      ? null
                      : loadRoute("VIEW-SUITE", {})
                  }
                  deleteIcon={<SuiteSwitchButton viewOption={"chipOnly"} />}
                  onDelete={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                  }}
                  sx={{
                    bgcolor: (theme) => theme.palette.grey[0],
                  }}
                />
              </span>
            );
            // return (
            //   <span
            //     key={index}
            //     style={{ display: "flex", alignItems: "center" }}
            //   >
            //     <SuiteSwitchButton viewOption={"chipOnly"} />
            //     <Chip
            //       label={pathCrumbs[0].label}
            //       onClick={pathCrumbs[0].handleClickFunction}
            //       color="primary"
            //     />
            //   </span>
            // );
          } else if (index === 1) {
            return getSwitchChip(crumb.value, index);
          } else {
            return crumb.handleClickFunction ? (
              <Button
                key={index}
                onClick={crumb.handleClickFunction}
                color="primary"
              >
                {crumb.label}
              </Button>
            ) : (
              <Typography
                key={index}
                variant="body2"
                color={
                  index === pathCrumbs.length - 1 ? "text.secondary" : "inherit"
                }
              >
                {clip(crumb.label, isMobile ? 20 : 35)}
              </Typography>
            );
          }
        })}
      </Breadcrumbs>
    </RootStyle>
  );
};

ShowPathCrumbs.propTypes = {
  className: PropTypes.string,
};

export default ShowPathCrumbs;
