// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { useState } from "react";
import LoadingSpinner from "blocks/atoms/LoadingSpinner";
import {
  Container,
  Box,
  Paper,
  IconButton,
  InputAdornment,
  SvgIcon,
  TextField,
  Typography,
  Fab,
  Pagination,
  Grid,
} from "@mui/material";
import {
  Edit as EditIcon,
  ArrowRight as ArrowRightIcon,
  Search as SearchIcon,
  PlusCircle as PlusCircleIcon,
} from "react-feather";
import { Close } from "@mui/icons-material";
import {
  useAppContext,
  checkIfNull,
  getRolePrivileges,
  useUsersInOrganization,
  useDocketsInSuite,
  useUsersInSuite,
  useSuite,
  useHandleRouterPush,
} from "@app21/core";
import { useIsMobileBreakpoint } from "hooks";

import { DocketEditForm } from "blocks/modules/Dockets";
import { DocketSummaryCard } from "../foundations";
import toast from "react-hot-toast";

const sortOptions = [
  {
    value: "createdOn|desc",
    label: "Date (newest first)",
  },
  {
    value: "createdOn|asc",
    label: "Date (oldest first)",
  },
  {
    value: "title|desc",
    label: "Meeting title (Z to A)",
  },
  {
    value: "title|asc",
    label: "Meeting title (A to Z)",
  },
];

const applyFilters = (dockets = [], query, filters) => {
  return dockets.filter((docket) => {
    let matches = true;
    let stringSum = JSON.stringify(docket);
    if (query) {
      const properties = ["title", "status"];
      let containsQuery = false;
      // the regex expression is to avoid parsing through html expressions in minutes etc
      // regex expression not being used.. use if needed.. stringSum.toLowerCase().replace(/<[^>]+>/g, '').includes(query.toLowerCase())
      if (stringSum.toLowerCase().includes(query.toLowerCase())) {
        containsQuery = true;
      }
      properties.forEach((property) => {
        if (docket[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if (value && docket[key] !== value) {
        matches = false;
      }
    });

    return matches;
  });
};

const applyPagination = (dockets, page, limit) => {
  return dockets.slice((page - 1) * limit, page * limit);
};

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const applySort = (dockets, sort) => {
  const [orderBy, order] = sort.split("|");
  const comparator = getComparator(order, orderBy);

  const stabilizedThis = dockets.map((el, index) => {
    let timeStamp = parseInt(el._id?.substr(0, 8), 16) * 1000;
    return [el, index, timeStamp];
  });

  //First sort is to fix by created date
  stabilizedThis.sort((a, b) => (a[2] < b[2] ? 1 : -1));

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);

    if (order !== 0) return order;

    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};

const ExecSuitesBreakoutsDashboardView = ({ newDocket = false, ...rest }) => {
  const isMobile = useIsMobileBreakpoint();
  const { userInfo, checkAccessRules } = useAppContext();
  const { selectedSuiteId, selectedOrganizationId } = useAppContext();

  const {
    data: usersInSuite,
    status: usersInSuiteStatus,
    apiError: usersInSuiteApiError,
  } = useUsersInSuite(selectedSuiteId);
  const {
    data: selectedSuite,
    status: selectedSuiteStatus,
    apiError: selectedSuiteApiError,
  } = useSuite(selectedSuiteId);
  const {
    data: usersInOrganization,
    status: usersInOrganizationStatus,
    apiError: usersInOrganizationApiError,
  } = useUsersInOrganization(selectedOrganizationId);
  const {
    data: dockets,
    status: docketsStatus,
    apiError: docketsApiError,
  } = useDocketsInSuite(selectedSuiteId);
  const dataFetchError =
    docketsApiError ||
    usersInOrganizationApiError ||
    usersInSuiteApiError ||
    selectedSuiteApiError;
  const dataFetchLoading =
    docketsStatus === "loading" ||
    usersInOrganizationStatus === "loading" ||
    usersInSuiteStatus === "loading" ||
    selectedSuiteStatus === "loading";

  React.useEffect(() => {
    if (dataFetchError && !dataFetchLoading) {
      toast.error(dataFetchError.message);
    }
  }, [dataFetchError]);

  const { loadRoute } = useHandleRouterPush();
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [newDocketFlag, setNewDocketFlag] = React.useState(newDocket);
  const [selectedDocket, setSelectedDocket] = React.useState(null);

  const handleEditDocketClose = () => {
    setOpenEditDialog(false);
    setNewDocketFlag(false);
    setSelectedDocket(null);
  };

  //const { data: docketMeetings, status: docketMeetingsStatus } = useMeetingsInDockets(listOfDocketIds);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);
  const [query, setQuery] = useState("");
  const [sort, setSort] = useState(sortOptions[0].value);

  const populatedDockets = React.useMemo(() => {
    let filledDockets = [];
    dockets?.reverse().forEach((docket) => {
      let tempDocket;
      let membersInfo = [];
      docket.members?.forEach((member) =>
        membersInfo.push(
          usersInOrganization?.find((user) => user._id === member)
        )
      );
      let combinedInviteesInfo = [...membersInfo, ...(docket?.visitors || [])];

      tempDocket = {
        ...docket,
        membersInfo,

        combinedInviteesInfo,
      };

      filledDockets.push(tempDocket);
    });
    return filledDockets;
  }, [dockets, usersInOrganization]);

  const [filters, setFilters] = useState({});

  const handleDocketEdit = (docket) => {
    setSelectedDocket(docket);
    setOpenEditDialog(true);
  };
  const handleDocketCreate = () => {
    setNewDocketFlag(true);
    setOpenEditDialog(true);
  };

  const handleQueryChange = (event) => {
    event.persist();
    setQuery(event.target.value);
  };

  const handleSortChange = (event) => {
    event.persist();
    setSort(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value));
  };

  const handleDocketViewClick = (
    event,
    docketId,
    orgId,
    suiteId,
    userIsDocketVisitor
  ) => {
    event.stopPropagation();
    loadRoute("VIEW-DOCKET", { docketId, orgId, suiteId, userIsDocketVisitor });
  };

  const handleDocketEditClick = (event, docketId) => {
    event.stopPropagation();
    loadRoute("EDIT-DOCKET", { docketId: docketId });
  };

  const filteredDockets = applyFilters(populatedDockets, query, filters);
  const sortedDockets = React.useMemo(
    () => applySort(filteredDockets, sort),
    [sort, filteredDockets]
  );
  const paginatedDockets = applyPagination(sortedDockets, page, limit);
  const { accessFlag: canCreateDocket } = checkAccessRules({
    entity: "SUITE",
    action: "CREATE-DOCKET",
    featureName: "DOCKET",
    ...getRolePrivileges({
      userId: userInfo._id,
      baseObject: selectedSuite,
      members: usersInSuite.map((user) => user._id),
    }),
  });

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        m: 0,
        p: 0,
        height: "auto",
        minHeight: "100%",
      }}
    >
      {docketsStatus === "loading" ? (
        <LoadingSpinner type="gif" />
      ) : checkIfNull(query) &&
        checkIfNull(paginatedDockets) &&
        !(docketsStatus === "loading") ? (
        <Box
          sx={{
            m: 1,
            p: 1,
            minHeight: 56,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" sx={{ m: 1 }}>
            No Breakouts organized yet.
          </Typography>
          {canCreateDocket && (
            <>
              <Typography variant="h6" sx={{ mb: 2 }}>
                You can get started by creating one.
              </Typography>
              <Fab
                color="primary"
                variant="extended"
                onClick={handleDocketCreate}
                sx={{ ml: 2 }}
              >
                <SvgIcon sx={{ mr: 2 }}>
                  <PlusCircleIcon />
                </SvgIcon>
                New
              </Fab>
            </>
          )}
        </Box>
      ) : (
        <Container maxWidth={"lg"}>
          <Box
            sx={{
              mt: 1,
              p: 0,
              minHeight: 56,
              display: "flex",
              alignItems: "center",
              flexDirection: isMobile ? "column" : "row",
            }}
          >
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item xs={10} sm={6}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                    endAdornment: !checkIfNull(query) && (
                      <InputAdornment position="end">
                        <IconButton size="small" onClick={() => setQuery("")}>
                          <Close fontSize="inherit" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleQueryChange}
                  placeholder="Search breakouts"
                  value={query}
                  sx={{ mr: 2 }}
                />
              </Grid>

              {/* <Grid
                  item
                  xs={9}
                  md={9}
                  sm={8}
                  lg={10}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <TextField
                    fullWidth
                    label="Sort By"
                    name="sort"
                    onChange={handleSortChange}
                    select
                    SelectProps={{ native: true }}
                    value={sort}
                    sx={{ maxWidth: 200 }}
                  >
                    {sortOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid> */}
              <Grid item xs={2}>
                {canCreateDocket && (
                  <Fab
                    color="secondary"
                    variant={!isMobile ? "extended" : "circular"}
                    onClick={handleDocketCreate}
                    sx={{ ml: 2, minWidth: 50, height: 50 }}
                  >
                    <SvgIcon sx={{ mr: !isMobile ? 2 : 0 }}>
                      <PlusCircleIcon />
                    </SvgIcon>
                    {!isMobile && "New"}
                  </Fab>
                )}
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ flexGrow: 1, height: "100%" }}>
            {docketsStatus === "loading" ? (
              <LoadingSpinner type="gif" />
            ) : checkIfNull(sortedDockets) ? (
              <Typography variant="body1" sx={{ mt: 2, ml: 3 }}>
                No matching searches.
              </Typography>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  mt: 1,
                }}
              >
                {sortedDockets.length > limit && (
                  <Pagination
                    count={Math.ceil(sortedDockets.length / limit)}
                    page={page}
                    onChange={handlePageChange}
                    showFirstButton
                    showLastButton
                    size="small"
                    sx={{ my: 2, alignSelf: "flex-end" }}
                  />
                )}
                {/* <Masonry
                  columns={sortedDockets.length > 2 ? 3 : sortedDockets.length}
                  spacing={{ xs: 1, sm: 2, md: 3 }}
                  defaultHeight={300}
                  defaultColumns={3}
                  defaultSpacing={1}
                  sx={{
                    display: 'flex',
                    flexDirection: isMobile ? 'row' : 'column',
                    width: '100%',
                  }}
                > */}
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  {paginatedDockets.map((docket, index) => {
                    const createdByUser = usersInOrganization?.find(
                      (user) => user._id === docket.createdBy
                    );

                    let userIsDocketVisitor = false;
                    docket.visitors?.map((v) => {
                      if (v._id === userInfo._id) userIsDocketVisitor = true;
                    });

                    const { accessFlag: canViewDocket } = checkAccessRules({
                      entity: "DOCKET",
                      action: "VIEW-DOCKET",
                      featureName: "DOCKET",
                      ...getRolePrivileges({
                        userId: userInfo._id,
                        baseObject: docket,
                        members: [
                          ...(docket.members ?? []),
                          ...(docket.visitors
                            ? docket.visitors.map((v) => v._id)
                            : []),
                        ],
                      }),
                    });
                    const { accessFlag: canEditDocket } = checkAccessRules({
                      entity: "DOCKET",
                      action: "UPDATE-DOCKET",
                      featureName: "DOCKET",
                      ...getRolePrivileges({
                        userId: userInfo._id,
                        baseObject: docket,
                        members: docket.members ?? [],
                      }),
                    });
                    const { accessFlag: canDeleteDocket } = checkAccessRules({
                      entity: "SUITE",
                      action: "DELETE-DOCKET",
                      featureName: "DOCKET",
                      ...getRolePrivileges({
                        userId: userInfo._id,
                        baseObject: selectedSuite,
                        members: usersInSuite.map((user) => user._id),
                      }),
                    });
                    if (canViewDocket) {
                      return (
                        <DocketSummaryCard
                          key={index}
                          docket={docket}
                          canEditDocket={canEditDocket}
                          canDeleteDocket={canDeleteDocket}
                          handleDocketEdit={handleDocketEdit}
                        />
                      );
                    } else return null;
                  })}
                </Box>
                {/* </Masonry> */}
              </Box>
            )}
          </Box>
        </Container>
      )}

      <DocketEditForm
        open={openEditDialog || newDocketFlag}
        handleClose={handleEditDocketClose}
        docket={selectedDocket}
        newDocket={newDocketFlag}
      />
    </Paper>
  );
};

ExecSuitesBreakoutsDashboardView.propTypes = {};

ExecSuitesBreakoutsDashboardView.defaultProps = {};

export default React.memo(ExecSuitesBreakoutsDashboardView);
