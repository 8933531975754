import React from 'react'
import { SvgIcon } from '@mui/material';

export default function ScreenMirroringIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M8.33337 54.0835V62.5002C8.33337 83.3335 16.6667 91.6668 37.5 91.6668H62.5C83.3334 91.6668 91.6667 83.3335 91.6667 62.5002V37.5002C91.6667 16.6668 83.3334 8.3335 62.5 8.3335H37.5C16.6667 8.3335 8.33337 16.6668 8.33337 37.5002"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M52.5 77.0835H68.3333C74.5833 77.0835 77.0833 74.5835 77.0833 68.3335V60.8335C77.0833 54.5835 74.5833 52.0835 68.3333 52.0835H52.5C46.25 52.0835 43.75 54.5835 43.75 60.8335V68.3335C43.75 74.5835 46.25 77.0835 52.5 77.0835Z"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
