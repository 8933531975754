import React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Typography,
  IconButton,
  SvgIcon,
} from "@mui/material";

export default function DocketActionCard({ action = {} }) {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",
        m: 1,
        border: (theme) =>
          `${theme.spacing(0.1)} solid ${theme.palette.gray.lighter}`,
        "&:hover": {
          border: (theme) =>
            `${theme.spacing(0.3)} solid ${theme.palette.primary.main}`,
        },
      }}
    >
      <CardActionArea onClick={action.onClickAction} sx={{ height: "100%" }}>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h5">{action.label}</Typography>

          <SvgIcon fontSize="inherit" sx={{ width: 40, height: 40, m: 4 }}>
            {action.icon}
          </SvgIcon>

          <Typography
            sx={{
              maxWidth: 160,
              textAlign: "center",
            }}
          >
            {action.description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
