import React from "react";
import { viewTasksFilterContextAtom } from "blocks/modules/Tasks/tasks-atoms";
import { Box, Button } from "@mui/material";
import { Clear } from "@mui/icons-material";
import { useAtom } from "jotai";
import { DocketSelectionDropdown } from "blocks/modules/Dockets";
import { checkIfNull, useHandleAddRemoveIdFromRoute } from "@app21/core";
import ShowSelectedDocketsChips from "./ShowSelectedDocketsChips";
import StatusFilterSelectionDropdown from "./StatusFilterSelectionDropdown";
import DateRangeSelection from "./DateRangeSelection";
import { useRouter } from "next/router";

export default function SelectedActionsDisplay({
  viewFilter,
  setViewFilter,
  selectedDateRangeFilter,
  setSelectedDateRangeFilter,
  selectedStatusFilter,
  setSelectedStatusFilter,
  selectedDocketsFilter,
  setSelectedDocketsFilter,
  baseDockets,
  baseStatusOptions,
}) {
  const { addIdToRoute, removeIdFromRoute } = useHandleAddRemoveIdFromRoute();

  const router = useRouter();

  const handleSelectedDocket = (docket) => {
    setSelectedDocketsFilter([docket]);
    let newRoutePath = addIdToRoute({
      paramKey: "docketId",
      paramValue: docket.value ?? docket._id,
    });

    router.push(newRoutePath, newRoutePath, { shallow: true });
  };
  const handleRemoveSelectedDocket = () => {
    setSelectedDocketsFilter([]);
    setViewFilter((current) =>
      current.filter((option) => option !== "docketsFilter")
    );
    router.push(removeIdFromRoute({ paramKey: "docketId" }), { shallow: true });
  };

  const handleStatusSelection = (option) => {
    setSelectedStatusFilter(option);
  };

  const handleDateRangeSelect = ({ startDate, endDate }) => {
    let adjStartDate = startDate;
    let adjEndDate = endDate;
    setSelectedDateRangeFilter({
      startDate: adjStartDate,
      endDate: adjEndDate,
    });
  };

  const displaySelectedFilter = (option) => {
    switch (option) {
      case "docketsFilter":
        if (checkIfNull(selectedDocketsFilter)) {
          return (
            <DocketSelectionDropdown
              showLabel={false}
              dockets={baseDockets}
              label="Select Breakout"
              id="newfield-random"
              onDocketSelect={(docket) => handleSelectedDocket(docket)}
              sx={{ maxWidth: 150 }}
            />
          );
        } else
          return (
            <ShowSelectedDocketsChips
              onClear={handleRemoveSelectedDocket}
              dockets={selectedDocketsFilter}
            />
          );

      case "statusFilter": {
        return (
          <StatusFilterSelectionDropdown
            selectedStatusFilterValue={selectedStatusFilter}
            statusOptions={baseStatusOptions}
            onStatusSelect={(option) => handleStatusSelection(option)}
            sx={{ maxWidth: 150 }}
          />
        );
      }
      case "dateFilter": {
        return (
          <DateRangeSelection
            selectedDateRange={selectedDateRangeFilter}
            handleDateRangeSelect={handleDateRangeSelect}
          />
        );
      }
      default:
        return null;
    }
  };
  return (
    <Box sx={{ flexGrow: 1, display: "flex", flexWrap: "wrap", my: 1, gap: 1 }}>
      {viewFilter?.map((option, index) => (
        <Box key={index} sx={{}}>
          {displaySelectedFilter(option)}
        </Box>
      ))}
    </Box>
  );
}
