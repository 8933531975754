export const processFileHistoryData = (fileHistoryData) => {
  const processedData = {};

  fileHistoryData.forEach((obj) => {
    let processedObj = {};
    processedObj.userId = obj.meta.userId;
    let eventObj = {};
    eventObj.eventType = obj.eventType;
    eventObj.createdAt = obj.createdAt;
    if (obj.eventType === "app21:S3:Read") {
      eventObj.summary = `Opened`;
      eventObj.viewDuration =
        obj?.data?.viewStartTime && obj?.data?.viewEndTime
          ? obj.data.viewEndTime - obj.data.viewStartTime
          : 0;
    } else if (obj.eventType === "app21:S3:Rename") {
      eventObj.summary = `Renamed`;
    } else if (obj.eventType === "app21:S3:Create") {
      eventObj.summary = `Created`;
    } else if (obj.eventType === "app21:S3:Delete") {
      eventObj.summary = `Deleted`;
    }

    if (processedData[processedObj.userId]) {
      processedData[processedObj.userId].events.push(eventObj);
      if (eventObj.viewDuration) {
        processedData[processedObj.userId].totalViewDuration +=
          eventObj.viewDuration;
      }
    } else {
      processedData[processedObj.userId] = {};
      processedData[processedObj.userId].events = [eventObj];
      processedData[processedObj.userId].totalViewDuration =
        eventObj.viewDuration ? eventObj.viewDuration : 0;
    }
  });
  return processedData;
};
