// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from "react";
import {
  Box,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  IconButton,
} from "@mui/material";
import { isMobile } from "react-device-detect";
import {
  useContentShareState,
  useContentShareControls,
} from "amazon-chime-sdk-component-library-react";
import { MenuPopover } from "blocks/atoms";
import { handleToggleCollaboration } from "blocks/atoms/handlers";
import { WhiteboardingIcon } from "blocks/atoms/customicons";
import {
  ScreenShare as ScreenShareIcon,
  PausePresentation,
  PlayCircleOutline,
  StopScreenShare,
  PresentToAll,
  KeyboardArrowUp,
  FileOpen,
  CancelPresentation,
} from "@mui/icons-material";

import {
  usePopupState,
  bindToggle,
  bindMenu,
} from "material-ui-popup-state/hooks";
import {
  useAppContext,
  useUserInfo,
  useMeeting,
  useHandleMeetingActions,
} from "@app21/core";
import { useVideocallContext } from "providers/TrrystVideocallProvider";

const MultiShareControl = () => {
  const { chimeViewMode, meetingId, setChimeViewMode } = useAppContext();

  const { handleMeetingActions } = useHandleMeetingActions();
  const { data: userInfo } = useUserInfo();
  const { data: meeting } = useMeeting(meetingId);
  const {
    isCollaborationActive,
    setIsCollaborationActive,
    collaborationPaused,
    setCollaborationPaused,
  } = useVideocallContext();

  const togglePauseCollaborationMode = () => {
    setCollaborationPaused(!collaborationPaused);
  };

  const { isLocalUserSharing } = useContentShareState();
  const { paused, toggleContentShare, togglePauseContentShare } =
    useContentShareControls();
  const multiSharePopupState = usePopupState({
    variant: "popover",
    popupId: "multiShareMenu",
  });

  const collaborationParams = {
    userInfo,
    meeting,
    handleMeetingActions,
    setIsCollaborationActive,
    setChimeViewMode,
    meetingId,
  };
  const menuChoiceOptions = [
    {
      label: "Share your screen",
      value: "screenshare",
      disabledFlag: isMobile,
      icon: (
        <ScreenShareIcon sx={{ color: "text.secondary" }} fontSize="medium" />
      ),
      action: () => {
        toggleContentShare();
        multiSharePopupState.close();
      },
    },
    {
      label: "Collaborate on a Whiteboard",
      value: "whiteboard",
      icon: (
        <WhiteboardingIcon sx={{ color: "text.secondary" }} fontSize="medium" />
      ),
      action: () => {
        handleToggleCollaboration({
          type: "TRRYST-WHITEBOARD",
          action: "START-COLLABORATION",
          ...collaborationParams,
        });
        multiSharePopupState.close();
      },
    },
    {
      label: "Document Walkthrough",
      value: "docshare",

      icon: <FileOpen sx={{ color: "text.secondary" }} fontSize="medium" />,
      action: () => {
        handleToggleCollaboration({
          type: "TRRYST-DOCSHARE",
          action: "START",
          ...collaborationParams,
        });
        multiSharePopupState.close();
      },
    },
  ];
  return (
    <>
      {isLocalUserSharing || isCollaborationActive ? (
        <Box
          sx={{
            border: 1,
            borderRadius: 0.5,
            borderColor: "text.secondary",
            color: "warning",
          }}
        >
          <IconButton
            size="medium"
            onClick={
              isLocalUserSharing
                ? togglePauseContentShare
                : togglePauseCollaborationMode
            }
          >
            {paused || collaborationPaused ? (
              <PlayCircleOutline fontSize="inherit" color="success" />
            ) : (
              <PausePresentation fontSize="inherit" color="warning" />
            )}
          </IconButton>
          {isCollaborationActive ? (
            <IconButton
              size="medium"
              onClick={() =>
                handleToggleCollaboration({
                  type: null,
                  action: "STOP-COLLABORATION",
                  ...collaborationParams,
                })
              }
            >
              <CancelPresentation fontSize="inherit" color="warning" />
            </IconButton>
          ) : (
            <IconButton size="medium" onClick={() => toggleContentShare()}>
              <StopScreenShare fontSize="inherit" color="warning" />
            </IconButton>
          )}
        </Box>
      ) : (
        <Box sx={{ position: "relative" }}>
          {!multiSharePopupState.isOpen && (
            <KeyboardArrowUp
              sx={{
                position: "absolute",
                top: -10,
                left: "50%",
                transform: "translate(-50%, 0)",
                color: "text.secondary",
              }}
            />
          )}
          <IconButton
            size={chimeViewMode === "compact" ? "small" : "medium"}
            {...bindToggle(multiSharePopupState)}
          >
            <PresentToAll color="textPrimary" fontSize="inherit" />
          </IconButton>
        </Box>
      )}
      <MenuPopover
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        arrow="top-center"
        PaperProps={{
          sx: { mb: 1 },
        }}
        {...bindMenu(multiSharePopupState)}
      >
        {menuChoiceOptions
          .filter((option) => option.value !== "docshare")
          .map((choice) => (
            <MenuItem
              key={choice.value}
              onClick={choice.action}
              disabled={choice.disabledFlag}
            >
              <ListItemIcon>{choice.icon}</ListItemIcon>
              <ListItemText
                primary={
                  <Typography color="textPrimary" variant="subtitle2">
                    {choice.label}
                  </Typography>
                }
              />
            </MenuItem>
          ))}
      </MenuPopover>
      {/*isLocalUserSharing ? (
        <div>
          <IconButton size="small" onClick={togglePauseContentShare}>
            {paused ? <PlayCircleOutline fontSize="inherit" /> : <PausePresentation fontSize="inherit" />}
          </IconButton>
          <IconButton size="small" onClick={() => toggleContentShare()}>
            <StopScreenShare fontSize="inherit" />
          </IconButton>
        </div>
      ) : isMobile ? (
        <Tooltip title="Screenshare not enabled for mobile devices" arrow>
          <span>
            <IconButton disabled size={chimeViewMode === 'compact' ? 'small' : 'medium'}>
              <ScreenShareIcon fontSize="inherit" />
            </IconButton>
          </span>
        </Tooltip>
      ) : (
        <IconButton size={chimeViewMode === 'compact' ? 'small' : 'medium'} onClick={() => toggleContentShare()}>
          <ScreenShareIcon fontSize="inherit" />
        </IconButton>
      )*/}
      {/*<ControlBarButton
        icon={<ScreenShare />}
        onClick={toggleContentShare}
        label={label}
        popOver={isLocalUserSharing ? dropdownOptions : null}
      />*/}
    </>
  );
};

export default MultiShareControl;
