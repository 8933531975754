import React from 'react'
import { SvgIcon } from '@mui/material';

export default function TimeIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M50.0002 33.3335V54.1668"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.5002 8.3335H62.5002"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.8326 33.3333C16.2492 39.4167 13.5409 47 13.5409 55.2083C13.5409 75.3333 29.8742 91.6667 49.9992 91.6667C70.1242 91.6667 86.4576 75.3333 86.4576 55.2083C86.4576 35.0833 70.1242 18.75 49.9992 18.75C44.7492 18.75 39.7909 19.8333 35.2909 21.8333"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
