// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Avatar, Typography, Box } from "@mui/material";
import { getS3ImageWrapper, useUserInfo } from "@app21/core";

const MeetingAccessRequestMessageShape = ({ userId, userDetails }) => {
  const { data: userInfo } = useUserInfo(userId);

  //  userDetails ?? usersInSuite?.find(user => user.id === attendeeId) ?? {};
  let userObj = userDetails ?? userInfo;
  return (
    <div style={{ display: "flex", flexGrow: 1, width: "100%" }}>
      <Avatar
        src={getS3ImageWrapper(userObj?.image, "person")}
        sx={{ mr: 1, width: 20, height: 20 }}
      />
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="body2">
          {userObj?.fullName ?? "Visitor"} wants to join the meeting.{" "}
        </Typography>
      </Box>
    </div>
  );
};

export default MeetingAccessRequestMessageShape;
