// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  Menu,
  MenuItem,
  Button,
  useMediaQuery,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Upgrade } from "@mui/icons-material";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";
import { useRouter } from "next/router";
import { useAppContext, useSuite } from "@app21/core";

export default function UpgradeButtonIfPersonalFreeSuite() {
  const router = useRouter();
  const minWidthFlag = useMediaQuery("(min-width: 1000px)");
  const { selectedSuiteId, selectedOrganizationId } = useAppContext();
  const { data: selectedSuite, status: selectedSuiteStatus } =
    useSuite(selectedSuiteId);
  const isFreeSuite =
    selectedSuite?.suiteType === "PERSONAL-SUITE-BASIC" ||
    selectedSuite?.suiteTier === "PERSONAL-SUITE-FREE";

  const handleOptionClick = () => {
    router.push({
      pathname: "/user/subscribe/requestpersonalsuiteupgrade",
      query: {
        orgId: selectedOrganizationId,
        suiteId: selectedSuiteId,
      },
    });
  };

  const buttonComponent = () => {
    if (minWidthFlag) {
      return (
        <Button
          color="secondary"
          variant="contained"
          onClick={handleOptionClick}
          startIcon={<Upgrade />}
        >
          Upgrade
        </Button>
      );
    } else
      return (
        <Tooltip title="Upgrade to Pro" arrow>
          <IconButton
            onClick={handleOptionClick}
            sx={{
              bgcolor: "secondary.main",
              "&:hover": { bgcolor: "secondary.dark" },
            }}
          >
            <Upgrade sx={{ color: "secondary.contrastText" }} />
          </IconButton>
        </Tooltip>
      );
  };
  if (isFreeSuite) {
    return null; //buttonComponent(); commenting this out for now..
  }
  return null;
}
