import React from "react";
import {
  Box,
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CardActionArea,
  alpha,
  Chip,
} from "@mui/material";
import { useTasksContext } from "providers/TasksProvider";
import {
  PrintDocketInfo,
  ProfilesChipsList,
  Label,
  UserAvatar,
} from "blocks/atoms";

import { populateUsers } from "@app21/core";
import { ArrowRightIcon, BreakoutIcon } from "blocks/atoms/trrysticons";
import {
  AssignmentTurnedIn,
  Edit,
  DeleteForever,
  Assignment,
  Attachment,
  HourglassTop,
} from "@mui/icons-material";

import clip from "text-clipper";
import { TaskCardActionMenuButton } from "blocks/modules/Tasks/foundations";
import { format } from "date-fns";
import { getDateTimeFormat } from "utils/getDateFormats";

export default function DisplaySidebarTaskCard({ taskObj, ...props }) {
  const {
    isDocket,
    selectedTaskId,
    handleTaskEdit,
    tasksPermissions,
    handleTaskDelete,
    handleTaskStatusChange,
    handleTaskView,
    usersInSuite,
  } = useTasksContext();

  const isCardActive = taskObj._id === selectedTaskId;
  const isTaskCompleted = taskObj?.task?.status === "completed";
  const menuOptions = [
    ...(tasksPermissions?.checkCanEdit(taskObj) && !isTaskCompleted
      ? [
          {
            label: "Edit",
            icon: <Edit />,
            onClick: () => handleTaskEdit(taskObj._id),
          },
        ]
      : []),
    ...(tasksPermissions?.checkCanDelete(taskObj)
      ? [
          {
            label: "Delete",
            icon: <DeleteForever />,
            onClick: () => handleTaskDelete(taskObj._id),
          },
        ]
      : []),
    ...(tasksPermissions?.checkCanEdit(taskObj) &&
    taskObj.status !== "completed"
      ? [
          {
            label: "Mark as Completed",
            icon: <AssignmentTurnedIn />,
            onClick: () => handleTaskStatusChange("completed", taskObj),
          },
        ]
      : [
          {
            label: "Re-open Task",
            icon: <Assignment />,
            onClick: () => handleTaskStatusChange("OPEN", taskObj),
          },
        ]),
  ];

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        p: 0,
        pr: 1,
        gap: 1,
        my: 1,
        backgroundColor: (theme) =>
          isCardActive
            ? alpha(theme.palette.primary.lighter, 0.1)
            : theme.palette.background.paper,
      }}
      {...props}
    >
      <CardHeader
        sx={{ p: 0 }}
        {...(taskObj?.dueBy
          ? {
              avatar: (
                <HourglassTop
                  fontSize="small"
                  sx={{ color: (theme) => theme.palette.gray.main }}
                />
              ),
            }
          : {})}
        title={
          taskObj.dueBy ? (
            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{ ml: -3 }}
            >
              {format(
                new Date(taskObj.dueBy),
                getDateTimeFormat({
                  startDate: taskObj.dueBy,
                  noTimeFlag: true,
                })
              )}
            </Typography>
          ) : (
            ""
          )
        }
        disableTypography
        action={
          <Box sx={{ display: "flex", alignItems: "center", gap: 1, p: 2 }}>
            {taskObj?.status === "wip" ? (
              taskObj?.completionPercentage ? (
                <Label color="success" sx={{ p: 2 }}>
                  {`${taskObj?.completionPercentage} %`}{" "}
                </Label>
              ) : null
            ) : (
              ""
            )}
            <TaskCardActionMenuButton taskObj={taskObj} />
          </Box>
        }
      />
      <CardActionArea onClick={() => handleTaskView(taskObj._id)}>
        <CardContent
          sx={{
            pt: 0,
            m: 0,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant={isCardActive ? "h6" : "subtitle1"}
            color={isCardActive ? "text.primary" : "text.secondary"}
          >
            {clip(taskObj?.title, 100)}
          </Typography>
          <ArrowRightIcon sx={{ color: "text.secondary" }} />
        </CardContent>

        <CardActions
          sx={{
            pt: 0,

            display: "flex",
            flexDirection: "row",
            alignItems: "center",

            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <UserAvatar
              userId={taskObj.createdBy}
              size={20}
              sx={{
                border: (theme) => `2px solid ${theme.palette.primary.main} `,
              }}
            />
            <ProfilesChipsList
              dense
              usersList={populateUsers(taskObj?.assignees, usersInSuite)}
              maxItems={4}
              size={20}
            />
          </Box>
          {taskObj?.docketId && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 1,
              }}
            >
              {Boolean(taskObj?.docketId) && (
                <Chip
                  size="small"
                  variant="outlined"
                  label={
                    <Typography variant="caption">
                      <PrintDocketInfo docketId={taskObj.docketId} />
                    </Typography>
                  }
                  sx={{ maxWidth: 100 }}
                />
              )}
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 2,
              color: "text.secondary",
            }}
          >
            {Boolean(taskObj?.attachments?.length) && (
              <Box
                sx={{
                  color: "text.secondary",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Attachment fontSize="small" sx={{ color: "text.secondary" }} />
                {taskObj?.attachments?.length}
              </Box>
            )}
          </Box>
        </CardActions>
      </CardActionArea>
    </Card>
  );
}
