import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemIcon,
} from "@mui/material";
import { checkIfNull } from "@app21/core";
import { RenderBulletedHeadlineList } from "blocks/atoms";

export default function DisplaySelectedSuiteFeatures({
  suiteName = "Board Suite",
  features = [],
}) {
  if (checkIfNull(features)) return null;
  return (
    <Box sx={{ p: 1 }}>
      <Typography variant="h5" gutterBottom sx={{ textAlign: "center" }}>
        {suiteName} features
      </Typography>
      <List disablePadding>
        <RenderBulletedHeadlineList
          listItems={features}
          ListItemProps={{
            dense: true,
            sx: { color: "text.primary", my: 0 },
          }}
          ListItemTypographyProps={{ variant: "subtitle1" }}
        />
        {/* {features.map((feature, index) => (
          <ListItem key={index}>
            <ListItemIcon>
              <Box sx={{ color: "success.main" }}>✓</Box>
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant="body2">{feature}</Typography>}
            />
          </ListItem>
        ))} */}
      </List>
    </Box>
  );
}
