import React from "react";
import {
  Typography,
  TextField,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Button,
  Box,
  IconButton,
  CardContent,
  Card,
  Stack,
  FormHelperText,
} from "@mui/material";
import { MenuPopover } from "blocks/atoms/uicomponents";
import MuiSelectFormField from "blocks/atoms/MuiSelectFormField";
import { FormProvider, Controller, useForm } from "react-hook-form";
import ObjectID from "bson-objectid";
import { stringPurify } from "utils/inputUtils";
import {
  useHandleTaskActions,
  useUsersInSuite,
  useDocketsInSuite,
  useAppContext,
  checkIfNull,
  populateUsers,
} from "@app21/core";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Label, MuiSelectDocketFormField } from "blocks/atoms";
import { useTasksContext } from "providers/TasksProvider";
import { EventNote, PersonAdd, Send } from "@mui/icons-material";
import { BreakoutIcon } from "blocks/atoms/trrysticons";

import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from "material-ui-popup-state/hooks";
import { format } from "date-fns";
import { TEXTFIELDLIMITS } from "blocks/atoms/constants/appConstants";
import ProfilesChipsList from "blocks/atoms/ProfilesChipsList";
import { selectedBreakoutsInTaskFiltersAtom } from "blocks/modules/Tasks/tasks-atoms";
import toast from "react-hot-toast";
import { useAtom } from "jotai";
import { getDateTimeFormat } from "utils/getDateFormats";

export default function TaskBasicInputForm({ handleEditTaskDetails }) {
  const { selectedSuiteId, checkAccessRules } = useAppContext();
  const { loadRoute, handleTaskActions, handleTaskCreate, isDocket } =
    useTasksContext();

  const { data: usersInSuite, apiError: usersInSuiteApiError } =
    useUsersInSuite(selectedSuiteId);
  const { data: docketsInSuite } = useDocketsInSuite(selectedSuiteId);

  const [selectedBreakoutsInTaskFilters, setSelectedBreakoutsInTaskFilters] =
    useAtom(selectedBreakoutsInTaskFiltersAtom);
  const [currentDocket, setCurrentDocket] = React.useState(
    !checkIfNull(selectedBreakoutsInTaskFilters)
      ? selectedBreakoutsInTaskFilters[0]
      : {}
  );
  const { accessFlag: canAccessMembers } = checkAccessRules({
    entity: "USERS-VISIBILITY",
    action: "VIEW-ALL-SUITE-USERS",
    featureName: "SUITE-USER-DETAILS",
    isInvitee: true,
  });

  const [newTaskDetails, setNewTaskDetails] = React.useState({
    title: "",
    dueBy: "",
    assignees: [],
  });
  const [taskDetails, setTaskDetails] = React.useState("");

  React.useEffect(() => {
    if (usersInSuiteApiError) {
      toast.error(usersInSuiteApiError.message, {
        appearance: "error",
        autoDismiss: false,
      });
    }
  }, [usersInSuiteApiError]);

  const dueDatePopupState = usePopupState({
    variant: "popover",
    popupId: "dueDatePopover",
  });
  const assigneeAddPopupState = usePopupState({
    variant: "popover",
    popupId: "assigneeAddPopover",
  });
  const selectBreakoutPopupState = usePopupState({
    variant: "popover",
    popupId: "selectBreakoutInTaskPopover",
  });

  const [calendarOpen, setCalendarOpen] = React.useState(false);

  const timeDurationList = [
    { label: "No Date Specified", value: "" },
    { label: "1 week", value: 168 },
    { label: "15 Days", value: 360 },
    { label: "1 month", value: 720 },
    { label: "3 months", value: 2160 },
    { label: "Custom", value: "custom" },
  ];
  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      title: "",
      assignees: [],
      dueBy: "",
      docketId: (currentDocket?.value || currentDocket?._id) ?? null,
    },
  });
  const {
    handleSubmit,
    control,
    formState,
    reset,
    getValues,
    setValue,
    watch,
  } = methods;
  const { isSubmitting, isValid, errors } = formState;
  const selectedDocket = docketsInSuite?.find(
    (docket) => docket._id === watch("docketId")
  );
  const usersInDocket = populateUsers(selectedDocket?.members, usersInSuite);
  const listOfUsers = watch("docketId")
    ? usersInDocket
    : canAccessMembers
    ? usersInSuite
    : [];
  canAccessMembers ? usersInSuite : [];

  const handleTaskDetails = (event) => {
    setTaskDetails(event.target.value);
    setNewTaskDetails({
      ...newTaskDetails,
      title: event.target.value,
    });
  };

  const handleDueBySelect = (value, field) => {
    //setEventCheck(false);

    if (value === "custom") {
      setValue("dueBy", new Date());
      setCalendarOpen(true);
    } else if (value != "") {
      setValue("dueBy", new Date(new Date().getTime() + value * 3600 * 1000));
      dueDatePopupState.close();
    } else {
      setValue("dueBy", "");
      dueDatePopupState.close();
    }
  };

  const onSubmit = async (formData) => {
    const localTaskId = ObjectID().toHexString();

    const modFormData = { ...formData };
    modFormData.title = stringPurify(formData.title);
    modFormData.assignees = formData.assignees?.map((assignee) => assignee._id);
    if (currentDocket?.value || currentDocket?._id) {
      modFormData.docketId = currentDocket?.value || currentDocket?._id;
    }
    await handleTaskCreate(localTaskId, modFormData);

    reset();
    loadRoute("VIEW-TASK", { taskId: localTaskId });
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          flexGrow: 1,
        }}
      >
        <Card
          sx={{
            width: "100%",
            m: 0,
            p: 0,
            boxShadow: "none",
          }}
        >
          <CardContent
            sx={{
              m: 0,
              p: 0,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Controller
              name="title"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "A title for the task is required",
                },
                maxLength: {
                  value: TEXTFIELDLIMITS.taskNameLength,
                  message: `Sorry. Too long. Limit to ${TEXTFIELDLIMITS.taskNameLength} charactersssss`,
                },
              }}
              render={({ field }) => {
                return (
                  <FormControl
                    fullWidth
                    size="small"
                    margin="dense"
                    sx={{ m: 1 }}
                  >
                    <InputLabel htmlFor="newTaskTextfield">Add Task</InputLabel>
                    <OutlinedInput
                      id="newTaskTextfield"
                      label="Amount"
                      placeholder="Add a new Task"
                      value={field.value}
                      error={!!errors.title}
                      onChange={(event) => {
                        handleTaskDetails(event);
                        field.onChange(event.target.value);
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <Stack direction="row" spacing={1}>
                            <IconButton
                              size="small"
                              {...bindTrigger(selectBreakoutPopupState)}
                            >
                              <BreakoutIcon fontSize="inherit" sx={{ mx: 1 }} />
                            </IconButton>

                            <IconButton
                              size="small"
                              {...bindTrigger(dueDatePopupState)}
                            >
                              <EventNote fontSize="inherit" sx={{ mx: 1 }} />
                            </IconButton>
                            <IconButton
                              size="small"
                              {...bindTrigger(assigneeAddPopupState)}
                            >
                              <PersonAdd fontSize="inherit" sx={{ mx: 1 }} />
                            </IconButton>
                            {/* <Button
                              color="primary"
                              variant="outlined"
                              size="small"
                              sx={{ m: 2 }}
                              onClick={() => {
                                handleEditTaskDetails(null, getValues(), null);
                                reset();
                              }}
                            >
                              Details
                            </Button> */}
                          </Stack>
                        </InputAdornment>
                      }
                    />
                    {errors.title?.message ? (
                      <FormHelperText id="helperTextTaskInput">
                        {errors.title?.message}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                );
              }}
            />
            <IconButton
              type="submit"
              color="success"
              disabled={!checkIfNull(errors) || !isValid || isSubmitting}
            >
              <Send />
            </IconButton>
          </CardContent>
          <Box
            sx={{
              display: "flex",
              px: 1,
              pb: 1,
              m: 0,
            }}
          >
            {!checkIfNull(watch("docketId")) && (
              <Label variant="ghost" color="success" sx={{ mr: 1, p: 1 }}>
                <Typography variant="body1" sx={{ mx: 1 }}>
                  {`Breakout Group: ${
                    currentDocket?.label ?? currentDocket?.title
                  }`}
                </Typography>
              </Label>
            )}
            {!checkIfNull(watch("dueBy")) && (
              <Label variant="ghost" color="primary" sx={{ mr: 1, p: 1 }}>
                <Typography variant="body1" sx={{ mx: 1 }}>
                  {`Task Due: ${format(
                    new Date(watch("dueBy")),
                    getDateTimeFormat({
                      startDate: watch("dueBy"),
                      noTimeFlag: true,
                    })
                  )}`}
                </Typography>
              </Label>
            )}
            {!checkIfNull(watch("assignees")) && (
              <Label
                variant="ghost"
                color="secondary"
                sx={{ mr: 1, px: 1, py: 1 }}
              >
                <Typography variant="body1" sx={{ mr: 1 }}>
                  Task Assignee(s):
                </Typography>
                <ProfilesChipsList
                  showAs="chip"
                  dense
                  usersList={populateUsers(
                    (watch("assignees") ?? []).map((member) => member._id),
                    usersInSuite
                  )}
                />
              </Label>
            )}
          </Box>
        </Card>
        <MenuPopover
          {...bindPopover(selectBreakoutPopupState)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          arrow="top-center"
          sx={{ p: 1 }}
        >
          <Controller
            name={"docketId"}
            control={control}
            rules={{
              required: { value: false, message: "Optional" },
            }}
            render={({ field: { ref, ...rest } }) => (
              <MuiSelectDocketFormField
                refs={ref}
                listOfDockets={docketsInSuite}
                label="*Link to a breakout group"
                placeholder="Link to a Breakout"
                inputValue={""}
                {...rest}
                onChange={(data) => {
                  setValue("docketId", data, { shouldDirty: true });
                  setCurrentDocket(
                    docketsInSuite?.find((docket) => docket._id === data)
                  );
                }}
                variant="outlined"
              />
            )}
          />
        </MenuPopover>
        <MenuPopover
          {...bindPopover(dueDatePopupState)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          arrow="top-center"
          sx={{ p: 1 }}
        >
          {calendarOpen ? (
            <LocalizationProvider dateAdapter={AdapterDateFns} disabled={true}>
              <Controller
                name="dueBy"
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <DatePicker
                    label="Due by Date"
                    autoOk
                    KeyboardButtonProps={{
                      "aria-label": "change date / time",
                    }}
                    sx={{
                      mt: 2,
                      marginRight: (theme) => theme.spacing(2),
                      "&:last-child": {
                        marginRight: 0,
                      },
                      width: "100%",
                      marginBottom: (theme) => theme.spacing(2),
                    }}
                    inputVariant={"outlined"}
                    format={"dd/MM/yyyy"}
                    // componentsProps={{
                    //   textField: {
                    //     margin: "normal",
                    //     fullWidth: true,
                    //     error: !!errors?.dueBy,
                    //     helperText: errors?.dueBy?.message,
                    //     variant: "outlined",
                    //     InputProps: {
                    //       sx: {
                    //         backgroundColor: (theme) =>
                    //           theme.palette.background.default,
                    //       },
                    //     },
                    //   },
                    // }}
                    {...rest}
                  />
                )}
              />
            </LocalizationProvider>
          ) : (
            <Controller
              name="dueBy"
              control={control}
              render={({ field }) => {
                return (
                  <TextField
                    fullWidth
                    select
                    SelectProps={{ native: true }}
                    label="Due Date for Task"
                    margin="normal"
                    name="DueTime"
                    size="large"
                    value={field.value}
                    variant="outlined"
                    onChange={(event, newValue) => {
                      handleDueBySelect(event.target.value, field);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    <option value={"unSelect"}>{"Select Due By"}</option>
                    {timeDurationList
                      .filter((option) => option.value !== watch("dueBy"))
                      .map((option, index) => (
                        <option value={option.value} key={index}>
                          {option.label}
                        </option>
                      ))}
                  </TextField>
                );
              }}
            />
          )}
        </MenuPopover>
        <MenuPopover
          {...bindPopover(assigneeAddPopupState)}
          id="dragModal"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          arrow="top-right"
          sx={{ p: 1, minWidth: 350 }}
        >
          <Controller
            control={control}
            name={"assignees"}
            render={({ field: { ref, ...rest } }) => (
              <MuiSelectFormField
                listOfUsers={listOfUsers}
                label="Assignees"
                refs={ref}
                placeholder="Add to Task"
                limitTags={10}
                allowGuests={false}
                inputValue={""}
                variant="outlined"
                {...rest}
                onChange={(data) => {
                  setValue("assignees", data, { shouldDirty: true });
                }}
                errors={errors?.assignees}
              />
            )}
            rules={{
              required: {
                value: false,
                message: "Atleast one person needs to be added to the Task .",
              },
            }}
          />
        </MenuPopover>
      </form>
    </FormProvider>
  );
}
