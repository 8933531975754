import React from "react";
import {
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Avatar,
  AvatarGroup,
  ListItemSecondaryAction,
} from "@mui/material";
import { UserAvatar, PrintUserInfo } from "blocks/atoms";
import {
  useHandleRouterPush,
  getS3ImageWrapper,
  useAppContext,
  useOrgsAndSuitesForUser,
} from "@app21/core";
import {
  BreakoutIcon,
  CalendarIcon,
  TaskIcon,
  FilesIcon,
  SurveysIcon,
} from "blocks/atoms/trrysticons";
import { getOrgIdFromSuiteId } from "utils";
import { getNameInitials } from "utils/getNameInitials";
import toast from "react-hot-toast";
import clip from "text-clipper";
import { useOrgsAndSuitesInfoContext } from "providers/OrgsAndSuitesInfoProvider";

const UnreadChatNotificationCard = ({ unreadChatInfo = {} }) => {
  const { loadRoute } = useHandleRouterPush();
  const { fetchUserInfo } = useOrgsAndSuitesInfoContext();

  const { userInfo } = useAppContext();
  const {
    data: orgsAndSuites,
    status: orgsAndSuitesStatus,
    apiError: orgsAndSuitesApiError,
  } = useOrgsAndSuitesForUser(userInfo?._id);
  React.useEffect(() => {
    if (orgsAndSuitesApiError && orgsAndSuitesStatus !== "loading") {
      toast.error(orgsAndSuitesApiError.message);
    }
  }, [orgsAndSuitesApiError]);

  const nonSelfUsersInChat = unreadChatInfo.chatDetails?.members?.filter(
    (member) => member.id !== userInfo._id
  );

  let baseObject;
  let channel = unreadChatInfo?.chatDetails ?? {};

  switch (channel.type) {
    case "DOCKETS":
      baseObject = channel.docket ?? {};
      break;
    case "MEETINGS":
      baseObject = channel.meeting ?? {};
      break;
    case "TASKS":
      baseObject = channel.task ?? {};
      break;
    case "SUITES":
      baseObject = channel.suite ?? {};
      break;
    case "FILES":
      baseObject = channel.file ?? {};
      break;
    case "SURVEYS":
      baseObject = channel.survey ?? {};
      break;
    default:
      break;
  }

  const getChatInfo = React.useCallback(
    (channel) => {
      switch (channel?.type) {
        case "SUITES":
          return {
            image: (
              <Avatar
                src={getS3ImageWrapper(baseObject?.image, "suite")}
                alt={getNameInitials(baseObject?.fullName)}
                sx={{ height: 25, width: 25 }}
                variant="rounded"
              />
            ),
            name: baseObject?.fullName,
          };

        case "DOCKETS":
          return {
            image: (
              <Avatar>
                <BreakoutIcon />
              </Avatar>
            ),
            name: baseObject?.title,
          };
        case "MEETINGS":
          return {
            image: <CalendarIcon />,
            name: baseObject?.meetingJSON?.summary ?? "Meeting",
          };
        case "TASKS":
          return {
            image: (
              <Avatar sx={{ height: 25, width: 25 }}>
                <TaskIcon fontSize="inherit" />
              </Avatar>
            ),
            name: baseObject?.title,
          };
        case "FILES":
          return { image: <FilesIcon />, name: baseObject?.title };
        case "SURVEYS":
          return { image: <SurveysIcon />, name: baseObject?.title };

        case "DIRECT":
          return {
            image: (
              <AvatarGroup max={2}>
                {nonSelfUsersInChat.map((user) => (
                  <UserAvatar key={user.id} userId={user.id} size={25} />
                ))}
              </AvatarGroup>
            ),
            name: `Chat with ${nonSelfUsersInChat.map((user) => {
              const userObj = fetchUserInfo(user.id);
              return userObj?.fullName ?? userObj?.emailId ?? "User";
            })}`,
          };

        default:
          return {
            image: <Avatar alt={channel?.name} />,
            name: channel?.name,
          };
      }
    },
    [nonSelfUsersInChat, baseObject]
  );

  return (
    <>
      <ListItem
        data-testid="chat-list"
        disableGutters
        dense
        sx={{ position: "relative", pr: 0 }}
      >
        <ListItemButton
          onClick={() => {
            let orgId =
              unreadChatInfo?.chatDetails?.metadata?.organizationId ??
              getOrgIdFromSuiteId(
                unreadChatInfo?.chatDetails?.metadata?.suiteId
                  ? unreadChatInfo?.chatDetails?.metadata?.suiteId[0]
                  : "",
                orgsAndSuites
              );

            loadRoute("GROUP-CHAT", {
              orgId: orgId,
              suiteId: unreadChatInfo?.chatDetails?.metadata?.suiteId[0],
              chatId: unreadChatInfo?.chatId,
              chatType: "suite",
            });
          }}
        >
          <ListItemAvatar sx={{ p: 0, mt: 1, mr: 1, ml: -1 }}>
            {getChatInfo(unreadChatInfo?.chatDetails).image}
          </ListItemAvatar>
          <ListItemText
            primary={clip(
              getChatInfo(unreadChatInfo?.chatDetails).name ?? "",
              40
            )}
          />

          <ListItemSecondaryAction>
            <Avatar
              sx={{
                bgcolor: "primary.main",
                height: 25,
                width: 25,
                fontSize: 12,
                fontWeight: 600,
              }}
            >
              {unreadChatInfo?.unreadMessageCount > 99
                ? "99+"
                : unreadChatInfo?.unreadMessageCount}
            </Avatar>
          </ListItemSecondaryAction>
        </ListItemButton>
      </ListItem>
    </>
  );
};

export default React.memo(UnreadChatNotificationCard);
