// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Paper } from "@mui/material";
import {
  AddCircleOutline,
  RemoveCircleOutline,
  Home,
} from "@mui/icons-material";
import { checkIfNull, useHandleRouterPush, useAppContext } from "@app21/core";
import { useTheme } from "@mui/material/styles";
import TreeView from "@mui/lab/TreeView";
import { getExtensionFromFilename } from "utils";
import { getFileIcon, DocketLabel } from "blocks/atoms";
import FolderTreeItemView from "./FolderTreeItemView";
import { useClickPreventionOnDoubleClick } from "hooks";
import path from "path";

const ShowFolderTreeView = ({
  filesList = { objects: [], folders: [] },
  allKeys,
  docketsInSuite,
  decodedS3Prefix,
}) => {
  const theme = useTheme();
  const { loadRoute } = useHandleRouterPush();
  const { viewFile } = useAppContext();
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [expandedItems, setExpandedItems] = React.useState([]);

  const handleNodeToggle = (event, nodeIds) => {
    //event.preventDefault();
    //event.stopPropagation();

    setExpandedItems(nodeIds);
  };

  const handleNodeSelect = (event, nodeIds) => {
    setSelectedItems(nodeIds);
  };

  const handleTreeItemSingleClick = (event, key, node, context) => {
    let labelClicked = event.target.closest(".MuiTreeItem-label");
    let docketSearchId = docketsInSuite?.find((d) => d.title === key)?._id;

    let updatedKey = docketSearchId ?? key;

    if (Boolean(labelClicked)) {
      const s3Entry =
        context === "folder"
          ? allKeys?.find(
              (k) =>
                k.Key.endsWith(`${updatedKey}/`) ||
                k?.origKey?.endsWith(`${updatedKey}/`)
            )
          : context === "home"
          ? `${node.parent}/${node.name}`
          : allKeys?.find(
              (k) => k.Key === updatedKey || k?.origKey === updatedKey
            );
      const fileName = updatedKey?.replace(/^.*(\\|\/|\:)/, "");
      const newKey = s3Entry?.Key?.replace(fileName, "");

      s3Entry &&
        loadRoute("VIEW-DATAROOM", {
          s3Key: context === "folder" ? s3Entry?.Key : newKey,
        });
    }
  };

  const handleTreeItemDoubleClick = (event, key, node, context) => {
    if (key === "Breakouts") {
      const firstKeySplit = allKeys[0].Key.split("/");
      const constructedKey = `${firstKeySplit[0]}/${firstKeySplit[1]}/dockets/`;
      loadRoute("VIEW-DATAROOM", { s3Key: constructedKey });
    }

    let docketId = docketsInSuite?.find((d) => d.title === key)?._id ?? null;

    if (docketId) key = docketId;

    const s3Entry = allKeys.find(
      (k) => k.Key.endsWith(`${key}/`) || k?.origKey?.endsWith(`${key}/`)
    );

    // if there is no key that ends with docketId, there is no other way but to manually send it out.
    if (docketId && !s3Entry) {
      const firstKeySplit = allKeys[0].Key.split("/");
      const constructedKey = `${firstKeySplit[0]}/${firstKeySplit[1]}/dockets/${docketId}/`;
      loadRoute("VIEW-DATAROOM", { s3Key: constructedKey });
    } else {
      s3Entry && loadRoute("VIEW-DATAROOM", { s3Key: s3Entry?.Key });
    }
  };

  const [handleSingleClick, handleDoubleClick] =
    useClickPreventionOnDoubleClick(
      handleTreeItemSingleClick,
      handleTreeItemDoubleClick
    );
  let idElements = decodedS3Prefix?.split("/");
  let folderId =
    idElements?.length > 1 ? idElements[idElements.length - 2] : "";

  function findNode(id, array) {
    if (array && Array.isArray(array)) {
      for (const node of array) {
        if (node?.name === id) return node;
        if (node?.folders) {
          const child = findNode(id, node.folders);
          if (child) return child;
        }
      }
    } else return {};
  }

  const showFilesFlag = Boolean(viewFile);

  const renderTree = (nodes, mainIndex) => {
    return (
      <>
        {!checkIfNull(nodes.folders) &&
          Array.isArray(nodes.folders) &&
          nodes.folders.map((node, subIndex) => {
            const isActiveFolder =
              decodedS3Prefix === node.parentPrefix + node.name;

            return (
              <FolderTreeItemView
                key={node.parentPrefix + node.name}
                nodeId={
                  `${node.parentPrefix + node.name}` ??
                  `Folder ${node.parentPrefix + subIndex}`
                }
                labelText={
                  node.parent === "Breakouts" ? (
                    <DocketLabel id={node.origKey} title={node.name} />
                  ) : (
                    node.name
                  )
                }
                labelInfo={""}
                color={theme.palette.secondary.contrastText}
                bgcolor={theme.palette.secondary.main}
                onClick={(event) =>
                  handleSingleClick(event, node.name, node, "folder")
                }
                onDoubleClick={(event) =>
                  handleDoubleClick(event, node.name, node, "folder")
                }
                isActiveFolder={isActiveFolder}
              >
                {renderTree(node, mainIndex * 21)}
              </FolderTreeItemView>
            );
          })}

        {!checkIfNull(nodes.objects) &&
          Array.isArray(nodes.objects) &&
          showFilesFlag &&
          nodes.objects.map((node, subIndex) => {
            return (
              <FolderTreeItemView
                key={node.parentPrefix + node.Key}
                nodeId={
                  `${node.parentPrefix + node.name}` ??
                  `Folder ${node.parentPrefix + subIndex}`
                }
                //  dndState={dndState}
                labelText={(node.Key ?? "File").replace(/^.*(\\|\/|\:)/, "")}
                labelIcon={getFileIcon({
                  fileExt: getExtensionFromFilename(node.Key ?? ""),
                  size: "lg",
                })}
                labelInfo={""}
                color={theme.palette.secondary.contrastText}
                bgcolor={theme.palette.secondary.main}
                onClick={(event) =>
                  handleSingleClick(event, node.Key, node, "file")
                }
                onDoubleClick={(event) =>
                  handleDoubleClick(event, node.Key, node, "file")
                }
              />
            );
          })}
      </>
    );
  };
  let defaultExpandedItems =
    filesList.folders?.filter((folder) =>
      decodedS3Prefix?.includes(folder?.parentPrefix + folder?.name)
    ) ?? [];

  return (
    <TreeView
      aria-label="rich object"
      defaultExpanded={defaultExpandedItems}
      defaultCollapseIcon={<RemoveCircleOutline />}
      defaultExpandIcon={<AddCircleOutline />}
      defaultEndIcon={<div style={{ marginLeft: -13 }} />}
      expanded={expandedItems}
      selected={selectedItems}
      onNodeToggle={handleNodeToggle}
      onNodeSelect={handleNodeSelect}
      multiSelect
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        overflowY: "auto",

        width: "100%",
        mt: 2,
      }}
    >
      {/* <TreeItemEntry
          nodeId="HomeRoot"
          labelText="Home"
          labelIcon={<Home fontSize="small" sx={{ mr: -1 }} />}
          onClick={event => handleSingleClick(event, filesList.name, filesList, 'home')}
        > */}
      {renderTree(filesList, 1)}
      {/* </TreeItemEntry> */}
    </TreeView>
  );
};

export default ShowFolderTreeView;
