import React from 'react'
import { SvgIcon } from '@mui/material';

export default function ArrowRightIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M20.7945 79.7091C28.2945 87.1258 38.6279 91.6675 50.0029 91.6675C73.0029 91.6675 91.6695 73.0008 91.6695 50.0008C91.6695 27.0008 73.0028 8.33414 50.0028 8.33414C27.0028 8.33414 8.33618 27.0008 8.33618 50.0008C8.33618 52.8758 8.62785 55.6675 9.16951 58.4175"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M44.748 64.71L59.4147 50.0016L44.748 35.2933"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
