import React from "react";
import { Box, Typography } from "@mui/material";
import GroupMemberChatCard from "./GroupMemberChatCard";
import { useAppContext, checkIfNull } from "@app21/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useChatContext } from "providers/TrrystChatProvider";
import { useAtom } from "jotai";
import {
  SearchFilterAtom,
  CurrentChannelAtom,
} from "blocks/modules/TrrystChat/state-atoms";
import { sort } from "fast-sort";

export default function ShowChatMembersList({
  membersList,
  handleSelectDirectChannel,
}) {
  const { userInfo } = useAppContext();
  const [currentChannel] = useAtom(CurrentChannelAtom);
  const { presentUUIDs } = useChatContext();
  const [searchFilter] = useAtom(SearchFilterAtom);

  let processedMembersList = [];
  (membersList ?? []).forEach((member) =>
    processedMembersList.push({
      ...member,
      ...{
        memberActive: (presentUUIDs ?? []).some((id) => member.id === id),
      },
    })
  );

  let sortedMembersList = sort(processedMembersList).by([
    { asc: (a) => a.id === userInfo._id },
    { desc: (a) => a.memberActive },
  ]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
        transition: "display 1s ease-in-out",
      }}
    >
      <PerfectScrollbar
        options={{ suppressScrollX: true }}
        style={{
          display: "flex",
          flexDirection: "column",
          paddingRight: 0,
          flexGrow: 1,
          height: "100%",
        }}
      >
        {sortedMembersList.map((member) => {
          const directChatId =
            userInfo._id < member?.id
              ? `${userInfo._id}-${member?.id}-CHAT`
              : `${member?.id}-${userInfo._id}-CHAT`;

          return (
            <GroupMemberChatCard
              key={member.id}
              member={member}
              isActive={currentChannel === directChatId}
              searchFilter={searchFilter}
              handleClick={() =>
                handleSelectDirectChannel({ userId: member.id })
              }
              disabled={member.id === userInfo._id}
              sx={{ my: 0.5 }}
            />
          );
        })}
      </PerfectScrollbar>
    </Box>
  );
}
