import React from "react";
import { styled, Tabs, Tab } from "@mui/material";
import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import { getSuiteColor } from "utils/styleHelpers";
import theme from "styles/theme";

export const StyledRoundedTabs = styled(TabsUnstyled)``;

export const StyledRoundedTab = styled(TabUnstyled, {
  shouldForwardProp: (prop) => prop !== "textColor",
})(
  ({ theme, textColor }) => `
  color:${textColor ?? theme.palette.text.secondary} ;
  cursor: pointer;
  background-color: transparent;
  width: 100%;
  margin: 0px 1px;
  border: none;
  padding: 8px;
  border-radius: ${theme.shape.borderRadiusButton}px ;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  &:hover {
    outline: 1px solid ${theme.palette.primary.lighter};
  }

  &:focus {
   
    border-radius: ${theme.shape.borderRadiusButton}px ;
    outline: 1px solid ${theme.palette.primary.light};
    outline-offset: 1px;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.primary.contrastText};
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`
);

export const StyledRoundedTabList = styled(TabsListUnstyled)(
  ({ theme }) => `
  background-color: ${theme.palette.grey[300]};
  border-radius: ${theme.shape.borderRadiusButton * 1.3}px;
 
  padding: 4px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`
);

export const StyledVerticalTabs = styled(Tabs, {
  shouldForwardProp: (prop) => prop !== "indicatorStyle",
})(({ theme, indicatorStyle }) => {
  let indicatorColor = getSuiteColor(theme, indicatorStyle);
  return {
    "& .MuiTabs-indicator": {
      backgroundColor: indicatorColor,
      width: 4,

      zIndex: 2,
    },
    "& .MuiTabs-scroller": {
      padding: "0 12px",
    },
  };
});

export const StyledHorizontalTabs = styled(Tabs, {
  shouldForwardProp: (prop) => prop !== "indicatorStyle",
})(({ theme, indicatorStyle }) => {
  let indicatorColor = getSuiteColor(theme, indicatorStyle);
  return {};
});
export const StyledHorizontalTab = styled(Tab)(({ theme }) => {
  return {
    color: theme.palette.text.secondary,
    cursor: "pointer",
    backgroundColor: "#FFFFFF",
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.h6.fontWeight,
    margin: theme.spacing(1),
    textAlign: "left",
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(2),
    justifyContent: "flex-start",
    "&:hover": {
      color: theme.palette.primary.light,
    },
    "&:focus": {
      color: theme.palette.primary.main,
    },
    "&.Mui-selected": {
      color: theme.palette.primary.main,
    },
    "&:selected": {
      color: theme.palette.primary.main,
    },
  };
});

export const StyledVerticalTab = styled(Tab)(({ theme }) => {
  return {
    color: theme.palette.text.secondary,
    cursor: "pointer",
    backgroundColor: "#FFFFFF",
    width: "100%",
    margin: theme.spacing(1),
    textAlign: "left",
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(2),
    justifyContent: "flex-start",
    "&:hover": {
      color: theme.palette.primary.light,
    },
    "&:focus": {
      color: theme.palette.primary.main,
    },
  };
});

export const MuiStyledRoundedTabs = styled(Tabs, {
  shouldForwardProp: (prop) => prop !== "tabsHeight",
})(({ theme, tabsHeight = 30 }) => ({
  padding: 0,
  display: "flex",
  height: tabsHeight,
  minHeight: tabsHeight,
  borderRadius: 15,

  ".MuiTabs-flexContainer": {
    minHeight: tabsHeight,
  },
  ".MuiTabs-indicator": {
    display: "none",
  },
}));

export const MuiStyledRoundedTab = styled(Tab, {
  shouldForwardProp: (prop) => prop !== "tabHeight",
})(({ theme, tabHeight = 30 }) => ({
  color: theme.palette.text.secondary,
  cursor: "pointer",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  padding: tabHeight / 3,
  height: tabHeight,
  minHeight: tabHeight,

  "&:hover": {
    color: theme.palette.primary.light,
  },
  "&:focus": {
    color: theme.palette.primary.main,
  },
  "&.Mui-selected": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 15,
  },
  "&: .MuiButtonBase-root": {},
}));
