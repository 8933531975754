// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { useEffect, useRef } from "react";
import {
  PreviewVideo,
  useLocalVideo,
} from "amazon-chime-sdk-component-library-react";
import NoVideoTile from "./NoVideoTile";
import { useAppContext, getS3ImageWrapper } from "@app21/core";
import { Box, styled } from "@mui/material";

export const StyledPreviewTile = styled("div")(({ theme }) => {
  return {
    flexGrow: 1,
    height: "100%",
    width: "100%",
    position: "relative",
    borderRadius: 8,
    background: "unset",
    background: theme.palette.primary.lighter,
    overflow: "hidden",
    boxSizing: "content-box",
    display: "flex",
    flexDirection: "column",
    "& video": {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: "100%",
      height: "100%",
      borderRadius: 8,
      objectFit: "cover",
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
    },
  };
});

const CustomPreviewTile = () => {
  const { isVideoEnabled } = useLocalVideo();
  const { userInfo } = useAppContext();

  if (isVideoEnabled) {
    return (
      <StyledPreviewTile>
        <PreviewVideo />
      </StyledPreviewTile>
    );
  }
  return (
    <NoVideoTile
      name={userInfo?.fullName}
      avatarUrl={getS3ImageWrapper(userInfo?.image, "person")}
    />
  );
};

export default CustomPreviewTile;
