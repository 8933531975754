// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
} from "@mui/material";
import { stringPurify } from "utils/inputUtils";
import { useForm, Controller } from "react-hook-form";
import { LoadingButton } from "@mui/lab";

function DialogFormSingleTextField({
  name,
  defaultValue = "",
  open,
  onClose,
  title = "",
  label = "",
  textFieldVariant = "standard",
  onSubmit,
  submitButtonTitle = "Submit",
  textfieldRules = {},
  ...rest
}) {
  const { handleSubmit, reset, setValue, control, formState, watch } = useForm({
    mode: "onChange",
    defaultValues: { inputText: defaultValue },
  });
  const { errors, isValid, isSubmitting } = formState;
  React.useEffect(() => {
    setValue("inputText", defaultValue);
  }, [defaultValue]);

  const handleSubmitClick = (data) => {
    const modData = { ...data };
    modData.inputText = stringPurify(data.inputText);
    onSubmit(modData);
    onClose();
    reset();
  };
  return (
    <form onSubmit={handleSubmit(handleSubmitClick)}>
      <Dialog open={open} onClose={onClose} {...rest}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            minHeight: 120,
          }}
        >
          <Box sx={{ height: 20 }} />
          <Controller
            name="inputText"
            control={control}
            rules={
              ({
                required: { value: true, message: "Required" },
              },
              { ...textfieldRules })
            }
            render={({ field }) => (
              <TextField
                {...field}
                autoFocus
                label={label}
                type="text"
                fullWidth
                variant={textFieldVariant}
                error={!!(errors && errors["inputText"])}
                helperText={
                  errors &&
                  Boolean(errors["inputText"]?.message) &&
                  errors["inputText"]?.message
                }
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} sx={{ mr: 2 }}>
            Close
          </Button>
          <LoadingButton
            color="primary"
            variant="contained"
            disabled={!isValid}
            loading={isSubmitting}
            onClick={handleSubmit(handleSubmitClick)}
          >
            {submitButtonTitle}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </form>
  );
}

export default DialogFormSingleTextField;
