// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Typography } from "@mui/material";
import {
  checkIfNull,
  checkTimeGap,
  useDockets,
  useAppContext,
  useMeetingsInDockets,
} from "@app21/core";
import { ShowRecords } from "blocks/modules/Dockets/foundations";
import { FlowBox } from "blocks/atoms/uistyles";
import { useIntl } from "react-intl";
import toast from "react-hot-toast";

const DocketRecordsView = (props) => {
  const { viewMode } = props;
  const { selectedDocketId } = useAppContext();
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });

  const {
    data: docketMeetings,
    status: docketMeetingsStatus,
    apiError: docketMeetingsApiError,
  } = useMeetingsInDockets(selectedDocketId);

  React.useEffect(() => {
    if (docketMeetingsApiError && docketMeetingsStatus != "loading") {
      toast.error(docketMeetingsApiError.message, {
        appearance: "error",
        autoDismiss: "true",
      });
    }
  }, [docketMeetingsApiError]);

  const isRecordingPresent =
    docketMeetings?.length > 0 ? docketMeetings[0].isRecordingPresent : false;

  if (viewMode === "summary") {
    if (!isRecordingPresent) {
      return (
        <Typography variant="body1">
          {" "}
          {`${t("DocketDashboard.ShowRecords.noRecordsMessage")}`}{" "}
        </Typography>
      );
    } else
      return (
        <Typography variant="body1">
          There are meeting recordings & transcripts available. Goto Records
          section to view
        </Typography>
      );
  } else
    return (
      <FlowBox sx={{ p: 1 }}>
        <Typography variant="h6">{`${t(
          "DocketDashboard.ShowRecords.recordsSummaryLabel"
        )}`}</Typography>
        <ShowRecords />
      </FlowBox>
    );
};
export default DocketRecordsView;
