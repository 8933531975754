// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
export const processAllTasksByStatus = (tasks = []) => {
  const pendingTasksList = [];
  const wipTasksList = [];
  const completedTasksList = [];
  tasks.forEach((task) => {
    if (task.status === "completed") {
      completedTasksList.push(task);
    } else if (task.status === "wip") {
      wipTasksList.push(task);
    } else {
      pendingTasksList.push(task);
    }
  });

  return { pendingTasksList, wipTasksList, completedTasksList };
};
