// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
//import { StatusBar } from "./StatusBar";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";

const TinyMceEditor = ({
  className,
  initialValue,
  value,
  onEditorChange,
  editorLayout,
  id,
  ...rest
}) => {
  const { handleDocumentClose } = rest ?? {};
  const { handleDocumentSave } = rest ?? {};
  const { handleDocumentDelete } = rest ?? {};
  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  const expandedViewOptions = {
    skin: "fabric",
    content_css: "document",
    resize: false,
    menu: {
      file: {
        title: "File",
        items: `preview print | ${handleDocumentSave ? "savedocument" : ""} ${
          handleDocumentDelete ? "deletedocument" : ""
        } | closedocument`,
      },
      edit: {
        title: "Edit",
        items: "undo redo | cut copy paste | selectall | searchreplace",
      },
      view: {
        title: "View",
        items:
          "code | visualaid visualchars visualblocks | spellchecker | preview fullscreen",
      },
      insert: {
        title: "Insert",
        items:
          "image link media template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor | insertdatetime",
      },
      format: {
        title: "Format",
        items:
          "bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | removeformat",
      },
      tools: {
        title: "Tools",
        items: "spellchecker spellcheckerlanguage | code wordcount",
      },
      table: {
        title: "Table",
        items: "inserttable | cell row column | tableprops deletetable",
      },
      help: { title: "Help", items: "help" },
    },
    setup: function (editor) {
      editor.ui.registry.addMenuItem("closedocument", {
        icon: "close",
        text: "Close Document",
        onAction: handleDocumentClose,
      });
      editor.ui.registry.addMenuItem("savedocument", {
        icon: "save",
        text: "Save",
        onAction: handleDocumentSave,
      });
      editor.ui.registry.addMenuItem("deletedocument", {
        icon: "remove",
        text: "Delete Document",
        onAction: handleDocumentDelete,
      });
    },
    menubar: "file edit insert view format table tools help",
    help_tabs: ["shortcuts", "keyboardnav"],
    branding: false,
    plugins:
      "print preview paste importcss searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount textpattern noneditable help charmap quickbars emoticons",
    toolbar:
      "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor | ltr rtl",
    toolbar_sticky: true,
    autosave_ask_before_unload: true,
    autosave_interval: "30s",
    autosave_prefix: "{path}{query}-{id}-",
    autosave_restore_when_empty: false,
    autosave_retention: "2m",
    image_advtab: true,
    importcss_append: true,
    height: "100% !important",
    image_caption: true,
    quickbars_selection_toolbar:
      "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
    noneditable_noneditable_class: "mceNonEditable",
    toolbar_mode: "sliding",
    contextmenu: "link image imagetools table",
    content_style:
      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
  };

  const classicViewOptions = {
    menubar: false,
    height: 100,
    placeholder: "Enter text here... ",
    inline: true,
    plugins: ["link", "lists", "autolink"],
    toolbar: [
      "undo redo | bold italic underline | fontselect fontsizeselect",
      "forecolor backcolor | alignleft aligncenter alignright alignfull | numlist bullist outdent indent",
    ],
    valid_elements: "p[style],strong,em,span[style],a[href],ul,ol,li",
    valid_styles: {
      "*": "font-size,font-family,color,text-decoration,text-align",
    },
  };
  const compactViewOptions = {
    placeholder: "Enter text here... ",
    menubar: false,
    inline: true,
    mobile: {
      inline: false,
    },
    //content_css: '/assets/libs/tinymce/styles/TinyMceStyles.css',
    plugins: ["autolink", "link", "lists", "table", "quickbars"],
    toolbar: false,
    branding: false,
    quickbars_insert_toolbar: " bullist numlist outdent indent ",
    quickbars_selection_toolbar:
      "bold italic underline | formatselect forecolor backcolor | bullist numlist outdent indent ",
    contextmenu:
      "bold italic underline |bullist numlist outdent indent | undo redo | forecolor backcolor | blockquote quicklink  ",
    //skin_url: '/assets/libs/tinymce/skins/ui/oxide', // Static files path
  };
  const viewOptions =
    editorLayout === "compact" ? compactViewOptions : expandedViewOptions;
  return (
    <div
      id={id || "newEditor"}
      style={{
        height: "100% !important",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,

        paddingBottom: (theme) => theme.spacing(1),

        "&:hover": {
          backgroundColor: (theme) => alpha(theme.palette.secondary.light, 0.2),
        },
        "&:focus": {
          backgroundColor: (theme) => alpha(theme.palette.secondary.light, 0.3),
          outline: "none !important",
        },
        "&.tox:not([dir=rtl])": {
          outline: "0 !important",
          border: "none",
        },
      }}
    >
      <Editor
        onInit={(evt, editor) => (editorRef.current = editor)}
        apiKey={process.env.TINYMCE_KEY}
        //  selector={id ? `div.${id}` : "div.newEditor"}

        initialValue={initialValue}
        init={{
          ...viewOptions,
        }}
        value={value}
        onEditorChange={onEditorChange}
        {...rest}
      />
    </div>
  );
};

TinyMceEditor.propTypes = {
  className: PropTypes.string,
  editorLayout: PropTypes.string,
};

TinyMceEditor.defaultProps = {
  editorLayout: "normal",
};

export default TinyMceEditor;
