import React from "react";
import { SvgIcon } from "@mui/material";

export default function RightArrowIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M35.0828 49.6675L60.0828 49.6675"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M51.7507 37.1675L64.2507 49.6675L51.7507 62.1675"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.3333 24.6667C11.125 31.625 8 40.2917 8 49.6667C8 72.6667 26.6667 91.3333 49.6667 91.3333C72.6667 91.3333 91.3333 72.6667 91.3333 49.6667C91.3333 26.6667 72.6667 8 49.6667 8C43.7083 8 38 9.25 32.875 11.5417"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
