import React, { Fragment } from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { Label, UserAvatar } from "blocks/atoms";
import clip from "text-clipper";
import { useIsMobileBreakpoint } from "hooks";

export default function AttentionActionListItem({
  actionLabel = "Action",
  actionOriginatorId,
  actionTitle = "NA",
  SecondaryComponent = <Fragment />,
  TertiaryComponent = null,
  actionButton = () => null,
  actionTheme = "primary",
}) {
  const isMobile = useIsMobileBreakpoint();

  let colorScheme =
    ["info", "action", "primary", "secondary", "success", "error"].indexOf(
      actionTheme
    ) > -1
      ? actionTheme
      : "primary";
  return (
    <Card
      sx={{
        m: 1,
        width: isMobile ? "100%" : 200,
        height: 150,
        border: "1px solid ",
        borderColor: (theme) => alpha(theme.palette[colorScheme].lighter, 0.1),

        position: "relative",
      }}
    >
      <Label
        color={colorScheme}
        variant="ghost"
        sx={{
          position: "absolute",
          top: 7,
          left: 4,

          p: 2,
          fontSize: (theme) => theme.typography.caption.fontSize,
          fontWeight: (theme) => theme.typography.caption.fontWeight,
        }}
      >
        {actionLabel}
      </Label>
      <UserAvatar
        userId={actionOriginatorId}
        size={25}
        sx={{ position: "absolute", top: 7, right: 7 }}
      />
      <CardContent sx={{ display: "flex", flexDirection: "column", mt: 7 }}>
        <Typography variant="subtitle2" sx={{ my: 1 }}>
          {clip(actionTitle, 40, { breakWords: true })}
        </Typography>
        <Box>{SecondaryComponent}</Box>
        {TertiaryComponent ? TertiaryComponent : null}
        <Box
          sx={{
            position: "absolute",
            bottom: 5,
          }}
        >
          {actionButton}
        </Box>
      </CardContent>
    </Card>
  );
}
