// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Typography, List } from "@mui/material";
import {
  checkIfNull,
  useResolutionsInDocket,
  useAppContext,
} from "@app21/core";

import LoadingSpinner from "blocks/atoms/LoadingSpinner";
import ListResolutionQuestions from "blocks/modules/Surveys/foundations/ListResolutionQuestions";
import { useIntl } from "react-intl";
import toast from "react-hot-toast";

const DocketResolutionsView = (props) => {
  const { viewMode } = props;
  const { selectedDocketId } = useAppContext();

  const {
    data: resolutionsInDocket,
    status: resolutionsInDocketStatus,
    apiError: resolutionsInDocketApiError,
  } = useResolutionsInDocket(selectedDocketId);
  React.useEffect(() => {
    if (resolutionsInDocketApiError && resolutionsInDocketStatus != "loading") {
      toast.error(resolutionsInDocketApiError.message, {
        appearance: "error",
        autoDismiss: "true",
      });
    }
  }, [resolutionsInDocketApiError]);
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });

  const listResolutions = (resolutions) => {
    return (
      <>
        <Typography variant="body1">{`${t(
          "DocketDashboard.ShowResolutions.resolutionsTabledMessage"
        )}`}</Typography>
        <List dense disablePadding>
          {resolutions?.map((resolution, allIndex) => (
            <ListResolutionQuestions key={allIndex} resolution={resolution} />
          ))}
        </List>
      </>
    );
  };

  if (checkIfNull(resolutionsInDocket) && viewMode === "summary") {
    if (resolutionsInDocketStatus === "loading") {
      return <LoadingSpinner size={20} />;
    } else {
      return (
        <Typography variant="body1">
          {" "}
          {`${t("DocketDashboard.ShowResolutions.noResolutionsMessage")}`}{" "}
        </Typography>
      );
    }
  } else {
    return viewMode === "summary" ? listResolutions(resolutionsInDocket) : null;
  }
};

export default DocketResolutionsView;
