import React from 'react'
import { SvgIcon } from '@mui/material';

export default function EditIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M72.3761 42.3771L77.9595 36.4604C83.8761 30.2104 86.5428 23.0854 77.3345 14.3771C68.1261 5.71039 61.1678 8.75205 55.2511 15.0021L21.0428 51.2104C19.7511 52.5854 18.5011 55.2937 18.2511 57.1687L16.7095 70.6687C16.1678 75.5437 19.6678 78.8771 24.5011 78.0437L37.9178 75.7521C39.7928 75.4187 42.4178 74.0437 43.7095 72.6271L60.1678 55.2104"
          stroke="currentColor"
          strokeWidth="6.36218"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M49.5399 21.0415C51.3316 32.5415 60.6649 41.3332 72.2483 42.4998"
          stroke="currentColor"
          strokeWidth="6.36218"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.4996 91.6675H58.333"
          stroke="currentColor"
          strokeWidth="6.36218"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M75 91.6675H87.5"
          stroke="currentColor"
          strokeWidth="6.36218"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
