// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import PropTypes from "prop-types";
import { SvgIcon, Typography, Box, Avatar, AvatarGroup } from "@mui/material";
import { NavigateNext, ArrowRightAlt } from "@mui/icons-material";

import { getS3ImageWrapper } from "@app21/core";

import {
  CheckSquare as CheckIcon,
  Eye as EyeIcon,
  File as FileIcon,
  MessageCircle as MessageIcon,
} from "react-feather";

const TaskCardFooter = ({ card, ...rest }) => {
  return (
    <Box
      {...rest}
      sx={{
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
        minHeight: 25,
      }}
    >
      {card.isWatching && (
        <SvgIcon color="action" fontSize="small">
          <EyeIcon />
        </SvgIcon>
      )}
      {card.attachments?.length > 0 && (
        <SvgIcon color="action" fontSize="small">
          <FileIcon />
        </SvgIcon>
      )}
      {card.checklists?.length > 0 && (
        <SvgIcon color="action" fontSize="small">
          <CheckIcon />
        </SvgIcon>
      )}
      {card.comments?.length > 0 && (
        <SvgIcon color="action" fontSize="small">
          <MessageIcon />
        </SvgIcon>
      )}
      <Box
        sx={{
          flexGrow: 1,
        }}
      />
      {card.assigneesInfo?.length > 0 && (
        <AvatarGroup max={5}>
          {card.assigneesInfo.map((member, index) => {
            return (
              <Avatar
                key={index}
                src={getS3ImageWrapper(member?.image, "person")}
                sx={{ height: 20, width: 20 }}
              />
            );
          })}
        </AvatarGroup>
      )}
      <Box
        sx={{
          flexGrow: 1,
          width: "100%",
        }}
      />
      <ArrowRightAlt fontSize="small" />
    </Box>
  );
};

TaskCardFooter.propTypes = {
  className: PropTypes.string,
};

export default TaskCardFooter;
