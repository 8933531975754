// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  TextField,
  Box,
  IconButton,
  Autocomplete,
  ListItem,
  ListItemIcon,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Button,
} from "@mui/material";
import {
  getS3ImageWrapper,
  useAppContext,
  useAllOrganizations,
  useOrgsAndSuitesForUser,
  checkIfNull,
  filterOrgsBasedOnAdmin,
  useHandleOrganizationActions,
  useHandleRouterPush,
  useHandleAddRemoveIdFromRoute,
  useOrganization,
} from "@app21/core";
import { useRouter } from "next/router";
import AddNewOrganization from "./AddNewOrganization";
import { Delete, AddCircle, DeleteForever } from "@mui/icons-material";

import LoadingSpinner from "blocks/atoms/LoadingSpinner";
import { VerifyDeleteDialog } from "blocks/atoms";
import toast from "react-hot-toast";
import { MoreActionsDropdownMenu } from "blocks/atoms/uicomponents";

export function createOrgSelectOptions(props) {
  let optionsArray = [];
  if (props && Array.isArray(props)) {
    props.forEach((d) =>
      optionsArray.push({
        _id: d._id,
        value: d._id,
        label: d?.fullName,
        avatar: getS3ImageWrapper(d?.image, "initials", d?.fullName),
      })
    );
  }
  return optionsArray;
}

const SelectOrgTextField = ({
  showLabel = false,
  currentContext,
  sx = {},
  ...rest
}) => {
  const {
    selectedOrganizationId,
    setSelectedSuiteId,
    checkAccessRules,
    setSelectedOrganizationId,
  } = useAppContext();

  const { userInfo = {} } = useAppContext();

  const {
    data: organizations,
    status: organizationStatus,
    apiError: allOrganizationsApiError,
  } = useAllOrganizations(userInfo?._id);

  const {
    data: orgsAndSuites,
    status: orgsAndSuitesStatus,
    apiError: orgsAndSuitesForUserApiError,
  } = useOrgsAndSuitesForUser(userInfo._id);
  const router = useRouter();
  const { removeIdFromRoute } = useHandleAddRemoveIdFromRoute();

  const isOrgCreator =
    organizations?.find((org) => org._id === selectedOrganizationId)
      ?.createdBy === userInfo?._id;

  const { handleOrganizationActions } = useHandleOrganizationActions();
  const { loadRoute } = useHandleRouterPush();
  const [showDeleteDialog, setshowDeleteDialog] = React.useState(false);

  const handleShowDialog = () => setshowDeleteDialog(true);

  const handleCloseDialog = () => setshowDeleteDialog(false);

  const dataFetchError =
    orgsAndSuitesForUserApiError || allOrganizationsApiError;

  React.useEffect(() => {
    if (dataFetchError) {
      toast.error(dataFetchError.message);
    }
  }, [dataFetchError]);

  const { accessFlag: canAddNewOrg } = checkAccessRules({
    entity: "ORGANIZATION",
    action: "ADD-ORGANIZATION",
    featureName: "GENERAL",
  });

  const { accessFlag: canDeleteOrg } = checkAccessRules({
    entity: "ORGANIZATION",
    action: "DELETE-ORGANIZATION",
    featureName: "GENERAL",
    isCreator: isOrgCreator,
  });

  const filteredOrgSet =
    (userInfo?.role?.toUpperCase() === "GLOBALADMIN" ||
    userInfo?.role?.toUpperCase() === "TRRYSTADMIN"
      ? organizations?.filter((org) => org.orgType !== "PERSONAL")
      : filterOrgsBasedOnAdmin(orgsAndSuites, userInfo._id)?.filter(
          (org) => org.orgType !== "PERSONAL"
        )) ?? [];

  const selectedOrganization = organizations?.find(
    (org) => org._id === selectedOrganizationId
  );
  const defaultOrgOption = selectedOrganizationId;

  // below react use effect is to basically check if selectedorgid is a personal org in which case make it null as we dont want any management of it to happen
  React.useEffect(() => {
    if (selectedOrganization?.orgType === "PERSONAL") {
      router.push(removeIdFromRoute({ paramKey: "orgId" }), { shallow: true });
      router.push(removeIdFromRoute({ paramKey: "suiteId" }), {
        shallow: true,
      });
    }
  }, [selectedOrganization]);
  // React.useEffect(() => {
  //   if (!checkIfNull(filteredOrgSet)) {
  //     if (!selectedOrganizationId || !filteredOrgSet.find(org => org._id === selectedOrganizationId)) {
  //       selectedOrganizationId ?? filteredOrgSet?.[0]?._id;
  //       currentContext === 'orgadmin' && loadRoute('MANAGE-ORGANIZATION', { orgId: filteredOrgSet[0]?._id });
  //       currentContext === 'analytics' && loadRoute('ADMIN-ANALYTICS', { orgId: filteredOrgSet[0]?._id });
  //     }
  //   }
  // }, [filteredOrgSet, selectedOrganizationId]);

  const handleOrgDelete = async () => {
    await handleOrganizationActions(
      {
        action: "DELETE-ORGANIZATION",
        app21TestData: process.env.APP21TESTDATA,
      },
      null,
      null
    );
    handleCloseDialog();

    currentContext === "orgadmin" &&
      loadRoute("MANAGE-ORGANIZATION", { orgId: filteredOrgSet[0]?._id });
  };

  const handleOrgSelect = (id) => {
    if (id === "addnew") {
      setSelectedOrganizationId(null);
      loadRoute("ADD-SUITE", { orgId: id, choice: "addsuite" });
    } else if (currentContext === "addsuite") {
      loadRoute("ADD-SUITE", { orgId: id, choice: "addsuite" });
    } else if (currentContext === "orgadmin") {
      loadRoute("MANAGE-ORGANIZATION", { orgId: id });
    } else if (currentContext === "analytics") {
      loadRoute("ADMIN-ANALYTICS", { orgId: id });
    } else if (currentContext === "permissions") {
      loadRoute("ADMIN-PERMISSIONS", { orgId: id });
    } else if (currentContext === "billing") {
      loadRoute("ADMIN-BILLING", { orgId: id });
    } else if (currentContext === "") setSelectedSuiteId(null);
  };

  const orgOptions = createOrgSelectOptions(filteredOrgSet);
  if (
    userInfo?.role?.toUpperCase() === "GLOBALADMIN" ||
    userInfo?.role?.toUpperCase() === "TRRYSTADMIN" ||
    currentContext === "addsuite"
  ) {
    orgOptions.push({
      _id: "addnew",
      value: "addnew",
      label: "Create New Organization",
      avatar: <AddCircle />,
    });
  }

  const menuOptions = [
    ...(userInfo?.role?.toUpperCase() === "GLOBALADMIN" || canAddNewOrg
      ? [
          {
            label: "Add New Organization",
            icon: <AddCircle />,
            onClick: () => {
              setSelectedOrganizationId(null);
              loadRoute("ADD-SUITE", { orgId: "addnew", choice: "addsuite" });
            },
          },
        ]
      : []),
    ...(userInfo?.role?.toUpperCase() === "GLOBALADMIN" || canDeleteOrg
      ? [
          {
            label: "Delete Organization",
            icon: <DeleteForever color="error" />,
            onClick: handleShowDialog,
          },
        ]
      : []),
  ];

  if (organizationStatus === "loading" || orgsAndSuitesStatus === "loading") {
    return <LoadingSpinner variant="exp" />;
  } else if (
    !checkIfNull(orgOptions) ||
    userInfo?.role?.toUpperCase() === "GLOBALADMIN" ||
    userInfo?.role?.toUpperCase() === "TRRYSTADMIN"
  ) {
    return (
      <Box
        sx={{
          mb: 1,
          pr: 1,
          pb: 1,
          mt: 1,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        {Array.isArray(filteredOrgSet) && (
          <Autocomplete
            id={`field${Math.floor(Math.random() * 100).toString()}`} // to avoid autofill from showing up
            sx={{
              width: 300,
              "&: .MuiAutocomplete-popper": {},
              ...sx,
            }}
            value={defaultOrgOption}
            options={orgOptions}
            autoHighlight
            disableClearable
            size="small"
            onChange={(event, newValue) => {
              handleOrgSelect(newValue?._id);
            }}
            getOptionLabel={(option) => {
              return (
                option?.label ??
                orgOptions?.find((org) => org._id === option)?.label ??
                ""
              );
            }}
            isOptionEqualToValue={(option, value) => {
              return option._id === value;
            }}
            renderOption={(props, option) => (
              <ListItem {...props}>
                {typeof option.avatar === "string" ? (
                  <ListItemAvatar>
                    <Avatar
                      alt={option.label}
                      src={option.avatar}
                      sx={{ widht: 25, height: 25 }}
                      variant="rounded"
                    />
                  </ListItemAvatar>
                ) : (
                  <ListItemIcon>{option.avatar}</ListItemIcon>
                )}
                <ListItemText primary={option.label} />
              </ListItem>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  showLabel
                    ? currentContext === "addsuite"
                      ? "Select Organization or Create New"
                      : "Select Organization"
                    : null
                }
                placeholder={
                  currentContext === "addsuite"
                    ? "Select Organization or Create New"
                    : "Select Organization"
                }
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          />
        )}
        {menuOptions.length > 0 && currentContext === "orgadmin" ? (
          <MoreActionsDropdownMenu
            menuOptions={menuOptions}
            tooltipLabel="Organization Manage Options"
          />
        ) : null}
        <VerifyDeleteDialog
          open={showDeleteDialog}
          handleClose={handleCloseDialog}
          verifyName={selectedOrganization?.fullName}
          handleConfirm={() => handleOrgDelete()}
          title={`Delete ${selectedOrganization?.fullName} now ?`}
          confirmationText="This will permanently delete the organization and all Suites within it permanently. Please proceed with utmost caution!"
        />
      </Box>
    );
  } else return "You are not an admin in any organization";
};
export default React.memo(SelectOrgTextField);
