import React from "react";
import { IconButton } from "@mui/material";
import { PersonSearch } from "@mui/icons-material";

const ChartUserPicker = () => {
  return (
    <IconButton size="small">
      <PersonSearch fontSize="inherit" />
    </IconButton>
  );
};
export default ChartUserPicker;
