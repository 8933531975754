// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
// import dynamic from "next/dynamic";
import { useTheme } from "@mui/material";

import {
  AreaChart,
  XAxis,
  YAxis,
  Area,
  Tooltip,
  CartesianGrid
} from "recharts";
// export interface IAreaChartData {
//   key: string | number;
//   value: string | number;
// }

// export interface IAreaChartWrapperProps {
//   data: IAreaChartData[];
//   width?: number;
//   height?: number;
//   YAxisName?: string;
//   name?: string;
//   tickFormatter?: (e: string | number) => string;
//   xAxisFormatter?: (e: string | number) => string;
//   // children: React.ReactNode;
// }

export const AreaChartWrapper = props => {
  const {
    data,
    width,
    height,
    YAxisName,
    name,
    tickFormatter,
    xAxisFormatter,
    children
  } = props;
  const theme = useTheme();
  // const { AreaChart, XAxis, YAxis, Area, Tooltip, CartesianGrid } = dynamic(
  //   () => import("recharts"),
  //   {
  //     ssr: false
  //   }
  // );

  return (
    <AreaChart width={width} height={height} data={data}>
      <defs>
        <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#ffffff" stopOpacity={0.8} />
          <stop
            offset="95%"
            stopColor={theme.palette.primary.main}
            stopOpacity={0}
          />
        </linearGradient>
      </defs>
      <XAxis dataKey="key" tickFormatter={xAxisFormatter} />
      <YAxis name={YAxisName} tickFormatter={tickFormatter} />
      <CartesianGrid strokeDasharray="3 3" />
      <Tooltip formatter={tickFormatter} />
      <Area
        dataKey="value"
        name={name}
        type="monotone"
        stroke={theme.palette.primary.main}
        fillOpacity={1}
        fill="url(#colorValue)"
      />
      {children}
    </AreaChart>
  );
};
