import React from "react";
import {
  Box,
  Button,
  Paper,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
} from "@mui/material";
import { FlowBox } from "blocks/atoms/uistyles";
import { useSurveysContext } from "providers/SurveysProvider";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ProfilesChipsList, Label, AvatarGroupWithStatus } from "blocks/atoms";
import { MoreActionsDropdownMenu } from "blocks/atoms/uicomponents";

import { useAppContext, populateUsers, useUsersInSuite } from "@app21/core";
import { format } from "date-fns";
import {
  Add,
  AssignmentTurnedIn,
  Delete,
  Edit,
  Visibility,
  Assignment,
} from "@mui/icons-material";
import { getDateTimeFormat } from "utils/getDateFormats";

export default function SurveysCenterPanel({ isMobile = false }) {
  const { selectedSuiteId } = useAppContext();
  const {
    viewContext,
    actionContext,
    selectedDecisionId,
    decisionsData = [],
    loadRoute,
    surveysPermissions,
    handleSurveyStatusChange,
  } = useSurveysContext();

  const { data: usersInSuite } = useUsersInSuite(selectedSuiteId);

  const handleCellClick = (event, decisionId) => {
    event.preventDefault();
    event.stopPropagation();
    loadRoute("VIEW-RESOLUTION", { decisionId: decisionId });
  };

  const handleRespondClick = (event, decisionId) => {
    event.preventDefault();
    event.stopPropagation();
    loadRoute("RESPOND-RESOLUTION", { decisionId: decisionId });
  };
  const getStatusLabel = (status) => {
    switch (status) {
      case "CLOSED":
        return { label: "Closed", color: "success" };
        break;
      case "DRAFT":
        return { label: "Draft", color: "secondary" };
        break;
      case "OPEN":
        return { label: "Open for Voting", color: "warning" };
        break;

      default:
        return { label: status, color: "primary" };
        break;
    }
  };
  const constructUserAvatarStatusList = (decision) => {
    let users = [];
    decision.survey.decisionInvitees.forEach((invitee) => {
      let user = usersInSuite?.find((user) => user._id === invitee);
      if (user) {
        let responseStatusFlag = Boolean(
          decision?.responses && decision.responses[invitee]
        );
        users.push({
          ...user,
          responseStatus: responseStatusFlag ? "yes" : "no",
          tooltipText: `${user.fullName} has ${
            responseStatusFlag ? "responded" : "not responded"
          }`,
        });
      }
    });
    return users;
  };

  const getAvailableMenuOptions = (rowData) => {
    let allOptions = [
      {
        label: "Close Voting",
        onClick: () => handleSurveyStatusChange("CLOSED", rowData),
        icon: <AssignmentTurnedIn />,
        criteria: rowData.survey.decisionStatus === "OPEN",
      },
      {
        label: "Re-open Resolution",
        onClick: () => handleSurveyStatusChange("OPEN", rowData),
        icon: <AssignmentTurnedIn />,
        criteria: rowData.survey.decisionStatus === "CLOSED",
      },
      {
        label: "Open Resolution for Voting",
        onClick: () => handleSurveyStatusChange("OPEN", rowData),
        icon: <AssignmentTurnedIn />,
        criteria: rowData.survey.decisionStatus === "DRAFT",
      },
      {
        label: "View",
        onClick: () =>
          loadRoute("VIEW-RESOLUTION", { decisionId: rowData._id }),
        icon: <Visibility />,
        criteria: surveysPermissions?.checkCanView(rowData),
      },
      {
        label: "Edit",
        onClick: () =>
          loadRoute("EDIT-RESOLUTION", { decisionId: rowData._id }),
        icon: <Edit />,
        criteria: surveysPermissions?.checkCanEdit(rowData),
      },
      {
        label: "Delete",
        onClick: () =>
          loadRoute("DELETE-RESOLUTION", { decisionId: rowData._id }),
        icon: <Delete />,
        criteria: surveysPermissions?.checkCanDelete(rowData),
      },
      {
        label: "Respond",
        onClick: () =>
          loadRoute("RESPOND-RESOLUTION", { decisionId: rowData._id }),
        icon: <Assignment />,
        criteria:
          rowData.userIsInvited && rowData.userResponseStatus === "pending",
      },
    ];
    return allOptions.filter((option) => option.criteria);
  };

  return (
    <FlowBox>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">Resolutions</Typography>
        <Button
          variant="outlined"
          startIcon={<Add />}
          onClick={() => loadRoute("CREATE-RESOLUTION", {})}
        >
          Add Resolution
        </Button>
      </Box>
      <FlowBox sx={{ mt: 3, pb: 2 }}>
        <PerfectScrollbar
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            flexGrow: 1,
          }}
        >
          <TableContainer
            component={Box}
            sx={{
              boxShadow: (theme) => theme.customShadows.z20,
            }}
          >
            <Table size="small">
              <TableHead sx={{ mb: 10 }}>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="center">Stage</TableCell>
                  <TableCell align="center">Created on</TableCell>
                  <TableCell align="center">Invitees</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ mt: 3 }}>
                {decisionsData?.map((rowData, index) => (
                  <TableRow
                    key={index}
                    hover
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell
                      onClick={(event) => {
                        handleCellClick(event, rowData._id);
                      }}
                      component="th"
                      scope="row"
                    >
                      {rowData.survey.decisionName}
                    </TableCell>
                    <TableCell
                      align="center"
                      onClick={(event) => {
                        handleCellClick(event, rowData._id);
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          gap: 2,
                        }}
                      >
                        <Label
                          color={getStatusLabel(rowData.survey.status).color}
                          variant="ghost"
                          sx={{ p: 3 }}
                        >
                          {getStatusLabel(rowData.survey.decisionStatus).label}
                        </Label>
                        {rowData.survey.decisionStatus !== "DRAFT" &&
                          ` (${
                            Object.values(rowData?.responses ?? [])?.length
                          } / ${
                            rowData.survey?.decisionInvitees?.length
                          } responses)`}
                      </Box>
                    </TableCell>
                    <TableCell
                      align="center"
                      onClick={(event) => {
                        handleCellClick(event, rowData._id);
                      }}
                    >
                      {format(
                        new Date(rowData.survey.decisionOpenDate),
                        getDateTimeFormat({
                          startDate: rowData.survey.decisionOpenDate,
                          noTimeFlag: true,
                        })
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      onClick={(event) => {
                        handleCellClick(event, rowData._id);
                      }}
                    >
                      <AvatarGroupWithStatus
                        userStatusList={constructUserAvatarStatusList(rowData)}
                        AvatarGroupProps={{ spacing: 3 }}
                      />
                    </TableCell>
                    <TableCell align="right">
                      {rowData.survey.decisionStatus === "OPEN" &&
                      rowData.userIsInvited &&
                      rowData.userResponseStatus === "pending" ? (
                        <Button
                          variant="contained"
                          onClick={(event) =>
                            handleRespondClick(event, rowData._id)
                          }
                        >
                          Respond
                        </Button>
                      ) : rowData.userResponseStatus === "completed" ? (
                        <Button
                          variant="outlined"
                          onClick={(event) =>
                            handleCellClick(event, rowData._id)
                          }
                        >
                          Results
                        </Button>
                      ) : null}
                      {
                        <MoreActionsDropdownMenu
                          menuOptions={getAvailableMenuOptions(rowData)}
                          tooltipLabel="More Resolution Options"
                        />
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </PerfectScrollbar>
      </FlowBox>
    </FlowBox>
  );
}
