// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Button } from "@mui/material";
import { useRouter } from "next/router";
import { useAuth0 } from "@auth0/auth0-react";
import LoggedInUser from "blocks/views/LandingPage/features/LoggedInUser";
import { useIntl } from "react-intl";

const SignupMenuBlock = () => {
  const { isAuthenticated } = useAuth0();
  const router = useRouter();
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {!isAuthenticated ? (
        <Button
          id="SignUp-Button-Title-Bar"
          size="small"
          variant="contained"
          color="secondary"
          sx={{ margin: (theme) => theme.spacing(1) }}
        >
          {`${t("TopNavbar.trialInvite")}`}
        </Button>
      ) : (
        <Button
          onClick={() => router.push("/user", { shallow: true })}
          data-testid="authenticated"
          variant="contained"
          color="secondary"
          size="small"
          sx={{ margin: (theme) => theme.spacing(1) }}
        >
          {`${t("TopNavbar.dashboard")}`}
        </Button>
      )}
      <LoggedInUser />
    </div>
  );
};
export default SignupMenuBlock;
