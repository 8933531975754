import React from "react";
import {
  Grid,
  Container,
  Box,
  Typography,
  Avatar,
  TextField,
  styled,
  Paper,
  alpha,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { CheckCircle, Done, PlaylistAddCheck, Send } from "@mui/icons-material";
import { FlowBox } from "blocks/atoms/uistyles";
import { suiteInformationText } from "blocks/atoms/constants";
import DisplaySelectedSuiteFeatures from "./DisplaySelectedSuiteFeatures";

const StyledPaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "isSelected",
})(({ theme, isSelected }) => {
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",

    padding: 10,
    backgroundColor: isSelected
      ? theme.palette.primary.main
      : theme.palette.background.paper,
    color: isSelected
      ? theme.palette.primary.contrastText
      : theme.palette.text.secondary,
  };
});
export default function SelectSubscriptionTemplateForm({
  formSubscriptionChoice,
  formSubscriptionTemplate,
  formCouponCode,
  isMobile = false,
  ...couponCodeProps
}) {
  const [featureComparisonDialogOpen, setFeatureComparisonDialogOpen] =
    React.useState(false);
  const suitesToShow = ["EXEC-SUITE", "BOARD-SUITE", "DATA-SUITE"];
  const allSuiteInfo = suitesToShow.map((suiteGroup) =>
    suiteInformationText.find((suite) => suite.suiteGroup === suiteGroup)
  );
  const { watch, control, getValues } = useFormContext();
  const {
    couponHelperMessage,
    couponSuccess,
    handleCheckCouponCode,
    app21ProductsAndCouponsData,
    priceInfo = {},
    creditsInfo = {},
    chosenPromoCode,
  } = couponCodeProps;

  const handleFeatureComparisonDialogOpen = () => {
    setFeatureComparisonDialogOpen(true);
  };
  const handleFeatureComparisonDialogClose = () => {
    setFeatureComparisonDialogOpen(false);
  };

  const TemplateSelectionPanel = ({
    icon,
    title,
    originalPrice,
    discountedPrice,
    description,
    isSelected,
    buttonText,
    templateValue,
  }) => {
    return (
      <StyledPaper
        elevation={3}
        isSelected={isSelected}
        sx={{ gap: 3, position: "relative" }}
      >
        <Controller
          control={control}
          name={formSubscriptionTemplate}
          render={({ field }) => {
            return (
              <Button
                variant="contained"
                size="medium"
                disabled={isSelected}
                onClick={() => field.onChange(templateValue)}
                startIcon={
                  isSelected ? (
                    <CheckCircle sx={{ color: "primary.main" }} />
                  ) : null
                }
                sx={{
                  position: "absolute",
                  top: 5,
                  right: 5,
                  mt: 2,
                  "&.Mui-disabled": {
                    bgcolor: "secondary.lighter",
                    color: "primary.main",
                  },
                }}
              >
                {isSelected ? "Selected" : buttonText}
              </Button>
            );
          }}
        />

        <Box sx={{ display: "flex" }}>
          <Typography variant="h4">
            <sup>$</sup>
          </Typography>
          <Typography
            variant="h2"
            sx={{
              ml: 2,
              textDecoration: chosenPromoCode ? "line-through" : "none",
              fontWeight: chosenPromoCode ? "normal" : "bold",
              color: chosenPromoCode
                ? "text.disabled"
                : isSelected
                ? "primary.contrastText"
                : "text.secondary",
            }}
          >
            {originalPrice}
          </Typography>
          {chosenPromoCode && (
            <Typography
              variant="h2"
              sx={{
                ml: 2,
              }}
            >
              {discountedPrice}
            </Typography>
          )}
        </Box>
        <Typography variant="h5">{title}</Typography>
        <Typography variant="caption">{description}</Typography>
      </StyledPaper>
    );
  };

  return (
    <FlowBox sx={{ p: 2 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="subtitle1">
          What kind of a Suite do you need
        </Typography>
        <Button
          color="primary"
          variant="outlined"
          startIcon={<PlaylistAddCheck />}
          size="small"
          onClick={handleFeatureComparisonDialogOpen}
          sx={{ ml: 2 }}
        >
          Help me Choose
        </Button>
      </Box>
      <Grid container columnSpacing={6} rowSpacing={4} sx={{ pt: 4 }}>
        <Grid item container columnSpacing={3} rowSpacing={3} xs={12} md={8}>
          {allSuiteInfo.map((suiteInfo, index) => {
            const originalPrice = priceInfo[suiteInfo.suiteGroup];
            const discountedPrice = chosenPromoCode
              ? Math.round(originalPrice * (1 - chosenPromoCode.discount))
              : 0;
            return (
              <Grid
                key={index}
                item
                xs={6}
                alignItems="stretch"
                sx={{
                  display: "flex",
                  flexGrow: "column",
                  flexGrow: 1,
                }}
              >
                <TemplateSelectionPanel
                  title={suiteInfo.suiteName}
                  originalPrice={originalPrice}
                  discountedPrice={discountedPrice}
                  description={suiteInfo.suiteBlurb}
                  templateValue={suiteInfo.suiteGroup}
                  isSelected={
                    watch(formSubscriptionTemplate) === suiteInfo.suiteGroup
                  }
                  buttonText="Select"
                />
              </Grid>
            );
          })}
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              height: "100%",
              backgroundColor: (theme) =>
                alpha(theme.palette.primary.light, 0.01),
            }}
          >
            <DisplaySelectedSuiteFeatures
              suiteName={
                suiteInformationText.find(
                  (suite) =>
                    suite.suiteGroup === watch(formSubscriptionTemplate)
                )?.suiteName ?? []
              }
              features={
                suiteInformationText.find(
                  (suite) =>
                    suite.suiteGroup === watch(formSubscriptionTemplate)
                )?.suiteFeatures ?? []
              }
            />
          </Paper>
        </Grid>

        <Grid
          item
          container
          direction={{ xs: "column", md: "row" }}
          xs={12}
          alignItems="center"
        >
          <Grid item xs={12} md={5} lg={4}>
            <Typography variant="h6">Have a promo code?</Typography>
          </Grid>
          <Grid item xs={12} md={7} lg={8}>
            <Controller
              control={control}
              name={formCouponCode}
              render={({ field }) => {
                return (
                  <TextField
                    size="small"
                    {...field}
                    sx={{ ml: 2 }}
                    helperText={couponHelperMessage}
                    placeholder="Insert Promo Code"
                    FormHelperTextProps={{ error: !couponSuccess }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {isMobile ? (
                            <IconButton
                              aria-label="Apply Coupon Code"
                              onClick={handleCheckCouponCode}
                              onMouseDown={handleCheckCouponCode}
                              edge="end"
                            >
                              <Send />
                            </IconButton>
                          ) : (
                            <Button
                              aria-label="Apply Coupon Code"
                              onClick={handleCheckCouponCode}
                              onMouseDown={handleCheckCouponCode}
                              edge="end"
                            >
                              Apply
                            </Button>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </FlowBox>
  );
}
