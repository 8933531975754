import React from 'react'
import { SvgIcon } from '@mui/material';

export default function RecordingsIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M33.332 41.7502V47.9168C33.332 57.1252 40.7903 64.5835 49.9986 64.5835C59.207 64.5835 66.6653 57.1252 66.6653 47.9168V25.0002C66.6653 15.7918 59.207 8.3335 49.9986 8.3335C40.7903 8.3335 33.332 15.7918 33.332 25.0002"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.1236 40.208V47.2913C18.1236 64.8747 32.4153 79.1663 49.9986 79.1663C67.582 79.1663 81.8736 64.8747 81.8736 47.2913V40.208"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M44.207 26.792C47.957 25.417 52.0403 25.417 55.7903 26.792"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M46.6653 35.624C48.8736 35.0407 51.1653 35.0407 53.3736 35.624"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M49.9986 79.1665V91.6665"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
