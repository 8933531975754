// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { Fragment } from "react";
import { Button, Typography, Paper, Box } from "@mui/material";
import { useRouter } from "next/router";
import { FlowBox } from "blocks/atoms/uistyles";
const BackTitleWrapperPaper = ({
  children,
  title,
  PaperProps = {},
  handleClose,
  handleActionClick,
  ActionButton,
  actionButtonProps = {},
  actionButtonTitle = "Edit",
  backButtonText = "Back",
  backButtonProps = {},
  hideBackButton = false,
  ...rest
}) => {
  const router = useRouter();
  const handleGoBack = () => {
    if (handleClose) {
      handleClose();
    } else router.back();
  };
  const RightActionButton = ActionButton ?? Button;
  return (
    <FlowBox
      sx={{
        width: "100%",
      }}
      {...rest}
    >
      <Paper
        elevation={0}
        sx={{
          p: 1,
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
          // mt: -0.5,
          // ml: -0.5,
        }}
        {...PaperProps}
      >
        {!hideBackButton && (
          <Button
            size="small"
            variant="outlined"
            onClick={handleGoBack}
            {...backButtonProps}
          >
            {backButtonText}
          </Button>
        )}
        {typeof title === "string" ? (
          <Typography variant="h6" sx={{ flex: 1, textAlign: "center" }}>
            {title}
          </Typography>
        ) : (
          title
        )}
        {handleActionClick ? (
          <RightActionButton onClick={handleActionClick} {...actionButtonProps}>
            {actionButtonTitle}
          </RightActionButton>
        ) : null}
      </Paper>
      {children}
    </FlowBox>
  );
};
export default BackTitleWrapperPaper;
