import React from 'react'
import { SvgIcon } from "@mui/material";

export default function KeyContributorsIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M60.1672 79.3743L66.5006 85.7077L79.1672 73.041"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M49.9585 90.8743C42.3752 90.8743 34.8335 88.9577 29.0835 85.1243C19.0002 78.3743 19.0002 67.3743 29.0835 60.666C40.5418 52.9993 59.3335 52.9993 70.7918 60.666"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M65.3338 16.5007C67.3338 19.459 68.5005 23.0007 68.5005 26.834C68.4588 36.834 60.5838 44.959 50.6672 45.2923C50.2505 45.2507 49.7505 45.2507 49.2922 45.2923C39.3755 44.959 31.5005 36.834 31.5005 26.834C31.5005 16.6257 39.7505 8.33398 50.0005 8.33398"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
