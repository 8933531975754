import React from "react";
import { Tooltip, IconButton, Typography, Box, Chip } from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineContent,
  TimelineOppositeContent,
  TimelineSeparator,
  TimelineConnector,
  TimelineDot,
} from "@mui/lab";

import {
  AddCircle,
  Group,
  Person,
  RecordVoiceOverOutlined,
} from "@mui/icons-material";
import { format } from "date-fns";
import { UserAvatar, PrintUserInfo } from "blocks/atoms";
import Highlighter from "react-highlight-words";
import { contrastColor } from "contrast-color";
import { getNumberFromString } from "utils/getNumberFromString";
import { COLORPALETTE } from "blocks/atoms/constants/appColors";
import PerfectScrollbar from "react-perfect-scrollbar";

export default function ShowDetailedTranscript({
  filteredTimelineTranscript = [],
  playedSeconds,
  videoOffsetSeconds,
  activeTranscriptRef,
  videoPlayerRef,
  setPlayedSeconds,

  searchQuery,
}) {
  const getMarkerTitleBackgroundColor = (string) => {
    //stringToColor(string);
    let colorNum = getNumberFromString(string, COLORPALETTE.length) ?? 255;
    return COLORPALETTE[colorNum];
  };

  return (
    <PerfectScrollbar
      style={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
        paddingRight: 10,
        overflow: "auto",
      }}
    >
      <Timeline sx={{}}>
        {filteredTimelineTranscript.map((transcript, index) => {
          return (
            <TimelineItem
              key={index}
              sx={{
                cursor: "pointer",

                p: 0,
                minHeight: 40,
              }}
              ref={
                playedSeconds + videoOffsetSeconds >
                  transcript.relativeStartTime &&
                playedSeconds + videoOffsetSeconds < transcript.relativeEndTime
                  ? activeTranscriptRef
                  : null
              }
              onClick={() => {
                setPlayedSeconds(
                  transcript.relativeStartTime - videoOffsetSeconds > 0
                    ? transcript.relativeStartTime - videoOffsetSeconds
                    : 0
                );
                videoPlayerRef?.current?.seekTo(
                  filteredTimelineTranscript[index == 0 ? index : index - 1]
                    .relativeStartTime / 1000
                );
              }}
            >
              <TimelineOppositeContent
                sx={{
                  m: "auto 0",
                  flex: 0.15,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  fontSize: 9,
                }}
                align="right"
                variant="caption"
                component="span"
                color="text.secondary"
              >
                {transcript?.speaker?.startTimestamp
                  ? format(
                      new Date(transcript?.speaker?.startTimestamp),
                      "H:mm:ss"
                    )
                  : null}
                {playedSeconds + videoOffsetSeconds >
                  transcript.relativeStartTime &&
                  playedSeconds + videoOffsetSeconds <
                    transcript.relativeEndTime && <RecordVoiceOverOutlined />}
                <span>
                  <PrintUserInfo
                    defaultText="Speaker"
                    userId={transcript?.speaker?.userId}
                  />
                </span>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot variant="outlined" sx={{ p: 0 }}>
                  <UserAvatar
                    style={{
                      border:
                        playedSeconds + videoOffsetSeconds >
                          transcript.relativeStartTime &&
                        playedSeconds + videoOffsetSeconds <
                          transcript.relativeEndTime
                          ? "2px solid #ffcc00"
                          : undefined,
                    }}
                    userId={transcript?.speaker?.userId}
                    sx={{ height: 25, width: 25 }}
                  />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent
                sx={{
                  py: 1,
                  px: 2,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography variant="body2" component="span">
                  <Highlighter
                    highlightClassName="YourHighlightClass"
                    searchWords={[searchQuery]}
                    autoEscape={true}
                    textToHighlight={transcript.content ?? ""}
                  />
                </Typography>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    </PerfectScrollbar>
  );
}
