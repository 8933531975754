// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  IconButton,
  TextField,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  ListItemSecondaryAction,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Add, Clear, AddCircle } from "@mui/icons-material";
import {
  useUsersInOrganization,
  useAppContext,
  getS3ImageWrapper,
  executeApi,
  useHandleOrganizationActions,
} from "@app21/core";

import React, { useState } from "react";
import {
  Controller,
  FormProvider,
  useForm,
  useFieldArray,
} from "react-hook-form";

import DraggablePaper from "blocks/atoms/uicomponents/DraggablePaper";
import LoadingSpinner from "blocks/atoms/LoadingSpinner";

import isEmail from "validator/lib/isEmail";
import toast from "react-hot-toast";
import AddIcon from "@mui/icons-material/Add";

export default function InviteNewUser({
  icon = true,
  label = "Invite Users to Organization",
  sx = {},
  ...rest
}) {
  const [open, setOpen] = useState(false);

  const blankEmail = { emailId: "" };
  const methods = useForm({
    mode: "onChange",
    defaultValues: { invitee: [blankEmail] },
  });
  const { watch, control, handleSubmit, formState, reset } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "invitee",
  });

  const [existingUserInfo, setExistingUserInfo] = useState(null);
  const { selectedOrganizationId, checkAccessRules } = useAppContext();
  const { data: usersInOrganization, apiError: usersInOrganizationApiError } =
    useUsersInOrganization(selectedOrganizationId);
  const [isFetchingExistingUser, setIsFetchingExistingUser] =
    React.useState(false);
  const [usersToAdd, setUsersToAdd] = React.useState([]);

  const { errors, isSubmitting } = formState;

  const {
    handleOrganizationActions,
    addUserToOrganizationStatus,
    inviteUserToOrganizationStatus,
  } = useHandleOrganizationActions();

  const handleClose = async () => {
    setExistingUserInfo(null);
    setUsersToAdd([]);
    reset();
    setOpen(false);
  };

  React.useEffect(() => {
    if (usersInOrganizationApiError) {
      toast.error(usersInOrganizationApiError.message);
    }
  }, [usersInOrganizationApiError]);

  const checkForExistingUser = async (addedEmail) => {
    if (addedEmail !== "") {
      setIsFetchingExistingUser(true);
      const fetchedExistingUser = await executeApi("FETCH-USER-BY-EMAIL", {
        emailId: addedEmail,
      });
      setIsFetchingExistingUser(false);
      if (fetchedExistingUser && fetchedExistingUser.data) {
        setExistingUserInfo(fetchedExistingUser.data);
      } else {
        append(blankEmail);
      }
    }
  };

  const addUserToArray = async (index) => {
    let addedEmail = watch(`invitee[${index}].emailId`);
    checkForExistingUser(addedEmail);
  };

  const onSubmit = async (formdata) => {
    const postAction = () => {
      handleClose();
    };
    handleOrganizationActions(
      { action: "INVITE-NEW-USER", formdata, usersToAdd },
      null,
      postAction
    );
  };

  const ShowExistingUserCard = ({ user }) => (
    <Card>
      <CardContent>
        Existing User found with that email Id. Proceed?{" "}
      </CardContent>
      <CardHeader
        avatar={<Avatar src={getS3ImageWrapper(user?.image, "person")} />}
        title={user?.fullName}
        subheader={user?.emailId}
      />
      <CardActions>
        <Button
          onClick={() => {
            setExistingUserInfo(null);
          }}
        >
          Cancel
        </Button>
        <Button
          type="button"
          onClick={() => {
            setExistingUserInfo(null);
            setUsersToAdd((users) => [...users, user]);
            append(blankEmail);
          }}
        >
          Add User
        </Button>
      </CardActions>
    </Card>
  );

  return checkAccessRules({
    entity: "ORGANIZATION",
    action: "ADD-USER",
    featureName: "MANAGEMENT-CONSOLE",
  })?.accessFlag ? (
    <div>
      {!icon ? (
        <Button
          startIcon={<AddIcon />}
          onClick={() => setOpen(true)}
          sx={{ ...sx }}
          {...rest}
          variant="outlined"
          size="small"
        >
          {label}
        </Button>
      ) : (
        <Tooltip title={label} arrow>
          <IconButton
            color="secondary"
            sx={{
              ...sx,
            }}
            onClick={() => setOpen(true)}
          >
            <AddCircle />
          </IconButton>
        </Tooltip>
      )}
      <Dialog
        PaperComponent={DraggablePaper}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <FormProvider {...methods}>
          <form>
            <DialogTitle id="dragModal">
              Invite User to Organization
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                To invite a new member to your organization on Trryst, please
                enter the member&lsquo;s email address here. We will send and
                invite and notify you when the person has accepted the
                invitation and joined Trryst.
              </DialogContentText>
              <List
                aria-labelledby="existing-users-list"
                subheader={
                  <ListSubheader component="div" id="existing-users-List">
                    Email ID of Invitee(s)
                  </ListSubheader>
                }
              >
                {fields.map((inviteeemail, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      primary={
                        <Controller
                          control={control}
                          name={`invitee[${index}].emailId`}
                          defaultValue={inviteeemail?.emailId ?? ""}
                          rules={{
                            required: {
                              value: false,
                              message: "Valid email address is needed.",
                            },
                            validate: {
                              validEmail: (value) =>
                                value === "" || isEmail(value ?? ""),
                              userAlreadyPresent: (value) => {
                                if (
                                  usersInOrganization.find(
                                    (user) => user.emailId === value
                                  )
                                ) {
                                  return false;
                                } else {
                                  return true;
                                }
                              },
                            },
                          }}
                          error={
                            !!(errors.invitee && errors.invitee[index]?.emailId)
                          }
                          render={({ field }) => (
                            <TextField
                              {...field}
                              autoFocus
                              margin="dense"
                              label="Email Address"
                              type="email"
                              autoComplete="email"
                              helperText={
                                (errors.invitee &&
                                  errors.invitee[index]?.emailId &&
                                  errors.invitee[index]?.emailId?.message) ||
                                (errors.invitee &&
                                  errors.invitee[index]?.emailId &&
                                  errors.invitee[index]?.emailId.type ===
                                    "validEmail" &&
                                  "Given E-mail address is not valid") ||
                                (errors.invitee &&
                                  errors.invitee[index]?.emailId &&
                                  errors.invitee[index]?.emailId?.type ===
                                    "userAlreadyPresent" &&
                                  "User already present in organization")
                              }
                              fullWidth
                              variant="standard"
                            />
                          )}
                        />
                      }
                    />
                    <ListItemSecondaryAction>
                      {index === fields.length - 1 ? (
                        <LoadingButton
                          startIcon={<Add />}
                          loadingPosition="start"
                          loading={isFetchingExistingUser || isSubmitting}
                          disabled={Boolean(
                            errors.invitee && errors.invitee[index]?.emailId
                          )}
                          type="button"
                          onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            addUserToArray(index);
                          }}
                        >
                          Add
                        </LoadingButton>
                      ) : (
                        <IconButton onClick={() => remove(index)}>
                          <Clear />
                        </IconButton>
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
              {Boolean(existingUserInfo) && (
                <ShowExistingUserCard user={existingUserInfo} />
              )}
            </DialogContent>
            <DialogActions>
              {!existingUserInfo && (
                <>
                  <Button onClick={handleClose}>Cancel</Button>
                  {addUserToOrganizationStatus?.isLoading ||
                  inviteUserToOrganizationStatus?.isLoading ? (
                    <LoadingSpinner variant="exp" size={20} />
                  ) : (
                    <Button
                      variant="contained"
                      type="button"
                      disabled={
                        isSubmitting ||
                        !(Object.values(errors).length === 0) ||
                        fields[0]?.emailId === "" ||
                        isFetchingExistingUser
                      }
                      onClick={handleSubmit(onSubmit)}
                    >
                      Submit All
                    </Button>
                  )}
                </>
              )}
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </div>
  ) : null;
}
