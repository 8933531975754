export const baseSearchActions = [
  {
    value: "docketsFilter",
    label: "Filter by Breakouts",
    actionFn: () => null,
  },
  { value: "dateFilter", label: "Filter by Date", actionFn: () => null },
  { value: "statusFilter", label: "Filter by Status", actionFn: () => null },
  // {
  //   value: "assigneeFilter",
  //   label: "Filter by Assignee",
  //   actionFn: () => null,
  // },
  { value: "sortByName", label: "Sort by name", actionFn: () => null },
  { value: "sortByStatus", label: "Sort by Status", actionFn: () => null },
  { value: "sortByDate", label: "Sort by Date", actionFn: () => null },
];
