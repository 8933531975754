import React, { useState } from "react";
import {
  DocketHeaderPanel,
  DocketConsolidatedView,
  DocketSelectionDropdown,
  DocketUsageGuidancePanel,
} from "blocks/modules/Dockets";
import { BoardMeetingEditForm } from "blocks/views/DocketDashboard/features";

import { FlowBox } from "blocks/atoms/uistyles";
// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import LoadingSpinner from "blocks/atoms/LoadingSpinner";

import Label from "blocks/atoms/uicomponents/Label";
import { alpha } from "@mui/material/styles";
import {
  Edit as EditIcon,
  ArrowRight as ArrowRightIcon,
  Search as SearchIcon,
  PlusCircle as PlusCircleIcon,
} from "react-feather";

import {
  useAppContext,
  getS3ImageWrapper,
  checkIfNull,
  getRolePrivileges,
  useUsersInOrganization,
  useDocketsInSuite,
  useMeetingsInDockets,
  useUsersInSuite,
  useSuite,
  useHandleRouterPush,
} from "@app21/core";

import { useDocketsContext } from "providers/DocketsProvider";

export default function TabbedDocketDashboardView({ children }) {
  const { selectedSuiteId, selectedOrganizationId, selectedDocketId } =
    useAppContext();
  const { viewContext, actionContext, allDockets, baseDocketsStatus } =
    useDocketsContext();

  const actionContextOptions = [
    "summary",
    "chat",
    "agenda",
    "files",
    "resolutions",
    "view-resolution",
    "edit-resolution",
    "add-resolution",
    "respond-resolution",
    "minutes",
    "tasks",
    "records",
  ];

  const getCentralDisplayComponent = () => {
    switch (true) {
      case viewContext === "add": {
        return <BoardMeetingEditForm newDocket={true} />;
      }
      case allDockets?.length === 0: {
        return <DocketUsageGuidancePanel dockets={allDockets} />;
      }
      case !selectedDocketId: {
        return <DocketUsageGuidancePanel dockets={allDockets} />;
      }
      case viewContext === "edit": {
        return <BoardMeetingEditForm />;
      }
      case actionContextOptions.includes(actionContext): {
        return <DocketConsolidatedView />;
      }
      case viewContext === "list": {
        return <DocketUsageGuidancePanel dockets={allDockets} />;
      }
      default:
        return <DocketConsolidatedView />;
    }
  };

  if (baseDocketsStatus === "loading") {
    return <LoadingSpinner variant="exp" />;
  }
  return (
    <FlowBox>
      <DocketHeaderPanel dockets={allDockets} />
      {getCentralDisplayComponent()}
    </FlowBox>
  );
}
