// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
//import { useHistory } from 'react-router-dom';
import { useMeetingManager } from "amazon-chime-sdk-component-library-react";
import React, { useState } from "react";
import { useVideocallContext } from "providers/TrrystVideocallProvider";
import { useAppContext, executeApi } from "@app21/core";

import toast from "react-hot-toast";
import { StyledSquareMenuButton } from "blocks/atoms/uistyles";

import { Button, Modal, Box, Typography, Stack } from "@mui/material";

import { useHandleLeaveVideocall } from "providers/TrrystVideocallProvider/hooks";
import { EndCallWebIcon } from "blocks/atoms/trrystCustomIcons";
import { ChatAllIcon } from "blocks/atoms/trrysticons";
import { pxToRem } from "utils/styleHelpers";
import { chimeTestIds } from "blocks/atoms/constants";

const EndConferenceControl = ({ recommendedButtonSize = 40 }) => {
  const meetingManager = useMeetingManager();
  const { meetingId, userInfo } = useAppContext();
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);

  const { isGuestUser } = useVideocallContext();
  const { leaveVideocall } = useHandleLeaveVideocall();

  const leaveMeeting = async () => {
    try {
      const chimeMeetingId = meetingManager.meetingId;
      await leaveVideocall({ meetingManager });
      executeApi("LEAVE-CHIME-MEETING", {
        chimeMeetingId: chimeMeetingId,
        userId: userInfo._id,
      });

      toast.success("You have left the meeting");
    } catch (e) {
      toast.error(`There was an issue in leaving the meeting properly - ${e} `);
    }
  };

  const endMeetingForAll = async () => {
    try {
      if (meetingId) {
        const chimeMeetingId = meetingManager.meetingId;
        await leaveVideocall({ meetingManager });

        executeApi("END-CHIME-MEETING", {
          chimeMeetingId: chimeMeetingId,
          externalMeetingId: meetingId,
          calledBy: userInfo._id,
        });

        toast.success("Meeting has ended for all participants", {
          appearance: "success",
          autoDismiss: true,
        });
      }
    } catch (e) {
      toast.error(`There was an issue in ending the meeting properly - ${e} `);
    }
  };

  const ShowEndMeetingDialog = () => {
    return (
      <Modal open={showModal} onClose={toggleModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: 24,
            m: 2,
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "background.paper",
            width: `clamp(350px, 50%, ${pxToRem(835)})`,
            height: `clamp(330px, 50%, ${pxToRem(530)})`,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",

              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <ChatAllIcon
              fontSize="large"
              sx={{
                width: 'min("15%", 96px)',

                color: (theme) => theme.palette.primary.main,
              }}
            />
            <Typography variant="h3" sx={{ mt: 8 }}>
              Leave The Meeting
            </Typography>
            <Box
              sx={{
                width: `clamp(350px, 75%, ${pxToRem(635)})`,
                display: "flex",
                justifyContent: "center",

                mt: 5,
              }}
            >
              <Typography variant="h6" sx={{ textAlign: "center" }}>
                Are you sure want to leave the Meeting ?
              </Typography>
            </Box>
            <Stack direction={"row"} spacing={5} sx={{ mt: 8 }}>
              <Button onClick={toggleModal}>CANCEL</Button>
              <Button
                onClick={leaveMeeting}
                variant="contained"
                sx={{ borderRadius: pxToRem(40) }}
              >
                LEAVE NOW
              </Button>
            </Stack>
            {!isGuestUser && (
              <Button
                variant="outlined"
                onClick={endMeetingForAll}
                color="error"
                sx={{ mt: 8 }}
              >
                END MEETING FOR ALL
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
    );
  };
  return (
    <>
      <StyledSquareMenuButton
        data-testid={chimeTestIds.endMeetingControl}
        color="error"
        onClick={toggleModal}
        sx={{
          borderRadius: 0,
          padding: 0,
          width: recommendedButtonSize,
          height: recommendedButtonSize,
          maxWidth: recommendedButtonSize,
          maxHeight: recommendedButtonSize,
          minWidth: recommendedButtonSize,
          minHeight: recommendedButtonSize,
        }}
      >
        <EndCallWebIcon
          fontSize="large"
          color="error"
          sx={{
            width: recommendedButtonSize,
            height: recommendedButtonSize,

            maxWidth: pxToRem(40),
            maxHeight: pxToRem(40),
            "&:hover": {
              color: "error.dark",
            },
          }}
        />
      </StyledSquareMenuButton>
      <ShowEndMeetingDialog />
    </>
  );
};

export default EndConferenceControl;
