import React from "react";
import { Box, Typography, Tabs, Tab, TabPanel, Paper } from "@mui/material";
import { FlowBox } from "blocks/atoms/uistyles";
import { useAppContext, useUsersInSuite, checkIfNull } from "@app21/core";
import { useChatContext } from "providers/TrrystChatProvider";
import { SearchGroupField } from "blocks/modules/TrrystChat/features";
import ShowChatMembersList from "./Members/ShowChatMembersList";
import ShowChatGroupAttachments from "./Files/ShowChatGroupAttachments";
import { useAtom } from "jotai";
import { SearchFilterAtom } from "blocks/modules/TrrystChat/state-atoms";
import { getUserRoleInSuite } from "utils/suiteHelperUtils";

const ShowChatInfoPanel = ({
  selectedChannel = "",
  handleSelectDirectChannel,
}) => {
  const { chatInfoContext, handleChangeChatInfoContext, chatPermissions } =
    useChatContext();
  const { selectedSuiteId, userInfo } = useAppContext();
  const { data: usersInSuite } = useUsersInSuite(selectedSuiteId);

  const [, setSearchFilter] = useAtom(SearchFilterAtom);
  let tabValue =
    chatInfoContext ?? (chatPermissions.canAccessMembers ? "members" : "files");

  const filteredMembersList = React.useMemo(() => {
    if (selectedChannel?.members) {
      return selectedChannel.members.filter((member) => {
        if (chatPermissions.canAccessMembers) {
          return true;
        } else if (member.id === userInfo._id) {
          return true;
        } else if (getUserRoleInSuite(member.id, usersInSuite) === "MANAGER") {
          return true;
        } else {
          return false;
        }
      });
    } else return [];
  }, [selectedChannel, chatPermissions, usersInSuite]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
        transition: "display 1s ease-in-out",
      }}
    >
      <Box
        sx={{
          pt: 1,
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          height: "100%",
          overflow: "hidden",
        }}
      >
        <Tabs
          value={tabValue}
          variant="fullWidth"
          onChange={(event, value) => {
            setSearchFilter("");
            handleChangeChatInfoContext(value);
          }}
          selectionFollowsFocus
        >
          <Tab
            value="members"
            label={
              <Typography
                variant="overline"
                sx={{
                  minWidth: 150,
                  maxWidth: 200,
                }}
              >
                Members
                {chatPermissions.canAccessMembers
                  ? `(${filteredMembersList?.length})`
                  : ""}
              </Typography>
            }
          />
          <Tab
            label={
              <Typography variant="overline">
                Files ({selectedChannel?.files?.length})
              </Typography>
            }
            value="files"
          />
        </Tabs>
        <Paper
          elevation={0}
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "min-content",
            py: 1,
          }}
        >
          <SearchGroupField />
        </Paper>
        {tabValue === "members" && !checkIfNull(filteredMembersList) && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              height: "100%",
              overflow: "hidden",
              pl: 1,
            }}
          >
            <ShowChatMembersList
              selectedChannel={selectedChannel}
              membersList={filteredMembersList}
              handleSelectDirectChannel={handleSelectDirectChannel}
            />
          </Box>
        )}
        {tabValue === "files" && (
          <FlowBox
            sx={{
              overflow: "hidden",
              pl: 1,
            }}
          >
            <ShowChatGroupAttachments selectedChannel={selectedChannel} />
          </FlowBox>
        )}
      </Box>
    </Box>
  );
};
export default ShowChatInfoPanel;
