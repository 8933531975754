import React from "react";
import { Box, Typography } from "@mui/material";
import { FlowBox } from "blocks/atoms/uistyles";
import { useIsMobileBreakpoint } from "hooks";
import { FilesSidebar, FilesCenterPanel } from "./features";
import { FilesMenubar } from "blocks/modules/TrrystFiles/foundations";
import FilesProvider from "providers/FilesProvider";
import {
  SidePanelDrawer,
  ModulesTitleComponent,
} from "blocks/atoms/uicomponents";

export default function MainFilesComponent({
  viewContext = "SUITE",
  viewMode = "full",
}) {
  const isMobile = useIsMobileBreakpoint(480, true) || viewMode === "compact";
  const sidebarModeActive = isMobile;

  const showSidebar = viewContext === "SUITE";
  const TitleComponent = (
    <Typography variant="h4" color="text.secondary">
      Files
    </Typography>
  );
  const sidebarWidthRatio = 20;
  const centralTitleBarHeight = 48;
  return (
    <FilesProvider viewContext={viewContext} isMobile={isMobile}>
      <FlowBox
        sx={{
          position: "relative",
        }}
      >
        <ModulesTitleComponent
          sidebarActive={isMobile}
          width={`${sidebarWidthRatio}%`}
          TitleComponent={TitleComponent}
          centralTitleBarHeight={centralTitleBarHeight}
          HeaderComponent={
            <FilesMenubar viewContext={viewContext} isMobile={isMobile} />
          }
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "stretch",
            flexGrow: 1,
            height: "100%",
            position: "relative",
          }}
        >
          {showSidebar ? (
            <SidePanelDrawer
              sidebarModeActive={sidebarModeActive}
              centralTitleBarHeight={centralTitleBarHeight}
              TitleComponent={TitleComponent}
              width={`${sidebarWidthRatio}%`}
              DrawerSxProps={{ minWidth: 250 }}
              sx={{ width: `min(max(${sidebarWidthRatio}%, 200px), 400px)` }}
            >
              <FilesSidebar viewContext={viewContext} isMobile={isMobile} />
            </SidePanelDrawer>
          ) : (
            <></>
          )}

          <FlowBox
            sx={{
              transition: "all 1s ease-in-out",
              paddingTop: `${centralTitleBarHeight}px`,

              width: showSidebar
                ? `calc(100% - min(max(${sidebarWidthRatio}%, 200px), 400px))}`
                : "100%",
            }}
          >
            <FilesCenterPanel viewContext={viewContext} isMobile={isMobile} />
          </FlowBox>
        </Box>
      </FlowBox>
    </FilesProvider>
  );
}
