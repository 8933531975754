import React from 'react'
import { SvgIcon } from '@mui/material';

export default function RecordingMarkerIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M74.9922 20.2559C69.9922 15.2559 63.1172 15.506 59.7422 20.881L52.3672 32.5476L75.9922 56.1725L87.6589 48.7977C92.7005 45.631 92.9922 38.2559 88.2422 33.5476"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M46.9973 89.4991L29.2057 91.5824C21.539 92.4574 16.039 86.9576 16.9557 79.3326L21.039 44.8325"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M76.0355 56.124L77.0355 73.2907C77.9938 82.874 74.6605 86.2073 65.5771 87.2906"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M52.3438 32.5423L45.0521 32.084"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.9696 86.6035L35.2196 73.3115"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M45.8099 27.0957C45.8099 28.804 45.5599 30.5124 45.1016 32.0958C44.6433 33.7624 43.9766 35.3041 43.1016 36.7624C42.1016 38.4291 40.8516 39.9291 39.3933 41.1791C36.1016 44.0957 31.8099 45.8457 27.0599 45.8457C24.9349 45.8457 22.9349 45.5124 21.0599 44.8457C16.8099 43.4291 13.2682 40.5124 11.0182 36.7624C9.30991 33.9291 8.30994 30.5957 8.30994 27.0957C8.30994 21.179 11.0183 15.8873 15.3516 12.4707C18.5599 9.88733 22.6433 8.3457 27.0599 8.3457C37.4349 8.3457 45.8099 16.7207 45.8099 27.0957Z"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.178 34.0766V20.0767"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.0043 27.0713H20.0043"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
