import React from "react";
import {
  Paper,
  Typography,
  Box,
  Grid,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import PropTypes from "prop-types";
import { format } from "date-fns";
import {
  useAppContext,
  checkTimeGap,
  useDockets,
  useMeetingsInDockets,
} from "@app21/core";
import ScheduleConference from "blocks/atoms/ScheduleConference";

import LoadingSpinner from "blocks/atoms/LoadingSpinner";
import { useIntl } from "react-intl";
import { useIsMobileBreakpoint } from "hooks";
import toast from "react-hot-toast";
import { getDateTimeFormat } from "utils";

const ShowSchedule = ({ viewMode }) => {
  const { selectedDocketId } = useAppContext();

  const { formatMessage } = useIntl();
  const isMobile = useIsMobileBreakpoint();
  const t = (id) => formatMessage({ id });

  const {
    data: selectedDocket,
    status: selectedDocketStatus,
    apiError: selectedDocketApiError,
  } = useDockets(selectedDocketId);
  const {
    data: docketMeetings,
    status: docketMeetingStatus,
    apiError: docketMeetingsApiError,
  } = useMeetingsInDockets([selectedDocketId]);

  const dataFetchError = docketMeetingsApiError || selectedDocketApiError;
  const dataFetchLoading =
    selectedDocketStatus === "loading" || docketMeetingStatus === "loading";

  React.useEffect(() => {
    if (dataFetchError && !dataFetchLoading) {
      toast.error(dataFetchError.message);
    }
  }, [dataFetchError]);

  const relatedMeeting = docketMeetings && docketMeetings[selectedDocketId];
  const meeting =
    Array.isArray(relatedMeeting) && relatedMeeting.slice(-1)[0]?.meetingJSON;

  const isMeeting = Boolean(meeting?.start || meeting?.end);
  return (
    <div
      style={{
        weight: "100%",
        height: "100%",
        maxWidth: 400,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Grid item direction="column" container spacing={1}>
        {viewMode !== "summary" && (
          <Grid item xs={12}>
            <Paper
              elevation={3}
              sx={{
                display: "flex",
                justifyContent: "center",
                position: "relative",
                height: "100%",
              }}
            >
              <Typography
                variant="h5"
                sx={{ flexGrow: 1, textAlign: "center" }}
              >
                {selectedDocket?.title}
              </Typography>
            </Paper>
          </Grid>
        )}
        {isMeeting ? (
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              minHeight: viewMode === "summary" ? "100%" : 500,
            }}
          >
            <Box
              component={Paper}
              elevation={viewMode !== "summary" ? 3 : 0}
              sx={{
                p: viewMode !== "summary" ? 3 : 0,
                minHeight: viewMode !== "summary" ? 500 : "100%",
                display: "flex",
                justifyContent: "center",
                position: "relative",
                height: "100%",
              }}
            >
              <TableContainer component={Paper} sx={{ width: "100%" }}>
                <Table size={"small"} aria-label="schedule table">
                  <TableBody>
                    <TableRow sx={{ pb: -2 }}>
                      <TableCell
                        variant="head"
                        sx={{
                          maxWidth: 80,
                          background: (theme) =>
                            alpha(theme.palette.primary.light, 0.1),
                        }}
                        scope="row"
                      >
                        Start
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          background: (theme) =>
                            alpha(theme.palette.primary.light, 0.1),
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          {format(
                            new Date(meeting?.start),
                            getDateTimeFormat({
                              startDate: meeting?.start,
                              endDate: meeting?.end ?? null,
                              datePosition: 1,
                              dateContext: "meeting",
                            })
                          )}
                        </span>
                      </TableCell>
                      <TableCell rowSpan={2}>
                        <div
                          style={{
                            display:
                              checkTimeGap(meeting?.start) > 15 ||
                              !(checkTimeGap(meeting?.end) > -60)
                                ? "none"
                                : "flex",
                            // marginLeft: 20,
                            alignSelf: "center",
                            // width: isMobile && '55px',
                          }}
                        >
                          <ScheduleConference
                            type="joinButton"
                            meetingId={relatedMeeting[0]?._id}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        variant="head"
                        sx={{
                          maxWidth: 80,
                          background: (theme) =>
                            alpha(theme.palette.primary.light, 0.1),
                        }}
                        scope="row"
                      >
                        End
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          background: (theme) =>
                            alpha(theme.palette.primary.light, 0.1),
                        }}
                      >
                        {format(
                          new Date(meeting?.end),
                          getDateTimeFormat({
                            startDate: meeting?.start,
                            endDate: meeting?.end ?? null,
                            datePosition: 2,
                            dateContext: "meeting",
                          })
                        )}
                      </TableCell>
                    </TableRow>
                    {/*§<TableRow>
                    <TableCell variant="head" component="th" sx={{maxWidth: 80, background: alpha(theme.palette.primary.light, 0.1)}} scope="row">
                      Invitees
                    </TableCell>
                    <TableCell align="left" sx={{ background: alpha(theme.palette.primary.light, 0.1) }}>
                      <ProfilesChipsList usersList={populatedUserArray} dense={false} showAs="chip" />
                    </TableCell>
                  </TableRow>*/}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
        ) : docketMeetingStatus === "loading" ? (
          <LoadingSpinner size={25} />
        ) : (
          <Typography variant="body1">No meeting has been scheduled</Typography>
        )}
      </Grid>
    </div>
  );
};

ShowSchedule.prototypes = {
  viewMode: PropTypes.string,
};

ShowSchedule.defaultProps = {
  viewMode: "normal",
};

export default ShowSchedule;
