import React from 'react'
import { SvgIcon } from '@mui/material';

export default function VideoCallHoursIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M50.0002 33.3335V54.1668"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.3753 22.75C38.3753 20.2083 44.0003 18.75 50.0003 18.75C70.1253 18.75 86.4586 35.0833 86.4586 55.2083"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M50.0002 91.6675C29.8752 91.6675 13.5419 75.3341 13.5419 55.2091C13.5419 46.9591 16.2919 39.3758 20.8752 33.2925"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.5002 8.3335H62.5002"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M62.0836 77.0824V72.249C62.0836 66.2907 66.3336 63.8324 71.5002 66.8324L75.6669 69.249L79.8336 71.6657C85.0002 74.6657 85.0002 79.5407 79.8336 82.5407L75.6669 84.9574L71.5002 87.374C66.3336 90.374 62.0836 87.9157 62.0836 81.9574V77.0824Z"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
