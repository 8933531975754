import React from 'react'
import { SvgIcon } from "@mui/material";

export default function ArrowSquareDownIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 16 16"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M4.82154 12.3571H8.03604C10.7148 12.3571 11.7863 11.2856 11.7863 8.60684V5.39234C11.7863 2.71359 10.7148 1.64209 8.03604 1.64209H4.82154C2.14279 1.64209 1.07129 2.71359 1.07129 5.39234V8.60684C1.07129 11.2856 2.14279 12.3571 4.82154 12.3571Z"
          stroke="currentColor"
          strokeWidth="0.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.53809 6.27148L6.42928 8.15733L8.32048 6.27148"
          stroke="currentColor"
          strokeWidth="0.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
