import React from "react";
import {
  Box,
  Typography,
  Card,
  CardMedia,
  CardHeader,
  CardActionArea,
} from "@mui/material";
import clip from "text-clipper";

export default function UrlPreviewCard({ urlMeta }) {
  const image =
    urlMeta.og?.image ||
    urlMeta?.meta?.image ||
    (urlMeta?.images && urlMeta.images[0]?.src) ||
    null;
  const title = clip(urlMeta.og?.title || urlMeta?.meta?.title, 55, {
    breakWords: true,
  });
  const url = urlMeta.og?.url || urlMeta?.meta?.url || url;
  const linkUrl = url || urlMeta.og?.url || urlMeta?.meta?.url;
  const description = clip(
    urlMeta.og?.description || urlMeta?.meta?.description,
    130,
    {
      breakWords: true,
    }
  );

  function youtube_parser(url) {
    var regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return match && match[7].length == 11 ? match[7] : false;
  }

  const isYoutubeVideo = React.useMemo(
    () =>
      urlMeta &&
      urlMeta.og?.type &&
      urlMeta.og?.type?.includes("video") &&
      urlMeta.og?.site_name === "YouTube",
    [urlMeta]
  );

  const isImageUrl = React.useMemo(
    () =>
      urlMeta &&
      urlMeta.url &&
      (urlMeta.url.includes(".png") ||
        urlMeta.url.includes(".jpeg") ||
        urlMeta.url.includes(".jpg")),
    [urlMeta]
  );

  const youTubeEmbedLink = React.useMemo(
    () =>
      isYoutubeVideo
        ? `https://www.youtube.com/embed/${youtube_parser(urlMeta.url)}`
        : null,
    [isYoutubeVideo, urlMeta.url]
  );

  if (isImageUrl) {
    return <img style={{ maxWidth: "100%" }} src={urlMeta.url} alt={title} />;
  }

  return (
    <Card
      sx={{
        m: 0,
        p: 0,
        display: "flex",
        borderRadius: 0.5,
        mb: 1,
      }}
    >
      {isYoutubeVideo && youTubeEmbedLink && (
        <iframe style={{ border: "0px" }} controls src={youTubeEmbedLink} />
      )}
      {image && !isYoutubeVideo && (
        <CardMedia
          component="img"
          sx={{ width: "30%" }}
          image={isImageUrl ? urlMeta.url : image}
          alt={title}
        />
      )}

      <CardActionArea
        onClick={() => {
          window.open(linkUrl, "_blank", "noopener noreferrer");
        }}
      >
        <CardHeader
          sx={{ m: 0, p: 1 }}
          title={title}
          subheader={
            <Typography
              variant="caption"
              sx={{ display: "flex", flexDirection: "column" }}
            >
              {description}
              <span style={{ color: "lightgrey", marginTop: 5 }}>
                {clip(url, 100, { breakwords: true })}
              </span>
            </Typography>
          }
        />
      </CardActionArea>
    </Card>
  );
}
