import { useScrollTrigger, Fade, Box, NoSsr, Slide, Link } from "@mui/material";
import { Link as ScrollLink, animateScroll } from "react-scroll";

export default function ScrollTop(props) {
  const {
    children,
    containerElementId = "topbar-landing-page",
    scrollElementId = "back-to-top",
  } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.

  return (
    <a onClick={() => animateScroll?.scrollToTop()}>
      <Box sx={{ position: "fixed", bottom: "1rem", right: "1rem" }}>
        {children}
      </Box>
    </a>
  );
}
