import React from 'react'
import { SvgIcon } from "@mui/material";

export default function MoneyIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 17 16"
      stroke="none"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M1.8335 5.66659C1.8335 3.33325 3.16683 2.33325 5.16683 2.33325H11.8335C13.8335 2.33325 15.1668 3.33325 15.1668 5.66659V10.3333C15.1668 12.6666 13.8335 13.6666 11.8335 13.6666H5.16683C3.16683 13.6666 1.8335 12.6666 1.8335 10.3333V8.35327"
          stroke="currentColor"
          strokeMiterlimit="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5 8C10.5 6.89333 9.60667 6 8.5 6C7.39333 6 6.5 6.89333 6.5 8C6.5 9.10667 7.39333 10 8.5 10"
          stroke="currentColor"
          strokeMiterlimit="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.16667 4H5.16667C4.24667 4 3.5 4.74667 3.5 5.66667V6.66667"
          stroke="currentColor"
          strokeMiterlimit="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.8335 4H11.8335C12.7535 4 13.5002 4.74667 13.5002 5.66667V6.66667"
          stroke="currentColor"
          strokeMiterlimit="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.16667 11.9999H5.16667C4.24667 11.9999 3.5 11.2533 3.5 10.3333V9.33325"
          stroke="currentColor"
          strokeMiterlimit="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.8335 11.9999H11.8335C12.7535 11.9999 13.5002 11.2533 13.5002 10.3333V9.33325"
          stroke="currentColor"
          strokeMiterlimit="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
