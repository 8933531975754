import React from 'react'
import { SvgIcon } from "@mui/material";

export default function GroupChatIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M54.8751 8.3335C34.5417 8.3335 18.0834 23.7085 18.0834 42.6668C18.0834 43.7501 18.1251 44.7918 18.2501 45.8335C21.9584 43.4168 26.4167 42.0419 31.2501 42.0419C43.9167 42.0419 54.1667 51.6251 54.1667 63.4584C54.1667 68.4167 52.3334 73.0002 49.2501 76.6252C51.0834 76.8752 52.9584 77.0001 54.8751 77.0001L72.3334 87.375C75.2917 89.1667 79.0001 86.7501 78.5834 83.2918L76.9584 70.1252C85.8751 63.8752 91.6667 53.9168 91.6667 42.6668C91.6667 29.8751 84.1667 18.7085 73.0001 12.7919"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.2502 45.8326C21.9585 43.4159 26.4168 42.041 31.2502 42.041C43.9168 42.041 54.1668 51.6242 54.1668 63.4575C54.1668 68.4158 52.3335 72.9993 49.2502 76.6243C45.1252 81.6243 38.5835 84.8325 31.2502 84.8325L20.3752 91.2908C18.5418 92.4158 16.2085 90.8742 16.4585 88.7492L17.5001 80.541C11.9168 76.666 8.3335 70.4575 8.3335 63.4575C8.3335 62.2075 8.4585 60.9992 8.66684 59.8325"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
