import React from 'react'
import { SvgIcon } from "@mui/material";

export default function ArrowLeftIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M20.7915 79.7082C28.2915 87.1248 38.6248 91.6665 49.9998 91.6665C72.9998 91.6665 91.6665 72.9998 91.6665 49.9998C91.6665 26.9998 72.9998 8.33317 49.9998 8.33317C26.9998 8.33317 8.33313 26.9998 8.33313 49.9998C8.33313 52.8748 8.6248 55.6665 9.16646 58.4165"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M55.2508 64.7085L40.5841 50.0002L55.2508 35.2918"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
