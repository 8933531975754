import { styled, alpha } from "@mui/material/styles";
import { Typography } from "@mui/material";

export const HeadlineUnderlineEffectTypography = styled(Typography)(
  ({ theme }) => {
    return `
    padding: ${theme.spacing(4)}px;
  background-image: linear-gradient(120deg, ${alpha(
    theme.palette.secondary.lighter,
    0.1
  )} 0%, ${theme.palette.secondary.lighter} 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.07em;
  background-position: 0 98%;
  transition: background-size 0.5s ease-in;
  &:hover {
    background-size: 110% 98%;
  }
`;
  }
);
