import React from 'react'
import { SvgIcon } from '@mui/material';

export default function ChatAllIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M74.9999 29.8335C74.7499 29.7918 74.4582 29.7918 74.2082 29.8335C68.4582 29.6252 63.8749 24.9168 63.8749 19.0835C63.8749 13.1252 68.6665 8.3335 74.6249 8.3335C80.5832 8.3335 85.3749 13.1668 85.3749 19.0835C85.3332 24.9168 80.7499 29.6252 74.9999 29.8335Z"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M70.7084 60.1665C76.4167 61.1249 82.7084 60.1249 87.1251 57.1665C93.0001 53.2499 93.0001 46.8332 87.1251 42.9165C82.6667 39.9582 76.2917 38.9582 70.5834 39.9582"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.8751 29.8335C25.1251 29.7918 25.4168 29.7918 25.6668 29.8335C31.4168 29.6252 36.0001 24.9168 36.0001 19.0835C36.0001 13.1252 31.2085 8.3335 25.2501 8.3335C19.2918 8.3335 14.5001 13.1668 14.5001 19.0835C14.5418 24.9168 19.1251 29.6252 24.8751 29.8335Z"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.1667 60.1665C23.4583 61.1249 17.1667 60.1249 12.75 57.1665C6.875 53.2499 6.875 46.8332 12.75 42.9165C17.2083 39.9582 23.5833 38.9582 29.2917 39.9582"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M49.9999 60.958C49.7499 60.9164 49.4582 60.9164 49.2082 60.958C43.4582 60.7497 38.8749 56.0413 38.8749 50.208C38.8749 44.2497 43.6665 39.458 49.6249 39.458C55.5832 39.458 60.3749 44.2913 60.3749 50.208C60.3332 56.0413 55.7499 60.7914 49.9999 60.958Z"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M62.1248 74.0835C55.4998 69.6668 44.5414 69.6668 37.8748 74.0835C31.9998 78.0002 31.9998 84.4168 37.8748 88.3335C44.5414 92.7918 55.4581 92.7918 62.1248 88.3335"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
