import React from "react";
import { FlowBox } from "blocks/atoms/uistyles";
import { Button, Typography } from "@mui/material";
import { useAtom } from "jotai";
import { useAppContext, useHandleRouterPush } from "@app21/core";
import { useTasksContext } from "providers/TasksProvider";

export default function ShowEmptyTasksComponent() {
  const { isDocket, loadRoute, tasksPermissions, selectedDocketId } =
    useTasksContext();

  return (
    <FlowBox sx={{ justifyContent: "center", alignItems: "center" }}>
      <Typography variant="h6" sx={{ color: "text.secondary" }}>
        {isDocket
          ? "No Tasks in this Breakout. Get Started by creating a new Task"
          : "There are no Tasks. Get Started by creating a new Task"}
      </Typography>
      {tasksPermissions?.canAddNew ? (
        isDocket ? (
          <Button
            variant="contained"
            onClick={() =>
              loadRoute("CREATE-TASK", { docketId: selectedDocketId })
            }
            sx={{ mt: 3 }}
          >
            Create a Task
          </Button>
        ) : (
          <Button
            onClick={() => loadRoute("CREATE-TASK", {})}
            variant="outlined"
            color="primary"
            sx={{ mt: 2 }}
          >
            Create Task
          </Button>
        )
      ) : null}
    </FlowBox>
  );
}
