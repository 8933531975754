import React from 'react'
import { SvgIcon } from '@mui/material';

export default function CopyLinkIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M62.4512 72.9158H68.7428C81.3262 72.9158 91.6595 62.6242 91.6595 49.9992C91.6595 37.4159 81.3678 27.0825 68.7428 27.0825H62.4512"
          stroke="currentColor"
          strokeWidth="5.72917"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.87504 41.709C8.87504 44.2923 8.33337 47.084 8.33337 50.0006C8.33337 62.584 18.625 72.9173 31.25 72.9173H37.5"
          stroke="currentColor"
          strokeWidth="5.72917"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.4988 27.0825H31.2488C27.7904 27.0825 24.5404 27.8325 21.5821 29.2075"
          stroke="currentColor"
          strokeWidth="5.72917"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.3334 49.999H66.6667"
          stroke="currentColor"
          strokeWidth="5.72917"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
