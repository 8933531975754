// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import VideoCarouselPlayer from "blocks/atoms/players/VideoCarouselPlayer";
import {
  useChimeRecordings,
  useSelectedBucketId,
  useAppContext,
  executeApi,
} from "@app21/core";

export default function PlaySelectedRecordings({
  meetingId,
  timeSlice,
  videoCarouselOpen,
  handleCarouselClose,
}) {
  const { selectedOrganizationId, selectedSuiteId } = useAppContext();
  const selectedBucketId = useSelectedBucketId(null, true);

  const [recordingUrls, setRecordingUrls] = React.useState([]);

  const { data: fetchedChimeRecordingKeys } = useChimeRecordings({
    meetingId,
    selectedOrganizationId,
    selectedSuiteId,
    selectedBucketId,
  });

  React.useEffect(() => {
    let listOfKeys = [];
    let listOfUrls = [];
    //arr.sort((a, b) => b > a ? -1 : 1)
    fetchedChimeRecordingKeys
      ?.sort((a, b) => {
        const { Key: aKey } = a;
        const { Key: bKey } = b;
        return bKey > aKey ? -1 : 1;
      })
      .map((item) => {
        const fileNameArray = item.Key.split("/");
        const fileName = fileNameArray[fileNameArray.length - 1].split(".")[0];
        const fileNameToDate = Date.parse(...fileName.split("-"));
        fileNameToDate > timeSlice - 15000 &&
          fileNameToDate < timeSlice + 15000 &&
          listOfKeys.push(item.Key);
      });

    const fetchKeys = async (listOfKeys) => {
      const response = await executeApi("FETCH-SIGNED-URL", {
        bucket: selectedBucketId,
        keyList: listOfKeys,
        urlTypeRequested: "getlist",
        contentType: "video/mp4",
      });
      response.signedUrlList.map((urlEntry) => {
        listOfUrls.push(urlEntry.url);
      });
      setRecordingUrls(listOfUrls);
    };
    fetchedChimeRecordingKeys && listOfKeys.length > 0 && fetchKeys(listOfKeys);
  }, [fetchedChimeRecordingKeys, selectedBucketId, timeSlice]);

  const onCarouselClose = () => {
    handleCarouselClose();
  };

  return (
    <VideoCarouselPlayer
      videoFileArray={recordingUrls}
      open={videoCarouselOpen}
      handleClose={onCarouselClose}
    />
  );
}
