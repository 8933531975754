import React from 'react'
import { SvgIcon } from '@mui/material';

export default function MicOffIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M66.6667 26.2502V25.0002C66.6667 15.7918 59.2083 8.3335 50 8.3335C40.7917 8.3335 33.3333 15.7918 33.3333 25.0002V45.8335"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.6667 59.1252C40.7084 62.5002 45.1251 64.5835 50.0001 64.5835C59.2084 64.5835 66.6667 57.1252 66.6667 47.9168V45.8335"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M78 62.583C80.5 58.0413 81.875 52.833 81.875 47.2913V40.208"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.25 70.626C33.9583 75.9176 41.5833 79.1676 50 79.1676C54.9167 79.1676 59.625 78.0426 63.7917 76.0426"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.125 40.208V47.2913C18.125 51.708 19 55.8747 20.625 59.708"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M83.625 11.8325L16.375 79.1242"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M45.8333 12.5V25"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M50 79.1665V91.6665"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
