import React from 'react'
import { SvgIcon } from '@mui/material';

export default function FolderFilesIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        {/* <path
          d="M23.4782 66.478H78.7825L81.3912 66.9998V86.8259L72.5217 94.1302H21.3912L15.1304 84.7389V72.2172L23.4782 66.478Z"
          fill-opacity="0.2"
        /> */}
        {/* <path
          d="M84.5217 33.0868V68.5651L53.4492 66.9999H21.8589L14.6086 72.739V48.2172V22.652L23.9304 14.3042H42.056H70.0212L79.8608 20.0433L84.5217 33.0868Z"
        /> */}
        <path
          d="M85.4176 52.833V77.833C85.4176 78.4163 85.4176 78.9997 85.376 79.583"
          stroke="currentColor"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.5837 81.9997V36.1663C14.5837 19.4997 18.7504 15.333 35.4171 15.333H64.5837C81.2504 15.333 85.4171 19.4997 85.4171 36.1663"
          stroke="currentColor"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.4587 69.5H85.4171V84.0833C85.4171 92.125 78.8754 98.6667 70.8337 98.6667H29.1671C21.1254 98.6667 14.5837 92.125 14.5837 84.0833V81.375C14.5837 74.8333 19.9171 69.5 26.4587 69.5Z"
          stroke="currentColor"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.3337 36.167H66.6671"
          stroke="currentColor"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.3337 50.75H54.1671"
          stroke="currentColor"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.17651 27.1382L27.7467 3.56796"
          stroke="currentColor"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.7466 27.1382L4.17636 3.56796"
          stroke="currentColor"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
