// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import {
  useAppContext,
  useMeetingsInDockets,
  useTranscript,
} from "@app21/core";
import LoadingSpinner from "blocks/atoms/LoadingSpinner";
import { ShowAllTranscriptionsForMeeting } from "blocks/modules/MeetingRecords";
import toast from "react-hot-toast";

const ShowRecords = () => {
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });

  const { selectedDocketId } = useAppContext();
  //const { data: selectedDocket } = useDockets(selectedDocketId);
  const {
    data: docketMeetings,
    status: docketMeetingsStatus,
    apiError: docketMeetingsApiError,
  } = useMeetingsInDockets(selectedDocketId);

  const {
    data: transcriptions,
    status: transcriptsStatus,
    apiError: transcriptionsApiError,
  } = useTranscript({
    meetingId: docketMeetings?.length > 0 ? docketMeetings[0]._id : null,
  });
  const dataFetchError = docketMeetingsApiError || transcriptionsApiError;
  const dataFetchLoading =
    transcriptsStatus === "loading" || docketMeetingsStatus === "loading";

  React.useEffect(() => {
    if (dataFetchError && !dataFetchLoading) {
      toast.errorror(dataFetchError.message);
    }
  }, [dataFetchError]);

  if (docketMeetingsStatus === "loading" || transcriptsStatus === "loading") {
    return <LoadingSpinner size={25} />;
  } else {
    return (
      <Box
        sx={{
          p: 2,
          my: 2,
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {/*<ShowTranscript />*/}
        <ShowAllTranscriptionsForMeeting
          viewContext={"docket"}
          selectedMeetingId={
            docketMeetings?.length > 0 ? docketMeetings[0]._id : null
          }
          transcriptions={transcriptions}
          meeting={docketMeetings?.length > 0 ? docketMeetings[0] : null}
        />
      </Box>
    );
  }
};

export default ShowRecords;
