import React from "react";
import {
  Box,
  Typography,
  Button,
  Paper,
  Toolbar,
  IconButton,
  Stack,
} from "@mui/material";
import { FlowBox } from "blocks/atoms/uistyles";
import { useIsMobileBreakpoint } from "hooks";
import { useAtom } from "jotai";
import { showFilesSidebarFlagAtom } from "providers/FilesProvider/files-atoms";
import { ReadMore, Refresh } from "@mui/icons-material";
import {
  FileActionsDropdown,
  UploadActionsDropdown,
  FilePathCrumbs,
} from "blocks/modules/TrrystFiles/foundations";
import { useFilesContext } from "providers/FilesProvider";
import { checkIfActionsAllowedPath } from "./checkIfActionsAllowedPath";

export default function FilesMenubar({ isMobile = false }) {
  const { handleRefreshFilesData, folderChain } = useFilesContext();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexGrow: 1,
        height: "fit-content",
        py: 1,
        pr: 1,
      }}
    >
      <Toolbar
        variant="dense"
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          flexGrow: 1,
          alignItems: "center",
        }}
      >
        <FilePathCrumbs isMobile={isMobile} />
        <Stack
          spacing={isMobile ? 1 : 3}
          direction="row"
          sx={{ display: "flex", alignItems: "center" }}
        >
          {checkIfActionsAllowedPath(folderChain) ? (
            <>
              <FileActionsDropdown />
              <UploadActionsDropdown />
            </>
          ) : null}
          <IconButton size="small" onClick={handleRefreshFilesData}>
            <Refresh fontSize="inherit" />
          </IconButton>
        </Stack>
      </Toolbar>
    </Box>
  );
}
