import React from 'react'
import { SvgIcon } from "@mui/material";

export default function DeleteIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 17 17"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M14.4233 4.47553C12.2033 4.25191 9.97001 4.13672 7.74334 4.13672C6.42334 4.13672 5.10334 4.20448 3.78334 4.34L2.42334 4.47553"
          stroke="currentColor"
          strokeWidth="0.901367"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.08984 3.79183L6.23651 2.90415C6.34318 2.26041 6.42318 1.7793 7.54984 1.7793H9.29651C10.4232 1.7793 10.5098 2.28751 10.6098 2.91092L10.7565 3.79183"
          stroke="currentColor"
          strokeWidth="0.901367"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5631 15.3314H6.28311C4.42311 15.3314 4.36311 14.5047 4.28978 13.4408L3.85645 6.61719"
          stroke="currentColor"
          strokeWidth="0.901367"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.9905 6.61719L12.5571 13.4408"
          stroke="currentColor"
          strokeWidth="0.901367"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.30957 11.6035H9.52957"
          stroke="currentColor"
          strokeWidth="0.901367"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.97021 8.89453H10.0902"
          stroke="currentColor"
          strokeWidth="0.901367"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.75684 8.89453H7.31017"
          stroke="currentColor"
          strokeWidth="0.901367"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
