// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { useAppContext, useSuite, useHandleRouterPush } from "@app21/core";

import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Divider,
  useTheme,
  Tooltip,
  Box,
} from "@mui/material";
import { ArrowCircleRightOutlined, Info } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";
import chartConfig from "blocks/atoms/constants/mongodbChart";
import { TopupRequestButton } from "blocks/modules/Subscriptions";
import UsageBar from "react-usage-bar";
import { useQueryClient } from "react-query";
import MongodbChartRender from "blocks/atoms/Charts/MongodbChartRender";
import { MongodbChartProvider } from "providers/mongodb/Chart";

const SuiteCreditDetailsPanel = () => {
  const { selectedSuiteId } = useAppContext();
  const { status, data: selectedSuiteInfo } = useSuite(selectedSuiteId);
  const [chartRawData, setChartRawData] = React.useState({});
  const { loadRoute } = useHandleRouterPush();
  const handleChartRawData = (data) => {
    setChartRawData(data);
  };
  const theme = useTheme();

  const getFieldInfo = (field) => {
    switch (field.group) {
      case "UNUSED-CREDITS":
        return { name: "Unused Credits", color: theme.palette.grey.lighter };
      case "SUBSCRIPTION-ADDED":
        return { name: "Total Credits", color: theme.palette.primary.lighter };
      case "AI":
        return { name: "AI Credits", color: theme.palette.secondary.lighter };
      case "STORAGE":
        return { name: "Storage", color: theme.palette.info.light };
      case "USER":
        return { name: "Users", color: theme.palette.warning.light };
      case "MEETINGS":
        return { name: "Meetings", color: theme.palette.secondary.light };
      case "RECORDING":
        return { name: "Recordings", color: theme.palette.error.light };
      case "TRANSCRIPTION":
        return {
          name: "Transcription",
          color: theme.palette.accent.lighter,
        };
      default:
        return {
          name: "Others",
          color: theme.palette.grey.lighter,
        };
    }
  };

  let data = chartRawData?.documents;
  const creditsUsageDisplayData = React.useMemo(() => {
    let creditsArray = [];
    let usedCredits = 0;
    let unusedCredits = 0;

    if (Array.isArray(chartRawData?.documents)) {
      usedCredits =
        chartRawData?.documents
          ?.filter((field) => field.group !== "SUBSCRIPTION-ADDED")
          ?.map((field) => field.value)
          ?.reduce((prev, curr) => prev + curr, 0) ?? 0;

      unusedCredits =
        (chartRawData?.documents?.find(
          (field) => field.group === "SUBSCRIPTION-ADDED"
        )?.value ?? 0) - usedCredits;
      chartRawData.documents
        ?.filter((field) => field.group !== "SUBSCRIPTION-ADDED")
        .forEach((field) => {
          let obj = {};

          let calFields = getFieldInfo(field);
          obj.value = field.value;
          obj.name = calFields.name;
          obj.color = calFields.color;
          creditsArray.push(obj);
        });
      creditsArray.push({
        name: "Unused Credits",
        value: unusedCredits,
        color: theme.palette.success.light,
      });
    }

    return creditsArray;
  }, [data]);

  return (
    <MongodbChartProvider>
      <Card sx={{ p: 0 }}>
        <CardHeader
          title="Suite Credits Usage"
          action={
            <>
              <TopupRequestButton
                variant="outlined"
                size="small"
                label="TopUp"
              />
              <IconButton
                onClick={() =>
                  loadRoute("MANAGE-SUITE", { tabValue: "creditsusage" })
                }
                size="small"
              >
                <ArrowCircleRightOutlined />
              </IconButton>
            </>
          }
          sx={{ pt: 1 }}
        />

        <Divider sx={{ mt: 1 }} />
        <CardContent
          sx={{ display: "flex", alignItems: "center", p: 0, px: 1, m: 0 }}
        >
          {status === "success" && selectedSuiteInfo && (
            <Box sx={{ maxWidth: 10, maxHeight: 10 }}>
              <MongodbChartRender
                name="Overall Credit Usage Pie Chart"
                style={{ height: "1px", width: "1px" }}
                chartId={chartConfig.CREDIT.mainAnalyticsBar}
                handleChartRawData={handleChartRawData}
                filters={{
                  suiteFilter: {
                    render: false,
                    value: [selectedSuiteInfo],
                  },
                }}
              />
            </Box>
          )}

          <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
            <UsageBar
              showPercentage
              compactLayout
              items={creditsUsageDisplayData}
              total={
                chartRawData?.documents?.find(
                  (field) => field.group === "SUBSCRIPTION-ADDED"
                )?.value ?? 0
              }
            />
          </Box>
        </CardContent>
      </Card>
    </MongodbChartProvider>
  );
};

export default SuiteCreditDetailsPanel;
