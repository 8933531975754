// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import PropTypes from "prop-types";
import { Close } from "@mui/icons-material";
// material
import { styled } from "@mui/material/styles";
import axios from "axios";
import toast from "react-hot-toast";
import { SuiteSwitchButton } from "blocks/views/SuiteDashboard/foundations";
import {
  Box,
  Stack,
  Button,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  IconButton,
  Grow,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Tabs,
  Tab,
  Toolbar,
  Typography,
} from "@mui/material";
// utils
import {
  checkIfNull,
  useAppContext,
  useOrgsAndSuitesForUser,
} from "@app21/core";
//
import UploadMultiFileComponent from "./UploadMultiFileComponent";
import CopyFromAnotherSuite from "./CopyFromAnotherSuite";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useConfirm } from "material-ui-confirm";
import { getSuiteAndOrganizationLabel } from "utils";
import { useCopyFilesS3, useSelectedBucketId } from "@app21/core";
import { fileUploadProcessor } from "utils/fileReaderHelpers";
import { useIsMobileBreakpoint } from "hooks";

const StyledTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.text.secondary,
  cursor: "pointer",
  fontFamily: theme.typography.fontFamily,
  fontWeight: theme.typography.fontWeightNormal,
  fontStyle: theme.typography.body1.fontSize,
  backgroundColor: "transparent",
  width: "100%",
  padding: "12px 16px",
  margin: "6px 6px",
  border: "none",
  borderRadius: "5px",
  display: "flex",
  justifyContent: "center",

  "&:hover": {
    backgroundColor: theme.palette.primary.lighter,
    color: theme.palette.primary.contrastText,
  },

  "&:focus": {
    borderRadius: "3px",
    outline: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.lighter,
    color: theme.palette.primary.darker,
    outlineOffset: "2px",
  },
  "&.Mui-selected": {
    backgroundColor: `${theme.palette.primary.main}`,
    color: theme.palette.primary.contrastText,
    borderRadius: 0,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return value === index ? (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
      }}
      {...other}
    >
      {children}
    </div>
  ) : null;
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: 5,
  width: "100%",
  height: 700,
  minHeight: 500,
  minWidth: 300,
  padding: "0px 10px",
  maxWidth: "max(70vw, 350px)",
  maxHeight: `max(${theme.breakpoints.down("md") ? 70 : 50}vh, 500px)`,
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
}));

UploadOmniModal.propTypes = {
  error: PropTypes.bool,
  showPreview: PropTypes.bool,
  showNotify: PropTypes.bool,
  open: PropTypes.bool,
  files: PropTypes.array,
  onSubmit: PropTypes.func,
  handleNotify: PropTypes.func,
  title: PropTypes.any,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  onClose: PropTypes.func,
  sx: PropTypes.object,
};

export default function UploadOmniModal(props) {
  const {
    files = [],
    sx = {},
    maxSize,
    onComplete,
    title = "Add Files",
    onClose = () => null,
    showNotify = false,
    handleNotify,
    bucket,
    s3Prefix,
    open,
    initialOption = "upload_files",
    checkFileNamesOverlap = () => false,
    showUploadFolderOption = true,
    prefixTimestampToFilename = false,
    ...rest
  } = props;
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [value, setValue] = React.useState(initialOption);
  const [uploadFiles, setUploadFiles] = React.useState(files);
  const [totalFilesUploaded, setTotalFilesUploaded] = React.useState(0);
  const [notifyPreference, setNotifyPreference] = React.useState(false);
  const [uploadingActive] = React.useState(false);
  const { userInfo } = useAppContext();
  const { data: orgsAndSuites } = useOrgsAndSuitesForUser(userInfo?._id);
  const [keyToURLMap, setKeyToURLMap] = React.useState();
  const [sourceOrganization, setSourceOrganization] = React.useState(null);
  const [sourceSuite, setSourceSuite] = React.useState(null);

  const selectedBucketId = useSelectedBucketId(null, true);
  const copyFilesS3 = useCopyFilesS3();
  const confirm = useConfirm();
  const isMobile = useIsMobileBreakpoint();

  React.useEffect(() => {
    setValue(initialOption);
  }, [initialOption]);

  React.useEffect(() => {
    let personalOrg =
      orgsAndSuites?.find((org) => org.fullName?.includes("My Organization")) ??
      (orgsAndSuites && orgsAndSuites[0]);
    setSourceSuite(personalOrg?.suitesInOrg && personalOrg?.suitesInOrg[0]);
    setSourceOrganization(personalOrg);
  }, [orgsAndSuites]);

  React.useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      Array.isArray(uploadFiles) &&
        uploadFiles?.forEach((file) => URL.revokeObjectURL(file?.preview));
    },
    [uploadFiles]
  );

  React.useEffect(() => {
    if (uploadFiles.length === 0) return;
    if (totalFilesUploaded === uploadFiles.length) {
      toast.success(`${totalFilesUploaded} file(s) uploaded successfully`);

      if (onComplete) onComplete(uploadFiles);
      if (onClose) onClose();
    }
  }, [onClose, onComplete, totalFilesUploaded, uploadFiles]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleFileCopyPaste = (event) => {
    if (event?.clipboardData?.files?.length) {
      const { files = [] } = event.clipboardData;
      setUploadFiles((oldFiles) => [
        ...oldFiles,
        ...fileUploadProcessor(files),
      ]);
    }
  };

  const handleMultipleFilesDrop = (data) => {
    setUploadFiles((oldFiles) => [
      ...oldFiles,
      ...fileUploadProcessor(data, prefixTimestampToFilename),
    ]);
  };

  const handleFileRemove = (index) => {
    setUploadFiles((oldFiles) => oldFiles.filter((value, i) => i !== index));
  };

  const handleClose = (event) => {
    setUploadFiles([]);
    onClose();
  };
  const handleSourceSuiteSwitch = (suiteId) => {
    orgsAndSuites?.some((org) => {
      let foundSuite = org?.suitesInOrg?.find((suite) => suite._id === suiteId);
      if (foundSuite) {
        setSourceSuite(foundSuite);
        setSourceOrganization(org);
        return true;
      } else return false;
    });
  };

  const handleSubmitUploadFiles = React.useCallback(
    async (action) => {
      const completeUpload = async () => {
        const list = uploadFiles.map((file) => ({
          bucket,
          key: `${s3Prefix}${decodeURIComponent(file.path)}`,
          contentType: file.type,
          calledBy: userInfo?._id,
          contentDisposition: "attachment",
          urlTypeRequested: "post",
        }));

        const { data } = await axios.post(
          `${process.env.AWS_APIPATH}/s3/fetchurl`,
          { list },
          {
            headers: {
              "Content-Type": "application/json",
            },
            responseType: "json",
          }
        );

        if (data?.result) {
          const map = new Map();
          data.result.forEach((e) => map.set(e?.key?.key, e?.key?.signedURL));

          setKeyToURLMap(map);
        }
        if (showNotify) {
          handleNotify(notifyPreference);
        }
      };

      const completeCopy = async () => {
        await copyFilesS3.mutate({
          fileList: selectedItems,
          bucket: selectedBucketId,
          targetPrefix: s3Prefix,
          s3Prefix: s3Prefix,
        });
        onClose();
      };

      if (action === "Upload") {
        if (uploadFiles && uploadFiles.length > 0) {
          const uploadFileNamesList = uploadFiles.map((file) => file.name);
          const checkFileNamesOverlapFlag =
            checkFileNamesOverlap(uploadFileNamesList);
          if (checkFileNamesOverlapFlag) {
            confirm({
              description:
                "There are files in the current directory that have the same filename as the ones you are uploading. If you proceed, those files will be overwritten. Please confirm to proceed?",
            })
              .then(async () => await completeUpload())
              .catch(() => {
                console.log("Upload cancelled.");
              })
              .finally(() => null);
          } else await completeUpload();
        } else onClose();
      } else if (action === "Copy") {
        completeCopy();
      }
    },
    [bucket, s3Prefix, uploadFiles, selectedItems, userInfo._id]
  );

  const handleSingleFileUploadComplete = React.useCallback(
    () => setTotalFilesUploaded((e) => e + 1),
    []
  );
  return (
    <Box
      sx={{
        position: "absolute",
        right: isMobile ? 10 : 20,
        bottom: isMobile ? 10 : 20,
      }}
      {...sx}
    >
      <Grow in={open}>
        <StyledCard>
          <CardHeader
            id="dragModal"
            title={title}
            action={
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            }
          />
          <PerfectScrollbar
            options={{ suppressScrollX: true }}
            style={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                alignItems: "stretch",
                px: 0,
              }}
            >
              <Box sx={{ width: 200, minWidth: 150 }}>
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleTabChange}
                  aria-label="File Upload Options"
                >
                  <StyledTab
                    label="Files Upload"
                    value={"upload_files"}
                    {...a11yProps(0)}
                  />
                  {Boolean(showUploadFolderOption) && (
                    <StyledTab
                      label="Folder Upload"
                      value={"upload_folder"}
                      {...a11yProps(1)}
                    />
                  )}
                  <StyledTab
                    label="Copy from Another Suite"
                    value={"copy_from_suite"}
                    {...a11yProps(2)}
                  />
                  <StyledTab
                    label="Google Drive"
                    value={"copy_from_gdrive"}
                    disabled
                    {...a11yProps(3)}
                  />
                  <StyledTab
                    label="DropBox"
                    value={"copy_from_dropbox"}
                    disabled
                    {...a11yProps(4)}
                  />
                </Tabs>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  pl: 1,
                }}
              >
                <TabPanel value={value} index={"upload_files"}>
                  <UploadMultiFileComponent
                    onFileCopyPaste={handleFileCopyPaste}
                    onDrop={handleMultipleFilesDrop}
                    onRemove={handleFileRemove}
                    loading={uploadingActive}
                    showPreview
                    maxSize={maxSize}
                    files={uploadFiles}
                    bucket={bucket}
                    s3Prefix={s3Prefix}
                    keyToURLMap={keyToURLMap}
                    onComplete={handleSingleFileUploadComplete}
                    {...rest}
                  />
                </TabPanel>
                <TabPanel value={value} index={"upload_folder"}>
                  <UploadMultiFileComponent
                    uploadContext="folder"
                    onDrop={handleMultipleFilesDrop}
                    onRemove={handleFileRemove}
                    loading={uploadingActive}
                    showPreview
                    maxSize={maxSize}
                    files={uploadFiles}
                    bucket={bucket}
                    s3Prefix={s3Prefix}
                    keyToURLMap={keyToURLMap}
                    onComplete={handleSingleFileUploadComplete}
                    {...rest}
                  />
                </TabPanel>
                <TabPanel value={value} index={"copy_from_suite"}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Toolbar>
                      <Typography variant="h6" id="tableTitle" sx={{ mr: 2 }}>
                        {getSuiteAndOrganizationLabel(sourceSuite)}
                      </Typography>
                      <SuiteSwitchButton
                        viewOption="textChip"
                        label="Change"
                        showSameSuite
                        handleSuiteSwitchClick={(id) =>
                          handleSourceSuiteSwitch(id)
                        }
                      />
                    </Toolbar>
                    <CopyFromAnotherSuite
                      selectedItems={selectedItems}
                      setSelectedItems={setSelectedItems}
                      onDrop={handleMultipleFilesDrop}
                      onRemove={handleFileRemove}
                      sourceSuite={sourceSuite}
                      sourceOrganization={sourceOrganization}
                      loading={uploadingActive}
                      showPreview
                      maxSize={maxSize}
                      files={uploadFiles}
                      bucket={bucket}
                      s3Prefix={s3Prefix}
                      keyToURLMap={keyToURLMap}
                      {...rest}
                    />
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={"copy_from_gdrive"}>
                  Hidden for now
                </TabPanel>
                <TabPanel value={value} index={"copy_from_dropbox"}>
                  Hidden for Now
                </TabPanel>
              </Box>
            </CardContent>
          </PerfectScrollbar>

          <CardActions sx={{ display: "flex", alignItems: "center" }}>
            {showNotify && (
              <FormControlLabel
                sx={{ maxWidth: 300 }}
                label="Notify other suite users of upload"
                control={
                  <Checkbox
                    checked={notifyPreference}
                    onChange={() => setNotifyPreference(!notifyPreference)}
                  />
                }
              />
            )}
            <Stack
              sx={{ flexGrow: 1 }}
              direction="row"
              justifyContent="flex-end"
            >
              <Button onClick={handleClose} sx={{ mr: 1.5 }}>
                Close
              </Button>
              <Button
                variant="contained"
                disabled={uploadingActive}
                onClick={() =>
                  handleSubmitUploadFiles(
                    value !== "copy_from_suite" ? "Upload" : "Copy"
                  )
                }
              >
                {uploadingActive ? (
                  <CircularProgress color="secondary" size={20} />
                ) : value !== "copy_from_suite" ? (
                  "Upload"
                ) : (
                  "Copy"
                )}
              </Button>
            </Stack>
          </CardActions>
        </StyledCard>
      </Grow>
    </Box>
  );
}
