// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";

import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  Tooltip,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import isEmpty from "validator/lib/isEmpty";
import { SortRecordingsPopover, FilterRecordingsPopover } from "..";

const RecordingsSearchbar = ({
  filteredMeetings,
  useMeetingsInSuiteStatus,
  setSearchContent,
  searchContent,
  handleCloseTranscript,
  sortOrder,
  handleSortOrderChange,
}) => {
  const [searchContentInput, setSearchContentInput] = React.useState("");

  const handleOnSearchClick = React.useCallback(
    () => setSearchContent(searchContentInput),
    [searchContentInput]
  );

  const handleSearchContentChange = (event) => {
    event.target.value != ""
      ? setSearchContentInput(event.target.value)
      : handleResetSearchContent();
  };
  const handleSearchContentKeypress = (event) => {
    if (
      event.keyCode === 13 &&
      !isEmpty(searchContentInput || "", { ignore_whitespace: true })
    ) {
      handleOnSearchClick();
      handleCloseTranscript();
    }
  };
  const handleResetSearchContent = () => {
    setSearchContentInput("");
    setSearchContent(null);
  };

  return (
    <>
      <Box sx={{ my: 2 }}>
        {(filteredMeetings.length > 0 || searchContent) && (
          <Box
            sx={{
              display: "flex",
              alignItems: "stretch",
            }}
          >
            <TextField
              value={searchContentInput}
              onChange={handleSearchContentChange}
              onKeyUp={handleSearchContentKeypress}
              sx={{ flexGrow: 7, width: "100%" }}
              label="Search Meeting Transcripts"
              variant="outlined"
              fullWidth
              size="small"
              InputProps={{
                endAdornment: !isEmpty(searchContentInput || "", {
                  ignore_whitespace: true,
                }) ? (
                  <InputAdornment position="end">
                    <Tooltip title="Clear Search">
                      <IconButton
                        aria-label="clear search input"
                        onClick={handleResetSearchContent}
                        onMouseDown={handleResetSearchContent}
                        edge="end"
                        size="small"
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ) : null,
              }}
            />

            {/* <SortRecordingsPopover
              sortOrder={sortOrder}
              handleSortOrderChange={handleSortOrderChange}
            />
            <FilterRecordingsPopover /> */}
          </Box>
        )}
      </Box>

      {searchContent && useMeetingsInSuiteStatus !== "loading" ? (
        <Typography variant="body1" sx={{ ml: 1 }}>
          {filteredMeetings.length
            ? `${filteredMeetings.length} meeting(s) found with transcript records containing the searched text`
            : "There are no meetings found with that search string"}
        </Typography>
      ) : null}
    </>
  );
};

export default RecordingsSearchbar;
