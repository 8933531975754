import { SvgIcon } from "@mui/material";
import React from "react";
export default function VideoCallActiveIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 56 56"
      stroke="#FAD2A0"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <rect
          width="56"
          height="56"
          stroke="none"
          rx="5.6"
          fill="currentColor"
        />
        <path
          d="M14 22.1059C14 17.6819 15.47 16.2119 19.894 16.2119H28.742C33.166 16.2119 34.636 17.6819 34.636 22.1059V33.8939C34.636 38.3179 33.166 39.7879 28.742 39.7879H19.894C15.47 39.7879 14 36.8479 14 33.8939V28.1679"
          strokeWidth="2.1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M38.5287 35.1402L34.6367 32.4102V23.5762L38.5287 20.8462C40.4327 19.5162 42.0007 20.3282 42.0007 22.6662V33.3342C42.0007 35.6722 40.4327 36.4842 38.5287 35.1402Z"
          strokeWidth="2.1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.4002 24.5004C29.4002 23.3384 28.4622 22.4004 27.3002 22.4004C26.1382 22.4004 25.2002 23.3384 25.2002 24.5004C25.2002 25.6624 26.1382 26.6004 27.3002 26.6004"
          strokeWidth="2.1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
