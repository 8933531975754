// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Autocomplete, TextField, Avatar, Box } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";

import { DocketLabel, ProfilesChipsList } from "blocks/atoms";
import { createSelectOptions, checkIfNull } from "@app21/core";
import isEmail from "validator/lib/isEmail";

import PropTypes from "prop-types";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useFormContext } from "react-hook-form";

import DraggablePaper from "blocks/atoms/uicomponents/DraggablePaper";
import useIsMobileBreakpoint from "hooks/useIsMobileBreakpoint";

const blankCheckIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MuiSelectDocketFormField = (props) => {
  const {
    refs,
    label,
    listOfDockets,
    placeholder,
    variant,
    onChange,
    name,
    inputValue,
    helperText,
    ...rest
  } = props;
  const [inputText, setInputText] = React.useState(inputValue ?? "");
  const { watch, control, setValue, getValues, formState, setError } =
    useFormContext();
  const { errors } = formState;
  const isMobile = useIsMobileBreakpoint();
  const handleInputChange = (event, value, reason) => {
    setInputText(value);
  };
  const handleDocketSelect = (event, selectedOption, reason) => {
    onChange(selectedOption?.value ?? null);
  };
  const formatOptionLabel = ({ fullName, avatar, image, key }) => (
    <div key={key} style={{ display: "flex", alignItems: "center", margin: 3 }}>
      <Avatar src={avatar || image} />
      <div style={{ marginLeft: 10 }}>{fullName}</div>
    </div>
  );

  let allOptions =
    listOfDockets?.map((docket) => ({
      value: docket._id,
      label: docket.title,
      members: docket.members,
    })) ?? [];
  return (
    <>
      <Autocomplete
        {...rest}
        autoHighlight
        multiple={false}
        onInputChange={handleInputChange}
        onChange={handleDocketSelect}
        ref={refs}
        inputValue={inputText}
        selectOnFocus
        getOptionLabel={(option) =>
          typeof option === "object"
            ? option.label
            : allOptions?.find((docket) => docket.value === option)?.label ||
              "Error"
        }
        options={allOptions}
        isOptionEqualToValue={(option, value) => option.value === value}
        renderOption={(props, option) => {
          return (
            <Box
              component="li"
              sx={{
                my: 0.5,
                display: "flex",
                justifyContent: "space-between",
                "& > img": { mr: 2, flexShrink: 0 },
              }}
              {...props}
            >
              {<DocketLabel id={option.value} title={option.label} />}
              <ProfilesChipsList
                usersList={option?.members ?? []}
                dense={true}
                size={25}
                showAs={"avatar"}
                maxItems={isMobile ? 4 : 7}
              />
            </Box>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            variant={variant}
            label={label}
            placeholder={placeholder}
            InputLabelProps={{}}
            error={!!(errors && rest && errors[rest.name])}
            helperText={
              helperText ||
              (errors &&
                rest &&
                Boolean(errors[rest.name]?.message) &&
                errors[rest.name]?.message)
            }
          />
        )}
      />
    </>
  );
};

MuiSelectDocketFormField.propTypes = {
  onChange: PropTypes.func,
  listOfDockets: PropTypes.array,
  placeholder: PropTypes.string,
  variant: PropTypes.oneOf(["outlined", "contained", "default"]),
  label: PropTypes.string,
};

MuiSelectDocketFormField.defaultProps = {
  variant: "outlined",
  placeholder: "Select Breakout",
  label: "",
  listOfDockets: [],
};
export default MuiSelectDocketFormField;
