import React from "react";
import { Box, styled, Typography, alpha, IconButton } from "@mui/material";
import { checkIfNull } from "@app21/core";
import { UserAvatar, StyledDot } from "blocks/atoms";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from "material-ui-popup-state/hooks";
import MessageMenuPopover from "./MessageMenuPopover";
import { formatChatTimeMessage } from "utils";
import ReplyMessageCard from "./ReplyMessageCard";
import DeletedMessageCard from "./DeletedMessageCard";
import UrlPreviewCard from "./UrlPreviewCard";
import { useChatContext } from "providers/TrrystChatProvider";

const StyledChatWrapperBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

const StyledChatBox = styled(Box)(({ theme, ownerState }) => {
  const { isMe } = ownerState ?? {};
  return {
    display: "flex",
    flexGrow: 1,
    justifyContent: isMe ? "flex-end" : "flex-start",
  };
});

export default function BasicMessageLayout({
  children,

  isMe = false,
  uuid,
  timeStamp = null,
  isContiguous = false,
  isDeleted = false,
  replyMessage = null,
  isMobile = false,
  showMenuOptions = false,
  setShowMenuOptions = () => null,
  urlMeta = null,
  messageType = "text",
  messageId,
  s3Key,
  handleFileDownload = () => null,
  handleMessageDelete = () => null,
  handleMessageReply = () => null,
  handleFileView = () => null,
  canDelete = false,
}) {
  const messageCardPopupState = usePopupState({
    variant: "popover",
    popupId: `message-card-popover-${messageId}`,
  });
  const { chatPermissions } = useChatContext();
  return (
    <StyledChatBox ownerState={{ isMe }}>
      <Box
        sx={{
          display: "flex",
          maxWidth: isMobile ? "80vw" : 600,
          flexDirection: isMe ? "row-reverse" : "row",
        }}
      >
        {!isContiguous ? (
          <UserAvatar userId={uuid} size={35} variant="rounded" />
        ) : (
          <span style={{ width: 35, maxWidth: 35, minWidth: 35 }} />
        )}
        <Box
          sx={{
            display: "flex",
            maxWidth: "100%",
            flexDirection: "column",
            px: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: isMe ? "row-reverse" : "row",
            }}
          >
            <Box
              sx={{
                p: 1,
                borderRadius: 1,
                display: "flex",
                flexDirection: "column",

                maxWidth: "100%",
                backgroundColor: (theme) =>
                  isMe
                    ? alpha(theme.palette.primary.lighter, 0.1)
                    : alpha(theme.palette.secondary.lighter, 0.1),
              }}
            >
              <Box
                sx={{
                  mb: 0.6,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {!isContiguous ? (
                  <Typography
                    variant="caption"
                    sx={{
                      fontWeight: 600,
                      color: (theme) => theme.palette.text.secondary,
                    }}
                  >
                    {isMe ? (
                      "You"
                    ) : (
                      <UserAvatar userId={uuid} viewContext={"name"} />
                    )}
                  </Typography>
                ) : (
                  <div />
                )}
                <Typography
                  variant="caption"
                  sx={{ ml: 2, alignSelf: "flex-end" }}
                >
                  {formatChatTimeMessage(timeStamp)}
                </Typography>
              </Box>
              {!isDeleted ? (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  {replyMessage && (
                    <ReplyMessageCard
                      originalMessageOnReply={replyMessage}
                      viewContext={"list"}
                      isMobile={isMobile}
                    />
                  )}
                  {!checkIfNull(urlMeta) && (
                    <UrlPreviewCard urlMeta={urlMeta} />
                  )}
                  {children}
                </Box>
              ) : (
                <DeletedMessageCard />
              )}
            </Box>
            {!isDeleted && chatPermissions.canRespondToChat && (
              <div edge="end">
                <IconButton
                  {...bindTrigger(messageCardPopupState)}
                  sx={{
                    mr: 0.5,
                    visibility: showMenuOptions ? "visible" : "hidden",
                  }}
                >
                  <MoreHorizOutlinedIcon fontSize="inherit" />
                </IconButton>
                <MessageMenuPopover
                  messageId={messageId}
                  s3Key={s3Key}
                  bindPopover={bindPopover}
                  messageCardPopupState={messageCardPopupState}
                  messageType={messageType}
                  handleFileDownload={handleFileDownload}
                  handleMessageDelete={handleMessageDelete}
                  handleMessageReply={handleMessageReply}
                  handleFileView={handleFileView}
                  canDelete={canDelete}
                />
              </div>
            )}
          </Box>
        </Box>
      </Box>
    </StyledChatBox>
  );
}
