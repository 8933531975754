import React from "react";
import {
  Accordion,
  Box,
  Typography,
  AccordionSummary,
  AccordionDetails,
  styled,
  alpha,
} from "@mui/material";
import ShowFolderTreeView from "./ShowFolderTreeView";
import { useFilesContext } from "providers/FilesProvider";
import {
  ExpandMore,
  ArrowForwardIosSharp,
  ArrowRight,
  ArrowDropDown,
} from "@mui/icons-material";
import { checkIfNull, useHandleRouterPush, useAppContext } from "@app21/core";
import { viewFilesContextAtom } from "providers/FilesProvider/files-atoms";
import { useAtom } from "jotai";
import path from "path";

const StyledAccordion = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const StyledAccordionSummary = styled(
  (props) => <AccordionSummary {...props} />,
  {
    shouldForwardProp: (prop) => prop !== "isActive",
  }
)(({ theme, isActive = false }) => ({
  backgroundColor: isActive
    ? alpha(theme.palette.primary.lighter, 0.2)
    : theme.palette.background.default,
  //flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  margin: 0,
  py: 2,
  // borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function ShowNestedFolders({ foldersList = [] }) {
  const { decodedS3Prefix, allS3Keys, docketsInSuite } = useFilesContext();
  const { viewFile } = useAppContext();
  const showFilesFlag = Boolean(viewFile);
  const viewedFilePrefix = `${path.dirname(decodeURIComponent(viewFile))}/`;

  const [viewFilesContext, setViewFilesContext] = useAtom(viewFilesContextAtom);
  const currentOpenFolder = foldersList?.find((folder) =>
    (showFilesFlag ? viewedFilePrefix : decodedS3Prefix)?.includes(
      folder.parentPrefix + folder.name
    )
  );

  const [expanded, setExpanded] = React.useState(
    currentOpenFolder?.name ?? null
  );
  const { loadRoute } = useHandleRouterPush();

  const handleFolderClick = (event, key, node, context) => {
    let docketSearchId = docketsInSuite?.find((d) => d.title === key)?._id;

    let updatedKey = docketSearchId ?? key;

    const s3Entry =
      context === "folder"
        ? allS3Keys.listOfAllKeys?.find(
            (k) =>
              k.Key.endsWith(`${updatedKey}/`) ||
              k?.origKey?.endsWith(`${updatedKey}/`)
          )
        : context === "home"
        ? `${node.parent}/${node.name}`
        : allS3Keys.listOfAllKeys?.find(
            (k) => k.Key === updatedKey || k?.origKey === updatedKey
          );
    const fileName = updatedKey?.replace(/^.*(\\|\/|\:)/, "");
    const newKey = s3Entry?.Key?.replace(fileName, "");

    s3Entry &&
      loadRoute("VIEW-DATAROOM", {
        docketId: docketSearchId,
        s3Key: context === "folder" ? s3Entry?.Key : newKey,
      });
  };

  const handleChange = (panel, node) => (event, newExpanded) => {
    if (!showFilesFlag) handleFolderClick(event, node.name, node, "folder");
    setExpanded(newExpanded ? panel : false);
  };

  return foldersList.map((folder, index) => {
    const isCurrentFolderInView =
      viewFilesContext === "suites"
        ? decodedS3Prefix?.includes(folder.name)
        : decodedS3Prefix?.includes(folder.origKey);

    return (
      <StyledAccordion
        key={index}
        expanded={expanded === folder.name}
        onChange={handleChange(folder.name, folder)}
      >
        <StyledAccordionSummary
          isActive={isCurrentFolderInView}
          {...(checkIfNull(folder.folders)
            ? {}
            : {
                expandIcon: <ArrowDropDown />,
              })}
        >
          <Typography variant="subtitle2">{folder.name}</Typography>
        </StyledAccordionSummary>
        {folder.folders && (
          <StyledAccordionDetails>
            <Box>
              <ShowFolderTreeView
                filesList={folder}
                decodedS3Prefix={decodedS3Prefix}
                allKeys={allS3Keys.listOfAllKeys}
                docketsInSuite={docketsInSuite}
              />
            </Box>
          </StyledAccordionDetails>
        )}
      </StyledAccordion>
    );
  });
}
