// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { ListItem, ListItemText, ListItemIcon } from "@mui/material";
import { Ballot } from "@mui/icons-material";

const ListResolutionQuestions = ({ resolution }) => {
  return resolution?.survey?.questionBlock?.map((question, index) => (
    <ListItem dense key={index}>
      <ListItemIcon size="small">
        <Ballot fontSize="inherit" />
      </ListItemIcon>
      <ListItemText primary={question?.questionItem} />
    </ListItem>
  ));
};

export default ListResolutionQuestions;
