// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  DialogActions,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  TextField,
  Paper,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Delete } from "@mui/icons-material";
import { Eye as EyeIcon, EyeOff as EyeOffIcon } from "react-feather";
import { useIntl } from "react-intl";
import Checklist from "blocks/modules/Tasks/foundations/TaskEditModal/Checklist";
import Comment from "blocks/modules/Tasks/foundations/TaskEditModal/Comment";
import CommentAdd from "blocks/modules/Tasks/foundations/TaskEditModal/CommentAdd";
import ActionButton from "blocks/modules/Tasks/foundations/TaskEditModal/ActionButton";
import {
  populateUsers,
  useDockets,
  useAppContext,
  checkIfNull,
  createSelectOptions,
  app21APIInstance,
  useDocketsInSuite,
} from "@app21/core";
import CompletionSlider from "blocks/modules/Tasks/foundations/TaskEditModal/CompletionSlider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import MuiSelectFormField from "blocks/atoms/MuiSelectFormField";
import { useConfirm } from "material-ui-confirm";
import {
  FormProvider,
  useForm,
  useFieldArray,
  Controller,
} from "react-hook-form";
import { MuiSelectDocketFormField } from "blocks/atoms";
import { stringPurify } from "utils/inputUtils";
import isEmpty from "validator/lib/isEmpty";
import toast from "react-hot-toast";
import { useTasksContext } from "providers/TasksProvider";
import { useChatContext } from "providers/TrrystChatProvider";
import { selectedBreakoutsInTaskFiltersAtom } from "blocks/modules/Tasks/tasks-atoms";
import { useAtom } from "jotai";
import { FlowBox } from "blocks/atoms/uistyles";
import { MoreActionsDropdownMenu } from "blocks/atoms/uicomponents";
import { TEXTFIELDLIMITS } from "blocks/atoms/constants/appConstants";
import { ContextualChatComponent } from "blocks/modules/TrrystChat";

const statusOptions = [
  { value: "pending", label: "Planned Task" },
  { value: "wip", label: "Work In Progress" },
  { value: "completed", label: "Completed" },
];
const TaskEditForm = ({ context, onClose, ...rest }) => {
  const { selectedSuiteId, selectedDocketId, checkAccessRules } =
    useAppContext();
  const {
    selectedTask,
    selectedTaskId,
    usersInSuite,
    handleTaskDelete,
    tasksPermissions,
    handleTaskSave,
    selectedTaskUniqueMembers,
    baseDockets,
  } = useTasksContext();

  const { data: docketsInSuite } = useDocketsInSuite(selectedSuiteId);

  const [selectedBreakoutsInTaskFilters, setSelectedBreakoutsInTaskFilters] =
    useAtom(selectedBreakoutsInTaskFiltersAtom);

  const currentDocket = !checkIfNull(selectedBreakoutsInTaskFilters)
    ? selectedBreakoutsInTaskFilters[0]
    : {};

  const { userInfo } = useAppContext();

  const { allChannelsData } = useChatContext();
  const [taskChatId, setTaskChatId] = React.useState();
  const confirm = useConfirm();
  const [, setShowTaskMembersFlag] = React.useState(false);
  const [watchers, setWatchers] = React.useState(selectedTask?.watchers ?? []);
  const [dirtyFlag, setDirtyFlag] = React.useState(false);
  const intl = useIntl();
  const t = (id) => intl.formatMessage({ id });

  React.useEffect(() => {
    if (Array.isArray(allChannelsData)) {
      const taskChannel = allChannelsData?.find(
        (channel) => channel.metadata?.belongsToId === selectedTaskId
      );
      if (taskChannel) {
        setTaskChatId(taskChannel.id);
      } else setTaskChatId(null);
    } else setTaskChatId(null);
    // you can use metadata.belongsToId === selectedTaskId to check if the
    // chat channel for this task exists or not.
  }, [allChannelsData, selectedTaskId]);

  const moreTaskOptions = [
    {
      label: "Delete Task",
      onClick: () => handleTaskDelete(selectedTask),
      icon: <Delete />,
      criteria: tasksPermissions?.checkCanDelete(selectedTask),
    },
  ];

  let populatedAssignees = createSelectOptions(
    populateUsers(selectedTask?.assignees, usersInSuite)
  );
  const currentDocketId =
    selectedDocketId ?? currentDocket?._id ?? currentDocket?.value;

  const defaultValues = {
    title: selectedTask?.title || "",
    docketId: selectedTask?.docketId ?? currentDocketId ?? null,
    description: selectedTask?.description || "",
    dueBy: selectedTask?.dueBy || null,
    status: selectedTask?.status || "pending",
    assignees: populatedAssignees ?? [],
    watchers: selectedTask?.watchers ?? [],
    comments: selectedTask?.comments ?? [],
    completionPercentage: selectedTask?.completionPercentage ?? 0,
    newComment: "",
  };

  React.useEffect(() => {
    reset(defaultValues);
  }, [selectedTask]);

  const methods = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
  });
  const {
    handleSubmit,
    control,
    formState,
    reset,
    getValues,
    setValue,
    watch,
  } = methods;
  const { isDirty, isSubmitting, isValid, errors } = formState;

  const { accessFlag: canAccessMembers } = checkAccessRules({
    entity: "USERS-VISIBILITY",
    action: "VIEW-ALL-SUITE-USERS",
    featureName: "SUITE-USER-DETAILS",
    isInvitee: true,
  });

  // Assumption is that you will make the chat visibility a onClick item
  // instead of always showing it.

  const handleTaskChatClick = async () => {
    const response = await app21APIInstance.post(`/channels/fetch`, {
      objectType: "task",
      objectId: selectedTaskId,
      calledBy: userInfo._id,
    });
    setTaskChatId(response?.data?.data?.data?.[0]?.id);
  };
  const selectedDocket = docketsInSuite?.find(
    (docket) => docket._id === watch("docketId")
  );
  function getTaskGroupTitle(taskGroupData) {
    switch (taskGroupData) {
      case "pending":
        return "Planned Tasks";
      case "wip":
        return " Work In Progress Tasks";
      case "completed":
        return "Completed Tasks";
      default:
        return "Tasks";
    }
  }
  const resetForm = () => {
    setShowTaskMembersFlag(false);
    setWatchers([]);
    setDirtyFlag(false);
    reset();
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const onSubmit = async (formData) => {
    const modFormData = { ...formData };

    modFormData.title = stringPurify(formData.title);
    modFormData.description = stringPurify(formData.description);

    if (!isEmpty(formData.newComment, { ignore_whitespace: true })) {
      modFormData.comments.unshift({
        who: userInfo._id,
        comment: stringPurify(formData.newComment),
        when: Date.now(),
      });
    }
    modFormData.watchers = watchers;
    await handleTaskSave(modFormData);
  };

  const handleSubscribeToggle = () => {
    let index = watchers.findIndex((x) => x === userInfo._id);
    setDirtyFlag(true);
    if (index === -1) {
      setWatchers((watchers) => [...watchers, userInfo._id]);
    } else {
      const newWatchers = watchers.filter((id) => id !== userInfo._id);
      setWatchers(newWatchers);
    }
  };

  const handleFormClose = () => {
    if (dirtyFlag || isDirty) {
      confirm({
        description: "You have some unsaved changes. Do you want to exit?",
      })
        .then(() => {
          handleClose();
        })
        .catch((err) => {
          console.log("Close cancelled.", err);
        });
    } else {
      handleClose();
    }
  };

  const usersInDocket = populateUsers(selectedDocket?.members, usersInSuite);
  const listOfUsers = watch("docketId")
    ? usersInDocket
    : canAccessMembers
    ? usersInSuite
    : [];

  return (
    <FormProvider {...methods}>
      <FlowBox>
        <Paper {...rest}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Controller
                    control={control}
                    name="status"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors?.status}
                        helperText={errors?.status?.message}
                        size="small"
                        margin="dense"
                        select
                        SelectProps={{ native: true }}
                        sx={{ mt: 2, maxWidth: 180 }}
                      >
                        {statusOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    )}
                  />

                  {getValues("status") === "wip" && (
                    <Box
                      sx={{
                        flexGrow: 1,
                        minWidth: 150,
                        maxWidth: 250,
                        pl: 2,
                      }}
                    >
                      <CompletionSlider
                        control={control}
                        errors={errors}
                        showTitle={false}
                        showMarks={false}
                        name={"completionPercentage"}
                      />
                    </Box>
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <LoadingButton
                      type={"submit"}
                      size="small"
                      variant="contained"
                      disabled={!isValid || !(dirtyFlag || isDirty)}
                      loading={isSubmitting}
                      onClick={handleSubmit(onSubmit)}
                    >
                      Save
                    </LoadingButton>
                    <MoreActionsDropdownMenu
                      menuOptions={moreTaskOptions}
                      tooltipLabel="More Task Options"
                    />
                  </div>
                </Box>
              </Box>
            </DialogTitle>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DialogContent
                sx={{
                  overflow: "auto",
                }}
              >
                <Box
                  sx={{
                    mt: 2,
                  }}
                >
                  <Typography variant="overline" color="textSecondary">
                    Title
                  </Typography>
                  <Controller
                    name="title"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "A title for the task is required",
                      },
                      maxLength: {
                        value: TEXTFIELDLIMITS.taskNameLength,
                        message: `Sorry. Too long. Limit to ${TEXTFIELDLIMITS.taskNameLength} characters`,
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        error={!!errors.title}
                        helperText={errors.title?.message}
                        placeholder="Something brief about the task"
                        fullWidth
                        InputProps={{
                          sx: {
                            backgroundColor: (theme) =>
                              theme.palette.background.default,
                          },
                        }}
                        InputLabelProps={{
                          sx: {
                            color: (theme) => theme.palette.text.primary,
                            backgroundColor: (theme) =>
                              theme.palette.background.default,
                          },
                        }}
                      />
                    )}
                  />
                </Box>
                <Grid container spacing={3}>
                  <Grid item xs>
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="overline" color="textSecondary">
                        {`Link to a Breakout`}
                      </Typography>

                      <Controller
                        name={"docketId"}
                        control={control}
                        rules={{
                          required: { value: false, message: "Optional" },
                        }}
                        render={({ field: { ref, ...rest } }) => (
                          <MuiSelectDocketFormField
                            refs={ref}
                            listOfDockets={baseDockets ?? []}
                            label=""
                            placeholder="Link to a Breakout (optional)"
                            inputValue={""}
                            size="small"
                            {...rest}
                            onChange={(data) => {
                              setValue("docketId", data, { shouldDirty: true });
                            }}
                            variant="outlined"
                          />
                        )}
                      />
                    </Box>

                    <Box sx={{ mt: 2 }}>
                      <Typography variant="overline" color="textSecondary">
                        Members
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            flexGrow: 1,
                          }}
                        >
                          <Controller
                            control={control}
                            name={"assignees"}
                            render={({ field: { ref, ...rest } }) => (
                              <MuiSelectFormField
                                listOfUsers={listOfUsers}
                                refs={ref}
                                size="small"
                                placeholder="Add to Task"
                                limitTags={10}
                                allowGuests={false}
                                inputValue={""}
                                variant="outlined"
                                {...rest}
                                onChange={(data) => {
                                  setValue("assignees", data, {
                                    shouldDirty: true,
                                  });
                                }}
                                errors={errors?.assignees}
                              />
                            )}
                            rules={{
                              required: {
                                value: false,
                                message:
                                  "Atleast one person needs to be added to the Task .",
                              },
                            }}
                          />
                        </Box>
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <Typography variant="overline" color="textSecondary">
                          Due By
                        </Typography>
                        <Controller
                          name="dueBy"
                          control={control}
                          render={({ field: { ref, ...rest } }) => (
                            // DatePicker
                            <DatePicker
                              autoOk
                              KeyboardButtonProps={{
                                "aria-label": "change date / time",
                              }}
                              sx={{
                                mr: 2,
                                "&:last-child": {
                                  mr: 0,
                                },
                                width: "100%",
                              }}
                              inputVariant={"outlined"}
                              format={"dd-MM-yyyy"}
                              disableMaskedInput
                              InputLabelProps={{
                                sx: {
                                  color: (theme) => theme.palette.text.primary,
                                  backgroundColor: (theme) =>
                                    theme.palette.background.default,
                                },
                              }}
                              // componentsProps={{
                              //   textField: {
                              //     margin: "dense",
                              //     size: "small",
                              //     fullWidth: true,
                              //     error: !!errors?.dueBy,
                              //     helperText: errors?.dueBy?.message,
                              //     variant: "outlined",
                              //     sx: { mt: 0 },
                              //     InputProps: {
                              //       sx: {
                              //         backgroundColor: (theme) =>
                              //           theme.palette.background.default,
                              //       },
                              //     },
                              //   },
                              // }}
                              {...rest}
                            />
                          )}
                        />
                      </Box>
                      <Controller
                        name="description"
                        control={control}
                        rules={{
                          maxLength: {
                            value: TEXTFIELDLIMITS.taskDescriptionLength,
                            message: `Sorry. Too long. Limit to ${TEXTFIELDLIMITS.taskDescriptionLength} characters`,
                          },
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            error={!!errors.description}
                            helperText={errors.description?.message}
                            multiline
                            rows={6}
                            sx={{ mt: 2 }}
                            fullWidth
                            placeholder="Add more task details (,if needed) "
                            InputProps={{
                              sx: {
                                backgroundColor: (theme) =>
                                  theme.palette.background.default,
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                color: (theme) => theme.palette.text.primary,
                                backgroundColor: (theme) =>
                                  theme.palette.background.default,
                              },
                            }}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  {selectedTaskUniqueMembers?.length > 1 && (
                    <Grid item xs={12} md={6}>
                      <FlowBox
                        sx={{
                          mt: 2,
                        }}
                      >
                        <Typography variant="overline" color="textSecondary">
                          Discussion
                        </Typography>
                        {taskChatId ? (
                          <FlowBox>
                            <ContextualChatComponent channelId={taskChatId} />
                          </FlowBox>
                        ) : (
                          <FlowBox
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            No comments yet.
                            <Button
                              variant="contained"
                              onClick={handleTaskChatClick}
                            >
                              Chat
                            </Button>
                          </FlowBox>
                        )}
                      </FlowBox>
                    </Grid>
                  )}
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={8}>
                    {selectedTask?.checklists?.length > 0 && (
                      <Box
                        sx={{
                          mt: 5,
                        }}
                      >
                        {selectedTask?.checklists?.map((checklist) => (
                          <Checklist
                            key={checklist?.id}
                            card={selectedTask}
                            checklist={checklist}
                            style={{
                              "& + &": {
                                marginTop: (theme) => theme.spacing(3),
                              },
                            }}
                          />
                        ))}
                      </Box>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    {/*<DueByDatePicker currentDueByDate={dueBy} handleDueDateChange={handleDueDateChange} />
                
            <ActionButton icon={<FileIcon />} disabled>
              Attachments
            </ActionButton>*/}
                    <Box
                      sx={{
                        mt: 1,
                      }}
                    >
                      {!(
                        userInfo._id === selectedTask?.createdBy ||
                        selectedTask?.assignees?.includes(userInfo._id)
                      ) &&
                        !checkIfNull(selectedTask) &&
                        (watchers?.includes(userInfo._id) ? (
                          <ActionButton
                            icon={<EyeOffIcon />}
                            color="accent"
                            onClick={handleSubscribeToggle}
                          >
                            {`${t("SuiteDashboard.Tasks.unwatch")}`}
                          </ActionButton>
                        ) : (
                          <ActionButton
                            icon={<EyeIcon />}
                            onClick={handleSubscribeToggle}
                          >
                            {`${t("SuiteDashboard.Tasks.watch")}`}
                          </ActionButton>
                        ))}
                    </Box>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions
                sx={{
                  width: "100%",
                  background: "rgba(255,255,255, 0.5)",
                  p: 1,
                  pr: 3,
                }}
              ></DialogActions>
            </LocalizationProvider>
          </form>
        </Paper>
      </FlowBox>
    </FormProvider>
  );
};

TaskEditForm.propTypes = {
  taskGroupData: PropTypes.string,
};

export default TaskEditForm;
