import React from "react";
import { Box, Container, Paper } from "@mui/material";
import TinyMceEditor from "blocks/atoms/TinyMceEditor";
import DocStatusbar from "blocks/atoms/DocStatusbar";
import { FlowBox } from "blocks/atoms/uistyles";

export default function DisplayMinutesDoc({
  meeting,
  fetchMeetingStatus = "idle",
  onUploadedDocumentSave,
  minutes,
  onClose,
  onChange,
}) {
  const getStatusString = () => {
    switch (fetchMeetingStatus) {
      case "loading":
        return "- AutoSaving...";
      case "success":
        return "- AutoSaved";
      case "error":
        return " - Error in saving document";
      case "idle":
        return "";
      default:
        return "";
    }
  };

  return (
    <FlowBox
      sx={{
        width: "100%",
        alignItems: "center",
        minHeight: "50vh",
        maxWidth: "100%",
        backgroundColor: "#525659",
        border: (theme) => `1px solid ${theme.palette.primary.main}`,
      }}
    >
      <Container
        maxWidth="md"
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          padding: 0,
        }}
      >
        <DocStatusbar
          documentName={`Minutes ${
            meeting?.meetingJSON?.summary
              ? `for - ${meeting?.meetingJSON?.summary}`
              : ""
          }`}
          displayStatus={getStatusString()}
        />
        <Paper
          elevation={4}
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            border: "1px solid red",
          }}
        >
          <TinyMceEditor
            editorLayout={"normal"}
            id={meeting?._id}
            initialValue={minutes}
            value={minutes}
            handleDocumentClose={onClose}
            handleDocumentSave={() => onUploadedDocumentSave(minutes)}
            handleDocumentDelete={() => onUploadedDocumentSave(null, "delete")}
            onEditorChange={(value) => onChange(value)}
          />
        </Paper>
      </Container>
    </FlowBox>
  );
}
