// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Box } from "@mui/material";
import { ShowFiles } from "blocks/modules/Dockets/foundations";

import { useIntl } from "react-intl";
import { MainFilesComponent } from "blocks/modules/TrrystFiles";

const DocketFilesView = (props) => {
  const { viewMode } = props;
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });

  if (viewMode === "summary") {
    return (
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          weight: "100%",
          height: "100%",
        }}
      >
        <ShowFiles />
      </Box>
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        weight: "100%",
        height: "100%",
      }}
    >
      <MainFilesComponent viewContext={"DOCKET"} />
    </Box>
  );
};

export default DocketFilesView;
