import React from "react";
import {
  showTasksSidebarFlagAtom,
  viewTaskContextAtom,
  viewTasksFilterContextAtom,
  selectedBreakoutsInTaskFiltersAtom,
  selectedStatusInTaskFiltersAtom,
  selectedTasksDateRangeAtom,
} from "blocks/modules/Tasks/tasks-atoms";
import { Box, Button } from "@mui/material";
import { Clear } from "@mui/icons-material";
import { useAtom } from "jotai";
import { DocketSelectionDropdown } from "blocks/modules/Dockets";
import { useTasksContext } from "providers/TasksProvider";
import { checkIfNull, useHandleAddRemoveIdFromRoute } from "@app21/core";
import ShowSelectedDocketsChips from "./ShowSelectedDocketsChips";
import TaskStatusFilterSelectionDropdown from "./TaskStatusFilterSelectionDropdown";
import TasksDateRangeSelection from "./TasksDateRangeSelection";
import { useRouter } from "next/router";

const baseTaskStatusOptions = [
  { label: "Open", value: "open" },
  { label: "In-Progress", value: "wip" },
  { label: "Completed", value: "completed" },
];
export default function SelectedActionsDisplay({}) {
  const { addIdToRoute, removeIdFromRoute } = useHandleAddRemoveIdFromRoute();

  const router = useRouter();
  const [viewTasksFilterContext, setViewTasksFilterContext] = useAtom(
    viewTasksFilterContextAtom
  );
  const [selectedBreakoutsInTaskFilters, setSelectedBreakoutsInTaskFilters] =
    useAtom(selectedBreakoutsInTaskFiltersAtom);
  const [selectedStatusInTaskFilters, setSelectedStatusInTaskFilters] = useAtom(
    selectedStatusInTaskFiltersAtom
  );
  const [selectedTasksDateRange, setSelectedTasksDateRange] = useAtom(
    selectedTasksDateRangeAtom
  );
  const { baseDockets } = useTasksContext();

  const handleSelectedDocket = (docket) => {
    setSelectedBreakoutsInTaskFilters([docket]);
    let newRoutePath = addIdToRoute({
      paramKey: "docketId",
      paramValue: docket.value ?? docket._id,
    });

    router.push(newRoutePath, newRoutePath, { shallow: true });
  };
  const handleRemoveSelectedDocket = () => {
    setSelectedBreakoutsInTaskFilters([]);
    setViewTasksFilterContext((current) =>
      current.filter((option) => option !== "breakoutsFilter")
    );
    router.push(removeIdFromRoute({ paramKey: "docketId" }), { shallow: true });
  };

  const handleTaskStatusSelection = (option) => {
    setSelectedStatusInTaskFilters(option);
  };

  const handleTasksDateRangeSelect = ({ startDate, endDate }) => {
    let adjStartDate = startDate;
    let adjEndDate = endDate;
    setSelectedTasksDateRange({ startDate: adjStartDate, endDate: adjEndDate });
  };

  const displaySelectedFilter = (option) => {
    switch (option) {
      case "breakoutsFilter":
        if (checkIfNull(selectedBreakoutsInTaskFilters)) {
          return (
            <DocketSelectionDropdown
              showLabel={false}
              dockets={baseDockets}
              label="Select Breakout"
              id="newfield-random"
              onDocketSelect={(docket) => handleSelectedDocket(docket)}
              sx={{ maxWidth: 150 }}
            />
          );
        } else
          return (
            <ShowSelectedDocketsChips
              onClear={handleRemoveSelectedDocket}
              dockets={selectedBreakoutsInTaskFilters}
            />
          );

      case "statusFilter": {
        return (
          <TaskStatusFilterSelectionDropdown
            selectedStatusFilterValue={selectedStatusInTaskFilters}
            taskStatusOptions={baseTaskStatusOptions}
            onStatusSelect={(option) => handleTaskStatusSelection(option)}
            sx={{ maxWidth: 150 }}
          />
        );
      }
      case "dateFilter": {
        return (
          <TasksDateRangeSelection
            selectedDateRange={selectedTasksDateRange}
            handleTasksDateRangeSelect={handleTasksDateRangeSelect}
          />
        );
      }
      default:
        return null;
    }
  };
  return (
    <Box sx={{ flexGrow: 1, display: "flex", flexWrap: "wrap", my: 1, gap: 1 }}>
      {viewTasksFilterContext?.map((option, index) => (
        <Box key={index} sx={{}}>
          {displaySelectedFilter(option)}
        </Box>
      ))}
    </Box>
  );
}
