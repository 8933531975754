// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";

export default function DraggablePaper({ id, ...rest }) {
  return (
    <Draggable
      handle={"#dragModal"}
      cancel={'[class*="MuiDialogContent-root"], [class*="MuiPaperRoot"]'}
    >
      <Paper {...rest} />
    </Draggable>
  );
}
