import React from "react";
import {
  Box,
  Typography,
  IconButton,
  ClickAwayListener,
  Button,
} from "@mui/material";
import {
  SelectSurveysSidebarContext,
  ShowSidebarSurveysList,
} from "../foundations";
import { FlowBox } from "blocks/atoms/uistyles";

export default function SurveysSidebar({ isMobile = false }) {
  return (
    <FlowBox
      sx={{
        width: "100%",
        maxWidth: isMobile ? "100%" : "300px",
        overflow: "hidden",
      }}
    >
      <Box>
        <SelectSurveysSidebarContext />
      </Box>
      <FlowBox sx={{ overflow: "hidden" }}>
        <ShowSidebarSurveysList />
      </FlowBox>
    </FlowBox>
  );
}
