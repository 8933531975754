import React, { Fragment } from "react";
import {
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Tooltip,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import {
  bindTrigger,
  bindMenu,
  usePopupState,
} from "material-ui-popup-state/hooks";

export default function MoreActionsDropdownMenu({
  menuOptions = [],
  IconButtonProps = {},
  index,
  tooltipTitle = "More Options",
  ...props
}) {
  const popupState = usePopupState({
    variant: "popover",
    popupId: `dropdownMenu+${
      index ? index : Math.floor(Math.random() * 101000)
    }`,
  });
  if (menuOptions?.length === 0) return null;
  return (
    <Fragment>
      <Tooltip title={tooltipTitle}>
        <IconButton
          {...IconButtonProps}
          {...bindTrigger(popupState)}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            // perform additional action...and then:
            bindTrigger(popupState).onClick(e);
          }}
        >
          <MoreVert fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <Menu {...bindMenu(popupState)}>
        {menuOptions.map((option, index) => (
          <MenuItem
            key={index}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              option.onClick(event);
              popupState.close();
            }}
          >
            <ListItemIcon>{option.icon}</ListItemIcon>
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
}
