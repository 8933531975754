import React from 'react'
import { SvgIcon } from '@mui/material';

export default function OneByOneIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M52.8333 16.5002C54.8333 19.4585 56 23.0002 56 26.8335C55.9583 36.8335 48.0833 44.9585 38.1667 45.2918C37.75 45.2502 37.25 45.2502 36.7917 45.2918C26.875 44.9585 19 36.8335 19 26.8335C19 16.6252 27.25 8.3335 37.5 8.3335"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M68.3749 16.667C76.4582 16.667 82.9582 23.2087 82.9582 31.2503C82.9582 39.1253 76.7083 45.542 68.9166 45.8337C68.5833 45.792 68.2083 45.792 67.8333 45.8337"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.3334 60.666C7.25004 67.416 7.25004 78.416 17.3334 85.1244C28.7917 92.791 47.5834 92.791 59.0417 85.1244C69.125 78.3744 69.125 67.3744 59.0417 60.666C47.625 53.041 28.8334 53.041 17.3334 60.666Z"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M76.4167 83.3325C79.4167 82.7075 82.2501 81.4992 84.5834 79.7075C91.0834 74.8325 91.0834 66.7909 84.5834 61.9159C82.2917 60.1659 79.5001 58.9992 76.5418 58.3325"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
