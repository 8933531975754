// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  Box,
  Card,
  CardHeader,
  Avatar,
  CardActionArea,
  Typography,
  useTheme,
} from "@mui/material";
import {
  useAppContext,
  useFilesS3,
  useHandleRouterPush,
  checkIfNull,
  useSelectedBucketId,
} from "@app21/core";

import LoadingSpinner from "blocks/atoms/LoadingSpinner";
import { useIntl } from "react-intl";

import { getFileIcon } from "blocks/atoms";
import clip from "text-clipper";
//import { FileIcon, defaultStyles } from 'react-file-icon';
import { getExtension } from "utils/inputUtils";
import toast from "react-hot-toast";

const checkIfDir = (key) => {
  return (key || "").slice(-1) === "/";
};

const ShowFiles = () => {
  const { selectedOrganizationId, selectedSuiteId, selectedDocketId } =
    useAppContext();
  const selectedBucketId = useSelectedBucketId(null, true);
  const { loadRoute } = useHandleRouterPush();
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });

  const {
    data: allS3Keys,
    status: allS3KeysStatus,
    apiError: allS3KeysApiError,
  } = useFilesS3({
    bucket: selectedBucketId,
    prefix: `${selectedOrganizationId}/${selectedSuiteId}/dockets/${selectedDocketId}/`,
    version: 2,
  });
  React.useEffect(() => {
    if (allS3KeysApiError && allS3KeysStatus != "loading") {
      toast.error(allS3KeysApiError.message);
    }
  }, [allS3KeysApiError]);

  let filteredS3Files = !checkIfNull(allS3Keys?.listOfAllKeys)
    ? (
        (Array.isArray(allS3Keys?.listOfAllKeys) && allS3Keys?.listOfAllKeys) ??
        []
      ).filter((file) => !checkIfDir(file.Key))
    : [];

  if (allS3KeysStatus === "loading") {
    return <LoadingSpinner size={25} />;
  } else if (!filteredS3Files?.length) {
    return (
      <Typography variant="body1">
        {" "}
        {`${t("DocketDashboard.ShowFiles.noFilesMessage")}`}{" "}
      </Typography>
    );
  } else {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          flexGrow: 1,
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {filteredS3Files.map((file, index) => {
          let fileName = file?.name ?? file.Key?.replace(/^.*(\\|\/|\:)/, "");
          let fileExtension = getExtension(fileName);
          let fileId = file.Key ?? file.id;

          return (
            <Card key={index} sx={{ mr: 1, border: "1px solid Lightgrey" }}>
              <CardActionArea
                onClick={() => {
                  loadRoute("VIEW-DOCKETFILE", {
                    s3Key: fileId,
                    docketId: selectedDocketId,
                  });
                }}
              >
                <CardHeader
                  sx={{ py: 0.2, pl: 0.8, pr: 1 }}
                  avatar={
                    <Avatar
                      alt={fileName}
                      sx={{
                        mr: 0,
                        bgcolor: "transparent",
                        height: 20,
                        width: 20,
                      }}
                      variant="square"
                    >
                      <>
                        {getFileIcon({
                          fileExt: fileExtension || "file",
                          size: "sm",
                        })}
                        {/*<FileIcon
                        fold={false}
                        radius={12}
                        color={theme.palette.primary.light}
                        extension={fileExtension}
                        {...defaultStyles[`${fileExtension}`]}
                      />*/}
                      </>
                    </Avatar>
                  }
                  title={
                    <Typography variant="caption">
                      {" "}
                      {clip(fileName, 25)}{" "}
                    </Typography>
                  }
                  disableTypography
                />
              </CardActionArea>
            </Card>
          );
        })}
      </Box>
    );
  }
};

export default ShowFiles;
