import React from "react";
import {
  Box,
  Paper,
  IconButton,
  Typography,
  ClickAwayListener,
} from "@mui/material";
import { ReadMore, Close, Title } from "@mui/icons-material";
import { FlowBox } from "blocks/atoms/uistyles";
import { showSidebarFlagAtom } from "providers/LayoutProvider/layout-atoms";
import { useAtom } from "jotai";

export default function SidePanelDrawer({
  sidebarModeActive = false,
  width = "20%",
  children,
  TitleComponent = <></>,
  DrawerSxProps = {},
  centralTitleBarHeight = 40,
  sx = {},
}) {
  const [showSidebarFlag, setShowSidebarFlag] = useAtom(showSidebarFlagAtom);
  const handleSidebarToggle = (ev, flag) => {
    ev.preventDefault();
    ev.stopPropagation();
    if (typeof flag === "boolean") {
      setShowSidebarFlag(flag);
    } else {
      setShowSidebarFlag((current) => !current);
    }
  };
  if (sidebarModeActive) {
    return (
      <FlowBox
        sx={{
          position: "relative",
          maxWidth: 2,
        }}
      >
        {showSidebarFlag ? (
          <ClickAwayListener
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            onClickAway={(ev) => handleSidebarToggle(ev, false)}
          >
            <Paper
              elevation={3}
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                flexDirection: "column",
                height: "100%",
                flexGrow: 1,
                maxWidth: 250,
                transition: "display 1s ease-in-out",
                zIndex: 10,
                px: 1,
                py: 1,
                //   zIndex: 3,
                ...DrawerSxProps,
              }}
            >
              <Box
                sx={{
                  height: centralTitleBarHeight,
                  ml: 10,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {TitleComponent}
              </Box>
              {children}
            </Paper>
          </ClickAwayListener>
        ) : (
          <></>
        )}
      </FlowBox>
    );
  } else {
    return (
      <FlowBox
        sx={{
          width: width,
          transition: "all 1s ease-in-out",
          paddingTop: `${centralTitleBarHeight}px`,
          ...sx,
        }}
      >
        {children}
      </FlowBox>
    );
  }
}
