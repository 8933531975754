// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { Fragment } from "react";
import {
  TextField,
  Box,
  Paper,
  Grid,
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  MobileStepper,
  IconButton,
  Toolbar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Send, ExpandMore, Delete, Add } from "@mui/icons-material";
import {
  FormProvider,
  useForm,
  Controller,
  useFieldArray,
} from "react-hook-form";
import ObjectID from "bson-objectid";
import { FlowBox } from "blocks/atoms/uistyles";
import {
  BackTitleWrapperPaper,
  MuiSelectFormField,
  MuiSelectDocketFormField,
  LoadingSpinner,
} from "blocks/atoms";
import { uploadFilesUsingSignedUrlToS3 } from "utils/uploadFileS3";
import { ArrowLeftIcon, ArrowRightIcon } from "blocks/atoms/trrysticons";
import {
  useUsersInSuite,
  useAppContext,
  populateUsers,
  useHandleResolutionActions,
  createSelectOptions,
  checkIfNull,
  useDocketsInSuite,
  useDockets,
  useDeleteFilesS3,
  useUploadFilesS3,
  useSelectedBucketId,
} from "@app21/core";

import processSimpleSurveyBuilder from "blocks/modules/Surveys/features/SimpleSurveyBuilder/processSimpleSurveyBuilder";
import QuestionCard from "blocks/modules/Surveys/features/SimpleSurveyBuilder/QuestionCard";

import MessagePanel from "blocks/atoms/uicomponents/MessagePanel";
import Slider from "react-slick";
import { useSurveysContext } from "providers/SurveysProvider";

const SimpleSurveyBuilder = ({}) => {
  let surveyCarouselRef = React.useRef(null);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const {
    selectedSuiteId,
    selectedDocketId,
    selectedOrganizationId,
    checkAccessRules,
    userInfo,
  } = useAppContext();
  const { isDocket, selectedDecisionId, selectedDecision, loadRoute } =
    useSurveysContext();
  const { data: usersInSuite, status: usersInSuiteStatus } =
    useUsersInSuite(selectedSuiteId);
  const { data: docketsInSuite, status: docketsInSuiteStatus } =
    useDocketsInSuite(selectedSuiteId);
  const selectedBucketId = useSelectedBucketId(null, true);
  const deleteFilesS3 = useDeleteFilesS3();
  const uploadFilesS3 = useUploadFilesS3();

  const isNewSurvey = checkIfNull(selectedDecisionId);
  const { data: selectedDocket, status: selectedDocketStatus } =
    useDockets(selectedDocketId);
  const usersInDocket = populateUsers(selectedDocket?.members, usersInSuite);
  const isDocketOwner = userInfo?._id === selectedDocket?.createdBy;
  const nonGuestList = (
    populateUsers(selectedDocket?.members, usersInSuite) ?? []
  ).filter((user) => user?.roleInfo?.role !== "Guest");

  const { handleResolutionActions } = useHandleResolutionActions();
  let defaultSurvey = selectedDecision ?? {};

  const { accessFlag: checkCanEdit } = checkAccessRules({
    entity: "SURVEYS",
    action: "UPDATE-SURVEY",
    featureName: "SURVEYS",
    isCreator: selectedDecision?.createdBy === userInfo?._id ?? false,
    isInvitee:
      selectedDecision?.survey?.decisionInvitees?.includes(userInfo?._id) ??
      false,
  });
  const { accessFlag: canAddNew } = checkAccessRules({
    entity: "SUITE",
    action: "CREATE-SURVEY",
    featureName: "SURVEYS",
    isCreator: false,
    isInvitee: true,
  });

  const blankObject = {
    questionItem: "",
    answerChoices: [
      { choice: "For" },
      { choice: "Against" },
      { choice: "No preference" },
    ],
    commentsBoxQuestion: true,
  };

  const basicQuestion = {
    decisionName: checkIfNull(defaultSurvey)
      ? isDocket
        ? `Resolutions - ${selectedDocket?.title} `
        : ""
      : defaultSurvey?.survey?.decisionName,
    docketId: isDocket
      ? selectedDocketId
      : defaultSurvey?.survey?.docketId ?? null,
    decisionInvitees:
      (checkIfNull(defaultSurvey)
        ? isDocket
          ? createSelectOptions(populateUsers(nonGuestList, usersInSuite))
          : []
        : createSelectOptions(
            populateUsers(defaultSurvey?.survey?.decisionInvitees, usersInSuite)
          )) ?? [],
    questionBlock: (checkIfNull(defaultSurvey)
      ? [blankObject]
      : defaultSurvey?.survey?.questionBlock) ?? [blankObject],
  };

  const methods = useForm({ mode: "onChange", defaultValues: basicQuestion });
  const { handleSubmit, formState, reset, control, setValue, watch, trigger } =
    methods;
  const { isDirty, errors, isSubmitting, isValid } = formState;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "questionBlock",
  });

  let isDocketSelected = Boolean(watch("docketId"));
  React.useEffect(() => {
    reset(basicQuestion);
  }, [reset, selectedDecision]);

  const handleQuestionChange = (panelIndex) => (event, isExpanded) => {
    setActiveIndex(isExpanded ? panelIndex : false);
  };

  const handleIndexChange = (index) => setActiveIndex(index);

  const handleGoto = async (index) => {
    handleIndexChange(index);
    surveyCarouselRef.current?.slickGoTo(index);
  };

  const handlePrevious = async () => {
    surveyCarouselRef.current?.slickGoTo(activeIndex - 1);
    handleIndexChange(activeIndex - 1);
  };

  const handleNext = async () => {
    await trigger(`responses[${activeIndex}].choice`, { shouldFocus: true });
    if (!errors?.responses?.[activeIndex]?.choice) {
      surveyCarouselRef.current?.slickGoTo(activeIndex + 1);
      handleIndexChange(activeIndex + 1);
    }
  };

  const sliderProps = {
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  const onSubmit = async (data) => {
    const localDecisionId = ObjectID().toHexString();

    let deletedS3Keys = [];
    let addedFiles = [];

    //  const updatedAgendaItems = data.agenda;

    const s3Prefix = isDocket
      ? `${selectedOrganizationId}/${selectedSuiteId}/dockets/${selectedDocketId}/resolutions/${
          selectedDecisionId || localDecisionId
        }/`
      : `${selectedOrganizationId}/${selectedSuiteId}/resolutions/${
          selectedDecisionId || localDecisionId
        }/`;

    data.questionBlock.map((question, index) => {
      let deletedS3KeysInQuestion = [];
      let addedFilesInQuestion = [];
      question.files.map((i) => {
        if (i.deleted)
          i.deleted.map((s3Key) => {
            deletedS3KeysInQuestion.push(s3Key);
          }); // summation of all deleted keys across Questions
        if (i.added) {
          i.added.map((file) => {
            addedFilesInQuestion.push({ file: file.file });
          });
        }
      });
      // update the question files now that we have fully processed added and deleted files.
      const updatedListOfFiles = [];

      question.files.map((i) => {
        if (
          Object.prototype.hasOwnProperty.call(i, "s3Key") &&
          !deletedS3KeysInQuestion.includes(i.s3Key)
        )
          updatedListOfFiles.push(i);
      });

      addedFilesInQuestion.map((a) => {
        updatedListOfFiles.push({
          s3Key: `${s3Prefix}${a.file.name}`,
          image: null,
        });
      });

      data.questionBlock[index].files = updatedListOfFiles;
      deletedS3KeysInQuestion.length > 0 &&
        deletedS3Keys.push(...deletedS3KeysInQuestion);
      addedFilesInQuestion.length > 0 &&
        addedFiles.push(...addedFilesInQuestion);
    });

    // update the deletedS3Keys to match format requested by useDeleteFilesS3 hook.
    //const updatedDeletedS3Keys = [];
    //deletedS3Keys.map((k) => updatedDeletedS3Keys.push({ id: k }));

    if (deletedS3Keys.length > 0)
      await deleteFilesS3.mutate({
        bucket: selectedBucketId,
        filesList: deletedS3Keys,
        s3Prefix: s3Prefix,
      });

    await uploadFilesUsingSignedUrlToS3(
      addedFiles,
      s3Prefix,
      selectedBucketId,
      userInfo._id
    );

    await Promise.all(
      addedFiles.map(async (elem, index) => {
        await uploadFilesS3.mutate({
          fileList: [elem.file.name], // just name is sufficient as upload will happen through SignedUrl
          bucket: selectedBucketId,
          key: s3Prefix,
          // update files at this prefix in the react-query cache
          s3Prefix: s3Prefix,
        });
      })
    );

    let processedData = processSimpleSurveyBuilder(
      data,
      selectedSuiteId,
      defaultSurvey
    );

    checkIfNull(defaultSurvey)
      ? await handleResolutionActions(
          {
            action: isDocket ? "ADD-RESOLUTION" : "ADD-SURVEY",
            decisionId: localDecisionId,
            ...(isDocket
              ? { processedResolutionData: processedData }
              : { processedSurveyData: processedData }),
          },
          null,
          null
        )
      : await handleResolutionActions(
          {
            action: isDocket ? "UPDATE-RESOLUTION" : "UPDATE-SURVEY",
            ...(isDocket
              ? { processedResolutionData: processedData }
              : { processedSurveyData: processedData }),
            decisionId: defaultSurvey?._id,
            surveyCreatedBy: defaultSurvey.createdBy,
          },
          null,
          null
        );
    reset(basicQuestion);

    isDocket
      ? loadRoute("VIEW-RESOLUION", {
          ...{
            orgId: selectedOrganizationId,
            suiteId: selectedSuiteId,
            docketId: selectedDocketId,
            tabValue: "resolutions",
            decisionId: null,
          },
        })
      : loadRoute("VIEW-SURVEY", { decisionId: localDecisionId });
  };

  const handleDelete = async (index) => {
    remove(index);

    if (index > 0) handlePrevious();
    if (index === 0) {
      handleGoto(0);
    }
  };

  if (usersInSuiteStatus === "loading" || docketsInSuiteStatus === "loading") {
    return <LoadingSpinner />;
  } else if (!isNewSurvey && !checkCanEdit) {
    return (
      <MessagePanel
        message={
          <Typography variant="subtitle2" color="textSecondary">
            {`Sorry! You cannot edit this ${
              isDocket ? "resolution" : "survey"
            }.`}
          </Typography>
        }
      />
    );
  } else if (isNewSurvey && !canAddNew) {
    return (
      <MessagePanel
        message={
          <Typography variant="subtitle2" color="textSecondary">
            {`Sorry! You cannot create a ${
              isDocket ? "resolution" : "survey"
            } here.`}
          </Typography>
        }
      />
    );
  } else if (
    !isNewSurvey &&
    Object.keys(selectedDecision?.responses ?? {})?.length > 0
  ) {
    return (
      <MessagePanel
        message={
          <Typography variant="h6" color="textSecondary">
            {`Sorry! You cannot edit a ${
              isDocket ? "resolution" : "survey"
            } after there has been atleast one
            response.`}
          </Typography>
        }
      />
    );
  } else
    return (
      <BackTitleWrapperPaper
        data-testid="SimpleSurveyBuilder"
        title={
          <Typography variant="h5" sx={{ flexGrow: 1, textAlign: "center" }}>
            {isNewSurvey ? "Create your " : "Edit "}
            {isDocket ? "Resolution" : "Survey"}
          </Typography>
        }
        ActionButton={LoadingButton}
        handleActionClick={handleSubmit(onSubmit)}
        actionButtonTitle="Submit"
        actionButtonProps={{
          variant: "contained",
          size: "small",
          sx: { ml: 10 },
          type: "submit",
          endIcon: <Send />,
          loading: isSubmitting,
          loadingPosition: "end",
          disabled: !isDirty || !isValid || fields.length === 0,
        }}
      >
        <FormProvider {...methods}>
          <form>
            <Card
              sx={{
                p: 0,
                mt: 1,
                display: "flex",
                flexDirection: "column",
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                pb: 1,
              }}
            >
              <CardHeader
                sx={{
                  p: 2,
                  "& .MuiCardHeader-root": {
                    backgroundColor: "rgba(55, 55, 55, 0.1)",
                  },
                }}
                title={
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12} md={4}>
                      <Typography variant="subtitle1">
                        {isDocket
                          ? "Resolution(s) title"
                          : "A brief title for your poll"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Controller
                        control={control}
                        name={"decisionName"}
                        rules={{
                          required: { value: true, message: "Required" },
                        }}
                        render={({ field }) => {
                          return (
                            <TextField
                              {...field}
                              disabled={isDocket}
                              size="small"
                              fullWidth
                              placeholder={`Enter a brief name for your ${
                                isDocket ? "resolution" : "survey"
                              }.`}
                              InputProps={{
                                sx: {
                                  backgroundColor: (theme) =>
                                    theme.palette.background.paper,
                                },
                              }}
                              error={!!errors?.decisionName}
                              helperText={errors?.decisionName?.message}
                            />
                          );
                        }}
                      />
                    </Grid>
                    {!isDocket && (
                      <>
                        <Grid item xs={12} md={4}>
                          <Typography variant="subtitle1">
                            Link to Breakout group (optional)
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <Controller
                            name={"docketId"}
                            control={control}
                            rules={{
                              required: { value: false, message: "Optional" },
                            }}
                            render={({ field: { ref, ...rest } }) => (
                              <MuiSelectDocketFormField
                                disabled={isDocket}
                                refs={ref}
                                listOfDockets={docketsInSuite}
                                placeholder="Link to a Breakout (optional)"
                                inputValue={""}
                                size="small"
                                {...rest}
                                onChange={(data) => {
                                  let selectedDocket = docketsInSuite?.find(
                                    (docket) => docket._id === data
                                  );
                                  setValue("docketId", data, {
                                    shouldDirty: true,
                                  });
                                  setValue(
                                    "decisionInvitees",
                                    createSelectOptions(
                                      populateUsers(
                                        selectedDocket?.members,
                                        usersInSuite
                                      )
                                    ),
                                    { shouldDirty: true }
                                  );
                                }}
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12} md={4}>
                      <Typography variant="subtitle1">Respondents</Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Controller
                        control={control}
                        name={"decisionInvitees"}
                        rules={{
                          required: { value: true, message: "Required" },
                        }}
                        render={({ field: { ref, ...rest } }) => (
                          <MuiSelectFormField
                            listOfUsers={usersInSuite}
                            refs={ref}
                            disabled={isDocket || isDocketSelected}
                            placeholder={
                              isDocketSelected
                                ? ""
                                : `Select ${
                                    isDocket ? "Resolution" : "Survey"
                                  } Respondents`
                            }
                            size="small"
                            limitTags={10}
                            allowGuests={false}
                            inputValue={""}
                            onChange={(data) => {
                              setValue("decisionInvitees", data, {
                                shouldDirty: true,
                              });
                            }}
                            {...rest}
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                }
              />

              <CardContent
                sx={{
                  p: 0,
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  maxHeight: "calc(100vh - 150px)",
                }}
              >
                <Box sx={{ width: "100%", display: "flex", my: 2 }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ flexGrow: 1, textAlign: "center", p: 1 }}
                  >
                    {isDocket ? "Resolution(s)" : "Survey Questions"}
                  </Typography>
                </Box>
                {fields.map((question, index) => {
                  return (
                    <Accordion
                      key={question.id}
                      expanded={activeIndex === index}
                      onChange={handleQuestionChange(index)}
                      transitionProps={{ unmountOnExit: false }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        sx={{ flexDirection: "row" }}
                      >
                        <Typography
                          variant="h6"
                          sx={{ color: "text.secondary", flexGrow: 1 }}
                        >
                          Q{index + 1} .{" "}
                          <span style={{ marginLeft: 10 }}>
                            {" "}
                            {question.questionItem}
                          </span>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Paper sx={{ px: 3 }}>
                          <QuestionCard
                            question={question}
                            index={index}
                            id={question.id}
                            handleDelete={handleDelete}
                            isDocket={isDocket}
                            totalQuestionsCount={fields.length}
                          />
                        </Paper>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
                <Box
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    type="button"
                    variant="outlined"
                    style={{ textTransform: "none" }}
                    disabled={!isValid}
                    startIcon={<Add />}
                    onClick={() => {
                      append(blankObject);
                      handleNext();
                    }}
                    sx={{ my: 2 }}
                  >
                    Add Question
                  </Button>
                </Box>

                {/*<Slider
                    ref={(el) => {
                      surveyCarouselRef.current = el;
                    }}
                    {...sliderProps}
                  >
                    {fields.map((question, index) => {
                      return (
                        <Fragment key={question.id}>
                          <Toolbar
                            variant="dense"
                            sx={{
                              mt: 3,
                              display: "flex",
                              justifyContent: "space-between",
                              bgcolor: "gray.lighter",
                            }}
                          >
                            {index >= 1 ? (
                              <IconButton onClick={handlePrevious}>
                                <ArrowLeftIcon />
                              </IconButton>
                            ) : (
                              <Box />
                            )}
                            <Typography variant="h6">
                              Question {index + 1}
                            </Typography>
                            {index < fields.length ? (
                              <Box />
                            ) : (
                              <IconButton onClick={handleNext}>
                                <ArrowRightIcon />
                              </IconButton>
                            )}
                          </Toolbar>
                          <Paper sx={{ px: 3 }}>
                            <QuestionCard
                              question={question}
                              index={index}
                              id={question.id}
                              handleDelete={handleDelete}
                              isDocket={isDocket}
                              totalQuestionsCount={fields.length}
                            />
                          </Paper>
                        </Fragment>
                      );
                    })}
                  </Slider>
                  <MobileStepper
                    steps={fields.length}
                    variant="text"
                    position="static"
                    sx={{}}
                    activeStep={activeIndex}
                    nextButton={
                      <Button size="small" onClick={handleNext}>
                        Next
                      </Button>
                    }
                    backButton={
                      <Button
                        size="small"
                        onClick={handlePrevious}
                        disabled={activeIndex <= 0}
                      >
                        Previous
                      </Button>
                    }
                  />*/}
              </CardContent>
              <CardActions
                sx={{
                  mt: 7,
                  py: 0,
                  px: 3,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              ></CardActions>
            </Card>
          </form>
        </FormProvider>
      </BackTitleWrapperPaper>
    );
};

export default SimpleSurveyBuilder;
