import React from 'react'
import { SvgIcon } from '@mui/material';

export default function AddToCalendarIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 103"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M12.4999 55.5093V36.2663C12.4999 23.4661 18.7499 14.9326 33.3333 14.9326H66.6666C81.2499 14.9326 87.4999 23.4661 87.4999 36.2663V69.8028C84.4583 66.2614 79.9999 64.0001 74.9999 64.0001C65.7916 64.0001 58.3333 71.6375 58.3333 81.067C58.3333 84.2671 59.2083 87.2965 60.7499 89.8565C61.6249 91.3925 62.7499 92.7579 64.0416 93.8672H33.3333C18.7499 93.8672 12.4999 85.3338 12.4999 72.5336"
          stroke="currentColor"
          strokeWidth="6.10517"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.3331 8.53418V21.3344"
          stroke="currentColor"
          strokeWidth="6.10517"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M66.6667 8.53418V21.3344"
          stroke="currentColor"
          strokeWidth="6.10517"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.5833 38.7842H85.4166"
          stroke="currentColor"
          strokeWidth="6.10517"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M75.0001 98.1358C84.2048 98.1358 91.6668 90.4947 91.6668 81.0689C91.6668 71.6431 84.2048 64.002 75.0001 64.002C65.7954 64.002 58.3334 71.6431 58.3334 81.0689C58.3334 90.4947 65.7954 98.1358 75.0001 98.1358Z"
          stroke="currentColor"
          strokeWidth="6.10517"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M81.2076 81.2817H68.7909"
          stroke="currentColor"
          strokeWidth="6.10517"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M75 75.0508V87.8083"
          stroke="currentColor"
          strokeWidth="6.10517"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M49.9811 58.4527H50.0185"
          stroke="currentColor"
          strokeWidth="8.14023"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.5597 58.4527H34.5971"
          stroke="currentColor"
          strokeWidth="8.14023"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.5597 71.2559H34.5971"
          stroke="currentColor"
          strokeWidth="8.14023"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
