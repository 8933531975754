// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  Autocomplete,
  TextField,
  Chip,
  Avatar,
  Checkbox,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Tooltip,
  useTheme,
} from "@mui/material";

import {
  createSelectOptions,
  checkIfNull,
  getS3ImageWrapper,
} from "@app21/core";

import isEmail from "validator/lib/isEmail";

import PropTypes from "prop-types";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useFormContext } from "react-hook-form";
import { matchSorter } from "match-sorter";
import toast from "react-hot-toast";

import DraggablePaper from "blocks/atoms/uicomponents/DraggablePaper";
import { randomIdGenerator } from "utils";

const blankCheckIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MuiSelectFormField = (props) => {
  const {
    refs,
    label,
    listOfUsers,
    existingUsersList = [],
    placeholder,
    variant,
    limitTags,
    onChange,
    allowGuests,
    name,
    inputValue,
    helperText,
    ...rest
  } = props;

  const [inputText, setInputText] = React.useState(inputValue ?? "");
  const { watch, control, setValue, getValues, formState, setError } =
    useFormContext();
  const { errors } = formState;
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogValue, setDialogValue] = React.useState({
    fullName: "",
    emailId: "",
    _id: null,
    phoneNumber: null,
    image: null,
    newAddFlag: false,
  });

  const handleDialogClose = () => {
    setDialogValue({
      emailId: null,
      fullName: null,
      _id: null,
      phoneNumber: null,
      image: null,
      newAddFlag: false,
    });

    setDialogOpen(false);
  };
  const checkIfUserAlreadyExists = (emailId) => {
    if (existingUsersList.find((user) => user.emailId === emailId)) {
      return true;
    }
    return false;
  };
  const handleInviteeInfo = async (event) => {
    event.preventDefault();
    if (checkIfUserAlreadyExists(dialogValue?.emailId)) {
      toast.error("A user with emailId already is in the suite");
      handleDialogClose();
    } else {
      let newVisitorObj = {
        fullName: dialogValue?.fullName ?? dialogValue?.emailId,
        emailId: dialogValue?.emailId,
        _id: `guest-${randomIdGenerator({ length: 10 })}`,
        phoneNumber: "111",
        image: getS3ImageWrapper(
          "",
          "initials",
          dialogValue?.fullName ?? dialogValue?.emailId
        ),
        newAddFlag: false,
      };
      setValue(name, [...watch(name), newVisitorObj], {
        shouldValidate: true,
        shouldDirty: true,
      });

      handleDialogClose();
      onChange;
    }
  };

  const handleNewInviteeAdd = async (event, newValue, reason) => {
    if (
      !checkIfNull(newValue) &&
      (newValue[newValue?.length - 1].newAddFlag || reason === "createOption")
    ) {
      setDialogOpen(true);
      setDialogValue({
        fullName: isEmail(newValue[newValue?.length - 1]?.emailId || "A")
          ? ""
          : newValue[newValue?.length - 1].emailId,
        emailId: isEmail(newValue[newValue?.length - 1]?.emailId || "A")
          ? newValue[newValue?.length - 1].emailId
          : "",
      });
    } else {
      onChange(newValue);
    }
  };

  const handleInputChange = (event, value, reason) => {
    setInputText(value);
  };
  const formatOptionLabel = ({ fullName, avatar, image, key }) => (
    <div key={key} style={{ display: "flex", alignItems: "center", margin: 3 }}>
      <Avatar sx={{ height: 25, width: 25 }} src={avatar || image} />
      <div style={{ marginLeft: 10 }}>{fullName}</div>
    </div>
  );
  const filterOptions = (options, { inputValue }) => {
    return matchSorter(options, inputValue, {
      keys: ["fullName", "emailId"],
      keepDiacritics: true,
    });
  };

  let allOptions = createSelectOptions(listOfUsers);
  return (
    <>
      <Autocomplete
        sx={{
          "& .MuiAutocomplete-option": {
            "& > span": { marginRight: 10 },
          },
          "& .MuiAutocomplete-inputRoot": {
            backgroundColor: (theme) => theme.palette.background.default,
          },
        }}
        onChange={handleNewInviteeAdd}
        onInputChange={handleInputChange}
        ref={refs}
        multiple
        inputValue={inputText}
        selectOnFocus
        freeSolo={allowGuests}
        handleHomeEndKeys
        limitTags={limitTags}
        options={allOptions}
        disableCloseOnSelect
        getOptionLabel={(option) => option._id ?? option.emailId}
        isOptionEqualToValue={(option, value) => value._id === option._id}
        filterOptions={(options, params) => {
          const filtered = filterOptions(options, params);

          if (params.inputValue !== "" && allowGuests) {
            filtered.push({
              emailId: params.inputValue,
              fullName: `Add "${params.inputValue}"`,
              newAddFlag: true,
            });
          }

          return filtered;
        }}
        renderTags={(value, getTagProps) => {
          return value.map((option, index) => {
            return (
              <Tooltip key={index} title={option.emailId}>
                <Chip
                  variant="outlined"
                  avatar={
                    <Avatar
                      sx={{ height: 25, width: 25 }}
                      src={getS3ImageWrapper(option.image, "person")}
                    />
                  }
                  size="small"
                  label={
                    <Typography
                      variant="body2"
                      color="primary"
                      style={{ fontSize: 10, fontWeight: 400 }}
                    >
                      {checkIfNull(option.fullName)
                        ? option.emailId
                        : option.fullName}
                    </Typography>
                  }
                  {...getTagProps({ index })}
                  sx={{
                    backgroundColor:
                      option.id && !option.id.includes("guest")
                        ? "PaleYellow"
                        : null,
                  }}
                />
              </Tooltip>
            );
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={blankCheckIcon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {formatOptionLabel(option)}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            variant={variant}
            {...(label ? { label: label } : {})}
            placeholder={placeholder}
            InputLabelProps={{
              sx: { color: (theme) => theme.palette.text.primary },
            }}
            error={!!(errors && rest && errors[rest.name])}
            helperText={
              helperText ||
              (errors &&
                rest &&
                Boolean(errors[rest.name]?.message) &&
                errors[rest.name]?.message)
            }
          />
        )}
        {...rest}
      />
      <Dialog
        PaperComponent={DraggablePaper}
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="dragModal" sx={{ mb: 2 }}>
          Enter Invitee Details
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Please enter the name and emailId
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            value={dialogValue.fullName ?? ""}
            onChange={(event) =>
              setDialogValue({ ...dialogValue, fullName: event.target.value })
            }
            label="Name"
            type="text"
            style={{ marginRight: 10 }}
            variant="outlined"
          />
          <TextField
            margin="dense"
            id="email"
            value={dialogValue.emailId ?? ""}
            onChange={(event) =>
              setDialogValue({ ...dialogValue, emailId: event.target.value })
            }
            label="EMail Id"
            type="email"
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button
            disabled={
              !(dialogValue.emailId && isEmail(dialogValue.emailId || "A"))
            }
            onClick={handleInviteeInfo}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

MuiSelectFormField.propTypes = {
  onChange: PropTypes.func,
  listOfUsers: PropTypes.array,
  placeholder: PropTypes.string,
  variant: PropTypes.oneOf(["outlined", "contained", "default"]),
  limitTags: PropTypes.number,
  allowGuests: PropTypes.bool,
  label: PropTypes.string,
};

MuiSelectFormField.defaultProps = {
  variant: "outlined",
  limitTags: 8,
  placeholder: "Add Meeting Invitees",
  allowGuests: false,
  label: "",
  listOfUsers: [],
};
export default MuiSelectFormField;
