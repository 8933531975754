import React from "react";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  MenuList,
} from "@mui/material";
import { VideocallIcon } from "blocks/atoms/trrysticons";
import {
  ArrowDropDown,
  VideoCall,
  Schedule,
  GroupAdd,
  Videocam,
} from "@mui/icons-material";
import {
  useAppContext,
  useHandleRouterPush,
  useHandleAddRemoveIdFromRoute,
} from "@app21/core";
import { useRouter } from "next/router";
import { handleWalkinCall } from "blocks/atoms";
import { useCheckActiveLink } from "hooks";
import { useVideocallContext } from "providers/TrrystVideocallProvider";

export default function ScheduleMeetinDropdownButton({
  isMobile = false,
  docketId = null,
  sx = {},
  TextButtonProps = {},
  ...rest
}) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const { selectedSuiteId, userInfo, checkAccessRules } = useAppContext();
  const { setChimeRoute } = useVideocallContext();
  const router = useRouter();
  const { loadRoute } = useHandleRouterPush();
  const { addIdToRoute } = useHandleAddRemoveIdFromRoute();
  const isConferenceLinkActive = useCheckActiveLink("JOIN-MEETING") ?? false;
  const { accessFlag: canstartWalkinCall } = checkAccessRules({
    entity: "SUITE",
    action: "START-WALKINCALL",
    featureName: "VIDEOCALL",
    isInvitee: true,
  });

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleWalkinVideoCall = () => {
    setChimeRoute("base");
    handleWalkinCall({
      docketId,
      userInfo,
      selectedSuiteId,
      addIdToRoute,
      router,
    });
  };

  const options = [
    {
      label: "Start a Walk-In meeting now",
      icon: VideoCall,
      disabledFlag: isConferenceLinkActive,
      accessFlag: canstartWalkinCall,
      action: () => handleWalkinVideoCall(),
    },
    {
      label: "Schedule meeting for Later",
      icon: Schedule,
      accessFlag: true,
      action: () => loadRoute("SCHEDULE-VIDEOCALL", {}),
      disabledFlag: false,
    },
    {
      label: "Create a breakout Group",
      icon: GroupAdd,
      accessFlag: true,
      action: () => loadRoute("CREATE-DOCKET", {}),
      disabledFlag: false,
    },
  ];

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
        sx={{
          borderRadius: (theme) => theme.shape.borderRadiusButton,
          ...sx,
        }}
        {...rest}
      >
        <Button
          data-testid="dashboard-meeting-button"
          {...(!isMobile
            ? { startIcon: <VideocallIcon fontSize="inherit" /> }
            : {})}
          onClick={handleWalkinVideoCall}
          sx={{ "&:hover": { color: "secondary.main" } }}
          {...TextButtonProps}
        >
          {isMobile ? <VideocallIcon fontSize="inherit" /> : "MEETING"}
        </Button>
        <Button
          data-testid="dashboard-dropdown-button"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select meeting option"
          aria-haspopup="menu"
          onClick={handleToggle}
          sx={{
            borderTopRightRadius: (theme) => theme.shape.borderRadiusButton,
            borderBottomRightRadius: (theme) => theme.shape.borderRadiusButton,
            "&:hover": { color: "secondary.main" },
          }}
        >
          <ArrowDropDown fontSize="inherit" />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 100,
        }}
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-end"
        role={undefined}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options
                    .filter((option) => option.accessFlag)
                    .map((option, index) => (
                      <ListItem
                        data-testid="dashboard-meeting-dropdown"
                        key={option.label}
                        disableGutters
                        disablePadding
                      >
                        <ListItemButton
                          selected={index === selectedIndex}
                          disabled={option.disabledFlag}
                          onClick={(event) => {
                            handleMenuItemClick(event, index);
                            option.action();
                          }}
                        >
                          <ListItemIcon>
                            <option.icon />
                          </ListItemIcon>
                          <ListItemText>{option.label}</ListItemText>
                        </ListItemButton>
                      </ListItem>
                    ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
