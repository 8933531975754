import _ from "lodash";

//function below is to replace the term dockets with the term breakouts throughout the file structure

export function docketsDeepReplaceRecursive(
  oldEntry,
  newEntry,
  object,
  docketsInSuite
) {
  const newObj = _.clone(object);

  _.each(object, (val, key) => {
    if (val === oldEntry) {
      // replace val if it's equal to the old value
      newObj.origKey = newObj.origKey ? newObj.origKey : oldEntry;
      newObj[key] = newEntry;
    } else if (docketsInSuite?.find((d) => d._id === val)) {
      newObj.origKey = docketsInSuite?.find((d) => d._id === val)._id;
      newObj[key] = docketsInSuite?.find((d) => d._id === val).title;
    } else if (typeof val === "object" || typeof val === "array") {
      // if val has nested values, make recursive call
      newObj[key] = docketsDeepReplaceRecursive(
        oldEntry,
        newEntry,
        val,
        docketsInSuite
      );
    }
  });
  return newObj;
}
