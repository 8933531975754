import React from "react";
import { TextField, IconButton, Box, InputAdornment } from "@mui/material";
import { Clear, Search } from "@mui/icons-material";

export default function ExpandingSearchIconButton({
  searchQuery = "",
  handleSearchQuery,
  position = "right",
}) {
  const [showTextfield, setShowTextfield] = React.useState(searchQuery !== "");

  const handleKeypressChange = (event) => {
    event.persist();
    handleSearchQuery(event.target.value);
  };

  const getDirection = (position) => {
    switch (position) {
      case "left":
        return "row-reverse";
      case "right":
        return "row";
      case "top":
        return "column";
      case "bottom":
        return "column-reverse";
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: getDirection(position),
        gap: 1,
      }}
    >
      <IconButton
        onClick={() => {
          setShowTextfield(!showTextfield);
          handleSearchQuery("");
        }}
      >
        <Search />
      </IconButton>
      <TextField
        sx={{ display: showTextfield ? "block" : "none" }}
        size="small"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="small"
                onClick={() => {
                  handleSearchQuery("");
                  setShowTextfield(!showTextfield);
                }}
              >
                <Clear fontSize="inherit" />
              </IconButton>
            </InputAdornment>
          ),
          // ),
        }}
        onChange={handleKeypressChange}
        placeholder="Search this transcript"
        value={searchQuery}
      />
    </Box>
  );
}
