// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import React from "react";
import {
  Box,
  Typography,
  Divider,
  IconButton,
  Tooltip,
  ListItemIcon,
  ListItem,
  Chip,
  Modal,
} from "@mui/material";
import { useVideocallContext } from "providers/TrrystVideocallProvider";
import { checkIfNull, useAppContext, useMeeting } from "@app21/core";
import { useMediaStreamMetrics } from "amazon-chime-sdk-component-library-react";
import { showTimeDuration } from "utils/formatTimeUtils";
import CopyMeetingLinkControl from "../CopyMeetingLinkControl";
import PerfectScrollbar from "react-perfect-scrollbar";
import { FlowBox } from "blocks/atoms/uistyles";
import {
  StopIcon,
  CopyIcon,
  SpeakingIcon,
  RecordingsIcon,
  MicOffIcon,
  PresentingIcon,
  CameraOnIcon,
  CamOffIcon,
} from "blocks/atoms/trrysticons";
import { pxToRem } from "utils/styleHelpers";
import AttendeesRoster from "./AttendeesRoster";
import { useIsOnline } from "hooks";

function formatMetric(metric) {
  return metric ? `${(metric / 1000).toFixed(2)} Mbps` : null;
}
function formatBitrate(metric) {
  return metric ? `${(metric / 1000000).toFixed(2)} Mbps` : null;
}

const MeetingInfoSnippet = ({ popupState = {} }) => {
  const metrics = useMediaStreamMetrics();
  const { isOffline } = useIsOnline();

  const { chimeAttendeeId, meeting, meetingId } = useVideocallContext();
  const selfVideocallMetrics = chimeAttendeeId
    ? metrics.videoStreamMetrics[chimeAttendeeId]
    : {};

  const processedSelfVideocallMetrics = !checkIfNull(selfVideocallMetrics)
    ? Object.values(selfVideocallMetrics)
    : [{}];

  const getAudioSignalQuality = () => {
    const audioSignalStrengthPoorQuality =
      metrics.audioPacketsSentFractionLossPercent > 30 ||
      metrics.audioPacketsReceivedFractionLossPercent > 30;

    const signalQuality =
      audioSignalStrengthPoorQuality || isOffline ? "Poor" : "Good";

    return signalQuality;
  };

  const getVideoSignalQuality = () => {
    const videoSignalStrengthPoorQuality =
      processedSelfVideocallMetrics[0].videoDownstreamPacketLossPercent > 30 ||
      processedSelfVideocallMetrics[0].videoUpstreamPacketLossPercent > 30;
    const signalQuality =
      videoSignalStrengthPoorQuality || isOffline ? "Poor" : "Good";

    return signalQuality;
  };
  const getSignalQualityAttributes = (signalQuality) => {
    if (signalQuality === "Good") {
      return {
        color: "success",
        label: "Good",
      };
    } else if (signalQuality === "Fair") {
      return {
        color: "warning",
        label: "Fair",
      };
    } else if (signalQuality === "Poor") {
      return {
        color: "error",
        label: "Poor",
      };
    } else {
      return {
        color: "inherit",
        label: "Unknown",
      };
    }
  };
  return (
    <FlowBox
      sx={{
        boxShadow: 24,
        bgcolor: "background.paper",
        overflow: "hidden",
        width: "100%",
        minWidth: 330,
        maxHeight: "85vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          m: 3,
          alignItems: "center",
        }}
      >
        <Typography variant="h5">Meeting Information</Typography>
        <IconButton onClick={() => popupState?.close()} sx={{ p: 0 }}>
          <StopIcon />
        </IconButton>
      </Box>
      <Divider sx={{ display: "flex", flexGrow: 1, flex: 1 }} />
      <FlowBox sx={{ m: 1, ml: 3, overflow: "hidden" }}>
        <PerfectScrollbar
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            height: "100%",
            width: "100%",
          }}
        >
          <Box
            sx={{
              mt: 2,
            }}
          >
            <AttendeesRoster />
          </Box>
          {meeting?.data?.meetingJSON?.start && (
            <Box sx={{ mt: 3 }}>
              <Typography variant="subtitle2">Duration</Typography>
              <ListItem
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  p: 0,
                  mt: 0.5,
                }}
              >
                <Typography variant="body2">Session Time</Typography>
                <Chip
                  label={showTimeDuration(
                    new Date(meeting?.data?.state?.startedAt),
                    Date.now()
                  )}
                  size="small"
                  sx={{ ml: 2, mt: 1 }}
                />
              </ListItem>
            </Box>
          )}
          <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle2">Meeting Bandwith</Typography>
            <ListItem
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                p: 0,
                mt: 0.5,
              }}
            >
              <Typography variant="body2">
                Available Incoming Bandwith
              </Typography>
              <Chip
                label={
                  formatMetric(metrics.availableIncomingBandwidth) ??
                  "unavailable"
                }
                size="small"
                sx={{ ml: 2, mt: 1 }}
              />
            </ListItem>
            <ListItem
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                p: 0,
                mt: 0.5,
              }}
            >
              <Typography variant="body2">
                Available Outgoing Bandwith
              </Typography>
              <Chip
                label={
                  formatMetric(metrics.availableOutgoingBandwidth) ||
                  "unavailable"
                }
                size="small"
                sx={{ ml: 2, mt: 1 }}
              />
            </ListItem>
          </Box>
          <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle2">Video Call</Typography>
            <ListItem
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                p: 0,
                mt: 0.5,
              }}
            >
              <Typography variant="body2">Video Stream Bitrate</Typography>
              <Chip
                label={
                  formatBitrate(
                    processedSelfVideocallMetrics[0].videoDownstreamBitrate
                  ) ??
                  formatBitrate(
                    processedSelfVideocallMetrics[0].videoUpstreamBitrate
                  ) ??
                  "unavailable"
                }
                size="small"
                sx={{ ml: 2, mt: 1 }}
              />
            </ListItem>
            <ListItem
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                p: 0,
                mt: 0.5,
              }}
            >
              <Typography variant="body2">
                Video Upstream Packet Loss
              </Typography>
              <Chip
                label={`${
                  processedSelfVideocallMetrics[0]
                    .videoDownstreamPacketLossPercent ??
                  processedSelfVideocallMetrics[0]
                    .videoUpstreamPacketLossPercent ??
                  "unavailable"
                } %`}
                size="small"
                sx={{ ml: 2, mt: 1 }}
              />
            </ListItem>
            <ListItem
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                p: 0,
                mt: 0.5,
              }}
            >
              <Typography variant="body2">Video Frames Encoding</Typography>
              <Chip
                label={`${
                  processedSelfVideocallMetrics[0]
                    .videoDownstreamFramesDecodedPerSecond ??
                  processedSelfVideocallMetrics[0]
                    .videoUpstreamFramesEncodedPerSecond ??
                  "unavailable"
                } fps`}
                size="small"
                sx={{ ml: 2, mt: 1 }}
              />
            </ListItem>
          </Box>
          <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle2">Signal Quality</Typography>
            <ListItem
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                p: 0,
                mt: 0.5,
              }}
            >
              <Typography variant="body2">Your Audio Signal Quality</Typography>
              <Chip
                label={
                  getSignalQualityAttributes(getAudioSignalQuality()).label
                }
                size="small"
                color={
                  getSignalQualityAttributes(getAudioSignalQuality()).color
                }
                sx={{ ml: 2, mt: 1 }}
              />
            </ListItem>
            <ListItem
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                p: 0,
                mt: 0.5,
              }}
            >
              <Typography variant="body2">Your Video Signal Quality</Typography>
              <Chip
                label={
                  getSignalQualityAttributes(getVideoSignalQuality()).label
                }
                size="small"
                color={
                  getSignalQualityAttributes(getVideoSignalQuality()).color
                }
                sx={{ ml: 2, mt: 1 }}
              />
            </ListItem>
          </Box>
        </PerfectScrollbar>
      </FlowBox>

      {/*<Typography variant="caption">VideoStream: {metrics.videoStreamMetrics || 'unavailable'}</Typography>*/}
    </FlowBox>
  );
};

export default MeetingInfoSnippet;
