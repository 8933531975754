// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { useState, useEffect, useRef } from "react";
//import { Document, Page } from 'react-pdf';
import { NoSsr, styled, Box, Stack, Tooltip } from "@mui/material";
import { ProgressBar, Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { useAppContext, useSuite } from "@app21/core";

import PrintIcon from "@mui/icons-material/Print";
import { Download, FileDownloadOffOutlined } from "@mui/icons-material";

const NoDownloadIcon = () => (
  <Tooltip title={"Suite Manager has disabled downloads in this group"}>
    <Box sx={{ color: "LightGrey" }}>
      <FileDownloadOffOutlined disabled />
    </Box>
  </Tooltip>
);

const CustomPageLayer = ({ renderPageProps, currentDocument }) => {
  React.useEffect(() => {
    // Mark the page rendered completely when the canvas layer is rendered completely
    // So the next page will be rendered
    if (renderPageProps.canvasLayerRendered) {
      renderPageProps.markRendered(renderPageProps.pageIndex);
    }
  }, [renderPageProps.canvasLayerRendered]);

  return (
    <>
      {renderPageProps.canvasLayer.children}
      <div
        style={{
          alignItems: "center",
          display: "flex",
          height: "100%",
          justifyContent: "center",
          left: 0,
          position: "absolute",
          top: 0,
          width: "100%",
        }}
      >
        <div
          style={{
            color: "rgba(0, 0, 0, 0.2)",
            fontSize: `${3 * renderPageProps.scale}rem`,
            fontWeight: "bold",
            textTransform: "none",
            transform: "rotate(-45deg)",
            userSelect: "none",
          }}
        >
          {currentDocument?.documentProtectParams?.protectFlag
            ? currentDocument?.documentProtectParams?.protectText ??
              "CONFIDENTIAL"
            : ""}
        </div>
      </div>
      {renderPageProps.annotationLayer.children}
      {currentDocument?.documentProtectParams?.protectFileDownload
        ? null
        : renderPageProps.textLayer.children}
    </>
  );
};

const CustomPDFRenderer = ({ mainState: { currentDocument } }) => {
  const StyledBox = styled(Box)(({ theme }) => ({
    height: "100%",
    width: "100%",

    "@page": {
      margin: 0,
    },
  }));

  const renderPage = (props) => (
    <CustomPageLayer
      renderPageProps={props}
      currentDocument={currentDocument}
    />
  );

  const renderToolbar = (Toolbar) => (
    <Toolbar>
      {(slots) => {
        const {
          CurrentPageInput,
          Download,
          EnterFullScreen,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          Print,
          ShowSearchPopover,
          Zoom,
          ZoomIn,
          ZoomOut,
        } = slots;
        return (
          <div
            style={{
              alignItems: "center",
              display: "flex",
              width: "100%",
            }}
          >
            <div style={{ padding: "0px 2px" }}>
              <ShowSearchPopover />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <ZoomOut />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <Zoom />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <ZoomIn />
            </div>
            <div style={{ padding: "0px 2px", marginLeft: "auto" }}>
              <GoToPreviousPage />
            </div>
            <div style={{ padding: "0px 2px", width: "4rem" }}>
              <CurrentPageInput />
            </div>
            <div style={{ padding: "0px 2px" }}>
              / <NumberOfPages />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <GoToNextPage />
            </div>
            <div style={{ padding: "0px 2px", marginLeft: "auto" }}>
              <EnterFullScreen />
            </div>

            {/* <div style={{ padding: "0px 2px" }}>
              {selectedSuite?.settings?.protectSuiteFilesDownload != true ? (
                <Download />
              ) : (
                <NoDownloadIcon />
              )}
            </div> */}

            {/* <div style={{ padding: '0px 2px' }}>
              <Print />
            </div> */}
          </div>
        );
      }}
    </Toolbar>
  );
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
  });

  return (
    <NoSsr>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <StyledBox sx={{ displayPrint: "none !important" }}>
          {/* Note : It's very important to note that the worker version and the `pdfjs` package (mentioned in the Install pdfjs library section of Getting started) have to be the same.*/}
          <Viewer
            fileUrl={currentDocument.uri}
            renderPage={
              currentDocument?.documentProtectParams?.protectFlag && renderPage
            }
            // plugins={[defaultLayoutPluginInstance]}
            renderLoader={(percentages) => (
              <div style={{ width: "240px" }}>
                <ProgressBar progress={Math.round(percentages)} />
              </div>
            )}
          />
        </StyledBox>
      </Worker>
    </NoSsr>
  );
};

CustomPDFRenderer.fileTypes = ["pdf", "application/pdf"];
CustomPDFRenderer.weight = 2;

export default CustomPDFRenderer;
