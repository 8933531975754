import React from "react";
import {
  TextField,
  Autocomplete,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
} from "@mui/material";

import { Search, FilterList, ArrowRightAlt, Clear } from "@mui/icons-material";
import { matchSorter } from "match-sorter";
import isEmpty from "validator/lib/isEmpty";

export default function SmartActionsSearchBar({
  baseActions = [],
  value = [],
  onFilterSelect = () => null,
  showClearButton = false,
  onClear = () => null,
}) {
  const [searchContentInput, setSearchContentInput] = React.useState("");
  const [selectedSearch, setSelectedSearch] = React.useState(null);

  const filterOptions = (options, { inputValue }) => {
    return matchSorter(options, inputValue, {
      keys: ["label"],
      keepDiacritics: true,
    });
  };
  const handleSearchContentChange = (event) => {
    setSearchContentInput(event.target.value);
  };
  const handleSearchContentKeypress = (event) => {
    if (
      event.keyCode === 13 &&
      !isEmpty(searchContentInput || "", { ignore_whitespace: true })
    ) {
      //handleOnSearchClick();
    }
  };
  const handleResetSearchContent = () => {
    setSearchContentInput("");
    setSelectedSearch(null);
  };

  const formatOptionLabel = (props, option) => {
    return (
      <ListItem {...props} dense key={option.value} divider>
        <ListItemText
          primary={option.label}
          secondaryTypographyProps={{ component: "span" }}
          secondary={<Box sx={{ display: "flex" }}></Box>}
        />
      </ListItem>
    );
  };

  const handleOptionClick = (event, value, reason, details) => {
    onFilterSelect(value);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        alignItems: "center",
      }}
    >
      <Autocomplete
        size="small"
        multiple
        fullWidth
        id="smart-actions-search"
        value={value}
        options={baseActions}
        filterOptions={filterOptions}
        onInputChange={handleSearchContentChange}
        onChange={handleOptionClick}
        onKeyUp={handleSearchContentKeypress}
        onClose={handleResetSearchContent}
        ListboxComponent={List}
        sx={{
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
          bgcolor: "background.paper",
          "& .MuiAutocomplete-inputRoot": {
            border: "1px solid lightgrey",
            borderRadius: 4,
            "& :hover": {
              outline: "none !important",
            },
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none !important",
            outline: "none !important",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: "none !important",
            outline: "none !important",
          },

          "& .MuiInput-underline:hover:not(.Mui-disabled):before ": {
            borderBottom: "0 !important",
            border: "none !important",
            outline: "none !important",
          },
        }}
        noOptionsText="No options found"
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => {
          if (typeof value === "object") {
            return option.value === value.value;
          } else return option.value === value;
        }}
        renderOption={(props, option) => formatOptionLabel(props, option)}
        renderInput={(params) => (
          <TextField
            {...params}
            style={{ width: "100%" }}
            placeholder="I want to..."
            variant="outlined"
            fullWidth
            inputProps={{
              ...params.inputProps,
              style: {
                border: "none",
              },
            }}
            InputProps={{
              ...params.InputProps,
              type: "search",
              startAdornment: <FilterList fontSize="inherit" sx={{ mr: 1 }} />,
              sx: {
                "& :hover": {
                  "& > fieldset": {
                    borderColor: "none",
                  },
                },
              },
            }}
          />
        )}
      />
      {showClearButton ? (
        <Button size="small" onClick={onClear}>
          Clear
        </Button>
      ) : null}
    </Box>
  );
}
