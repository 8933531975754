import React from 'react'
import { SvgIcon } from '@mui/material';

export default function InformationIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M50.0007 66.6685L50.0007 45.8348"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.667 24.9994C11.4586 31.9579 8.33356 40.6247 8.33356 49.9998C8.33356 73.0002 27.0005 91.6672 50.0009 91.6672C73.0012 91.6672 91.6682 73.0002 91.6682 49.9998C91.6682 26.9995 73.0012 8.33252 50.0009 8.33252C44.0425 8.33252 38.334 9.58254 33.2089 11.8742"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M50.017 33.3345L49.9796 33.3345"
          stroke="currentColor"
          strokeWidth="8.23406"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
