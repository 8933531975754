import { SvgIcon } from "@mui/material";
import React from "react";

export default function AddUserIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M62.5831 12.5404C59.0831 9.87374 54.7498 8.33203 49.9998 8.33203C38.4998 8.33203 29.1665 17.6654 29.1665
        29.1654C29.1665 40.6654 38.4998 49.9987 49.9998 49.9987C61.4998 49.9987 70.8332 40.6654 70.8332 29.1654"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.209 91.6667C14.209 75.5417 30.2506 62.5 50.0006 62.5C54.0006 62.5 57.8757 63.0416 61.5007 64.0416"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M91.6668 74.9987C91.6668 76.332 91.5002 77.6237 91.1668 78.8737C90.7918 80.5403 90.1252 82.1654 89.2502 83.582C86.3752 88.4154 81.0835 91.6654 75.0002 91.6654C70.7085 91.6654 66.8335 90.0402 63.9168 87.3736C62.6668 86.2902 61.5834 84.9987 60.7501 83.582C59.2084 81.082 58.3335 78.1237 58.3335 74.9987C58.3335 70.4987 60.1252 66.3738 63.0418 63.3738C66.0835 60.2488 70.3335 58.332 75.0002 58.332C79.9168 58.332 84.3752 60.4571 87.3752 63.8738C90.0418 66.8321 91.6668 70.7487 91.6668 74.9987Z"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M81.2085 74.9165H68.7919"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M74.993 68.8335V81.2918"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
