import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  styled,
  Card,
  CardHeader,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import {
  useAppContext,
  useApp21ProductsAndCoupons,
  app21APIInstance,
  checkIfNull,
} from "@app21/core";
import { LoadingSpinner } from "blocks/atoms";
import toast from "react-hot-toast";
import { ShoppingCartCheckout } from "@mui/icons-material";

const StyledCard = styled(Card)(({ theme }) => ({
  width: "fit-content",
  height: "fit-content",

  display: "flex",
  position: "relative",
  alignItems: "center",
  flexGrow: 1,
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(2),
  },
}));

const InfoCard = ({ title = "", subheader = "", sx = {} }) => {
  return (
    <Card
      elevation={0}
      sx={{
        borderRadius: 0,
        background: "transparent",
        boxShadow: "none",

        ...sx,
      }}
    >
      <CardHeader
        title={title}
        subheader={subheader}
        sx={{ m: 0, p: 0, background: "transparent" }}
      />
    </Card>
  );
};

export default function TopupRequestButton({
  ButtonProps = {},
  DialogProps = {},
  handleClose = () => null,
  label = "Topup",
  ...rest
}) {
  const {
    data: app21ProductsAndCouponsData,
    status: app21ProductsAndCouponsDataStatus,
  } = useApp21ProductsAndCoupons();
  const [topupCode, setTopupCode] = React.useState(null);
  const { userInfo, selectedOrganizationId, selectedSuiteId } = useAppContext();

  const [topupOpen, setTopupOpen] = React.useState(false);

  const handleOptionSelect = (code) => setTopupCode(code);
  const handleTopupOpen = () => setTopupOpen(true);

  const handleTopupClose = () => {
    handleClose();
    setTopupCode(null);
    setTopupOpen(false);
  };
  const handleTopupSubmit = async (topup) => {
    const { data } = await app21APIInstance.post(
      "/stripe/payments/sessions/create",
      {
        calledBy: userInfo._id,
        suiteId: selectedSuiteId,
        orgId: selectedOrganizationId,
        type: "TOPUP",
        productId: app21ProductsAndCouponsData.topups[topupCode].productId,
        couponCode: "",
        priceId:
          app21ProductsAndCouponsData.topups[topupCode].pricing.GBP.priceId,
      }
    );
    if (data && data.sessionURL) {
      window.location.href = data.sessionURL;
    } else toast.error("Sorry! Something went wrong with the topup");
    handleClose();
  };

  if (app21ProductsAndCouponsDataStatus === "loading") {
    return <LoadingSpinner />;
  } else if (checkIfNull(app21ProductsAndCouponsData?.topups))
    return "Something went wrong";
  else
    return (
      <>
        <Button onClick={handleTopupOpen} {...ButtonProps} {...rest}>
          {label}
        </Button>
        <Dialog open={topupOpen} onClose={handleTopupClose} {...DialogProps}>
          <DialogTitle>
            Topup your Trryst account with some additional credits.
          </DialogTitle>
          {app21ProductsAndCouponsDataStatus !== "loading" ? (
            <DialogContent>
              <StyledCard
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 3,
                  flexWrap: "wrap",
                }}
              >
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  onChange={(e) => {
                    handleOptionSelect(e.target.value);
                  }}
                  value={topupCode}
                  row
                >
                  {Object.entries(app21ProductsAndCouponsData?.topups)?.map(
                    (option, index) => (
                      <FormControlLabel
                        key={index}
                        value={option[0]}
                        control={<Radio />}
                        labelPlacement="bottom"
                        label={
                          <InfoCard
                            title={`+ ${option[1].label}`}
                            subheader={`${option[1].pricing.GBP.symbol} ${option[1].pricing.GBP.amount}`}
                          />
                        }
                      />
                    )
                  )}
                </RadioGroup>
              </StyledCard>
              {/*app21ProductsAndCouponsData &&
              app21ProductsAndCouponsData.topups &&
              Object.keys(app21ProductsAndCouponsData.topups).map(
                (topupItem) => {
                  const topup = app21ProductsAndCouponsData.topups[topupItem];
                  return (
                    <Box sx={{ p: 4 }} key={topupItem}>
                      <Typography variant="h4">{topupItem}</Typography>
                      <Typography>Name: {topup.name}</Typography>
                      <Typography>Credits: {topup.credits}</Typography>
                      <Button
                        onClick={() => handleTopupSubmit(topupItem)}
                        color="primary"
                        variant="contained"
                      >
                        Buy Now
                      </Button>
                    </Box>
                  );
                }
              )*/}
            </DialogContent>
          ) : (
            <LoadingSpinner />
          )}

          <DialogActions
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button onClick={handleTopupClose}>Cancel</Button>
            <Button
              sx={{ ml: 2 }}
              startIcon={<ShoppingCartCheckout />}
              onClick={handleTopupSubmit}
            >
              <Box
                variant="contained"
                color="primary"
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <div>Buy Topup</div>
                <div>
                  {topupCode &&
                    `( ${
                      Object.entries(app21ProductsAndCouponsData?.topups)?.find(
                        (option) => option[0] === topupCode
                      )[1].label
                    } )`}
                </div>
              </Box>
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
}
