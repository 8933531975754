import React from "react";
import { Box } from "@mui/material";
import { useAppContext } from "@app21/core";
import dynamic from "next/dynamic";
/* Excalidraw does not support Server Side Rendering... */

export default function CollaborationTile() {
  const { meetingId } = useAppContext();
  const Excalidraw = dynamic(() => import("@excalidraw/excalidraw"), {
    ssr: false,
  });

  const [publicKey, encryptionKey] = React.useMemo(() => {
    return [meetingId.substr(0, 20), `${meetingId.substr(0, 22)}`];
  }, [meetingId]);

  const excalidrawRef = React.useRef(null);

  const updateScene = () => {
    const sceneData = {
      elements: [
        {
          type: "rectangle",
          version: 141,
          versionNonce: 361174001,
          isDeleted: false,
          id: "oDVXy8D6rom3H1-LLH2-f",
          fillStyle: "hachure",
          strokeWidth: 1,
          strokeStyle: "solid",
          roughness: 1,
          opacity: 100,
          angle: 0,
          x: 100.50390625,
          y: 93.67578125,
          strokeColor: "#c92a2a",
          backgroundColor: "transparent",
          width: 500,
          height: 500,
          seed: 1968410350,
          groupIds: [],
        },
      ],
      appState: {
        viewBackgroundColor: "#edf2ff",
      },
    };
    if (excalidrawRef?.current?.updateScene) {
      console.log("ok");
      excalidrawRef?.current?.updateScene(sceneData);
    }
  };

  React.useEffect(() => {
    updateScene();
  }, [Excalidraw]);

  //   const resolvablePromise = () => {
  //     let resolve;
  //     let reject;
  //     const promise = new Promise((_resolve, _reject) => {
  //       resolve = _resolve;
  //       reject = _reject;
  //     });
  //     promise.resolve = resolve;
  //     promise.reject = reject;
  //     return promise;
  //   };

  //   const initialStatePromiseRef = React.useRef({ promise: null });
  //   if (!initialStatePromiseRef.current.promise) {
  //     initialStatePromiseRef.current.promise = resolvablePromise();
  //   }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 10,
        position: "relative", //
        height: "100%", //
        width: "100%",
      }}
    >
      {/* <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          margin: '10px',

          flexGrow: 10,
          position: 'absolute',
          border: '1px solid red',
          height: '100%', //
          width: '100%',
          top: 0,
          left: 0,
        }}
      >
        <Excalidraw
          zenModeEnabled={false}
          viewModeEnabled={false}
          ref={excalidrawRef}
          theme={theme}
          onChange={e => console.log('onChange', e)}
          name="Custom name of drawing"
          UIOptions={{
            canvasActions: { loadScene: false },
          }}
          onPointerUpdate={payload => console.log(payload)}
          onCollabButtonClick={() => window.alert('You clicked on collab button')}
        /> */}

      {meetingId && (
        <iframe
          style={{ display: "flex", flexGrow: 1, flexDirection: "column" }}
          src={`https://excalidraw.com/#room=${publicKey},${encryptionKey}`}
          width="100%"
          height="100%"
        />
      )}
      {/* </Box> */}
    </Box>
  );
}
