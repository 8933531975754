import React from "react";
import {
  styled,
  Typography,
  Card,
  CardHeader,
  Box,
  IconButton,
} from "@mui/material";
import { getFileIcon } from "blocks/atoms";
import { getExtensionFromFilename, getChatFilename, formatBytes } from "utils";
import DownloadingOutlinedIcon from "@mui/icons-material/DownloadingOutlined";
import {
  useUserInfo,
  useSelectedBucketId,
  app21APIInstance,
  useAppContext,
} from "@app21/core";
import ReactPlayer from "react-player";

const StyledFileMessageCard = styled(Card)(({ ownerState = {} }) => {
  const { viewContext = "message" } = ownerState;

  return {
    minWidth: viewContext === "reply" ? 50 : 100,
    width: viewContext === "reply" ? 50 : "fit-content",
    maxWidth: viewContext === "reply" ? 50 : "max-content",
    maxHeight: 250,
  };
});

export default function FileMessageCard({
  message,
  viewContext = "message",
  handleFileDownload,
  handleFileView,
}) {
  const [fileSignedUrl, setFileSignedUrl] = React.useState(null);

  const selectedBucketId = useSelectedBucketId(null, true);
  const { data: userInfo } = useUserInfo();
  const { clientDB } = useAppContext();

  const getSignedUrl = async (s3Key) => {
    // check if any of the signed url's have expired and delete them first
    clientDB
      .table("signedurls")
      .where("urlGeneratedAt")
      .below(Date.now() - 60 * 60000) // in ms
      .delete();

    const result = await clientDB.signedurls
      .where({
        s3Key: s3Key,
      })
      .first();

    if (result) setFileSignedUrl(result.url);
    else {
      const response = await app21APIInstance.post("/s3/fetchurl", {
        bucket: selectedBucketId,
        key: s3Key,
        urlTypeRequested: "get",
        contentDisposition: "inline",
        expiresIn: 60 * 60, // 60 minutes
        calledBy: `${userInfo?._id}`,
      });

      setFileSignedUrl(response?.data?.signedUrl);

      // add the currently generated signed url to the database
      clientDB.table("signedurls").add({
        s3Key: message?.message?.customElements?.s3Key,
        url: response?.data?.signedUrl,
        urlGeneratedAt: Date.now(),
      });
    }
  };

  React.useEffect(() => {
    if (
      message.message?.customElements?.app21MessageType === "IMAGE-FILE" ||
      message.message?.customElements?.app21MessageType === "VIDEO-FILE"
    ) {
      getSignedUrl(message?.message?.customElements?.s3Key);
    }
  }, [message]);

  const getFileViewCard = (type) => {
    switch (type) {
      case "VIDEO-FILE":
        return (
          <Box>
            <ReactPlayer
              controls
              playsinline
              width="300px"
              height="170px"
              url={fileSignedUrl}
            />
          </Box>
        );
      case "IMAGE-FILE":
        return fileSignedUrl ? (
          <Box
            component={"img"}
            src={fileSignedUrl}
            alt={message.message.customElements.s3Key}
            sx={{ objectFit: "contain", cursor: "pointer" }}
            onClick={() =>
              window.open(fileSignedUrl, "_blank", "noopener noreferrer")
            }
          />
        ) : (
          <>{message?.message?.text}</>
        );
      default:
        return null;
    }
  };

  if (
    message.message?.customElements?.app21MessageType === "IMAGE-FILE" ||
    message.message?.customElements?.app21MessageType === "VIDEO-FILE"
  ) {
    return (
      <StyledFileMessageCard
        ownerState={{ viewContext }}
        sx={{
          display: "flex",
          background: "transparent",
          borderRadius: 0,
          boxShadow: "none",
          // backgroundColor: isMe ? "rgba(7, 73, 60, 0.1)" : "#FFF3E4",
        }}
      >
        {getFileViewCard(message?.message?.customElements?.app21MessageType)}
      </StyledFileMessageCard>
    );
  } else {
    return (
      <StyledFileMessageCard
        ownerState={{ viewContext }}
        sx={{
          display: "flex",
          background: "transparent",
          borderRadius: 0,
          boxShadow: "none",
          // backgroundColor: isMe ? "rgba(7, 73, 60, 0.1)" : "#FFF3E4",
        }}
      >
        <CardHeader
          sx={{ m: 0, p: 0 }}
          avatar={
            <IconButton
              onClick={() =>
                handleFileView(message?.message?.customElements?.s3Key)
              }
            >
              {getFileIcon({
                fileExt: getExtensionFromFilename(
                  getChatFilename(message?.message?.customElements?.s3Key)
                ),
                size: "lg",
              })}
            </IconButton>
          }
          title={
            <Typography variant="body2">
              {getChatFilename(message?.message?.customElements?.s3Key)}
            </Typography>
          }
          subheader={
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {parseInt(message?.message?.customElements?.fileSize)
                ? formatBytes(message?.message?.customElements?.fileSize, 1)
                : ""}
              <IconButton
                size="small"
                sx={{ p: 0 }}
                onClick={() => {
                  handleFileDownload(message?.message?.id);
                }}
              >
                <DownloadingOutlinedIcon />
              </IconButton>
            </Box>
          }
        />
      </StyledFileMessageCard>
    );
  }
}
