import React from 'react'
import { SvgIcon } from '@mui/material';

export default function CheckInIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M91.667 37.5V62.5C91.667 72.9167 89.5836 80.2083 84.917 84.9167L58.3336 58.3333L90.542 26.125C91.292 29.4167 91.667 33.1666 91.667 37.5Z"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.33362 37.5007C8.33362 16.6673 16.667 8.33398 37.5003 8.33398H62.5003C79.0003 8.33398 87.667 13.584 90.542 26.1257L26.1252 90.5423C13.5836 87.6673 8.33362 79.0007 8.33362 62.5007V53.9173"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M84.9169 84.9173C80.2086 89.584 72.9169 91.6673 62.5002 91.6673H37.5002C33.1669 91.6673 29.4169 91.2923 26.1252 90.5423L58.3336 58.334L84.9169 84.9173Z"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.0002 33.2505C28.8335 21.0422 47.1669 21.0422 50.0002 33.2505C51.6252 40.4172 47.1252 46.5005 43.1668 50.2505C40.2918 53.0005 35.7502 53.0005 32.8336 50.2505C28.8752 46.5005 24.3335 40.4172 26.0002 33.2505Z"
          stroke="currentColor"
          strokeWidth="6.25"
        />
        <path
          d="M37.8944 36.251H37.9319"
          stroke="currentColor"
          strokeWidth="8.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
