// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Chip } from "@mui/material";

export default function HoverExpandChip({
  compactFlag = false,
  label = "",
  compactLabel = "",
  ...props
}) {
  const [hovered, setHovered] = React.useState(false);
  const handleHoverOpen = () => setHovered(true);
  const handleHoverClose = () => setHovered(false);

  return (
    <Chip
      onMouseEnter={handleHoverOpen}
      onMouseLeave={handleHoverClose}
      label={hovered || !compactFlag ? label : compactLabel}
      sx={{
        "& .MuiChip-label": {
          width: "100%",
          overflow: "hidden",
          animation: "slow 2s ease forwards",
        },
      }}
      {...props}
    />
  );
}
