import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { ChangeSubscriptionForm } from "blocks/modules/Subscriptions/features";

export default function ManageSubscriptionDialog({
  open = false,
  handleClose = () => null,
  targetOrgId = null,
  targetSuiteId = null,
  messageString = "",
}) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Manage Subscription</DialogTitle>
      <DialogContent sx={{ my: 1 }}>
        <DialogContentText>{messageString}</DialogContentText>
        <ChangeSubscriptionForm
          targetOrgId={targetOrgId}
          targetSuiteId={targetSuiteId}
          subscriptionContext={"NEW"}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
