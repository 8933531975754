import React from 'react'
import { SvgIcon } from '@mui/material';

export default function CampactIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M37.4979 8.3335V91.6668"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.496 13.8335C10.8293 18.4585 8.32935 26.1252 8.32935 37.5002V62.5002C8.32935 83.3335 16.6627 91.6668 37.496 91.6668H62.496C83.3294 91.6668 91.6627 83.3335 91.6627 62.5002V37.5002C91.6627 16.6668 83.3294 8.3335 62.496 8.3335H37.496"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
