// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { useRef, useState } from "react";
import { Icon } from "@iconify/react";
import homeFill from "@iconify/icons-eva/home-fill";
import personFill from "@iconify/icons-eva/person-fill";
import smilingFace from "@iconify/icons-eva/smiling-face-fill";

import { alpha } from "@mui/material/styles";
import {
  Box,
  Avatar,
  Button,
  Divider,
  MenuItem,
  Typography,
  Badge,
} from "@mui/material";
import { NotificationImportant } from "@mui/icons-material";
import { resetStorage } from "utils";
import MenuPopover from "blocks/atoms/uicomponents/MenuPopover";
import { IconButtonAnimate } from "blocks/atoms/uieffects/animate";

import { useAuth0 } from "@auth0/auth0-react";
import { getS3ImageWrapper, useHandleRouterPush } from "@app21/core";
import { useApp21FirebaseContext } from "providers/Firebase";
import { menuActionTestIds } from "../constants";

const MENU_OPTIONS = [
  { label: "Profile", icon: personFill, linkTo: "userprofile" },
  { label: "Received Invite?", icon: homeFill, linkTo: "joininvite" },
  { label: "Contact Us", icon: smilingFace, linkTo: "contactus" },
];
// { label: 'Settings', icon: settings2Fill, linkTo: '#' },

const AuthUserAvatarMenu = ({ userInfo, iconSize = "small" }) => {
  const { logout } = useAuth0();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const { loadRoute } = useHandleRouterPush();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {
    desktopNotificationsApprovalState,
    // handleDesktopNotificationsApproval,
  } = useApp21FirebaseContext();

  const handleMenuOption = (linkTo) => {
    if (linkTo === "joininvite") {
      loadRoute("GOTO-LINK", { hrefLink: "/joininvite" });
    } else if (linkTo === "contactus") {
      loadRoute("MANAGE-PROFILE", { choice: "contactus" });
    } else loadRoute("MANAGE-PROFILE", { choice: "userprofile" });
    handleClose();
  };

  if (userInfo) {
    return (
      <>
        <Badge
          variant="dot"
          color={"warning"}
          invisible={desktopNotificationsApprovalState !== "denied"}
          overlap="circular"
        >
          <IconButtonAnimate
            data-testid={menuActionTestIds.userAvatarMenuButtonTestId}
            ref={anchorRef}
            onClick={handleOpen}
            size={iconSize}
            sx={{
              padding: 0,
              height: 35,
              width: 35,

              // ml: 1,
              ...(open && {
                "&:before": {
                  zIndex: 1,
                  content: "''",
                  width: "100%",
                  height: "100%",
                  borderRadius: "50%",
                  position: "absolute",
                  bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                },
              }),
            }}
          >
            <Avatar
              alt={userInfo?.fullName ?? "User"}
              src={getS3ImageWrapper(userInfo?.image, "person")}
              sx={{ height: 35, width: 35, borderRadius: 2 }}
              variant="rounded"
            />
          </IconButtonAnimate>
        </Badge>

        <MenuPopover
          open={open}
          onClose={handleClose}
          anchorEl={anchorRef.current}
          sx={{ width: 220 }}
        >
          <Box sx={{ my: 1.5, px: 2.5 }}>
            <Typography variant="subtitle1" noWrap>
              {userInfo.fullName}
            </Typography>
            <Typography
              variant="caption"
              sx={{ color: "text.secondary" }}
              noWrap
            >
              {userInfo.emailId}
            </Typography>
          </Box>

          <Divider sx={{ my: 1 }} />

          {MENU_OPTIONS.map((option) => (
            <MenuItem
              data-testid={option.label}
              key={option.label}
              onClick={() => handleMenuOption(option.linkTo)}
              sx={{ typography: "body2", py: 1, px: 2.5 }}
            >
              <Box
                component={Icon}
                icon={option.icon}
                sx={{
                  mr: 2,
                  width: 24,
                  height: 24,
                }}
              />

              {option.label}
            </MenuItem>
          ))}
          {/* {desktopNotificationsApprovalState !== "granted" && (
            <MenuItem
              onClick={() => handleDesktopNotificationsApproval()}
              sx={{ typography: "body2", py: 1, px: 2.5 }}
            >
              <NotificationImportant
                sx={{
                  mr: 2,
                  width: 24,
                  height: 24,
                }}
                color={
                  desktopNotificationsApprovalState === "denied"
                    ? "error"
                    : "warning"
                }
              />
              {desktopNotificationsApprovalState === "denied"
                ? "Enable"
                : "Allow"}{" "}
              Notifications
            </MenuItem>
          )} */}
          <Box sx={{ p: 2, pt: 1.5 }}>
            <Button
              data-testid={menuActionTestIds.logoutDataTestId}
              fullWidth
              color="inherit"
              variant="outlined"
              onClick={() => {
                resetStorage();
                logout({ returnTo: process.env.ROOT_URL });
              }}
            >
              Logout
            </Button>
          </Box>
        </MenuPopover>
      </>
    );
  } else {
    return null;
  }
};
export default AuthUserAvatarMenu;
