import React from 'react'
import { SvgIcon } from '@mui/material';

export default function VoiceIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M25 41.082V58.9572"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.5 35.125V64.8751"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M50 29.1665V70.8332"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M62.5 35.125V64.8751"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M75 41.082V58.9572"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.5 91.6668H62.5C83.3333 91.6668 91.6667 83.3335 91.6667 62.5002V37.5002C91.6667 16.6668 83.3333 8.3335 62.5 8.3335H37.5C16.6666 8.3335 8.33331 16.6668 8.33331 37.5002V62.5002C8.33331 83.3335 16.6666 91.6668 37.5 91.6668Z"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
