// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)

import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Box,
  Tooltip,
  IconButton,
  SvgIcon,
  Stack,
  Collapse,
} from "@mui/material";
import { Settings, BallotOutlined } from "@mui/icons-material";
import {
  app21APIInstance,
  useUserInfo,
  useHandleRouterPush,
  useAppContext,
} from "@app21/core";
import { getBackgroundColorFromString } from "utils";
import { alpha, darken } from "@mui/material/styles";
import {
  Calendar as CalendarIcon,
  Trello as TrelloIcon,
  MessageCircle as MessageCircleIcon,
  Database as DataroomIcon,
} from "react-feather";

import {
  UserAvatar,
  ProfilesChipsList,
  ScheduleConference,
  DocketLabel,
} from "blocks/atoms";
import { CopyMeetingLinkControl } from "blocks/modules/TrrystVideocall/foundations/components";

import { useIsMobileBreakpoint } from "hooks";

const DocketSummaryCard = ({
  docket = {},
  canViewDocket = true,
  canEditDocket = false,
  canDeleteDocket = false,
  handleDocketEdit,
}) => {
  const [hovered, setHovered] = React.useState(true);
  const docketString = docket?._id + docket?.title ?? "breakout";
  const {
    userInfo,
    selectedOrganizationId,
    selectedSuiteId,
    checkAccessRules,
  } = useAppContext();
  const { loadRoute } = useHandleRouterPush();
  const mobileViewBreakPoint = useIsMobileBreakpoint();
  const { accessFlag: canAccessMembers } = checkAccessRules({
    entity: "USERS-VISIBILITY",
    action: "VIEW-ALL-DOCKET-USERS",
    featureName: "DOCKET",
    isInvitee: true,
  });

  const handleHoverOn = () => {
    setHovered(true);
  };
  const handleHoverOff = () => {
    setHovered(true);
  };

  const getDocketBackgroundColor = React.useCallback(
    (string) => getBackgroundColorFromString(string),
    []
  );

  const handleDocketDataroomClick = () => {
    loadRoute("VIEW-DATAROOM", {
      docketId: docket?._id,
      s3Key: `${selectedOrganizationId}/${selectedSuiteId}/dockets/${docket?._id}/`,
    });
  };

  const handleDocketCalendarClick = () => {
    loadRoute("VIEW-CALENDAR", {
      docketId: docket._id,
    });
  };
  const handleDocketTasksClick = () => {
    loadRoute("LIST-TASKS", {
      docketId: docket._id,
    });
  };
  const handleDocketSurveysClick = () => {
    loadRoute("LIST-SURVEYS", {
      docketId: docket._id,
    });
  };
  const handleDocketChatClick = async () => {
    const response = await app21APIInstance.post(`/channels/fetch`, {
      objectType: "docket",
      objectId: docket._id,
      calledBy: userInfo._id,
      additionalData: {
        suiteId: selectedSuiteId,
      },
    });

    if (response) {
      loadRoute("GROUP-CHAT", {
        chatId: response?.data?.data.data[0].id,
        chatType: "dockets",
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",

        justifyContent: "center",
        alignItems: "center",
        pr: 2,
        py: 1,
        transition: "padding-top padding-bottom all 2s ease-out",
      }}
    >
      <Card
        onMouseEnter={handleHoverOn}
        onMouseLeave={handleHoverOff}
        key={docket._id}
        sx={{
          flexGrow: 1,
          width: "100%",
          minWidth: mobileViewBreakPoint ? 400 : 300,
          maxWidth: 650,

          "&:hover": {
            bgcolor: alpha(getDocketBackgroundColor(docketString), 0.1),

            width: "100%",
            minWidth: mobileViewBreakPoint ? 400 : 300,
            maxWidth: 650,
          },
        }}
      >
        <CardHeader
          avatar={<UserAvatar userId={docket?.createdBy} />}
          title={<DocketLabel id={docket?._id} title={docket?.title} />}
          disableTypography
          action={
            canEditDocket && (
              <IconButton
                sx={{
                  height: 40,
                  width: 40,
                  visibility: hovered ? "visible" : "hidden",
                  transition: "visibility all 2s ease-out",
                }}
                onClick={() => handleDocketEdit(docket)}
              >
                <Settings />
              </IconButton>
            )
          }
        />
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            pb: hovered ? 1 : 2,
            flexGrow: 1,
          }}
        >
          <Box sx={{}}>
            {docket?.combinedInviteesInfo.length ? (
              canAccessMembers ? (
                <ProfilesChipsList
                  usersList={docket?.combinedInviteesInfo}
                  dense={true}
                />
              ) : (
                <></>
              )
            ) : (
              "No Invitees added"
            )}
          </Box>
        </CardContent>
        <Collapse
          in={hovered}
          style={{
            transitionDuration: "1500ms",
            transitionTimingFunction: "linear",
          }}
        >
          <CardActions sx={{ display: "flex", flexDirection: "column" }}>
            <ScheduleConference
              type="joinButton"
              buttonLabel={"Start a Walk-in Call"}
              variant="outlined"
              docketId={docket._id}
              sx={{
                alignSelf: "flex-end",
                borderColor: getDocketBackgroundColor(docketString),
                color: darken(getDocketBackgroundColor(docketString), 0.3),
                backgroundColor: alpha(
                  getDocketBackgroundColor(docketString),
                  0.2
                ),
                visibility: hovered ? "visible" : "hidden",
                transition: "visibility all 2s ease-out",
              }}
            />
            {/*<Box sx={{ borderRadius: '50%', p: 2, bgcolor: getDocketBackgroundColor(docketString) }} />*/}
            <Box
              sx={{
                flexGrow: 1,
                opacity: hovered ? 1 : 0,
                visibility: hovered ? "visible" : "hidden",
                transition: "visibility 1500ms, opacity 1500ms ease",
              }}
            >
              <Stack
                direction="row"
                spacing={1}
                justifyContent="center"
                alignItems="center"
              >
                <Tooltip title="Files in Breakout room" arrow>
                  <IconButton onClick={handleDocketDataroomClick}>
                    <SvgIcon fontSize="inherit">
                      <DataroomIcon />
                    </SvgIcon>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Scheduled meetings in breakout room" arrow>
                  <IconButton onClick={handleDocketCalendarClick}>
                    <SvgIcon fontSize="inherit">
                      <CalendarIcon />
                    </SvgIcon>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Tasks assigned within breakout" arrow>
                  <IconButton onClick={handleDocketTasksClick}>
                    <SvgIcon fontSize="inherit">
                      <TrelloIcon />
                    </SvgIcon>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Surveys initiated with Breakout members" arrow>
                  <IconButton onClick={handleDocketSurveysClick}>
                    <BallotOutlined />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Discuss with other Breakout room members" arrow>
                  <IconButton onClick={handleDocketChatClick}>
                    <SvgIcon fontSize="inherit">
                      <MessageCircleIcon fontSize="inherit" />
                    </SvgIcon>
                  </IconButton>
                </Tooltip>
              </Stack>
            </Box>
            <Box>
              {docket?.meetingInfo?._id && (
                <CopyMeetingLinkControl
                  meetingId={docket?.meetingInfo?._id}
                  componentType={"icon"}
                />
              )}
            </Box>
          </CardActions>
        </Collapse>
      </Card>
    </Box>
  );
};

export default DocketSummaryCard;
