import React from "react";
import {
  Box,
  Typography,
  Chip,
  Card,
  CardContent,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListSubheader,
  Button,
} from "@mui/material";
import { AutoFixHigh, ContentCopy, Add } from "@mui/icons-material";
import { UserAvatar } from "blocks/atoms";
import { LoadingButton } from "@mui/lab";
import { checkIfNull, app21APIInstance, useAppContext } from "@app21/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import PerfectScrollbar from "react-perfect-scrollbar";
import parseTranscriptSection from "./parseTranscriptSection";
import ShowAISummariesAndActionsTabs from "./ShowAISummariesAndActionsTabs";

export default function ShowMagicSummary({ meeting = {}, transcription = {} }) {
  const [magicSummaryLoading, setMagicSummaryLoading] = React.useState(false);

  const { userInfo } = useAppContext();
  const handleGenerateSummary = React.useCallback(async () => {
    if (transcription && transcription._id) {
      setMagicSummaryLoading(true);
      await app21APIInstance.post("/transcript/generateSummary", {
        transcriptionId: transcription._id,
        calledBy: userInfo?._id,
      });
      setMagicSummaryLoading(false);
    }
  }, [transcription]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
        pr: 2,
        overflow: "hidden",
      }}
    >
      {!transcription.openAi && (
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardContent>
            Would you like to see a AI-generated meeting minutes of your
            meeting?
          </CardContent>
          <LoadingButton
            color="secondary"
            variant="contained"
            loadingPosition="start"
            startIcon={<AutoFixHigh />}
            sx={{ m: 2 }}
            disabled={magicSummaryLoading}
            loading={magicSummaryLoading}
            onClick={handleGenerateSummary}
          >
            Generate AI Summary
          </LoadingButton>
        </Card>
      )}

      {/* <Card sx={{ borderRadius: 0, m: 0, p: 0 }}>
        <CardHeader
          title="AI-generated Meeting Summary"
          action={
            <>
              <CopyToClipboard text={copyState.value} onCopy={onCopy}>
                <Tooltip title="Copy" arrow>
                  <IconButton>
                    <ContentCopy />
                  </IconButton>
                </Tooltip>
              </CopyToClipboard>
              <Button>Create Meeting Minutes</Button>
            </>
          }
        />
        <CardContent>
          {transcription.openAi &&
            transcription.openAi?.summaryContent &&
            typeof transcription.openAi?.summaryContent === "string" && (
              <Typography variant="body1" sx={{ p: 5 }}>
                {transcription.openAi.summaryContent}
              </Typography>
            )}
          <ul>
            {transcription.openAi &&
              transcription.openAi?.summaryContent &&
              typeof transcription.openAi?.summaryContent !== "string" &&
              transcription.openAi?.summaryContent?.map((e, index) => (
                <li key={index}>
                  <Typography variant="body1" sx={{ p: 5, py: 2 }}>
                    - {e}
                  </Typography>
                </li>
              ))}
          </ul>
          <Typography variant="caption" sx={{ p: 5 }}>
            {transcription.openAi?.keywords?.map((e, index) => (
              <Chip key={index} sx={{ m: 1 }} label={`#${e}`} />
            ))}
          </Typography>
        </CardContent>
      </Card> */}

      {transcription.openAi && (
        <Card sx={{ borderRadius: 0, m: 0, py: 2, pl: 2, overflow: "auto" }}>
          <PerfectScrollbar
            style={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              height: "100%",
              paddingRight: 10,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h5" sx={{ pl: 5 }}>
                AI Generated Summary of meeting
              </Typography>
            </Box>

            <ShowAISummariesAndActionsTabs
              openAiSummary={transcription.openAi}
            />
          </PerfectScrollbar>
          {/* {transcription.openAi &&
              transcription.openAi?.summaryContent &&
              typeof transcription?.openAi?.summaryContent === "string" && (
                <Typography variant="body1" sx={{ p: 5 }}>
                  {transcription.openAi.summaryContent}
                </Typography>
              )}
            <ul>
              {transcription.openAi &&
                transcription.openAi?.summaryContent &&
                typeof transcription?.openAi?.summaryContent !== "string" &&
                Array.isArray(transcription?.openAi?.summaryContent) &&
                transcription.openAi?.summaryContent?.map((e, index) => (
                  <li key={index}>
                    <Typography variant="body1" sx={{ p: 5, py: 2 }}>
                      {e}
                    </Typography>
                  </li>
                ))}
            </ul> */}
          {/* <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {transcription.openAi?.keywords?.map((e, index) => (
                <Chip
                  key={index}
                  sx={{ m: 1 }}
                  label={`#${clip(e ?? "", 50)}`}
                />
              ))}
            </Box> */}
        </Card>
      )}
    </Box>
  );
}
