import React from 'react'
import { SvgIcon } from '@mui/material';

export default function CopyIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M8.33344 53.7516C8.33344 39.1683 14.1668 33.335 28.7501 33.335H46.2501C60.8334 33.335 66.6668 39.1683 66.6668 53.7516V71.2516C66.6668 85.835 60.8334 91.6683 46.2501 91.6683H28.7501C14.1668 91.6683 8.33344 85.835 8.33344 71.2516"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M91.6668 46.2516C91.6668 60.835 85.8334 66.6683 71.2501 66.6683H66.6668V53.7516C66.6668 39.1683 60.8334 33.335 46.2501 33.335H33.3334V28.7516C33.3334 14.1683 39.1668 8.33496 53.7501 8.33496H71.2501C85.8334 8.33496 91.6668 14.1683 91.6668 28.7516"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
