// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Typography, Button, Box } from "@mui/material";
import { FlowBox } from "blocks/atoms/uistyles";
import { AddIcon } from "blocks/atoms/trrysticons";
import { useIsMobileBreakpoint } from "hooks";
import { useAppContext, useHandleRouterPush } from "@app21/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import { LoadingSpinner } from "blocks/atoms";
import { useDocketsContext } from "providers/DocketsProvider";
import DisplayDashboardDocketCard from "blocks/modules/Dockets/foundations/DisplayDashboardDocketCard";

export default function DocketsSuiteDashboardListView({}) {
  const {
    sortedDocketsList = [],
    baseDocketsStatus,
    filteredMembershipDocketsList = [],
  } = useDocketsContext();
  const { suiteRole, selectedOrganizationId } = useAppContext();
  const { loadRoute } = useHandleRouterPush();
  const isSuiteManager = suiteRole === "MANAGER";

  if (baseDocketsStatus === "loading") {
    return <LoadingSpinner type="exp" />;
  }

  return (
    <FlowBox
      sx={{
        transition: "all 1s ease-in-out",
        overflow: "auto",
      }}
    >
      {filteredMembershipDocketsList?.length > 0 ? (
        <PerfectScrollbar
          options={{ suppressScrollX: true }}
          style={{ paddingRight: 10, maxHeight: "70vh" }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 3,
              flexWrap: "wrap",
            }}
          >
            {filteredMembershipDocketsList.map((docketObj, index) => {
              return (
                <DisplayDashboardDocketCard
                  key={index}
                  docketObj={docketObj}
                  isSuiteManager={isSuiteManager}
                />
              );
            })}
          </Box>
        </PerfectScrollbar>
      ) : isSuiteManager ? (
        <Box sx={{ m: 3, display: "flex", gap: 3, alignItems: "center" }}>
          <Typography variant="body1">
            There are no breakouts with you as a member created yet.
          </Typography>
          <Button
            sx={{}}
            startIcon={<AddIcon />}
            onClick={() => {
              loadRoute("CREATE-DOCKET", {
                orgId: selectedOrganizationId,
              });
            }}
          >
            Create Breakout
          </Button>
        </Box>
      ) : (
        <Typography variant="body1" sx={{ my: 3 }}>
          You are not part of any breakouts.
        </Typography>
      )}
    </FlowBox>
  );
}
