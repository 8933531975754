import React from 'react'
import { SvgIcon } from '@mui/material';

export default function SelectInactiveIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M8.32324 54.005V62.505C8.32324 83.3384 16.6566 91.6717 37.4899 91.6717H62.4899C83.3232 91.6717 91.6566 83.3384 91.6566 62.505V37.505C91.6566 16.6717 83.3232 8.33838 62.4899 8.33838H37.4899C16.6566 8.33838 8.32324 16.6717 8.32324 37.505"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
