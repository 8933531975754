import * as React from "react";
import {
  Checkbox,
  ListItemText,
  ListItemIcon,
  ListItem,
  ListItemButton,
  Avatar,
  Divider,
  ListItemAvatar,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Box,
  TextField,
  ListItemSecondaryAction,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import {
  getS3ImageWrapper,
  useHandleOrganizationActions,
  useHandleSuiteActions,
  useAppContext,
} from "@app21/core";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";
import { constantCase } from "change-case";
import { useConfirm } from "material-ui-confirm";
import { Label } from "blocks/atoms";
import { LabelDropdownButtonGroup } from "blocks/atoms/uicomponents";
import clip from "text-clipper";
import { checkIfNotLastAdminUser } from "utils/suiteHelperUtils";

export default function UserListCard({
  handleToggle,
  user,
  selLeft,
  selRight,
  keyValue,
  usersInOrganization,
}) {
  const confirm = useConfirm();
  const { handleSuiteActions, detachUserFromSuiteStatus } =
    useHandleSuiteActions();
  const { handleOrganizationActions, detachUserFromOrganizationStatus } =
    useHandleOrganizationActions();
  const {
    userInfo,
    selectedSuiteId,
    selectedOrganizationId,
    checkAccessRules,
  } = useAppContext();

  const allRoleOptionLabels = [
    { label: "Manager", value: "MANAGER", color: "secondary" },
    { label: "Normal", value: "NORMAL", color: "gray" },
    { label: "Guest", value: "GUEST", color: "error" },
  ];

  const labelId = `transfer-list-item-${user._id}-label`;

  const actionSelectPopupState = usePopupState({
    variant: "popover",
    popupId: "actionsMenu",
  });

  const handleRoleChangeClick = async (
    targetRole,
    roleSelectPopupState,
    context
  ) => {
    roleSelectPopupState?.close();

    if (
      constantCase(user?.roleInfo?.role ?? "") === "ADMIN" &&
      !checkIfNotLastAdminUser(usersInOrganization)
    ) {
      confirm({
        title: "Alert!",
        description:
          "You cannot remove the last Administrator from the organization.",
        confirmationText: "Close",
      })
        .then(() => {
          console.log("closed");
        })
        .catch(() => {
          console.log("Cancelled.");
        });
    } else {
      if (context === "organization-panel") {
        await handleOrganizationActions({
          action: "CHANGE-USER-ROLE",
          userId: user?._id,
          role:
            constantCase(user?.roleInfo?.role ?? "") === "ADMIN"
              ? "NORMAL"
              : "ADMIN",
        });
      } else {
        await handleSuiteActions(
          {
            action: "CHANGE-USER-ROLE",
            userId: user?._id,
            role: targetRole,
          },
          null,
          null
        );
      }
    }
  };

  return (
    <ListItem
      dense
      disableGutters
      disablePadding
      key={user._id}
      sx={{ minHeight: 35, my: 1, border: "1px solid LightGrey", px: 1 }}
      //role="listitem"
    >
      <ListItemButton
        sx={{ p: 0, flexGrow: 1 }}
        onClick={handleToggle(user, keyValue)}
      >
        <ListItemIcon>
          {keyValue == "left" ? (
            <Checkbox
              size="small"
              sx={{ m: 0, p: 0 }}
              onClick={handleToggle(user, keyValue)}
              checked={
                selLeft.find((orgUser, index) => orgUser._id === user._id)
                  ? true
                  : false
              }
              tabIndex={-1}
              disableRipple
              inputProps={{
                "aria-labelledby": labelId,
              }}
            />
          ) : (
            <Checkbox
              size="small"
              sx={{ m: 0, p: 0 }}
              onClick={handleToggle(user, keyValue)}
              checked={
                selRight.find((orgUser, index) => orgUser._id === user._id)
                  ? true
                  : false
              }
              tabIndex={-1}
              disableRipple
              inputProps={{
                "aria-labelledby": labelId,
              }}
            />
          )}
        </ListItemIcon>
        {/* <ListItemAvatar>
          <Avatar
            src={getS3ImageWrapper(user?.image, "person")}
            variant="rounded"
            sx={{ height: 25, width: 25 }}
          />
        </ListItemAvatar> */}
        <ListItemText
          id={labelId}
          primary={
            <Box>
              <Typography variant="body2">
                {clip(user.fullName, 20, { breakWords: true })}
              </Typography>
              {user?.roleInfo?.role === "ADMIN" && keyValue == "left" && (
                <Label
                  variant="ghost"
                  color="secondary"
                  sx={{
                    ml: 1,
                    mt: -1,
                  }}
                >
                  <Typography variant="caption">Admin</Typography>
                </Label>
              )}
            </Box>
          }
          secondary={
            keyValue == "left" ? (
              <>
                {/* //
            <IconButton {...bindTrigger(actionSelectPopupState)}>
              // <MoreVert /> //
            </IconButton> */}
              </>
            ) : (
              <LabelDropdownButtonGroup
                menuOptions={allRoleOptionLabels}
                selectedValue={constantCase(user.roleInfo?.role ?? "")}
                handleClick={handleRoleChangeClick}
                sx={{ mt: 1 }}
              />
            )
          }
        />
      </ListItemButton>
    </ListItem>
  );
}
