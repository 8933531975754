// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { useRef, useState } from "react";
// material
import { alpha } from "@mui/material/styles";
import { Box, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
// components
import MenuPopover from "blocks/atoms/uicomponents/MenuPopover";
import { IconButtonAnimate } from "blocks/atoms";
//Hooks
import { useIntl } from "react-intl";
import { useRouter } from "next/router";
import Image from "next/image";

// ----------------------------------------------------------------------

const LANGS = [
  {
    value: "en",
    label: "English",
    icon: "/static/icons/ic_flag_en.svg",
  },
  {
    value: "de",
    label: "German",
    icon: "/static/icons/ic_flag_de.svg",
  },
  {
    value: "fr",
    label: "French",
    icon: "/static/icons/ic_flag_fr.svg",
  },
];

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const anchorRef = useRef(null);
  const router = useRouter();
  const [open, setOpen] = useState(false);
  const { locale } = useRouter();
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleChangeLanguage = (localeChoice) => {
    handleClose();
    router.push(router.asPath, router.asPath, { locale: localeChoice });
  };
  const selectedOption =
    LANGS.find((option) => option.value === locale) || LANGS[0];

  return <>
    <IconButtonAnimate
      ref={anchorRef}
      onClick={handleOpen}
      sx={{
        p: 0,
        m: 1,
        width: 34,
        height: 34,
        ...(open && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.focusOpacity
            ),
        }),
      }}
    >
      <Image src={selectedOption.icon} alt={selectedOption.label} fill sizes="100vw" />
    </IconButtonAnimate>

    <MenuPopover
      open={open}
      onClose={handleClose}
      anchorEl={anchorRef.current}
    >
      <Box sx={{ py: 1 }}>
        {LANGS.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === selectedOption.value}
            onClick={() => handleChangeLanguage(option.value)}
            sx={{ py: 1, px: 2.5 }}
          >
            <ListItemIcon>
              <Box component="img" alt={option.label} src={option.icon} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ variant: "body2" }}>
              {option.label}
            </ListItemText>
          </MenuItem>
        ))}
      </Box>
    </MenuPopover>
  </>;
}
