import React from "react";
import { Box, TextField, InputAdornment } from "@mui/material";
import { useChatContext } from "providers/TrrystChatProvider";
import { Search, Clear } from "@mui/icons-material";
import { useAtom } from "jotai";
import { SearchFilterAtom } from "blocks/modules/TrrystChat/state-atoms";

export default function SearchGroupField({}) {
  const [searchFilter, setSearchFilter] = useAtom(SearchFilterAtom);
  return (
    <Box>
      <TextField
        fullWidth
        size="small"
        onChange={(e) => setSearchFilter(e.target.value)}
        placeholder="Search"
        type="text"
        value={searchFilter}
        InputProps={{
          ...(!searchFilter
            ? {
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }
            : {
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={() => setSearchFilter("")}
                  >
                    <Clear fontSize={"small"} sx={{ pointer: "cursor" }} />
                  </InputAdornment>
                ),
              }),
        }}
      />
    </Box>
  );
}
