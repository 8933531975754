import { sort } from "fast-sort";
import { findOrgIdFromSuiteId, getPersonalSuiteInfo } from "utils";
import { checkIfNull } from "@app21/core";

export default function constructAllUnreadChats(
  unreadMessages = [],
  groupMetadata = [],
  orgsAndSuites = []
) {
  let unreadFlattenedMessagesArray = [];

  unreadMessages?.forEach((obj) => {
    const { messages, ...metaInfo } = obj;
    messages
      ?.filter((m) => m.category === "message")
      ?.forEach((message) => {
        let combinedMessage = { ...message, ...metaInfo };
        unreadFlattenedMessagesArray.push(combinedMessage);
      });
  });
  let chatArrayWithAllRelevantInfo = [];

  let personalSuiteInfo = getPersonalSuiteInfo(orgsAndSuites);

  unreadFlattenedMessagesArray.forEach((chat) => {
    if (chat.user) {
      chatArrayWithAllRelevantInfo.push({
        ...chat,
        ...{
          userId: chat.user,
          suiteId: personalSuiteInfo.suiteId,
          orgId: personalSuiteInfo.orgId,
        },
      });
    } else if (chat.guid) {
      let metadataInfo = groupMetadata?.find(
        (obj) => obj.guid === chat.guid
      )?.metadata;
      if (!checkIfNull(metadataInfo)) {
        let foundOrgId = findOrgIdFromSuiteId(
          metadataInfo?.suiteId,
          orgsAndSuites
        );
        if (metadataInfo?.suiteId && foundOrgId) {
          chatArrayWithAllRelevantInfo.push({
            ...chat,
            ...{ orgId: foundOrgId },
            ...metadataInfo,
          });
        }
      }
    }
  });
  const sortedChatArray = sort(chatArrayWithAllRelevantInfo).desc(
    (m) => m.sentAt
  );
  return sortedChatArray;
}
