// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  Box,
  Button,
  Typography,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { useAppContext, checkIfNull } from "@app21/core";
import CountdownButton from "blocks/atoms/CountdownButton";
import { format, isSameDay, isWithinInterval, sub } from "date-fns";
import { useVideocallContext } from "providers/TrrystVideocallProvider";
import ShowMeetingInviteesAndRsvp from "blocks/modules/Scheduler/foundations/ShowMeetingInviteesAndRsvp";
import DeviceSetup from "./DeviceSetup";
import GridHeaderBar from "blocks/modules/TrrystVideocall/foundations/components/GridHeaderBar";
import { getDateTimeFormat } from "utils/getDateFormats";

const JoinMeetingWaitingRoom = ({ action }) => {
  const { userInfo } = useAppContext();
  // const [joinMeetingCompleted, setJoinMeetingCompleted] = React.useState(false);
  const { isMeetingCreator, meeting } = useVideocallContext();

  const meetingInfo = meeting?.data?.meetingJSON;
  const currentDate = new Date();
  const [isInviteeFlag] = React.useState(
    !checkIfNull(meeting)
      ? meeting?.data?.meetingJSON?.members?.some(
          (user) => userInfo?._id === user?._id
        ) || isMeetingCreator
      : false
  );

  const hasMeetingEndedFlag =
    meeting?.data?.meetingType === "CLOSED-MEETING" &&
    meetingInfo?.end &&
    meetingInfo?.end < new Date().getTime();

  const sameDayFlag = isSameDay(
    new Date(meetingInfo?.start),
    new Date(meetingInfo?.end)
  );

  const pastMeetingFlag = Date.parse(meetingInfo?.end) < currentDate.valueOf();

  let startMeetingEnabled =
    isMeetingCreator ||
    meeting?.data?.meetingType === "GROUP-MEETING" ||
    !hasMeetingEndedFlag;

  const setStartMeetingEnabled = () => {
    isMeetingCreator ||
      meeting?.data?.meetingType === "GROUP-MEETING" ||
      hasMeetingEndedFlag;
  };

  const isGroupMeeting = meeting?.data?.meetingType === "GROUP-MEETING";

  const [upcomingMeetingFlag] = React.useState(
    meetingInfo?.start < meetingInfo?.end
      ? isWithinInterval(new Date(), {
          start: sub(new Date(meetingInfo?.start), { minutes: 5 }),
          end: new Date(meetingInfo?.end),
        })
      : false
  );

  const meetingFlags = {
    isInviteeFlag,
    hasMeetingEndedFlag,
    pastMeetingFlag,
    upcomingMeetingFlag,
    sameDayFlag,
  };

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
      }}
    >
      <GridHeaderBar viewContext="setup" />
      {!checkIfNull(meeting) ? (
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            m: 1,
            pb: 2,
            flexGrow: 1,
          }}
        >
          <TableContainer>
            <Table size="small">
              <TableBody>
                {!isGroupMeeting && (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" sx={{ maxWidth: 90 }}>
                      <Typography variant="body2"> Schedule </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {meetingInfo && (
                        <Typography variant="body2" color="primary">
                          {format(
                            new Date(meetingInfo?.start),
                            getDateTimeFormat({
                              startDate: meetingInfo?.start,
                              endDate: meetingInfo?.end,
                              datePosition: 1,
                              dateContext: "meeting",
                            })
                          )}{" "}
                          -
                          {format(
                            new Date(meetingInfo?.end),
                            getDateTimeFormat({
                              startDate: meetingInfo?.start,
                              endDate: meetingInfo?.end,
                              datePosition: 2,
                              dateContext: "meeting",
                            })
                          )}
                        </Typography>
                      )}
                    </TableCell>
                  </TableRow>
                )}
                {isInviteeFlag && !isGroupMeeting && (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" sx={{ maxWidth: 90 }}>
                      Invited
                    </TableCell>
                    <TableCell align="left">
                      <ShowMeetingInviteesAndRsvp
                        meeting={meeting}
                        action={action}
                        meetingFlags={meetingFlags}
                      />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {meetingFlags.hasMeetingEndedFlag ? (
              <Button disabled variant="contained">
                Meeting has Ended.
              </Button>
            ) : startMeetingEnabled ? (
              <DeviceSetup meetingInfo={meetingInfo} />
            ) : (
              <Box style={{ borderColor: "1px blue" }}>
                <CountdownButton
                  targetDate={meetingInfo?.start}
                  timeFormat="MM DD, h:mm a"
                  setStartMeetingEnabled={setStartMeetingEnabled}
                  startMeetingEnabled={startMeetingEnabled}
                />
              </Box>
            )}
          </Box>
        </Card>
      ) : (
        <Typography variant="h5">Something went wrong </Typography>
      )}
    </Box>
  );
};

export default JoinMeetingWaitingRoom;
