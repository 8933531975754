import React from "react";
import { Box } from "@mui/material";
import {
  StyledRoundedTabs,
  StyledRoundedTab,
  StyledRoundedTabList,
} from "blocks/atoms/uistyles";
import { useAtom } from "jotai";
import { useHandleRouterPush, useAppContext } from "@app21/core";

import {
  viewSurveysFilterContextAtom,
  selectedDocketsInSurveysFilterAtom,
  selectedStatusInSurveysFilterAtom,
  selectedSurveysDateRangeAtom,
} from "blocks/modules/Surveys/surveys-atoms";

import {
  SmartActionsSearchBar,
  baseSearchActions,
  SelectedActionsDisplay,
} from "blocks/atoms/uicomponents/smartsearch";
import { useSurveysContext } from "providers/SurveysProvider";

const baseSurveysStatusOptions = [
  { label: "Open", value: "OPEN" },
  { label: "Completed", value: "CLOSED" },
  { label: "All", value: "ALL" },
];

export default function SelectSurveysSidebarContext() {
  const [viewSurveysFilterContext, setViewSurveysFilterContext] = useAtom(
    viewSurveysFilterContextAtom
  );
  const [selectedDocketsInSurveysFilters, setSelectedDocketsInSurveysFilters] =
    useAtom(selectedDocketsInSurveysFilterAtom);
  const [selectedStatusInSurveysFilters, setSelectedStatusInSurveysFilters] =
    useAtom(selectedStatusInSurveysFilterAtom);
  const [selectedSurveysDateRange, setSelectedSurveysDateRange] = useAtom(
    selectedSurveysDateRangeAtom
  );
  const { baseDockets } = useSurveysContext();

  const handleFilterContextSelect = (value) => {
    setViewSurveysFilterContext(value?.map((obj) => obj.value) ?? []);
  };
  const filteredValues =
    baseSearchActions.filter((obj) =>
      viewSurveysFilterContext?.includes(obj.value)
    ) ?? [];

  const handleResetFilterValues = () => {
    setSelectedDocketsInSurveysFilters([]);
    setSelectedStatusInSurveysFilters([]);
    setViewSurveysFilterContext([]);
    setSelectedSurveysDateRange({});
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        flexGrow: 1,
        alignItems: "stretch",
        flexDirection: "column",
        pr: 0,
      }}
    >
      <SmartActionsSearchBar
        baseActions={baseSearchActions}
        value={filteredValues}
        onFilterSelect={(value) => handleFilterContextSelect(value)}
        showClearButton
        onClear={handleResetFilterValues}
      />
      <SelectedActionsDisplay
        viewFilter={viewSurveysFilterContext}
        setViewFilter={setViewSurveysFilterContext}
        selectedDateRangeFilter={selectedSurveysDateRange}
        setSelectedDateRangeFilter={setSelectedSurveysDateRange}
        selectedStatusFilter={selectedStatusInSurveysFilters}
        setSelectedStatusFilter={setSelectedStatusInSurveysFilters}
        selectedDocketsFilter={selectedDocketsInSurveysFilters}
        setSelectedDocketsFilter={setSelectedDocketsInSurveysFilters}
        baseDockets={baseDockets}
        baseStatusOptions={baseSurveysStatusOptions}
      />
    </Box>
  );
}
