import React from "react";
import { MenuItem } from "@mui/material";
import { MenuPopover } from "blocks/atoms/uicomponents";

export default function MessageMenuPopover({
  bindPopover = {},
  messageCardPopupState,
  messageId,
  s3Key,
  messageType,
  handleFileDownload,
  handleMessageDelete,
  handleMessageReply,
  handleFileView,
  canDelete = false,
}) {
  return (
    <MenuPopover
      {...bindPopover(messageCardPopupState)}
      id="message-card-popover"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      arrow="top-center"
      sx={{ width: 100, bgcolor: "background.default" }}
    >
      <MenuItem
        onClick={() => {
          handleMessageReply(messageId);
          messageCardPopupState.close();
        }}
      >
        Reply
      </MenuItem>
      {messageType === "file" && (
        <MenuItem
          onClick={() => {
            handleFileView(s3Key);
            messageCardPopupState.close();
          }}
        >
          View
        </MenuItem>
      )}
      {messageType === "file" && (
        <MenuItem
          onClick={() => {
            handleFileDownload(messageId);
            messageCardPopupState.close();
          }}
        >
          Download
        </MenuItem>
      )}
      {canDelete && (
        <MenuItem
          onClick={() => {
            handleMessageDelete(messageId);
            messageCardPopupState.close();
          }}
        >
          Delete
        </MenuItem>
      )}
    </MenuPopover>
  );
}
