// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import { checkIfNull } from "@app21/core";
import { format } from "date-fns";
import { getDateTimeFormat } from "utils/getDateFormats";

export const buildInviteesList = (members, visitors) => {
  let baseString =
    checkIfNull(members) && checkIfNull(visitors)
      ? "No Invitees added to meeting."
      : "";

  Array.isArray(members) &&
    !checkIfNull(members) &
      members.forEach((member) => {
        baseString += ` ${member?.fullName}, `;
      });

  Array.isArray(visitors) &&
    !checkIfNull(visitors) &
      visitors.forEach((visitor) => {
        baseString += ` ${visitor?.fullName} (Guest) , `;
      });

  return baseString;
};

export const constructBasicMinutesTemplate = ({
  meeting = {},
  parsedAISummary = "",
  transcription = {},
}) => {
  let basicString =
    '<h1 style="text-align:center;">Meeting Minutes</h1><p> </p>';
  let meetingInfo = meeting?.meetingJSON;
  let meetingState = meeting?.state;
  let meetingDetailsString = "Meeting not Scheduled";
  if (!checkIfNull(meetingInfo)) {
    meetingDetailsString = `${format(
      new Date(meetingState?.startedAt ?? null),
      getDateTimeFormat({
        startDate: meetingState?.startedAt,
        endDate: meetingState?.endedAt ?? null,
        datePosition: 1,
        dateContext: "meeting",
      })
    )} - ${format(
      new Date(meetingState?.endedAt ?? null),
      getDateTimeFormat({
        startDate: meetingState?.startedAt,
        endDate: meetingState?.endedAt ?? null,
        datePosition: 2,
        dateContext: "meeting",
      })
    )} `;
  }
  let meetingInfoString = `
    <h2 style="text-align: center;">Meeting Minutes</h2>
    <h2>Meeting Summary</h2>
    <table style="border-collapse: collapse; width: 93.304%; height: 135px;" border="1">
    <tbody>
    <tr style="height: 19px;">
    <td style="width: 7.89331%; height: 19px;"><strong>Meeting Details:</strong></td>
    <td style="width: 40.8683%; height: 19px;">${meetingDetailsString}</td>
    </tr>
    <tr style="height: 19px;">
    <td style="width: 7.89331%; height: 19px;"><strong>Invitees:</strong></td>
    <td style="width: 40.8683%; height: 19px;"><span style="font-family: 'times new roman', times, serif;">${buildInviteesList(
      meetingInfo?.members ?? meetingInfo?.attendees,
      meetingInfo?.visitors
    )} </span></td>
    </tr>
    </tbody>
    </table>`;

  let constructedMinutes = "";
  if (!checkIfNull(parsedAISummary)) {
    constructedMinutes = parsedAISummary;
  } else if (!checkIfNull(transcription.openAi)) {
    constructedMinutes = "<h3>Summary </h3>";
    if (transcription.openAi?.shortSummaryContent) {
      constructedMinutes +=
        transcription.openAi?.shortSummaryContent + "<br/><br/>";
    }
    if (!checkIfNull(transcription.openAi?.summaryContent)) {
      constructedMinutes += "<h3>Minutes </h3><br/>";
      transcription.openAi?.summaryContent.forEach((e) => {
        constructedMinutes += e + "<br/><br/>";
      });
    }
  } else {
    constructedMinutes = "No Minutes Available";
  }

  return (meetingInfoString + constructedMinutes).replace(/\n/g, "<br/>");
};
