import React from "react";
import { Box } from "@mui/material";
import { FlowBox } from "blocks/atoms/uistyles";
import { ChatMessageList, ChatMessageInput } from "../foundations";
import FileViewer from "blocks/modules/FileViewer";
import { checkIfNull } from "@app21/core";

export default function ChatMessagePanel({
  viewMode = "full",
  viewFile,
  isMobile = false,
}) {
  const fileViewerFlag = checkIfNull(viewFile);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
      }}
    >
      {fileViewerFlag ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            height: "100%",
            overflow: "hidden",
            pb: 1,
            pt: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                height: "100%",
                overflow: "hidden",
              }}
            >
              <ChatMessageList viewMode={viewMode} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <ChatMessageInput viewMode={viewMode} />
            </Box>
          </Box>
        </Box>
      ) : (
        <FlowBox>
          <FileViewer file={viewFile} />
        </FlowBox>
      )}
    </Box>
  );
}
