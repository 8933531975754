/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Paper, Stack, Box } from "@mui/material";
import { useMongodbChartContext } from "providers/mongodb/Chart";
import {
  ChartDatePicker,
  ChartSuitePicker,
  ChartTextInfoPicker,
  ChartUserPicker,
  ChartGroupByPicker,
  Suite,
} from "blocks/atoms/Charts";
import type ChartsEmbedSdk from "@mongodb-js/charts-embed-dom";
import useDeepCompareEffect from "use-deep-compare-effect";
import type { Chart } from "@mongodb-js/charts-embed-dom";
import { Moment } from "moment";

interface IMongodbChartRenderProps {
  style: React.CSSProperties | undefined;
  chartId: string;
  token?: string;
  handleChartRawData?: any;
  filters?: {
    suiteFilter?: { render?: boolean; value: Suite[] };
    userFilter?: { render?: boolean; value: string[] };
    dateFilter?: { render?: boolean; value: { from?: Date; to?: Date } };
    textFilter?: { render?: boolean; value: string };
    groupByFilter?: { render?: boolean; value: string };
    transactionTypeFilter?: { render?: boolean; value: string };
  };
  listOfSuites?: [any];
  listOfUsers?: [any];
  extraSettings?: {
    showAttribution?: boolean;
  };
  className?: string;
  name?: string;
}

const MongodbChartRender: React.FC<IMongodbChartRenderProps> = (props) => {
  const {
    style = {},
    chartId,
    filters,
    className,
    listOfSuites = [],
    handleChartRawData,
  } = props;
  const { mongodbChartSdk } = useMongodbChartContext();
  const [boxClickedFlag, setBoxClickedFlag] = useState(false);
  /* State */
  const [selectedListOfSuites, setSelectedListOfSuites] = useState<Suite[]>([]);

  const [groupBySelect, setGroupBySelect] = useState<string | undefined>(
    "daily"
  );
  const [userId, setUserId] = useState(filters?.userFilter?.value);
  const [date, setDate] = useState(filters?.dateFilter?.value);
  const [query, setQuery] = useState(filters?.textFilter?.value);
  const [transactionType, setTransactionType] = useState(
    filters?.transactionTypeFilter?.value
  );
  const [chartRangeDate, setChartRangeDate] = useState<{
    startDate: null | Moment;
    endDate: null | Moment;
  }>({
    startDate: null,
    endDate: null,
  });
  const [chart, setChart] = useState<Chart | null>(null);

  const uniqueId = React.useMemo(() => _.uniqueId("mongodb-charts"), []);

  const domElementId = React.useMemo(
    // Enables us to render charts with same chartId multiple times on DOM
    () =>
      `${uniqueId}-${chartId}-${selectedListOfSuites
        .map(({ _id }) => _id)
        ?.toString()}`,
    [chartId, selectedListOfSuites, uniqueId]
  );

  React.useEffect(() => {
    const newDate: { from: undefined | Date; to: undefined | Date } = {
      from: undefined,
      to: undefined,
    };
    if (chartRangeDate.startDate)
      newDate.from = chartRangeDate.startDate?.toDate();

    if (chartRangeDate.endDate) newDate.to = chartRangeDate.endDate?.toDate();

    setDate(newDate);
  }, [chartRangeDate.startDate, chartRangeDate.endDate]);

  React.useEffect(() => {
    (async () => {
      const domElement = document.getElementById(domElementId);
      if (domElement && mongodbChartSdk) {
        const _chart = (mongodbChartSdk as ChartsEmbedSdk).createChart({
          chartId,
          autoRefresh: true,
          showAttribution: false,
          filter: {
            $or: selectedListOfSuites?.map((suite) => ({ suiteId: suite._id })),
          },
        });
        await _chart.render(domElement);
        if (typeof handleChartRawData === "function") {
          const chartData = await _chart.getData();
          handleChartRawData(chartData);
        }
        setChart(_chart);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartId, domElementId, mongodbChartSdk]);

  const renderChart = React.useCallback(async () => {
    const domElement = document.getElementById(domElementId);

    if (chart && domElement) {
      const dateFilter: any[] = [];

      if (date?.from) {
        dateFilter.push({ createdAt: { $gte: new Date(date.from) } });
      }

      if (date?.to) {
        dateFilter.push({
          createdAt: { $lt: new Date(date.to) },
        });
      }

      const filterChecks: any[] = [];
      filterChecks.push({
        $or: selectedListOfSuites?.map((suite) => ({ suiteId: suite._id })),
      });
      transactionType &&
        filterChecks.push({ transactionType: transactionType });
      date && filterChecks.push(...dateFilter);

      await chart.setFilter({
        $and: filterChecks,
      });
    }
  }, [chart, date, domElementId, selectedListOfSuites, transactionType]);

  useDeepCompareEffect(() => {
    setSelectedListOfSuites(filters?.suiteFilter?.value || []);
    setUserId(filters?.userFilter?.value);
    setDate(filters?.dateFilter?.value);
    setQuery(filters?.textFilter?.value);
    setTransactionType(filters?.transactionTypeFilter?.value);
  }, [props]);

  useEffect(() => {
    renderChart();
  }, [renderChart]);

  return (
    <Paper
      elevation={1}
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        border: (theme) => `1px solid ${theme.palette.grey[300]} `,
        boxShadow: (theme) => theme.shadows[boxClickedFlag ? 8 : 3],
        borderRadius: 2,
        pt: 3,
        px: 1,
      }}
      onClick={() => setBoxClickedFlag(true)}
      onMouseLeave={() => setBoxClickedFlag(false)}
    >
      <Stack
        direction="row"
        justifyContent="flex-end"
        sx={{
          position: "absolute",
          right: 0,
          top: 0,
          width: "100%",
          zIndex: 10,
        }}
      >
        {filters?.suiteFilter?.render ? (
          <ChartSuitePicker
            listOfSuites={listOfSuites}
            value={selectedListOfSuites}
            handleSuiteSelect={(val) => {
              setSelectedListOfSuites(val ?? []);
            }}
          />
        ) : null}

        {filters?.userFilter?.render ? <ChartUserPicker /> : null}
        {filters?.textFilter?.render ? <ChartTextInfoPicker /> : null}
        {filters?.dateFilter?.render ? (
          <ChartDatePicker
            value={chartRangeDate}
            handleDateSelect={(val) => setChartRangeDate(val)}
          />
        ) : null}
        {filters?.groupByFilter?.render ? (
          <ChartGroupByPicker
            handleGroupBySelect={(val) => setGroupBySelect(val)}
            value={groupBySelect}
          />
        ) : null}
      </Stack>

      <Box
        id={domElementId}
        style={{
          width: "100%",
          height: "100%",
          minHeight: 100,
          borderRadius: 3,
          pointerEvents: boxClickedFlag ? "auto" : "none",
          ...style,
        }}
        className={className}
      />
    </Paper>
  );
};

export default MongodbChartRender;
