import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Button,
  TextField,
  Stack,
  ToggleButton,
  Typography,
  InputAdornment,
  Tooltip,
  IconButton,
} from "@mui/material";
import { CheckCircle, Send } from "@mui/icons-material";
import { checkIfNull, useAppContext } from "@app21/core";
import { useAtom } from "jotai";
import { gql, useApolloClient } from "@apollo/client";
import {
  selectedFilesListAtom,
  aiPromptDialogOpenAtom,
} from "providers/FilesProvider/files-atoms";
import { FlowBox } from "blocks/atoms/uistyles";
import isEmpty from "validator/lib/isEmpty";
import { LoadingSpinner } from "blocks/atoms";
import toast from "react-hot-toast";

const generateAiSummaryMutation = gql`
  mutation GenerateAISummary(
    $key: String!
    $options: GenerateFileSummaryOptions
  ) {
    generateAISummary(key: $key, options: $options)
  }
`;

const FileAIPromptDialog = ({ onClose = () => null, selectedFile = {} }) => {
  const { asyncRequestStates, setAsyncRequestStates } = useAppContext();
  const apolloClient = useApolloClient();
  const [aiPromptDialogOpen, setAiPromptDialogOpen] = useAtom(
    aiPromptDialogOpenAtom
  );
  React.useEffect(() => {
    setAsyncRequestStates((prevState) => ({
      ...prevState,
      fileAIRequestStatus: null,
    }));
  }, []);

  const [chosenSummaryTimestamp, setChosenSummaryTimestamp] =
    React.useState(null);

  const hasExistingSummaries = selectedFile?.aiSummary?.length > 0;
  const hasDefaultSummary = selectedFile?.aiSummary?.some(
    (summary) => summary.prompt === "default"
  );

  const commandOptions = ["customPrompt"];
  if (!hasDefaultSummary) {
    commandOptions.push("default");
  }
  const [chosenCommandOption, setChosenCommandOption] = React.useState(null);
  const [customPromptText, setCustomPromptText] = React.useState("");

  const resetState = () => {
    setChosenCommandOption(null);
    setCustomPromptText("");
  };

  const handleOptionSelect = (event, chosenOption) => {
    if (chosenOption !== chosenSummaryTimestamp) {
      setChosenSummaryTimestamp(chosenOption);
    } else setChosenSummaryTimestamp(null);
  };

  const handleGenerateOptionSelect = (event, chosenOption) => {
    setChosenSummaryTimestamp(null);
    setChosenCommandOption(chosenOption);
    if (chosenOption === "customPrompt" && !customPromptText) {
      //do nothing as no prompt text is entered
    } else handleSubmit();
  };

  const handleInput = (e) => {
    e.preventDefault();
    if (
      e.keyCode === 13 &&
      !isEmpty(customPromptText || "", { ignore_whitespace: true })
    ) {
      handleSubmit();
    } else setCustomPromptText(e.target.value);
  };
  const handleClose = () => {
    setChosenCommandOption(null);
    setCustomPromptText("");
    onClose();
  };

  const handleAiPromptAction = async ({ fileInfo, commandPrompt }) => {
    if (!checkIfNull(fileInfo) && !checkIfNull(commandPrompt)) {
      setAsyncRequestStates((prevState) => ({
        ...prevState,
        fileAIRequestStatus: "requested",
      }));
      toast.success(`Requesting document A.I. summary...`, {
        icon: "⏳",
        duration: 5000,
        style: {
          minWidth: "250px",
        },
      });
      const response = await apolloClient.mutate({
        mutation: generateAiSummaryMutation,
        variables: {
          key: fileInfo.id,
          options: {
            customPrompt: commandPrompt ?? null,
          },
        },
      });
      if (response.data?.generateAISummary) {
        setAsyncRequestStates((prevState) => ({
          ...prevState,
          fileAIRequestStatus: "queued",
        }));
        toast.success(`Successfully Queued`, {
          icon: "✅",
          duration: 5000,
          style: {
            minWidth: "250px",
          },
        });
      } else {
        toast.error(
          `Please wait for few minutes before making another request`,
          {
            icon: "⚠️",
            duration: 5000,
            style: {
              minWidth: "250px",
            },
          }
        );
      }
    }
  };

  const handleSubmit = async () => {
    let promptText = checkIfNull(customPromptText)
      ? "default"
      : customPromptText;
    await handleAiPromptAction({
      fileInfo: selectedFile,
      commandPrompt: promptText,
    });
    setCustomPromptText("");
    // handleClose();
  };

  const isValidFileFormat =
    (selectedFile.fileType === "pdf" ||
      selectedFile.fileType === "docx" ||
      selectedFile.fileType === "doc") ??
    false;

  const ShowExistingSummaries = () => {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: 3, my: 4 }}>
        <Typography variant="subtitle1" color="secondary">
          Earlier AI prompts on this document
        </Typography>
        <Stack direction="column" spacing={2}>
          {selectedFile?.aiSummary?.map((summary, index) => (
            <ToggleButton
              key={index}
              value={summary.timestamp}
              selected={chosenSummaryTimestamp === summary.timestamp}
              onChange={handleOptionSelect}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                textAlign: "left",
              }}
            >
              {chosenSummaryTimestamp === summary.timestamp && (
                <CheckCircle color="success" sx={{ mr: 2 }} />
              )}
              {summary.prompt === "default"
                ? "Generate Quick Summary"
                : summary.prompt}
            </ToggleButton>
          ))}
        </Stack>
      </Box>
    );
  };
  const ShowSelectedPromptOutput = () => {
    if (asyncRequestStates.fileAIRequestStatus === "queued")
      return (
        <FlowBox sx={{ justifyContent: "center", alignItems: "center" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="body1" gutterBottom color="primary">
              Getting your query processed by the AI. This will take a bit of
              time based on the file size. You will be notified once the summary
              is ready.
            </Typography>
            <LoadingSpinner />
          </Box>
        </FlowBox>
      );

    return (
      <FlowBox>
        {selectedFile?.aiSummary
          ?.find((summary) => summary.timestamp === chosenSummaryTimestamp)
          ?.value?.split("\n")
          .map((e, index) => (
            <Typography key={index} variant="body1" gutterBottom>
              {e}
            </Typography>
          ))}
      </FlowBox>
    );
  };

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={aiPromptDialogOpen && !checkIfNull(selectedFile)}
      onClose={handleClose}
      sx={{ p: 2 }}
    >
      <DialogTitle>
        What would you like the AI to do on the document -{selectedFile.name}
      </DialogTitle>

      {isValidFileFormat ? (
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "stretch",
            minHeight: "75vh",
            gap: 3,
          }}
        >
          {hasExistingSummaries && (
            <Box
              sx={{
                width: "30%",
                maxWidth: "30%",
                minWidth: "25%",
              }}
            >
              <ShowExistingSummaries />
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
              flexGrow: 1,
              pt: 3,
            }}
          >
            <TextField
              multiline
              minRows={1}
              label="What would you like the AI to analyze in the document?"
              placeholder="Give clear instructions for the AI to analyze in the file? Be as verbose and clear as you need to. For e.g. Summarize the document into 5 bullet points or what are the pros and cons of the different approaches explained in the document?"
              variant="outlined"
              fullWidth
              value={customPromptText}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title="Submit" arrow>
                      <span>
                        <IconButton
                          size="small"
                          type="submit"
                          color="primary"
                          style={{ marginLeft: "0.8em" }}
                          disabled={isEmpty(customPromptText, {
                            ignore_whitespace: true,
                          })}
                          onClick={() =>
                            handleGenerateOptionSelect("customprompt")
                          }
                        >
                          <Send fontSize="inherit" />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              onChange={handleInput}
            />

            {!hasExistingSummaries && !chosenSummaryTimestamp && (
              <Box sx={{ display: "flex", alignItems: "center", gap: 4 }}>
                <Typography variant="subtitle2">
                  Quick-start suggestion
                </Typography>
                <Button
                  variant="outlined"
                  onClick={() => handleGenerateOptionSelect("default")}
                >
                  Generate a quick summary
                </Button>
              </Box>
            )}
            <FlowBox>
              <ShowSelectedPromptOutput />
            </FlowBox>
            {/* <Stack direction="row" spacing={2}>
              {commandOptions.map((commandOption) => (
                <ToggleButton
                  key={commandOption}
                  value={commandOption}
                  selected={chosenCommandOption === commandOption}
                  onChange={handleGenerateOptionSelect}
                >
                  {chosenCommandOption === commandOption && (
                    <CheckCircle color="success" sx={{ mr: 2 }} />
                  )}
                  {getCommandOptionLabels(commandOption)}
                </ToggleButton>
              ))}
            </Stack> */}

            {/* {chosenCommandOption === "customPrompt" && (
              
            )} */}
          </Box>
        </DialogContent>
      ) : (
        <DialogContent>
          <Typography variant="body1" color="error">
            This file type is not supported for AI Prompt. At the moment, we
            only offer support for PDF, DOC and DOCX files.
          </Typography>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        {/* <Button
          disabled={
            !isValidFileFormat ||
            checkIfNull(selectedFile) ||
            checkIfNull(chosenCommandOption) ||
            (chosenCommandOption === "customPrompt" &&
              checkIfNull(customPromptText))
          }
          onClick={handleSubmit}
        >
          Generate
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(FileAIPromptDialog);
