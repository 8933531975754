// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import {
  Card,
  TextField,
  CardContent,
  Grid,
  Typography,
  Button,
  Box,
} from "@mui/material";
import { FormProvider, useForm, Controller } from "react-hook-form";
import BreakoutFileUploadComponent from "blocks/atoms/uicomponents/BreakoutFileUploadComponent";

const AddEditAgendaCard = ({
  agendaInfo,
  saveAgenda,
  deleteAgenda,
  indexValue,
  canDeleteAgenda,
}) => {
  const methods = useForm({
    mode: "onChange",
    defaultValues: agendaInfo,
  });
  const { control, handleSubmit, watch, reset, setValue, formState } = methods;

  const onSubmit = async (formData) => {
    saveAgenda(formData);
  };

  const deleteRow = () => {
    indexValue ? deleteAgenda(agendaInfo, indexValue) : deleteAgenda();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ m: 0, mb: 1, p: 0 }}>
          <CardContent sx={{ display: "flex", alignItems: "center" }}>
            <Grid container spacing={2}>
              <Grid item md={2}>
                <Typography>Index</Typography>
                <Controller
                  control={control}
                  name={"seq"}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item md={4}>
                <Typography>Duration</Typography>
                <Controller
                  control={control}
                  name={"when"}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item md={6}>
                <Typography>Presenter</Typography>
                <Controller
                  control={control}
                  name={"who"}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item md={9}>
                <Typography>Topic</Typography>
                <Controller
                  control={control}
                  name={"what"}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Controller
                control={control}
                name={"files"}
                render={({ field }) => (
                  <BreakoutFileUploadComponent
                    value={field.value}
                    onChange={(value) => {
                      field.files = value;
                      field.onChange(value);
                    }}
                    showFileAddIcon={true}
                  />
                )}
              />
            </Grid>
          </CardContent>
          <Box sx={{ display: "flex", justifyContent: "end", p: 2 }}>
            <Button
              variant="outlined"
              color="error"
              onClick={deleteRow}
              disabled={!canDeleteAgenda}
            >
              DELETE ROW
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              sx={{ ml: 4 }}
            >
              SAVE
            </Button>
          </Box>
        </Card>
      </form>
    </FormProvider>
  );
};

// AddEditAgendaCard.propTypes = {
//   control: PropTypes.func,
//   formMethods: PropTypes.func,
//   agendaItemsData: PropTypes.func,
// };

// AddAgendaCard.defaultProps = {};

export default AddEditAgendaCard;
