import React from "react";
import {
  styled,
  Typography,
  ListItem,
  List,
  ListItemAvatar,
  ListItemText,
  IconButton,
} from "@mui/material";
import { UserAvatar } from "blocks/atoms";
import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from "material-ui-popup-state/hooks";
import MessageMenuPopover from "./MessageMenuPopover";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import FileMessageCard from "./FileMessageCard";

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 320,
  minWidth: 30,
  width: "fit-content",
  padding: theme.spacing(1.5),
  marginTop: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  color: "#727287",
  backgroundColor: "rgba(7, 73, 60, 0.1)",
}));

export default function SentMessageCard({ message }) {
  let chatMessage = message?.message ?? {};
  let fileKey = message?.message?.customElements?.s3Key;
  const [showOptions, setShowOptions] = React.useState(false);
  const messageCardPopupState = usePopupState({
    variant: "popover",
    popupId: "message-card-popover",
  });
  return (
    <List
      onMouseEnter={() => setShowOptions(true)}
      onMouseLeave={() => setShowOptions(false)}
    >
      <ListItem
     
      >
        <ListItemAvatar>
          <IconButton edge="end">
            {showOptions && (
              <MoreHorizOutlinedIcon
                {...bindTrigger(messageCardPopupState)}
                sx={{ width: "1rem", height: "1rem" }}
              />
            )}
            <MessageMenuPopover
              bindPopover={bindPopover}
              messageCardPopupState={messageCardPopupState}
            />
          </IconButton>
        </ListItemAvatar>
        <ListItemText
          primary={
            <>
              {fileKey ? (
                <FileMessageCard
                  fileKey={fileKey}
                  fileName={chatMessage.text}
                  bgColor="rgba(7, 73, 60, 0.1)"
                />
              ) : (
                <ContentStyle>
                  <Typography variant="body2">{chatMessage.text}</Typography>
                </ContentStyle>
              )}
            </>
          }
          
        />
      </ListItem>
    </List>
  );
}
