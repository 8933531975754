import React from 'react'
import { SvgIcon } from '@mui/material';

export default function StopSharingIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M16.6667 24.9999C11.4584 31.9583 8.33337 40.6249 8.33337 49.9999C8.33337 72.9999 27 91.6666 50 91.6666C73 91.6666 91.6667 72.9999 91.6667 49.9999C91.6667 26.9999 73 8.33325 50 8.33325C44.0417 8.33325 38.3334 9.58325 33.2084 11.8749"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect x="33" y="32" width="35" height="35" fill="currentColor" />
      </g>
    </SvgIcon>
  );
}
