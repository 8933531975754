import React from 'react'
import { SvgIcon } from '@mui/material';

export default function ApplyVisualEffectIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M8.20312 53.9224V62.5057C8.20312 83.339 16.5365 91.6724 37.3698 91.6724H62.3698C83.2031 91.6724 91.5365 83.339 91.5365 62.5057V54.1724"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M45.7031 8.3335H37.3698C16.5365 8.3335 8.20312 16.6668 8.20312 37.5002"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M77.138 12.5011C79.888 10.9594 82.5547 9.66775 85.0547 8.66775C87.013 7.91775 88.9297 8.50108 90.138 9.70941C91.388 10.9594 92.013 12.8761 91.2213 14.8344C86.0963 27.6261 73.2213 45.0427 62.4713 53.6677L55.888 58.9177C55.0547 59.5427 54.2213 60.0427 53.263 60.4177C53.263 59.7927 53.2213 59.1677 53.138 58.5011C52.763 55.7094 51.513 53.0844 49.263 50.8761C46.9713 48.5844 44.2213 47.2927 41.388 46.9177C40.7213 46.8761 40.0547 46.8344 39.388 46.8761C39.763 45.8344 40.3047 44.8761 41.013 44.0844L46.2213 37.5011C50.6797 31.9177 57.5547 25.7511 64.9297 20.3761"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M53.2341 60.369C53.2341 64.0356 51.8175 67.5357 49.1925 70.2023C47.1508 72.244 44.4008 73.6607 41.1091 74.0773L32.9008 74.9523C28.4425 75.4523 24.6091 71.6607 25.1091 67.119L25.9841 58.9107C26.7758 51.619 32.8591 46.9523 39.3591 46.8273C40.0258 46.7857 40.6925 46.8273 41.3591 46.869C44.1925 47.244 46.9425 48.5357 49.2341 50.8273C51.4841 53.0773 52.7341 55.6606 53.1091 58.4523C53.1925 59.119 53.2341 59.7856 53.2341 60.369Z"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M65.9219 49.9225C65.9219 41.2142 58.8802 34.1309 50.1302 34.1309"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
