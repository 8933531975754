// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Typography, Collapse, Button } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { LoadingSpinner } from "blocks/atoms";
import { checkIfNull, useMeetingsInDockets, useAppContext } from "@app21/core";
import { ShowMinutes } from "blocks/modules/Dockets/foundations";

import { useIntl } from "react-intl";
import toast from "react-hot-toast";

const DocketMinutesView = (props) => {
  const { viewMode } = props;
  const [viewMinutes, setViewMinutes] = React.useState(false);
  const { selectedDocketId } = useAppContext();
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });

  const {
    data: docketMeetings,
    status: docketMeetingStatus,
    apiError: docketMeetingsApiError,
  } = useMeetingsInDockets([selectedDocketId]);

  React.useEffect(() => {
    if (docketMeetingsApiError && docketMeetingStatus != "loading") {
      toast.error(docketMeetingsApiError.message, {
        appearance: "error",
        autoDismiss: "true",
      });
    }
  }, [docketMeetingsApiError]);

  const relatedMeetings =
    (docketMeetings && docketMeetings[selectedDocketId]) ?? [];
  const meeting =
    (Array.isArray(relatedMeetings) && relatedMeetings.slice(-1)[0]) ?? {};
  if (docketMeetingStatus === "loading") return <LoadingSpinner />;
  else if (viewMode === "summary") {
    if (checkIfNull(meeting?.minutes)) {
      return (
        <Typography variant="body1">
          {" "}
          {`${t("DocketDashboard.ShowMinutes.noMinutesMessage")}`}{" "}
        </Typography>
      );
    } else
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1">{`${t(
              "DocketDashboard.ShowMinutes.minutesUpdatedMessage"
            )}`}</Typography>
            <Button
              onClick={() => setViewMinutes(!viewMinutes)}
              variant="outlined"
              sx={{ marginLeft: (theme) => theme.spacing(1) }}
              endIcon={
                !viewMinutes ? <KeyboardArrowDown /> : <KeyboardArrowUp />
              }
            >
              <Typography variant="body1" sx={{ textTransform: "none" }}>
                {`${t("DocketDashboard.ShowMinutes.viewMinutesButtonLabel")}`}
              </Typography>
            </Button>
          </div>
          <Collapse
            in={viewMinutes}
            sx={{ display: "flex", flexDirection: "column", p: 1 }}
          >
            <ShowMinutes viewMode="summary" meetingId={meeting?._id} />
          </Collapse>
        </div>
      );
  }
  return (
    <div style={{ weight: "100%", height: "100%" }}>
      <ShowMinutes meetingId={meeting?._id} />
    </div>
  );
};
export default DocketMinutesView;
