// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import NavbarLayout from "blocks/views/Layouts/features/NavbarLayout";
import { useOrgsAndSuitesForUser, useAppContext } from "@app21/core";

import toast from "react-hot-toast";

const SuiteNavbar = (props) => {
  const { userInfo = {}, isBasicDataReady } = useAppContext();

  const {
    data: orgsAndSuites,
    status: orgsAndSuitesStatus,
    apiError: orgsAndSuitesApiError,
  } = useOrgsAndSuitesForUser(userInfo?._id);

  React.useEffect(() => {
    if (orgsAndSuitesApiError && orgsAndSuitesStatus != "loading") {
      toast.error(orgsAndSuitesApiError.message);
    }
  }, [orgsAndSuitesApiError]);

  if (!isBasicDataReady) return null;
  return (
    <NavbarLayout
      orgsAndSuites={orgsAndSuites}
      viewContext="suite"
      {...props}
    />
  );
};

export default SuiteNavbar;
