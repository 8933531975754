import { SvgIcon } from "@mui/material";
import React from "react";

export default function MicOffWebIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 56 56"
      stroke="#D8D8DD"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <rect
          width="56"
          height="56"
          rx="5.6"
          stroke="none"
          fill="currentColor"
        />
        <path
          d="M22.3994 25.228V27.3C22.3994 30.394 24.9054 32.9 27.9994 32.9C31.0934 32.9 33.5994 30.394 33.5994 27.3V19.6C33.5994 16.506 31.0934 14 27.9994 14C24.9054 14 22.3994 16.506 22.3994 19.6"
          strokeWidth="2.1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.2896 24.7109V27.0909C17.2896 32.9989 22.0916 37.8009 27.9996 37.8009C33.9076 37.8009 38.7095 32.9989 38.7095 27.0909V24.7109"
          strokeWidth="2.1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.0532 20.202C27.3132 19.74 28.6852 19.74 29.9452 20.202"
          strokeWidth="2.1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.8799 23.1704C27.6219 22.9744 28.3919 22.9744 29.1339 23.1704"
          strokeWidth="2.1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.9995 37.7998V41.9998"
          strokeWidth="2.1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
