export const suiteInformationText = [
  {
    suiteCode: "BOARD-SUITE-BASIC",
    suiteGroup: "BOARD-SUITE",
    suiteLevel: "BASIC",
    suiteName: "Board Suite",
    suiteBlurb:
      "Designed specifically for Boardroom conversations. The workflows and features are designed to help you get the most out of your Boardroom meetings.",
    suiteFeatures: [
      "Built for Board Meetings",
      "Conduct Resolution voting",
      "Automatic Transcription",
      "AI Minutes",
      "Build and share Agenda",
      "Confidential Document Shares",
      "Watermarking & Copy protections",
    ],
  },
  {
    suiteCode: "EXEC-SUITE-BASIC",
    suiteGroup: "EXEC-SUITE",
    suiteLevel: "BASIC",
    suiteName: "Executive Suite",
    suiteBlurb:
      "Ideal for executive team conversations. This suite has been designed to help your executives converse, share and interact with each other while efficiently supported by Trryst's AI features.",
    suiteFeatures: [
      "General Purpose Suite",
      "Collaborate with team members",
      "Recording & Transcriptions",
      "Create Breakout sub-groups",
      "Task Management",
      "Conduct team surveys",
    ],
  },
  {
    suiteCode: "DATA-SUITE-BASIC",
    suiteGroup: "DATA-SUITE",
    suiteLevel: "BASIC",
    suiteName: "Data Suite",
    suiteBlurb:
      "Built for Dataroom use-cases. So whether you need a Dataroom for a M&A deal, a legal case or a due diligence process, Data Suite is the perfect fit.",
    suiteFeatures: [
      "Virtual data room",
      "Rich Document Analytics",
      "Converse securely & confidentially",
      "Secure multi-org communications",
      "Document Management",
      "Watermarking & Copy protections",
      "Confidential Document Shares",
    ],
  },
  {
    suiteCode: "SALES-SUITE-BASIC",
    suiteGroup: "SALES-SUITE",
    suiteLevel: "BASIC",
    suiteName: "Sales Suite",
    suiteBlurb:
      "Sales Suite is meant for the Sales or any other outreach situations like Analyst Outreach etc. Whether you are a Sales warrior or an executive who wants to keep in touch with your industry Analysts this suite is for you.",
  },
  {
    suiteCode: "PERSONAL-SUITE-BASIC",
    suiteGroup: "PERSONAL-SUITE",
    suiteLevel: "BASIC",
    suiteName: "Personal Suite",
    suiteBlurb:
      "Personal suite is a free suite that has been designed to give you an introduction and insight into the features and capabilities of the Trryst platform.",
  },
];
