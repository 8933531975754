// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { isString } from "lodash";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
// material
import { alpha, styled } from "@mui/material/styles";
import { Paper, Box, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import fileFill from "@iconify/icons-eva/file-fill";
import closeFill from "@iconify/icons-eva/close-fill";
import { motion, AnimatePresence } from "framer-motion";
import { SIZELIMITS } from "blocks/atoms/constants/appConstants";

// utils
import { fData } from "@app21/core";
//
import { UploadIllustration } from "blocks/atoms/graphics";
import { checkIfNull } from "@app21/core";
// ----------------------------------------------------------------------

const DropZoneStyle = styled("div")(({ theme }) => ({
  outline: "none",
  display: "flex",
  overflow: "hidden",
  textAlign: "center",
  position: "relative",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(5, 0),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create("padding"),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  "&:hover": {
    opacity: 0.72,
    cursor: "pointer",
  },
  [theme.breakpoints.up("md")]: { textAlign: "left", flexDirection: "row" },
}));

// ----------------------------------------------------------------------

UploadSingleFileComponent.propTypes = {
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  maxSize: PropTypes.number,
  accept: PropTypes.any,
  onDrop: PropTypes.func,
  loading: PropTypes.bool,
  onRemove: PropTypes.func,
  sx: PropTypes.object,
  error: PropTypes.bool,
};
export default function UploadSingleFileComponent({
  error,
  loading,
  onRemove,
  maxSize = SIZELIMITS.objectImageMaxFileSize,
  file,
  sx,
  ...other
}) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    multiple: false,
    maxSize: maxSize ?? 5000000,
    createImageThumbnails: true,
    ...other,
  });

  const defaultAttachmentImage = (event) => {
    event.target.src = "/static/images/AttachmentIcon.png";
  };

  const ShowRejectionItems = () => (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        borderColor: "error.light",
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
      }}
    >
      {fileRejections?.map(({ file, errors }) => {
        const { path, size } = file;
        return (
          <Box key={path} sx={{ my: 1 }}>
            <Typography variant="subtitle2" noWrap>
              {path} - {fData(size)}
            </Typography>
            {errors.map((e, index) => {
              if (e.code === "file-too-large") {
                return (
                  <Typography key={index} variant="caption" component="p">
                    Individual File size is restricted to {fData(maxSize)}
                  </Typography>
                );
              } else {
                return (
                  <Typography key={index} variant="caption" component="p">
                    - {e.message}
                  </Typography>
                );
              }
            })}
          </Box>
        );
      })}
    </Paper>
  );

  return (
    <Box sx={{ width: "100%", my: 1, ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: "error.main",
            borderColor: "error.light",
            bgcolor: "error.lighter",
          }),
          ...(file && { padding: "12% 0" }),
          minHeight: 150,
          minWidth: 250,
        }}
      >
        <input {...getInputProps()} />
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", width: 420 }}>
            <img src="/static/images/uploadanimation.gif" height={150} />
          </Box>
        ) : checkIfNull(file) ? (
          <>
            <UploadIllustration sx={{ width: 220 }} />

            <Box sx={{ p: 3, ml: { md: 2 } }}>
              <Typography gutterBottom variant="h5">
                Drop or Select file
              </Typography>

              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                Drop file here or click
                <Typography
                  variant="body2"
                  component="span"
                  sx={{ color: "primary.main", mx: 1 }}
                >
                  browse
                </Typography>
                through your machine
              </Typography>
            </Box>
          </>
        ) : null}
        {Boolean(file) && (
          <Box
            sx={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              maxHeight: "50vh",
              maxWidth: "50vw",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                color: "white",
                p: 1,
                opacity: 0.9,
                bgcolor: "primary.dark",
              }}
            >
              Click to change
            </Typography>
            <Box
              component="img"
              alt="file preview"
              src={isString(file) ? file : file.preview}
              sx={{
                top: 8,
                borderRadius: 1,
                objectFit: "cover",

                width: "calc(100% - 16px)",
                height: "calc(100% - 16px)",
                minHeight: 150,
                maxHeight: 250,
                minWidth: 150,

                maxWidth: 300,
              }}
              onError={defaultAttachmentImage}
            />
          </Box>
        )}
      </DropZoneStyle>
      {fileRejections?.length > 0 && <ShowRejectionItems />}
    </Box>
  );
}
