// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  IconButton,
  TextField,
  Typography,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { Icon } from "@iconify/react";
import toast from "react-hot-toast";
import copyFill from "@iconify/icons-eva/copy-fill";
import { CopyToClipboard } from "react-copy-to-clipboard";

const VideoCallJoinLink = ({ meetingId, guestId }) => {
  const shapedLinkUrl = guestId
    ? `${process.env.ROOT_URL}/meeting?meetingId=${meetingId}`
    : `${process.env.ROOT_URL}/meeting?meetingId=${meetingId}`;

  const [copyState, setCopyState] = React.useState({
    value: shapedLinkUrl,
    copied: false,
  });

  const handleChange = (event) => {
    setCopyState({ value: event.target.value, copied: false });
  };

  const onCopy = () => {
    setCopyState({ ...copyState, copied: true });
    if (copyState.value) {
      toast.success("Copied");
    }
  };

  return (
    <div style={{ display: "flex", flexGrow: 1, alignItems: "center" }}>
      <TextField
        label="Link"
        defaultValue={shapedLinkUrl}
        size="small"
        inputProps={{ readOnly: true }}
        sx={{ width: "calc( 100% - 40px)" }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <CopyToClipboard text={copyState.value} onCopy={onCopy}>
                <Tooltip title="Copy" arrow>
                  <IconButton>
                    <Icon icon={copyFill} width={24} height={24} />
                  </IconButton>
                </Tooltip>
              </CopyToClipboard>
            </InputAdornment>
          ),
        }}
      />
      {/*<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
        <Typography variant="caption"> {shapedLinkUrlCopied ? 'Copied !' : ''}</Typography>
        <IconButton size="small" onClick={setShapedLinkUrlCopied}>
          <Icon fontSize="inherit">
            <FontAwesomeIcon icon={faCopy} />
          </Icon>
        </IconButton>
      </div>*/}
    </div>
  );
};

export default VideoCallJoinLink;
