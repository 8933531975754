import React from 'react'
import { SvgIcon } from "@mui/material";

export default function DocumentDownloadIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 16 16"
      stroke="currentColor"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M6 9.56445V11.3311L7.33333 9.99779"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 7.33203V7.9987"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.00033 11.3333L4.66699 10"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.33301 5.9987C1.33301 2.66536 2.66634 1.33203 5.99967 1.33203H9.33301"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.6663 6.66797V10.0013C14.6663 13.3346 13.333 14.668 9.99967 14.668H5.99967C2.66634 14.668 1.33301 13.3346 1.33301 10.0013V8.65463"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.9997 6.66536C9.99967 6.66536 9.33301 5.9987 9.33301 3.9987V1.33203L14.6663 6.66536"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
