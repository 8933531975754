import React from "react";
import {
  Box,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Grid,
  IconButton,
} from "@mui/material";
import { UserAvatar, PrintUserInfo } from "blocks/atoms";
import BasicSummaryCard from "./BasicSummaryCard";
import {
  RecordVoiceOverOutlined,
  FastForwardOutlined,
  AddCircleOutlined,
} from "@mui/icons-material";
import { format } from "date-fns";
import { bindTrigger } from "material-ui-popup-state/hooks";

export default function AggregatedTranscriptCard({
  playedSeconds,
  videoOffsetSeconds,
  videoPlayerRef,
  setPlayedSeconds,
  handleAddMeetingMarker,
  searchQuery,
  transcript,
  index,
  handleAddMarker,
  handleSelectMarkerTranscript,
  addMarkerPopupState,
  keyWords,
  transcription,
}) {
  const isCardActive =
    playedSeconds + videoOffsetSeconds > transcript.relativeStartTime &&
    playedSeconds + videoOffsetSeconds < transcript.relativeEndTime;
  return (
    <Card
      key={index}
      sx={{
        display: "flex",
        gap: 1,
        border: (theme) =>
          isCardActive
            ? `2px solid ${theme.palette.secondary.lighter} `
            : undefined,
        borderRadius: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "15vw",
          flexShrink: 0,
          minWidth: 100,
          height: "100%",
          maxWidth: 150,
          gap: 1,
          p: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="subtitle2">
            <PrintUserInfo
              defaultText="Speaker"
              userId={transcript?.speaker?.userId}
            />
          </Typography>
          {isCardActive && (
            <RecordVoiceOverOutlined
              fontSize="small"
              color="secondary"
              sx={{
                ml: 2,

                "-webkit-animation":
                  "blink-animation 1s steps(5, start) infinite",
                animation: "blink-animation 1s steps(5, start) infinite",
                "@keyframes blink-animation": {
                  to: {
                    visibility: "hidden",
                  },
                },
              }}
            />
          )}
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <UserAvatar
            userId={transcript?.speaker?.userId}
            variant="rounded"
            size={22}
          />
          <Typography variant="caption" sx={{ ml: 2 }}>
            {!Number.isNaN(transcript?.start)
              ? format(new Date(transcript.start), "h:mm aaa")
              : transcript?.start}
          </Typography>

          <IconButton
            size="small"
            onClick={() => {
              setPlayedSeconds(
                transcript.relativeStartTime - videoOffsetSeconds > 0
                  ? transcript.relativeStartTime - videoOffsetSeconds
                  : 0
              );
              videoPlayerRef?.current?.seekTo(
                transcript.relativeStartTime - videoOffsetSeconds > 0
                  ? (transcript.relativeStartTime - videoOffsetSeconds) / 1000
                  : 0
              );
            }}
            sx={{ p: 0, ml: 2 }}
          >
            <FastForwardOutlined fontSize="inherit" />
          </IconButton>
          {/* <IconButton
            {...bindTrigger(addMarkerPopupState, {
              onClick: handleSelectMarkerTranscript,
            })}
          >
            <AddCircleOutlined />
          </IconButton> */}
        </Box>
      </Box>

      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <BasicSummaryCard
          searchQuery={searchQuery}
          summary={transcript.content ?? ""}
        />
      </CardContent>
      {/* <Box sx={{ display: "flex", p: 1 }}> */}

      {/*  */}
      {/* <IconButton sx={{ p: 0, ml: 2 }}>
              <SpecifyNotePopover />
            </IconButton> */}
      {/* <Tooltip title="Add Marker"> */}
      {/* <IconButton size="small" sx={{ fontSize: "10px", py: 0, px: 2 }}>
              <AddMarkerPopover
                handleAddMeetingMarker={handleAddMeetingMarker}
                selectedKey={
                  transcript?.speaker?.startTimestamp ?? transcript.start
                }
              />
            </IconButton> */}
      {/* <IconButton
                    size="small"
                    onClick={(event) => {
                      handleAddMarker(
                        event,
                        transcript?.speaker?.startTimestamp ?? transcript.start
                      );
                    }}
                    sx={{ fontSize: "10px", py: 0, px: 2 }}
                  >
                    <AddCircleOutlineOutlinedIcon />
                  </IconButton> */}
      {/* </Tooltip> */}
      {/* </Typography>
        </Box>
      </Box> */}
      {/* <HandleKeywords transcription={transcription} count={3} /> */}
    </Card>
  );
}
