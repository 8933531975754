import React from 'react'
import { SvgIcon } from '@mui/material';

export default function ShareFilesIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <rect x="4" y="6" width="92" height="90" rx="23" fill="none" />
        <path
          d="M4 54.37V63.8C4 86.8 13.2 96 36.2 96H63.8C86.8 96 96 86.8 96 63.8V36.2C96 13.2 86.8 4 63.8 4H36.2C13.2 4 4 13.2 4 36.2"
          stroke="none"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M52.7344 63.3569L30.196 51.1487" stroke="currentColor" strokeWidth="6.25" strokeLinecap="round" />
        <path d="M66.1615 33.2319L30.1216 51.1489" stroke="currentColor" strokeWidth="6.25" strokeLinecap="round" />
        <circle cx="32.0742" cy="51.1487" r="12.1575" fill="none" />
        <circle cx="32.0739" cy="51.1486" r="6.83858" fill="none" />
        <circle cx="67.826" cy="70.2704" r="12.4799" fill="none" />
        <circle cx="67.8261" cy="70.2704" r="7.01992" fill="none" />
        <circle cx="67.826" cy="32.4799" r="12.4799" fill="none" />
        <circle cx="67.8261" cy="32.4799" r="7.01992" fill="none" />
        <path
          d="M22.9412 31.6V5.62354H40.2588V31.6L32.2185 24.5647L22.9412 31.6Z"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
