import React from 'react'
import { SvgIcon } from '@mui/material';

export default function SurveysIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M83.3334 62.6668V75.0002C83.3334 87.5002 75.875 91.6668 66.6667 91.6668H33.3334C24.125 91.6668 16.6667 87.5002 16.6667 75.0002V34.3752C16.6667 20.8335 24.125 17.7085 33.3334 17.7085C33.3334 20.2918 34.3749 22.6251 36.0832 24.3335C37.7916 26.0418 40.125 27.0835 42.7084 27.0835H57.2917C62.4584 27.0835 66.6667 22.8752 66.6667 17.7085C75.875 17.7085 83.3334 20.8335 83.3334 34.3752V41.9585"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M66.6666 17.7085C66.6666 22.8752 62.4583 27.0835 57.2916 27.0835H42.7083C40.125 27.0835 37.7915 26.0418 36.0832 24.3335C34.3749 22.6251 33.3333 20.2918 33.3333 17.7085C33.3333 12.5418 37.5416 8.3335 42.7083 8.3335H57.2916C59.875 8.3335 62.2084 9.37518 63.9168 11.0835C65.6251 12.7918 66.6666 15.1252 66.6666 17.7085Z"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.3333 54.1665H50"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.3333 70.8335H66.6666"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
