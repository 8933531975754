export const CREDITSUSAGE = {
  meetings: {
    creditsPerUserPerHour: 1,
  },
  processing: {
    creditsPerRecordingPerHour: 5,
    creditsPerTranscriptionPerHour: 5,
  },
  storage: {
    creditsPerGB: 1,
  },
  ai: {
    creditsPerHour: 1,
  },
  users: {
    creditsPerUser: 1,
    creditsPerAdmin: 5,
  },
};
