import React from 'react'
import { SvgIcon } from '@mui/material';

export default function SplitIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M50.0052 8.3335V91.6668"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5052 22.5418C9.0052 26.5835 8.33853 31.5418 8.33853 37.5002V62.5002C8.33853 83.3335 16.6719 91.6668 37.5052 91.6668H62.5052C83.3385 91.6668 91.6719 83.3335 91.6719 62.5002V37.5002C91.6719 16.6668 83.3385 8.3335 62.5052 8.3335H37.5052C32.5885 8.3335 28.4219 8.79183 24.8385 9.75016"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
