import React from 'react'
import { SvgIcon } from '@mui/material';

export default function ShareIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 107"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path d="M60 78L12 52" stroke="currentColor" strokeWidth="6.25" strokeLinecap="round" />
        <path d="M88.5967 13.8423L11.8423 52.0002" stroke="currentColor" strokeWidth="6.25" strokeLinecap="round" />
        <circle cx="16" cy="52" r="16" fill="currentColor" />
        <circle cx="16" cy="52" r="9" fill="white" />
        <circle cx="84" cy="16" r="16" fill="currentColor" />
        <circle cx="84" cy="16" r="9" fill="white" />
        <circle cx="84" cy="91" r="16" fill="currentColor" />
        <circle cx="84" cy="91" r="9" fill="white" />
      </g>
    </SvgIcon>
  );
}
