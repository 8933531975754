// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { LoadingSpinner } from "blocks/atoms";
import Results from "./BoardMeetingsListTableView/Results";
import { useAppContext, useSuite } from "@app21/core";
import { ExecSuitesBreakoutsDashboardView } from "blocks/views/DocketDashboard/features";
import toast from "react-hot-toast";
import { TabbedDocketDashboardView } from "blocks/modules/Dockets";

const DocketsListView = () => {
  const { selectedSuiteId } = useAppContext();

  const {
    data: selectedSuite,
    status: selectedSuiteStatus,
    apiError: selectedSuiteApiError,
  } = useSuite(selectedSuiteId);

  React.useEffect(() => {
    if (selectedSuiteApiError && selectedSuiteStatus != "loading") {
      toast.error(selectedSuiteApiError.message);
    }
  }, [selectedSuiteApiError]);

  function getDisplayComponent(suiteType) {
    switch (true) {
      case suiteType?.includes("BOARD"):
        return <TabbedDocketDashboardView viewContext="list" />;
      default:
        return <ExecSuitesBreakoutsDashboardView />;
    }
  }
  if (selectedSuiteStatus === "loading") {
    return <LoadingSpinner />;
  } else return getDisplayComponent(selectedSuite?.suiteType);
};

export default DocketsListView;
