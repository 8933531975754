// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
export const processTasksData = ({
  tasks,
  viewContext,
  contextId,
  usersInSuite,
  userInfo,
  theme,
}) => {
  const myId = userInfo._id;
  const baseData =
    (viewContext === "docket"
      ? tasks?.allTasks?.filter((task) => task.docketId === contextId)
      : tasks?.allTasks) ?? [];
  const pendingTasksMeta = {
    id: "pending",
    title: "Planned Tasks",
    label: null,
    style: {
      overflow: "auto",
      width: "33%",
      maxWidth: 350,
      paddingRight: 5,
      paddingLeft: 5,
      backgroundColor: theme.palette.secondary.lighter,
      textAlign: "center",
    },
  };
  const wipTasksMeta = {
    id: "wip",
    title: "Work In Progress",
    label: null,
    style: {
      width: "33%",
      maxWidth: 350,
      flexGrow: 1,
      backgroundColor: theme.palette.primary.lighter,
      textAlign: "center",
    },
    disallowAddingCard: true,
  };
  const completedTasksMeta = {
    id: "completed",
    title: "Completed",
    label: null,
    style: {
      width: "33%",
      maxWidth: 350,
      backgroundColor: theme.palette.success.lighter,
      textAlign: "center",
    },
    disallowAddingCard: true,
  };

  const myTasksData = {
    lanes: [
      { ...pendingTasksMeta, cards: [] },
      { ...wipTasksMeta, cards: [] },
      { ...completedTasksMeta, cards: [] },
    ],
  };
  const watchingTasksData = {
    lanes: [
      { ...pendingTasksMeta, cards: [] },
      { ...wipTasksMeta, cards: [] },
      { ...completedTasksMeta, cards: [] },
    ],
  };
  const allTasksData = {
    lanes: [
      { ...pendingTasksMeta, cards: [] },
      { ...wipTasksMeta, cards: [] },
      { ...completedTasksMeta, cards: [] },
    ],
  };

  const pendingTasksList = [];
  const wipTasksList = [];
  const completedTasksList = [];

  baseData.forEach((task) => {
    const modObj = {};
    const isMyTask = task.assignees?.includes(myId) || task.createdBy === myId;
    const isWatching = task.watchers?.includes(myId);
    const { _id, ...rest } = task;
    modObj["id"] = task._id;
    modObj["metadata"] = rest;
    // ppopulate required data to simplify downstream components
    modObj.metadata.createdByInfo = usersInSuite?.find(
      (user) => user._id === task.createdBy
    );
    modObj.metadata.isWatching = isWatching;
    modObj.metadata.assigneesInfo = [];
    task.assignees.forEach((assignee) =>
      modObj.metadata.assigneesInfo.push(
        usersInSuite?.find((user) => user._id === assignee)
      )
    );
    // push into specific lane depending on status
    if (isMyTask) {
      if (task.status === "completed") {
        myTasksData.lanes[2].cards.push(modObj);
      } else if (task.status === "wip") {
        myTasksData.lanes[1].cards.push(modObj);
      } else {
        myTasksData.lanes[0].cards.push(modObj);
      }
    } else if (isWatching) {
      if (task.status === "completed") {
        watchingTasksData.lanes[2].cards.push(modObj);
      } else if (task.status === "wip") {
        watchingTasksData.lanes[1].cards.push(modObj);
      } else {
        watchingTasksData.lanes[0].cards.push(modObj);
      }
    }
    if (task.status === "completed") {
      allTasksData.lanes[2].cards.push(modObj);
    } else if (task.status === "wip") {
      allTasksData.lanes[1].cards.push(modObj);
    } else {
      allTasksData.lanes[0].cards.push(modObj);
    }
  });
  for (var i = 0; i < 3; i++) {
    myTasksData.lanes[i].label = myTasksData.lanes[i].cards.length
      ? myTasksData.lanes[i].cards.length.toString() + " task(s)"
      : "";
    watchingTasksData.lanes[i].label = watchingTasksData.lanes[i].cards.length
      ? watchingTasksData.lanes[i].cards.length.toString() + " task(s)"
      : "";
    allTasksData.lanes[i].label = allTasksData.lanes[i].cards.length
      ? allTasksData.lanes[i].cards.length.toString() + " task(s)"
      : "";
  }

  return {
    myTasks: myTasksData,
    watchingTasks: watchingTasksData,
    allTasks: allTasksData,
  };
};
