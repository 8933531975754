import React from 'react'
import { SvgIcon } from '@mui/material';

export default function AverageViewIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M55 89.0423C52.25 92.709 47.7499 92.709 44.9999 89.0423L38.7499 80.709C38.0416 79.7923 36.5416 79.0423 35.4166 79.0423H33.3333C16.6666 79.0423 8.33331 74.8757 8.33331 54.0423V33.209C8.33331 16.5423 16.6666 8.20898 33.3333 8.20898H66.6666C83.3333 8.20898 91.6666 16.5423 91.6666 33.209V54.0423"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M89.1666 75.8333C89.1666 83.2083 83.2084 89.1667 75.8334 89.1667C68.4584 89.1667 62.5 83.2083 62.5 75.8333C62.5 68.4583 68.4584 62.5 75.8334 62.5"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M91.6667 91.6667L87.5 87.5"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M66.6519 45.8333H66.6893"
          stroke="currentColor"
          strokeWidth="8.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M49.9812 45.8333H50.0186"
          stroke="currentColor"
          strokeWidth="8.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.3104 45.8333H33.3479"
          stroke="currentColor"
          strokeWidth="8.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
