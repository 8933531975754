import React from "react";
import { intervalToDuration } from "date-fns";

export default function DisplayRecordingTimeSnippet({ initiatedTime }) {
  const [durationObj, setDurationObj] = React.useState({});

  const origTime = new Date(initiatedTime);

  React.useEffect(() => {
    var timer = setInterval(
      () =>
        setDurationObj(
          intervalToDuration({ start: origTime, end: new Date() })
        ),
      60000
    );
    return function cleanup() {
      clearInterval(timer);
    };
  });

  if (!durationObj.hours && !durationObj.minutes) return null;
  return (
    <span>
      {durationObj.hours} : {durationObj.minutes}
    </span>
  );
}
