// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Button,
  IconButton,
  NoSsr,
  Tooltip,
  Input,
  Alert,
  Stack,
  AlertTitle,
} from "@mui/material";
import { AutoFixHigh, CloudDownload, OpenInNew } from "@mui/icons-material";
import { useApolloClient, gql } from "@apollo/client";
import {
  useAppContext,
  checkIfNull,
  useSuite,
  useDockets,
  useHandleAddRemoveIdFromRoute,
  useFetchSignedURL,
} from "@app21/core";
import { handleViewerClose } from "blocks/atoms/handlers";
import tinyurl from "tinyurl";
import queryString from "query-string";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { styled, useTheme } from "@mui/material/styles";
import CustomVideoRenderer from "./CustomVideoRenderer";
import CustomPDFRenderer from "./CustomPDFRenderer";
import LoadingSpinner from "blocks/atoms/LoadingSpinner";
import CustomGifRenderer from "./CustomGifRenderer";
import CustomMSDocRenderer from "./CustomMSDocRenderer";
import mime from "mime-types";
import { useAtom } from "jotai";
import {
  selectedFilesListAtom,
  selectedFileActionAtom,
  aiPromptDialogOpenAtom,
} from "providers/FilesProvider/files-atoms";
import clip from "text-clipper";
import path from "path";
import { FileAIPromptDialog } from "blocks/modules/TrrystFiles/foundations";

const DocViewer = dynamic(() => import("@cyntler/react-doc-viewer"), {
  ssr: false,
});

const getFileByKeyQuery = gql`
  query FileByKey($key: String, $password: String) {
    fileByKey(key: $key) {
      isPasswordProtected
      signedURL(password: $password) {
        signedURL
        metricId
      }
      Key
      metadata
      type
      aiSummary {
        prompt
        value
        generatedBy
        tokensUsed
        timestamp
      }
      contentType
    }
  }
`;

const StyledPaper = styled(Paper)(() => ({
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  position: "relative",
}));

const StyledViewerPaper = styled(Paper)(({ theme }) => ({
  height: "100%",
  width: "100%",
  display: "flex",
  "& #no-renderer": {
    marginTop: 10,
    marginLeft: 10,
    fontSize: theme.typography.button.fontSize,
  },
  "& #no-renderer-download": {
    marginTop: 10,
    marginLeft: 10,
    fontSize: theme.typography.button.fontSize,
  },
}));

export const checkIfOfficeDoc = (fileUrl) => {
  let fileFlag = false;
  if (typeof fileUrl === "string") {
    let fileType = mime.lookup(fileUrl);

    if (
      fileUrl.includes("officedocument") ||
      fileUrl.includes("doc") ||
      fileUrl.includes("docx") ||
      fileUrl.includes("xls") ||
      fileUrl.includes("xlsx") ||
      fileUrl.includes("ppt") ||
      fileUrl.includes("pptx") ||
      fileUrl.includes("msword") ||
      fileUrl.includes("msexcel") ||
      fileUrl.includes("powerpoint")
    ) {
      fileFlag = true;
    }
  }
  return fileFlag;
};
const FileViewer = ({ viewContext }) => {
  const router = useRouter();
  const { viewFile } = JSON.parse(localStorage.getItem("lastIds"));

  const theme = useTheme();
  const graphqlClient = useApolloClient();
  const [selectedFilesList, setSelectedFilesList] = useAtom(
    selectedFilesListAtom
  );
  const [selectedFileAction, setSelectedFileAction] = useAtom(
    selectedFileActionAtom
  );
  const [aiPromptDialogOpen, setAiPromptDialogOpen] = useAtom(
    aiPromptDialogOpenAtom
  );
  const {
    s3Prefix,
    selectedOrganizationId,
    selectedSuiteId,
    selectedDocketId,
  } = useAppContext();

  const [fileFetchedStatus, setFileFetchedStatus] = React.useState("success");
  const [fileViewerKey, setFileViewerKey] = React.useState(null);
  const [documentProtectParams, setDocumentProtectParams] = React.useState({});
  const [aiSummary, setAiSummary] = React.useState([]);
  const { removeIdFromRoute } = useHandleAddRemoveIdFromRoute();
  const { data: selectedSuite } = useSuite(selectedSuiteId);
  const { data: selectedDocket } = useDockets(selectedDocketId);

  const [errors, setErrors] = useState([]);
  const [filePassword, setFilePassword] = useState(null);

  // dataroom and suitedashboard dont have download links.
  const isDataroomContext =
    router.pathname.includes("dataroom") ||
    router.pathname.includes("/user/organization/suite");

  const [customDocViewerRenderers, setCustomDocViewerRenderers] =
    React.useState(null);
  const [filePayload, setFilePayload] = React.useState(null);

  const [downloadFileUrl, setDownloadFileUrl] = React.useState(null);

  const response = useFetchSignedURL(
    getFileByKeyQuery,
    {
      key: fileViewerKey ?? undefined,
      password: filePassword ?? undefined,
    },
    fileViewerKey ? `${fileViewerKey}${filePassword}` : undefined
  );

  React.useEffect(() => {
    if (viewFile) {
      setFileViewerKey(decodeURIComponent(viewFile));
    } else if (s3Prefix) {
      setFileViewerKey(decodeURIComponent(s3Prefix));
    } else setFileViewerKey(null);

    setDocumentProtectParams({
      protectFlag:
        viewContext === "DOCKET"
          ? selectedDocket?.settings?.protectDocketFiles
          : selectedSuite?.settings?.protectSuiteFiles,
      protectText:
        viewContext === "DOCKET"
          ? selectedDocket?.settings?.protectDocketText
          : selectedSuite?.settings?.protectSuiteText,
      protectFileDownload:
        viewContext === "DOCKET"
          ? selectedDocket?.settings?.protectDocketFilesDownload
          : selectedSuite?.settings?.protectSuiteFilesDownload,
    });
  }, [viewFile, s3Prefix]);

  const handleMarkFileAsViewed = React.useCallback(async () => {
    if (filePayload && filePayload[0].metricsId) {
      const response = async () =>
        (
          await fetch(`${process.env.AWS_APIPATH}/analytics/file/endView`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              metricId: filePayload[0].metricsId,
            }),
          })
        ).json();

      response();
    }
  }, [filePayload]);

  const minifyUrl = React.useCallback(
    async (signedUrl, mimeType, fileName, metricsId) => {
      let minifiedUrl = await tinyurl.shorten(signedUrl);

      if (minifiedUrl) {
        setFilePayload([
          {
            uri: minifiedUrl,
            fileType: mimeType,
            fileName: fileName,
            documentProtectParams,
            metricsId,
          },
        ]);
      }
      setFileFetchedStatus("success");
    },
    [documentProtectParams]
  );

  const fileExtension = fileViewerKey?.split(".").pop();

  const isAiValidFileFormat =
    (fileExtension === "pdf" ||
      fileExtension === "docx" ||
      fileExtension === "doc" ||
      fileExtension === "txt") ??
    false;

  // this use effect is needed because signed url will be only fetched
  // once. After that it will fetched from the local cache. This would
  // mean that fileFetchedStatus will not be set to loading in that case
  // which is messing up the loading of the renderer.
  React.useEffect(() => {
    setFileFetchedStatus("loading");
  }, [viewFile]);

  const fetchSignedUrlForFileGraphql = React.useCallback(async () => {
    setFileFetchedStatus("loading");
    setErrors([]);
    try {
      if (!response || !response.data) return;
      const {
        data: { data, errors: graphqlErrors },
      } = response;
      if (graphqlErrors && graphqlErrors.length > 0) {
        setErrors(graphqlErrors.map((error) => error.message));
      } else if (
        data &&
        "fileByKey" in data &&
        data.fileByKey.signedURL.signedURL !== null
      ) {
        const {
          Key,
          contentType,
          signedURL: { signedURL, metricId },
        } = data.fileByKey;

        if ("aiSummary" in data.fileByKey) {
          setAiSummary(data.fileByKey.aiSummary);
        }

        if (checkIfOfficeDoc(contentType)) {
          const urlParams = queryString.parse(signedURL);
          const fileName = urlParams[`response-content-disposition`]
            ?.split(";")[1]
            ?.split("=")[1]
            ?.replace(/^["'](.+(?=["']$))["']$/, "$1");

          minifyUrl(signedURL, contentType, fileName, metricId);
        } else {
          setFilePayload([
            {
              uri: signedURL,
              fileType: data.fileByKey.contentType,
              fileName: path.basename(data.fileByKey.Key),
              documentProtectParams,
              metricsId: metricId,
            },
          ]);
          setDownloadFileUrl(signedURL);
          setFileFetchedStatus("success");
        }
      } else {
        // File Does Not Exists
        setFileFetchedStatus("file-does-not-exist");
      }
    } catch (error) {
      setFileFetchedStatus("error");
    }
  }, [documentProtectParams, minifyUrl, response?.dataUpdatedAt]);

  React.useEffect(() => {
    if (fileFetchedStatus === "loading") {
      setErrors([]);
    }
  }, [fileFetchedStatus]);

  React.useEffect(() => {
    let fetchRenderers = async () => {
      let { DocViewerRenderers: DocViewerRenderers } = await import(
        "@cyntler/react-doc-viewer"
      );
      if (DocViewerRenderers) {
        // const [PDFRenderer, ...NonPDFRenderers] = DocViewerRenderers;
        const NonPDFRenderers = DocViewerRenderers.filter(
          (f) => f.name !== "PDFRenderer"
        );
        setCustomDocViewerRenderers(NonPDFRenderers);
      }
    };
    fetchRenderers();
  }, []);

  React.useEffect(() => {
    window.addEventListener("unload", handleMarkFileAsViewed);
    return () => {
      window.removeEventListener("unload", handleMarkFileAsViewed);
      handleMarkFileAsViewed();
    };
  }, [handleMarkFileAsViewed]);

  React.useEffect(() => {
    if (
      fileViewerKey &&
      !fileViewerKey.endsWith("/") &&
      selectedOrganizationId
    ) {
      fetchSignedUrlForFileGraphql();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fileViewerKey,
    selectedOrganizationId,
    filePassword,
    response.dataUpdatedAt,
  ]);

  const fetchedFileForAiFormat = {
    id: fileViewerKey,
    fileType: fileExtension,
    aiSummary: [...aiSummary],
  };
  const customDocumentViewerHeader = (state) => {
    if (!state.currentDocument || state.config?.header?.disableFileName) {
      return null;
    }

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          p: 1,
          alignItems: "center",
          displayPrint: "none !important",
        }}
      >
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={() => handleViewerClose({ router })}
          sx={{ my: 1, alignSelf: "center" }}
        >
          Back
        </Button>
        <Box sx={{ display: "flex", alignItems: "center", gap: 4 }}>
          <Typography variant="h6" sx={{ my: 1, mx: 2, textAlign: "center" }}>
            {clip(state.currentDocument?.fileName || "File", 50, {
              breakWords: true,
            })}
          </Typography>
          {isAiValidFileFormat && (
            <Tooltip
              title="You can now interact with your text documents using AI."
              placement="top-start"
            >
              <Button
                variant="outlined"
                size="small"
                startIcon={<AutoFixHigh fontSize="inherit" />}
                onClick={() => {
                  setSelectedFilesList([fileViewerKey]);
                  setAiPromptDialogOpen(true);
                }}
                sx={{ ml: 3 }}
              >
                AI
              </Button>
            </Tooltip>
          )}
        </Box>
        <Box
          sx={{ display: "flex", alignItems: "center" }}
          disabled={selectedSuite?.settings?.protectSuiteFilesDownload}
        >
          {selectedSuite?.settings?.protectSuiteFilesDownload != true ? (
            <>
              <a
                href={
                  filePayload[0]?.fileType?.includes("officedocument")
                    ? `http://view.officeapps.live.com/op/view.aspx?src=${filePayload[0]?.uri}&embedded=true&disablePrint=true`
                    : filePayload[0]?.uri
                }
                target="_blank"
                rel="noreferrer"
              >
                <Tooltip title="Open in a Separate Tab" arrow>
                  <IconButton>
                    <OpenInNew />
                  </IconButton>
                </Tooltip>
              </a>
              {!isDataroomContext && (
                <a
                  href={downloadFileUrl ?? router.asPath}
                  download={downloadFileUrl ? true : false}
                >
                  <Tooltip title="Download" arrow>
                    <IconButton>
                      <CloudDownload />
                    </IconButton>
                  </Tooltip>
                </a>
              )}
            </>
          ) : (
            <>
              {/* <Tooltip title="Open in a Separate Tab" arrow>
                <IconButton>
                  <OpenInNew diabled />
                </IconButton>
              </Tooltip> */}

              {!isDataroomContext && (
                <a
                  href={downloadFileUrl ?? router.asPath}
                  download={downloadFileUrl ? true : false}
                >
                  <Tooltip title="Download" arrow>
                    <IconButton>
                      <CloudDownload />
                    </IconButton>
                  </Tooltip>
                </a>
              )}
            </>
          )}
        </Box>
      </Box>
    );
  };

  const useContextMenu = () => {
    return React.useCallback((event) => {
      event.preventDefault();
      event.stopPropagation();
    }, []);
  };
  const showContextMenu = useContextMenu();

  const resetActionsList = () => {
    setSelectedFilesList([]);
    setSelectedFileAction("");
  };

  const handleAiDialogClose = () => {
    resetActionsList();
    router.push(removeIdFromRoute({ paramKey: "choice" }), { shallow: true });
    setAiPromptDialogOpen(false);
  };

  if (errors && errors.length > 0) {
    return (
      <StyledPaper
        sx={{
          p: 0,
          bgcolor: "primary.lighter",
        }}
      >
        <Box
          sx={{
            p: 0,
            height: "100%",
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onContextMenu={showContextMenu}
        >
          <NoSsr>
            <StyledViewerPaper
              elevation={1}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              {errors.map((error) => (
                <>
                  {(error === "PASSWORD-REQUIRED" ||
                    error === "PASSWORD-INCORRECT") && (
                    <Stack sx={{ width: "100%", my: 2 }} spacing={2}>
                      {error === "PASSWORD-INCORRECT" && (
                        <Alert severity="error">
                          <AlertTitle>Incorrect Password</AlertTitle>
                        </Alert>
                      )}
                      <Alert severity="info">
                        <AlertTitle>Password Required</AlertTitle>
                        This file is password protected{" "}
                        <strong>Please enter password</strong>
                      </Alert>
                      <Box sx={{ display: "flex", gap: 2 }}>
                        <Input
                          onChange={(e) => setFilePassword(e.target.value)}
                          sx={{ mx: 5 }}
                          placeholder="Please enter password"
                          type="password"
                        />
                        <Stack direction="row" spacing={2} sx={{ mx: 5 }}>
                          <Button
                            onClick={(e) => fetchSignedUrlForFileGraphql()}
                            variant="contained"
                            color="primary"
                          >
                            Submit
                          </Button>
                          <Button
                            onClick={(e) => handleViewerClose({ router })}
                          >
                            Back
                          </Button>
                        </Stack>
                      </Box>
                    </Stack>
                  )}
                </>
              ))}
            </StyledViewerPaper>
          </NoSsr>
        </Box>
      </StyledPaper>
    );
  }

  return (
    <StyledPaper
      sx={{
        p: 0,
        bgcolor: "primary.lighter",
      }}
    >
      <Box
        sx={{
          p: 0,
          height: "100%",
          flexGrow: 1,
        }}
        onContextMenu={showContextMenu}
      >
        <NoSsr>
          <StyledViewerPaper
            elevation={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {fileFetchedStatus === "file-does-not-exist" && (
              <Typography variant="body1" sx={{ m: "10px !important" }}>
                The File does not exist and it appears to have been deleted from
                Storage
              </Typography>
            )}
            {fileFetchedStatus === "error" && (
              <Typography variant="body1" sx={{ m: "10px !important" }}>
                Sorry. There is an Error in retrieving the file. The file cannot
                be displayed
              </Typography>
            )}
            {!(fileFetchedStatus === "loading") &&
            !checkIfNull(customDocViewerRenderers) &&
            !checkIfNull(filePayload) ? (
              <DocViewer
                pluginRenderers={[
                  ...customDocViewerRenderers,
                  CustomVideoRenderer,
                  CustomPDFRenderer,
                  CustomGifRenderer,
                  CustomMSDocRenderer,
                ]}
                documents={filePayload}
                style={{
                  width: "100%",
                  height: "100%",
                  flexGrow: 1,
                  overflow: "hidden",
                  backgroundColor: (theme) => theme.palette.primary.lighter,
                  borderColor: "transparent !important",
                }}
                config={{
                  header: {
                    overrideComponent: customDocumentViewerHeader,
                  },
                }}
                theme={{
                  primary: theme.palette.primary.main,
                  secondary: theme.palette.secondary.main,
                  tertiary: "#5296d899",
                  text_primary: theme.palette.primary.contrastText,
                  text_secondary: theme.palette.secondary.contrastText,
                  text_tertiary: "#00000099",
                  disableThemeScrollbar: true,
                }}
              />
            ) : fileFetchedStatus === "loading" ? (
              <LoadingSpinner variant="exp" />
            ) : (
              <span style={{ padding: 10 }}>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => handleViewerClose({ router })}
                  sx={{}}
                >
                  Close
                </Button>
              </span>
            )}
          </StyledViewerPaper>
        </NoSsr>
      </Box>
      {aiPromptDialogOpen && (
        <FileAIPromptDialog
          selectedFile={fetchedFileForAiFormat}
          onClose={handleAiDialogClose}
        />
      )}
    </StyledPaper>
  );
};

export default FileViewer;
