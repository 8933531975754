// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Box, TextField, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { TEXTFIELDLIMITS } from "blocks/atoms/constants/appConstants";

const Details = ({ control, errors, className, ...rest }) => {
  return (
    <div {...rest}>
      <Box
        sx={{
          mt: 2,
        }}
      >
        <Controller
          name="title"
          control={control}
          rules={{
            required: {
              value: true,
              message: "A title for the task is required",
            },
            maxLength: {
              value: TEXTFIELDLIMITS.taskNameLength,
              message: `Sorry. Too long. Limit to ${TEXTFIELDLIMITS.taskNameLength} characters`,
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.title}
              helperText={errors.title?.message}
              placeholder="Something brief about the task"
              fullWidth
              label="Task Title"
              InputProps={{
                sx: {
                  backgroundColor: (theme) => theme.palette.background.default,
                },
              }}
              InputLabelProps={{
                sx: {
                  color: (theme) => theme.palette.text.primary,
                  backgroundColor: (theme) => theme.palette.background.default,
                },
              }}
            />
          )}
        />
      </Box>
      <Box
        sx={{
          mt: 2,
        }}
      >
        <Typography variant="overline" color="textSecondary">
          Description
        </Typography>
        <Box>
          <Controller
            name="description"
            control={control}
            rules={{
              maxLength: {
                value: TEXTFIELDLIMITS.taskDescriptionLength,
                message: `Sorry. Too long. Limit to ${TEXTFIELDLIMITS.taskDescriptionLength} characters`,
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.description}
                helperText={errors.description?.message}
                multiline
                rows={6}
                fullWidth
                placeholder="Add a longer description (,if needed) "
                InputProps={{
                  sx: {
                    backgroundColor: (theme) =>
                      theme.palette.background.default,
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: (theme) => theme.palette.text.primary,
                    backgroundColor: (theme) =>
                      theme.palette.background.default,
                  },
                }}
              />
            )}
          />
        </Box>
      </Box>
    </div>
  );
};

Details.propTypes = {
  className: PropTypes.string,
  control: PropTypes.any,
  errors: PropTypes.any,
};

export default Details;
