import React from "react";
import {
  InfoActiveWebIcon,
  InfoInactiveWebIcon,
} from "blocks/atoms/trrystCustomIcons";
import { Button, useTheme } from "@mui/material";
import { pxToRem } from "utils/styleHelpers";
import {
  usePopupState,
  bindToggle,
  bindPopover,
} from "material-ui-popup-state/hooks";
import MeetingInfoSnippet from "./MeetingInfoSnippet";
import { MenuPopover } from "blocks/atoms";
import { StyledSquareMenuButton } from "blocks/atoms/uistyles";
import { chimeTestIds } from "blocks/atoms/constants";

export default function MeetingInfoControl({ recommendedButtonSize = 40 }) {
  const theme = useTheme();

  const meetingInfoShowPopupState = usePopupState({
    variant: "popover",
    popupId: "meetingInfoPopupMenu",
  });

  return (
    <>
      <StyledSquareMenuButton
        data-testid={chimeTestIds.meetingInfoControl}
        {...bindToggle(meetingInfoShowPopupState)}
        sx={{
          borderRadius: 0,
          padding: 0,
          width: recommendedButtonSize,
          height: recommendedButtonSize,
          maxWidth: recommendedButtonSize,
          maxHeight: recommendedButtonSize,
          minWidth: recommendedButtonSize,
          minHeight: recommendedButtonSize,
        }}
      >
        {!meetingInfoShowPopupState.isOpen ? (
          <InfoActiveWebIcon
            color="primary"
            stroke={theme.palette.primary.contrastText}
            fontSize="large"
            sx={{
              width: recommendedButtonSize,
              height: recommendedButtonSize,

              maxWidth: pxToRem(40),
              maxHeight: pxToRem(40),
              "&:hover": {
                color: "primary.dark",
              },
            }}
          />
        ) : (
          <InfoInactiveWebIcon
            stroke={theme.palette.grey[600]}
            fontSize="large"
            sx={{
              width: recommendedButtonSize,
              height: recommendedButtonSize,

              maxWidth: pxToRem(40),
              maxHeight: pxToRem(40),
              color: (theme) => theme.palette.gray.lighter,
              "&:hover": {
                color: "gray.light",
              },
            }}
          />
        )}
      </StyledSquareMenuButton>
      <MenuPopover
        {...bindPopover(meetingInfoShowPopupState)}
        id="dragModal"
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        arrow="top-center"
        sx={{
          pb: 3,
          minHeight: 600,
          maxHeight: "85vh",
          minWidth: 330,
          bgcolor: "transparent",
        }}
      >
        <MeetingInfoSnippet popupState={meetingInfoShowPopupState} />
      </MenuPopover>
      {/* {handleMeetingInfo()} */}
    </>
  );
}
