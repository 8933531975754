import React from 'react'
import { SvgIcon } from "@mui/material";

export default function MessageTextIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 22 22"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M7.79134 17.4166H7.33301C3.66634 17.4166 1.83301 16.4999 1.83301 11.9166V7.33325C1.83301 3.66659 3.66634 1.83325 7.33301 1.83325H14.6663C18.333 1.83325 20.1663 3.66659 20.1663 7.33325V11.9166C20.1663 15.5833 18.333 17.4166 14.6663 17.4166H14.208C13.9238 17.4166 13.6488 17.5541 13.4747 17.7833L12.0997 19.6166C11.4947 20.4233 10.5047 20.4233 9.89967 19.6166L8.52467 17.7833C8.37801 17.5816 8.03884 17.4166 7.79134 17.4166Z"
          stroke="currentColor"
          strokeWidth="1.375"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.41699 7.33325H15.5837"
          stroke="currentColor"
          strokeWidth="1.375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.41699 11.9167H11.917"
          stroke="currentColor"
          strokeWidth="1.375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
