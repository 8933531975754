import React from "react";
import { Box, Typography, Button, Divider } from "@mui/material";

export default function ShowAgendaHeaderMenu({
  canEditAgenda = false,
  meeting = {},
}) {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",

          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5" sx={{ pl: 1, my: 2 }}>
          {meeting?.meetingJSON?.summary}
        </Typography>

        {canEditAgenda && meeting?.agendaItems?.length !== 0 && (
          <Button>Edit</Button>
        )}
      </Box>
      <Divider sx={{ my: 2 }} />
    </Box>
  );
}
