import React from 'react'
import { SvgIcon } from '@mui/material';

export default function ChatIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M8.33435 33.3345C8.33435 16.6678 16.6677 8.33447 33.3343 8.33447H66.6677C83.3343 8.33447 91.6677 16.6678 91.6677 33.3345V54.1678C91.6677 70.8345 83.3343 79.1678 66.6677 79.1678H64.5843C63.2927 79.1678 62.0427 79.7928 61.251 80.8345L55.001 89.1678C52.251 92.8345 47.751 92.8345 45.001 89.1678L38.751 80.8345C38.0844 79.9178 36.5427 79.1678 35.4177 79.1678H33.3343C16.6677 79.1678 8.33435 75.0011 8.33435 54.1678V50.0011"
          stroke="currentColor"
          strokeWidth="6.67188"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M66.626 33.3345H70.8343"
          stroke="currentColor"
          strokeWidth="6.67188"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.1656 33.3345H52.124"
          stroke="currentColor"
          strokeWidth="6.67188"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.1656 54.1655H54.1656"
          stroke="currentColor"
          strokeWidth="6.67188"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
