// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Timeline } from "@mui/lab";
import {
  Paper,
  Typography,
  Tooltip,
  AppBar,
  Toolbar,
  IconButton,
  MenuItem,
  ListItemText,
  Box,
} from "@mui/material";
import {
  ArrowCircleUp,
  ArrowCircleDown,
  Sync,
  Delete,
} from "@mui/icons-material";
import MenuPopover from "blocks/atoms/uicomponents/MenuPopover";
import { IconButtonAnimate } from "blocks/atoms";
import { alpha } from "@mui/material/styles";
import {
  useUsersInSuite,
  useInterval,
  useAppContext,
  checkIfNull,
  useMeetingsInDockets,
  useTranscript,
  useDeleteFilesS3,
  useHandleTranscriptActions,
} from "@app21/core";
import { useQueryClient } from "react-query";
import toast from "react-hot-toast";

import PlaySelectedRecordings from "blocks/modules/MeetingRecords/features/PlaySelectedRecordings";

import { TranscriptionTimelineWithMedia } from "blocks/modules/MeetingRecords/foundations";

const ShowTranscript = () => {
  const { selectedSuiteId, selectedDocketId } = useAppContext();
  const { data: usersInSuite, status: usersInSuiteStatus } =
    useUsersInSuite(selectedSuiteId);
  const [reverseSort, setReverseSort] = React.useState(true);
  const [videoCarouselOpen, setVideoCarouselOpen] = React.useState(false);
  const [selectedTimeSlice, setSelectedTimeSlice] = React.useState(null);
  const { data: docketMeetings } = useMeetingsInDockets(selectedDocketId);
  const meetingId = docketMeetings?.length > 0 ? docketMeetings[0]._id : null;
  const { data: transcripts, status: transcriptsStatus } = useTranscript({
    meetingId,
  });
  const queryClient = useQueryClient();
  const anchorRef = React.useRef(null);
  const [deleteMenuOpen, setDeleteMenuOpen] = React.useState(false);

  const { selectedBucketId, selectedOrganizationId } = useAppContext();

  const deleteFilesS3 = useDeleteFilesS3();
  const { handleTranscriptActions, deleteTranscriptStatus } =
    useHandleTranscriptActions();

  const handleDeleteMenuOpen = () => setDeleteMenuOpen(true);
  const handleDeleteMenuClose = () => setDeleteMenuOpen(false);

  let sortedArray = transcriptsStatus ? transcripts : [];
  const [previousTranscriptLength, setPreviousTranscriptLength] =
    React.useState(sortedArray?.length ?? 0);

  sortedArray?.sort(function (a, b) {
    return reverseSort
      ? b.startTimeMs - a.startTimeMs
      : a.startTimeMs - b.startTimeMs;
  });

  useInterval(async () => {
    if (previousTranscriptLength !== sortedArray.length) {
      await queryClient.invalidateQueries([
        "allTranscripts",
        { meetingId: meetingId },
      ]);
      await queryClient.invalidateQueries([
        "allChimeRecordings",
        { meetingId: meetingId },
      ]);
      setPreviousTranscriptLength(sortedArray?.length);
    }
  }, 10000);

  const handlePlayRecordsClick = (transcriptItem) => {
    setSelectedTimeSlice(transcriptItem.startTimeMs);
    setVideoCarouselOpen(true);
  };

  const handleRefresh = async () => {
    await queryClient.invalidateQueries([
      "allTranscripts",
      { meetingId: meetingId },
    ]);
    await queryClient.invalidateQueries([
      "allChimeRecordings",
      { meetingId: meetingId },
    ]);
  };

  const handleCarouselClose = () => {
    setVideoCarouselOpen(false);
    setSelectedTimeSlice(null);
  };
  const handleDeleteRecordings = async () => {
    await deleteFilesS3.mutate({
      bucket: selectedBucketId,
      filesList: [
        `${selectedOrganizationId}/${selectedSuiteId}/records/${meetingId}/`,
      ],
      s3Prefix: `${selectedOrganizationId}/${selectedSuiteId}/records/${meetingId}/`,
    });
    if (deleteFilesS3.others.isSuccess) {
      toast.success("Recording Files Deleted");
    } else if (deleteFilesS3.others.isError) {
      toast.error("Something went wrong with the Recording Files Deletion");
    }
  };
  const idToUserMapObject = React.useMemo(() => {
    const data = {};
    if (usersInSuiteStatus !== "success") return data;
    usersInSuite.map((user) => {
      data[user._id] = user;
    });
    return data;
  }, [usersInSuite, usersInSuiteStatus]);

  const handleDeleteRecordingsAndTranscriptions = async () => {
    await handleDeleteRecordings();

    // currently there is no concept of a transcriptionId in the FE, but since a meeting can have multiple
    // transcriptions. Once that concept is introduced in FE, the transcriptionId : null will have to be
    // replaced with that.

    // if no transcriptionId is provided all transcriptions in the meetingId provided will be deleted.

    handleTranscriptActions(
      { action: "DELETE-TRANSCRIPT", meetingId, transcriptionId: null },
      null,
      null
    );

    if (deleteTranscriptStatus.isSuccess) {
      toast.success("Transcription has been deleted");
    } else if (deleteTranscriptStatus.isError) {
      toast.error("Something went wrong in the deletion of Transcriptions");
    }
  };

  return (
    <>
      <Paper sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        {checkIfNull(sortedArray) ? (
          <>
            <Tooltip title="Refresh Transcripts" arrow>
              <IconButton
                edge="end"
                color="inherit"
                sx={{ mr: 2, alignSelf: "flex-end" }}
                onClick={handleRefresh}
              >
                {<Sync />}
              </IconButton>
            </Tooltip>
            <Typography sx={{ m: 2 }}>
              No Transcript found to display
            </Typography>
          </>
        ) : (
          <>
            <AppBar position="sticky">
              <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  Transcript
                </Typography>
                <Tooltip title="Refresh Transcripts" arrow>
                  <IconButton
                    edge="end"
                    color="inherit"
                    sx={{ mr: 2 }}
                    onClick={handleRefresh}
                  >
                    {<Sync />}
                  </IconButton>
                </Tooltip>
                <Tooltip title="Sort by time" arrow>
                  <IconButton
                    color="inherit"
                    sx={{ mr: 2 }}
                    onClick={() => setReverseSort(!reverseSort)}
                  >
                    {reverseSort ? <ArrowCircleUp /> : <ArrowCircleDown />}
                  </IconButton>
                </Tooltip>
                <IconButtonAnimate
                  ref={anchorRef}
                  onClick={handleDeleteMenuOpen}
                  sx={{
                    p: 0,

                    width: 34,
                    height: 34,
                    color: "white",
                    ...(deleteMenuOpen && {
                      bgcolor: (theme) =>
                        alpha(
                          theme.palette.primary.main,
                          theme.palette.action.focusOpacity
                        ),
                    }),
                  }}
                >
                  {<Delete />}
                </IconButtonAnimate>
              </Toolbar>
            </AppBar>
            <MenuPopover
              open={deleteMenuOpen}
              onClose={handleDeleteMenuClose}
              anchorEl={anchorRef.current}
              sx={{ width: "100%", minWidth: 200, maxWidth: 250 }}
            >
              <Box sx={{ py: 1, minWidth: 200 }}>
                <MenuItem
                  onClick={handleDeleteRecordings}
                  sx={{ py: 1, px: 2.5 }}
                >
                  <ListItemText primaryTypographyProps={{ variant: "body2" }}>
                    Delete All Recordings
                  </ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={handleDeleteRecordingsAndTranscriptions}
                  sx={{ py: 1, px: 2.5 }}
                >
                  <ListItemText primaryTypographyProps={{ variant: "body2" }}>
                    Delete Transcriptions & Recordings
                  </ListItemText>
                </MenuItem>
              </Box>
            </MenuPopover>

            <Timeline>
              {sortedArray.map((transcriptItem, index) => (
                <TranscriptionTimelineWithMedia
                  key={index}
                  userToIdMap={idToUserMapObject}
                  transcription={transcriptItem}
                />
              ))}
            </Timeline>
          </>
        )}
      </Paper>
      <PlaySelectedRecordings
        meetingId={meetingId}
        timeSlice={selectedTimeSlice}
        videoCarouselOpen={videoCarouselOpen}
        handleCarouselClose={handleCarouselClose}
      />
    </>
  );
};

export default ShowTranscript;
