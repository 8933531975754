import React from "react";
import { styled, Menu, MenuItem, alpha } from "@mui/material";

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },
}));

export const StyledMenuPopover = styled((props) => (
  <Menu
    elevation={10}
    anchorOrigin={{ vertical: "top", horizontal: "center" }}
    transformOrigin={{ vertical: "bottom", horizontal: "center" }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    width: "100%",
    marginBottom: theme.spacing(1),

    color:
      theme.palette.mode === "light"
        ? theme.palette.gray.main
        : theme.palette.gray.lighter,
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "6px 0px",
      marginTop: 4,
    },
    "& .MuiMenuItem-root": {
      paddingTop: 10,
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.light,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
