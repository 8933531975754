import React from "react";
import { Box, Typography, Grid, Stack } from "@mui/material";
import { FlowBox } from "blocks/atoms/uistyles";
import { useDocketsContext } from "providers/DocketsProvider";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  ShowUpcomingDocketCard,
  ShowCompletedDocketCard,
} from "blocks/modules/Dockets/foundations";

export default function BoardMeetingsListPanel({ dockets = [] }) {
  const { isBoardContext } = useDocketsContext();

  const upcomingMeetingDockets = dockets.filter((docket) => {
    const lastMeetingArray = docket.relatedMeetings?.slice(-1);
    if (!lastMeetingArray) return false;
    const lastMeetingObj = lastMeetingArray[0];
    if (
      lastMeetingObj?.meetingStatus === "SCHEDULED" ||
      lastMeetingObj?.meetingStatus === "ONGOING" ||
      lastMeetingObj?.meetingStatus === "DRAFT"
    ) {
      return true;
    } else {
      return false;
    }
  });
  const pastMeetingDockets = dockets.filter((docket) => {
    const lastMeetingArray = docket.relatedMeetings?.slice(-1);
    if (!lastMeetingArray) return false;
    const lastMeetingObj = lastMeetingArray[0];
    if (
      lastMeetingObj?.meetingStatus === "COMPLETED" ||
      lastMeetingObj?.meetingStatus === "ARCHIVED"
    ) {
      return true;
    } else {
      return false;
    }
  });

  return (
    <FlowBox>
      <FlowBox>
        <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
          Upcoming Board Meetings
        </Typography>
        <PerfectScrollbar>
          <Stack spacing={2} direction="row">
            {upcomingMeetingDockets.map((docket, index) => (
              <ShowUpcomingDocketCard key={index} docket={docket} />
            ))}
          </Stack>
        </PerfectScrollbar>
      </FlowBox>
      <FlowBox>
        <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
          Your past Meetings
        </Typography>
        <PerfectScrollbar>
          <Stack spacing={2} direction="row">
            {pastMeetingDockets.map((docket, index) => (
              <ShowCompletedDocketCard key={index} docket={docket} />
            ))}
          </Stack>
        </PerfectScrollbar>
      </FlowBox>
    </FlowBox>
  );
}
