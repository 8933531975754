// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { UploadSingleFileDialog } from "blocks/atoms/uicomponents/upload";
import { SIZELIMITS } from "blocks/atoms/constants/appConstants";

EditUploadImage.propTypes = {
  children: PropTypes.any,
  handleImageFileUpload: PropTypes.func,
  maxSize: PropTypes.number,
  title: PropTypes.any,
};

export default function EditUploadImage({
  children,
  currentImage = null,
  handleImageFileUpload,
  maxSize = SIZELIMITS.objectImageMaxFileSize,
  title = "Upload an Image",
  ...rest
}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [uploadingActive, setUploadingActive] = useState(false);

  const handleDialogOpen = (event) => {
    event && event.preventDefault();
    event && event.stopPropagation();
    setDialogOpen(true);
  };
  const handleDialogClose = (event) => {
    event && event.preventDefault();
    event && event.stopPropagation();
    setDialogOpen(false);
  };
  return (
    <>
      <Box onClick={handleDialogOpen} {...rest} sx={{ cursor: "pointer" }}>
        {children}
      </Box>
      <UploadSingleFileDialog
        open={dialogOpen}
        accept={"image/*"}
        loading={uploadingActive}
        title={title}
        maxSize={maxSize}
        file={currentImage}
        onSubmit={async (files) => {
          setUploadingActive(true);

          await handleImageFileUpload(files);
          setUploadingActive(false);
          setDialogOpen(false);
        }}
        onClose={handleDialogClose}
      />
    </>
  );
}
