// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Paper, Button, Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";

const StyledBox = styled(Box)(({ theme }) => ({
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  overflowX: "hidden",
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: "20vh",
  width: "20vw",
  minHeight: 200,
  minWidth: 500,
  borderRadius: 15,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

const RedirectComponent = ({ messageTitle, buttonMessage, handleClick }) => {
  return (
    <StyledBox
      sx={{
        bgcolor: "background.default",
      }}
    >
      <StyledPaper elevation={3}>
        <Typography variant="h6">{messageTitle}</Typography>
        <Box sx={{ display: "flex" }}>
          <Button
            sx={{ m: 2, textTransform: "none" }}
            color="primary"
            variant="contained"
            onClick={handleClick}
          >
            {buttonMessage}
          </Button>
        </Box>
      </StyledPaper>
    </StyledBox>
  );
};

RedirectComponent.propTypes = {
  messageTitle: PropTypes.any,
  buttonMessage: PropTypes.string,
  handleClick: PropTypes.func,
};
RedirectComponent.defaultProps = {
  messageTitle: "You may not have access to this resource",
  buttonMessage: "Goto Dashboard",
  handleClick: () => {
    console.log("need handler for this click");
  },
};

export default RedirectComponent;
