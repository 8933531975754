// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import toast from "react-hot-toast";
import { useIntl } from "react-intl";
import { Box, Button, TextField } from "@mui/material";

const CheckItemAdd = ({ card, checklist, className, ...rest }) => {
  const [name, setName] = useState("");
  const [isExpanded, setExpanded] = useState(false);

  const handleAdd = () => {
    setExpanded(true);
  };

  const handleCancel = () => {
    setExpanded(false);
    setName("");
  };

  const handleChange = (event) => {
    event.persist();
    setName(event.target.value);
  };

  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });

  const handleSave = async () => {
    try {
      if (!name) {
        return;
      }

      //      await dispatch(addCheckItem(card.id, checklist.id, name));
      setExpanded(false);
      setName("");
      toast.success("Check item added");
    } catch (err) {
      console.error(err);
      toast.error(`Something went wrong - ${JSON.stringify(err)} `);
    }
  };

  return (
    <div {...rest}>
      {isExpanded ? (
        <div>
          <TextField
            fullWidth
            onChange={handleChange}
            placeholder="Add an item"
            value={name}
          />
          <Box
            sx={{
              mt: 1,
            }}
          >
            <Button variant="contained" size="small" onClick={handleSave}>
              {`${t("global.buttons.save")}`}
            </Button>
            <Button size="small" onClick={handleCancel}>
              {`${t("global.buttons.cancel")}`}
            </Button>
          </Box>
        </div>
      ) : (
        <Button variant="outlined" size="small" onClick={handleAdd}>
          {`${t("global.buttons.addItem")}`}
        </Button>
      )}
    </div>
  );
};

CheckItemAdd.propTypes = {
  card: PropTypes.object.isRequired,
  checklist: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default CheckItemAdd;
