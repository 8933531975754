import React from "react";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  IconButton,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useIntl } from "react-intl";
import VideoCallJoinLink from "blocks/modules/Scheduler/foundations/VideoCallJoinLink";
import { Delete } from "@mui/icons-material";
import { FormProvider, useForm, Controller } from "react-hook-form";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useConfirm } from "material-ui-confirm";
import { useRouter } from "next/router";
import {
  useUsersInSuite,
  useDockets,
  useMeetingsInDockets,
  useSuite,
  useAppContext,
  checkIfNull,
  createSelectOptions,
  sortMembersVisitors,
  getRolePrivileges,
  useHandleRouterPush,
  useHandleMeetingActions,
  useHandleDocketActions,
} from "@app21/core";
import LoadingSpinner from "blocks/atoms/LoadingSpinner";

import MuiSelectFormField from "blocks/atoms/MuiSelectFormField";

import ObjectID from "bson-objectid";

import isAlphanumeric from "validator/lib/isAlphanumeric";
import { useIsMobileBreakpoint } from "hooks";

const BoardMeetingEditForm = ({ className, newDocket = false, ...rest }) => {
  const deleteConfirm = useConfirm();
  const { selectedSuiteId, selectedDocketId, checkAccessRules, userInfo } =
    useAppContext();
  const {
    data: selectedSuite,
    status: selectedSuiteStatus,
    apiError: selectedSuiteApiError,
  } = useSuite(selectedSuiteId);
  const isMobile = useIsMobileBreakpoint();

  const {
    data: usersInSuite,
    status: usersInSuiteStatus,
    apiError: usersInSuiteApiError,
  } = useUsersInSuite(selectedSuiteId);
  const {
    data: docket,
    status: docketStatus,
    apiError: docketApiError,
  } = useDockets(selectedDocketId);
  const {
    data: docketMeetings,
    status: docketMeetingsStatus,
    apiError: meetingInDocketError,
  } = useMeetingsInDockets([selectedDocketId]);

  const { loadRoute } = useHandleRouterPush();

  const { handleMeetingActions } = useHandleMeetingActions();
  const { formatMessage } = useIntl();

  const dataFetchError =
    docketApiError ||
    usersInSuiteApiError ||
    meetingInDocketError ||
    selectedSuiteApiError;
  const dataFetchLoading =
    docketStatus === "loading" ||
    usersInSuiteStatus === "loading" ||
    docketMeetingsStatus === "loading" ||
    selectedSuiteStatus === "loading";

  React.useEffect(() => {
    if (dataFetchError && !dataFetchLoading) {
      toast.error(dataFetchError.message);
    }
  }, [dataFetchError]);

  const t = (id) => formatMessage({ id });

  const { accessFlag: canCreateDocket } = checkAccessRules({
    entity: "SUITE",
    action: "CREATE-DOCKET",
    featureName: "DOCKET",
    ...getRolePrivileges({
      userId: userInfo._id,
      baseObject: selectedSuite,
      members: usersInSuite.map((user) => user._id),
    }),
  });

  const { accessFlag: canEditDocket } = checkAccessRules({
    entity: "DOCKET",
    action: "UPDATE-DOCKET",
    featureName: "DOCKET",
    ...getRolePrivileges({
      userId: userInfo?._id,
      baseObject: docket ?? {},
      members: docket?.members ?? [],
    }),
  });
  const { accessFlag: canDeleteDocket } = checkAccessRules({
    entity: "DOCKET",
    action: "DELETE-DOCKET",
    featureName: "DOCKET",
    ...getRolePrivileges({
      userId: userInfo._id,
      baseObject: docket ?? {},
      members: docket?.members ?? [],
    }),
  });
  const { accessFlag: canManageInvitees } = checkAccessRules({
    entity: "DOCKET",
    action: "MANAGE-INVITEES",
    featureName: "DOCKET",
    ...getRolePrivileges({
      userId: userInfo._id,
      baseObject: docket ?? {},
      members: docket?.members ?? [],
    }),
  });
  const { accessFlag: canManageStatus } = checkAccessRules({
    entity: "DOCKET",
    action: "MANAGE-DOCKET-STATUS",
    featureName: "DOCKET",
    ...getRolePrivileges({
      userId: userInfo._id,
      baseObject: docket ?? {},
      members: docket?.members ?? [],
    }),
  });

  let selectedDocket = newDocket ? {} : docket;
  let currentMeeting = newDocket
    ? {}
    : !checkIfNull(docketMeetings)
    ? docketMeetings[selectedDocketId][0]
    : {};

  let populatedUserArray = [];
  if (
    !checkIfNull(selectedDocket?.members) &&
    typeof selectedDocket?.members[0] === "string"
  ) {
    selectedDocket?.members.forEach((userId) =>
      populatedUserArray.push(
        usersInSuite?.find((user) => user?._id === userId)
      )
    );
  } else {
    populatedUserArray = [
      ...populatedUserArray,
      ...(selectedDocket?.members || []),
    ];
  }
  if (!populatedUserArray.some((user) => user?._id === userInfo?._id)) {
    populatedUserArray.push(userInfo);
  }

  if (
    !checkIfNull(selectedDocket?.visitors) &&
    Array.isArray(selectedDocket?.visitors)
  ) {
    populatedUserArray = [
      ...populatedUserArray,
      ...(selectedDocket?.visitors || []),
    ];
  }

  let defaultUserOptions = createSelectOptions(populatedUserArray) || [];

  const baseDefaultValues = {
    title: selectedDocket?.title || "",
    start: currentMeeting?.meetingJSON?.start
      ? new Date(currentMeeting?.meetingJSON?.start)
      : null,
    end: currentMeeting?.meetingJSON?.end
      ? new Date(currentMeeting?.meetingJSON?.end)
      : null,
    members: selectedDocket?.members || [],
    notifyUsers: false,
    visitors: selectedDocket?.visitors || [],
    combined: defaultUserOptions ?? [],
    status: selectedDocket?.status || "PUBLISHED",
    videocall: selectedDocket?.videocall ?? true,
    protectDocketFiles: selectedDocket?.settings?.protectDocketFiles ?? false,
    protectDocketText:
      selectedDocket?.settings?.protectDocketText ?? "Confidential",
  };

  console.log(baseDefaultValues);
  const docketStatusOptions = [
    { label: "Draft (only you can see)", value: "DRAFT" },
    { label: "Published (all Invited can access meeting)", value: "PUBLISHED" },
    { label: "Completed (Meeting Completed)", value: "COMPLETED" },
    { label: "Archived (only visible on request)", value: "ARCHIVED" },
  ];
  const methods = useForm({
    mode: "onChange",
    defaultValues: baseDefaultValues,
  });
  const {
    getValues,
    handleSubmit,
    control,
    formState,
    watch,
    setValue,
    reset,
    setError,
  } = methods;

  const { errors, isDirty, isSubmitting, isValid, dirtyFields } = formState;

  const { handleDocketActions } = useHandleDocketActions();

  React.useEffect(() => {
    reset(baseDefaultValues);
  }, [docket, reset]);

  const handleEditClose = () => {
    reset();
    loadRoute("LIST-DOCKETS", {});
  };

  const handleDocketDelete = async () => {
    deleteConfirm({
      description:
        " Deleting the Meeting is permanent and all your data in this record will be deleted and cannot be recovered. Proceed only if you are sure!",
    })
      .then(async () => {
        await handleDocketActions(
          { action: "DELETE-DOCKET", docketId: selectedDocket._id },
          null,
          null
        );
        handleEditClose();
      })
      .catch(() => {
        console.log("Deletion cancelled.");
      });
  };

  const handleDocketUpdate = async (data) => {
    await handleDocketActions(
      {
        action: "UPDATE-DOCKET",
        docketData: data,
        selectedDocket: selectedDocket,
      },
      null,
      null
    );

    const inviteesList = [];

    data.members.map((i) =>
      inviteesList.push({
        _id: i?._id,
        emailId: i.emailId,
        fullName: i.label,
        image: i.image || i.avatar,
        rsvpStatus: i.rsvpStatus ?? "Invited",
        calendarAdded: true,
      })
    );

    const meetingJSON = {
      ...(currentMeeting?.meetingJSON || []),
      summary: data.title,
      start: data.start,
      end: data.end,
      members: inviteesList,
      visitors: data?.visitors || [],
      videocall: data?.videocall,
      meetingStatus: data?.status || "PUBLISHED",
    };

    const meetingData = {
      meetingJSON: meetingJSON,
      suiteId: selectedSuiteId,
      docketId: selectedDocketId,
      meetingType: "CLOSED-MEETING",
    };

    if (data.start || data.end) {
      if (checkIfNull(currentMeeting)) {
        await handleMeetingActions(
          {
            action: "ADD-MEETING",
            meetingId: ObjectID().toHexString(),
            meetingData: meetingData,
          },
          null,
          null
        );
      } else {
        await handleMeetingActions({
          action: "UPDATE-MEETING",
          meetingId: currentMeeting?._id,
          meetingData: meetingData,
        });
      }
    }
    return selectedDocketId;
  };
  const handleDocketCreate = async (data) => {
    const newDocketId = ObjectID().toHexString();

    await handleDocketActions({
      action: "ADD-DOCKET",
      docketData: data,
      docketId: newDocketId,
      notifyUsers: true,
    });

    await handleMeetingActions({
      action: "ADD-MEETING-BOARDMEETINGEDITFORM",
      docketCreateData: data,
      newDocketId,
      meetingId: ObjectID().toHexString(),
      notifyUsers: true,
    });
    //TODO: need to fix issue of error situations in hook
    toast.success("Board Meeting created");

    return newDocketId;
  };

  const onSubmit = async (data) => {
    let sortedData = sortMembersVisitors(data.combined);
    let modifiedData = { ...data, ...sortedData };
    let docketWriteResult;
    try {
      if (newDocket) {
        docketWriteResult = await handleDocketCreate(modifiedData);
      } else {
        docketWriteResult = await handleDocketUpdate(modifiedData);
      }
      reset();
    } catch (err) {
      toast.error(`Something went wrong ${err}`);
    }

    if (docketWriteResult) {
      loadRoute("VIEW-DOCKET", { docketId: docketWriteResult });
    } else loadRoute("LIST-DOCKETS", {});
  };

  if (
    docketStatus === "loading" ||
    docketMeetingsStatus === "loading" ||
    usersInSuiteStatus === "loading"
  ) {
    return <LoadingSpinner />;
  } else
    return (
      <FormProvider {...methods}>
        {(newDocket && canCreateDocket) || canEditDocket ? (
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Card sx={{ p: 2 }}>
              <CardContent sx={{ p: 2 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        name="title"
                        rules={{
                          required: {
                            value: true,
                            message: "A name for the Breakout is required",
                          },
                          maxLength: {
                            value: 100,
                            message:
                              "Sorry. Too long. Limit to 1000 characters",
                          },
                          validate: {
                            validText: (value) =>
                              isAlphanumeric(value, "en-US", {
                                ignore:
                                  '/^(?=.*[0-9])[- +()_@./#&!"#%{}£~`0-9]+$/',
                              }),
                          },
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            error={!!errors?.title}
                            helperText={
                              errors?.title?.message ||
                              (errors?.title?.type === "validText" &&
                                "Special characters are not allowed")
                            }
                            placeholder="Enter a brief title for your Breakout (For e.g. March 2021 Exco)"
                            label="Name for your Breakout"
                            InputProps={{
                              sx: {
                                backgroundColor: (theme) =>
                                  theme.palette.background.default,
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                color: (theme) => theme.palette.text.primary,
                                backgroundColor: (theme) =>
                                  theme.palette.background.default,
                              },
                            }}
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        name={"combined"}
                        defaultValue={defaultUserOptions}
                        disabled={!newDocket && !canManageInvitees}
                        render={({ field: { ref, ...rest } }) => (
                          <MuiSelectFormField
                            listOfUsers={usersInSuite}
                            refs={ref}
                            placeholder="Select or Invite Trryst participants"
                            limitTags={10}
                            allowGuests
                            inputValue={""}
                            {...rest}
                            onChange={(data) => {
                              setValue("combined", data, { shouldDirty: true });
                            }}
                            errors={errors?.combined}
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item container xs={12}>
                      <Box
                        sx={{
                          width: "100%",
                        }}
                      >
                        <Typography variant="subtitle1" sx={{ mb: 3 }}>
                          {`${t(
                            "SuiteDashboard.BoardMeetingEditForm.scheduleBoardMeet"
                          )}`}
                        </Typography>
                        <Grid container spacing={3}>
                          <Grid item md={6} xs={12}>
                            <Controller
                              name="start"
                              control={control}
                              render={({ field: { ref, ...rest } }) => (
                                <DateTimePicker
                                  disablePast
                                  margin="normal"
                                  label="Start Date/Time"
                                  KeyboardButtonProps={{
                                    "aria-label": "change date / time",
                                  }}
                                  sx={{
                                    flexGrow: 1,
                                    marginRight: (theme) => theme.spacing(2),
                                    "&:last-child": {
                                      marginRight: 0,
                                    },
                                    width: "100%",
                                    marginBottom: (theme) => theme.spacing(2),
                                  }}
                                  ampm={false}
                                  ampmInClock
                                  inputVariant={"outlined"}
                                  format={"dd/MM/yyyy HH:mm"}
                                  InputLabelProps={{
                                    sx: {
                                      color: (theme) =>
                                        theme.palette.text.primary,
                                      backgroundColor: (theme) =>
                                        theme.palette.background.default,
                                    },
                                  }}
                                  onAccept={() => {
                                    if (!watch("end")) {
                                      setError("end", {
                                        type: "manual",
                                        message: "End time needed",
                                      });
                                    }
                                  }}
                                  // componentsProps={{
                                  //   textField: {
                                  //     error: !!errors?.start,
                                  //     helperText: errors?.start?.message,

                                  //     InputProps: {
                                  //       sx: {
                                  //         backgroundColor: (theme) =>
                                  //           theme.palette.background.default,
                                  //       },
                                  //     },
                                  //   },
                                  // }}
                                  {...rest}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Controller
                              name="end"
                              control={control}
                              rules={{
                                required: {
                                  value: watch("start") ? true : false,
                                  message:
                                    "An End time for the meeting is needed.",
                                },
                              }}
                              render={({ field: { ref, ...rest } }) => (
                                <DateTimePicker
                                  disablePast
                                  margin="normal"
                                  label="End Date/Time"
                                  KeyboardButtonProps={{
                                    "aria-label": "change date / time",
                                  }}
                                  strictCompareDates
                                  sx={{
                                    flexGrow: 1,
                                    marginRight: (theme) => theme.spacing(2),
                                    "&:last-child": {
                                      marginRight: 0,
                                    },
                                    width: "100%",
                                    marginBottom: (theme) => theme.spacing(2),
                                  }}
                                  ampm={false}
                                  ampmInClock
                                  inputVariant={"outlined"}
                                  format={"dd/MM/yyyy HH:mm"}
                                  minDateTime={watch("start")}
                                  // componentsProps={{
                                  //   textField: {
                                  //     error: !!errors?.end,
                                  //     helperText: errors?.end?.message,

                                  //     InputProps: {
                                  //       sx: {
                                  //         backgroundColor: (theme) =>
                                  //           theme.palette.background.default,
                                  //       },
                                  //     },
                                  //   },
                                  // }}
                                  InputLabelProps={{
                                    sx: {
                                      color: (theme) =>
                                        theme.palette.text.primary,
                                      backgroundColor: (theme) =>
                                        theme.palette.background.default,
                                    },
                                  }}
                                  onAccept={() => {
                                    if (!watch("start")) {
                                      setError("start", {
                                        type: "manual",
                                        message: "Start time needed",
                                      });
                                    }
                                  }}
                                  onError={(error, value) => {
                                    if (
                                      Boolean(error) &&
                                      errors?.end?.message !== error
                                    ) {
                                      if (error === "minDate") {
                                        setError("end", {
                                          type: "manual",
                                          message:
                                            "End date should be after Start",
                                        });
                                      } else
                                        setError("end", {
                                          type: "manual",
                                          message: error,
                                        });
                                    }
                                  }}
                                  {...rest}
                                />
                              )}
                            />
                          </Grid>
                          {!(
                            checkIfNull(watch("start")) ||
                            checkIfNull(watch("end"))
                          ) && (
                            <Grid
                              item
                              xs={12}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                pt: 2,
                              }}
                            >
                              <Controller
                                name="videocall"
                                control={control}
                                render={({ field }) => (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        onChange={(e) =>
                                          field.onChange(e.target.checked)
                                        }
                                        checked={field.value}
                                      />
                                    }
                                    label={
                                      !checkIfNull(currentMeeting) &&
                                      currentMeeting._id
                                        ? "Trryst Video Link for meeting"
                                        : "Auto-Setup a Video Conference call for this Meeting"
                                    }
                                    variant="standard"
                                  />
                                )}
                              />
                              {!checkIfNull(currentMeeting) &&
                                currentMeeting._id && (
                                  <VideoCallJoinLink
                                    meetingId={currentMeeting._id}
                                  />
                                )}
                            </Grid>
                          )}
                          ´´
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography
                        variant="subtitle1"
                        style={{ marginRight: 10 }}
                      >
                        {`${t(
                          "SuiteDashboard.BoardMeetingEditForm.statusPrompt"
                        )}`}
                      </Typography>
                      <Controller
                        control={control}
                        name="status"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            placeholder="Enter a brief title for your Breakout Meeting (For e.g. March 2021 Exco)"
                            fullWidth
                            style={{ maxWidth: 300 }}
                            select
                            disabled={!newDocket && !canManageStatus}
                            SelectProps={{ native: true }}
                          >
                            {docketStatusOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <Controller
                        name="protectDocketFiles"
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(e) =>
                                  field.onChange(e.target.checked)
                                }
                                checked={field.value}
                              />
                            }
                            label={
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography
                                  variant="subtitle1"
                                  color="textPrimary"
                                >
                                  {`${t(
                                    "SuiteDashboard.BoardMeetingEditForm.watermarkPrompt"
                                  )}`}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {`${t(
                                    "SuiteDashboard.BoardMeetingEditForm.protectDocumentPrompt"
                                  )}`}
                                </Typography>
                              </div>
                            }
                          />
                        )}
                      />

                      {watch("protectDocketFiles") && (
                        <Controller
                          name="protectDocketText"
                          control={control}
                          rules={{
                            maxLength: {
                              value: 25,
                              message:
                                "Sorry. Too long. Limit to 25 characters",
                            },
                            minLength: {
                              value: 3,
                              message:
                                "Sorry. Too short. Atleast 3 characters required",
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              sx={{ ml: 3, mt: 1 }}
                              error={!!errors?.protectDocketText}
                              helperText={errors?.protectDocketText?.message}
                              variant="standard"
                              placeholder={
                                "What text do you want to use for the watermark?"
                              }
                              label="Watermark text"
                              size="small"
                              style={{ minWidth: 150, maxWidth: 300 }}
                            />
                          )}
                        />
                      )}
                    </Grid>
                  </Grid>
                </LocalizationProvider>
                <Box
                  sx={{
                    mt: 2,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Controller
                    name="notifyUsers"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) => field.onChange(e.target.checked)}
                            checked={field.value}
                          />
                        }
                        label={
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="subtitle1" color="textPrimary">
                              {`${t(
                                "SuiteDashboard.BoardMeetingEditForm.sendCalenderInvite"
                              )}`}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              {`${t(
                                "SuiteDashboard.BoardMeetingEditForm.calenderInvitePrompt"
                              )}`}
                            </Typography>
                          </div>
                        }
                        disabled={!(watch("start") || watch("end"))}
                        variant="standard"
                      />
                    )}
                  />

                  <Box
                    sx={{
                      mt: 2,
                      display: "flex",
                      justifyContent: newDocket ? "flex-end" : "space-between",
                      alignItems: "center",
                    }}
                  >
                    {!newDocket && canDeleteDocket && (
                      <IconButton onClick={handleDocketDelete}>
                        <Delete />
                      </IconButton>
                    )}

                    <div style={{ display: "flex" }}>
                      <Button
                        onClick={handleEditClose}
                        style={{ marginRight: 10 }}
                      >
                        {`${t("global.buttons.cancel")}`}
                      </Button>
                      <LoadingButton
                        type="submit"
                        onClick={handleSubmit(onSubmit)}
                        variant="contained"
                        loading={isSubmitting}
                        disabled={
                          !isDirty || !(Object.values(errors).length === 0)
                        }
                      >
                        {`${newDocket ? "Setup" : "Update"} Breakout`}
                      </LoadingButton>
                    </div>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </form>
        ) : (
          "You do not have privileges to carry out this action"
        )}
      </FormProvider>
    );
};

BoardMeetingEditForm.propTypes = {
  className: PropTypes.string,
  newDocket: PropTypes.bool,
};

BoardMeetingEditForm.defaultProps = {
  newDocket: false,
};

export default BoardMeetingEditForm;
