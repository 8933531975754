import React from "react";
import { SvgIcon } from "@mui/material";

export default function ShareScreenModIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M8.3335 54.8833V63.3C8.3335 84.1333 16.6668 92.4666 37.5002 92.4666H62.5002C83.3335 92.4666 91.6668 84.1333 91.6668 63.3V38.3C91.6668 17.4666 83.3335 9.1333 62.5002 9.1333H37.5002C16.6668 9.1333 8.3335 17.4666 8.3335 38.3"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M52.4999 77.8835H68.3332C74.5832 77.8835 77.0832 75.3835 77.0832 69.1335V61.6335C77.0832 55.3835 74.5832 52.8835 68.3332 52.8835H52.4999C46.2499 52.8835 43.7499 55.3835 43.7499 61.6335V69.1335C43.7499 75.3835 46.2499 77.8835 52.4999 77.8835Z"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
