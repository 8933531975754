// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  useAppContext,
  useSelectedBucketId,
  useFilesS3,
  useDocketsInSuite,
  FileQueryBuilder,
} from "@app21/core";
import { SelectableTreeFileExplorer } from "blocks/atoms/uicomponents";
import { Box, Typography } from "@mui/material";
//import ShowFileInfo from './ShowFileInfo';
import { docketsDeepReplaceRecursive } from "blocks/modules/TrrystFiles/foundations";

export default function CopyFromAnotherSuite({
  sourceSuite,
  sourceOrganization,
  sx,
  selectedItems,
  setSelectedItems,
}) {
  const { selectedOrganizationId, selectedSuiteId, s3Prefix } = useAppContext();
  const selectedBucketId = useSelectedBucketId(null, true);
  const { data: docketsInSuite, status: docketsInSuiteStatus } =
    useDocketsInSuite(selectedSuiteId);
  const [decodedS3Prefix, setDecodedS3Prefix] = React.useState(
    decodeURIComponent(s3Prefix)
  );

  const fileQueryBuilder = React.useMemo(
    () => new FileQueryBuilder.default(),
    []
  );

  React.useEffect(() => {
    fileQueryBuilder.setId("suite", [`${sourceSuite?._id ?? selectedSuiteId}`]);
  }, [fileQueryBuilder, sourceSuite, selectedSuiteId]);

  const {
    data: allS3Keys,
    status: allS3KeysStatus,
    apiError: allS3KeysApiError,
  } = useFilesS3(
    {
      aggregateQuery: fileQueryBuilder.getQuery(),
    },
    []
  );

  const updatedTreeKeys = React.useMemo(
    () =>
      allS3Keys?.treeKeys &&
      docketsDeepReplaceRecursive(
        "dockets",
        "Breakouts",
        JSON.parse(allS3Keys.treeKeys),
        docketsInSuite
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allS3Keys]
  );

  const keysData = updatedTreeKeys?.folders?.[0]?.folders?.[0] ?? [];

  return (
    <Box
      sx={{
        ...sx,
      }}
    >
      {keysData?.length === 0 ? (
        <Typography variant="body2">No files found</Typography>
      ) : (
        <SelectableTreeFileExplorer
          filesList={keysData}
          decodedS3Prefix={decodedS3Prefix}
          allKeys={allS3Keys.listOfAllKeys}
          docketsInSuite={docketsInSuite}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
        />
      )}
      {/*<NoSsr>
        <FileBrowser files={filesToDisplay} icons={FileIcons()} detailRenderer={ShowFileInfo} />
    </NoSsr>*/}
    </Box>
  );
}
