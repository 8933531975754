import React from 'react'
import { SvgIcon } from "@mui/material";

export default function LeftArrowIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M64.584 50L39.584 50"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M47.916 62.5L35.416 50L47.916 37.5"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.6667 24.9992C11.4584 31.9575 8.33337 40.6242 8.33337 49.9992C8.33337 72.9992 27 91.6659 50 91.6659C73 91.6659 91.6667 72.9992 91.6667 49.9992C91.6667 26.9992 73 8.33252 50 8.33252C44.0417 8.33252 38.3334 9.58252 33.2084 11.8742"
          stroke="currentColor"
          strokeWidth="6.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
