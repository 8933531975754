// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import ReactPlayer from "react-player";
import { Dialog } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Carousel } from "react-responsive-carousel";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  ".MuiDialog-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(0),
  },
  "& .MuiDialog-container": {
    padding: theme.spacing(1),
  },
}));

const VideoCarouselPlayer = ({ open, handleClose, videoFileArray }) => {
  const [currentVideoIndex, setCurrentVideoIndex] = React.useState(0);

  const playNextVideo = () => {
    const nextIndex = currentVideoIndex + 1;
    if (nextIndex >= videoFileArray.length) {
      setCurrentVideoIndex(0);
    } else {
      setCurrentVideoIndex(nextIndex);
    }
  };

  const YoutubeSlide = ({ url, isSelected }) => (
    <ReactPlayer
      width="100%"
      url={url}
      playing={isSelected}
      controls={true}
      onEnded={playNextVideo}
    />
  );

  const customRenderItem = (item, props) => (
    <item.type {...item.props} {...props} />
  );

  const getVideoThumb = (videoId) => "https://picsum.photos/200/300";

  const getVideoId = (url) =>
    url.substr("https://www.youtube.com/embed/".length, url.length);

  const customRenderThumb = (children) =>
    children.map((item, index) => {
      const videoId = getVideoId(item.props.url);
      return <img key={index} src={getVideoThumb(videoId)} />;
    });

  return (
    <StyledDialog open={open} onClose={handleClose}>
      <Carousel
        autoFocus
        selectedItem={currentVideoIndex}
        transitionTime={0}
        renderItem={customRenderItem}
        renderThumbs={customRenderThumb}
        showThumbs={false}
        style={{ padding: 10 }}
      >
        {videoFileArray.map((videoUrl, index) => (
          <YoutubeSlide key={index} url={videoUrl} />
        ))}
      </Carousel>
    </StyledDialog>
  );
};
export default VideoCarouselPlayer;
