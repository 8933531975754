// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import PropTypes from "prop-types";
// @mui
import { alpha, useTheme, styled } from "@mui/material/styles";
import { Typography, Box, IconButton } from "@mui/material";
//
import { Iconify } from "blocks/atoms";
import arrowRightFill from "@iconify/icons-eva/arrow-right-fill";
import arrowLeftFill from "@iconify/icons-eva/arrow-left-fill";
// ----------------------------------------------------------------------

const RootStyle = styled(Box, {
  shouldForwardProp: (prop) => prop !== "position",
})(({ theme, position }) => ({
  zIndex: 9,
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(1),
  bottom: theme.spacing(2),
  ...(position === "left"
    ? { left: theme.spacing(2) }
    : { right: theme.spacing(2) }),

  color: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.grey[600], 0.08),
}));

const ArrowStyle = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1),
  opacity: 0.48,
  color: theme.palette.secondary.main,
  "&:hover": { opacity: 1 },
}));

// ----------------------------------------------------------------------

CarouselArrowIndex.propTypes = {
  customIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  index: PropTypes.number,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  onPause: PropTypes.func,
  total: PropTypes.number,
  pauseState: PropTypes.bool,
  position: PropTypes.string,
};

export default function CarouselArrowIndex({
  index,
  total,
  onNext,
  onPrevious,
  onPause,
  pauseState,
  customIcon,
  position = "left",
  ...other
}) {
  const theme = useTheme();

  const isRTL = theme.direction === "rtl";

  return (
    <RootStyle position={position} {...other}>
      <ArrowStyle onClick={onPrevious}>
        {leftIcon(customIcon, isRTL)}
      </ArrowStyle>

      {/*<Typography variant="subtitle2">
        {index + 1}/{total}
  </Typography>*/}
      <ArrowStyle onClick={onPause}>
        <Iconify
          icon={!pauseState ? "eva:pause-circle-fill" : "eva:play-circle-fill"}
          sx={{
            width: 30,
            height: 30,
            ...(isRTL && { transform: " scaleX(-1)" }),
          }}
        />
      </ArrowStyle>
      <ArrowStyle onClick={onNext}>{rightIcon(customIcon, isRTL)}</ArrowStyle>
    </RootStyle>
  );
}

// ----------------------------------------------------------------------

const leftIcon = (customIcon, isRTL) => (
  <Iconify
    icon={customIcon ? customIcon : "eva:arrow-right-fill"}
    sx={{
      width: 30,
      height: 30,
      transform: " scaleX(-1)",
      ...(isRTL && { transform: " scaleX(1)" }),
    }}
  />
);

const rightIcon = (customIcon, isRTL) => (
  <Iconify
    icon={customIcon ? customIcon : "eva:arrow-right-fill"}
    sx={{
      width: 30,
      height: 30,
      ...(isRTL && { transform: " scaleX(-1)" }),
    }}
  />
);
