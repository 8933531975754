import React, { useState } from "react";
import { Typography } from "@mui/material";
import { FlowBox } from "blocks/atoms/uistyles";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Add, Search } from "@mui/icons-material";
import { LoadingSpinner } from "blocks/atoms";
import { useAtom } from "jotai";
import {
  selectedBreakoutsInTaskFiltersAtom,
  selectedStatusInTaskFiltersAtom,
  selectedTasksDateRangeAtom,
} from "blocks/modules/Tasks/tasks-atoms";
import { checkIfNull } from "@app21/core";
import { useIntl } from "react-intl";
import { sort } from "fast-sort";
import { format, isWithinInterval } from "date-fns";
import { useTasksContext } from "providers/TasksProvider";
import { DisplaySidebarTaskCard } from "blocks/modules/Tasks/foundations";

export default function ShowSidebarTasksList({ viewContext = "suite" }) {
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });

  const [selectedBreakoutsInTaskFilters] = useAtom(
    selectedBreakoutsInTaskFiltersAtom
  );
  const [selectedStatusInTaskFilters] = useAtom(
    selectedStatusInTaskFiltersAtom
  );
  const [selectedTasksDateRange] = useAtom(selectedTasksDateRangeAtom);
  const { fetchedTasks = {}, fetchedTasksStatus } = useTasksContext();

  const filteredTasks = fetchedTasks?.allTasks ?? [];

  const filterTasksBySelectedBreakouts = React.useCallback(
    (tasks = []) => {
      if (checkIfNull(selectedBreakoutsInTaskFilters)) return tasks;
      return tasks?.filter((task) => {
        if (!task.docketId) {
          return false;
        }
        return selectedBreakoutsInTaskFilters?.some(
          (docket) => (docket.value || docket._id) === task.docketId
        );
      });
    },
    [selectedBreakoutsInTaskFilters]
  );
  const filterTasksBySelectedStatus = React.useCallback(
    (tasks = []) => {
      if (checkIfNull(selectedStatusInTaskFilters)) return tasks;
      return tasks?.filter((task) => {
        if (!task.status) {
          return false;
        }
        return selectedStatusInTaskFilters?.some(
          (status) => status === task.status
        );
      });
    },
    [selectedStatusInTaskFilters]
  );
  const filterTasksBySelectedDateRange = React.useCallback(
    (tasks = []) => {
      if (checkIfNull(selectedTasksDateRange)) return tasks;

      return tasks?.filter((task) => {
        return isWithinInterval(new Date(task.dueBy ?? task.createdOn), {
          start: new Date(selectedTasksDateRange.startDate),
          end: new Date(selectedTasksDateRange.endDate),
        });
      });
    },
    [selectedTasksDateRange]
  );

  const filteredTasksSelection = filterTasksBySelectedStatus(
    filterTasksBySelectedBreakouts(
      filterTasksBySelectedDateRange(filteredTasks)
    )
  );
  const sortedTasks = sort(filteredTasksSelection ?? []).by([
    { desc: (a) => a.updatedAt },
  ]);
  if (fetchedTasksStatus === "loading") {
    return <LoadingSpinner type="exp" />;
  }

  return (
    <FlowBox sx={{ overflow: "auto" }}>
      {!sortedTasks?.length ? (
        <Typography variant="subtitle2">No Tasks found</Typography>
      ) : (
        <PerfectScrollbar
          options={{ suppressScrollX: true }}
          style={{ paddingRight: 10 }}
        >
          {sortedTasks?.map((taskObj, index) => {
            return <DisplaySidebarTaskCard key={index} taskObj={taskObj} />;
          })}
        </PerfectScrollbar>
      )}
    </FlowBox>
  );
}
