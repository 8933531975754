// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  Menu,
  MenuItem,
  Chip,
  Tooltip,
  Button,
  IconButton,
} from "@mui/material";
import { yellow, red } from "@mui/material/colors";
import {
  Rsvp,
  CheckCircle,
  ThumbDown,
  QuestionMark,
} from "@mui/icons-material";
import clsx from "clsx";
import MenuPopover from "blocks/atoms/uicomponents/MenuPopover";
import { LoadingSpinner } from "blocks/atoms";

const RSVPHandlerChip = ({
  rsvpStatus = "Invited",
  handleRSVPChange,
  pastMeetingFlag,
  loadingStatusFlag = true,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [rsvpStatusFlag, setrsvpStatusFlag] = React.useState(rsvpStatus);

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };
  React.useEffect(() => {
    setrsvpStatusFlag(rsvpStatus);
  }, [rsvpStatus]);

  const allRSVPOptionIcons = {
    Invited: <Rsvp />,
    Accepted: <CheckCircle color="success" fontSize="inherit" />,
    Tentative: <QuestionMark color="warning" fontSize="inherit" />,
    Declined: <ThumbDown color="error" fontSize="inherit" />,
  };

  const allRSVPOptionLabels = {
    Invited: "RSVP",
    Accepted: "Accept",
    Tentative: "Tentative",
    Declined: "Decline",
  };

  const handleStatusChangeClick = (targetStatus) => {
    handleRSVPChange(targetStatus);
    setrsvpStatusFlag(targetStatus);
    handleClose();
  };

  const getStyleInfo = (status) => {
    switch (status) {
      case "Invited":
        return "primary";
      case "Accepted":
        return "success";
      case "Declined":
        return "error";
      case "Tentative":
        return "secondary";
      default:
        return "primary";
    }
  };

  const handleRSVPChipClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
    event.nativeEvent.stopImmediatePropagation();
    event.stopPropagation();
    event.preventDefault();
  };
  const getStatusMenuOptions = (status) => {
    const allOptions = ["Accepted", "Declined", "Tentative"];
    let statusIndex = allOptions.findIndex((allStatus) => allStatus === status);

    if (statusIndex > -1) {
      allOptions.splice(statusIndex, 1);
    }
    return allOptions.map((allStatus, index) => (
      <MenuItem
        key={index}
        onClick={() => handleStatusChangeClick(allStatus)}
        sx={{ display: "flex", gap: 2, width: 120 }}
      >
        {allRSVPOptionIcons[allStatus]}
        {allRSVPOptionLabels[allStatus]}
      </MenuItem>
    ));
  };

  if (loadingStatusFlag) {
    return (
      !(pastMeetingFlag && rsvpStatusFlag?.toLowerCase() === "invited") && (
        <>
          <Tooltip title={rsvpStatusFlag} placement="top">
            <span>
              <IconButton
                size="small"
                variant="outlined"
                disabled={pastMeetingFlag}
                onClickCapture={handleRSVPChipClick}
              >
                {allRSVPOptionIcons[rsvpStatusFlag] ?? <Rsvp />}
              </IconButton>
            </span>
          </Tooltip>
          <MenuPopover
            open={open}
            onClose={handleClose}
            anchorEl={anchorEl}
            sx={{ width: 130 }}
          >
            {getStatusMenuOptions(rsvpStatusFlag)}
          </MenuPopover>
        </>
      )
    );
  } else return <LoadingSpinner variant="exp" />;
};
export default RSVPHandlerChip;
