import React from "react";
import { Card, CardHeader, IconButton, Avatar, alpha } from "@mui/material";
import { isString } from "lodash";
import clip from "text-clipper";
import { fData } from "@app21/core";
import { IconButtonAnimate } from "blocks/atoms";
import { Close } from "@mui/icons-material";

export default function FilePreviewCard({ file, index, onRemove }) {
  const defaultAttachmentImage = (event) => {
    event.target.src = "/static/images/AttachmentIcon.png";
  };

  return (
    <Card
      sx={{
        width: "100%",
        my: 0.5,
        flexGrow: 1,
        border: "1px solid Lightgrey",
        borderRadius: 1.5,
        overflow: "hidden",
        position: "relative",
      }}
    >
      <CardHeader
        sx={{
          p: 1,
          display: "flex",
          flexDirection: "space-between",
          alignItems: "center",
        }}
        avatar={
          <Avatar
            src={isString(file) ? file : file?.preview}
            variant="rounded"
            alt={isString(file) ? file : file?.name}
            onError={defaultAttachmentImage}
          />
        }
        title={clip(isString(file) ? file : file?.name, 42, {
          breakWords: true,
        })}
        subheader={isString(file) ? "" : fData(file?.size)}
        titleTypographyProps={{ variant: "body2" }}
        subheaderTypographyProps={{ variant: "caption" }}
        action={
          <IconButtonAnimate
            size="small"
            onClick={() => onRemove(index)}
            sx={{
              mr: "3px",
              color: "common.white",
              bgcolor: (theme) => alpha(theme.palette.grey[500], 0.72),
              "&:hover": {
                bgcolor: (theme) => alpha(theme.palette.grey[700], 0.4),
              },
            }}
          >
            <Close fontSize="inherit" />
          </IconButtonAnimate>
        }
      />
    </Card>
  );
}
