// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import PropTypes from "prop-types";
import { Button, SvgIcon } from "@mui/material";

const ActionButton = ({ icon: iconProp, children, ...rest }) => {
  const Icon = iconProp ? <SvgIcon fontSize="small">{iconProp}</SvgIcon> : null;

  return (
    <Button
      sx={{
        justifyContent: "flex-start",
        mt: (theme) => theme.spacing(2),
        mb: (theme) => theme.spacing(1),
      }}
      fullWidth
      variant="contained"
      size="small"
      startIcon={Icon}
      {...rest}
    >
      {children}
    </Button>
  );
};

ActionButton.propTypes = {
  icon: PropTypes.element,
  children: PropTypes.node,
};

export default ActionButton;
