// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { useEffect } from "react";
import { Box, IconButton } from "@mui/material";

import { Add } from "@mui/icons-material";
import Board from "react-trello";
import PropTypes from "prop-types";
import TaskCard from "blocks/modules/Tasks/foundations/TaskCard";
import { useAppContext } from "@app21/core";

const TaskBoardView = ({
  taskData,
  handleAddTask,
  handleTaskEditClick,
  handleTaskStatusUpdate,
  context,
  isDocketCreator,
  isDocketInvitee,
}) => {
  const { checkAccessRules } = useAppContext();

  const AddNewTask = () => {
    return checkAccessRules({
      entity: context === "docket" ? "DOCKET" : "ACTIONS",
      action: context === "docket" ? "ADD-DOCKET-ACTIONS" : "ADD-ACTIONS",
      featureName: context === "docket" ? "DOCKET-ACTIONS" : "ACTIONS",
      isCreator: context === "docket" ? isDocketCreator : true,
      isInvitee: context === "docket" ? isDocketInvitee : true,
    })?.accessFlag ? (
      <IconButton onClick={handleAddTask}>
        <Add />
      </IconButton>
    ) : null;
  };
  return (
    <Board
      style={{
        backgroundColor: "transparent",
        height: "100%",
        overflow: "auto",
        display: "flex",
        justifyContent: "center",
        paddingRight: 0,
      }}
      laneStyle={{ flexGrow: 1, paddingRight: "0px !important" }}
      data={taskData}
      data-testid="TaskBoardView"
      cardDraggable
      laneDraggable={false}
      onCardClick={handleTaskEditClick}
      handleDragEnd={handleTaskStatusUpdate}
      components={{
        AddCardLink: AddNewTask,
        Card: TaskCard,
      }}
      editable
    />
  );
};
TaskBoardView.propTypes = {
  taskData: PropTypes.object.isRequired,
  handleTaskEditClick: PropTypes.func.isRequired,
  handleAddTask: PropTypes.func.isRequired,
};

export default TaskBoardView;
