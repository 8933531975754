{
  /**
Inputs: onDateSelect, OnClose, basicInputChoicesArray
Outputs: endDate, startDate

Should show some custom options and show one additional custom option

*/
}

import React, { useState } from "react";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import { Box, IconButton } from "@mui/material";
import { ArrowLeftIcon, ArrowRightIcon } from "blocks/atoms/trrysticons";

export default function DateRangeSelector({
  onPrevDate = () => null,
  onNextDate = () => null,
  ...rest
}) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {startDate && (
          <IconButton onClick={onPrevDate} sx={{ p: 1 }}>
            <ArrowLeftIcon sx={{ width: 18, height: 18 }} />
          </IconButton>
        )}
        <DateRangePicker
          startDatePlaceholderText="Start"
          endDatePlaceholderText="End"
          startDateId="startDate"
          endDateId="endDate"
          startDate={startDate}
          endDate={endDate}
          onDatesChange={({ startDate, endDate }) => {
            setStartDate(startDate);
            setEndDate(endDate);
          }}
          focusedInput={focusedInput}
          onFocusChange={(focusedInput) => {
            setFocusedInput(focusedInput);
          }}
          format="DD MMM YY"
          autoFocus
          small
          {...rest}
        />
        {endDate && (
          <IconButton onClick={onNextDate} sx={{ p: 1 }}>
            <ArrowRightIcon sx={{ width: 18, height: 18 }} />
          </IconButton>
        )}
      </Box>
    </>
  );
}
