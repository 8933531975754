import React from 'react'
import { SvgIcon } from '@mui/material';

export default function TrrystLogoIcon(props) {
  return (
    <SvgIcon
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fontSize="inherit"
      {...props}
    >
      <g fill="none">
        <path
          d="M0.095459 21.6544C0.095459 9.95282 9.58149 0.466797 21.2831 0.466797H45.0132V9.3656H23.8256C16.3366 9.3656 10.2655 15.4367 10.2655 22.9257C10.2655 30.4147 16.3366 36.4858 23.8256 36.4858H45.2251V72.5047H33.9957V44.5371H32.7467L31.2182 47.1845L31.22 47.1855L16.6019 72.5047H3.63528L6.66543 67.2564L6.66366 67.2554L20.2828 43.6664C9.04562 43.1441 0.095459 33.8682 0.095459 22.5019V21.6544Z"
          fill="currentColor"
        />
        <path
          d="M100.095 21.6544C100.095 9.95282 90.6094 0.466797 78.9078 0.466797H55.1777V9.3656H76.3653C83.8543 9.3656 89.9254 15.4367 89.9254 22.9257C89.9254 30.4147 83.8543 36.4858 76.3653 36.4858H54.9658V72.5047H66.1953V44.5371H67.4442L68.9727 47.1845L68.971 47.1855L83.589 72.5047H96.5556L93.5255 67.2564L93.5273 67.2554L79.9082 43.6664C91.1453 43.1441 100.095 33.8682 100.095 22.5019V21.6544Z"
          fill="currentColor"
        />
        <circle cx="28.6983" cy="23.5608" r="6.56817" fill="currentColor" />
        <circle cx="71.7088" cy="23.5608" r="6.56817" fill="currentColor" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.4902 26.3161L49.9834 32.8093L59.4431 23.3496H56.6886L49.9834 30.0547L46.2448 26.3161H43.4902Z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
}
