import React from "react";
import { styled, Typography, Box } from "@mui/material";
import { useIsMobileBreakpoint } from "hooks";
import { ShowMoreTextPanel } from "blocks/atoms";

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 700,
  minWidth: 30,
  width: "fit-content",
  marginTop: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  color: "#727287",
  wordWrap: "break-word",
}));

export default function BasicSummaryCard({ searchQuery, summary }) {
  const isMobile = useIsMobileBreakpoint();

  return (
    <ContentStyle>
      <Typography variant="body2">
        <ShowMoreTextPanel
          text={summary}
          maxLength={isMobile ? 100 : 250}
          showMoreIndicator="More"
          showLessIndicator="Less"
          toggleFlag={true}
          searchQuery={searchQuery}
        />
      </Typography>
    </ContentStyle>
  );
}
